import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import AnimateCC from "../animate";
import { MC } from "../animate/utils";
import CreationComponent from "../creation_corner/CreationComponent";
import { PreloaderComponent } from "../loaders/PreloaderComponent";
import { routes } from "../navigation/routesPath";
import { myWork } from "./myWork";
import { additionalSounds } from "./myWorksSounds";

const MyWorksComponent: React.FC<{}> = () => {
    const [currentWorks, setCurrentWorks] = useState<any[]>();
    const [screenReady, setScreenReady] = useState(false);
    const [thumb1Loaded, setThumb1Loaded] = useState(false);
    const [thumb2Loaded, setThumb2Loaded] = useState(false);
    const { pathname } = useLocation();
    const game = React.useRef(new myWork(setCurrentWorks));
    const [renders, setRenders] = useState(0);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    useEffect(() => {
        setThumb1Loaded(false);
        setThumb2Loaded(false);
    }, [currentWorks]);

    const renderThumb = (i: number) => {
        if (!currentWorks || currentWorks.length < i + 1) return null;
        const workId = currentWorks[i];
        return (
            <CreationComponent
                viewMode={"myWorks"}
                key={workId + renders}
                workId={workId}
                workIndex={i}
                onLoaded={() => {
                    if (i === 0) setThumb1Loaded(true);
                    else if (i === 1) setThumb2Loaded(true);
                }}
            />
        );
    };

    const renderPreloader = (i: number) => {
        if (!currentWorks || currentWorks.length < i + 1) return null;
        const workId = currentWorks[i];
        return (
            <CreationComponent
                viewMode={"myWorks"}
                key={workId + renders}
                workIndex={i}
                workId={workId}
                preloader={true}
            />
        );
    };
    useEffect(() => {
        if (shouldRefresh) {
            game.current.refreshWorks().then(() => {
                setThumb1Loaded(false);
                setThumb2Loaded(false);
                setRenders((curr) => {
                    return curr + 1;
                });
            });
        }
    }, [shouldRefresh]);

    useEffect(() => {
        pathname === routes.my_works.list && game.current.resumeSound();
    }, [pathname]);
    return (
        <>
            {!screenReady && <PreloaderComponent name="maya" />}
            <AnimateCC
                base="/myWorks"
                animationName="MyWorks"
                onLoaded={(mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc);
                }}
                visible={pathname === routes.my_works.list || pathname.includes("remove")}
                additionalSounds={additionalSounds}
            />
            {renderThumb(0)}
            {thumb1Loaded ? renderThumb(1) : renderPreloader(1)}
            {thumb2Loaded ? renderThumb(2) : renderPreloader(2)}

            <Switch>
                <Route
                    path={routes.my_works.preview}
                    render={(props) => {
                        return <CreationComponent viewMode={"preview"} workId={props.match.params.workId} />;
                    }}
                />
                <Route
                    path={routes.my_works.create}
                    render={(props) => {
                        return (
                            <CreationComponent
                                viewMode={"myWorksCreation"}
                                workId={props.match.params.workId}
                                setShouldRefresh={setShouldRefresh}
                            />
                        );
                    }}
                />
                <Route
                    path={routes.my_works.remove}
                    render={(props) => {
                        return (
                            <CreationComponent
                                viewMode={"delete"}
                                workId={props.match.params.workId}
                                setShouldRefresh={setShouldRefresh}
                            />
                        );
                    }}
                />
            </Switch>
        </>
    );
};

export default MyWorksComponent;
