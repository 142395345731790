import { MC, getLetter, waitForEvent, playSound, stopAllSounds } from "../../animate/utils";
import { getWordForLetter } from "./getDataFromJson";

export class FancylWord {
    root: MC;
    showWordAndDefinition: any;
    showDefinition: any;
    path: any;
    shouldPlay: boolean = true;

    constructor(root: createjs.MovieClip, private letter: number, showWordAndDefinition: any, showDefinition: any) {
        this.root = root;
        this.showWordAndDefinition = showWordAndDefinition;
        this.showDefinition = showDefinition;
        (window as any).FancyWord = root;
    }

    setVisibleFalse = () => {
        this.root.end.visible = false;
        this.root.game.visible = false;
        this.root.explain.visible = false;
        this.root.end.gotoAndStop(0);
        this.root.game.gotoAndStop(0);
        if (this.root.explain2) {
            this.root.explain2.gotoAndStop(0);
            this.root.explain2.visible = false;
        }
        if (this.root.game2) {
            this.root.game2.gotoAndStop(0);
            this.root.game2.visible = false;
        }
        if (this.root.solutionGame) {
            this.root.solutionGame.gotoAndStop(0);
            this.root.solutionGame.visible = false;
        }
        this.root.explain.gotoAndStop(0);
    };

    loaded = async () => {
        this.setVisibleFalse();
        const data = getWordForLetter(getLetter(this.letter - 1));
        const word = data.wordToReturn;
        const definition = data.definitionToReturn;
        const firstLetter = data.firstLetter;
        await this.showWordAndDefinition(word, definition, firstLetter);
        if (this.letter === 2) {
            await this.letter2Explain();
            if (!this.shouldPlay) return;
        } else if (this.letter === 12) {
            if (!this.shouldPlay) return;

            await this.showLeteer12Explains();
        } else {
            this.root.explain.visible = true;
            if (!this.shouldPlay) return;
            this.root.explain.gotoAndPlay(1);
            if (this.letter === 3) {
                await this.waitForClickOnDog();
            } else if (!this.root.explain2) {
                if (!this.shouldPlay) return;
                return waitForEvent(this.root.explain, "animationEnd");
            } else {
                if (!this.shouldPlay) return;
                await waitForEvent(this.root.explain, "animationEnd");
                if (!this.shouldPlay) return;
                this.root.explain.visible = false;
                this.root.explain2.visible = true;
                stopAllSounds();
                if (!this.shouldPlay) return;
                this.root.explain2.gotoAndPlay(1);
                if (!this.shouldPlay) return;
                return waitForEvent(this.root.explain2, "animationEnd");
            }
        }
    };
    letter2Explain = () => {
        if (!this.shouldPlay) return;
        return new Promise<void>(async (resolve) => {
            const explain = this.root.explain;
            const explain2 = this.root.explain2;
            explain.visible = true;
            explain.cars.gotoAndStop(0);
            explain.gotoAndPlay(1);
            explain.cars.gotoAndStop(0);
            await waitForEvent(explain, "explainEnd");
            if (!this.shouldPlay) return;
            explain.cars.gotoAndPlay(1);
            if (!this.shouldPlay) return;
            await waitForEvent(explain.cars, "animationEnd");
            if (!this.shouldPlay) return;
            explain.visible = false;
            stopAllSounds();
            explain2.visible = true;
            if (!this.shouldPlay) return;
            explain2.gotoAndPlay(1);
            if (!this.shouldPlay) return;
            await waitForEvent(explain2, "animationEnd");
            if (!this.shouldPlay) return;
            resolve();
        });
    };
    waitForClickOnDog = () => {
        if (!this.shouldPlay) return;
        return new Promise<void>(async (resolve) => {
            const explain = this.root.explain;
            explain.y = 380;
            explain.scaleY = 0.8;
            explain.scaleX = 0.8;
            explain.visible = true;
            explain.mouseChildren = false;
            if (!this.shouldPlay) return;
            explain.gotoAndPlay(1);
            if (!this.shouldPlay) return;
            await waitForEvent(explain, "questionEnd");
            explain.mouseChildren = true;
            explain.cat.gotoAndStop(0);
            explain.cat.cat_tv.gotoAndStop(0);
            explain.dog.addEventListener("click", async () => {
                explain.mouseEnabled = false;
                if (!this.shouldPlay) return;
                explain.gotoAndPlay(345);
                if (!this.shouldPlay) return;
                await waitForEvent(explain, "gameEnd");
                if (!this.shouldPlay) return;
                explain.dog.removeAllEventListeners();
                explain.cat.removeAllEventListeners();
                resolve();
            });
            explain.cat.addEventListener("rollover", async () => {
                explain.cat.cat_tv.gotoAndStop("over");
            });
            explain.cat.addEventListener("rollout", async () => {
                explain.cat.cat_tv.gotoAndStop(0);
            });
            explain.cat.addEventListener("click", async () => {
                explain.cat.cat_tv.gotoAndStop("pressed");
                playSound("wrong");
                // explain.cat.cat_tv.gotoAndStop("normal");
            });
        });
    };
    showLeteer12Explains = () => {
        return new Promise<void>(async (resolve) => {
            const explain = this.root.explain;
            const explain2 = this.root.explain2;
            explain.x = 600;
            explain.y = 520;
            explain.visible = true;
            if (!this.shouldPlay) return;
            explain.gotoAndPlay(1);
            if (!this.shouldPlay) return;
            await waitForEvent(explain, "animationEnd");
            explain.visible = false;
            explain2.x = 850;
            explain2.visible = true;
            explain2.gotoAndStop(0);
            stopAllSounds();
            if (!this.shouldPlay) return;
            explain2.gotoAndPlay(1);
            this.showDefinition("לב שבור");
            if (!this.shouldPlay) return;
            await waitForEvent(explain2, "animation1End");
            if (!this.shouldPlay) return;
            explain2.gotoAndPlay(259);
            this.showDefinition("שם לב");
            if (!this.shouldPlay) return;
            await waitForEvent(explain2, "animation2End");
            if (!this.shouldPlay) return;
            resolve();
        });
    };
}
