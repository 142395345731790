import { MC } from "../../animate/utils";
import { CreationGame } from "../CreationGame";

export class ColorsStore {
    constructor(private root: MC, private creationGame: CreationGame) {
        this.start();
    }
    start = () => {
        this.root.panel_colors.children.forEach((colorBtn: MC, i: number) => {
            colorBtn.addEventListener("click", () => {
                this.creationGame.setChooseColor(this.map[colorBtn.name]);
                this.root.curColor.gotoAndStop(i + 1);
                if (this.creationGame.getEditMode()) {
                    this.creationGame.selectNewStore(this.root.btnColor);
                }
            });
        });
    };
    private map: any = {
        c1: "#404040",
        c2: "#AAAAAA",
        c3: "#FDFDFD",
        c4: "#AD0203",
        c5: "#FF3535",
        c6: "#FFB0A6",
        c7: "#CC6101",
        c8: "#FF9921",
        c9: "#F9D08E",
        c10: "#DE8C07",
        c11: "#FFD521",
        c12: "#FCF389",
        c13: "#2B8F00",
        c14: "#73DF21",
        c15: "#DAFC83",
        c16: "#127BBA",
        c17: "#75B4FD",
        c18: "#C6F1F9",
        c19: "#90388B",
        c20: "#DD54F0",
        c21: "#F5C2FF",
    };
}
