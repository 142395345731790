export const additionalSounds = [
    {
        src: "sounds/elephant.mp3",
        id: "elephant",
    },
    {
        src: "sounds/clown.mp3",
        id: "clown",
    },
];
