import React from "react";
import { useParams } from "react-router-dom";
import AnimateCC from "../../animate";
import { PicGame } from "../../lunapark/rocket/PicGame";
import { PuzzleGame } from "../../lunapark/rocket/PuzzleGame";
import { PuzzleFrame } from "./PuzzleFrame";
const BiblePuzzleComponent: React.FC<{ frame: PuzzleFrame; type: string }> = ({ frame, type }) => {
    const { index } = useParams<{ index: string }>();
    const puzzle_num = (parseInt(type) - 1) * 4 + parseInt(index);
    const isPicGame = puzzle_num % 4 === 1 || puzzle_num % 4 === 2;
    const pic_game = React.useRef(new PicGame(frame, true));
    const puzzle_game = React.useRef(new PuzzleGame(frame, true));

    return (
        <>
            <AnimateCC
                key={Date.now()}
                base={`/bible_stories/puzzles/pic_${puzzle_num}`}
                animationName={`bible_pic_${puzzle_num}`}
                onLoaded={isPicGame ? pic_game.current.loaded : puzzle_game.current.loaded}
                scale={0.6}
                shiftX={300}
                shiftY={100}
            />
        </>
    );
};
export default BiblePuzzleComponent;
