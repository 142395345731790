import { MC, setupStage, playSound } from "../../animate/utils";

export class MirrorsGame {
    private girl: MC;
    private giraffe: MC;
    private girlMouseUp: boolean = false;
    private giraffeMouseUp: boolean = false;
    private giraffeScale: number;
    private girlScale: number;
    private moveGirlX: number;
    private moveGirlY: number;
    private moveGiraffeX: number;
    private moveGiraffeY: number;
    constructor(private frame: any) {}
    private root: MC;
    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        (window as any).MirrorsGame = mc;
        (window as any).game = this;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.girl = this.root.girl_back;
        this.giraffe = this.root.giraffe_back;
        this.start();
        this.giraffe.addEventListener("mousedown", () => {
            playSound("click");
            const localMouse = this.root.globalToLocal(
                this.root.stage.mouseX,
                this.root.stage.mouseY
            );
            this.moveGiraffeX = localMouse.x - this.giraffe.x;
            this.moveGiraffeY = localMouse.y - this.giraffe.y;
            if (this.giraffeMouseUp === false) {
                this.giraffeMouseUp = true;
                this.girlMouseUp = false;
            } else {
                this.giraffeMouseUp = false;
            }
        });
        this.girl.addEventListener("mousedown", () => {
            playSound("click");
            const localMouse = this.root.globalToLocal(
                this.root.stage.mouseX,
                this.root.stage.mouseY
            );
            this.moveGirlX = localMouse.x - this.girl.x;
            this.moveGirlY = localMouse.y - this.girl.y;
            if (this.girlMouseUp === false) {
                this.girlMouseUp = true;
                this.giraffeMouseUp = false;
            } else {
                this.girlMouseUp = false;
            }
        });
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (this.girlMouseUp === true) {
                this.moveGirl();
            }
            if (this.giraffeMouseUp === true) {
                this.movegiraffe();
            }
        });
    };
    start = () => {
        const girlX = this.girl.x + 91;
        const girlY = this.girl.y - 15;
        const giraffeX = this.giraffe.x + 91;
        const giraffeY = this.giraffe.y - 15;
        this.girl = this.root.girl_back;
        this.girl.scale = 1 + this.girl.x / 20 / 100 + this.girl.y / 20 / 100;
        this.root.regular_girl.scale = this.girl.scale;
        this.giraffe = this.root.giraffe_back;
        this.giraffe.scale =
            1 + this.giraffe.x / 20 / 100 + this.giraffe.y / 20 / 100;
        this.root.upside_giraffe.scale = this.giraffe.scale;
        this.root.regular_girl.x = girlX;
        this.root.regular_girl.y = girlY;
        this.root.tall_girl.x = girlX;
        this.root.tall_girl.y = girlY;
        this.root.short_girl.x = girlX;
        this.root.short_girl.y = girlY;
        this.root.upside_girl.x = girlX;
        this.root.upside_girl.y = girlY;
        this.root.giraffe_tall.x = giraffeX;
        this.root.giraffe_tall.y = giraffeY;
        this.root.giraffe_short.x = giraffeX;
        this.root.giraffe_short.y = giraffeY;
        this.root.upside_giraffe.x = giraffeX;
        this.root.upside_giraffe.y = giraffeY;
    };
    moveGirl = () => {
        const localMouse = this.root.globalToLocal(
            this.root.stage.mouseX,
            this.root.stage.mouseY
        );
        this.girl.x = localMouse.x - this.moveGirlX;
        this.girl.y = localMouse.y - this.moveGirlY;
        const girlX = this.girl.x + 91;
        const girly = this.girl.y - 15;
        this.girlScale = 1 + this.girl.x / 20 / 100 + this.girl.y / 20 / 100;
        this.girl.scale = this.girlScale;
        this.root.regular_girl.scale = this.girlScale;
        this.root.upside_girl.scale = this.girlScale;
        this.root.regular_girl.x = girlX;
        this.root.regular_girl.y = girly;
        this.root.tall_girl.x = girlX;
        this.root.tall_girl.y = girly;
        this.root.short_girl.x = girlX;
        this.root.short_girl.y = girly;
        this.root.upside_girl.x = girlX;
        this.root.upside_girl.y = girly;
    };
    movegiraffe = () => {
        this.giraffeScale =
            1 + this.giraffe.x / 20 / 100 + this.giraffe.y / 20 / 100;
        this.giraffe.scale = this.giraffeScale;
        const giraffeX = this.giraffe.x + 91;
        const giraffeY = this.giraffe.y - 15;
        const localMouse = this.root.globalToLocal(
            this.root.stage.mouseX,
            this.root.stage.mouseY
        );
        this.giraffe.x = localMouse.x - this.moveGiraffeX;
        this.giraffe.y = localMouse.y - this.moveGiraffeY;
        this.root.giraffe_reg.scale = this.giraffeScale;
        this.root.upside_giraffe.scale = this.giraffeScale;
        this.root.giraffe_reg.x = giraffeX;
        this.root.giraffe_reg.y = giraffeY;
        this.root.giraffe_tall.x = giraffeX;
        this.root.giraffe_tall.y = giraffeY;
        this.root.giraffe_short.x = giraffeX;
        this.root.giraffe_short.y = giraffeY;
        this.root.upside_giraffe.x = giraffeX;
        this.root.upside_giraffe.y = giraffeY;
    };
}
