import { asyncTween, MC, playSound } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game14 extends BaseGame {
    helpBtn: any;
    answersCounter = 0;
    correctAnswersButtons: any = [4, 0, 6];
    catToButtons = [
        { btnIndex: 0, catIndex: 0 },
        { btnIndex: 4, catIndex: 1 },
        { btnIndex: 6, catIndex: 2 },
    ];
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.helpBtn = helpBtn;
        game.visible = true;
        game.scaleX = 1.3;
        game.scaleY = 1.3;
        game.x = 860;
        game.y = 450;
        game.gotoAndStop(0);
        for (let i = 0; i < 3; i++) {
            game[`cat${i}`].alpha = 0;
        }
        for (let i = 0; i < 7; i++) {
            const currentBtn = game[`btn${i}`];
            currentBtn.gotoAndStop(0);

            currentBtn.cursor = "pointer";
            currentBtn.addEventListener("click", () => {
                this.clickOnBtn(game, gameEnd, game[`btn${i}`], i);
            });

            currentBtn.addEventListener("rollover", () => {
                currentBtn.gotoAndStop("over");
            });
            currentBtn.addEventListener("rollout", () => {
                currentBtn.gotoAndStop("normal");
            });
        }
        await playSound("game_14");
        if (!this.shouldPlay) return;
        helpBtn(false);
    };
    clickOnBtn = async (game: MC, gameEnd: () => void, currentBtn: MC, btnIndex: number) => {
        this.helpBtn(true);
        game.mouseChildren = false;
        currentBtn.gotoAndStop("down");
        if (this.correctAnswersButtons.includes(btnIndex)) {
            this.answersCounter++;
            let catToShow: any;
            for (let i = 0; i < 3; i++) {
                await asyncTween(game[`cat${i}`], { alpha: 0 }, 300);
                if (!this.shouldPlay) return;
            }
            this.catToButtons.forEach((obj) => {
                if (obj.btnIndex === btnIndex) {
                    catToShow = obj.catIndex;
                }
            });
            asyncTween(game[`cat${catToShow}`], { alpha: 1 }, 300);
            game[`cat${catToShow}`].gotoAndPlay(1);
            await playSound(`cat_${catToShow}`);
            if (!this.shouldPlay) return;
            game.mouseChildren = true;
            this.helpBtn(false);
            if (this.answersCounter === 3) {
                gameEnd();
            }
        } else {
            await playSound("wrong");
            if (!this.shouldPlay) return;
            game.mouseChildren = true;
            this.helpBtn(false);
        }
    };
}
