import { Quiz } from "./Quiz";
import { Kite } from "../kites/Kite";
import { Fishes } from "../Fishes/Fishes";
import { Boxes } from "../Boxes/Boxes";
import { playSound, setupStage, MC } from "../../../animate/utils";
export class QuizGame {
    mode: string;
    answerIndex: number = 0;
    answerStatus: string;
    gameIndex: number = 0;
    enabledButton: boolean = false;
    private games = [
        ["kites", "boxes"],
        ["kites", "boxes"],
        ["kites", "boxes", "fishes"],
        ["kites", "boxes", "fishes"],
        ["kites", "boxes", "fishes"],
    ];

    constructor(
        private match: any,
        private sectionIndex: any,
        private numberOfQuestion: number,
        private setCurrentGame: (game: string) => void
    ) {}

    enabledButtonFunc = (status: boolean) => {
        this.enabledButton = status;
    };
    addPrizes = async (gameName: string, questionIndex: number, answerStatus: string) => {
        this.answerStatus = answerStatus;
        if (gameName === "kites") {
            this.answerIndex = questionIndex;
        } else if (gameName === "boxes") {
            this.answerIndex = questionIndex + this.numberOfQuestion;
        } else {
            this.answerIndex = questionIndex + this.numberOfQuestion + this.numberOfQuestion;
        }
        this.quiz.addResult(this.answerStatus, this.answerIndex);
        if (questionIndex === this.numberOfQuestion) {
            this.moveToNextGame(gameName);
        }
    };
    showBg = (gameName: string) => {
        console.log("gameName", gameName);

        const bg = this.quiz.root.splash;
        const box_bg = this.quiz.root.boxes_bg;
        bg.children.forEach((child: MC) => {
            child.visible = false;
        });
        bg.bg.cloudes.visible = false;
        if (gameName === "kites") {
            bg.bg.visible = true;
            bg.visible = true;
        } else {
            box_bg.visible = true;
        }
    };

    hideBg = () => {
        this.quiz.root.splash.visible = false;
        this.quiz.root.boxes_bg.visible = false;
    };

    moveToNextGame = async (gameName: string) => {
        this.showBg(gameName);
        this.gameIndex++;
        this.setCurrentGame(this.games[this.sectionIndex - 1][this.gameIndex]);
        this.quiz.root.help.removeAllEventListeners();
        if (gameName === "kites") {
            await this.Kite.quizModeEndOfGame().then(() => {
                this.Kite.root.backgtound.visible = false;
            });
        } else if (gameName === "boxes") {
            if (this.numberOfQuestion === 6) {
                this.endOfQuiz();
            }
        }
        if (gameName === "fishes") {
            this.fishes.unLoadad();
            //this.hideBg();
            this.endOfQuiz();
        }
    };
    endOfQuiz = async () => {
        const bg = this.quiz.root.splash;
        bg.children.forEach((child: MC) => {
            child.visible = true;
        });
        if (bg.instTotalPrize) {
            if (bg.instTotalPrize.fbKite) {
                bg.instTotalPrize.fbKite.visible = false;
            }
            if (bg.instTotalPrize.rainbow) {
                bg.instTotalPrize.rainbow.visible = false;
            }
        }
        this.quiz.root.boxes_bg.visible = false;
        bg.visible = true;
        this.quiz.endOfGame();
    };

    setHelpSound = async (helpSound: string) => {
        this.quiz.root.help.addEventListener("click", async () => {
            if (this.enabledButton) {
                this.enabledButton = false;
                await playSound(helpSound);
                this.enabledButton = true;
            }
        });
        this.quiz.root.help.addEventListener("rollover", async () => {
            if (this.enabledButton) {
                this.enabledButton = false;
                await playSound("help");
                this.enabledButton = true;
            }
        });
    };
    private quiz: Quiz = null;
    quizLoaded = async (mc: createjs.MovieClip, lettersContext: any) => {
        this.quiz = new Quiz(mc, this.sectionIndex, lettersContext);
        await this.quiz.loaded().then(() => {
            this.setCurrentGame("kites");
        });
    };

    private Kite: Kite = null;
    kiteLoaded = (mc: createjs.MovieClip) => {
        this.hideBg();
        this.Kite = new Kite(
            mc,
            this.sectionIndex,
            null,
            this.numberOfQuestion,
            (this.mode = "quiz"),
            this.addPrizes,
            this.setHelpSound,
            this.enabledButtonFunc
        );
        setupStage(this.quiz.root, mc);
    };

    private fishes: Fishes = null;
    fishesLoaded = (mc: createjs.MovieClip) => {
        this.hideBg();
        this.fishes = new Fishes(
            mc,
            this.match,
            this.sectionIndex,
            null,
            this.numberOfQuestion,
            (this.mode = "quiz"),
            this.addPrizes,
            this.setHelpSound,
            this.enabledButtonFunc
        );
        setupStage(this.quiz.root, mc);
    };

    private boxes: Boxes = null;
    boxLoaded = (mc: createjs.MovieClip) => {
        this.hideBg();
        this.boxes = new Boxes(
            mc,
            this.match,
            this.sectionIndex,
            null,
            this.numberOfQuestion,
            (this.mode = "quiz"),
            this.addPrizes,
            this.setHelpSound,
            this.enabledButtonFunc
        );

        setupStage(this.quiz.root, mc);
    };

    unload = () => {
        if (this.boxes) {
            this.boxes.unloaded();
        }
    };
}
