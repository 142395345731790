import React from "react";
import AnimateCC from "../../../animate";
import { NumberSevenGame } from "./NumberSevenGame";
import additionalSounds from "../BigWheelSounds";
import { BigWheelGame } from "../BigWheelGame";
const NumberSevenComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberSevenGame(frame));

    return (
        <AnimateCC
            base="/lunapark/big_wheel/number_seven"
            animationName="number_seven"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default NumberSevenComponent;
