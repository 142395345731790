import { routes } from "../../navigation/routesPath";
import {
    localToGlobal,
    MC,
    playSound,
    playSoundSync,
    stopAllSounds,
    waitForEvent,
    waitForTimer,
} from "../../animate/utils";
import { SoundToolTip } from "../../utils/soundTooltip";
import { Navigation } from "../../navigation/navigation";

const lettersArray = [
    [1, 2, 3, 4],
    [5, 6, 7, 8],
    [9, 10, 11, 12],
    [13, 14, 15, 16, 17],
    [18, 19, 20, 21, 22],
];

export class LettersIsland {
    root: MC;
    bgSound: any = null;
    currentIndex: number;
    dataStatus: { letters: boolean[]; boxes: boolean[] };
    disabledFancyWords: boolean;
    objectToOver: MC;
    onHover: boolean = false;
    showMayaEvent: any;
    private _isLoggedIn: boolean;

    constructor(private history: any) {}

    initNavigator = (index: number) => {
        for (let i = 1; i <= 5; i++) {
            const currentBtn = this.root.navigator_mc[`navigator_btn${i}`];
            currentBtn.removeAllEventListeners();
            currentBtn.cursor = "default";
            if (index === i) {
                currentBtn.gotoAndStop(19);
            } else {
                currentBtn.gotoAndStop("normal");
                currentBtn.addEventListener("rollover", () => {
                    this.objectToOver = currentBtn;
                });
                currentBtn.addEventListener("rollout", () => {
                    this.objectToOver = null;
                });
            }
        }
    };
    chooseNavigator = (index: number) => {
        this.initNavigator(index);
        this.navigateTo(index);
    };
    startNavigation = async (i: number) => {
        clearTimeout(this.showMayaEvent);
        this.root.maya_and_meter_mc.visible = false;
        this.root.anims_mc.visible = false;
        this.root.foreground_mc.visible = true;
        await waitForTimer(400);
        this.root.letters_mc.visible = false;
        await waitForTimer(400);
        this.chooseNavigator(i);
    };
    initListeners = () => {
        for (let i = 1; i <= 5; i++) {
            const currentBtn = this.root.navigator_mc[`navigator_btn${i}`];

            if (this.currentIndex !== i) {
                this.throwEventListener(currentBtn, null, "over", "normal");
                currentBtn.addEventListener("click", () => {
                    this.startNavigation(i);
                });
            }
        }
        if (this.objectToOver && this.objectToOver !== this.root.navigator_mc[`navigator_btn${this.currentIndex}`]) {
            this.objectToOver.gotoAndStop("over");
        }
    };
    addLetterListeners = (currentLetter: MC, letter: number) => {
        this.animateToObj(currentLetter, () => {
            this.throwEventListener(currentLetter, `letter${letter}`, "over", "normal");
        });
        currentLetter.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.letters.activities.menu.replace(":letterIndex", letter - 1 + ""));
        });
    };
    initLetter = async (letter: number, currentObj: MC) => {
        const letterDone = this.dataStatus.letters[letter];
        const letterName = `letter_${letter}${letterDone ? "_done" : ""}`;
        const currentLetter = currentObj[letterName];
        currentLetter.scaleX = currentLetter.scaleY = 0.9;
        currentLetter.gotoAndStop(0);
        currentObj.setChildIndex(currentObj[`letter_shadow${letter}`], 0);
        currentObj.setChildIndex(currentLetter, 2);
        currentObj[`letter_${letter}_done`].visible = letterDone;
        currentObj[`letter_${letter}`].visible = !letterDone;
        currentObj[`letter_shadow${letter}`].alpha = 1;
        this.addLetterListeners(currentLetter, letter);
        return await waitForTimer(300);
    };

    resetLastIndex = () => {
        if (!this.currentIndex) return;
        const currentObj = this.root.letters_mc[`letters_set${this.currentIndex}`];
        const boxCompleted = this.dataStatus.boxes[this.currentIndex];
        currentObj.removeAllEventListeners();
        currentObj[`box${this.currentIndex}`][boxCompleted ? "done_box" : "normal_box"].removeAllEventListeners();
        currentObj.back_btn?.removeAllEventListeners();
        currentObj.next_btn?.removeAllEventListeners();

        for (const letter of lettersArray[this.currentIndex - 1]) {
            currentObj[`letter_${letter}`].removeAllEventListeners();
        }
    };

    navigateTo = async (index: number) => {
        const bgLocation = [-2355, -1155, 360, 1720, 3210];
        const fgLocation = [-4514, -3422, -2145, 130, 225];

        this.root.foreground_mc.visible = true;
        if (index === 1) {
            this.root.x = -5;
            this.root.top_mc.scaleX = 1;
            this.root.bottom_mc.scaleX = 1;
        } else if (this.currentIndex === 1) {
            this.root.top_mc.scaleX = 1.009;
            this.root.bottom_mc.scaleX = 1.009;
        }
        this.resetLastIndex();
        this.root.background_mc.scaleX = this.root.background_mc.scaleY = 1.0012;
        createjs.Tween.get(this.root.foreground_mc)
            .to({ x: fgLocation[index - 1] }, 1400, createjs.Ease.cubicOut)
            .call(async () => {});
        createjs.Tween.get(this.root.background_mc)
            .to({ x: bgLocation[index - 1] }, 1400, createjs.Ease.cubicOut)
            .call(async () => {
                this.showMayaEvent = setTimeout(async () => {
                    this.animateToObj(this.root.maya_and_meter_mc, null, 3000);
                    await waitForTimer(1000);
                }, 2200);
                this.currentIndex = index;
                this.root.letters_mc.children.forEach((c: MC) => {
                    c.visible = false;
                });
                this.showContent();
                const currentObj = this.root.letters_mc[`letters_set${index}`];
                const currentBox = this.root.letters_mc[`letters_set${index}`][`box${index}`];
                currentObj.visible = true;
                currentObj.children.forEach((c: MC) => {
                    if (c.name !== "arrow_mc") {
                        c.alpha = 0;
                    }
                });

                this.root.maya_and_meter_mc.gotoAndStop(index - 1);
                this.root.maya_and_meter_mc.alpha = 0;
                const boxCompleted = this.dataStatus.boxes[this.currentIndex];
                currentObj.back_btn && this.throwEventListener(currentObj.back_btn, "backward", 10, "normal");
                currentObj.next_btn && this.throwEventListener(currentObj.next_btn, "forward", 10, "normal");
                currentBox &&
                    this.throwEventListener(
                        boxCompleted ? currentBox.done_box : currentBox.normal_box,
                        "review",
                        "over",
                        "normal"
                    );
                this.root.anims_mc.gotoAndStop(this.currentIndex - 1);
                if (this.currentIndex === 1) {
                    this.root.foreground_mc.visible = false;
                }
                this.root.anims_mc.visible = true;
                this.initListeners();
                for (const letter of lettersArray[index - 1]) {
                    await this.initLetter(letter, currentObj);
                }
                currentObj.next_btn && this.animateToObj(currentObj.next_btn);
                await waitForTimer(300);
                if (currentBox) {
                    if (currentBox.holder_mc) currentBox.holder_mc.visible = false;

                    currentBox.done_box.visible = boxCompleted;
                    currentBox.normal_box.visible = !boxCompleted;
                    currentBox.gotoAndStop(0);
                    this.animateToObj(currentBox);
                    await waitForTimer(300);
                }
                currentObj.back_btn && this.animateToObj(currentObj.back_btn);
                await waitForTimer(300);

                currentObj.back_btn?.addEventListener("click", () => {
                    this.onHover = false;
                    this.startNavigation(index - 1);
                });
                currentObj.next_btn?.addEventListener("click", () => {
                    this.onHover = false;
                    this.startNavigation(index + 1);
                });
                currentBox.addEventListener("click", () => {
                    stopAllSounds();
                    Navigation.openPage(routes.letters.review.menu.replace(":sectionIndex", this.currentIndex + ""));
                });
            });
    };
    throwEventListener = (
        currentObj: MC,
        SoundParameterName: string,
        roollhoverParameter: string | number,
        rolloutParameter: string | number
    ) => {
        this.onHover = false;
        currentObj.gotoAndStop(0);
        currentObj.cursor = "pointer";
        if (SoundParameterName) {
            SoundToolTip.addToolTip(currentObj, SoundParameterName);
        }

        currentObj.addEventListener("rollover", async () => {
            this.onHover = true;
            if (roollhoverParameter) currentObj.gotoAndStop(roollhoverParameter);
        });
        currentObj.addEventListener("rollout", () => {
            this.onHover = false;
            if (rolloutParameter) currentObj.gotoAndStop(rolloutParameter);
        });
    };
    animateToObj = (currentObj: MC, callFuction?: () => void, timeParameter?: number) => {
        createjs.Tween.get(currentObj)
            .to({ alpha: 1 }, timeParameter ? timeParameter : 600, createjs.Ease.cubicOut)
            .call(() => {
                callFuction && callFuction();
            });
    };
    initBottomButtons = () => {
        let bottomButtons = ["book", "icecream", "tv"];
        const pathes = [
            routes.letters.fancy_words.menu,
            routes.letters.sweets,
            routes.letters.video.replace(":letterIndex", "22"),
        ];

        for (let i = 0; i < 3; i++) {
            const currentBtn = this.root[`${bottomButtons[i]}_btn`];
            currentBtn.cursor = "pointer";
            const currentBtnName = bottomButtons[i];
            currentBtn.gotoAndStop("normal");

            if (currentBtnName === "book" && this.disabledFancyWords) {
                currentBtn.gotoAndStop("disabled");
                currentBtn.cursor = "default";
                currentBtn.removeAllEventListeners();
            } else {
                currentBtn && this.throwEventListener(currentBtn, currentBtnName, "over", "normal");
                currentBtn.addEventListener("click", () => {
                    Navigation.openPage(pathes[i]);
                    stopAllSounds();
                });
            }
        }
    };
    getInitialIndex = () => {
        for (let index = 1; index <= 5; index++) {
            let isDone = true;
            if (!this.dataStatus.boxes[index]) {
                isDone = false;
            }
            lettersArray[index - 1].forEach((letterIndex) => {
                if (!this.dataStatus.letters[letterIndex]) {
                    isDone = false;
                }
            });
            if (!isDone) {
                return index;
            }
        }
        return 1;
    };

    initShowOnSetup = async () => {
        this.initBg(this.root.top_mc, 500, ["navigator_mc", "back_btn"]);
        await waitForTimer(500);
        this.root.foreground_mc.visible = true;
        this.root.letters_mc.children.forEach((c: MC) => {
            c.visible = false;
        });
        this.root.maya_and_meter_mc.alpha = 0;
        this.chooseNavigator(this.getInitialIndex());
        this.initBg(this.root.background_mc, 500);
        await waitForTimer(500);

        this.initBottomButtons();
        this.initBg(this.root.bottom_mc, 500, ["icecream_btn", "book_btn", "tv_btn"]);

        return await waitForTimer(500);
    };

    showContent = async () => {
        let showOnSetup = [
            "middle_mc",
            "navigator_mc",
            "foreground_mc",
            "book_btn",
            "tv_btn",
            "maya_and_meter_mc",
            "letters_mc",
        ];

        showOnSetup.forEach((name: string) => {
            this.root[name].visible = true;
        });
    };
    initBg = (item: MC, timeParameter: number, showOnSetup?: string[]) => {
        showOnSetup?.forEach((name: string) => {
            this.root[name].visible = true;
        });
        item.visible = true;
        item.alpha = 0;
        this.animateToObj(item, null, timeParameter);
    };
    showObject = (item: MC) => {
        item.visible = true;
        item.alpha = 0;
        return new Promise((resolve) =>
            createjs.Tween.get(item).to({ alpha: 1 }, 300, createjs.Ease.cubicOut).call(resolve)
        );
    };

    doneLetter = async (letter: number) => {
        this.root.test_letter.visible = true;
        let setIndex = 0;
        for (let index = 0; index < 5; index++) {
            if (lettersArray[index].includes(letter)) {
                setIndex = index + 1;
            }
        }
        const currentObj = this.root.letters_mc[`letters_set${setIndex}`][`letter_${letter}_done`];
        this.root.test_letter.scale = 0.9;
        this.root.letters_mc[`letters_set${setIndex}`][`letter_${letter}`].visible = false;
        currentObj.visible = false;
        const xPos = [40, 40, 45, -10, -10];
        const yPos = [15, 15, 20, -40, -40];
        const x = localToGlobal(currentObj, 0, 0).x + xPos[setIndex - 1];
        const y = localToGlobal(currentObj, 0, 0).y + yPos[setIndex - 1];
        this.root.test_letter.x = x;
        this.root.test_letter.y = y;
        this.root.test_letter.normal_letters_mc.gotoAndStop(letter - 1);
        this.root.test_letter.done_letters_mc.gotoAndStop(letter - 1);
        this.root.test_letter.letters_shadows_mc.gotoAndStop(letter - 1);
        this.root.test_letter.gotoAndPlay("change");
        this.root.test_letter.normal_letters_mc.gotoAndStop(letter - 1);

        await waitForEvent(this.root.test_letter, "animationEnd");
        this.root.test_letter.visible = false;
        currentObj.visible = true;
        currentObj.alpha = 1;
        currentObj.gotoAndStop(0);
        this.dataStatus.letters[letter] = true;
        this.addLetterListeners(currentObj, letter);
    };

    doneBox = (setIndex: number) => {
        const currentBox = this.root.letters_mc[`letters_set${setIndex}`][`box${setIndex}`];
        currentBox.done_box.visible = true;
        currentBox.normal_box.visible = false;
        currentBox.gotoAndStop(1);
    };

    loaded = async (mc: MC, dataStatus: any, disabledFancyWords: boolean[]) => {
        this.root = mc;
        (window as any).menu = this;
        this.dataStatus = dataStatus;
        this.disabledFancyWords = !disabledFancyWords.some((value) => value);
        this.root.x = -5;
        this.root.back_btn.x = 10;
        SoundToolTip.addToolTip(this.root.back_btn, "/letters_island/review_menu/sounds/back.mp3", true);
        this.root.gotoAndStop(1);
        this.root.children.forEach((child: MC) => {
            if (child.name !== "middle_mc") {
                child.visible = false;
            }
        });
        this.updateGuestArrow();

        this.root.background_mc.x = -2355;
        this.root.foreground_mc.x = -4514;
        await this.initShowOnSetup();
        this.root.anims_mc.gotoAndStop(0);
        this.showContent();
        // this.doneLetter()
        if (this.history.location.pathname === routes.letters.menu) {
            playSound("welcome").then(() => {
                if (this.history.location.pathname === routes.letters.menu) {
                    this.bgSound = playSoundSync("bg", { loop: -1 });
                    this.bgSound.volume = 0.6;
                }
            });
        }
        for (let i = 0; i < 5; i++) {
            const currentBtn = this.root.navigator_mc[`navigator_btn${i + 1}`];
            const xArray = [0, 0, 0, -24, 0];
            const wArray = [129, 110, 110, 150, 150];
            const hArray = [22.35, 25, 21.5, 23.6, 22];
            const hit = new createjs.Shape();
            hit.graphics.beginFill("#000").drawRect(xArray[i], -2, wArray[i], hArray[i]);
            currentBtn.hitArea = hit;
        }
        this.throwEventListener(this.root.back_btn, null, "over", "normal");
        this.root.back_btn.addEventListener("click", () => {
            this.root.back_btn.gotoAndStop("pressed");
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
    };

    private updateGuestArrow() {
        if (!this.root) return;

        const { arrow_mc } = this.root.letters_mc.letters_set1;

        if (this._isLoggedIn) {
            arrow_mc.visible = false;
            arrow_mc.removeAllEventListeners();
        } else {
            arrow_mc.visible = true;
            arrow_mc.alpha = 1;
            arrow_mc.gotoAndPlay(0);
            arrow_mc.pointer = "cursor";
            arrow_mc.addEventListener("click", () => {
                Navigation.openPage(routes.letters.activities.menu.replace(":letterIndex", "0"));
            });
        }
    }

    set isLoggedIn(value: boolean) {
        this._isLoggedIn = value;
        this.updateGuestArrow();
    }

    unload = () => {};
}
