import MusicGameBase from "../musicGame/MusicGameBase";
import { waitForEvent, MC, playSound, stopAllSounds, asyncTween } from "../../animate/utils";
import { getRandomArr, shuffle } from "../../utils/MathUtils";
import { loadSound } from "../../animate/sound_utils";
const data = [[], [4, 2, 60, -30, 45, 60, 1.5], [4, 3, 160, -20, 30, 45, 1.1], [6, 3, 55, -25, 30, 45, 1.1]];
const instruments = [
    "piano",
    "accordion",
    "tuba",
    "sax",
    "drum",
    "harp",
    "violin",
    "darbuka",
    "trumpet",
    "oud",
    "acoustic-guitar",
    "xylophone",
    "electronic-guitar",
    "harmonica",
    "flute",
    "cello",
];
export class Memory extends MusicGameBase {
    name = "memory";
    tickets: MC[] = [];
    level: number = 1;
    firstOpened: MC = null;
    donePairs = 0;

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).memory = root;
    }

    loadSounds = () => {
        instruments.forEach((inst) => {
            loadSound(`/music/sounds/memory/${inst}.mp3`);
            loadSound(`/music/sounds/memory/w_${inst}.mp3`);
        });
    };

    clearTickets = () => {
        stopAllSounds();
        this.root.cardsContainer.removeAllChildren();
        this.tickets = [];
    };

    enableCard = (card: MC) => {
        card.cursor = "pointer";
        card.addEventListener("click", () => this.open(card));
        card.addEventListener("rollover", () => {
            card.gotoAndPlay("rollover");
        });
        card.addEventListener("rollout", () => {
            card.gotoAndPlay("close");
        });
    };
    disableCard = (card: MC) => {
        card.cursor = "default";
        card.removeAllEventListeners();
    };

    initTicket = (levelData: any, i: number, j: number, instIndex: number) => {
        const card = new this.root.lib[`card${instIndex + 1}`]();
        card.scale = levelData[6];
        card.x = levelData[2] + (66 * card.scale + levelData[4]) * i;
        card.y = levelData[3] + (96 * card.scale + levelData[5]) * j;
        const currentInst = instruments[instIndex];
        card.name = currentInst;
        this.enableCard(card);
        this.root.cardsContainer.addChild(card);
        this.tickets.push(card);
    };

    createTickets = () => {
        this.donePairs = 0;
        const instrumentsNum = (data[this.level][0] * data[this.level][1]) / 2;
        const currentInstruments = getRandomArr(0, 15, instrumentsNum);
        const shuffleArr = shuffle(currentInstruments.concat(currentInstruments));
        let cardIndex = 0;
        const levelData = data[this.level];

        for (let i = levelData[0]; i > 0; i--) {
            for (let j = levelData[1]; j > 0; j--) {
                this.initTicket(levelData, i, j, shuffleArr[cardIndex]);
                cardIndex++;
            }
        }
    };

    handleBigSuccess = async () => {
        this.root.animation.visible = true;
        this.root.animation.gotoAndPlay(0);
        await waitForEvent(this.root.animation, "openingEnded");
        this.root.animation.visible = false;
        this.createTickets();
    };

    disableAllCards = () => {
        this.tickets.forEach((ticket) => {
            if (ticket !== this.firstOpened) {
                this.disableCard(ticket);
            }
        });
    };

    enableAllCards = () => {
        this.tickets.forEach((ticket) => {
            if (ticket !== this.firstOpened) {
                this.enableCard(ticket);
            }
        });
    };

    removeCard = (card: MC) => {
        card.gotoAndPlay("remove");
        waitForEvent(card, "removed").then(() => {
            this.root.cardsContainer.removeChild(card);
        });
        this.tickets.splice(this.tickets.indexOf(card), 1);
    };

    openCard = async (card: MC) => {
        await asyncTween(card, { scaleX: 0.25 }, 250);
        card.gotoAndPlay("open");
        return asyncTween(card, { scaleX: card.scaleY }, 250);
    };

    closeCard = async (card: MC) => {
        await asyncTween(card, { scaleX: 0.25 }, 250);
        card.gotoAndPlay("close");
        return asyncTween(card, { scaleX: card.scaleY }, 250);
    };
    open = async (card: MC) => {
        const instSound = card.name;
        this.disableAllCards();
        await this.openCard(card);
        this.disableCard(card);
        if (this.firstOpened) {
            this.disableHelp();
            stopAllSounds();
            if (this.firstOpened.name === card.name) {
                this.donePairs++;
                await playSound(`/music/sounds/memory/w_${instSound}.mp3`);
                this.enableHelp();
                this.removeCard(card);
                this.removeCard(this.firstOpened);
                const instrumentsNum = (data[this.level][0] * data[this.level][1]) / 2;
                if (this.donePairs === instrumentsNum) {
                    await waitForEvent(card, "removed");
                    this.handleBigSuccess();
                }
            } else {
                await playSound(`/music/sounds/memory/${instSound}.mp3`);
                this.enableHelp();
                this.closeCard(this.firstOpened);
                this.closeCard(card);
            }
            this.firstOpened = null;
        } else {
            playSound(`/music/sounds/memory/${instSound}.mp3`);
            this.firstOpened = card;
        }
        this.enableAllCards();
    };

    handleLevel = (level: number) => {
        this.level = level;
        if (this.root.animation.visible === true) return;
        this.clearTickets();
        this.createTickets();
    };
    loaded = async (level: number) => {
        if (level) this.level = level;
        this.loadSounds();
        loadSound("/music/sounds/memory/Memory.mp3");
        await waitForEvent(this.root.animation, "openingEnded");
        this.root.animation.visible = false;
        playSound("/music/sounds/memory/Memory.mp3");
        this.createTickets();
    };
}
