import { MC, waitForEvent, playSound, asyncTween } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game8 extends BaseGame {
    helpBtn: any;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.helpBtn = helpBtn;
        game.visible = true;
        game.x = 530;
        game.alpha = 0;
        await asyncTween(game, { alpha: 1 }, 300);
        if (!this.shouldPlay) return;
        game.gotoAndPlay(10);
        await waitForEvent(game, "animationEnd");
        if (!this.shouldPlay) return;
        helpBtn(false);
        game.children.forEach((child: MC) => {
            child.addEventListener("click", () => {
                this.checkIfCorrectAnswer(game, gameEnd, child);
            });
        });
    };
    checkIfCorrectAnswer = async (game: MC, gameEnd: () => void, currentPic: MC) => {
        this.helpBtn(true);
        if (currentPic.children[0].libary_pic) {
            game.gotoAndPlay(111);
            await waitForEvent(game, "gameEnd");
            if (!this.shouldPlay) return;
            gameEnd();
        } else {
            await playSound("wrong");
            this.helpBtn(false);
        }
    };
}
