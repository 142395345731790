import React from "react";
import AnimateCC from "../../../animate";
import { RocketMenu } from "../RocketMenu";
import { VeggiesGame } from "./VeggiesGame";

const VeggiesComponent: React.FC<{
    frame: RocketMenu;
}> = ({ frame }) => {
    const game = React.useRef(new VeggiesGame(frame));

    return (
        <AnimateCC
            base="/lunapark/rocket/veggies"
            animationName="veggies"
            scale={0.75}
            shiftX={130}
            shiftY={5}
            onLoaded={game.current.loaded}
        />
    );
};
export default VeggiesComponent;
