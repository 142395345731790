export function getRandomNumber(min = 1, max = 9) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getRandomArr = (min, max, length) => {
    const randomArr = [];
    let index;
    for (let i = 0; i < length; i++) {
        do {
            index = getRandomNumber(min, max);
        } while (randomArr.includes(index));
        randomArr.push(index);
    }
    return randomArr;
};

export function uid() {
    return Array(6)
        .fill(0)
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join("");
}

export const shuffle = (array) => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
};
