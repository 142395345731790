import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { SeekerseGame } from "./SeekersGame";

const SeekersGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new SeekerseGame());
    return (
        <>
            <AnimateCC
                base="/special_games/seekers"
                animationName="seekers"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                }}
                shiftY={10}
                scale={0.95}
            />
        </>
    );
};
export default SeekersGameComponent;
