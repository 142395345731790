import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { HipomazeGame } from "./HipomazeGame";

const HipomazeGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new HipomazeGame());
    return (
        <>
            <AnimateCC
                base="/week_games/hipomaze_game"
                animationName="hipomaze"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                }}
                shiftY={30}
                shiftX={30}
                scale={0.88}
            />
        </>
    );
};
export default HipomazeGameComponent;
