import React, { useRef, useState } from "react";
import AnimateCC from "../../../animate";
import { letterMovies } from "../../../video/videoUrl";
import { getMayaFeedbackWithoutEffect } from "../../common/feedBacksSounds";
import { getMayaSoundsByLetter } from "../../common/lettersSounds";
import { VideoPlayer, VideoPlayerComponent } from "../../../video/VideoPlayer";
import { FindLetters } from "../findLetters/FindLetters";
import { isEndLetter } from "../LettersUtils";
import { routes } from "../../../navigation/routesPath";
import { displayIf } from "../../../utils/StyleUtils";
import { useParams } from "react-router-dom";
import { LetterActivitiesdGame } from "../LetterActivitiesGame";
import { useLettersContext } from "../../../context/LettersContext";

const VideoActivitiesComponent: React.FC<{ frame: LetterActivitiesdGame }> = ({ frame }) => {
    const myVideo = useRef<VideoPlayer>();

    const { letterIndex } = useParams<{ letterIndex: string }>();
    const lIndInt: number = parseInt(letterIndex);
    const lettersContext = useLettersContext();
    const src = letterMovies[lIndInt];
    const [animationBtnPlay, setAnimationBtnPlay] = useState<FindLetters>();
    const [screenReady, setScreenReady] = useState(false);

    const onEndFunction = () => {
        myVideo.current.restartVideo();
        frame.enableNext(
            isEndLetter(lIndInt + 1)
                ? routes.letters.activities.final_draw
                : routes.letters.activities.enter_exit.replace(":action", "exit")
        );
        animationBtnPlay.root.videoPlayer.btnPlay.gotoAndStop(2);
    };

    return (
        <>
            <VideoPlayerComponent
                src={src}
                ref={myVideo}
                width={426}
                height={320}
                frameWidth={1200}
                frameHeight={900}
                shiftX={-10}
                shiftY={-50}
                onEndFunction={onEndFunction}
                style={displayIf(screenReady)}
            />
            <AnimateCC
                base="/letters_island/find_letters"
                animationName="find_letters"
                onLoaded={(mc: createjs.MovieClip) => {
                    setScreenReady(true);
                    setAnimationBtnPlay(
                        frame.findLettersLoaded(
                            mc,
                            myVideo.current.pausePlay,
                            myVideo.current.restartVideo,
                            lettersContext
                        )
                    );
                }}
                additionalSounds={[...getMayaFeedbackWithoutEffect(), ...getMayaSoundsByLetter(lIndInt + 1)]}
                shiftX={190}
                shiftY={155}
                frameWidth={1200}
                frameHeight={900}
            />
        </>
    );
};

export default VideoActivitiesComponent;
