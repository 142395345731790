const additionalSounds = [
    {
        src: "sounds/boingwav.mp3",
        id: "trampHit",
    },
    {
        src: "sounds/Game11.mp3",
        id: "trampFall",
    },
];

export default additionalSounds;
