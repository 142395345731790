import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { GlassGame } from "./GlassGame";

const GlassComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new GlassGame(frame));

    return <AnimateCC base="/lunapark/abc/glass" animationName="glass" onLoaded={game.current.loaded} />;
};
export default GlassComponent;
