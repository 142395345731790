export const feedbacksSounds = [
    {
        src: "/letters_island/common/feedBacks/Bad1.mp3",
        id: "Bad1",
    },
    {
        src: "/letters_island/common/feedBacks/Bad2.mp3",
        id: "Bad2",
    },
    {
        src: "/letters_island/common/feedBacks/Bad0.mp3",
        id: "Bad0",
    },
    {
        src: "/letters_island/common/feedBacks/Good1.mp3",
        id: "Good1",
    },
    {
        src: "/letters_island/common/feedBacks/Good2.mp3",
        id: "Good2",
    },
    {
        src: "/letters_island/common/feedBacks/Good3.mp3",
        id: "Good3",
    },
    {
        src: "/letters_island/common/feedBacks/Good4.mp3",
        id: "Good4",
    },
    {
        src: "/letters_island/common/feedBacks/Good5.mp3",
        id: "Good5",
    },
    {
        src: "/letters_island/common/feedBacks/Good6.mp3",
        id: "Good6",
    },
    {
        src: "/letters_island/common/feedBacks/Good7.mp3",
        id: "Good7",
    },
    {
        src: "/letters_island/common/feedBacks/Good8.mp3",
        id: "Good8",
    },
    {
        src: "/letters_island/common/feedBacks/Good9.mp3",
        id: "Good9",
    },
    {
        src: "/letters_island/common/feedBacks/Good10.mp3",
        id: "Good10",
    },
    {
        src: "/letters_island/common/feedBacks/goodR0.mp3",
        id: "m_goodr0",
    },
    {
        src: "/letters_island/common/feedBacks/goodR2.mp3",
        id: "m_goodr1",
    },
    {
        src: "/letters_island/common/feedBacks/goodR4.mp3",
        id: "m_goodr2",
    },
    {
        src: "/letters_island/common/feedBacks/goodR6.mp3",
        id: "m_goodr3",
    },
    {
        src: "/letters_island/common/feedBacks/she/good1.mp3",
        id: "m_good1",
    },
    {
        src: "/letters_island/common/feedBacks/she/good2.mp3",
        id: "m_good2",
    },
    {
        src: "/letters_island/common/feedBacks/she/good3.mp3",
        id: "m_good3",
    },
    {
        src: "/letters_island/common/feedBacks/she/good4.mp3",
        id: "m_good4",
    },
    {
        src: "/letters_island/common/feedBacks/she/good5.mp3",
        id: "m_good5",
    },
    {
        src: "/letters_island/common/feedBacks/she/good6.mp3",
        id: "m_good6",
    },
    {
        src: "/letters_island/common/feedBacks/she/good7.mp3",
        id: "m_good7",
    },
    {
        src: "/letters_island/common/feedBacks/she/good8.mp3",
        id: "m_good8",
    },
    {
        src: "/letters_island/common/feedBacks/she/good9.mp3",
        id: "m_good9",
    },
];
export const myasFeedBacksSounds = (): any[] => {
    return [
        {
            src: "/letters_island/common/feedBacks/she/good0.mp3",
            id: "feedback0",
        },
        {
            src: "/letters_island/common/feedBacks/she/good1.mp3",
            id: "feedback1",
        },
        {
            src: "/letters_island/common/feedBacks/she/good2.mp3",
            id: "feedback2",
        },
        {
            src: "/letters_island/common/feedBacks/she/good3.mp3",
            id: "feedback3",
        },
        {
            src: "/letters_island/common/feedBacks/she/good4.mp3",
            id: "feedback4",
        },
        {
            src: "/letters_island/common/feedBacks/she/good5.mp3",
            id: "feedback5",
        },
        {
            src: "/letters_island/common/feedBacks/she/good6.mp3",
            id: "feedback6",
        },
        {
            src: "/letters_island/common/feedBacks/she/good7.mp3",
            id: "feedback7",
        },
    ];
};

export const getMayaFeedbackWithEffect = () => {
    return [
        {
            src: "/letters_island/common/feedBacks/she/m_good1.mp3",
            id: "m_good1",
        },
        {
            src: "/letters_island/common/feedBacks/she/m_good2.mp3",
            id: "m_good2",
        },
        {
            src: "/letters_island/common/feedBacks/she/m_good3.mp3",
            id: "m_good3",
        },
        // {
        //     src: "/letters_island/common/feedBacks/she/m_good4.mp3",
        //     id: "m_good4",},
        {
            src: "/letters_island/common/feedBacks/she/m_good5.mp3",
            id: "m_good4",
        },
        {
            src: "/letters_island/common/feedBacks/she/m_good6.mp3",
            id: "m_good5",
        },
        {
            src: "/letters_island/common/feedBacks/she/m_good7.mp3",
            id: "m_good6",
        },
        {
            src: "/letters_island/common/feedBacks/she/m_good8.mp3",
            id: "m_good7",
        },
        {
            src: "/letters_island/common/feedBacks/she/m_good9.mp3",
            id: "m_good8",
        },
        {
            src: "/letters_island/common/feedBacks/she/m_good10.mp3",
            id: "m_good9",
        },
    ];
};

export const getMayaFeedbackWithoutEffect = () => {
    return [
        {
            src: "/letters_island/common/feedBacks/she/good1.mp3",
            id: "m_good1",
        },
        {
            src: "/letters_island/common/feedBacks/she/good2.mp3",
            id: "m_good2",
        },
        {
            src: "/letters_island/common/feedBacks/she/good3.mp3",
            id: "m_good3",
        },
        {
            src: "/letters_island/common/feedBacks/she/good4.mp3",
            id: "m_good4",
        },
        {
            src: "/letters_island/common/feedBacks/she/good5.mp3",
            id: "m_good5",
        },
        {
            src: "/letters_island/common/feedBacks/she/good6.mp3",
            id: "m_good6",
        },
        {
            src: "/letters_island/common/feedBacks/she/good7.mp3",
            id: "m_good7",
        },
        {
            src: "/letters_island/common/feedBacks/she/good8.mp3",
            id: "m_good8",
        },
        {
            src: "/letters_island/common/feedBacks/she/good9.mp3",
            id: "m_good9",
        },
    ];
};
