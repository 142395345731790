import { generatePath } from "react-router-dom";
import { MC, playSound } from "../../animate/utils";
import { getBongoSound } from "../../lunapark/rocket/common";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { SoundToolTip } from "../../utils/soundTooltip";

export class PuzzleFrame {
    private root: MC;
    constructor(private type: string) {}
    loaded = (mc: MC) => {
        this.root = mc;
        this.root.gotoAndStop(0);
        this.initBackBtn();
        this.initBtns();
    };

    private initBtns = () => {
        [1, 2, 3, 4].forEach((index) => {
            const btn = this.root.puzzle_btns["btn" + index];
            btn.gotoAndStop(parseInt(this.type) - 1);
            btn.addEventListener("click", () => {
                playSound(getBongoSound());
                this.toggleInner(true);
                Navigation.openPage(
                    generatePath(routes.bible_stories.story_menu.puzzle.game, { type: this.type, index: index })
                );
            });
        });
    };

    initBackBtn = () => {
        const back_btn = this.root.btnBack;
        back_btn.gotoAndStop("up");
        back_btn.cursor = "pointer";
        back_btn.addEventListener("click", () => {
            playSound(getBongoSound());
            Navigation.openPage(generatePath(routes.bible_stories.story_menu.menu, { type: this.type }));
        });
        back_btn.addEventListener("mouseover", () => {
            back_btn.gotoAndStop("over");
        });
        back_btn.addEventListener("mouseout", () => {
            back_btn.gotoAndStop("up");
        });
        SoundToolTip.addToolTip(back_btn, "/bible_stories/sounds/back.mp3");
    };

    toggleInner = (val: boolean) => {
        this.root.board.visible = val;
    };
}
