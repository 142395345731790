export const versionInfo = {
    buildNumber: process.env.REACT_APP_BUILD_NUMBER,
    buildTime: process.env.REACT_APP_BUILD_TIME,
    gitBranch: process.env.REACT_APP_GIT_BRANCH,
    gitCommit: process.env.REACT_APP_GIT_COMMIT,
};

(window as any).sodmayaVersion = () => {
    console.table(versionInfo);
};
