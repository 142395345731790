import { MC, asyncTween, playSound, playSoundSync, waitForTimer } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game5 extends BaseGame {
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        game.visible = true;
        game.gotoAndPlay(0);
        for (let i = 0; i < 3; i++) {
            game.cardMc[`pic${i}`].alpha = 0;
            game[`btn${i}`].alpha = 0;
            game[`btn${i}`].gotoAndStop(0);
        }
        game.cardMc.greetingMc.alpha = 0;
        await waitForTimer(300);
        for (let i = 0; i < 3; i++) {
            if (!this.shouldPlay) return;
            playSound("books");
            await asyncTween(game[`btn${i}`], { alpha: 1 }, 500);
            await waitForTimer(1000);
        }
        await asyncTween(game.cardMc.greetingMc, { alpha: 1 }, 500);
        if (!this.shouldPlay) return;

        await playSound("Letter");
        if (!this.shouldPlay) return;

        await playSound(`instructions`);
        if (!this.shouldPlay) return;

        helpBtn();
        gameEnd();
        for (let i = 0; i < 3; i++) {
            const currentObj = game[`btn${i}`];
            currentObj.cursor = "pointer";
            currentObj.addEventListener("click", async () => {
                game.mouseChildren = false;
                currentObj.gotoAndStop("_down");
                await asyncTween(game.cardMc[`pic${i}`], { alpha: 1 }, 600);
                if (i === 1) {
                    if (!this.shouldPlay) return;

                    playSoundSync("pic1", { loop: -1 });
                } else {
                    if (!this.shouldPlay) return;

                    playSound(`pic${i}`);
                }

                await asyncTween(currentObj, { alpha: 0 }, 1000);
                if (!this.shouldPlay) return;

                game.mouseChildren = true;
            });
            currentObj.addEventListener("rollover", () => {
                if (!this.shouldPlay) return;

                playSound("Pum");
                currentObj.gotoAndStop("_over");
            });
            currentObj.addEventListener("rollout", () => {
                currentObj.gotoAndStop("_up");
            });
        }
    };
}
