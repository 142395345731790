export const frameSounds = [
    {
        src: "sounds/exit.mp3",
        id: "exit",
    },
    {
        src: "sounds/help.mp3",
        id: "help",
    },
];
export function getFrameSounds() {
    return [
        {
            src: "sounds/exit.mp3",
            id: "exit",
        },
        {
            src: "sounds/help.mp3",
            id: "helpBtn",
        },
        {
            src: "sounds/next.mp3",
            id: "next",
        },
    ];
}
