import { MC, playSound, playSoundSync, setupStage, waitForEvent, waitForTimer } from "../../../animate/utils";

export class RobotGame {
    private root: MC;
    private robot: MC;
    private doMouseOver: boolean = true;
    private paused: boolean = true;
    private canPause: boolean = false;
    private playWalk: boolean = false;
    private walkIsPlaying: boolean = false;

    constructor(private frame: any) {}

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).tiger = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.robot = this.root.robot;
        this.robot.gotoAndStop(0);
        this.start();
    };

    start = () => {
        this.robot.addEventListener("click", () => {
            this.animateRobot();
            this.robot.removeAllEventListeners(); //only happen once
        });

        this.root.addEventListener("tick", async () => {
            this.checkRobotUnderMouse();
            if (this.playWalk && !this.walkIsPlaying) {
                this.walkIsPlaying = true;
                const s1 = playSoundSync("walk");
                s1.volume = 0.9;
                await waitForEvent(this.root, "playSecond");
                await waitForTimer(10);
                const s2 = playSoundSync("walk");
                s2.volume = 0.9;
                this.playWalk = false;
                this.walkIsPlaying = false;
            }
            if (!this.paused) {
                await waitForEvent(this.root, "checkBounds");
                this.canPause = true;
                if (this.robot.x > 900) {
                    this.robot.x = -140;
                }
                this.robot.gotoAndPlay("walk");
                this.playWalk = true;
            }
        });
    };

    checkRobotUnderMouse = async () => {
        const localMouse = this.robot.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        if (!this.robot.hitTest(localMouse.x, localMouse.y)) {
            return;
        }
        if (!this.doMouseOver) return;
        if (!this.paused && this.canPause) {
            this.paused = true;
            this.playWalk = false;
            this.robot.gotoAndPlay(54);
            this.robot.head.gotoAndStop("front");
            this.doMouseOver = false;
            await waitForEvent(this.root, "startMove");
            this.robot.head.gotoAndStop("side");
            this.doMouseOver = true;
            this.paused = false;
            this.canPause = false;
            this.robot.gotoAndPlay("walk");
            this.playWalk = true;
        }
    };

    animateRobot = async () => {
        playSound("robotGrow");
        this.robot.gotoAndPlay(1);
        await waitForEvent(this.root, "growEnd");
        this.robot.head.gotoAndPlay("open");
        await waitForEvent(this.root, "headGrowEnd");
        this.robot.gotoAndPlay(11);
        this.robot.head.gotoAndStop("front");
        await waitForEvent(this.root, "startMove");
        this.robot.head.gotoAndStop("side");
        this.robot.gotoAndPlay("walk");
        this.playWalk = true;
        this.paused = false;
    };
}
