import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIframeContext } from "../context/IframeContext";
import { Navigation } from "./navigation";

export const NavigationInitializer: React.FC = () => {
    const history = useHistory();
    const { setIframeSrc, setShowLogin,setShowRegistration } = useIframeContext();

    useEffect(() => {
        Navigation.init(history, setIframeSrc, setShowLogin,setShowRegistration);
    }, [history, setIframeSrc, setShowLogin,setShowRegistration]);
    return null;
};
