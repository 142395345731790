import { localToGlobal, MC, playSound } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { BOUND_X, BOUND_Y, getBongoSound, HEIGHT, WIDTH } from "./common";

export class ShadowGame {
    private moveX: number;
    private moveY: number;
    private should_move: boolean = false;
    private pressed_index: number = 0;

    private readonly g = new createjs.Shape();
    private readonly g2 = new createjs.Shape();

    constructor(
        private root: any,
        private areaWidth: number,
        private areaHeight: number,
        private numElements: number,
        private widths: number[],
        private heights: number[],
        private begin: number
    ) {}

    start = () => {
        this.root.gotoAndStop(this.begin);
        this.initPieces();

        this.root.addChild(this.g);
        this.root.addChild(this.g2);

        this.root.board.addEventListener("click", () => {
            playSound(getBongoSound());
        });

        this.root.addEventListener("tick", () => {
            this.moveElement();
        });
        this.initShadows();
    };

    initShadows = () => {
        for (let i = 1; i <= this.numElements; i++) {
            const shadow = this.root[`shadow${i}`];
            shadow.addEventListener("click", () => {
                playSound(getBongoSound());
            });
        }
    };

    initPieces = () => {
        // this.debugAreas();
        for (let i = 1; i <= this.numElements; i++) {
            const piece = this.root[`piece${i}`];

            piece.x = getRandomNumber(BOUND_X + this.widths[i - 1] / 2, BOUND_X + WIDTH - this.widths[i - 1] / 2);
            piece.y = getRandomNumber(
                BOUND_Y + HEIGHT / 2 - 30 + this.heights[i - 1] / 2,
                BOUND_Y + HEIGHT - this.heights[i - 1] / 2
            );
            this.addListeners(piece, i);
        }
    };

    addListeners = (piece: MC, index: number) => {
        this.mouseOver(piece);
        this.mouseDown(piece, index);
        this.pressup(piece, index);
        this.mouseout(piece, index);
    };

    mouseOver = (piece: MC) => {
        piece.addEventListener("mouseover", () => {
            if (!this.should_move) this.root.setChildIndex(piece, this.root.numChildren - 1);
        });
    };
    mouseDown = (piece: MC, i: number) => {
        piece.addEventListener("mousedown", async () => {
            if (this.should_move) {
                return;
            }
            this.pressed_index = i;
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.moveX = localMouse.x - piece.x;
            this.moveY = localMouse.y - piece.y;
            await playSound(getBongoSound());
        });
    };

    mouseout = (piece: MC, i: number) => {
        piece.addEventListener("mouseout", () => {
            if (this.pressed_index === i && !this.should_move) {
                //current piece was the last one pressed.
                this.pressed_index = 0;
            }
        });
    };
    pressup = (piece: MC, i: number) => {
        piece.addEventListener("pressup", async () => {
            if (this.should_move && this.pressed_index === i) {
                //current piece was the last one pressed, and is on move.
                this.should_move = false;
                if (this.checkIntersection(piece)) {
                    await playSound(getBongoSound());
                    playSound("Click");
                } else {
                    playSound(getBongoSound());
                }
                return;
            }
            if (this.pressed_index === i) {
                playSound("Click");
                this.should_move = true;
            }
        });
    };
    moveElement = () => {
        if (this.should_move) {
            if (this.root.stage.mouseX && this.root.stage.mouseY) {
                const pos = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
                const new_x = pos.x - this.moveX;
                const new_y = pos.y - this.moveY;
                const width = this.widths[this.pressed_index - 1];
                const height = this.heights[this.pressed_index - 1];
                const piece = this.root[`piece${this.pressed_index}`];
                if (new_x - width / 2 >= BOUND_X && new_x + width / 2 <= BOUND_X + WIDTH) {
                    piece.x = new_x;
                } else {
                    if (new_x - width / 2 < BOUND_X) {
                        piece.x = BOUND_X + width / 2;
                    } else {
                        piece.x = BOUND_X + WIDTH - width / 2;
                    }
                }

                if (new_y - height / 2 >= BOUND_Y && new_y + height / 2 <= BOUND_Y + HEIGHT) {
                    piece.y = new_y;
                } else {
                    if (new_y - height / 2 < BOUND_Y) {
                        piece.y = BOUND_Y + height / 2;
                    } else {
                        piece.y = BOUND_Y + HEIGHT - height / 2;
                    }
                }
            }
        }
    };

    checkIntersection = (piece: MC) => {
        const shadow = this.root[`shadow${this.pressed_index}`];
        const globalShadowArea = localToGlobal(shadow.area, 0, 0);
        const globalPieceArea = localToGlobal(piece.area, 0, 0);
        const shadowRect = new createjs.Rectangle(
            globalShadowArea.x - this.areaWidth / 2,
            globalShadowArea.y - this.areaHeight / 2,
            this.areaWidth,
            this.areaHeight
        );

        const pieceRect = new createjs.Rectangle(
            globalPieceArea.x - this.areaWidth / 2,
            globalPieceArea.y - this.areaHeight / 2,
            this.areaWidth,
            this.areaHeight
        );

        if (shadowRect.intersects(pieceRect)) {
            piece.visible = false;
            shadow.gotoAndStop(10);
            return true;
        }
        return false;
    };

    debugAreas = () => {
        let g = this.g.graphics;
        g.s("red").r(BOUND_X, BOUND_Y, WIDTH, HEIGHT);
        let g2 = this.g2.graphics;
        g2.s("blue").r(BOUND_X, BOUND_Y + HEIGHT / 2 - 30, WIDTH, HEIGHT / 2 + 30);
    };
}
