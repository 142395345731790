import { loadSound } from "../animate/sound_utils";
import { MC, playSoundSync } from "../animate/utils";

export class SoundToolTip {
    private static nDelay: number = 500;
    private static current_over_mc: MC;
    private static nInterval: any;
    private static bEnabled: boolean = true;
    public static bActive: boolean;
    private static currentSound: createjs.AbstractSoundInstance;
    private static stopSound: boolean = true;

    public static addToolTip = (mc: MC, soundID: string, isPath: boolean = false, stopSound: boolean = true) => {
        SoundToolTip.stopSound = stopSound;

        mc.addEventListener("mouseover", () => {
            SoundToolTip.current_over_mc = mc;
            SoundToolTip.startDelayTooltip(mc, soundID);
        });
        mc.addEventListener("mouseout", () => {
            SoundToolTip.stopCurrentToolTip();
            SoundToolTip.current_over_mc = null;
        });
        mc.addEventListener("pressup", () => {
            SoundToolTip.stopCurrentToolTip();
            SoundToolTip.current_over_mc = null;
        });
        mc.addEventListener("click", () => {
            SoundToolTip.stopCurrentToolTip();
            SoundToolTip.current_over_mc = null;
        });
    };

    public static startByMC = (mc: MC) => {
        SoundToolTip.current_over_mc = mc;
        SoundToolTip.startDelayTooltip(mc, mc.oSoundToolTip);
    };

    public static stopCurrentToolTip = () => {
        if (!SoundToolTip.bEnabled) return;
        SoundToolTip.onToolTipEnd();
        SoundToolTip.bActive = false;
        if (SoundToolTip.stopSound === true) {
            SoundToolTip.currentSound && SoundToolTip.currentSound.stop();
        }
        clearTimeout(SoundToolTip.nInterval);
    };

    private static startDelayTooltip(mc: MC, sound: string) {
        SoundToolTip.nInterval = setTimeout(() => {
            SoundToolTip.doToolTip(mc, sound);
        }, SoundToolTip.nDelay);
    }

    private static doToolTip = async (mc: MC, sound: string) => {
        if (!SoundToolTip.bEnabled) return;
        if (SoundToolTip.current_over_mc !== mc) return;
        SoundToolTip.onToolTipStart();
        SoundToolTip.bActive = true;
        SoundToolTip.currentSound && SoundToolTip.currentSound.stop();
        let oSound;
        if (sound.endsWith(".mp3")) {
            oSound = await loadSound(sound);
            oSound.play();
        } else {
            oSound = playSoundSync(sound);
        }
        oSound.on("complete", () => {
            SoundToolTip.onToolTipEnd();
            SoundToolTip.bActive = false;
        });
        SoundToolTip.currentSound = oSound;
    };

    public static enable() {
        SoundToolTip.bEnabled = true;
    }

    public static disable() {
        SoundToolTip.stopCurrentToolTip();
        SoundToolTip.bEnabled = false;
    }

    public static onToolTipStart() {
        //trace("SoundToolTip >> onToolTipStart");
    }

    public static onToolTipEnd() {
        //trace("SoundToolTip >> onToolTipEnd");
    }
}
