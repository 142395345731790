import { localToGlobal, MC, waitForTimer } from "../../animate/utils";
import {
    BOTTOM_BOARD_LIMIT,
    GRAPHIC_INIT_SCALE,
    LEFT_BOARD_LIMIT,
    RIGHT_BOARD_LIMIT,
    STAMP_MIN_HEIGHT,
    STAMP_MIN_WIDTH,
    TOP_BOARD_LIMIT,
} from "../utils/constants";
import { CreationGame } from "../CreationGame";
import { Store } from "./Store";
import letterFrames from "../mapping/letterFrames.json";
import { creationTooltip } from "../utils/creationTooltip";

export class StampStore extends Store {
    private frameAmount: number;
    private have_listeners: MC[] = []; //because some stamps are in 2 lists

    constructor(private root: MC, private panel: MC, private letterIndex: string, private creationGame: CreationGame) {
        super(letterIndex ? 9 : 8, 0, panel);
        this.frameAmount = letterIndex ? 9 : 8;
        this.initStore();
        letterIndex && this.getInitFrame();
        // this.debugStamps();
    }

    getInitFrame = () => {
        for (let i = 0; i < letterFrames.length; i++) {
            const line = letterFrames[i];
            if (line.letters.includes(this.letterIndex)) {
                super.setCurrentFrame(line.frame);
                return;
            }
        }
    };

    initStore = () => {
        this.panel.visible = false;
        for (let i = 0; i < this.frameAmount; i++) {
            this.panel.gotoAndStop(i);
            this.panel.children.forEach((child: MC) => {
                if (this.isChildElement(child) && !this.have_listeners.includes(child)) {
                    this.initHitArea(child);
                    this.have_listeners.push(child);
                    this.onMouseOver(child);
                    this.onMouseOut(child);
                    this.onClick(child);
                    if (child.name === "stamp87" || child.name === "stamp96") return;
                    creationTooltip.addToolTip(child, `/creation_corner/sounds/stamps/${child.name}.mp3`);
                }
            });
        }
        this.panel.gotoAndStop(0);
        this.panel.visible = true;
    };

    onClick = (child: any) => {
        child.addEventListener("click", () => {
            this.duplicateItem(child);
        });
    };

    onMouseOut = (child: any) => {
        child.addEventListener("mouseout", () => {
            if (child !== this.creationGame.getCurrentItemToDrag()) {
                this.removeHoverEffect(child);
            }
        });
    };

    onMouseOver = (child: any) => {
        child.addEventListener("mouseover", () => {
            if (child !== this.creationGame.getCurrentItemToDrag()) {
                this.addHoverEffect(child);
            }
        });
    };

    isChildElement = (child: any) => {
        return child && child.name?.startsWith("stamp");
    };

    addHoverEffect = (child: any) => {
        child.filters = child.filters || [];
        child.filters.push(new createjs.ColorFilter(0.5, 0.5, 0.5, 1, 0, 51, 127.5, 0));
        child.cache(-500, -500, 1000, 1000);
    };

    removeHoverEffect = (child: any) => {
        if (!child.filters) return;
        child.filters.pop();
        child.updateCache();
    };

    duplicateItem = (child: MC) => {
        const instance = new this.root.lib[child.name]();
        instance.lib = child.name;
        const childPosition = localToGlobal(child, 0, 0);
        instance.scale = GRAPHIC_INIT_SCALE;
        instance.x = childPosition.x;
        instance.y = childPosition.y;

        const hit = new createjs.Shape();
        hit.graphics.beginFill("#000").drawRect(-145, -135, 290, 270);
        hit.alpha = 0.2;
        instance.hitArea = hit;
        // instance.addChild(hit);
        this.initNewInstance(instance);
    };

    initInstanceListeners = (inst: any) => {
        inst.mouseChildren = null;
        this.onMouseOver(inst);
        this.onMouseOut(inst);
        this.instanceOnClick(inst);
    };

    instanceOnClick = (stamp: any) => {
        stamp.addEventListener("click", () => {
            this.removeHoverEffect(stamp);
            const currentDraggedItem = this.creationGame.getCurrentItemToDrag();
            if (currentDraggedItem === stamp) {
                this.checkPosition(stamp);
                this.creationGame.setNewItemToDrag(null);
            } else {
                this.creationGame.chooseAction(stamp, "graphic");
            }
        });
    };

    checkPosition = (stamp: any) => {
        const stampScale = this.creationGame.getcurrentGraphicScale(stamp) / GRAPHIC_INIT_SCALE;
        if (this.isOutOfBounds(stamp)) {
            this.creationGame.removeItemFromBoard(stamp);
        } else {
            if (stamp.x > RIGHT_BOARD_LIMIT - 48 * stampScale) {
                stamp.x = RIGHT_BOARD_LIMIT - 48 * stampScale;
            }
            if (stamp.x < LEFT_BOARD_LIMIT + 48 * stampScale) {
                stamp.x = LEFT_BOARD_LIMIT + 48 * stampScale;
            }
            if (stamp.y - 40 * stampScale <= TOP_BOARD_LIMIT) {
                stamp.y = TOP_BOARD_LIMIT + 40 * stampScale;
            }
            if (stamp.y + 40 * stampScale >= BOTTOM_BOARD_LIMIT) {
                stamp.y = BOTTOM_BOARD_LIMIT - 40 * stampScale;
            }
        }
    };

    initNewInstance = (instance: any) => {
        this.creationGame.addItemToBoard(instance, 0);
        this.initInstanceListeners(instance);
        this.creationGame.setNewItemToDrag(instance, instance.x, instance.y);
    };

    isOutOfBounds = (inst: any) => {
        const stampScale = this.creationGame.getcurrentGraphicScale(inst);
        return (
            inst.x > this.root.storeBG.x ||
            inst.y - STAMP_MIN_HEIGHT * stampScale > BOTTOM_BOARD_LIMIT ||
            inst.y + STAMP_MIN_HEIGHT * stampScale < TOP_BOARD_LIMIT ||
            inst.x + STAMP_MIN_WIDTH * stampScale < LEFT_BOARD_LIMIT
        );
    };

    debugStamps = async () => {
        this.root.panel_stamps_ab.visible = true;
        for (let i = 0; i < this.frameAmount; i++) {
            this.panel.gotoAndStop(i);
            this.root.panel_stamps_ab.gotoAndStop(i);
            this.panel.children.forEach((child: MC) => {
                if (this.isChildElement(child)) {
                    try {
                        this.duplicateDebugItem(child);
                    } catch (e) {
                        console.log("error", child);
                    }
                }
            });
            this.root.panel_stamps_ab.children.forEach((child: MC) => {
                if (this.isChildElement(child)) {
                    try {
                        this.duplicateDebugItem(child);
                    } catch (e) {
                        console.log("error", child);
                    }
                }
            });
            await waitForTimer(1000);
        }
    };

    duplicateDebugItem = (child: MC) => {
        const instance = new this.root.lib[child.name]();
        instance.scale = GRAPHIC_INIT_SCALE;
        instance.x = 300;
        instance.y = 0;
        this.creationGame.addItemToBoard(instance, 0);
        this.checkPositionDebug(instance);
    };

    checkPositionDebug = (stamp: any) => {
        const stampScale = stamp.scale / GRAPHIC_INIT_SCALE;
        if (stamp.x > RIGHT_BOARD_LIMIT - 48 * stampScale) {
            stamp.x = RIGHT_BOARD_LIMIT - 48 * stampScale;
        }
        if (stamp.x < LEFT_BOARD_LIMIT + 48 * stampScale) {
            stamp.x = LEFT_BOARD_LIMIT + 48 * stampScale;
        }
        if (stamp.y - 40 * stampScale <= TOP_BOARD_LIMIT) {
            stamp.y = TOP_BOARD_LIMIT + 40 * stampScale;
        }
        if (stamp.y + 40 * stampScale >= BOTTOM_BOARD_LIMIT) {
            stamp.y = BOTTOM_BOARD_LIMIT - 40 * stampScale;
        }
    };

    initHitArea = (child: any) => {
        const hit = new createjs.Shape();
        hit.graphics.beginFill("#000").drawRect(-135, -120, 270, 240);
        hit.alpha = 0.2;
        child.hitArea = hit;
        // child.addChild(hit);
    };
}
