import {
    MC,
    waitForTimer,
    waitForEvent,
    playSound,
    getLetter,
    getEndLetter,
    playSoundSync,
    getLastLetterIndex,
} from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { randomObjectToLetter, returnCorrectAnswerText, getLetterForIndex } from "../jsonFileFunction";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { ReviewGameBasic } from "../ReviewGameBasic";
import { routes } from "../../../navigation/routesPath";
import { loadSound, soundPlayed } from "../../../animate/sound_utils";
export class Fishes extends ReviewGameBasic {
    root: MC;
    bgSound: any = null;
    currentLetterIndex: any;
    questionIndex: number = 0;
    lettersForQuestions: any = [];
    indexesForGoodFeedback: any = [];
    indexesForBadFeedback: any = [];
    numOfclamsForQuestion: any = [];
    currentQuestioNumOfClams: any;
    objectName: any;
    firstChoiceArr: number[];
    secondChoiceArr: number[];
    quizModeNumberOfQuestion: number;
    mode: string = "";
    addPrizes: any;
    clickedClam: boolean = false;
    setHelpSound: any;
    shouldPlay: boolean;

    constructor(
        root: createjs.MovieClip,
        private history: any,
        private sectionIndex: number,
        private lettersContext: any,
        numberOfQuestion: number,
        mode: string,
        addPrizes?: any,
        setHelpSound?: any,
        private enabledButtonFunc?: any
    ) {
        super();
        this.root = root;
        this.sectionIndex = sectionIndex;
        this.mode = mode;
        this.quizModeNumberOfQuestion = numberOfQuestion;
        this.addPrizes = addPrizes;
        this.setHelpSound = setHelpSound;
        if (this.mode === "quiz") {
            this.root.children.forEach((child: MC) => {
                child.visible = false;
                child.alpha = 1;
            });
            this.setHelpSound("general");
        }
        this.loaded();

        (window as any).fishes = this;
    }
    initFrame = () => {
        if (this.mode !== "quiz") {
            const objToShow = ["reset", "help", "exit"];
            this.root.children.forEach((child: MC) => {
                if (!objToShow.includes(child.name)) child.visible = false;
            });

            this.root.prizes.visible = true;
            this.root.prizes.children.forEach((prize: MC) => {
                prize.visible = false;
            });
            this.root.frame.visible = true;
            if (this.questionIndex > 0) {
                for (let i = 0; i < this.questionIndex; i++) {
                    this.root.prizes[`prize${i + 1}`].visible = true;
                }
            }
        }
        this.root.background.visible = true;
        this.currentQuestioNumOfClams = this.numOfclamsForQuestion.pop();

        for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
            this.root[`letter_${i + 1}`].removeAllEventListeners();
            this.root.clams[`clam${i + 1}`].alpha = 1;
            this.root[`letter_${i + 1}`].alpha = 1;
            this.root.clams[`clam${i + 1}`].gotoAndStop("clamClose");
            this.root[`letter_${i + 1}`].gotoAndStop(0);
        }
        if (this.currentQuestioNumOfClams === 2) {
            this.root.clams[`clam3`].visible = false;
            this.root[`letter_3`].visible = false;
        } else {
            this.root.clams[`clam3`].visible = true;
            this.root[`letter_3`].visible = true;
        }
    };

    showLoader = async () => {
        this.root.LoadBlock.visible = true;
        this.root.LoadBlock.gotoAndPlay(1);
        this.root.intro.gotoAndPlay(27);
        this.root.intro.visible = true;
        await waitForEvent(this.root, "animationEnd");
        this.bgSound = playSoundSync("fishesbg", { loop: -1 });
        this.root.LoadBlock.visible = false;
        this.soundPlayed = true;
        if (this.history.path === routes.letters.review.fishes || this.history.path === routes.letters.review.quiz)
            await playSound("fishesGameName");
        if (this.history.path === routes.letters.review.fishes || this.history.path === routes.letters.review.quiz)
            await playSound("general");
        this.soundPlayed = false;
        this.root.intro.visible = false;
        this.root.LoadBlock.visible = false;
    };

    startGame = () => {
        if (!this.bgSound || this.bgSound.volume === 0) {
            this.bgSound = playSoundSync("fishesbg", { loop: -1 });
        }
        this.root.wordAnim.visible = true;
        this.root.wordAnim.gotoAndPlay(1);
        this.root.clams.visible = true;
        this.root.clams.gotoAndStop(1);
        this.root.showObject.gotoAndStop(0);
        this.showQuestion();
    };

    randomLetters = () => {
        switch (this.sectionIndex) {
            case 3:
                this.firstChoiceArr = [9, 12, 11];
                this.secondChoiceArr = [3, 4, 7, 8];
                break;
            case 4:
                this.firstChoiceArr = [15, 13, 14, 17];
                this.secondChoiceArr = [3, 4, 7, 8, 9, 11, 12];
                break;
            case 5:
                this.firstChoiceArr = [18, 19, 20, 21, 22];
                this.secondChoiceArr = [3, 4, 7, 8, 9, 11, 12, 15, 16, 13, 14, 17];
                break;
        }
        this.initLetterArr();
    };

    initLetterArr = () => {
        let letterIndex;
        for (let index = 0; index < 2; index++) {
            do {
                letterIndex = getRandomNumber(0, this.secondChoiceArr.length - 1);
            } while (this.lettersForQuestions.includes(this.secondChoiceArr[letterIndex]));
            this.lettersForQuestions.push(this.secondChoiceArr[letterIndex]);
        }
        let numOanswer: number;
        if (this.mode !== "quiz") {
            numOanswer = 3;
        } else {
            numOanswer = 2;
        }
        for (let index = 0; index < numOanswer; index++) {
            do {
                letterIndex = getRandomNumber(0, this.firstChoiceArr.length - 1);
            } while (this.lettersForQuestions.includes(this.firstChoiceArr[letterIndex]));
            this.lettersForQuestions.push(this.firstChoiceArr[letterIndex]);
        }
    };

    showQuestion = async () => {
        if (this.mode !== "quiz") {
            this.disableButtons(["reset", "help"]);
        }
        const letterIndex = this.randomLetter();
        const object = randomObjectToLetter(letterIndex, this.currentQuestioNumOfClams);
        this.root.showObject.visible = true;
        this.root.showObject.scaleX = 0.7;
        this.root.showObject.scaleY = 0.7;
        if (this.mode === "quiz") {
            this.enabledButtonFunc(false);
        }
        if (this.shouldPlay) await playSound("word");
        this.root.showObject.gotoAndStop(object.correctAnswer);
        this.root.showObject.removeAllEventListeners();
        const objectSound = await loadSound(`/letters_island/common/objects/${object.correctAnswer}.mp3`);
        this.objectName = objectSound;
        SoundToolTip.addToolTip(
            this.root.showObject,
            `/letters_island/common/objects/${object.correctAnswer}.mp3`,
            true
        );
        if (this.shouldPlay) await soundPlayed(objectSound.play());
        await waitForTimer(500);
        if (this.mode === "quiz") {
            this.enabledButtonFunc(true);
        }

        this.showLettersInClams(object.correctAnswer, object.correctAnswerIndex, letterIndex);
    };

    showLettersInClams = async (correctAnswer: any, correctAnswerIndex: any, letterIndex: any) => {
        if (this.mode !== "quiz") {
            this.disableButtons(["reset", "help"]);
        }
        const othersAnswersLetters: any = [];
        for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
            if (i === correctAnswerIndex) {
                othersAnswersLetters[i] = null;
            } else {
                const index = getRandomNumber(1, 21);
                if (index + 1 !== parseInt(letterIndex) && !othersAnswersLetters.includes(index)) {
                    othersAnswersLetters.push(index);
                } else {
                    i--;
                }
            }
        }

        for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
            let letterSound: any;
            const currentLetter = this.root[`letter_${i + 1}`];
            this.root.clams[`clam${i + 1}`].gotoAndStop("clamOpen");
            if (this.shouldPlay) playSoundSync("pickup");

            currentLetter.visible = true;
            if (othersAnswersLetters[i] === null) {
                let correctLetter_f: any;
                let correctLetter_f_index: any;
                correctLetter_f = getEndLetter(letterIndex - 1);
                correctLetter_f_index = getLastLetterIndex(letterIndex - 1);
                if (correctLetter_f !== null) {
                    currentLetter.gotoAndStop(correctLetter_f);
                    letterSound = `/letters_island/common/letters/${getLetterForIndex(
                        getLetter(correctLetter_f_index - 1)
                    )}_d.mp3`;
                    correctLetter_f = null;
                } else {
                    currentLetter.gotoAndStop(getLetter(letterIndex - 1));
                    letterSound = `/letters_island/common/letters/${getLetterForIndex(getLetter(letterIndex - 1))}.mp3`;
                }
            } else {
                let letter_f: any;
                let letter_f_index: any;
                letter_f = getEndLetter(othersAnswersLetters[i]);
                letter_f_index = getLastLetterIndex(othersAnswersLetters[i]);
                if (letter_f !== null) {
                    currentLetter.gotoAndStop(letter_f);
                    letterSound = `/letters_island/common/letters/${getLetterForIndex(
                        getLetter(letter_f_index - 1)
                    )}_d.mp3`;
                    letter_f = null;
                    letter_f_index = null;
                } else {
                    currentLetter.gotoAndStop(getLetter(othersAnswersLetters[i]));
                    letterSound = `/letters_island/common/letters/${getLetterForIndex(
                        getLetter(othersAnswersLetters[i])
                    )}.mp3`;
                }
            }
            const sound = await loadSound(letterSound);
            if (this.shouldPlay) await soundPlayed(sound.play());
            currentLetter.letterSound = letterSound;
            await waitForTimer(600);
        }
        for (let i = 1; i <= this.currentQuestioNumOfClams; i++) {
            const currentClam = this.root.clams[`clam${i}`];
            const currentLetter = this.root[`letter_${i}`];

            currentClam.addEventListener("click", async () => {
                if (this.clickedClam === false) {
                    this.clickedClam = true;
                    await playSound("click");
                    this.checkCorrectAnswer(correctAnswer, correctAnswerIndex + 1, currentClam, currentLetter, i);
                }
            });

            currentLetter.mouseEnabled = false;

            SoundToolTip.addToolTip(currentClam, currentLetter.letterSound, true);
            currentClam.cursor = "pointer";
        }
        if (this.mode !== "quiz") {
            this.enableButtons(["reset", "help", "exit"]);
        }
    };

    wink = (fish: MC, interval: number) => {
        setInterval(async () => {
            await fish.gotoAndPlay(0);
        }, interval);
    };

    randomLetter = () => {
        let letterIndex: any;
        letterIndex = getRandomNumber(0, this.lettersForQuestions.length - 1);
        let letter = this.lettersForQuestions[letterIndex];
        this.lettersForQuestions.splice(letterIndex, 1); //remve randomed letter
        this.currentLetterIndex = letterIndex;
        return `${letter}`;
    };

    checkCorrectAnswer = async (
        correctAnswer: any,
        correctAnswerIndex: any,
        currentClam: MC,
        currentLetter: MC,
        answerIndex: any
    ) => {
        if (correctAnswerIndex === answerIndex) {
            for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
                this.root.clams[`clam${i + 1}`].removeAllEventListeners();
                this.root[`letter_${i + 1}`].letterSound = "";
                this.root.clams[`clam${i + 1}`].cursor = "default";
            }
            const extaObj = [];
            for (let i = 0; i < 3; i++) {
                if (i + 1 !== correctAnswerIndex) {
                    extaObj.push(this.root.clams[`clam${i + 1}`]);
                    extaObj.push(this.root[`letter_${i + 1}`]);
                }
            }
            const promises: any[] = [];
            extaObj.forEach((child: MC) => {
                promises.push(
                    new Promise((resolve) =>
                        createjs.Tween.get(child).to({ alpha: 0 }, 1000, createjs.Ease.cubicOut).call(resolve)
                    )
                );
            });
            await Promise.all(promises);
            this.disableButtons(["reset", "help"]);
            for (let i = 0; i < 3; i++) {
                this.root[`letter_${i + 1}`].removeAllEventListeners();
            }
            this.root.word.visible = true;
            let answer: any;
            answer = returnCorrectAnswerText(correctAnswer);
            this.root.word.children[1].boxLeft.text = answer[answer.length - 1];
            this.root.word.children[1].boxRight.text = answer.slice(0, answer.length - 1);

            if (this.questionIndex < 6 || this.questionIndex < this.quizModeNumberOfQuestion) {
                this.questionIndex++;
            }
            if (this.shouldPlay) await playSound("good");
            if (this.mode !== "quiz") {
                this.root.prizes[`prize${this.questionIndex}`].visible = true;
                if (this.shouldPlay) await playSound(`Good${this.indexesForGoodFeedback.pop()}`);
                this.reset();
            } else {
                this.quizModeReset("correct");
            }
        } else {
            currentLetter.letterSound = "";
            await playSound("bad");
            if (this.mode !== "quiz") {
                this.disableButtons(["reset", "help"]);
                if (this.shouldPlay) await playSound(`Bad${this.indexesForBadFeedback.pop()}`);
                if (this.shouldPlay) await soundPlayed(this.objectName.play());
                this.enableButtons(["reset", "help"]);
                this.clickedClam = false;
            } else {
                this.questionIndex++;
                this.quizModeReset("wrong");
            }
        }
    };

    reset = async () => {
        if (this.questionIndex >= 5) {
            this.resetAll();
        } else {
            for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
                this.root.clams[`clam${i + 1}`].removeAllEventListeners();
                this.root[`letter_${i + 1}`].letterSound = "";
                this.root.clams[`clam${i + 1}`].cursor = "default";
            }
            this.clickedClam = false;
            this.root.showObject.removeAllEventListeners();
            this.currentLetterIndex = null;
            this.objectName = null;
            await this.initFrame();
            await waitForTimer(300);
            this.startGame();
        }
    };
    quizModeReset = async (answerStatus: string) => {
        this.clickedClam = false;
        for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
            this.root.clams[`clam${i + 1}`].removeAllEventListeners();
            this.root[`letter_${i + 1}`].letterSound = "";
            this.root.clams[`clam${i + 1}`].cursor = "default";
        }
        this.addPrizes("fishes", this.questionIndex, answerStatus);
        await waitForTimer(700);
        if (this.questionIndex < this.quizModeNumberOfQuestion) {
            for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
                this.root.clams[`clam${i + 1}`].removeAllEventListeners();
                this.root[`letter_${i + 1}`].letterSound = "";
                this.root.clams[`clam${i + 1}`].cursor = "default";
            }
            this.root.word.visible = false;
            this.root.showObject.removeAllEventListeners();
            this.currentLetterIndex = null;
            this.objectName = null;
            await this.initFrame();
            await waitForTimer(300);
            this.startGame();
        }
    };
    playAgain = async () => {
        this.clickedClam = false;
        this.enableButtons(["exit"]);
        this.questionIndex = 0;
        this.currentQuestioNumOfClams = 0;
        this.lettersForQuestions = [];
        this.randoomNumOfClams();
        this.randomLetters();
        this.randomFeedbacks();
        await this.initFrame();
        await this.startGame();
    };
    restartGame = () => {
        for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
            this.root.clams[`clam${i + 1}`].alpha = 0;
            this.root[`letter_${i + 1}`].alpha = 0;
            this.root.clams[`clam${i + 1}`].removeAllEventListeners();
            this.root[`letter_${i + 1}`].letterSound = "";
            this.root.clams[`clam${i + 1}`].cursor = "default";
        }
        this.root.showObject.removeAllEventListeners();
        this.root.wordAnim.gotoAndStop(0);
        this.playAgain();
    };
    resetAll = async () => {
        this.questionIndex = 0;
        for (let i = 0; i < this.currentQuestioNumOfClams; i++) {
            this.root.clams[`clam${i + 1}`].alpha = 0;
            this.root[`letter_${i + 1}`].alpha = 0;
        }
        this.lettersContext.setGameStatus(this.sectionIndex, 3);
        await this.initFrame();
        this.bgSound.volume = 0;
        this.root.last_feedback.visible = true;
        if (this.shouldPlay) await playSound("end");
        this.root.last_feedback.gotoAndPlay("start");
        if (this.shouldPlay) playSoundSync("endGame");
        this.bgSound.volume = 1;
        await waitForEvent(this.root, "animationEnd");
        this.setAlertBox(this.root.AlertBox, this.playAgain, this.sectionIndex);
    };

    randomFeedbacks = () => {
        let goodIndex;
        let badIndex;
        while (this.indexesForGoodFeedback.length < 5) {
            goodIndex = getRandomNumber(1, 10);
            if (!this.indexesForGoodFeedback.includes(goodIndex)) {
                this.indexesForGoodFeedback.push(goodIndex);
            }
        }
        while (this.indexesForBadFeedback.length < 5) {
            badIndex = getRandomNumber(0, 2);
            this.indexesForBadFeedback.push(badIndex);
        }
    };

    randoomNumOfClams = () => {
        let numOfClams;
        if (this.mode !== "quiz") {
            while (this.numOfclamsForQuestion.length < 5) {
                numOfClams = getRandomNumber(2, 3);
                this.numOfclamsForQuestion.push(numOfClams);
            }
        } else {
            while (this.numOfclamsForQuestion.length < this.quizModeNumberOfQuestion) {
                numOfClams = getRandomNumber(2, 3);
                this.numOfclamsForQuestion.push(numOfClams);
            }
        }
    };

    loaded = async () => {
        // await this.initGame
        this.shouldPlay = true;
        this.wink(this.root.wordAnim.fishLeft, 4000);
        this.wink(this.root.wordAnim.fishRight, 5000);
        this.randoomNumOfClams();
        this.randomLetters();
        this.randomFeedbacks();
        this.initFrame();
        if (this.mode !== "quiz") {
            this.initButtons(this.root, this.restartGame, "general", this.sectionIndex);
            this.disableButtons(["reset", "help"]);
            await this.showLoader();
        }
        this.startGame();
    };
    unLoadad = () => {
        this.bgSound.volume = 0;
        this.shouldPlay = false;
        this.root.children.forEach((child: MC) => {
            child.visible = false;
        });
    };
}
