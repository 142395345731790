const additionalSounds = [
    {
        src: "sounds/bg.mp3",
        id: "background",
    },
    {
        src: "sounds/exit.mp3",
        id: "exit",
    },
    {
        src: "sounds/back.mp3",
        id: "back",
    },
    {
        src: "sounds/home.mp3",
        id: "home",
    },
];

export default additionalSounds;
