import React from "react";
import AnimateCC from "../../animate";
import { SweetGame } from "./SweetRhymesGame";
import sweetRhymes_sounds from "./sweetRhymes_sounds.json";

export default function SweetRhymesComponent() {
    const game = React.useRef(new SweetGame());

    return (
        <>
            <AnimateCC
                base="/letters_island/sweetRhymes/"
                animationName="sweetRhymes"
                onLoaded={game.current.sweetLoaded}
                additionalSounds={sweetRhymes_sounds}
            />
        </>
    );
}
