import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AnimateCC from "../animate";
import { MC } from "../animate/utils";
import { DubbyEnglishGames } from "./DubbyEnglishGames";

export const DubbyEnglishGamesComponent: React.FC = () => {
    const { gameType, roomType } = useParams<{ gameType: string; roomType: string }>();
    const game = React.useRef(new DubbyEnglishGames(gameType, roomType));

    useEffect(() => {
        if (gameType && roomType) {
            game.current.gameType = gameType;
            game.current.roomType = roomType;
        } else {
            game.current.gameType = null;
            game.current.roomType = null;
            game.current.dubbyCurrentGame = null;
            game.current.frame = null;
        }
    }, [gameType, roomType]);

    return gameType !== "" && roomType !== "" ? (
        <>
            <AnimateCC
                key={`${gameType}_${roomType}`}
                base="/dubby_english/"
                animationName={`${gameType}_${roomType}room`}
                onLoaded={async (mc: MC) => {
                    game.current.dubbyEnglishGamesLoaded(mc);
                }}
                scale={0.7}
                shiftX={175}
                shiftY={125}
            />
            <AnimateCC
                key={gameType}
                base="/dubby_english/"
                animationName={`${gameType}_frame`}
                onLoaded={async (mc: MC) => {
                    game.current.dubbyEnglishFrameLoaded(mc);
                }}
                scale={0.7}
                shiftX={175}
                shiftY={125}
            />
        </>
    ) : null;
};
