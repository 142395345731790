import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Cars } from "./Cars";

export function CarsGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="cars"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Cars(mc), 3)}
            game={game}
        />
    );
}

// const CarsGameComponent: React.FC<{}> = () => {
//     const game = React.useRef(new Cars());
//     return (
//         <>
//             <AnimateCC
//                 base="/math/cars"
//                 // additionalSounds={mathMenuSounds}
//                 animationName="cars"
//                 onLoaded={game.current.loaded}
//                 scale={0.72}
//                 shiftX={142}
//                 shiftY={130}
//             />
//         </>
//     );

// };
// export default CarsGameComponent;
