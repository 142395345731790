import { MC, asyncTween, playSound, waitForTimer } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game6 extends BaseGame {
    answersIndex = 0;
    helpBtn: any;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.helpBtn = helpBtn;
        game.visible = true;
        game.x = 600;
        game.gotoAndStop(0);
        game.mouseChildren = false;
        let buttonsToShow = [2, 1, 0, 5, 4, 3];
        for (let i = 0; i < 6; i++) {
            game[`btn_word${i}`].alpha = 0;
        }
        game.flower1.alpha = 0;
        game.flower2.alpha = 0;
        await waitForTimer(400);
        for (let i = 0; i < 6; i++) {
            if (!this.shouldPlay) return;
            await asyncTween(game[`btn_word${buttonsToShow[i]}`], { alpha: 1 }, 400);
            if (!this.shouldPlay) return;
            await playSound("books");
            if (!this.shouldPlay) return;
            game[`btn_word${i}`].addEventListener("click", () => {
                this.checkCorrectanswer(game, gameEnd, i);
            });
        }
        if (!this.shouldPlay) return;
        await playSound(`game_${6}`);
        game.mouseChildren = true;
        helpBtn(false);
    };
    checkCorrectanswer = async (game: MC, gameEnd: () => void, buttonIndex: any) => {
        this.helpBtn(true);
        if (buttonIndex === 0 || buttonIndex === 4) {
            game.mouseChildren = false;
            game[`btn_word${buttonIndex}`].gotoAndStop("down");
            let flowerIndex: any;
            if (buttonIndex === 0) {
                flowerIndex = 1;
            } else {
                flowerIndex = 2;
            }
            game[`flower${flowerIndex}`].alpha = 1;
            game[`flower${flowerIndex}`].gotoAndPlay(1);
            this.answersIndex++;
            if (!this.shouldPlay) return;
            await playSound(`feedback_6_${this.answersIndex}`);
            game.mouseChildren = true;
            this.helpBtn(false);
            if (this.answersIndex === 2) {
                gameEnd();
            }
        } else {
            playSound("wrong");
            this.helpBtn(false);
        }
    };
}
