import { generatePath } from "react-router-dom";
import { MC, stopAllSounds } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { SoundToolTip } from "../../utils/soundTooltip";

export class BibleFrame {
    private root: MC;
    constructor(private type: string, private isCreationGame: boolean) {}
    loaded = (mc: MC) => {
        this.root = mc;
        this.initBtns();
    };
    togglePrint = (val: boolean) => {
        this.root.frame.btnPrint.visible = val;
    };
    toggleReplay = (val: boolean) => {
        this.root.frame.btnReplay.visible = val;
    };
    toggleHelp = (val: boolean) => {
        this.root.frame.btnHelp.visible = val;
    };
    setIsCreationGame = (val: boolean) => {
        this.isCreationGame = val;
    };

    initBtns = () => {
        this.togglePrint(false);
        this.toggleHelp(false);
        this.toggleReplay(false);
        this.setupBtns();
        this.initExitBtn();
    };

    initExitBtn = () => {
        const exitBtn = this.root.frame.btnExit;
        exitBtn.cursor = "pointer";

        exitBtn.addEventListener("click", () => {
            if (this.isCreationGame) return;
            stopAllSounds();
            Navigation.openPage(generatePath(routes.bible_stories.story_menu.menu, { type: this.type }));
        });
    };

    disableBtn = (btn: MC) => {
        btn.mouseEnabled = false;
        btn.cursor = "default";
    };

    enableBtn = (btn: MC) => {
        btn.mouseEnabled = true;
        btn.cursor = "pointer";
    };

    private setupBtns() {
        ["btnPrint", "btnExit", "btnHelp", "btnReplay"].forEach((name) => {
            const btn = this.root.frame[name];
            btn.cursor = "pointer";
            btn.addEventListener("rollover", () => btn.gotoAndStop("over"));
            btn.addEventListener("rollout", () => btn.gotoAndStop("up"));
        });
        SoundToolTip.addToolTip(this.root.frame.btnExit, "/bible_stories/bible_frame/sounds/exit.mp3");
        SoundToolTip.addToolTip(this.root.frame.btnHelp, "/bible_stories/bible_frame/sounds/help.mp3");
        SoundToolTip.addToolTip(this.root.frame.btnReplay, "/bible_stories/bible_frame/sounds/NewGame.mp3");
        SoundToolTip.addToolTip(this.root.frame.btnPrint, "/bible_stories/bible_frame/sounds/print.mp3");
    }
}
