import React, { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import { useUserContext } from "../../context/UserContext";
import { MeterStaticPreloaderComponent } from "../../loaders/meterStatic/MeterStaticPreloaderComponent";
import { SodRoute } from "../../navigation/PrivateRoot";
import { routes } from "../../navigation/routesPath";
import StoryMenuComponent from "../story_menu/StoryMenuComponent";
import { BibleMenu } from "./BibleMenu";
import { additionalSounds } from "./BibleMenuSounds";

const BibleStoriesComponent: React.FC = () => {
    const [screenReady, setScreenReady] = useState(false);
    const { pathname } = useLocation();
    const isBibleMenu = pathname === routes.bible_stories.menu;
    const { isLoggedIn } = useUserContext();
    const game = React.useRef(new BibleMenu(isLoggedIn));

    useEffect(() => {
        game.current.setIsLoggedIn(isLoggedIn);
    }, [isLoggedIn]);

    useEffect(() => {
        pathname === routes.bible_stories.menu && game.current.playBg();
    }, [pathname]);

    return (
        <>
            {!screenReady && isBibleMenu && <MeterStaticPreloaderComponent />}
            <Switch>
                <SodRoute path={routes.bible_stories.story_menu.menu}>
                    <StoryMenuComponent />
                </SodRoute>
            </Switch>
            <AnimateCC
                visible={isBibleMenu}
                base="/bible_stories"
                animationName="bible_menu"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                    setScreenReady(true);
                }}
                additionalSounds={additionalSounds}
            />
        </>
    );
};

export default BibleStoriesComponent;
