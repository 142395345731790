import React, { useContext, useState } from "react";

function useIframeValue() {
    const [iframeSrc, setIframeSrc] = useState<string>();
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [showRegistration, setShowRegistration] = useState<boolean>(false);
    const [sessionRefresherParams, setSessionRefresherParams] = useState<any>();

    return {
        iframeSrc,
        setIframeSrc,
        showLogin,
        setShowLogin,
        showRegistration,
        setShowRegistration,
        sessionRefresherParams,
        setSessionRefresherParams,
    };
}

export type IframeContextType = ReturnType<typeof useIframeValue>;

const IframeContext = React.createContext<IframeContextType>({
    iframeSrc: undefined,
    setIframeSrc: () => {},
    showLogin: false,
    setShowLogin: () => {},
    showRegistration: false,
    setShowRegistration: () => {},
    sessionRefresherParams: null,
    setSessionRefresherParams: () => {},
});

export const IframeContextProvider: React.FC = (props) => {
    const value = useIframeValue();

    return <IframeContext.Provider value={value}>{props.children}</IframeContext.Provider>;
};

export const useIframeContext = () => useContext(IframeContext);
