import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Swing } from "./Swing";

export function SwingGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="swing"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Swing(mc), 2)}
            game={game}
            changeFramerate={true}
        />
    );
}
