import { loadSound } from "../../../animate/sound_utils";
import { MC, playSound, waitForEvent, waitForTimer } from "../../../animate/utils";
import { shuffle } from "../../../utils/MathUtils";
import { SpecialGameBasic } from "../../SpecialGameBasic";

export class MemoryGame extends SpecialGameBasic {
    cardPlaceX: number = 118;
    cardPlaceY: number = 116.5;
    cardWidth: number = 71.95;
    cardHeight: number = 74;
    cardSpace: number = 7;
    successPairs: number = 8;
    donePairs: number;
    tickets: MC[] = [];
    firstOpened: MC = null;
    winMc: MC = null;
    helpSound = "/week_games/memory_game/sounds/helpSound.mp3";
    bGSound = "/week_games/memory_game/sounds/music.mp3";
    pairSound = "/week_games/memory_game/sounds/pairSound.mp3";
    winSound = "/week_games/memory_game/sounds/winSound.mp3";

    cardNames = [1, 2, 3, 4, 5, 6, 7, 8];

    openCard = (card: MC) => {
        card.gotoAndStop(card.name);
    };

    removeAllCards = () => {
        this.tickets.forEach((ticket) => {
            this.root.removeChild(ticket);
        });
    };

    removeCard = async (card: MC) => {
        await createjs.Tween.get(card)
            .to({ alpha: 0 }, 700)
            .call(() => {
                this.root.removeChild(card);
                this.tickets.splice(this.tickets.indexOf(card), 1);
            });
    };

    closeCard = async (card: MC) => {
        card[`mcCard_${card.name}`].gotoAndPlay("closed");
        console.log(`await for event${card.name}`);
        await this.waitForEvent(card, card.name);
    };

    waitForEvent = async (card: MC, name: string) => {
        await waitForEvent(this.root, `CloseEvent${name}`);
        console.log(`after event${name}`);
        card[`mcCard_${name}`].gotoAndStop(0);
    };

    enableAllCards = () => {
        this.tickets.forEach((ticket) => {
            // if (ticket !== this.firstOpened) {
            this.enableCard(ticket);
            // }
        });
    };

    disableAllCards = (card: MC) => {
        this.tickets.forEach((ticket) => {
            if (ticket !== card) {
                this.disableCard(ticket);
            }
        });
    };

    disableCard = (card: MC) => {
        card.cursor = "default";
        card.mouseEnabled = false;
        // card.removeAllEventListeners();
    };

    replay = () => {
        this.removeAllCards();
        this.root.removeChild(this.winMc);
        this.createTickets();
    };

    handleBigSuccess = async () => {
        const win = new this.root.lib.win();
        win.x = 400;
        win.y = 300;
        win.cursor = "pointer";
        win.addEventListener("click", () => {
            this.replay();
        });
        this.winMc = win;
        this.root.addChild(win);
        await loadSound(this.winSound);
        playSound(this.winSound);
        await waitForEvent(this.root, "winEnd");
    };

    open = async (card: MC) => {
        this.disableAllCards(card);
        if (!this.firstOpened || (this.firstOpened && this.firstOpened.id !== card.id)) {
            card.gotoAndStop(0);
            this.openCard(card);
            await waitForEvent(this.root, "CardEvent");
        }

        this.disableCard(card);
        if (this.firstOpened) {
            if (this.firstOpened.id === card.id) {
                //click twice on card
                this.closeCard(card);
            }
            // if(card.name)
            else if (this.firstOpened.name === card.name) {
                playSound(this.pairSound);
                this.donePairs++;
                await waitForTimer(1000);
                this.removeCard(card);
                this.removeCard(this.firstOpened);
                if (this.donePairs === this.successPairs) {
                    this.handleBigSuccess();
                }
            } else {
                // this.enableHelp();
                await waitForTimer(1000);
                this.firstOpened && this.closeCard(this.firstOpened);
                this.closeCard(card);
            }
            this.firstOpened = null;
        } else {
            this.firstOpened = card;
        }
        this.enableAllCards();
    };

    enableCard = (card: MC) => {
        card.cursor = "pointer";
        card.mouseEnabled = true;
        // card.addEventListener("click", () => this.open(card));
    };

    initTicket = (x: number, y: number, name: string) => {
        const ticket = new this.root.lib.card();

        ticket.x = x;
        ticket.y = y;
        ticket.name = name;
        this.root.addChild(ticket);
        ticket.cursor = "pointer";
        ticket.addEventListener("click", () => this.open(ticket));
        // this.enableCard(ticket);
        ticket.gotoAndStop(0);
        this.tickets.push(ticket);
    };

    createTickets = () => {
        this.donePairs = 0;
        const shuffleArr = shuffle(this.cardNames.concat(this.cardNames));

        let cardIndex = 0;
        let cardX = this.cardPlaceX;
        let cardY = this.cardPlaceY;

        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 4; j++) {
                this.initTicket(cardX, cardY, shuffleArr[cardIndex]);
                cardIndex++;
                cardX += this.cardWidth + this.cardSpace;
            }
            cardX = this.cardPlaceX;
            cardY += this.cardHeight + this.cardSpace;
        }
    };

    loaded = async (root: MC) => {
        this.root = root;
        (window as any).memory = this.root;
        loadSound(this.pairSound);
        this.createTickets();
        this.initReset(this.replay);
        this.setBgSound();
        this.initExit();
        this.enableSpeaker();
        this.initSpeker();
        this.enableHelp();
        this.initHelp();
        this.initIconMc();
    };
}
