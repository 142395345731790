import { loadSound } from "../../animate/sound_utils";
import { MC, playSoundSync } from "../../animate/utils";

export class creationTooltip {
    private static nDelay: number = 750;
    private static current_over_mc: MC;
    private static nInterval: any;
    private static bEnabled: boolean = true;
    public static bActive: boolean;
    private static currentSound: createjs.AbstractSoundInstance;

    public static addToolTip = (mc: MC, soundID: string) => {
        mc.addEventListener("mouseover", () => {
            creationTooltip.current_over_mc = mc;
            creationTooltip.startDelayTooltip(mc, soundID);
        });
        mc.addEventListener("mouseout", () => {
            creationTooltip.current_over_mc = mc;
            creationTooltip.stopCurrentToolTip();
        });
        mc.addEventListener("pressup", () => {
            creationTooltip.stopCurrentToolTip();
            creationTooltip.current_over_mc = null;
        });
        mc.addEventListener("click", () => {
            creationTooltip.stopCurrentToolTip();
            creationTooltip.current_over_mc = null;
        });
    };

    public static stopCurrentToolTip = () => {
        if (!creationTooltip.bEnabled) return;
        creationTooltip.bActive = false;
        clearTimeout(creationTooltip.nInterval);
    };

    private static startDelayTooltip(mc: MC, sound: string) {
        creationTooltip.nInterval = setTimeout(() => {
            creationTooltip.doToolTip(mc, sound);
        }, creationTooltip.nDelay);
    }

    private static doToolTip = async (mc: MC, sound: string) => {
        if (!creationTooltip.bEnabled) return;
        if (creationTooltip.current_over_mc !== mc) return;
        creationTooltip.bActive = true;
        creationTooltip.currentSound && creationTooltip.currentSound.stop(); //stop previous sound played
        let oSound;
        if (sound.endsWith(".mp3")) {
            oSound = await loadSound(sound);
            oSound.play();
        } else {
            oSound = playSoundSync(sound);
        }
        oSound.on("complete", () => {
            creationTooltip.bActive = false;
        });
        creationTooltip.currentSound = oSound;
    };
}
