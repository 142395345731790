import React from "react";
import AnimateCC from "../../../animate";
import { RocketMenu } from "../RocketMenu";
import { LeavesGame } from "./LeavesGame";

const LeavesComponent: React.FC<{
    frame: RocketMenu;
}> = ({ frame }) => {
    const game = React.useRef(new LeavesGame(frame));

    return (
        <AnimateCC
            base="/lunapark/rocket/leaves"
            animationName="leaves"
            scale={0.75}
            shiftX={130}
            shiftY={5}
            onLoaded={game.current.loaded}
        />
    );
};
export default LeavesComponent;
