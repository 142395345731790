import React from "react";
import { MC } from "../../animate/utils";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Aqua } from "./Aqua";

export function AquaGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="aqua"
            // additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Aqua(mc), 2)}
            game={game}
        />
    );
}
