import { playSound, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { getBrowser } from "../../utils/BrowserUtils";
import MathGameBase from ".././MathGame/MathGameBase";

export class Hipo extends MathGameBase {
    private level?: number;
    private objects: createjs.MovieClip[][] = [[], []];
    successCount = 0;
    wrongCount = 0;
    wrongGame = 0;
    private targetCounters: number[] = [];
    private ropePull: boolean = false;
    actionNow: boolean = false;
    canAddMore0: boolean = true;
    canAddMore1: boolean = true;
    name = "hippo";

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).root = root;
    }

    chooserDown = () => {
        this.handleLevel();
    };

    chooserUp = () => {
        this.root.hipo_mc.stop();
        this.root.bubble_mc.stop();
    };

    getWrongString = () => {
        if (this.level === 1) {
            if (
                (this.targetCounters[0] === 0 && this.objects[0].length !== 0) ||
                (this.targetCounters[1] === 0 && this.objects[1].length !== 0)
            ) {
                return `again${getRandomNumber(1, 3)}`;
            }
        }
        if (this.targetCounters[0] > this.objects[0].length) {
            return "NotEnoughWatermelon";
        }
        if (this.targetCounters[1] > this.objects[1].length) {
            return "NotEnoughTuna";
        }
        if (this.targetCounters[0] < this.objects[0].length) {
            return "TooMuchWatermelon";
        }
        if (this.targetCounters[1] < this.objects[1].length) {
            return "TooMuchTuna";
        }
    };

    handleWrong = () => {
        this.stopAction(true);
        this.wrongCount++;
        if (this.wrongCount === 3) {
            playSound(`wrong${getRandomNumber(1, 3)}`);
            this.handleLevel();
            this.wrongCount = 0;
            this.wrongGame++;
        } else {
            const wrongStr = this.getWrongString();
            playSound(wrongStr);
        }
        this.ropePull = false;
    };

    startAction = (action: boolean) => {
        this.actionNow = action;
        action ? this.enableHelp() : this.disableHelp();

        const rope = this.root.shelf_mc.rope_mc;
        rope.cursor = "default";
        rope.removeEventListener("click");
        this.root.barrel1_mc.removeAllEventListeners();
        this.root.barrel2_mc.removeAllEventListeners();
        this.root.barrel1_mc.cursor = "default";
        this.root.barrel2_mc.cursor = "default";
    };

    canAdd = (type: 0 | 1) => {
        return (this as any)[`canAddMore${type}`];
    };

    addPlaySoundListener = (type: 0 | 1) => {
        this.root[`barrel${type + 1}_mc`].addEventListener("mousedown", () => {
            playSound("MoveObject");
        });
    };

    current_object_mc: createjs.MovieClip;
    xPos: number;
    yPos: number;

    createNewObject = (type: 0 | 1) => {
        if (!this.actionNow) return;
        if (!this.canAdd(type)) {
            this.root[`barrel${type + 1}_mc`].removeAllEventListeners();
            this.addPlaySoundListener(type);
            return;
        }
        this.root[`barrel${type + 1}_mc`].addEventListener("pressmove", this.onMove);
        this.root[`barrel${type + 1}_mc`].addEventListener("pressup", this.onRelease);

        const holderMc = this.root.shelf_mc.objects_holder_mc;

        if (type === 0) {
            this.current_object_mc = new this.root.lib.watermelon_mc();
            this.current_object_mc.scaleX = this.current_object_mc.scaleY = 0.9;
            this.xPos = this.objects[0].length * 54 + 25;
            this.yPos = -30;
            this.current_object_mc.regY = -30;
            this.current_object_mc.regX = 25;
        } else {
            this.current_object_mc = new this.root.lib.tuna();
            this.current_object_mc.scaleX = this.current_object_mc.scaleY = 0.7;
            this.xPos = 510 - this.objects[1].length * 54;
            this.yPos = -17;
            this.current_object_mc.regY = -14;
        }
        (this.current_object_mc as any).type = type;
        this.current_object_mc.cursor = "pointer";
        holderMc.setChildIndex(this.current_object_mc, 8);
        this.root.setChildIndex(this.root.barrel2_mc, 6);
        this.root.setChildIndex(this.root.barrel1_mc, 7);
        this.objects[type].push(this.current_object_mc);
        if (this.objects[type].length >= 10) {
            (this as any)[`canAddMore${type}`] = false;
        }
        holderMc.addChild(this.current_object_mc);
        if (type === 0) {
            holderMc.setChildIndex(this.current_object_mc, 1);
        }
        if (holderMc.children.length === 20) {
            holderMc.setChildIndex(this.objects[1][0], 2);
        }
        this.current_object_mc.addEventListener("click", (e) => this.removeObject(e as createjs.MouseEvent));
        this.onMove();
    };

    onMove = () => {
        const holderMc = this.root.shelf_mc.objects_holder_mc;
        const position = holderMc.globalToLocal(holderMc.stage.mouseX, holderMc.stage.mouseY);
        this.current_object_mc.x = position.x;
        this.current_object_mc.y = position.y;
        this.root.barrel1_mc.removeEventListener("pressmove");
        this.root.barrel2_mc.removeEventListener("pressmove");
    };

    createWatermelon = () => {
        this.createNewObject(0);
    };

    createTuna = () => {
        this.createNewObject(1);
    };

    onRelease = () => {
        playSound("MoveObject");
        createjs.Tween.get(this.current_object_mc)
            .to({ x: this.xPos, y: this.yPos }, 1800, createjs.Ease.cubicOut)
            .call(() => {});
        this.root.barrel1_mc.removeEventListener("pressup");
        this.root.barrel2_mc.removeEventListener("pressup");
    };

    stopAction = (error: boolean) => {
        const setBarrelListener = (type: 1 | 0) => {
            if (this.objects[type].length < 10) {
                this.setCanAddMore(type);
            } else {
                this.addPlaySoundListener(type);
            }
        };
        this.actionNow = true;
        this.enableHelp();
        const rope = this.root.shelf_mc.rope_mc;
        const hit = new createjs.Shape();
        hit.graphics.beginFill("#000").drawRect(-331, -135, 82, error ? 310 : 260);
        rope.hitArea = hit;
        rope.cursor = "pointer";
        rope.addEventListener("click", this.onRopePull);
        setBarrelListener(0);
        setBarrelListener(1);
        this.root.barrel1_mc.cursor = "pointer";
        this.root.barrel2_mc.cursor = "pointer";
    };

    onRopePull = () => {
        if (this.ropePull || !this.actionNow) return;
        this.startAction(true);
        this.root.shelf_mc.rope_mc.gotoAndPlay("end");
        this.ropePull = true;
        if (this.targetCounters[0] === this.objects[0].length && this.targetCounters[1] === this.objects[1].length) {
            this.handleSuccess();
        } else {
            console.log("Oops", this.objects, this.targetCounters);
            this.handleWrong();
        }
    };

    handleSuccess = async () => {
        this.actionNow = true;
        this.enableHelp();
        this.wrongCount = 0;
        this.hideShelf();
        this.successCount++;
        const remark = `good${getRandomNumber(0, 10)}`;
        this.root.shelf_mc.gotoAndPlay("end");
        this.root.bubble_mc.gotoAndPlay("end");
        this.root.hipo_mc.gotoAndPlay("end");
        await playSound(remark);
        this.addPrize("watermelon_prize");
        for (let i = 0; i < this.objects[0].length; i++) {
            await this.eatWatermelon();
        }
        if (this.objects[0].length > 0) {
            await waitForTimer(1000);
        }
        for (let i = 0; i < this.objects[1].length; i++) {
            await this.eatTuna();
        }
        await this.finishEat();
        if (this.successCount === 5) {
            this.handleGameEnd();
        } else {
            this.handleLevel();
        }
    };

    disableGame = () => {
        this.actionNow = true;
        this.root.barrel1_mc.cursor = "default";
        this.root.barrel2_mc.cursor = "default";
        this.root.shelf_mc.rope_mc.cursor = "default";
    };

    handleLevel = async (level?: number) => {
        this.onGame = true;
        this.ropePull = false;
        this.openMenu = false;
        this.root.shelf_mc.objects_holder_mc.removeAllChildren();
        this.objects = [[], []];
        this.disableGame();
        this.disableHelp();
        if (level) {
            this.cleanPrizes(true);
            if (!this.level) {
                playSound("inst");
            }
            this.level = level;
        }
        this.setCanAddMore(0);
        this.setCanAddMore(1);
        this.actionNow = false;
        if (this.level === 1) {
            const type = getRandomNumber(1, 2);
            type === 1
                ? this.showQuestion(getRandomNumber(), 0, level)
                : this.showQuestion(0, getRandomNumber(), level);
        } else {
            this.showQuestion(getRandomNumber(1, 6), getRandomNumber(1, 6), level);
        }
    };

    setCanAddMore = (type: 1 | 0) => {
        (this as any)[`canAddMore${type}`] = true;
        this.root[`barrel${type + 1}_mc`].removeAllEventListeners();
        this.root[`barrel${type + 1}_mc`].addEventListener(
            "mousedown",
            type === 0 ? this.createWatermelon : this.createTuna
        );
    };

    finishGame = () => {
        this.root.hipo_mc.gotoAndPlay("finish eat");
        return waitForEvent(this.root, "animationEnd");
    };

    handleGameEnd = async () => {
        this.finishGame().then(() => {
            this.root.hipo_mc.gotoAndPlay("go to start");
            this.successCount = 0;
            this.cleanPrizes();
        });
    };

    orderIndexes = () => {
        this.root.setChildIndex(this.root.shelf_mc, 7);
        this.root.setChildIndex(this.root.hipo_mc, 6);
        this.root.setChildIndex(this.root.bubble_mc, 1);
        this.root.setChildIndex(this.root.barrel2_mc, 8);
        this.root.setChildIndex(this.root.barrel1_mc, 8);
    };

    removeObject = (e: createjs.MouseEvent) => {
        const itemToRemove = e.currentTarget;
        const type = itemToRemove.type;
        const currIndex = this.objects[type].indexOf(itemToRemove);
        this.objects[type].splice(currIndex, 1);
        const xPos = this.root[`barrel${type + 1}_mc`].x - 30;
        const yPos = this.root[`barrel${type + 1}_mc`].y - 120;
        this.orderIndexes();
        const animFinished = () => {
            this.root.shelf_mc.objects_holder_mc.removeChild(itemToRemove);
            if (!this.canAdd(type) && this.objects[type].length < 10) {
                this.setCanAddMore(type);
            }
        };
        createjs.Tween.get(itemToRemove)
            .to({ x: xPos, y: yPos }, 1400, createjs.Ease.cubicOut)
            .call(animFinished, [itemToRemove], animFinished);
        for (let index = currIndex; index < this.objects[type].length; index++) {
            const itemToMove = this.objects[type][index];
            const xPos = type === 0 ? index * 54 + 25 : 510 - index * 54;

            createjs.Tween.get(itemToMove)
                .to({ x: xPos }, 1800, createjs.Ease.cubicOut)
                .call(() => {});
        }
    };

    loaded = () => {
        this.onGame = true;
        this.startAction(false);
    };

    unload = () => {
        this.successCount = 0;
        this.wrongCount = 0;
        this.wrongGame = 0;
        this.root.barrel1_mc.removeAllEventListeners();
        this.root.barrel2_mc.removeAllEventListeners();
        stopAllSounds();
    };

    finishEat = () => {
        this.root.hipo_mc.gotoAndPlay("close");
        return waitForEvent(this.root, "animationEnd");
    };

    showShelf = () => {
        this.root.shelf_mc.gotoAndPlay("start");
        this.root.shelf_mc.rope_mc.gotoAndPlay("start");
        this.root.hipo_mc.gotoAndPlay("start");
    };

    hideShelf = () => {
        this.root.shelf_mc.gotoAndPlay("end");
    };

    eatWatermelon = () => {
        this.root.hipo_mc.gotoAndPlay("eat1");
        return waitForEvent(this.root, "animationEnd");
    };

    eatTuna = () => {
        this.root.hipo_mc.gotoAndPlay("eat2");
        return waitForEvent(this.root, "animationEnd");
    };

    showQuestion = async (num1: number, num2: number, level?: number) => {
        const isSafari = getBrowser() === "Safari";
        await waitForTimer(level ? 1000 : 0);
        this.showShelf();
        this.targetCounters = [num1, num2];
        const bubbleMc = this.root.bubble_mc;
        let questionMc = bubbleMc.question_mc;
        questionMc.txt_1.alpha = 0;
        questionMc.objects1.alpha = 0;
        questionMc.objects1.gotoAndStop(0);
        questionMc.txt_2.alpha = 0;
        questionMc.objects2.alpha = 0;
        questionMc.objects2.gotoAndStop(1);

        if (num1 && num2) {
            questionMc.gotoAndStop(1);
        } else {
            questionMc.gotoAndStop(0);
        }
        const hipoWant: string[] = [];
        if (num1) {
            hipoWant.push(`Watermelon${num1}`);
            if (num2) {
                hipoWant.push(`AndTuna${num2}`);
            }
        } else if (num2) {
            hipoWant.push(`Tuna${num2}`);
        }
        if (num1) {
            questionMc.txt_1.text = "" + num1;
            questionMc.txt_1.alpha = 1;
            questionMc.objects1.alpha = 1;
            if (!isSafari) questionMc.txt_1.y = 40;
        }
        if (num2) {
            questionMc.txt_2.text = "" + num2;
            questionMc.txt_2.alpha = 1;
            questionMc.objects2.alpha = 1;
            if (!num1) {
                questionMc.objects2.y = 10;
                questionMc.txt_2.y = isSafari ? 20 : 40;
                questionMc.objects2.scaleX = questionMc.objects2.scaleY = 0.8;
                questionMc.txt_2.scaleX = questionMc.txt_2.scaleY = 1;
            }
            if (num1 && num2) {
                questionMc.objects1.scaleX = questionMc.objects1.scaleY = 0.8;
                questionMc.txt_1.scaleX = questionMc.txt_1.scaleY = 0.8;
                if (isSafari) {
                    questionMc.txt_1.y = 10;
                    questionMc.txt_2.y = 60;
                } else {
                    questionMc.txt_1.y = 20;
                    questionMc.txt_2.y = 70;
                }
                questionMc.objects2.scaleX = questionMc.objects2.scaleY = 0.8;
                questionMc.txt_2.scaleX = questionMc.txt_2.scaleY = 0.8;
            }
        }
        bubbleMc.gotoAndPlay("start");
        await waitForTimer(2500);
        if (!this.openMenu && this.onGame) {
            playSound("Buawav");
        }
        await waitForTimer(500);
        if (!this.openMenu && this.onGame) {
            await playSound(hipoWant[0]);
            hipoWant[1] && (await playSound(hipoWant[1]));
        }
        this.root.hipo_mc.stop();
        this.enableHelp();
        this.stopAction(false);
    };
}
