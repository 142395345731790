import { Madorim } from "../context/UserContext";

export const permissions = async () => {
    const response = await fetch("/UserPermission.aspx");
    const data = await response.json();

    const permissions: Madorim = {
        english_time: true,
        dubby_english: true,
        letters: true,
        math: true,
        music: true,
        bible_stories: true,
    };

    data.forEach((mador: { [key: string]: any }) => {
        switch (mador.Guid) {
            case "eb02efe4-887e-4164-86e1-691092b9ccaa":
                permissions.math = mador.Permission;
                break;
            case "c8f17521-1e38-4290-8951-53a56cba180a":
                permissions.english_time = mador.Permission;
                permissions.dubby_english = mador.Permission;
                break;
            case "e66a310e-44e5-4f6d-b6e1-b7d9ba01fb2d":
                permissions.music = mador.Permission;
                break;
            case "edbc89ad-da5d-44e1-a346-6a3a39c9243d":
                permissions.bible_stories = mador.Permission;
                break;
            case "a992e063-570f-40c8-8619-3f0fd766cd2c":
                permissions.letters = mador.Permission;
                break;
        }
    });
    return permissions;
};
