import { MC, playSoundSync, waitForTimer } from "../../../animate/utils";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { gamesIndex } from "../../../utils/ReviewGameUtils";

import { routes } from "../../../navigation/routesPath";
import { Navigation } from "../../../navigation/navigation";

export class ReviewMenu {
    root: MC;
    private gamesStatus: any = {};
    buttonSound: any = null;
    originX: Array<number> = [];
    originY: Array<number> = [];
    bgSound: createjs.AbstractSoundInstance;
    gamesToPlay: number;
    hovering: boolean;
    rollout: any;

    constructor(private sectionIndex: number) {}

    navigateBack = () => {
        const buttonsArr = ["back_btn", "exit_btn"];
        for (let i = 0; i < buttonsArr.length; i++) {
            const currentButton = this.root[buttonsArr[i]];
            currentButton.gotoAndStop("normal");
            // currentButton.scale=1.25;
            this.throwEventListener(currentButton, null, "over", "normal");
            currentButton.name === "exit_btn" && SoundToolTip.addToolTip(currentButton, "home");
            currentButton.name === "back_btn" && SoundToolTip.addToolTip(currentButton, "back");

            if (currentButton.name === "back_btn") {
                currentButton.x = currentButton.x - 80;
                currentButton.y = -35;
            } else {
                currentButton.x = currentButton.x + 60;
                currentButton.y = -35;
            }
            // currentButton.name === "exit_btn" &&
            //     this.throwEventListener(currentButton, "exit", null, null);
            currentButton.name === "exit_btn" &&
                currentButton.addEventListener("click", () => {
                    this.bgSound.volume = 0;
                    Navigation.openPage(routes.home);
                });
            currentButton.name === "back_btn" &&
                currentButton.addEventListener("click", () => {
                    if (this.bgSound) this.bgSound.volume = 0;
                    Navigation.openPage(routes.letters.menu);
                });
            //add back to main menu - letter Island
        }
    };
    throwEventListener = (
        currentObj: MC,
        SoundParameterName: string,
        roollhoverParameter: string | number,
        rolloutParameter: string | number
    ) => {
        currentObj.gotoAndStop(0);
        currentObj.cursor = "pointer";
        if (SoundParameterName) {
            SoundToolTip.addToolTip(currentObj, SoundParameterName);
        }
        currentObj.addEventListener("rollover", () => {
            if (roollhoverParameter) currentObj.gotoAndStop(roollhoverParameter);
        });
        currentObj.addEventListener("rollout", () => {
            if (rolloutParameter) currentObj.gotoAndStop(rolloutParameter);
        });
    };

    calcGamesToPlay() {
        if (this.sectionIndex < 3) {
            this.gamesToPlay = 2;
        } else if (this.sectionIndex === 3) {
            this.gamesToPlay = 3;
        } else {
            this.gamesToPlay = 4;
        }
    }

    initGames = async () => {
        const btn_arr = ["kite", "box", "fish", "pinguin"];
        const test_btn = this.root.btn_test;
        for (let i = 1; i < btn_arr.length; i++) this.root[`btn_${btn_arr[i]}`].visible = false;

        //order on page
        const bigBtnW = 140;
        const smallBtnW = 108;
        const padding = 20;
        const marginLeft = (800 - bigBtnW - (smallBtnW + padding) * this.gamesToPlay) / 2;

        let nextBtnX = marginLeft + bigBtnW / 2;
        test_btn.x = nextBtnX;
        nextBtnX += bigBtnW / 2 + +smallBtnW / 2 + padding;
        for (let x = 0; x < this.gamesToPlay; x++) {
            const currentButton = this.root[`btn_${btn_arr[x]}`];
            currentButton.x = nextBtnX;
            currentButton.y = 500;
            nextBtnX += padding + smallBtnW;
        }
        //show and animate
        for (let i = 0; i < this.gamesToPlay; i++) {
            const currentButton = this.root[`btn_${btn_arr[i]}`];
            this.animateInitGameBtn(currentButton, (i + 1) * 300, i + 1, i + 1);
        }
        this.animateInitGameBtn(test_btn, (this.gamesToPlay + 1) * 300, 0, "test");
    };

    animateInitGameBtn = (currentButton: MC, waitParam: number, i: number, doneIndex: number | string) => {
        const gameDone = this.gamesStatus[doneIndex];
        const selected = gameDone ? "selected_" : "";

        currentButton.scaleX = 0;
        currentButton.visible = true;
        createjs.Tween.get(currentButton, { override: false })
            .wait(waitParam + 100)
            .to(
                {
                    scaleX: 1,
                },
                400,
                createjs.Ease.cubicOut
            )
            .call(() => {});
        currentButton.gotoAndStop(`${selected}up`);
        this.originX[i] = currentButton.x;
        this.originY[i] = currentButton.y;
        currentButton.addEventListener("rollover", () => {
            const gameDone = this.gamesStatus[doneIndex];
            const selected = gameDone ? "selected_" : "";
            this.hovering = true;
            currentButton.gotoAndStop(`${selected}over`);
            currentButton.cursor = "pointer";
            this.animateToObjOver(currentButton, i);
        });
        this.rollout = currentButton.on("rollout", () => {
            const gameDone = this.gamesStatus[doneIndex];
            const selected = gameDone ? "selected_" : "";

            this.hovering = true;
            currentButton.gotoAndStop(`${selected}up`);
            this.animateToObjOut(currentButton, i);
        });
        currentButton.addEventListener("click", () => {
            const gameDone = this.gamesStatus[doneIndex];
            const selected = gameDone ? "selected_" : "";
            currentButton.gotoAndStop(`${selected}down`);
            if (this.bgSound) this.bgSound.volume = 0;
            currentButton.gotoAndStop(`${selected}up`);
            this.animateToObjOut(currentButton, i);
            currentButton.removeEventListener("rollout", this.rollout);
            let path: string;
            switch (currentButton.name) {
                case "btn_box":
                    path = routes.letters.review.wood_boxes;
                    break;
                case "btn_pinguin":
                    path = routes.letters.review.penguins;
                    break;
                case "btn_kite":
                    path = routes.letters.review.kites;
                    break;
                case "btn_fish":
                    path = routes.letters.review.fishes;
                    break;
                case "btn_test":
                    path = routes.letters.review.quiz;
                    break;
            }
            Navigation.openPage(path.replace(":sectionIndex", this.sectionIndex + ""));
        });
    };

    animateToObjOver = async (currentObj: MC, index: number) => {
        // let gotoX: number;
        let gotoY: number;

        if (currentObj.name === "btn_test") {
            // gotoX = this.originX[index] - 139.4 / 8;
            gotoY = this.originY[index] - 138.6 / 8;
        } else {
            // gotoX = this.originX[index] - 107.6 / 8;
            gotoY = this.originY[index] - 107.55 / 8;
        }
        createjs.Tween.get(currentObj, { override: true })
            .wait(100)
            .to(
                {
                    scaleX: 1.2,
                    scaleY: 1.2,
                    y: gotoY /* , x: gotoX - 7, y: gotoY */,
                    x: currentObj.x - 5,
                },
                600,
                createjs.Ease.cubicOut
            )
            .call(() => {});
        await waitForTimer(200);
        if (this.hovering) {
            createjs.Tween.get(currentObj, { override: false }).to(
                {
                    scaleX: 1.25,
                    scaleY: 1.25,
                    x: currentObj.x + 5,
                },
                1500,
                createjs.Ease.cubicOut
            );
        }
    };

    animateToObjOut = async (currentObj: MC, index: number) => {
        createjs.Tween.get(currentObj, { override: true })
            .to(
                {
                    scaleX: 1.1,
                    scaleY: 1.1,
                    y: this.originY[index],
                    x: this.originX[index],
                },
                500
            )
            .call(() => {});
        await waitForTimer(200);
        createjs.Tween.get(currentObj, { override: true })
            .to(
                {
                    scaleX: 1,
                    scaleY: 1,
                },
                500
            )
            .call(() => {});
    };

    loaded = (root: MC) => {
        this.root = root;

        this.calcGamesToPlay();
        this.updateButtons();

        this.bgSound = playSoundSync("background", { loop: -1 });
        this.root.preloader_mc.visible = false;
        this.root.preloader_mc.alpha = 0;
        this.initGames();
        this.navigateBack();
    };

    updateGameData = (gamesStatus: any) => {
        this.gamesStatus = gamesStatus;
        if (!this.root) return;

        this.updateButtons();
    };

    updateButtons = () => {
        Object.keys(this.gamesStatus).forEach((index: any) => {
            const btnName = gamesIndex[index];
            const done = this.gamesStatus[index];
            this.root[`btn_${btnName}`].gotoAndStop(done ? "selected_up" : 0);
        });
    };

    muteUnmuteBgSound = (mute: boolean) => {
        if (this.bgSound) {
            if (mute) {
                this.bgSound.stop();
            } else {
                this.bgSound.play({ loop: -1, volume: 1 });
            }
        }
    };
}
