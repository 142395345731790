import React, { useEffect, useState } from "react";
import AnimateCC from "../animate";
import { useUserContext } from "../context/UserContext";
import { additionalSounds } from "./mainscreenSounds";
import { MainscreenMenu } from "./MainscreenMenu";
import { useLocation } from "react-router-dom";
import { routes } from "../navigation/routesPath";
import { displayIf } from "../utils/StyleUtils";
import { InitialScreenComponent } from "./initialScreen/InitialScreenComponent";
import { MC, waitFor } from "../animate/utils";
import { useMovieContext } from "../context/MovieContext";
import { useIframeContext } from "../context/IframeContext";
import LoginComponent from "../loginnew/LoginComponent";
import { SodMayaTracking } from "../navigation/GoogleAnalytics";
import { Navigation } from "../navigation/navigation";
import { PreloaderComponent } from "../loaders/PreloaderComponent";
import SessionManagerComponent from "../sessionManager/sessionManagerComponent";

const POPUP_NAME = "sodMayaGame";

function SafeURL(url: string) {
    let resultUrl;
    try {
        resultUrl = new URL(url).hostname.endsWith(".cet.ac.il") ? url : "";
    } catch (e) {
        resultUrl = "";
    }

    return resultUrl;
}

const HomeComponent: React.FC = () => {
    const { pathname } = useLocation();
    const directLink = new URLSearchParams(window.location.search).get("path");
    const currentDateParam = new URLSearchParams(window.location.search).get("currentDate");
    const isFromExternalLogin = new URLSearchParams(window.location.search).has("autologin");
    const externalIframe = SafeURL(new URLSearchParams(window.location.search).get("iframe"));

    const currentDate = currentDateParam ? new Date(currentDateParam) : new Date();

    const [showInitialScreen, setShowInitialScreen] = useState(true);
    const [screenReady, setScreenReady] = useState(false);

    const { isLoggedIn, permissions, logout, currentUser } = useUserContext();

    const movieContext = useMovieContext();

    const gameWindow = React.useRef<any>();
    const mainScreen = React.useRef(new MainscreenMenu(isLoggedIn, logout, permissions, movieContext));

    const { iframeSrc, showLogin, setShowLogin } = useIframeContext();

    useEffect(() => {
        console.log("!!!", mainScreen.current.isLoaded);

        mainScreen.current.updatePermissions(permissions);
    }, [permissions]);

    useEffect(() => {
        const w = window as any;
        w.startGame = (path?: string) => {
            setShowInitialScreen(false);
            if (path) {
                Navigation.openPage(path);
            }
        };
        return () => {
            delete w.startGame;
        };
    }, []);

    useEffect(() => {
        if (screenReady) {
            mainScreen.current.initEnterBtn(isLoggedIn);
        }
    }, [isLoggedIn, screenReady, currentUser, pathname]);

    useEffect(() => {
        if (pathname === routes.home) {
            if (iframeSrc || externalIframe) {
                mainScreen.current.stopSounds();
            } else if (screenReady) {
                mainScreen.current.undoMagic();
            } else {
                mainScreen.current.updateMuteStatus();
            }
        }
    }, [externalIframe, iframeSrc, pathname, screenReady]);

    useEffect(() => {
        if (screenReady && (directLink || externalIframe)) {
            const { location } = window;
            var clean_uri = location.protocol + "//" + location.host + location.pathname;
            window.history.replaceState({}, document.title, clean_uri);
        }
    }, [screenReady, directLink, externalIframe]);

    useEffect(() => {
        if (pathname === routes.home) {
            if (window.name === POPUP_NAME) {
                SodMayaTracking("/menu");
            } else {
                SodMayaTracking("/");
            }
        }
    }, [pathname]);

    useEffect(() => {
        if (isLoggedIn) {
            mainScreen.current.updateCampaignBtn(false);
        } else {
            mainScreen.current.updateCampaignBtn(true);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (externalIframe) {
            setShowInitialScreen(false);
            Navigation.openIframe(externalIframe);
        }
    }, [externalIframe]);

    return showInitialScreen ? (
        <InitialScreenComponent
            style={displayIf(pathname === routes.home)}
            isPopup={window.name === POPUP_NAME}
            showMayaRoom={async () => {
                if (window.name === POPUP_NAME) {
                    setShowInitialScreen(false);
                } else if (gameWindow.current == null || gameWindow.current.closed) {
                    const params = [
                        "height=" + window.screen.height,
                        "width=" + window.screen.width,
                        "fullscreen=yes", // only works in IE, but here for completeness
                    ].join(",");
                    gameWindow.current = window.open(window.location.href, POPUP_NAME, params);
                    await waitFor(() => gameWindow.current.startGame);
                    //DoLogin if needed
                    gameWindow.current.startGame(directLink);
                } else {
                    //DoLogin if needed
                    gameWindow.current.focus();
                }
            }}
        />
    ) : (
        <>
            {!iframeSrc && !screenReady && (!directLink || (directLink && pathname === routes.home)) && (
                <PreloaderComponent name="plain" />
            )}
            {(!directLink || (directLink && pathname === routes.home)) && (
                <AnimateCC
                    base="/mainscreen"
                    animationName="mainscreen"
                    onLoaded={(mc: MC) => {
                        mainScreen.current.loaded(mc, currentDate, directLink === null && externalIframe === null);
                        setScreenReady(true);
                    }}
                    additionalSounds={additionalSounds}
                    visible={!iframeSrc && pathname === routes.home}
                />
            )}
            {iframeSrc && (
                <iframe
                    id="parentInfoIframe"
                    title="info"
                    src={iframeSrc}
                    style={{
                        border: 0,
                        margin: "0",
                        display: "block",
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                        ...displayIf(!showLogin),
                    }}
                />
            )}
            {iframeSrc && showLogin && (
                <LoginComponent
                    loginAfterLocation={"/"}
                    onLoggedIn={() => {
                        (document.getElementById(
                            "parentInfoIframe"
                        ) as HTMLIFrameElement).contentWindow.location.reload();
                        setShowLogin(false);
                    }}
                />
            )}
            {isFromExternalLogin && !isLoggedIn && <SessionManagerComponent />}
        </>
    );
};

export default HomeComponent;
