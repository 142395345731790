import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, playSoundSync, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { getRandomArr, getRandomNumber } from "../../utils/MathUtils";
import MathGameBase from "../MathGame/MathGameBase";

export class Frogs extends MathGameBase {
    frogs: number[];
    digits: number[];
    chosenLevel: number;
    bSmallest: boolean;
    answer: number;
    answerOptions: number[];
    frogFramesArr: number[];
    frogsDisplayed: MC[] = [];
    correctAnswers: number = 0;
    enableClick: boolean = true;
    bgSounds: createjs.AbstractSoundInstance;
    allDigits: number[];
    gamesPlayed: number = 0;
    levelInfo = [
        { level: 1, frogs: [2], digits: [1, 2, 3, 4, 5, 6], bSmallest: false },
        { level: 2, frogs: [3, 4], digits: [5, 6, 7, 8, 9, 10], bSmallest: false },
        { level: 3, frogs: [3, 4], digits: [1, 2, 3, 4, 5, 6], bSmallest: true },
    ];
    name = "frogs";
    resetSound: boolean;
    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).frogs = root;
    }

    handleLevel = async (level?: number) => {
        this.disableHelp();
        this.clearFrogs();
        this.clearQuestionProps();
        this.resetProps();
        this.cleanPrizes(true);
        this.chosenLevel = level;
        this.levelInfo.forEach((info: any) => {
            if (info.level === this.chosenLevel) {
                this.frogs = info.frogs;
                this.bSmallest = info.bSmallest;
                info.digits.forEach((digit: number) => {
                    this.digits.push(digit);
                    this.allDigits.push(digit);
                });
            }
        });
        this.resetSound && playSoundSync("frogsBg", { loop: -1 });
        this.startGame();
    };

    clearFrogs = () => {
        for (let i = 1; i < 5; i++) {
            const frog = this.root[`frog${i}_mc`];
            frog.gotoAndStop(0);
        }
    };

    chooserUp = () => {
        stopAllSounds();
        this.frogsDisplayed.forEach((frog: MC) => {
            frog.stop();
            this.enableClick = false;
        });
        this.resetSound = true;
    };

    chooserDown = () => {
        this.clearFrogs();
        this.resetSound && playSoundSync("frogsBg", { loop: -1 });
        this.enableClick = true;
        // this.root.
        this.newQuestion();
    };

    resetProps = () => {
        this.gamesPlayed = 0;
        this.frogs = [];
        this.digits = [];
        this.bSmallest = false;
        this.answer = null;
        this.answerOptions = [];
        this.frogFramesArr = [];
        this.frogsDisplayed = [];
        this.allDigits = [];
        this.enableClick = true;
        this.correctAnswers = 0;
    };

    clearQuestionProps = () => {
        this.answer = null;
        this.answerOptions = [];
        this.frogFramesArr = [];
        this.frogsDisplayed.forEach((frog: MC) => {
            frog.removeAllEventListeners();
        });
        this.frogsDisplayed = [];
    };

    startGame = () => {
        this.newQuestion();
    };
    newQuestion = () => {
        this.clearQuestionProps();
        this.generateQuestion();
    };

    randomFrogsNum = () => {
        const num = getRandomNumber(0, this.frogs.length - 1);
        return this.frogs[num];
    };

    generateQuestion = async () => {
        const frogsNumber = this.randomFrogsNum();

        if (this.digits.length < frogsNumber) {
            this.digits = [];
            this.allDigits.forEach((digit: number) => {
                this.digits.push(digit);
            });
        }

        let rnd: number;

        for (var x = 0; x < frogsNumber; x++) {
            rnd = getRandomNumber(0, this.digits.length - 1);
            this.answerOptions.push(this.digits[rnd]);
            this.digits.splice(rnd, 1);
        }

        if (this.bSmallest) {
            this.answer = this.getMin();
        } else {
            this.answer = this.getMax();
        }
        this.ramdomizeFrogsToDisplay(frogsNumber);
        await this.frogsEnter(frogsNumber);
        await this.displayQuestion();
        this.playInst();
    };

    getMax = () => {
        let max: number;
        for (var x = 0; x < this.answerOptions.length; x++) {
            if (max != null) {
                if (this.answerOptions[x] > max) max = this.answerOptions[x];
            } else {
                max = this.answerOptions[x];
            }
        }
        return max;
    };

    getMin = () => {
        let min: number;
        for (var x = 0; x < this.answerOptions.length; x++) {
            if (min != null) {
                if (this.answerOptions[x] < min) min = this.answerOptions[x];
            } else {
                min = this.answerOptions[x];
            }
        }
        return min;
    };

    ramdomizeFrogsToDisplay = (frogsNumber: number) => {
        let numbersRandomed: number[];
        numbersRandomed = [];
        for (let i = 0; i < frogsNumber; i++) {
            let num;
            do {
                num = getRandomNumber(1, 4);
            } while (numbersRandomed.includes(num));
            numbersRandomed.push(num);
            const frog = this.root[`frog${num}_mc`];
            this.frogsDisplayed.push(frog);
        }
    };

    frogsEnter = async (frogsNumber: number) => {
        let promises: any[] = [];
        for (let i = 0; i < this.frogsDisplayed.length; i++) {
            const frog = this.frogsDisplayed[i];
            const rndFrame = getRandomNumber(1, 2);
            this.frogFramesArr.push(rndFrame);
            frog.gotoAndPlay(`start${rndFrame}`);
            promises.push(waitForEvent(frog, `finishedStart${rndFrame}`));
            await waitForTimer(500);
        }
        return Promise.all(promises);
    };

    displayQuestion = async () => {
        await loadSound("/math/sounds/frogs/Slap.mp3");
        let promises: any[] = [];
        for (let i = 0; i < this.frogsDisplayed.length; i++) {
            const frog = this.frogsDisplayed[i];
            frog.num_holder.num_txt.text = this.answerOptions[i].toString();
            frog.addEventListener("click", () => {
                this.enableClick && this.clickFrog(frog, this.answerOptions[i]);
            });
            frog.cursor = "pointer";
            frog.gotoAndPlay("flagup");
            // playSound(`/math/sounds/frogs/Slap.mp3`);
            waitForEvent(frog, `playSound`).then(async () => {
                playSound(`/math/sounds/frogs/Slap.mp3`);
            });
            promises.push(waitForEvent(frog, `endFlag`));
            const delay = getRandomNumber(2, 5) * 100;
            await waitForTimer(delay);
        }
        return Promise.all(promises);
    };

    playInst = async () => {
        await loadSound(`/math/sounds/frogs/frogs_inst_level_${this.chosenLevel}.mp3`);
        await playSound(`/math/sounds/frogs/frogs_inst_level_${this.chosenLevel}.mp3`);
        this.enableHelp();
    };

    sayGood = async () => {
        const num = getRandomNumber(1, 9);
        await playSound(`m_good${num}`);
    };

    playWrong = async () => {
        const num = getRandomNumber(1, 2);
        await loadSound(`/math/sounds/frogs/wrong${num}.mp3`);
        await playSound(`/math/sounds/frogs/wrong${num}.mp3`);
    };

    clickFrog = async (frog: MC, numberClicked: number) => {
        this.enableClick = false;
        if (numberClicked === this.answer) {
            this.disableHelp();
            this.correctAnswers++;
            this.addPrize("prize_frog");
            if (this.correctAnswers === 5) {
                await this.sayGood();
                this.endGame();
            } else {
                this.sayGood();
                frog.gotoAndPlay("end2");
                await waitForEvent(frog, "finishedEnd2");
                await waitForTimer(500);
                await this.frogFlagsOut();
                await this.frogsOut();
                this.enableClick = true;
                this.newQuestion();
            }
        } else {
            this.enableClick = true;
            await this.playWrong();
        }
    };

    frogFlagsOut = async (frogClicked: MC = null) => {
        let randomArr: any[] = [];
        randomArr = getRandomArr(0, this.frogsDisplayed.length - 1, this.frogsDisplayed.length);
        for (let i = 0; i < this.frogsDisplayed.length; i++) {
            const frog = this.frogsDisplayed[randomArr[i]];
            if (frog !== frogClicked) {
                frog.gotoAndStop("end1");
                frog.gotoAndPlay("end1");
                await waitForEvent(frog, "finishedEnd1");
            }
        }
    };

    frogsOut = async () => {
        let promises: any[] = [];
        let randomArr: any[] = [];
        randomArr = getRandomArr(0, this.frogsDisplayed.length - 1, this.frogsDisplayed.length);
        for (let i = 0; i < this.frogsDisplayed.length; i++) {
            const frog = this.frogsDisplayed[randomArr[i]];
            frog.gotoAndStop("end");
            frog.gotoAndPlay("end");
            promises.push(waitForEvent(frog, "animationEnd"));
            const delay = getRandomNumber(2, 5) * 100;
            await waitForTimer(delay);
        }
        return Promise.all(promises);
    };

    endGame = async () => {
        this.gamesPlayed++;
        await this.frogFlagsOut();
        await this.frogsEatFly();
        await this.frogsOut();
        this.cleanPrizes();
    };

    frogsEatFly = async () => {
        let promises: any[] = [];
        let randomArr: any[] = [];
        randomArr = getRandomArr(0, this.frogsDisplayed.length - 1, this.frogsDisplayed.length);
        for (let index = 0; index < this.frogsDisplayed.length; index++) {
            const frog = this.frogsDisplayed[randomArr[index]];
            frog.gotoAndPlay("fly");
            promises.push(waitForEvent(frog, "finishedFlySing"));
            const delay = getRandomNumber(2, 5) * 100;
            await waitForTimer(delay);
        }
        return Promise.all(promises);
    };

    loaded = () => {
        playSoundSync("frogsBg", { loop: -1 });
    };
}
