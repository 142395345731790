import { MC } from "../../animate/utils";
import { DubbyBaseGame } from "./DubbyBaseGame";

export default class DubbyLivingGame extends DubbyBaseGame {
    root: MC;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    hidePreloader: () => void;
    objects: string[] = [];
    genericObjects = ["Television", "Picture", "Sofa", "LivingRoomTable", "Lamp", "Bookcase", "VacuumCleaner", "Chest"];
    buildObjects = ["television", "picture", "sofa", "table", "lamp", "bookcase", "vacuumcleaner", "chest"];
    objectsName = ["television", "picture", "couch", "table", "lamp", "bookcase", "vacuum cleaner", "TV cabinet"];
    loaded = () => {
        super.loaded();
        this.hidePreloader();
        this.root.visible = true;
    };
    beeMode = () => {
        this.objects = [...this.genericObjects];
        super.beeMode();
    };
    whatInMode = async () => {
        this.objects = [...this.genericObjects];
        super.whatInMode();
    };
    buildMode = async () => {
        this.objects = [...this.buildObjects];
        super.buildMode();
    };
}
