import { MC } from "../../animate/utils";
import { CreationGame } from "../CreationGame";
import { Store } from "./Store";

export class BackgroundStore extends Store {
    private currentBgIndex: number = 0;
    constructor(private root: MC, creationGame: CreationGame) {
        super(2, 0, root.panel_backgrounds);
        this.initStore(creationGame);
    }

    initStore = (creationGame: CreationGame) => {
        for (let i = 0; i <= 27; i++) {
            const bg: createjs.MovieClip = this.root.panel_backgrounds["b" + i];
            bg.mouseChildren = false;
            bg.addEventListener("click", () => {
                this.currentBgIndex = i;
                this.root.theWork.BackPics.gotoAndStop(i);
            });
        }
    };

    getCurrentBg = () => {
        return this.currentBgIndex;
    };

    setCurrentBg = (bgIndx: number) => {
        this.currentBgIndex = bgIndx;
        this.root.theWork.BackPics.gotoAndStop(bgIndx);
    };
}
