import React from "react";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import { BibleFrame } from "../story_menu/BibleFrame";
import { NoachGame } from "./NoachGame";
import { additionalSounds } from "./NoachGameSounds";

const NoachGameComponent: React.FC<{ frame: BibleFrame; onLoaded: () => void }> = ({ frame, onLoaded }) => {
    const game = React.useRef(new NoachGame(frame));

    return (
        <>
            <AnimateCC
                base="/bible_stories/noach_game"
                animationName="noach_game"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                    onLoaded();
                }}
                additionalSounds={additionalSounds}
            />
        </>
    );
};

export default NoachGameComponent;
