import { MC, playSound, playSoundSync, setupStage, waitForTimer } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

export class IceCreamGame {
    private root: MC;
    private frames1: number[] = [];
    private frames2: number[] = [];
    private frames3: number[] = [];
    private frames4: number[] = [];
    constructor(private frame: LunaparkMenu) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).icecream = mc;
        this.frame.toggleSweetsBtn(true);
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.root.gotoAndStop(0);
        this.initIcePart(1);
        this.initIcePart(2);
        this.initIcePart(3);
        this.initIcePart(4);
    };

    initIcePart = (ice_number: number) => {
        const frames = this.getFrames(ice_number);
        for (let i = 1; i <= 3; i++) {
            const frame = Math.floor(Math.random() * 7);
            frames[i] = frame;
            this.root["ice" + ice_number]["i" + i].cursor = "pointer";
            this.root["ice" + ice_number]["i" + i].gotoAndStop(frame);
            this.root["ice" + ice_number]["i" + i].addEventListener("mousedown", () => {
                playSound("clickPart");
                this.incrementColor(ice_number, i);
            });
        }
    };

    getFrames = (index: number) => {
        switch (index) {
            case 1:
                return this.frames1;
            case 2:
                return this.frames2;
            case 3:
                return this.frames3;
            case 4:
                return this.frames4;
        }
    };
    incrementColor = async (ice_number: number, slice_number: number) => {
        const frames = this.getFrames(ice_number);
        const newIndex = frames[slice_number] < 6 ? frames[slice_number] + 1 : 0;
        frames[slice_number] = newIndex;
        this.root["ice" + ice_number]["i" + slice_number].gotoAndStop(newIndex);
        if (this.checkSuccess()) {
            await waitForTimer(20);
            console.log("win");
            let i = 1;
            let count = 10;
            this.root.addEventListener("tick", (e: any) => {
                if ((e as createjs.TickerEvent).paused) return;

                if (count % 10 === 0) {
                    if (i <= 4) {
                        this.root["ice" + i].visible = false;
                        playSoundSync("bubble");
                        i += 1;
                    } else {
                        this.frame.LoadGame(routes.lunapark.sweets.menu);
                    }
                }
                count += 1;
            });
        }
    };

    checkSuccess = () => {
        for (let i = 1; i <= 3; i++) {
            if (
                !(
                    this.frames1[i] === this.frames2[i] &&
                    this.frames2[i] === this.frames3[i] &&
                    this.frames3[i] === this.frames4[i]
                )
            )
                return false;
        }
        return true;
    };
}
