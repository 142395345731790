import { MC, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

export class NumberSixGame {
    private root: MC;
    private counter = 0;
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).SixGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.stage.mouseX = this.frame.root.stage.mouseX;
        this.root.stage.mouseY = this.frame.root.stage.mouseY;
        this.root.addEventListener("tick", (e: any) => {
            this.moveRemote();
        });

        this.root.remote.addEventListener("mousedown", (e: any) => {
            this.root.children.forEach((child: MC) => {
                if (child.tv4) {
                    if (!child.isClick) {
                        if (this.isIntersecting(child)) {
                            child.isClick = true;
                            this.counter += 1;
                            child.big_screen.gotoAndStop(10);
                            child.screen.text = this.counter;
                            if (this.counter === 6) {
                                this.frame.root.Bar.againbut.visible = true;
                            }
                        }
                    }
                }
            });
        });

        this.resetTv();
    };
    resetTv = () => {
        this.root.children.forEach((child: MC) => {
            if (child.tv4) {
                const random = getRandomNumber(1, 5);
                switch (random) {
                    case 1:
                        child.big_screen.screen.gotoAndPlay(3);
                        break;
                    case 2:
                        child.big_screen.screen.gotoAndPlay(16);
                        break;
                    case 3:
                        child.big_screen.screen.gotoAndPlay(28);
                        break;
                    case 4:
                        child.big_screen.screen.gotoAndPlay(47);
                        break;
                    case 5:
                        child.big_screen.screen.gotoAndPlay(57);
                        break;
                }
                child.tv4.gotoAndStop(getRandomNumber(0, 2));
            }
        });
    };
    isIntersecting = (tv: MC) => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        const tvAreaX = tv.x;
        const tvAreaY = tv.y;
        const remoteAreaX = localMouse.x;
        const remoteAreaY = localMouse.y;
        const tvRect = new createjs.Rectangle(tvAreaX, tvAreaY - 113, 116, 113);
        const remoteRect = new createjs.Rectangle(remoteAreaX, remoteAreaY, 1, 1);

        return tvRect.intersects(remoteRect);
    };
    moveRemote = () => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        this.root.remote.x = localMouse.x;
        this.root.remote.y = localMouse.y;
        this.root.remote.rotation = -(localMouse.x - 400) / 6;
    };
}
