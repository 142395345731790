import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, playSoundSync, stopAllSounds, waitFor, waitForTimer } from "../../animate/utils";
import { CancelablePromise } from "../../utils/CancelablePromise";
import { getRandomArr } from "../../utils/MathUtils";
// import { getRandomArr } from "../../utils/MathUtils";
import MusicGameBase from "../musicGame/MusicGameBase";
import { getMusicSet } from "./TrainJsonFileFunctions";

export class Train extends MusicGameBase {
    name = "train";
    currentSet: any[] = [];
    orderArr: any[] = [];
    chosenLevel: number;
    currentObject: any;
    soundPlayed: createjs.AbstractSoundInstance;
    activeTrafficBtn: MC = null;
    enableTrafficClick: boolean = false;
    trafficStop: MC;
    trafficPlay: MC;
    enableStop: boolean = true;
    isPlaying: boolean;
    soundsLoaded: boolean;
    wagonsPlaced: MC[];
    correctWagonsPlaced: MC[];
    wagonsPlacedCorrectly: number = 0;
    wagonX: number[] = [];
    wagonY: number[] = [];
    placeToWagon: any[];
    awaitingPromise: CancelablePromise<any>;
    currentPlace: any;
    stopPlayingSet: boolean;
    allowWagonEvents: boolean;
    shouldPlayInst: boolean;
    enableHelp: () => void;
    disableHelp: () => void;
    correctAnswerNum: number;
    isTrafficPlayOn: boolean;
    speakerPlaying: any;

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).train = root;
    }

    resetWagons = () => {
        for (let index = 0; index < 4; index++) {
            const wagon = this.root[`wagon${index}`];

            wagon.removeAllEventListeners();
            wagon.hit.removeAllEventListeners();
            wagon.speaker.removeAllEventListeners();
        }
    };

    resetProps = () => {
        // this.root.gameover.stop();
        // this.root.gameover.gotoAndStop(0);
        this.trafficPlay && this.trafficPlay.removeAllEventListeners();
        this.trafficStop && this.trafficStop.removeAllEventListeners();
        this.enableStop = true;
        this.trafficPlay = null;
        this.trafficStop = null;
        this.soundsLoaded = false;
        this.wagonsPlaced = [];
        this.correctWagonsPlaced = [];
        this.wagonsPlacedCorrectly = 0;
        this.allowWagonEvents = false;
        this.orderArr = [];
        this.currentSet = [];
        this.isTrafficPlayOn = false;
        this.placeToWagon = [
            { wagon: null, soundIndex: -1 },
            { wagon: null, soundIndex: -1 },
            { wagon: null, soundIndex: -1 },
            { wagon: null, soundIndex: -1 },
        ];
    };

    handleLevel = (level: number) => {
        // this.returnWagons();
        this.awaitingPromise?.cancel?.();
        stopAllSounds();
        this.resetWagons();
        this.resetProps();
        this.chosenLevel = level;
        this.chosenLevel === 1 ? (this.correctAnswerNum = 4) : (this.correctAnswerNum = 3);
        this.startGame();
    };

    startGame = async () => {
        this.disableHelp();
        this.currentSet = getMusicSet(this.chosenLevel);
        this.loadSetSounds();
        this.initTrafficLight();
        this.initWagons();
        this.shouldPlayInst && (await this.playInst());
        await this.cancelableWait(waitFor(() => this.soundsLoaded));
        this.allowWagonEvents = true;
        // debugger;
        this.enableHelp();
        // this.shouldPlayInst = false;
        this.enableTrafficClick = true;
        this.enableStop = false;
        this.root.btnStop.gotoAndStop("disabled");
    };

    loadSetSounds = async () => {
        for (let index = 0; index < this.currentSet.length; index++) {
            const sound = this.currentSet[index].sound;
            await loadSound("/music/" + sound);
        }
        this.soundsLoaded = true;
        await loadSound("/music/train/sounds/Train.mp3");
    };

    playInst = async () => {
        this.shouldPlayInst = false;
        await this.cancelableWait(loadSound("/music/train/sounds/train_help.mp3"));
        await this.cancelableWait(playSound("/music/train/sounds/train_help.mp3"));
        // this.enableHelp();
        this.allowWagonEvents = true;
    };

    initTrafficLight = () => {
        this.trafficPlay = this.root.btnPlay;
        this.trafficStop = this.root.btnStop;

        this.trafficPlay.addEventListener("rollover", () => {
            if (this.isPlaying) return;
            this.trafficPlay.gotoAndStop("over");
        });
        this.trafficPlay.addEventListener("rollout", () => {
            if (this.isPlaying) return;
            this.trafficPlay.gotoAndStop("up");
        });
        this.trafficPlay.addEventListener("click", async () => {
            if (!this.enableTrafficClick) return;
            this.trafficStop.gotoAndStop("up");
            this.isTrafficPlayOn = true;
            this.enableStop = true;
            this.trafficPlay.gotoAndStop("active");
            this.isPlaying = true;
            this.playSet();
            // await waitForTimer(1000);//to do create play function
            // this.isPlaying = false;

            // this.activeTrafficBtn = this.trafficPlay;
        });
        this.trafficPlay.addEventListener("mouseover", () => {
            if (!this.enableTrafficClick) return;
            this.trafficPlay.cursor = "pointer";
        });

        this.trafficStop.addEventListener("mouseover", () => {
            if (!this.enableTrafficClick || !this.enableStop) return;
            this.trafficStop.cursor = "pointer";
        });

        this.trafficStop.addEventListener("rollover", () => {
            if (this.activeTrafficBtn === this.trafficPlay || !this.enableStop) return;
            this.trafficStop.gotoAndStop("over");
        });
        this.trafficStop.addEventListener("rollout", () => {
            if (this.activeTrafficBtn === this.trafficPlay || !this.enableStop) return;
            this.trafficStop.gotoAndStop("up");
        });
        this.trafficStop.addEventListener("click", async () => {
            if (!this.enableTrafficClick) return;
            if (this.enableStop) {
                stopAllSounds();
                this.enableHelp();
                this.stopPlayingSet = true;
                this.awaitingPromise?.cancel?.();
                this.currentPlace.blink.gotoAndStop(0);
                this.currentPlace.gotoAndStop("on");
                this.trafficStop.gotoAndStop("active");
                this.allowWagonEvents = true;
                await waitForTimer(300);
                this.activeTrafficBtn = this.trafficStop;
                this.trafficStop.gotoAndStop("disabled");
                this.enableStop = false;
                this.trafficPlay.gotoAndStop("up");
                this.isPlaying = false;
            }
        });
    };

    hideWagons = () => {
        for (let index = 0; index < 4; index++) {
            const wagon = this.root[`wagon${index}`];
            wagon.visible = false;
        }
    };

    playEnd = async () => {
        const gameover = new this.root.lib.game_over();
        this.root.addChild(gameover);
        this.disableHelp();
        this.hideWagons();
        this.soundPlayed && this.soundPlayed.stop();
        gameover.framerate = 20;
        gameover.animation.framerate = 20;
        gameover.name = "game_over";
        this.enableTrafficClick = false;
        // gameover.gotoAndPlay("loop");
        gameover.gotoAndStop(1);

        gameover.animation.changColors.gotoAndPlay("change");
        gameover.x += 405;
        gameover.y += 300;

        const soundUrl = this.currentSet[0].sound;
        await this.cancelableWait(playSound("/music/train/sounds/Train.mp3"));
        await this.cancelableWait(playSound("/music/" + soundUrl));
        gameover.gotoAndStop(0);
        this.handleLevel(this.chosenLevel);
    };

    playSet = async () => {
        stopAllSounds();
        this.disableHelp();
        this.soundPlayed && this.soundPlayed.stop();
        this.soundPlayed && this.soundPlayed.stop();
        this.speakerPlaying && this.speakerPlaying.gotoAndStop("up");
        this.stopPlayingSet = false;
        this.allowWagonEvents = false;
        for (let index = 0; index < 4; index++) {
            if (this.stopPlayingSet) {
                return;
            }
            const place = this.root[`place${index}`];
            this.currentPlace = place;
            place.gotoAndStop("on");
            place.blink.framerate = 20;
            place.blink.gotoAndPlay(0);
            if (this.placeToWagon[index].wagon !== null) {
                const soundIndex = this.placeToWagon[index].soundIndex;
                const soundUrl = this.currentSet[soundIndex + 1].sound;
                await this.cancelableWait(playSound("/music/" + soundUrl));
                place.blink.gotoAndStop(0);
                place.gotoAndStop("on");
            } else {
                await this.cancelableWait(waitForTimer(2300));
                place.blink.gotoAndStop(0);
                place.gotoAndStop("on");
            }
        }
        this.enableHelp();
        this.trafficStop.gotoAndStop("disabled");
        this.enableStop = false;
        this.isTrafficPlayOn = false;
        this.allowWagonEvents = true;
    };

    initWagons = async () => {
        this.orderArr = getRandomArr(0, 3, 4);
        await this.cancelableWait(waitFor(() => this.wagonY.length === 4));
        for (let index = 0; index < 4; index++) {
            const wagon = this.root[`wagon${index}`];
            const correctBoxNum = this.orderArr[index];
            const correctBox = this.root[`place${correctBoxNum}`];
            correctBox.mouseChildren = false;
            this.addSpeakerEvents(wagon, correctBoxNum);
            wagon.visible = true;
            wagon.addEventListener("mouseover", () => {
                if (!this.allowWagonEvents) {
                    wagon.cursor = "default";
                } else {
                    wagon.cursor = "pointer";
                }
            });
            if (this.chosenLevel === 2 && correctBoxNum === 0) {
                wagon.x = correctBox.x;
                wagon.y = correctBox.y;
                this.placeToWagon[0].wagon = wagon;
                this.placeToWagon[0].soundIndex = correctBoxNum;
            } else {
                wagon.x = this.wagonX[index];
                wagon.y = this.wagonY[index];

                this.currentObjectEvent(wagon, correctBox, correctBoxNum);
            }
        }
    };

    addSpeakerEvents = (wagon: MC, soundNum: number) => {
        let isSoundPlaying = false;

        wagon.speaker.addEventListener("click", () => {
            if (!this.allowWagonEvents) return;
            if (isSoundPlaying && wagon.speaker.currentLabel === "down") {
                isSoundPlaying && this.soundPlayed.stop();
                isSoundPlaying = false;
            } else {
                stopAllSounds();
                this.enableHelp();
                this.soundPlayed && this.soundPlayed.stop();
                this.speakerPlaying && this.speakerPlaying.gotoAndStop("up");
                wagon.speaker.gotoAndStop("down");
                this.speakerPlaying = wagon.speaker;
                isSoundPlaying = true;
                this.playWagonSound(soundNum);
                this.soundPlayed.on("complete", () => {
                    isSoundPlaying = false;
                    this.speakerPlaying = null;
                    wagon.speaker.gotoAndStop("up");
                });
            }
        });
        wagon.speaker.addEventListener("rollover", () => {
            if (isSoundPlaying || this.isTrafficPlayOn) return;
            wagon.speaker.gotoAndStop("over");
        });
        wagon.speaker.addEventListener("rollout", () => {
            if (wagon.speaker.currentLabel !== "down") wagon.speaker.gotoAndStop("up");
        });
    };

    playWagonSound = (numberInSet: number) => {
        const soundUrl = this.currentSet[numberInSet + 1].sound;
        this.soundPlayed = playSoundSync("/music/" + soundUrl);
    };

    isFromAPlace = (wagon: MC) => {
        if (this.correctWagonsPlaced.includes(wagon)) {
            const i = this.correctWagonsPlaced.indexOf(wagon);
            this.correctWagonsPlaced.splice(i, 1);
            this.wagonsPlacedCorrectly--;
        }
        for (let index = 0; index < this.placeToWagon.length; index++) {
            if (this.placeToWagon[index].wagon === wagon) {
                this.placeToWagon[index].wagon = null;
                this.placeToWagon[index].soundIndex = -1;
            }
        }
    };

    isInPlace = (target: any, currentObj: MC, soundNum: number): boolean => {
        for (let index = 0; index < 4; index++) {
            const place = this.root[`place${index}`];
            if (target === place) {
                this.isFromAPlace(currentObj);
                currentObj.x = place.x;
                currentObj.y = place.y;
                this.placeToWagon[index].wagon = currentObj;
                this.placeToWagon[index].soundIndex = soundNum;

                return true;
            }
        }
        return false;
    };

    currentObjectEvent = async (currentObj: MC, correctBox: MC, soundNum: number) => {
        const startX = currentObj.x;
        const startY = currentObj.y;
        currentObj.visible = true;

        const stopMove = (e: createjs.MouseEvent) => {
            if (!this.allowWagonEvents) return;
            currentObj.mouseEnabled = true;
            this.root.cursor = "default";
            this.root.removeEventListener("click", stopMove);
            currentObj.removeAllEventListeners();

            if (this.isInPlace(e.target, currentObj, soundNum)) {
                this.wagonsPlaced.push(currentObj);
                if (e.target === correctBox) {
                    this.correctWagonsPlaced.push(currentObj);
                    this.wagonsPlacedCorrectly++;
                    if (this.wagonsPlacedCorrectly === this.correctAnswerNum) {
                        this.playEnd();
                    }
                }
                currentObj.hit.addEventListener("click", startMove);
            } else {
                if (this.wagonsPlaced.includes(currentObj)) {
                    this.removeWagon(currentObj);
                }
                this.returnWagonToStorage(currentObj, startX, startY);
                currentObj.hit.addEventListener("click", startMove);
            }

            this.currentObject = null;
        };

        const startMove = async (event: createjs.Event) => {
            if (this.currentObject || !this.allowWagonEvents) return;
            event.stopImmediatePropagation();
            this.currentObject = currentObj;
            currentObj.mouseEnabled = false;
            this.root.cursor = "pointer";

            currentObj.addEventListener("tick", (e: any) => {
                if ((e as createjs.TickerEvent).paused) return;
                const parent: createjs.DisplayObject = currentObj.parent;
                const position = parent.globalToLocal(parent.stage.mouseX, parent.stage.mouseY);
                currentObj.x = position.x;
                currentObj.y = position.y;
            });

            this.root.addEventListener("click", stopMove);
        };

        currentObj.hit.addEventListener("click", startMove);
    };

    returnWagonToStorage = (wagon: MC, startX: number, startY: number) => {
        createjs.Tween.get(wagon).to(
            {
                x: startX,
                y: startY,
            },
            1000,
            createjs.Ease.cubicOut
        );
    };

    removeWagon = (wagon: MC) => {
        const index = this.wagonsPlaced.indexOf(wagon);
        this.wagonsPlaced.splice(index, 1);
        this.isFromAPlace(wagon);
    };

    saveWagonPos = () => {
        for (let index = 0; index < 4; index++) {
            const wagon = this.root[`wagon${index}`];
            this.wagonX.push(wagon.x);
            this.wagonY.push(wagon.y);
        }
    };

    returnWagons = async () => {
        await this.cancelableWait(waitFor(() => this.wagonY.length === 4));
        for (let index = 0; index < 4; index++) {
            const wagon = this.root[`wagon${index}`];
            wagon.x = this.wagonX[index];
            wagon.y = this.wagonY[index];
        }
    };

    async cancelableWait(p: Promise<any>) {
        this.awaitingPromise = p as CancelablePromise<any>;
        await p;
        this.awaitingPromise = null;
    }

    loaded = async (level: number = 1) => {
        this.disableHelp();
        this.root.btnLogo.addEventListener("rollover", () => {
            this.root.btnLogo.cursor = "pointer";
            this.root.btnLogo.gotoAndStop("hover");
        });
        this.root.btnLogo.addEventListener("rollout", () => {
            this.root.btnLogo.gotoAndStop("up");
        });
        this.root.engine.framerate = 20;
        this.shouldPlayInst = true;
        this.handleLevel(level);
        this.saveWagonPos();
    };
}
