import { MC, waitForTimer, playSound, asyncTween } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { showNextButton, showReloadBtn } from "./games";

export class SpecialWords {
    shouldPlay = true;

    constructor(public root: MC, private letter: any, private eventForFrameButton: any) {}
    showLetter = async () => {
        const randomNumFOrSound = getRandomNumber(1, 2);
        this.root.letters.visible = false;
        this.root.letters.gotoAndStop(`letter${this.letter}`);
        this.root.letters.visible = true;
        this.root.letters.children.forEach((child: MC) => {
            child.alpha = 0;
        });
        const cucrrentLetter = this.root.letters[`letter${this.letter}`];
        const decoratorLetter = this.root.letters[`decoratorLetter`];
        if (this.shouldPlay) {
            playSound(`IntroLetter${randomNumFOrSound}`);
        }
        await asyncTween(cucrrentLetter, { alpha: 1 }, 2000);
        await asyncTween(decoratorLetter, { alpha: 1 }, 600);
        await waitForTimer(2000);
        if (this.shouldPlay) {
            await playSound(`letter_${this.letter}`);
        }
        await waitForTimer(500);
        await asyncTween(decoratorLetter, { alpha: 0 }, 300);
        await asyncTween(cucrrentLetter, { alpha: 0 }, 800);
    };

    setVisibleFalse = () => {
        this.root.letter_anim_mc.visible = false;
        this.root.word_anim_mc.visible = false;
        this.root.end_btn.visible = false;
        this.root.definition_anim_mc.visible = false;
        this.root.switch_activity_mc.visible = false;
        this.root.letters.visible = false;
        this.root.activities_loader_mc.visible = false;
        this.root.letters_mc.visible = false;
        this.root.next_btn.gotoAndStop("disabled");
        this.root.reload_btn.gotoAndStop("disabled");
        this.root.next_btn.visible = showNextButton(this.letter);
        this.root.reload_btn.visible = showReloadBtn(this.letter);
        this.root.exit_btn.gotoAndStop(0);
        this.root.help_btn.gotoAndStop("disabled");
        this.root.exit_btn.mouseChildren = false;
        this.eventForFrameButton(this.root.exit_btn);
        this.eventForFrameButton(this.root.next_btn);
        this.eventForFrameButton(this.root.reload_btn);
        this.eventForFrameButton(this.root.help_btn);
    };

    loaded = async () => {
        await this.setVisibleFalse();
        await this.showLetter();
    };
}
