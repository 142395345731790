import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Ducks } from "./Ducks";

export function DucksGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="ducks_math"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Ducks(mc), 3)}
            onUnload={game.current.unload}
            game={game}
        />
    );
}
