import { MC, playSound, setupStage, waitForEvent } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

export class AppleGame {
    private root: MC;
    private current_frame = 1;
    constructor(private frame: LunaparkMenu) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).apple = this.root;
        (window as any).frame = this.frame;
        this.root.gotoAndStop(0);
        this.root.apple.gotoAndStop(0);
        this.frame.toggleSweetsBtn(true);
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.root.apple.applebtn.addEventListener("mousedown", this.eatApple);
    };
    eatApple = async () => {
        this.root.apple.gotoAndStop(this.current_frame);
        this.current_frame += 1;
        if (this.current_frame === 20) {
            this.root.apple.applebtn.removeEventListener("mousedown", this.eatApple);
            this.root.apple.gotoAndPlay(20);
            playSound("greps");
            await waitForEvent(this.root, "appleEnd");
            this.frame.LoadGame(routes.lunapark.sweets.menu);
        } else {
            playSound("biteApple");
        }
    };
}
