const additionalSounds = [
    {
        src: "sounds/reka.mp3",
        id: "rekaSound",
    },
    {
        src: "sounds/hammer.mp3",
        id: "hammer",
    },
    {
        src: "sounds/hezMizpozez.mp3",
        id: "arrowsPop",
    },
    {
        src: "sounds/back.mp3",
        id: "back",
    },
    {
        src: "sounds/popcorn.mp3",
        id: "popcorn",
    },
    {
        src: "sounds/trampoline.mp3",
        id: "trampoline",
    },
    {
        src: "sounds/ghost_3.mp3",
        id: "ghost",
    },
    {
        src: "sounds/theater.mp3",
        id: "theater",
    },
    {
        src: "sounds/duck.mp3",
        id: "duck",
    },
    {
        src: "sounds/kiosk.mp3",
        id: "mirrors",
    },
    {
        src: "sounds/musicRoom.mp3",
        id: "musicRoom",
    },
    {
        src: "sounds/galgal.mp3",
        id: "bigWheel",
    },
    {
        src: "sounds/karusel.mp3",
        id: "karusel",
    },
    {
        src: "sounds/baloon.mp3",
        id: "baloon",
    },
    {
        src: "sounds/door.mp3",
        id: "door",
    },
    {
        src: "sounds/train.mp3",
        id: "train",
    },
    {
        src: "sounds/alefbet1.mp3",
        id: "tent",
    },
];

export default additionalSounds;
