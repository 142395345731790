import { MC, setupStage } from "../../../animate/utils";

export class MandolineGame {
    private root: MC;
    constructor(private frame: any) {}

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).mandoline = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(19);
        this.start();
    };

    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            const pos = this.root.globalToLocal(this.frame.root.stage.mouseX, this.frame.root.stage.mouseY);
            this.root.pick.x = pos.x;
            this.root.pick.y = pos.y;
        });
    };
}
