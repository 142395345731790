import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Frogs } from "./Frogs";

export function FrogsGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="frogs"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Frogs(mc), 3)}
            game={game}
            changeFramerate={true}
            shiftY={70}
        />
    );
}
