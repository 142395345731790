import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { FrogGame } from "./FrogGame";

const FrogGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new FrogGame());
    return (
        <>
            <AnimateCC
                base="/special_games/frog"
                animationName="frog"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                }}
                shiftY={30}
                shiftX={30}
                scale={0.88}
            />
        </>
    );
};
export default FrogGameComponent;
