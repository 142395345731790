import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { KiteGame } from "./KiteGame";
import kiteSounds from "./sounds_kites_game.json";
import frameSound from "../sounds_review_frame.json";
import { lettersSounds } from "../../common/lettersSounds";
import { commomSounds } from "../../common/commonSounds";
import { useParams } from "react-router-dom";
import { useLettersContext } from "../../../context/LettersContext";

export default function KiteGameComponent() {
    const { sectionIndex } = useParams<{ sectionIndex: string }>();
    const game = React.useRef(new KiteGame(parseInt(sectionIndex)));
    const lettersContext = useLettersContext();

    return (
        <AnimateCC
            base="/letters_island/kites/"
            animationName="kites"
            additionalSounds={[...kiteSounds, ...lettersSounds, ...frameSound, ...commomSounds]}
            onLoaded={(mc: MC) => game.current.kiteLoaded(mc, lettersContext)}
        />
    );
}
