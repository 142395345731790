import { loadSound } from "../../animate/sound_utils";
import { asyncTween, MC, playSound, playSoundSync, waitFor, waitForEvent, waitForTimer } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { CancelablePromise } from "../../utils/CancelablePromise";
import { getRandomNumber } from "../../utils/MathUtils";
import MathGameBase from "../MathGame/MathGameBase";

export class Phone extends MathGameBase {
    chosenGame: number;
    currentGameMc: MC;
    chosenLevel: number;
    menuBg: createjs.AbstractSoundInstance;
    wrongAnswers: number;
    numberSounds: string[] = [];
    numberSoundsStart: string[] = [];
    numberSoundsMiddle: string[] = [];
    numberSoundsEnd: string[] = [];
    enableEvents: boolean = true;
    enableClick: boolean = false;
    levelInfo = [
        { game: 1, level: 1, digits: 1 },
        { game: 1, level: 2, digits: 3 },
        { game: 1, level: 3, digits: 4 },
        { game: 2, level: 1, digits: 3 },
        { game: 2, level: 2, digits: 7 },
    ];
    digits: number;
    chosenDigits: number[] = [];
    digitsIndex: number = 0;
    correctAnswers: number = 0;
    initBtnsAnim: boolean = true;
    numPlaceIndex: number = 1;
    shouldEnter: boolean;
    prevHolder: MC;
    blinkingBtn: MC;
    finishedAnimAdd: boolean;
    name = "";
    awaitingPromise: CancelablePromise<any>;
    inOuter: boolean;
    enableHover: boolean;
    soundsLoaded: boolean;
    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).phone = root;
    }

    handleLevel = (level?: number) => {
        this.resetNumHolders();
        this.resetPhoneBtnEvents();
        this.currentGameMc.phone_mc.monitor_txt.text = "";
        this.chosenLevel = level;
        this.prevHolder = null;
        this.inOuter = false;
        this.enableEvents = true;
        this.enableClick = false;
        this.enableHover = false;
        this.resetBtnsStateAndPointers();
        this.startGame();
        this.cleanPrizes(true);
    };

    chooserUp = () => {
        this.enableEvents = false;
        this.root.stop();
        this.stopAnimations();
    };

    stopGame = () => {};

    stopAnimations = () => {
        this.awaitingPromise?.cancel?.();
        for (let i = 0; i < 10; i++) {
            const btn = this.currentGameMc[`btn_phone_${i}`];
            btn.anim?.stop();
        }
    };

    chooserDown = () => {
        this.enableEvents = true;
        this.resetNumHolders();
        this.newQuestion();
    };

    resetPhoneBtnEvents = () => {
        for (let i = 0; i < 10; i++) {
            const btn1 = this.root.game_phone1[`btn_phone_${i}`];
            const btn2 = this.root.game_phone2[`btn_phone_${i}`];
            btn1.removeAllEventListeners();
            btn2.removeAllEventListeners();
        }
    };

    addPhonesEvents = () => {
        for (let i = 1; i < 3; i++) {
            const currentPhone = this.root.open_mc[`game${i}_mc`];
            this.root[`game_phone${i}`].stop();
            let currentColor: string;
            currentPhone.cursor = "pointer";
            i === 1 ? (currentColor = "yellow") : (currentColor = "red");
            currentPhone.addEventListener("rollover", () => {
                currentPhone[currentColor].gotoAndPlay(1);
            });
            currentPhone.addEventListener("rollout", () => {
                currentPhone[currentColor].gotoAndStop(0);
            });
            currentPhone.addEventListener("click", () => {
                this.onInnerGame = true;
                this.menuBg && this.menuBg.stop();
                this.root.open_mc.visible = false;
                this.root[`game_phone${i}`].visible = true;
                this.chosenGame = i;
                // window.history.pushState(
                //     { page: `phone${i}` },
                //     "הסוד של מיה משחקי חשיבה לילדים",
                //     i === 1 ? routes.math.phone1 : routes.math.phone2
                // );
                Navigation.openPage(routes.math.phone.replace(":param", this.chosenGame.toString()));
                this.name = `phone${i}`;
                this.currentGameMc = this.root[`game_phone${i}`];
                if (this.chosenGame === 2) {
                    this.currentGameMc.notes_mc.gotoAndStop(0);
                    this.currentGameMc.notes_mc.numbers_holder_mc.gotoAndStop(0);
                }
                this.openSelector(currentColor === "yellow");
            });
        }
    };

    resetGameProps = () => {
        this.digits = null;
        this.digitsIndex = 0;
        this.chosenDigits = [];
        this.correctAnswers = 0;
    };

    retrieveGameProps = () => {
        this.levelInfo.forEach((info: any) => {
            if (info.game === this.chosenGame && info.level === this.chosenLevel) {
                this.digits = info.digits;
            }
        });
    };

    resetNumHolders = () => {
        for (let index = 1; index < 8; index++) {
            const holder = this.root[`n${index}`];
            holder.children[2] && holder.removeChild(holder.children[2]);
        }
    };

    startGame = async () => {
        this.resetGameProps();
        this.retrieveGameProps();
        this.currentGameMc.gotoAndStop(`game${this.chosenGame}`);
        this.currentGameMc.phone_mc.mouth_mc.gotoAndStop(0);
        this.addPhoneBtnsEvents();
        await this.cancelableWait(this.playInst());
        this.newQuestion();
    };

    newQuestion = () => {
        if (this.inOuter) return;
        if (this.chosenGame === 2) {
            this.resetNumHolders();
        }
        this.currentGameMc.phone_mc.monitor_txt.text = "";
        this.enableClick = false;
        this.wrongAnswers = 0;
        this.chosenDigits = [];
        this.digitsIndex = 0;
        this.generateQuestion();
        this.displayQuestion();
    };

    addPhoneBtnsEvents = () => {
        for (let i = 0; i < 10; i++) {
            const btn = this.currentGameMc[`btn_phone_${i}`];

            btn.addEventListener("click", () => {
                if (!this.enableEvents || !this.enableClick) return;
                btn.cursor = "pointer";
                const clickSound = getRandomNumber(1, 5);
                playSound(`beep${clickSound}`);
                btn.gotoAndStop("normal");
                this.clickPhoneBtn(btn, i);
            });
            btn.addEventListener("rollover", () => {
                if (!this.enableEvents || !this.enableHover) return;
                console.log("in over");

                btn.cursor = "pointer";
                btn.gotoAndStop("over");
            });
            btn.addEventListener("rollout", () => {
                if (!this.enableEvents || !this.enableHover) return;
                btn.gotoAndStop("normal");
            });
        }
    };

    resetBtnsStateAndPointers = () => {
        for (let i = 0; i < 10; i++) {
            const btn = this.root.game_phone1[`btn_phone_${i}`];
            const btn2 = this.root.game_phone2[`btn_phone_${i}`];

            btn.cursor = "default";
            btn.gotoAndStop("normal");

            btn2.cursor = "default";
            btn2.gotoAndStop("normal");
        }
    };

    generateQuestion = () => {
        this.wrongAnswers = 0;
        for (let i = 0; i < this.digits; i++) {
            if (this.chosenLevel === 2 && i === 0) this.chosenDigits.push(getRandomNumber(2, 9));
            else this.chosenDigits.push(getRandomNumber(0, 9));
        }
    };

    displayQuestion = async () => {
        this.disableHelp();
        this.enableHover = false;
        this.resetBtnsStateAndPointers();
        if (this.chosenGame === 2) {
            this.currentGameMc.notes_mc.gotoAndPlay(1);
            playSound("/math/sounds/phone/paperwav.mp3");
            await this.cancelableWait(waitForEvent(this.currentGameMc.notes_mc, "endAnim"));
            this.currentGameMc.notes_mc.gotoAndStop(0);
            this.chosenLevel === 1 ? (this.numPlaceIndex = 3) : (this.numPlaceIndex = 1);
            this.shouldEnter = true;
            this.ask();
        } else {
            this.ask();
        }
        this.enableHelp();
    };

    ask = async () => {
        await waitFor(() => this.soundsLoaded);
        if (this.chosenDigits.length === 1) {
            playSound(this.numberSounds[this.chosenDigits[0]]);
            this.currentGameMc.phone_mc.mouth_mc.gotoAndPlay(`say${this.chosenDigits[0]}`);
            await this.cancelableWait(waitForEvent(this.currentGameMc.phone_mc.mouth_mc, "finishedMouth"));
            this.currentGameMc.phone_mc.mouth_mc.gotoAndStop(0);
            this.enableClick = true;
            this.enableHover = true;
            return;
        }
        for (let i = 0; i < this.chosenDigits.length; i++) {
            const digit = this.chosenDigits[i];
            this.chosenGame === 2 ? await this.drawDigit(digit, i) : await this.sayDigit(digit, i);
            await this.cancelableWait(waitForTimer(500));
        }
        debugger;
        this.enableClick = true;
        this.enableHover = true;
        this.prevHolder && (await this.exitAnim());
        this.prevHolder = null;
    };

    sayDigit = async (digit: number, i: number) => {
        this.currentGameMc.phone_mc.mouth_mc.gotoAndPlay(`say${digit}`);
        this.playDigitSound(digit, i);
        await this.cancelableWait(waitForEvent(this.currentGameMc.phone_mc.mouth_mc, "finishedMouth"));
    };

    drawDigit = async (digit: number, i: number) => {
        const numToWrite = new this.root.lib[`write${digit}_mc`]();
        const digitHolder = this.root[`n${this.numPlaceIndex}`];

        this.numPlaceIndex++;
        numToWrite.name = "drawDigit";
        numToWrite.scale = 0.8;
        numToWrite.x = 100;
        numToWrite.y = -20;
        digitHolder.addChild(numToWrite);
        if (this.shouldEnter) {
            await this.enterAnim(numToWrite);
            this.shouldEnter = false;
        }
        this.playDigitSound(digit, i);
        this.currentGameMc.phone_mc.mouth_mc.gotoAndPlay(`say${digit}`);
        this.prevHolder && this.prevHolder.gotoAndStop("end");
        digitHolder.children[2].gotoAndPlay(1);
        await this.cancelableWait(waitForEvent(digitHolder.children[2], "endDraw"));
        this.prevHolder = digitHolder.children[2];
    };

    enterAnim = async (numToWrite: MC) => {
        numToWrite.visible = true;
        numToWrite.gotoAndStop(0);
        numToWrite.y = -20;
        numToWrite.x = 500;
        const tween = asyncTween(numToWrite, { x: 100 }, 1500);
        await this.cancelableWait(tween);
    };

    exitAnim = async () => {
        createjs.Tween.get(this.prevHolder.pencil_shadow).to({ x: 500 }, 1500);
        const tween = asyncTween(this.prevHolder.pencil, { x: 500 }, 1500);
        await this.cancelableWait(tween);
        this.enableClick = true;
        this.enableHover = true;
    };

    playDigitSound = (digit: number, i: number) => {
        if (i === 0) {
            playSound(this.numberSoundsStart[digit]);
        } else {
            if (i < this.chosenDigits.length - 1) {
                playSound(this.numberSoundsMiddle[digit]);
            } else {
                playSound(this.numberSoundsEnd[digit]);
            }
        }
    };

    clickPhoneBtn = async (btn: MC, number: number) => {
        this.enableClick = false;
        this.blinkingBtn && this.blinkingBtn.gotoAndStop(0);
        if (number === this.chosenDigits[this.digitsIndex]) {
            const isLast = this.digitsIndex === this.chosenDigits.length - 1 ? true : false;
            isLast && this.sayGood(isLast);
            this.currentGameMc.phone_mc.monitor_txt.text += number;
            this.chosenGame === 2 ? await this.chooseDigit(btn) : await this.pressBtn(btn);
            if (!isLast) {
                this.enableClick = true;
            }
            this.digitsIndex++;
            this.showBtnAnim(btn, isLast);
        } else {
            await this.pressBtn(btn, 70);
            btn.gotoAndStop("normal");
            this.chosenGame === 1 ? this.handleWrong() : this.sayBad();
        }
    };

    pressBtn = async (btn: MC, duration: number = 100) => {
        btn.gotoAndStop("pressed");
        await waitForTimer(duration);
        btn.gotoAndStop("normal");
    };

    chooseDigit = async (btn: MC) => {
        await this.pressBtn(btn);
        const minIndex = this.chosenLevel === 1 ? 3 : 1;
        const numHolder = this.root[`n${minIndex + this.digitsIndex}`];
        numHolder.children[2].alpha = 0.5;
    };

    handleWrong = async () => {
        this.enableClick = true;
        if (this.chosenLevel > 1) {
            this.enableClick = false;
            this.enableHover = false;
            this.resetBtnsStateAndPointers();
            await this.sayAgain();
            this.currentGameMc.phone_mc.monitor_txt.text = "";
            this.digitsIndex = 0;
            this.ask();
        } else {
            this.wrongAnswers++;
            this.wrongAnswers <= 2 ? this.sayAgain() : this.sayBad();
            if (this.wrongAnswers === 2) {
                this.showCorrect();
            } else if (this.wrongAnswers >= 3) {
                await this.cancelableWait(waitForTimer(2300));
                this.newQuestion();
            }
        }
    };

    showCorrect = () => {
        //blink_btn
        const btn_num = this.chosenDigits[this.digitsIndex];
        const btn = this.currentGameMc[`btn_phone_${btn_num}`];
        this.playDigitSound(btn_num, this.digitsIndex);
        btn[`blink_btn${btn_num}`].gotoAndPlay(1);
        this.blinkingBtn = btn[`blink_btn${btn_num}`];
        this.enableClick = true;
    };

    sayGood = (isLast: boolean) => {
        if (isLast) {
            this.correctAnswers++;
            this.enableHover = false;
            this.enableClick = false;
            this.resetBtnsStateAndPointers();
            if (this.correctAnswers > 5) {
                console.error("correct Answers is larger than 5");
            }
            this.addPrize(`prize_butterfly${this.correctAnswers}`, 550, 530);
        }
        const num = getRandomNumber(1, 10);
        this.loadAndPlaySound(`/math/sounds/he/good${num}.mp3`);
    };

    sayAgain = async () => {
        const num = getRandomNumber(1, 3);
        await this.loadAndPlaySound(`/math/sounds/he/again${num}.mp3`);
    };

    sayBad = () => {
        const num = getRandomNumber(1, 3);
        this.loadAndPlaySound(`/math/sounds/he/wrong${num}.mp3`);
        this.enableClick = true;
    };

    endTurn = async () => {
        this.chosenGame === 2 && this.resetNumHolders();
        this.currentGameMc.phone_mc.monitor_txt.text = " ";
        if (this.chosenGame === 2 && this.chosenLevel === 2 && this.correctAnswers < 5) {
            await this.showEndTurnAnim();
            console.log("recieved event");
            this.currentGameMc.gotoAndStop("game2");
        }
        this.correctAnswers === 5 ? await this.endPhoneAnim() : this.newQuestion();
    };

    showEndTurnAnim = async () => {
        this.resetNumHolders();
        this.currentGameMc.gotoAndStop(`end2`);
        this.currentGameMc.anim2.gotoAndPlay(1);
        return this.cancelableWait(waitForEvent(this.currentGameMc, "endAnim"));
    };

    endPhoneAnim = async () => {
        this.currentGameMc.gotoAndStop(`end1`);
        this.currentGameMc.anim.gotoAndPlay(1);
        await this.cancelableWait(waitForEvent(this.currentGameMc, "endAnim"));
        this.correctAnswers = 0;
        await this.cancelableWait(waitForTimer(500));
        this.cleanPrizes();
    };

    showBtnAnim = async (btn: MC, isLast: boolean) => {
        this.wrongAnswers = 0;
        btn.gotoAndStop("normal");
        const num = getRandomNumber(1, 4);
        switch (num) {
            case 1:
                btn.anim.children[0].gotoAndStop("ballons");
                btn.anim.children[0].ballons_mc.gotoAndPlay(1);
                break;
            case 2:
                btn.anim.children[0].gotoAndStop("butterfly");
                btn.anim.children[0].butterfly_mc.gotoAndPlay(1);
                break;
            case 3:
                btn.anim.children[0].gotoAndStop("flowers");
                btn.anim.children[0].flowers_mc.gotoAndPlay(1);
                break;
            case 4:
                btn.anim.children[0].gotoAndStop("stars");
                btn.anim.children[0].stars_mc.gotoAndPlay(1);
        }
        await this.cancelableWait(waitForEvent(btn, "endAnim"));
        isLast && this.endTurn();
    };

    loadAndPlaySound = async (soundUrl: string) => {
        await loadSound(soundUrl);
        await playSound(soundUrl);
    };

    playInst = async () => {
        const soundUrl =
            this.chosenLevel === 1
                ? `/math/sounds/phone/phone${this.chosenGame}_inst_level1.mp3`
                : `/math/sounds/phone/phone${this.chosenGame}_inst_level2.mp3`;
        await this.loadAndPlaySound(soundUrl);
    };

    playOpeningSounds = async () => {
        let sound = playSoundSync("PtihaZilZulwav");
        sound.volume = 0.5;
        sound.on("complete", () => {
            this.menuBg = playSoundSync("Ptihaloopwav", { loop: -1 });
            this.menuBg.volume = 0.5;
        });
    };

    loadNumberSounds = async () => {
        for (let i = 0; i < 10; i++) {
            await loadSound(`/math/sounds/numbers/fml_num_${i}.mp3`);
            this.numberSounds.push(`/math/sounds/numbers/fml_num_${i}.mp3`);
            await loadSound(`/math/sounds/numbers/fml_num_first_${i}.mp3`);
            this.numberSoundsStart.push(`/math/sounds/numbers/fml_num_first_${i}.mp3`);
            await loadSound(`/math/sounds/numbers/fml_num_middle_${i}.mp3`);
            this.numberSoundsMiddle.push(`/math/sounds/numbers/fml_num_middle_${i}.mp3`);
            await loadSound(`/math/sounds/numbers/fml_num_last_${i}.mp3`);
            this.numberSoundsEnd.push(`/math/sounds/numbers/fml_num_last_${i}.mp3`);
        }
        this.soundsLoaded = true;
        await loadSound("/math/sounds/RikudSofwav.mp3");
        await loadSound("/math/sounds/phone/paperwav.mp3");
    };
    hideGames = () => {
        this.root.game_phone1.visible = false;
        this.root.game_phone2.visible = false;
        this.onInnerGame = false;
        this.root.open_mc.visible = true;
    };

    addBtnAnimations = () => {
        for (let i = 0; i < 10; i++) {
            const animation = new this.root.lib.buttons_animation();
            const animation1 = new this.root.lib.buttons_animation();
            const btn1 = this.root.game_phone1[`btn_phone_${i}`];
            const btn = this.root.game_phone2[`btn_phone_${i}`];
            animation.name = "animation";
            btn.anim.addChild(animation1);
            btn1.anim.addChild(animation);
        }
        this.finishedAnimAdd = true;
    };

    backToOuterMenu = () => {
        this.initBtnsAnim = false;
        this.inOuter = true;
        this.correctAnswers = 0;
        this.currentGameMc.phone_mc.monitor_txt.text = "";
        this.stopAnimations();
        this.resetNumHolders();
        this.cleanPrizes(true);
        this.root.stop();
        this.root.open_mc.visible = true;
        this.hideGames();
        Navigation.openPage(routes.math.phone);

        // window.history.pushState({ page: `phone_menu` }, "הסוד של מיה משחקי חשיבה לילדים", routes.math.phone);
        this.playOpeningSounds();
    };

    async cancelableWait(p: Promise<any>) {
        this.awaitingPromise = p as CancelablePromise<any>;
        await p;
        this.awaitingPromise = null;
    }

    loaded = async () => {
        this.addPhonesEvents();
        this.loadNumberSounds();
        this.hideGames();
        this.initBtnsAnim && this.addBtnAnimations();
        this.initBtnsAnim && (await waitFor(() => this.finishedAnimAdd));
        this.playOpeningSounds();
    };
}
