import { loadSound, soundPlayed } from "../../animate/sound_utils";
import { MC, setupStage, stopAllSounds, waitFor, waitForEvent } from "../../animate/utils";
import { CancelablePromise } from "../../utils/CancelablePromise";

export class BibleColoring {
    private root: MC;
    currentPics: MC;
    picsNum: number;
    picsList: MC[] = [];
    picsIndex: number = 0;
    prevPic: MC;
    currentPromise: CancelablePromise<any>;
    browsingSound = loadSound("/bible_stories/coloring_bible/sounds/newspaper1wav.mp3");

    constructor(private type: string, private frame: any, private setPrintImage: (value: string) => void) {}

    loaded = async (mc: MC) => {
        this.root = mc;
        this.root.visible = false;
        stopAllSounds();
        await waitFor(() => this.frame);
        setupStage(this.frame.root, this.root);
        this.importCurrentPics();
        this.initPics();
        this.initBtns();
        this.root.visible = true;
        this.root.gotoAndPlay(0);
    };

    importCurrentPics = () => {
        const pics = new this.root.lib[`pics_${this.type}`]();
        this.picsNum = pics.duration;
        this.picsList.push(pics[`instance`]);
        for (let i = 1; i < this.picsNum; i++) {
            this.picsList.push(pics[`instance_${i}`]);
        }
    };
    initPics = () => {
        this.root.picsHolder.loop = false;
        this.root.picsHolder.gotoAndStop(0);
        //first pic
        this.root.picsHolder.pics0.addChild((this.prevPic = this.picsList[this.picsIndex]));
        this.root.picsHolder.pics0.gotoAndStop(0);
        this.root.picsHolder.pics1.gotoAndStop(0);
        this.root.picsHolder.pics1.removeChildAt(this.root.picsHolder.pics1.children.length - 1);
        this.setPrintImage(`/bible_stories/print/p_${this.type}_${this.picsIndex}.svg`);
    };
    initBtns = () => {
        const root = this.root;
        this.frame.togglePrint(true);
        this.frame.toggleHelp(false);
        this.frame.toggleReplay(false);
        const callbackArray = [this.setPrevPic, this.setNextPic];

        [root.btnPrev, root.btnNext].forEach((element, i) => {
            element.gotoAndStop("up");
            element.cursor = "pointer";

            element.addEventListener("click", () => {
                callbackArray[i]();
            });
            element.addEventListener("rollover", () => {
                element.gotoAndStop("over");
            });
            element.addEventListener("rollout", () => {
                element.gotoAndStop("up");
            });
        });
        this.initPrintBtn();
    };

    setPrevPic = () => {
        console.log("prev");
        this.picsIndex = this.picsIndex - 1 < 0 ? this.picsNum - 1 : this.picsIndex - 1;
        this.browsePics("Prev");
    };
    setNextPic = () => {
        console.log("next");
        this.picsIndex = (this.picsIndex + 1) % this.picsNum;
        this.browsePics("Next");
    };
    browsePics = async (btnName: string) => {
        this.root[`btn${btnName}`].mouseEnabled = false;
        this.root[`btn${btnName}`].gotoAndStop("down");

        //set pic to display + browse
        this.root.picsHolder.pics1.addChild(this.picsList[this.picsIndex]);
        this.root.picsHolder.gotoAndPlay(0);
        this.cancelableWait(soundPlayed((await this.browsingSound).play()));
        await waitForEvent(this.root.picsHolder, "animatonEnd");

        this.root.picsHolder.pics0.removeChild(this.prevPic);
        this.root.picsHolder.gotoAndStop(0);
        this.root.picsHolder.pics0.addChild((this.prevPic = this.picsList[this.picsIndex]));

        this.root[`btn${btnName}`].gotoAndStop("over");
        this.root[`btn${btnName}`].mouseEnabled = true;
        this.setPrintImage(`/bible_stories/print/p_${this.type}_${this.picsIndex}.svg`);
    };

    async cancelableWait(p: Promise<any>) {
        this.currentPromise = p as CancelablePromise<any>;
        await p;
        this.currentPromise = null;
    }

    initPrintBtn = () => {
        const printBtn = this.frame.root.frame.btnPrint;
        printBtn.addEventListener("click", () => {
            printBtn.gotoAndStop("down");
            window.print();
        });
    };
}
