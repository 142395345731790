import { MC, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

export class KaliedascopeGame {
    private root: MC;
    constructor(private frame: any) {}
    private myran1: number;
    private myran2: number;
    private myran3: number;
    private myran4: number;
    private myran5: number;
    private myran6: number;
    private myran7: number;
    private rspeed = 0;
    private moveX = 0;
    private moveY = 0;
    private moveSlider = false;

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).tiger = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(27);
        this.changeColor();
        this.addEventListeners();
    };
    addEventListeners = () => {
        this.root.addEventListener("tick", () => {
            for (let i = 1; i <= 6; i++) {
                if (i % 2) this.root["circle" + i].rotation += this.rspeed;
                else this.root["circle" + i].rotation -= this.rspeed;
            }
            if (this.moveSlider) {
                this.rotate();
            }
        });
        this.root.slider.btn.addEventListener("mousedown", () => {
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.moveX = localMouse.x - this.root.slider.btn.x;
            this.moveY = localMouse.y - this.root.slider.btn.y;
            this.root.cursor = "pointer";
            this.moveSlider = true;
        });
        this.root.slider.btn.addEventListener("pressup", () => {
            this.moveSlider = false;
            this.root.cursor = "default";
        });
        this.root.btn.addEventListener("click", () => {
            this.changeColor();
        });
    };

    changeColor = () => {
        this.random();
        this.root.children.forEach((child: MC) => {
            if (child.wedge1) {
                for (let i = 1; i <= 6; i++) {
                    child["wedge" + i].triangle.gotoAndStop(this.myran7);
                    child["wedge" + i].shape1.gotoAndStop(this.myran1);
                    child["wedge" + i].shape2.gotoAndStop(this.myran2);
                    child["wedge" + i].shape3.gotoAndStop(this.myran3);
                    child["wedge" + i].shape4.gotoAndStop(this.myran4);
                    child["wedge" + i].shape5.gotoAndStop(this.myran5);
                    child["wedge" + i].shape6.gotoAndStop(this.myran6);
                }
            }
        });
    };
    random = () => {
        this.myran1 = getRandomNumber(0, 20);
        this.myran2 = getRandomNumber(0, 20);
        this.myran3 = getRandomNumber(0, 20);
        this.myran4 = getRandomNumber(0, 20);
        this.myran5 = getRandomNumber(0, 20);
        this.myran6 = getRandomNumber(0, 20);
        this.myran7 = getRandomNumber(0, 9);
    };
    rotate = () => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        if (localMouse.y - this.moveY > 100) {
            this.root.slider.btn.y = 100;
        } else if (localMouse.y - this.moveY < -100) {
            this.root.slider.btn.y = -100;
        } else {
            this.root.slider.btn.y = localMouse.y - this.moveY;
            this.rspeed = this.root.slider.btn.y / 50;
        }
    };
}
