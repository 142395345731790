import { MC } from "../../animate/utils";

export class Store {
    constructor(private numFrames: number, private currentFrame: number = 0, private store: MC) {}
    setCurrentFrame(frame: number) {
        this.currentFrame = frame;
        this.store.gotoAndStop(this.currentFrame);
    }
    getCurrentFrame() {
        return this.currentFrame;
    }
    nextFrame() {
        this.currentFrame += 1 % this.numFrames;
        this.store.gotoAndStop(this.currentFrame);
    }

    prevFrame() {
        this.currentFrame = this.currentFrame - 1 >= 0 ? this.currentFrame - 1 : this.numFrames - 1;
        this.store.gotoAndStop(this.currentFrame);
    }
    isChildElement: (target: MC) => boolean;
}
