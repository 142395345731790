export const additionalSounds = [
    {
        src: "/lunapark/rocket/bubbleSounds/bubble_1.mp3",
        id: "bubble1",
    },
    {
        src: "/lunapark/rocket/bubbleSounds/bubble2_1.mp3",
        id: "bubble2",
    },
    {
        src: "/lunapark/rocket/bubbleSounds/bubble3_1.mp3",
        id: "bubble3",
    },
    {
        src: "/lunapark/rocket/clickSound/Click.mp3",
        id: "click",
    },
    {
        src: "sounds/bongo1wav.mp3",
        id: "bongo1wav",
    },
    {
        src: "sounds/bongo2wav.mp3",
        id: "bongo2wav",
    },
    {
        src: "sounds/bongo3wav.mp3",
        id: "bongo3wav",
    },
];
