import { MC, waitForEvent } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game21 extends BaseGame {
    gameEnd: any;
    game: any;
    clockIsRuning: boolean = false;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.gameEnd = gameEnd;
        this.game = game;
        game.visible = true;
        game.x = 230;
        game.y = 200;
        game.gotoAndStop(0);
        game.gotoAndPlay(1);
        await waitForEvent(game, "questionEnd");
        if (!this.shouldPlay) return;
        helpBtn(false);
        gameEnd();
        game.children.forEach((child: MC) => {
            if (child.cursor === "pointer") {
                child.addEventListener("click", async () => {
                    this.clockIsRuning = true;
                    helpBtn(true);
                    game.removeAllEventListeners();
                    game.gotoAndPlay(37);
                });
            }
        });
    };
}
