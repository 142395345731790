import React, { useEffect, useRef, useState } from "react";
import { Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import AnimateCC from "../../animate";
import menuSounds from "./LettersIslandSounds";
import { getDoneFancyWords, getLettersStatus } from "../../api/lettersIsland";
import { MC, playSoundSync } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { LettersIsland } from "./LettersIsland";
import { SodRoute } from "../../navigation/PrivateRoot";
import ReviewMenuComponent from "../review/menu/ReviewMenuComponent";
import FancyWordComponent from "../FancyWords/Menu/fancyWordsComponent";
import LetterMenuComponent from "../letter_menu/LetterMenuComponent";
import { useUserContext } from "../../context/UserContext";
import { LettersSongComponent } from "../letters_song/LettersSongComponent";
import SweetRhymesComponent from "../sweetRhymes/SweetRhymesComponent";
import { PreloaderComponent } from "../../loaders/PreloaderComponent";
import { useLettersContext } from "../../context/LettersContext";

export default function LetterIslandMenuComponent() {
    const { pathname } = useLocation();
    const [screenReady, setScreenReady] = useState(false);
    const { completedLetter, setCompletedLetter } = useLettersContext();
    const lettersStatus = useRef<ReturnType<typeof getLettersStatus>>();
    const disabledFancyWords = useRef<ReturnType<typeof getDoneFancyWords>>();
    const { isLoggedIn } = useUserContext();
    const { isExact: isLettersMenu } = useRouteMatch(routes.letters.menu);
    const history = useHistory();
    const game = React.useRef(new LettersIsland(history));

    useEffect(() => {
        lettersStatus.current = getLettersStatus();
        disabledFancyWords.current = getDoneFancyWords();
    }, []);

    useEffect(() => {
        game.current.isLoggedIn = isLoggedIn;
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLettersMenu) {
            if (game.current.root) {
                // not a first launch;
                game.current.bgSound = playSoundSync("bg", { loop: -1 });
            }
        }
    }, [isLettersMenu]);

    useEffect(() => {
        if (isLettersMenu && completedLetter) {
            game.current.doneLetter(completedLetter);
            setCompletedLetter(null);
        }
    }, [isLettersMenu, completedLetter, setCompletedLetter]);

    return (
        <>
            {!screenReady && pathname === routes.letters.menu && <PreloaderComponent name="boat" />}
            <AnimateCC
                visible={pathname === routes.letters.menu}
                base="/letters_island/letters_island_menu/"
                animationName="letters_island_menu"
                additionalSounds={menuSounds}
                onLoaded={async (mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc, await lettersStatus.current, await disabledFancyWords.current);
                }}
            />
            <Switch>
                <SodRoute path={routes.letters.review.menu}>
                    <ReviewMenuComponent />
                </SodRoute>
                <SodRoute path={routes.letters.fancy_words.menu}>
                    <FancyWordComponent />
                </SodRoute>
                <SodRoute path={routes.letters.activities.menu}>
                    <LetterMenuComponent />
                </SodRoute>
                <SodRoute path={routes.letters.video}>
                    <LettersSongComponent />
                </SodRoute>
                <SodRoute path={routes.letters.sweets}>
                    <SweetRhymesComponent />
                </SodRoute>
            </Switch>
        </>
    );
}
