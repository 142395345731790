import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AnimateCC from "../animate";
import { MC } from "../animate/utils";
import { VideoPlayer, VideoPlayerComponent } from "../video/VideoPlayer";
import { EnglishTime, EnglishTimeGameTypes } from "./EnglishTime";

export default function EnglishTimeComponent() {
    const { gameType } = useParams<{ gameType: EnglishTimeGameTypes }>();
    const videoPlayer = useRef<VideoPlayer>();
    const [videoSrc, setVideoSrc] = useState("");

    const game = React.useRef(new EnglishTime(videoPlayer, setVideoSrc));

    useEffect(() => {
        if (gameType) {
            game.current.gameType = gameType;
        }
    }, [gameType]);

    return (
        <>
            <VideoPlayerComponent
                ref={videoPlayer}
                src={videoSrc}
                width={800}
                height={600}
                frameWidth={940}
                frameHeight={705}
                onCanPlay={game.current.videoCanBeStarted}
                onEndFunction={game.current.videoEnded}
                shiftX={-10}
                shiftY={10}
            />
            <AnimateCC
                visible={!videoSrc}
                key={`zigi_${gameType}`}
                base="/english/"
                animationName={`zigi_${gameType}_game`}
                scale={0.85}
                shiftX={60}
                shiftY={68}
                onLoaded={async (mc: MC) => {
                    game.current.englishTimeGameLoaded(mc);
                }}
            />
            <AnimateCC
                key="frame"
                base="/english/"
                animationName="english_frame"
                onLoaded={async (mc: MC) => {
                    game.current.englishTimeFrameLoaded(mc);
                }}
                scale={0.85}
                shiftX={60}
                shiftY={65}
            />
        </>
    );
}
