import React, { useRef, useEffect } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import AnimateCC from "../../../animate";
import { menuSounds } from "../games_sounds";
import { lettersMyasSounds } from "../../common/lettersSounds";
import { routes } from "../../../navigation/routesPath";
import SpecialWordComponent from "../specialWordComponent";
import { FancyWords } from "./fancyWords";
import { SodRoute } from "../../../navigation/PrivateRoot";
import { getDoneFancyWords } from "../../../api/lettersIsland";
import { MC } from "../../../animate/utils";

export default function FancyWordComponent() {
    const { isExact: isFancyMenu } = useRouteMatch(routes.letters.fancy_words.menu);
    const lettersDone = useRef(getDoneFancyWords());
    const game = React.useRef(new FancyWords());

    useEffect(() => {
        game.current.muteUnmuteBgSound(!isFancyMenu);
    }, [isFancyMenu]);

    return (
        <>
            <AnimateCC
                base="/letters_island/fancy_words/"
                animationName="fancy_menu"
                onLoaded={async (mc: MC) => {
                    game.current.loaded(mc, await lettersDone.current);
                }}
                additionalSounds={[...menuSounds, ...lettersMyasSounds]}
            />
            <Switch>
                <SodRoute path={routes.letters.fancy_words.word}>
                    <SpecialWordComponent />
                </SodRoute>
            </Switch>
        </>
    );
}
