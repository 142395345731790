import React, { useEffect, useState } from "react";
import AnimateCC from "../animate";
import { MC } from "../animate/utils";
import { Switch, useRouteMatch } from "react-router-dom";
import { DubbyEnglishMenu } from "./DubbyEnglishMenu";
import { SodRoute } from "../navigation/PrivateRoot";
// import { useUserContext } from "../context/UserContext";
import { routes } from "../navigation/routesPath";
import { DubbyEnglishGamesComponent } from "./DubbyEnglishGamesComponent";
import { PreloaderComponent } from "../loaders/PreloaderComponent";

export default function DubbyEnglishMenuComponent() {
    const { isExact: isMenu } = useRouteMatch();
    const directLink = new URLSearchParams(window.location.search).get("path");
    const [fromDirectLink] = useState(directLink);
    const [screenReady, setScreenReady] = useState(false);

    // const { isLoggedIn } = useUserContext();
    useEffect(() => {
        if (!isMenu) return;
    }, [isMenu]);

    // useEffect(() => {
    //     menu.current.setIsLoggedIn(isLoggedIn);
    // }, [isLoggedIn]);

    const game = React.useRef(new DubbyEnglishMenu());
    return (
        <>
            {fromDirectLink && !screenReady && <PreloaderComponent name="plain" />}

            <AnimateCC
                key="homePage"
                base="/dubby_english/"
                animationName="dubby_home_page"
                onLoaded={async (mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc);
                }}
                visible={isMenu}
                scale={0.7}
                shiftX={175}
                shiftY={125}
            />
            <Switch>
                <SodRoute path={routes.dubby_english.game} component={DubbyEnglishGamesComponent} />
            </Switch>
        </>
    );
}
