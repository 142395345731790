import { loadSound } from "../../../animate/sound_utils";
import { MC, waitForEvent, playSound } from "../../../animate/utils";
import { SpecialGameBasic } from "../../SpecialGameBasic";

export class HanukaGame extends SpecialGameBasic {
    root: MC;
    helpSound = "/special_games/hanuka/sounds/help.mp3";
    instructionSound = "/special_games/hanuka/sounds/inst.mp3";
    bGSound = "/special_games/hanuka/sounds/music.mp3";

    loaded = async (root: MC) => {
        this.root = root;
        (window as any).hanuka = root;
        this.root.gotoAndStop("loader");
        this.root.gotoAndPlay("loader");
        await waitForEvent(this.root, "finishedLoader");
        this.root.gotoAndStop("game");
        this.initExit();
        this.enableHelp();
        this.initHelp();
        this.initIconMc();
        this.initSpeker();
        this.initReset(this.resetGame);
        this.initDraidels();
        await this.playInstSound();
        this.setBgSound();
    };

    initDraidels = async () => {
        await loadSound("/special_games/hanuka/sounds/fall.mp3");
        for (let index = 0; index < 8; index++) {
            this.root[`item${index}`].gotoAndPlay(1);
            this.root[`item${index}`].cursor = "pointer";
            this.root[`ref${index}`].gotoAndPlay(1);
            this.waitForEnd(index);
            this.root[`item${index}`].removeAllEventListeners();
            this.root[`item${index}`].addEventListener("mousedown", () => {
                this.spinDraidle(index);
            });
        }
    };

    spinDraidle = (index: number) => {
        this.root[`item${index}`].gotoAndPlay("start");
        this.root[`ref${index}`].gotoAndPlay("start");
        this.waitForEnd(index);
    };

    waitForEnd = (index: number) => {
        waitForEvent(this.root, `end${index}`).then(() => {
            playSound("/special_games/hanuka/sounds/fall.mp3");
            this.root[`item${index}`].gotoAndStop("end");
            this.root[`ref${index}`].gotoAndStop("end");
            this.root[`item${index}`].inner.stop();
            // this.root[`ref${index}`].stop();
            this.root[`ref${index}`].inner.stop();
        });
    };

    resetGame = () => {
        this.root.gotoAndStop("game");
        this.initDraidels();
    };
}
