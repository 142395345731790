import { useIframeContext } from "../context/IframeContext";
import React, { useEffect } from "react";
import { mapPathToStatisticsParams } from "./StatisticsUtils";
import { useLocation } from "react-router-dom";

export const SessionRefresher: React.FC = () => {
    const { pathname } = useLocation();
    const { sessionRefresherParams, setSessionRefresherParams } = useIframeContext();
    useEffect(() => {
        setSessionRefresherParams(mapPathToStatisticsParams(pathname));
    }, [setSessionRefresherParams, pathname]);

    return sessionRefresherParams ? (
        <iframe
            id="sessionRefresher"
            title="sessionRefresher"
            src={`/reports/SessionRefresher.aspx?${new URLSearchParams(
                Object.entries(sessionRefresherParams)
            ).toString()}`}
            style={{
                display: "none",
            }}
        />
    ) : null;
};
