import {
    MC,
    optimize,
    playSound,
    playSoundSync,
    setupStage,
    stopAllSounds,
} from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";

export class BalloonsGame {
    private root: MC;
    private clownsCounter = 0;
    private flyerList: MC[] = [];
    private isEndGame: boolean = false;
    private audio_instances: number = 0;
    private cspeed = 0.66;
    constructor(private frame: any) {}

    loaded = (mc: createjs.MovieClip) => {
        console.log("in baloons game");
        (window as any).BaloonsGame = mc;
        (window as any).game = this;

        stopAllSounds();

        this.root = mc;
        this.root.flyer.visible = false;

        setupStage(this.frame?.root, this.root);

        this.frame.hideBackground();
        this.frame.updateMainCounter(0);
        this.frame.root.Bar.maincounter.counterValue.y = 16;
        this.frame.root.Bar.maincounter.visible = true;
        this.frame.root.Bar.againbut.visible = false;
        this.clownsCounter = 0;
        this.flyerList = [];
        this.start();
    };

    start = () => {
        let num = 1;
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (num === 1 && this.isEndGame === false) {
                this.newFlyer();
            }
            num = getRandomNumber(0, 120);
            this.flyerList.forEach((flyer: any) => {
                if (flyer.y < 500) {
                    flyer.x += this.cspeed;
                    if (flyer.x >= 729) {
                        if (this.isEndGame === false) {
                            this.endGame(flyer);
                        }
                    }
                }
                if (flyer.lower > 0) {
                    flyer.y += 2.5;
                    flyer.lower -= 2.5;
                    if (flyer.y >= 505) {
                        flyer.clown.gotoAndStop(9);
                        if (!this.isEndGame) {
                            playSound("clown");
                            flyer.clown.downArms.gotoAndStop(flyer.armsColor);
                            (flyer as createjs.MovieClip).removeAllEventListeners();
                            (flyer as createjs.MovieClip).cache(
                                -26,
                                -114,
                                52,
                                114
                            );
                            this.clownsCounter += 1;
                            this.frame.updateMainCounter(this.clownsCounter);
                        }
                    }
                }
            });
        });
    };

    newFlyer = () => {
        const newFlyer = new this.root.lib.flyer();
        this.randomFlyer(newFlyer);
        newFlyer.x = -15;
        newFlyer.y = 255;
        this.root.addChild(newFlyer);
        this.flyerList.push(newFlyer);
        newFlyer.lower = 0;
    };

    randomFlyer = (flyer: MC) => {
        let mask = new createjs.Shape();
        mask.graphics.beginFill("#000").drawRect(-60, -240, 120, 155);
        for (let i = 1; i <= 5; i++) {
            let rotation = getRandomNumber(-10, 10);
            flyer["ball" + i].tickEnabled = true;
            flyer["ball" + i].paused = true;
            flyer["ball" + i].balloon.mouseChildren = false;
            flyer["ball" + i].rotation = rotation;
            flyer["ball" + i].regY = -getRandomNumber(0, 60);
            flyer["ball" + i].balloon.gotoAndStop(getRandomNumber(0, 10));
            flyer["ball" + i].mask = mask;
            flyer["ball" + i].balloon.cursor = "pointer";
            flyer["ball" + i].balloon.addEventListener("click", () => {
                if (!flyer["ball" + i].isPop) {
                    this.popBalloon(flyer, i);
                }
            });
        }
        [
            flyer.clown.jacket,
            flyer.clown.shoes,
            flyer.clown.pants,
            flyer.clown.button1,
            flyer.clown.button2,
            flyer.clown.button3,
            flyer.clown.button4,
            flyer.clown.button5,
            flyer.clown.button6,
            flyer.clown.button7,
            flyer.clown.head.hat,
        ].forEach((value) => value.gotoAndStop(getRandomNumber(0, 10)));

        optimize(flyer.clown);

        flyer.armsColor = getRandomNumber(0, 10);
        flyer.clown.arms.gotoAndStop(flyer.armsColor);
    };

    popBalloon = async (flyer: MC, bNumber: number) => {
        if (this.isEndGame !== true) {
            flyer["ball" + bNumber].visible = false;
            flyer.lower += 50;
            flyer["ball" + bNumber].isPop = true;

            let audio = playSoundSync("pop");
            audio.volume = 0.1;
            this.audio_instances += 1;
            audio.on("complete", () => {
                this.audio_instances -= 1;
                if (this.audio_instances === 0) {
                    playSound("dropBal");
                }
            });
        }
    };

    endGame(flyer: MC) {
        this.isEndGame = true;
        this.flyerList.forEach((element) => {
            if (element !== flyer && element.y !== 505) {
                element.visible = false;
            }
        });
        this.frame.root.Bar.againbut.visible = true;
    }
}
