import { MC, playSound, setupStage } from "../../../animate/utils";

export class NoseGame {
    private root: MC;
    private moveX: number;
    private moveY: number;
    private should_move: boolean = false;
    private pressed_element: MC = null;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).nose = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.addListeners();
        this.start();
    };
    start = () => {
        this.root.addEventListener("tick", () => {
            this.moveElement();
        });
    };

    addListeners = () => {
        this.root.elephant.addEventListener("click", () => {
            playSound("elephant");
            this.updateMove(this.root.elephant);
        });
        this.root.clown.addEventListener("click", () => {
            playSound("clown");
            this.updateMove(this.root.clown);
        });
        this.root.human.addEventListener("click", () => {
            this.updateMove(this.root.human);
        });

        this.root.elephant.addEventListener("mouseover", () => {
            playSound("elephant");
        });
        this.root.clown.addEventListener("mouseover", () => {
            playSound("clown");
        });
    };

    updateMove = (element: MC) => {
        if (!this.should_move || this.pressed_element !== element) {
            this.should_move = true;
            this.pressed_element = element;
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.moveX = localMouse.x - element.x;
            this.moveY = localMouse.y - element.y;
        } else {
            this.should_move = false;
            this.pressed_element = null;
        }
    };

    moveElement = () => {
        if (this.should_move) {
            const pos = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.pressed_element.x = pos.x - this.moveX;
            this.pressed_element.y = pos.y - this.moveY;
        }
    };
}
