import { getRandomNumber } from "../../utils/MathUtils";

const jsonData = require("./Objects.json");

export function findObjectsToLetter(letter) {
    let objStartWithLetter = [];
    let othersObjects = [];
    jsonData.hop.words.word.forEach((obj) => {
        if (obj.sFirstLetter === letter) {
            objStartWithLetter.push(obj);
        } else {
            othersObjects.push(obj);
        }
    });

    const randomIndex = getRandomNumber(1, objStartWithLetter.length - 1);
    const correctAnswer = objStartWithLetter[randomIndex].sID;
    const correctAnswerIndex = getRandomNumber(0, 2);
    const worngAnswers = [];
    for (let i = 0; i < 3; i++) {
        if (i === correctAnswerIndex) {
            worngAnswers[i] = null;
        } else {
            const index = getRandomNumber(0, othersObjects.length - 1);
            const answer = othersObjects[index].sID;
            if (!othersObjects.includes(answer)) {
                worngAnswers.push(answer);
            } else {
                i--;
            }
        }
    }
    return { correctAnswerIndex, correctAnswer, worngAnswers };
}

export function randomObjectToBeginLetter(letter) {
    const goodWords = jsonData.hop.words.word
        .filter((word) => word.sFirstLetter === letter + "")
        .filter((word) => word.sText.length < 6);
    const index = getRandomNumber(0, goodWords.length - 1);
    return goodWords[index];
}

export function randomObjectToEndLetter(letter) {
    let objEndWithLetter = [];
    jsonData.hop.words.word.forEach((obj) => {
        if (obj.sLastLetter === letter) {
            objEndWithLetter.push(obj);
        }
    });

    let index = 0;
    do {
        index = getRandomNumber(0, objEndWithLetter.length - 1);
    } while (objEndWithLetter[index].sText.length >= 6);

    return objEndWithLetter[index];
}

export function returnCorrectAnswerText(correctAnswer) {
    let answer;
    jsonData.hop.words.word.forEach((obj, index) => {
        if (obj.sID === correctAnswer) {
            answer = obj.sText;
        }
    });
    return answer;
}
export function returnAlertMessage(id) {
    let massageToReturn;
    jsonData.hop.messages.message.forEach((item) => {
        if (item.sID === id) {
            massageToReturn = item.text;
        }
    });
    return massageToReturn;
}

export function randomObjectToLetter(letter, index) {
    const objDonWithLetter = [];
    jsonData.hop.words.word.forEach((obj) => {
        if (obj.sLastLetter === letter) objDonWithLetter.push(obj);
    });
    let randomIndex;
    if (objDonWithLetter.length >= 1) {
        randomIndex = getRandomNumber(0, objDonWithLetter.length - 1);
        const correctAnswer = objDonWithLetter[randomIndex].sID;
        const correctAnswerIndex = getRandomNumber(0, index - 1);
        return { correctAnswer, correctAnswerIndex };
    }
}

export function getRymes(level) {
    const rymesArr = [];
    jsonData.hop.words.word.forEach((word) => {
        if (word.rimes) {
            const wordRimes = word.rimes.rime[1] ? word.rimes.rime : [word.rimes.rime];
            Object.keys(wordRimes).forEach((rime) => {
                if (wordRimes[rime].nLevel === level) {
                    rymesArr.push(word.sID);
                }
            });
        }
    });
    return rymesArr;
}

export function generateSoundFile() {
    const rymeArr = [];
    getRymes("1").forEach((ryme) => {
        rymeArr.push({ src: `sounds/rime1_${ryme}`, id: `rime1_${ryme}` });
        rymeArr.push({
            src: `sounds/rime1_${ryme}_full`,
            id: `rime1_${ryme}_full`,
        });
        rymeArr.push({
            src: `sounds/rime1_${ryme}_quest1`,
            id: `rime1_${ryme}_quest1`,
        });
        rymeArr.push({
            src: `sounds/rime1_${ryme}_quest2`,
            id: `rime1_${ryme}_quest2`,
        });
    });
    getRymes("2").forEach((ryme) => {
        rymeArr.push({ src: `sounds/rime2_${ryme}`, id: `rime2_${ryme}` });
        rymeArr.push({
            src: `sounds/rime2_${ryme}_full`,
            id: `rime2_${ryme}_full`,
        });
        rymeArr.push({
            src: `sounds/rime2_${ryme}_quest1`,
            id: `rime2_${ryme}_quest1`,
        });
        rymeArr.push({
            src: `sounds/rime2_${ryme}_quest2`,
            id: `rime2_${ryme}_quest2`,
        });
    });
    return rymeArr;
}

export function getWrongRymeFor(ryme, level) {
    const rymesArr = [];
    jsonData.hop.words.word.forEach((word) => {
        if (word.rimes && ryme !== word.sID) {
            const wordRimes = word.rimes.rime[1] ? word.rimes.rime : [word.rimes.rime];
            Object.keys(wordRimes).forEach((rime) => {
                if (wordRimes[rime].nLevel === level) {
                    rymesArr.push(word.sID);
                }
            });
        }
    });

    return rymesArr[getRandomNumber(0, rymesArr.length - 1)];
}

export function getLetterForIndex(letter) {
    let letterName;
    jsonData.hop.letters.letter.forEach((obj) => {
        if (obj.sText === letter) {
            letterName = obj.sID;
        }
    });
    return letterName;
}
