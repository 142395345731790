import React, { useCallback, useEffect, useRef, useState } from "react";
import { getUserId, makeRequestVieCetSession } from "../api/login";

import styles from "./SessionManagerComponent.module.scss";
import { Navigation } from "../navigation/navigation";
import { messages, Status } from "../api/messages";
import { ReactComponent } from "./login.svg";
import { useUserContext } from "../context/UserContext";
import { setCookie } from "../utils/cookieUtils";
import { useLunaparkContext } from "../context/LunaparkContext";

declare var cet: any;

const SessionManagerComponent: React.FC<{}> = () => {
    const { setCurrentUser } = useUserContext();
    const { setWantedGame } = useLunaparkContext();

    const loginSvg = useRef<SVGSVGElement>();
    const [ready, setReady] = useState(false);
    const [loader, setLoader] = useState(false);

    const [message, setMessage] = useState<string>("");

    const clearMessage = useCallback(() => {
        setMessage("");
        //TODO: logout and return to the correct game?
        //Make sure the user return without the param 'autologin'
        cet.accessmanagement.LogoutFromIdp(document.location.origin);
    }, []);

    const doLogin = useCallback(async () => {
        setLoader(true);
        const status: Status = await makeRequestVieCetSession();
        setLoader(false);

        if (status === "loginSucceed") {
            const rawReturnLocation = new URLSearchParams(window.location.search).get("returnlocation");
            const returnlocation = rawReturnLocation ? decodeURIComponent(rawReturnLocation) : "";

            window.history.replaceState(null, null, document.location.origin);
            setCurrentUser(getUserId());
            const oneYearFromNow = new Date();
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
            setCookie("sodmayaEntries", "0");
            if (returnlocation) {
                //lunapark proved to be special, we need to use context to load the specific game.
                if (returnlocation.startsWith("/lunapark")) {
                    setWantedGame(returnlocation);
                    Navigation.openPage("/lunapark");
                } else {
                    Navigation.openPage(returnlocation);
                }
            }
        } else {
            setMessage(messages[status]);
        }
    }, [setCurrentUser, setWantedGame]);

    const setVisibility = useCallback((selector: string, visible: boolean) => {
        const element = loginSvg.current.querySelector(selector) as any;
        element.style.display = visible ? "initial" : "none";
        return element;
    }, []);

    const setupBtn = useCallback((btn: SVGElement, onClick: () => void) => {
        btn.style.cursor = "pointer";
        btn.addEventListener("click", onClick);

        return () => {
            btn.removeEventListener("click", onClick);
        };
    }, []);

    useEffect(() => {
        loginSvg.current.removeAttribute("preserveAspectRatio");
        loginSvg.current.querySelectorAll("tspan").forEach((textElement) => {
            textElement.setAttribute("font-family", "Choco");

            let t = textElement.textContent;
            if (t.charAt(0) === ":") {
                t = t.slice(1) + ":";
            }
            textElement.textContent = t.split("").reverse().join("");
        });

        setVisibility("#message", false);

        setReady(true);
    }, [setVisibility]);

    useEffect(() => {
        const isFromExternalLogin = new URLSearchParams(window.location.search).has("autologin");
        if (isFromExternalLogin) {
            doLogin();
        }

        //return setupBtn(loginSvg.current.querySelector("#loginBtn"), doLogin, loginSound.current);
    }, [doLogin]);

    useEffect(() => {
        return setupBtn(loginSvg.current.querySelector("#closeMsgBtn"), clearMessage);
    }, [clearMessage, setupBtn]);

    useEffect(() => {
        const messageElement = setVisibility("#message", !!message);

        const text = messageElement.querySelector("#msgTxt") as SVGElement;

        text.innerHTML = "";

        const foreign = document.createElementNS(text.namespaceURI, "foreignObject");
        foreign.setAttribute("class", styles.message);
        text.appendChild(foreign);

        const pre = document.createElementNS("http://www.w3.org/1999/xhtml", "pre");
        pre.innerText = message;
        pre.setAttribute("class", styles.messageTxt);
        foreign.appendChild(pre);
    }, [setVisibility, message]);

    return (
        <form className={styles.form} style={{ pointerEvents: "none" }}>
            <ReactComponent ref={loginSvg} style={{ display: ready ? "block" : "none" }} className={styles.svg} />
            {loader && (
                <div className={styles.loader}>
                    <img src={require("./clock.png")} alt="loader" />
                </div>
            )}
        </form>
    );
};
export default SessionManagerComponent;
