import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { EggGame } from "./EggGame";
import { additionalSounds } from "./EggSounds";
const EggComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new EggGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/egg"
            animationName="egg"
            onLoaded={game.current.loaded}
            additionalSounds={additionalSounds}
        />
    );
};
export default EggComponent;
