import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import { MC, playSound, stopAllSounds, waitFor, waitForEvent, waitForTimer } from "../../../animate/utils";
import { FindItems } from "../../FindItems";

export class SeekerseGame extends FindItems {
    NUM_GOOD_ITEMS = 6;
    NUM_BAD_ITEMS = 3;
    MAX_LEFT = 220.35;
    MAX_RIGHT = -253;
    MAX_UP = 112;
    MAX_DOWN = -262.15;
    numPrizes = 5;

    constructor() {
        super("seekers/");
        this.setAnimationFunction = this.setAnimation;
        this.setItemsFunction = this.setItems;
        this.setItemVisible = true;
        this.selectCurrentItemFunction = this.selectCurrentItem;
    }
    loaded = async (root: MC) => {
        stopAllSounds();
        this.root = root;
        await waitFor(() => this.root);
        await this.onLoad();
    };

    setAnimation = async (item: MC) => {
        soundPlayed((await this.goodItemSound).play());
        this.root.map.innerMap[`marker${this.selectedItem}`].gotoAndPlay(0);
        this.root[`prize${this.correctIndex++}`].gotoAndStop("on");
        this.disableHelp();
        this.root.mouseEnabled = true;
        await this.cancelableWait(waitForEvent(this.root.map.innerMap[`marker${this.selectedItem}`], "animationEnd"));
        await waitForTimer(1500);
        this.enableHelp();
        if (this.correctIndex > this.numPrizes - 1) {
            this.finishGame();
            return;
        }
        this.selectCurrentItem();
    };

    async selectCurrentItem() {
        this.selectedItem = this.selectedArray[0];
        this.selectedArray.splice(0, 1);
        this.helpSound = `special_games/seekers/sounds/${this.selectedItem + 1}.mp3`;
        await loadSound(this.helpSound);
        await playSound(this.helpSound);
    }

    async setItems() {
        super.setItems("item", 22, 5, 0);
        await loadSound(`special_games/sounds/openSound.mp3`);
        await playSound(`special_games/sounds/openSound.mp3`);
        this.selectCurrentItem();
    }
}
