import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIframeContext } from "../context/IframeContext";
import styles from "./LoginComponent.module.scss";
import { Navigation } from "../navigation/navigation";
import { loadSound, soundPlayed } from "../animate/sound_utils";
import { routes } from "../navigation/routesPath";
import { useParams } from "react-router-dom";
// import { useUserContext } from "../context/UserContext";
declare var cet: any;
type SoundPromise = Promise<createjs.AbstractSoundInstance>;
const LoginComponent: React.FC<{ loginAfterLocation: string; onLoggedIn?: () => void }> = (props) => {
    const [fontSize, setFontSize] = useState("10px");
    const [marginTop /*,setMarginTop*/] = useState("0");
    const params = useParams<{ returnUrl?: string }>();
    const loginAfterLocation = props.loginAfterLocation || params.returnUrl;
    const [ready] = useState(false);
    // const { setPermissions } = useUserContext();
    // const [loader, setLoader] = useState(false);

    const backSound = useRef<SoundPromise>();
    const loginSound = useRef<SoundPromise>();
    const forgotPasswordSound = useRef<SoundPromise>();
    const registerSound = useRef<SoundPromise>();
    const instructionsSound = useRef<SoundPromise>();
    const playingIntro = useRef(false);
    const registerNewUser = useCallback(() => {
        instructionsSound.current.then((s) => s.stop());
        Navigation.gotoRegistration();
    }, []);
    const setupBtn = useCallback(
        (btn: SVGElement, onClick: () => void, sound?: Promise<createjs.AbstractSoundInstance>) => {
            const onOver = () => {
                if (playingIntro.current) return;
                sound.then((s) => s.play());
            };
            const onOut = () => {
                sound.then((s) => s.stop());
            };

            btn.style.cursor = "pointer";
            btn.addEventListener("click", onClick);
            if (sound) {
                btn.addEventListener("mouseover", onOver);
                btn.addEventListener("mouseout", onOut);
            }

            return () => {
                btn.removeEventListener("click", onClick);
                btn.removeEventListener("mouseover", onOver);
                btn.removeEventListener("mouseout", onOut);
            };
        },
        []
    );
    useEffect(() => {
        backSound.current = loadSound("/login/back.mp3");
        loginSound.current = loadSound("/login/enter.mp3");
        forgotPasswordSound.current = loadSound("/login/forgot_password.mp3");
        registerSound.current = loadSound("/login/register.mp3");
        instructionsSound.current = loadSound("/login/login_instructions.mp3");

        if (!ready) return;

        (async () => {
            playingIntro.current = true;
            await soundPlayed((await instructionsSound.current).play());
            playingIntro.current = false;
        })();
    }, [ready]);

    const goBack = useCallback(() => {
        instructionsSound.current.then((s) => s.stop());

        Navigation.setShowLogin(false);
        Navigation.setIframeSrc("");

        Navigation.openPage(routes.home);
    }, []);

    const doLogin = useCallback(async () => {
        // setPermissions(null);
        instructionsSound.current.then((s) => s.stop());
        if (loginAfterLocation !== undefined) {
            cet.accessmanagement.RedirectToIdpLogin(
                document.location.origin + "?autologin=1&returnlocation=" + encodeURIComponent(loginAfterLocation),
                "sodmaya"
            );
        } else {
            cet.accessmanagement.RedirectToIdpLogin(document.location.origin + "?autologin=1", "sodmaya");
        }
    }, [loginAfterLocation]);
    const doModLogin = useCallback(async () => {
        // setPermissions(null);
        instructionsSound.current.then((s) => s.stop());
        if (loginAfterLocation !== undefined) {
            cet.accessmanagement.RedirectToMoeIdpLogin(
                document.location.origin + "?autologin=1&returnlocation=" + encodeURIComponent(loginAfterLocation),
                "sodmaya"
            );
        } else {
            cet.accessmanagement.RedirectToMoeIdpLogin(document.location.origin + "?autologin=1", "sodmaya");
        }
    }, [loginAfterLocation]);

    useEffect(() => {
        return setupBtn(document.querySelector("#backBtn"), goBack, backSound.current);
    }, [goBack, setupBtn]);

    useEffect(() => {
        return setupBtn(document.querySelector("#registerBtn"), registerNewUser, registerSound.current);
    }, [registerNewUser, setupBtn]);
    useEffect(() => {
        return setupBtn(document.querySelector("#loginBtn"), doLogin, loginSound.current);
    }, [doLogin, setupBtn]);
    useEffect(() => {
        return setupBtn(document.querySelector("#modBtn"), doModLogin);
    }, [doLogin, setupBtn, doModLogin]);

    useEffect(() => {
        const onResize = () => {
            const contW = 1980,
                contH = 1080;
            const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            let newFontSize; //, marginTop = 0;

            if (w / h > contW / contH) {
                newFontSize = 10 * (h / contH);
            } else {
                newFontSize = 10 * (w / contW);
                // marginTop = (h - ((contH * newFontSize) / 10)) / 2;
            }
            setFontSize(newFontSize + "px");
            //setMarginTop(marginTop + 'px');
        };

        onResize();
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const { showRegistration } = useIframeContext();

    //console.log('From Login new ',showRegistration);

    return (
        <div className={styles.wraper} style={{ display: showRegistration ? "none" : "block" }}>
            <div className={styles.contaner} style={{ fontSize: fontSize, marginTop: marginTop }}>
                <div className={styles.logo}></div>
                <div id="backBtn" className={styles.back_btn}></div>
                <div className={styles.form}>
                    <div className={styles.form_bg}></div>
                    <div className={styles.giraff}></div>
                    <div className={styles.member}>
                        <span>?יש לך מינוי</span>
                    </div>
                    <div id="loginBtn" className={styles.btn_enter}>
                        <span>כניסה</span>
                    </div>
                    <div id="registerBtn" className={styles.buy_link}>
                        <span>
                            עדיין לא הצטרפת? <b>לרכישה</b>
                        </span>
                    </div>
                </div>
                <div id="modBtn" className={styles.mod}>
                    <div>
                        <span>הזדהות משרד החינוך</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LoginComponent;
