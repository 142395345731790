import { letterMovies, allLettersMovie } from "../video/videoUrl";
import { IChannel } from "./IChannel";
export class IslandChannel implements IChannel {
    channelMovies: any[] = [];
    moviesNum: number;

    constructor(public setVideoSrc: (value: string) => void) {
        this.channelMovies = letterMovies;
        this.cleanData();
        this.channelMovies.push(allLettersMovie);
        this.moviesNum = this.channelMovies.length;
    }

    setMovieByIndex(index: number) {
        this.setVideoSrc(this.channelMovies[index]);
    }

    getMoviesNum() {
        return this.moviesNum;
    }

    getIconLabelByIndex(index: number) {
        let label: string;
        if (index > this.moviesNum) {
            label = "none";
        } else {
            index < 10 ? (label = `island_0${index}`) : (label = `island_${index}`);
        }
        return label;
    }

    cleanData() {
        const index = this.channelMovies.indexOf("/movies/abc/Letterssong.m4v", 0);
        if (index > -1) {
            this.channelMovies.splice(index, 1);
        }
    }
}
