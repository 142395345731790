import { MC, asyncTween, playSound } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game15 extends BaseGame {
    answersIndex = 0;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        game.visible = true;
        game.x = 600;
        game.gotoAndStop(0);
        game.flower1.alpha = 0;
        game.flower2.alpha = 0;
        for (let i = 0; i < 4; i++) {
            const currentWord = game[`word${i}`];
            currentWord.alpha = 0;
            currentWord.cursor = "pointer";
            currentWord.addEventListener("click", () => {
                currentWord.gotoAndStop("_down");
                this.clickOnWord(game, gameEnd, currentWord, i);
            });
            currentWord.addEventListener("rollover", () => {
                currentWord.gotoAndStop("_over");
            });
            currentWord.addEventListener("rollout", () => {
                currentWord.gotoAndStop("_up");
            });
        }
        await playSound("game_15");
        if (!this.shouldPlay) return;

        for (let i = 0; i < 4; i++) {
            const currentWord = game[`word${i}`];
            await asyncTween(currentWord, { alpha: 1 }, 400);
            await playSound(`btn_${i}`);
            if (!this.shouldPlay) return;
            helpBtn(false);
        }
    };
    clickOnWord = async (game: MC, gameEnd: () => void, currentWord: MC, btn_index: number) => {
        if (btn_index === 1 || btn_index === 2) {
            game.mouseChildren = false;
            game[`flower${btn_index}`].alpha = 1;
            game[`flower${btn_index}`].gotoAndPlay(1);
            await playSound("good");
            if (!this.shouldPlay) return;
            await playSound(`answer_${btn_index}`);
            if (!this.shouldPlay) return;
            if (this.answersIndex < 1) {
                await playSound("MM15_Instructios");
                if (!this.shouldPlay) return;
            }
            game.mouseChildren = true;
            this.answersIndex++;
            if (this.answersIndex === 2) {
                gameEnd();
            }
        } else {
            playSound("wrong");
        }
    };
}
