import { MC, waitForTimer } from "../../animate/utils";
import { loadSound, soundPlayed } from "../../animate/sound_utils";
const SOUND_TYPE = new Promise<createjs.AbstractSoundInstance>(() => {});

export class LettersSong {
    root: MC;
    sounds = {
        exit: SOUND_TYPE,
    };

    constructor(private pausePlay: () => void, private restartVideo: () => void, private onBack: () => void) {}

    loadSounds = () => {
        this.sounds.exit = loadSound(`letters_island/activities_frame/sounds/exit.mp3`);
    };

    loaded = async (root: createjs.MovieClip, letterVideoDone: any) => {
        this.root = root;
        this.root.backBtn.gotoAndStop(1);
        this.root.btnPlay.gotoAndStop(1);
        letterVideoDone();
        await waitForTimer(1000);
        this.root.videoPlayer.children[1].visible = false;
        this.restartVideo();
        this.pausePlay();

        this.root.backBtn.cursor = "pointer";
        this.root.backBtn.addEventListener("click", () => {
            this.onBack();
        });
        this.root.backBtn.addEventListener("rollover", async () => {
            this.root.backBtn.gotoAndStop(16);
            this.loadSounds();
            soundPlayed((await this.sounds.exit).play());
        });
        this.root.backBtn.addEventListener("rollout", () => {
            this.root.backBtn.gotoAndStop(0);
        });

        this.root.btnPlay.cursor = "pointer";
        this.root.btnPlay.addEventListener("click", () => {
            const state = this.root.btnPlay.currentFrame;
            this.root.btnPlay.gotoAndStop(state + 1);
            this.pausePlay();
        });

        this.root.btnRewind.cursor = "pointer";
        this.root.btnRewind.addEventListener("click", () => {
            this.root.btnPlay.gotoAndStop(2);
            this.restartVideo();
        });
    };
    unload = () => {};
}
