import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import gameSounds from "./sounds_boxesGame.json";
import { commomSounds } from "../../common/commonSounds";
import frameSound from "../sounds_review_frame.json";
import { useParams, useRouteMatch } from "react-router-dom";
import { useLettersContext } from "../../../context/LettersContext";
import { Boxes } from "./Boxes";

export default function BoxesGameComponent() {
    const { sectionIndex } = useParams<{ sectionIndex: string }>();
    const game = React.useRef<Boxes>();
    const lettersContext = useLettersContext();
    const match = useRouteMatch();

    return (
        <AnimateCC
            base="/letters_island/boxes/"
            animationName="boxes"
            additionalSounds={[...gameSounds, ...frameSound, ...commomSounds]}
            onLoaded={(mc: MC) => {
                game.current = new Boxes(mc, match, parseInt(sectionIndex), lettersContext, null, null);
            }}
            onUnload={game.current?.unloaded}
        />
    );
}
