import React, { useState } from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { PenguinGame } from "./PenguinGame";
import penguinSounds from "./sounds_penguin.json";
import { feedbacksSounds } from "../../common/feedBacksSounds";
import { commomSounds } from "../../common/commonSounds";

import frameSound from "../sounds_review_frame.json";
import { useParams } from "react-router-dom";
import { useLettersContext } from "../../../context/LettersContext";

export default function PenguinGameComponent() {
    const { sectionIndex } = useParams<{ sectionIndex: string }>();
    const lettersContext = useLettersContext();
    const [shouldPrint, setShouldPrint] = useState(null);
    const game = React.useRef(new PenguinGame(parseInt(sectionIndex), setShouldPrint));

    return (
        <>
            {shouldPrint && (
                <AnimateCC
                    base="/letters_island/penguin/"
                    animationName="penguin"
                    onLoaded={game.current.prepareForPrint}
                    print
                />
            )}
            <AnimateCC
                base="/letters_island/penguin/"
                animationName="penguin"
                additionalSounds={[...penguinSounds, ...frameSound, ...feedbacksSounds, ...commomSounds]}
                onLoaded={(mc: MC) => game.current.penguinLoaded(mc, lettersContext)}
            />
        </>
    );
}
