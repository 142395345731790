import { MC } from "../../animate/utils";
import { DubbyBaseGame } from "./DubbyBaseGame";

export default class DubbyBathGame extends DubbyBaseGame {
    root: MC;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    hidePreloader: () => void;
    objects: string[] = [];
    genericObjects = ["Douche", "Sink", "Toilet", "Bathtub", "Towels", "Window", "Cupboard", "Mat"];
    buildObjects = ["douche", "sink", "toilet", "bathtub", "towels", "window", "cupboard", "mat"];
    objectsName = ["shower", "sink", "toilet", "bathtub", "towel", "window", "cabinet", "bath mat"];

    loaded = () => {
        super.loaded();
        this.hidePreloader();
        this.root.visible = true;
    };
    beeMode = () => {
        this.objects = [...this.genericObjects];
        super.beeMode();
    };
    whatInMode = async () => {
        this.objects = [...this.genericObjects];
        super.whatInMode();
    };
    buildMode = async () => {
        this.objects = [...this.buildObjects];
        super.buildMode();
    };
}
