export const isDebug = !!localStorage.getItem("debug") || process.env.NODE_ENV === "development";

export function timeStart(label: string) {
    if (isDebug) {
        console.time(label);
    }
}

export function timeEnd(label: string) {
    if (isDebug) {
        console.timeEnd(label);
    }
}

export function profileStart(label: string) {
    if (isDebug) {
        console.profile(label);
    }
}

export function profileEnd(label: string) {
    if (isDebug) {
        console.profileEnd(label);
    }
}
