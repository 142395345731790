const additionalSounds = [
    {
        src: "sounds/_ducktune.mp3",
        id: "_ducktune",
    },
    {
        src: "sounds/duck_noise.mp3",
        id: "duck_noise",
    },
    {
        src: "sounds/EGOpen_1.mp3",
        id: "EGOpen_1",
    },
    {
        src: "sounds/swanny.mp3",
        id: "swanny",
    },
];
export default additionalSounds;
