import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { MandolineGame } from "./MandolineGame";

const MandolineComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new MandolineGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/mandolin"
            animationName="mandolin"
            onLoaded={game.current.loaded}
            changeFramerate={true}
        />
    );
};
export default MandolineComponent;
