import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import {
    getLetterIndex,
    loadFont,
    MC,
    playSound,
    stopAllSounds,
    waitForEvent,
    waitForTimer,
} from "../../../animate/utils";
import { Navigation } from "../../../navigation/navigation";
import { routes } from "../../../navigation/routesPath";
import { getRandomNumber } from "../../../utils/MathUtils";
import {
    findBeginToLetterActivity,
    findIncorrectToLetterActivity,
    findMidToLetterActivity,
    returnFrameToFigureType,
} from "./activitiesJsonFilefunction";

const TEXT_WIDTH = 190;
const COLOR_NORMAL = "#FFFF00";
const COLOR_OVER = "#FF8FCC";
const COLOR_CLICK = "#e516ce";
export class ChildrenInBox {
    root: MC;
    allFigures: MC = null;
    letter: number;
    word1: any;
    word2: any;
    word3: any;
    correctAnswers: number = 0;
    word1Arr: createjs.Text[] = [];
    word2Arr: createjs.Text[] = [];
    word3Arr: createjs.Text[] = [];
    figureArr: any[] = [];
    figures: MC[3] = ["", "", ""];
    enableEvents: boolean = false;
    objHovered: boolean;
    objToHover: any;
    textBoxToHover: any;
    namesArr: string[] = [];
    playing: boolean = false;
    lastClicked: number = 0;
    enableHelp: () => void;
    disableHelp: () => void;
    showSwitcher: () => void;

    constructor(
        root: createjs.MovieClip,
        letter: number,
        enableHelp: () => void,
        disableHelp: () => void,
        showSwitcher: () => void
    ) {
        this.root = root;
        this.letter = letter;
        this.enableHelp = enableHelp;
        this.disableHelp = disableHelp;
        this.showSwitcher = showSwitcher;
        this.loaded();
        (window as any).cbox = this;
    }

    loopWord = async (word: any, arr: createjs.Text[], box: MC, index: number) => {
        const wordText = word.sWord;
        // debugger
        const wordSound = await loadSound(`/letters_island/children_in_box/sounds/${word.sSound}.mp3`);
        for (let i = 0; i < wordText.length; i++) {
            const letter = wordText.charAt(i);
            const text = new createjs.Text(letter, "70px 'ProntoMF'", COLOR_NORMAL);

            arr.push(text);
            box.textHolder.visible = false;
            box.textHolder.addChild(text);
        }
        arr.reverse();

        // await waitForTimer(100);

        let totalWidth: number = 0;
        arr.forEach((text) => {
            totalWidth += text.getMeasuredWidth();
        });

        let x = (TEXT_WIDTH - totalWidth) / 2;
        arr.forEach((text) => {
            text.x = x;
            x += text.getMeasuredWidth();

            const hit = new createjs.Shape();
            hit.graphics.beginFill("#000").drawRect(0, 0, text.getMeasuredWidth(), text.getMeasuredHeight());

            text.hitArea = hit;
            text.addEventListener("click", (e: any) => {
                if (this.enableEvents) {
                    this.clickLetter(box, e.target, word, arr, index);
                    box.textHolder.cursor = "pointer";
                }
            });
            text.addEventListener("rollover", (e: any) => {
                if (this.enableEvents) {
                    e.target.color = COLOR_OVER;
                    box.textHolder.cursor = "pointer";
                } else {
                    this.objHovered = true;
                    this.objToHover = e.target;
                    this.textBoxToHover = box.textHolder;
                }
            });
            text.addEventListener("rollout", (e: any) => {
                if (this.enableEvents) {
                    e.target.color = COLOR_NORMAL;
                } else {
                    this.objHovered = false;
                    this.objToHover = null;
                    this.textBoxToHover = null;
                }
            });
        });
        return this.animateTextIn(box, wordSound);
    };

    disableTextEvents = (arr: any) => {
        arr.forEach((text: any) => {
            text.removeAllEventListeners();
            text.cursor = "default";
        });
    };

    clickLetter = async (box: MC, letterPressed: any, word: any, arr: any, index: number) => {
        stopAllSounds();
        this.lastClicked = createjs.Ticker.getTime(true);
        this.enableHelp();
        if (getLetterIndex(letterPressed.text) === this.letter) {
            this.disableHelp();
            this.enableEvents = false;
            letterPressed.color = COLOR_CLICK;
            this.disableTextEvents(arr);
            this.correctAnswers++;
            const num = getRandomNumber(1, 7);
            await playSound(`m_good${num}`);
            await playSound(`maya_letter${this.letter + 1}`);
            const frameNum = returnFrameToFigureType(word.sType, this.figureArr);
            this.figureArr.push(frameNum);
            box.pictureMc.visible = true;
            await this.showFigure(box.pictureMc[`figure${frameNum}`], index);
            this.enableHelp();
            this.enableEvents = true;
            if (this.objHovered === true) {
                this.objToHover.color = COLOR_OVER;
                this.textBoxToHover.cursor = "pointer";
                this.objHovered = false;
                this.textBoxToHover = false;
            }
            if (this.correctAnswers === 3) {
                this.playing = false;
                this.hideChildren();
                await this.hideText();
                this.endActivity();
            }
            if (this.correctAnswers > 3) {
                console.error("more than 3 correct answers");
            }
        } else {
            playSound("wrong_letter");
        }
    };

    showFigure = (figure: MC, index: number) => {
        this.figures[index - 1] = figure;
        figure.visible = true;
        figure.gotoAndPlay(1);
        return waitForEvent(figure, "animationEnd");
    };

    hideBoxFigures = (box: MC) => {
        for (let index = 1; index < 21; index++) {
            const fig = box.pictureMc[`figure${index + 1}`];
            fig.gotoAndStop(0);
            fig.visible = false;
        }
    };
    finishFigureAnimation = async (figure: MC) => {
        figure.gotoAndStop("wait");
        figure.gotoAndPlay("wait");
        return waitForEvent(figure, "animationEnd");
    };

    hideChildren = async () => {
        for (let index = 0; index < 3; index++) {
            this.finishFigureAnimation(this.figures[index]);
        }
    };

    hideText = async () => {
        for (let i = 3; i > 0; i--) {
            const box = this.allFigures[`box${i}`];
            await this.animTextOut(box);
        }
    };

    animTextOut = (box: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(box.textHolder)
                .to(
                    {
                        alpha: 0,
                    },
                    800
                )
                .call(resolve);
        });
    };

    startPlay = async () => {
        stopAllSounds();
        await playSound("intro");
        this.findWords();
    };

    animateTextIn(box: MC, wordSound: any) {
        box.textHolder.alpha = 0;
        box.textHolder.visible = true;
        return new Promise((resolve) => {
            // playSound(word.sSound);
            soundPlayed(wordSound.play());
            createjs.Tween.get(box.textHolder)
                .to(
                    {
                        alpha: 1,
                    },
                    1000
                )
                .call(resolve);
        });
    }

    endActivity = async () => {
        this.allFigures.visible = false;
        playSound("boxUp");
        this.root.gotoAndStop(2);
        this.root.out_mc.gotoAndPlay(0);
        await waitForEvent(this.root, "animationEnd");
        Navigation.openPage(routes.letters.activities.wood_boxes.replace(":letterIndex", this.letter + ""));
        this.showSwitcher();
    };

    findWords = async () => {
        this.word1 = findBeginToLetterActivity(this.letter + 1, this.namesArr);
        this.namesArr.push(this.word1.sWord);
        this.word2 = findMidToLetterActivity(this.letter + 1, this.namesArr);
        this.namesArr.push(this.word1.sWord);
        this.word3 = findIncorrectToLetterActivity(this.letter + 1, this.namesArr);
        this.namesArr.push(this.word1.sWord);
        await this.loopWord(this.word3, this.word3Arr, this.allFigures.box3, 3);
        await this.loopWord(this.word2, this.word2Arr, this.allFigures.box2, 2);
        await this.loopWord(this.word1, this.word1Arr, this.allFigures.box1, 1);
        await playSound("help");
        this.enableHelp();
        this.enableEvents = true;
        this.playing = true;
        this.lastClicked = createjs.Ticker.getTime(true);
        if (this.objHovered === true) {
            this.objToHover.color = COLOR_OVER;
            this.textBoxToHover.cursor = "pointer";
            this.objHovered = false;
            this.textBoxToHover = false;
        }
    };

    showGameFigures = async () => {
        this.allFigures = new this.root.lib.allFigures();
        const mask = new createjs.Shape();
        mask.graphics.beginFill("#000").drawRect(0, 0, 800, 600);
        this.allFigures.mask = mask;
        this.allFigures.x = 334;
        this.allFigures.y = 190;
        this.allFigures.visible = false;
        for (let i = 0; i < 3; i++) {
            const box = this.allFigures[`box${i + 1}`];
            this.hideBoxFigures(box);
        }
        this.root.addChild(this.allFigures);
    };

    boxesIn = async () => {
        playSound("boxDrop").then(this.startPlay);
        this.root.gotoAndStop(0);
        await waitForTimer(0);
        this.root.in_mc.visible = true;
        this.root.in_mc.gotoAndPlay(1);
        await waitForEvent(this.root.in_mc, "finish");
        this.root.gotoAndStop(1);
        this.allFigures.visible = true;
        this.root.setChildIndex(this.allFigures, 9);
        for (let i = 0; i < 3; i++) {
            const box = this.allFigures[`box${i + 1}`];
            this.hideBoxFigures(box);
            box.visible = true;
        }
        // this.showAllFigures();
    };

    showAllFigures = async () => {
        // stopAllSounds();
        // await playSound("boxDrop");
        this.root.gotoAndStop(1);
        for (let i = 0; i < 3; i++) {
            const box = this.allFigures[`box${i + 1}`];
            this.hideBoxFigures(box);
            box.visible = false;
        }
        const box = this.allFigures.box1;
        box.visible = true;
        for (let index = 3; index < 21; index++) {
            const fig = box.pictureMc[`figure${index + 1}`];
            await this.showFigure(fig, 0);
            await this.finishFigureAnimation(fig);
        }
    };

    help = async () => {
        this.disableHelp();
        this.lastClicked = createjs.Ticker.getTime(true);
        await playSound("help");
    };
    loaded = async () => {
        loadFont(this.root, "ProntoMF");
        this.root.in_mc.visible = false;
        const duration = 12000;
        this.disableHelp();
        this.root.addEventListener("tick", async (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (this.playing && createjs.Ticker.getTime(true) - this.lastClicked > duration) {
                this.help();
            }
        });
        this.showGameFigures();
        this.boxesIn();
    };
}
