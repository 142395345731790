import { MC, stopAllSounds, waitForEvent } from "../../animate/utils";
import { ZigiGameBase } from "./ZigiGameBase";
import { loadSound } from "../../animate/sound_utils";
import { getRandomNumber } from "../../utils/MathUtils";

export default class ZigiColorsGame extends ZigiGameBase {
    colors = ["yellow", "white", "black", "blue", "red", "green"];
    questionIndex = 0;
    colorBtnClicked: boolean = false;
    shouldPlay: boolean = true;
    enabledFramBtn: boolean = true;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    startLoop: boolean = false;

    loaded(root: MC) {
        super.loaded(root);
    }
    startLearn = async () => {
        try {
            super.startLearn();
            for (let i = 0; i < 6; i++) {
                const currentColor = this.root[`${this.colors[i]}_mc`];
                currentColor.removeAllEventListeners();
            }
            const instPath = `/english/sounds/zigi_colors_game_sounds/learn/inst.mp3`;
            const instSound = loadSound(instPath);
            this.helpSrc = instSound;
            this.helpSrc2 = null;
            await this.cancelableWait(super.playSound(instSound));
            this.addEventsToColorsBtn(false, null);
        } catch (ignore) {}
    };

    addEventsToColorsBtn = async (fromExercise: boolean, correctAnswer: any) => {
        this.root.mouseEnabled = true;
        for (let i = 0; i < 6; i++) {
            const currentColor = this.root[`${this.colors[i]}_mc`];
            currentColor.colorName = this.colors[i];
            const colorSound = loadSound(`/english/sounds/zigi_colors_game_sounds/learn/${currentColor.colorName}.mp3`);
            const colorTalkSound = loadSound(
                `/english/sounds/zigi_colors_game_sounds/learn/${currentColor.colorName}M.mp3`
            );

            currentColor.cursor = "pointer";
            currentColor.mouseChildren = false;
            currentColor.addEventListener("click", async () => {
                this.root.mouseEnabled = false;
                if (!this.colorBtnClicked) {
                    this.colorBtnClicked = true;
                    stopAllSounds();
                    currentColor.gotoAndStop(0);
                    currentColor.mouseEnabled = false;
                    this.root.mouseEnabled = false;
                    if (!fromExercise || (fromExercise && currentColor.colorName === correctAnswer)) {
                        super.playSound(colorSound);
                        if (!this.shouldPlay) return;
                        currentColor.gotoAndPlay("start");
                        if (!this.shouldPlay) return;
                        await this.cancelableWait(waitForEvent(currentColor, "sayHello"));
                        super.playSound(colorTalkSound);
                        await this.cancelableWait(waitForEvent(currentColor, `${currentColor.colorName}AnimationEnd`));
                        stopAllSounds();
                        this.root.mouseEnabled = true;
                        currentColor.mouseEnabled = true;
                        this.colorBtnClicked = false;
                        if (fromExercise) {
                            stopAllSounds();
                            this.askQuestion();
                        }
                    } else if (fromExercise && currentColor.colorName !== correctAnswer) {
                        const colorTalkSoundWorng = loadSound(
                            `/english/sounds/zigi_colors_game_sounds/practice/W_${correctAnswer}.mp3`
                        );
                        await this.cancelableWait(super.playSound(colorTalkSoundWorng));
                        this.root.mouseEnabled = true;
                        currentColor.mouseEnabled = true;
                        this.colorBtnClicked = false;
                    }
                }
            });
            currentColor.addEventListener("rollover", async () => {
                this.startLoop = true;
                if (!this.colorBtnClicked) {
                    do {
                        currentColor.gotoAndPlay(1);
                        await this.cancelableWait(waitForEvent(currentColor, "stopLaughter"));
                    } while (this.startLoop && !this.colorBtnClicked);
                }
            });
            currentColor.addEventListener("rollout", () => {
                if (!this.colorBtnClicked) {
                    this.startLoop = false;
                    stopAllSounds();
                    currentColor.gotoAndStop(0);
                }
            });
        }
    };

    startExercise = async () => {
        try {
            super.startExercise();
            for (let i = 0; i < 6; i++) {
                const currentColor = this.root[`${this.colors[i]}_mc`];
                currentColor.removeAllEventListeners();
            }
            const instPath = `/english/sounds/zigi_colors_game_sounds/practice/inst.mp3`;
            const instSound = loadSound(instPath);
            this.helpSrc = instSound;
            await this.cancelableWait(super.playSound(instSound));
            this.askQuestion();
        } catch (ignore) {}
    };

    askQuestion = async () => {
        this.root.mouseEnabled = false;
        for (let i = 0; i < 6; i++) {
            const currentColor = this.root[`${this.colors[i]}_mc`];
            currentColor.removeAllEventListeners();
        }
        const colorName = this.randomColorForQuestion();
        const colorTalkSound = loadSound(`/english/sounds/zigi_colors_game_sounds/practice/${colorName}Z.mp3`);
        await this.cancelableWait(super.playSound(colorTalkSound));
        this.helpSrc2 = colorTalkSound;
        this.addEventsToColorsBtn(true, colorName);
    };

    randomColorForQuestion = () => {
        const index = getRandomNumber(0, 5);
        const color = this.colors[index];
        return color;
    };
    resetGame = () => {
        super.resetGame();
        stopAllSounds();
        this.colorBtnClicked = false;
        for (let i = 0; i < 6; i++) {
            const currentColor = this.root[`${this.colors[i]}_mc`];
            currentColor.removeAllEventListeners();
            currentColor.mouseEnabled = true;
            currentColor.gotoAndStop(0);
        }
    };
}
