import { LetterActivitiesFrame } from "./LetterActivitiesFrame";
import { AirplaneEnters } from "./AirplaneEnters";
import { DrawLetter } from "./DrawLetter";
import { FindLetters } from "./findLetters/FindLetters";
import { ChildrenInBox } from "./childrenInBox/ChildrenInBox";
import { ColorLetter } from "./ColorLetter";
import { setupStage, MC, playSound, stopAllSounds } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { WoodBoxes } from "./woodBoxes/WoodBoxes";
import { Crabs } from "./crabs/Crabs";
import { SoundToolTip } from "../../utils/soundTooltip";
import { loadSound } from "../../animate/sound_utils";
import { FinalLetters } from "./finalLetters/FinalLetter";
import { FinalLetterDraw } from "./finalLetters/FinalLetterDraw";

export class LetterActivitiesdGame {
    letter: number;
    childInBox: any;
    crabs: any;
    findLetters: FindLetters;
    constructor(letter: number) {
        this.letter = letter;
    }
    private airplaneEnters: AirplaneEnters = null;
    private frame: LetterActivitiesFrame = null;
    private drawLetter: DrawLetter = null;
    private woodBoxes: WoodBoxes = null;
    private colorLetter: ColorLetter = null;
    private finalLetters: FinalLetters;
    private finalLettersDraw: FinalLetterDraw;
    child: createjs.MovieClip;

    showSwitcher = () => {
        return this.frame?.showSwitcher();
    };

    hideSwitcher = () => {
        return this.frame?.hideSwitcher();
    };

    childLoaded = (mc: createjs.MovieClip) => {
        this.child = mc;
        setupStage(this.frame?.root, mc);
        this.hideSwitcher();
    };

    frameLoaded = (mc: createjs.MovieClip) => {
        this.frame = new LetterActivitiesFrame(mc, this.letter);
        setupStage(mc, this.child);
        this.disableHelp();
    };

    colorLoaded = async (mc: createjs.MovieClip) => {
        this.childLoaded(mc);
        this.colorLetter = new ColorLetter(mc, this.letter, this.enableNext, this.enableHelp);
        this.colorLetter.loaded();
        this.disableNext();
        this.disableHelp();
    };

    airplaneEntersLoaded = async (mc: createjs.MovieClip, navigate: string, showSwitcher: boolean) => {
        this.childLoaded(mc);
        this.disableNext();
        this.airplaneEnters = new AirplaneEnters(mc);
        await this.airplaneEnters.loaded();
        Navigation.openPage(navigate.replace(":letterIndex", this.letter + ""));
    };

    drawLetterLoaded = (mc: createjs.MovieClip) => {
        this.childLoaded(mc);
        this.drawLetter = new DrawLetter(mc, this.showSwitcher);
        this.drawLetter.showLetter(this.letter);
    };

    findLettersLoaded = (
        mc: createjs.MovieClip,
        pausePlay: () => void,
        restartVideo: () => void,
        lettersContext: any
    ) => {
        this.childLoaded(mc);
        this.findLetters = new FindLetters(
            mc,
            this.letter,
            this.enableHelp,
            this.disableHelp,
            this.enableNext,
            this.disableNext,
            pausePlay,
            restartVideo,
            lettersContext
        );
        return this.findLetters;
    };

    finalLettersLoaded = (mc: createjs.MovieClip) => {
        this.childLoaded(mc);

        this.finalLetters = new FinalLetters(mc, this.letter, this.showSwitcher, this.disableNext);
    };

    finalDrawsLoaded = (mc: createjs.MovieClip) => {
        this.childLoaded(mc);
        this.finalLettersDraw = new FinalLetterDraw(mc, this.letter, this.showSwitcher, this.disableNext);
    };

    childInBoxLoaded = (mc: createjs.MovieClip) => {
        this.childLoaded(mc);
        this.childInBox = new ChildrenInBox(mc, this.letter, this.enableHelp, this.disableHelp, this.showSwitcher);
        this.disableNext();
        this.disableHelp();
    };

    crabsLoaded = (mc: createjs.MovieClip, lettersContext: any) => {
        this.childLoaded(mc);
        this.crabs = new Crabs(
            mc,
            this.letter,
            this.enableHelp,
            this.disableHelp,
            0,
            this.showSwitcher,
            lettersContext
        );
        this.disableNext();
        this.disableHelp();
    };

    woodBoxesLoaded = (mc: createjs.MovieClip) => {
        this.childLoaded(mc);
        this.woodBoxes = new WoodBoxes(
            mc,
            this.showSwitcher,
            this.letter,
            this.enableHelp,
            this.disableHelp,
            this.enableNext
        );
    };

    enableBtn = (btn: MC, event: () => void, sound: string) => {
        btn.gotoAndStop("normal");
        btn.cursor = "pointer";
        btn.removeAllEventListeners();
        SoundToolTip.addToolTip(btn, sound);
        btn.addEventListener("rollover", () => {
            btn.gotoAndStop("over");
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("normal");
        });
        btn.addEventListener("click", () => {
            btn.gotoAndStop("pressed");
            event && event();
        });
    };

    disableBtn = (btn: MC) => {
        if (!btn) return;
        btn.gotoAndStop("disabled");
        btn.cursor = "default";
        btn.removeAllEventListeners();
    };

    enableNext = (path: string) => {
        this.enableBtn(
            this.frame.root.next_btn,
            () => {
                Navigation.openPage(path.replace(":letterIndex", this.letter + ""));
                this.showSwitcher();
            },
            "next"
        );
    };

    disableNext = () => {
        this.disableBtn(this.frame.root.next_btn);
    };
    enableHelp = (enableOnce?: boolean, specialSound?: any) => {
        this.enableBtn(
            this.frame.root.help_btn,
            async () => {
                this.disableHelp();
                stopAllSounds();
                let oSound;
                if (specialSound) {
                    oSound = await loadSound(specialSound);
                    oSound.play();
                    if (!enableOnce) {
                        this.enableHelp();
                    }
                } else {
                    playSound("help").then(() => {
                        if (!enableOnce) {
                            this.enableHelp();
                        }
                    });
                }
            },
            "helpBtn"
        );
    };
    disableHelp = () => {
        this.disableBtn(this.frame?.root.help_btn);
    };

    unload = () => {};
}
