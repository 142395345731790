import { MC, waitForEvent } from "../../../animate/utils";
import { Navigation } from "../../../navigation/navigation";
import { routes } from "../../../navigation/routesPath";

export class FinalLetters {
    root: MC;
    letter: number;
    showSwitcher: () => void;
    disableNext: () => void;
    constructor(root: createjs.MovieClip, letter: number, showSwitcher: () => void, disableNext: () => void) {
        this.root = root;
        this.letter = letter;
        this.showSwitcher = showSwitcher;
        this.disableNext = disableNext;
        (window as any).final = root;
        this.loaded();
    }

    loaded = async () => {
        this.root.gotoAndStop("loaded");
        this.disableNext();
        await waitForEvent(this.root.letterclip, "animationEnd");
        console.log("after await");
        Navigation.openPage(
            routes.letters.activities.enter_exit.replace(":letterIndex", this.letter + "").replace(":action", "exit")
        );
        // await this.showSwitcher();
    };
}
