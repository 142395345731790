export const getLetterBg = (letter: number) => {
    const airplaneEnters = [0, 1, 2, 3, 8, 9, 10, 11, 17, 18, 19, 20, 21];
    const tractorEnters = [4, 5, 6, 7, 12, 13, 14, 15, 16];
    if (airplaneEnters.includes(letter)) {
        return "airplane_enter";
    } else if (tractorEnters.includes(letter)) {
        return "tractor_enter";
    }
};

export const isEndLetter = (letter: number) => {
    const endLetters = [11, 13, 14, 17, 18];
    return endLetters.includes(letter);
};
