import React, { useEffect, useRef, useState } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import AnimateCC from "../animate";
import { MC } from "../animate/utils";
import { EnglishTimeMenu } from "./EnglishTimeMenu";
import { routes } from "../navigation/routesPath";
import { useUserContext } from "../context/UserContext";
import EnglishTimeComponent from "./EnglishTimeComponent";
import { SodRoute } from "../navigation/PrivateRoot";
import { PreloaderComponent } from "../loaders/PreloaderComponent";

export default function EnglishTimeMenuComponent() {
    const { isExact: isMenu } = useRouteMatch();

    const { isLoggedIn } = useUserContext();

    const firstTime = useRef(true);
    const menu = React.useRef(new EnglishTimeMenu(isLoggedIn));
    const directLink = new URLSearchParams(window.location.search).get("path");
    const [fromDirectLink] = useState(directLink);
    const [screenReady, setScreenReady] = useState(false);

    useEffect(() => {
        if (!isMenu) return;

        if (firstTime.current) {
            firstTime.current = false;
        } else {
            menu.current.reset();
        }
    }, [isMenu]);

    useEffect(() => {
        menu.current.setIsLoggedIn(isLoggedIn);
    }, [isLoggedIn]);

    return (
        <>
            {fromDirectLink && !screenReady && <PreloaderComponent name="maya" />}
            <AnimateCC
                key="menu"
                base="/english/"
                animationName="english_menu"
                onLoaded={async (mc: MC) => {
                    setScreenReady(true);
                    menu.current.loaded(mc);
                }}
                visible={isMenu}
            />
            <Switch>
                <SodRoute path={routes.english_time.game} component={EnglishTimeComponent} />
            </Switch>
        </>
    );
}
