import { MC, setupStage, stopAllSounds, playSoundSync, localToGlobal, playSound } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";

export class HorsesGame {
    constructor(private frame: any) {}
    private root: MC;
    private click = false;
    private lastHorse: MC;
    private speed: number = 2.48;
    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        (window as any).HorsesGame = mc;
        (window as any).game = this;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.root.gotoAndPlay(9);
        this.root["horse1"].gotoAndPlay(0);
        this.root["horse2"].gotoAndPlay(60);
        this.root["horse3"].gotoAndPlay(0);
        this.root["horse4"].gotoAndPlay(60);
        this.horseColor(this.root.horse1);
        this.horseColor(this.root.horse2);
        this.horseColor(this.root.horse3);
        this.horseColor(this.root.horse4);
        this.lastHorse = this.root.horse1;
        let audio = playSoundSync("tuneWAV", -1);
        audio.volume = 0.2;
        this.start();
        this.frame.root.Bar.out.addEventListener("click", async (e: MouseEvent) => {
            await stopAllSounds();
        });
    };
    start = () => {
        console.log("start!");
        for (let i = 0; i < 7; i++) {
            this.root["sad_" + i].click = false;
            this.root["sad_" + i].addEventListener("mousedown", (e: MouseEvent) => {
                playSound("Hit");
                if (!this.root["sad_" + i].click) {
                    if (this.click) {
                        for (let i = 0; i < 7; i++) {
                            if (this.root["sad_" + i].click) {
                                this.root["sad_" + i].click = false;
                                this.root["sad_" + i].x = this.root["sad_" + i].lastX;
                                this.root["sad_" + i].y = this.root["sad_" + i].lastY;
                            }
                        }
                    }
                    this.root["sad_" + i].click = true;
                    this.sadClick(this.root["sad_" + i]);
                    this.click = true;
                } else {
                    this.root["sad_" + i].click = false;

                    console.log(this.root["sad_" + i].x, this.root["sad_" + i].y);
                    this.checkIntersection(this.root["sad_" + i], i);
                    this.click = false;
                }
            });
        }

        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            for (let i = 0; i < 7; i++) {
                if (this.root["sad_" + i].click) {
                    this.moveSad(this.root["sad_" + i]);
                }
            }
            for (let i = 1; i <= 4; i++) {
                this.root["horse" + i].x += this.speed;
                if (this.root["horse" + i].x > 900) {
                    this.horseColor(this.root["horse" + i]);
                    this.root["horse" + i].x = this.lastHorse.x - 250;
                    this.lastHorse = this.root["horse" + i];
                }
            }
        });
    };

    checkIntersection = (sad: MC, i: number) => {
        // let touch = false;
        for (let j = 1; j <= 4; j++) {
            let horse = this.root["horse" + j];
            if (horse.color === i) {
                if (this.isIntersecting(horse, sad)) {
                    horse.hsaddle.gotoAndStop(i);
                    sad.visible = false;
                    sad.x = sad.lastX;
                    sad.y = sad.lastY;
                    sad.visible = true;
                    // touch = true;
                    this.speed += 0.35;
                    console.log("true");

                    return;
                }
            }
        }
        //   if (touch === false) {
        sad.x = sad.lastX;
        sad.y = sad.lastY;
        // }
    };

    sadClick = (sad: MC) => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        sad.moveX = localMouse.x - sad.x;
        sad.moveY = localMouse.y - sad.y;

        sad.lastX = sad.x;
        sad.lastY = sad.y;
    };
    horseColor = (horse: MC) => {
        horse.color = getRandomNumber(0, 6);
        horse.hbody.gotoAndStop(horse.color);
        horse.hriens.gotoAndStop(7);
        horse.hsaddle.gotoAndStop(7);
        horse.sad = this.root["sad_" + horse.color];
    };
    moveSad = (sad: MC) => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);

        sad.x = localMouse.x - sad.moveX;
        sad.y = localMouse.y - sad.moveY;
    };
    isIntersecting = (horse: MC, sad: MC) => {
        const sadAreaX = sad.x - 20;
        const sadAreaY = sad.y - 3.5;
        const horseAreaX = horse.x - 15;
        const horseAreaY = localToGlobal(horse.hbody, 0, 0).y - 8;
        console.log("hbody", horseAreaY);

        const sadRect = new createjs.Rectangle(sadAreaX - 37.55 / 2, sadAreaY - 38.3 / 2, 37.55, 38.3);

        const horseRect = new createjs.Rectangle(horseAreaX - 118 / 2, horseAreaY - 71.8 / 2, 82, 55);
        return sadRect.intersects(horseRect);
    };
}
