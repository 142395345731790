import { MC, playSound, playSoundSync, setupStage, waitForEvent } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";

export class RollerCoasterGame {
    private root: MC;
    private newTrain = true;
    private startGame = true;
    private levelScores: number[] = [0, 0, 0];
    private currentLevel: number = 0;
    private panelCarsColors: number[] = [0, 0, 0, 0];
    private panelCarsShapes: number[] = [0, 0, 0, 0];
    private resultCarColors: number[] = [0, 0, 0, 0];
    private resultCarShapes: number[] = [0, 0, 0, 0];
    private trains: MC[];
    private carPanelColors: MC[];
    private topTrains: MC[];
    private buttomTrains: MC[];
    private finishAnimate: boolean = true;
    private newQuestion: boolean = false;
    private correctAnswer: boolean = false;
    private canGame: boolean = false;
    private trainGoOut: boolean = true;
    private currentTrain: MC;
    private currentPanel: MC;
    private audio: createjs.AbstractSoundInstance;
    private onMove = false;
    constructor(private frame: any) {}

    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        (window as any).Roller = mc;
        (window as any).game = this;
        this.root.sound.gotoAndStop(0);
        this.frame.hideBackground();
        this.start();
    };

    start = () => {
        this.trains = [this.root.train_1, this.root.train_2, this.root.train_3];
        this.topTrains = [this.root.toptrack, this.root.toptrack_1, this.root.toptrack_2];
        this.buttomTrains = [this.root.bottomtrack, this.root.bottomtrack_1, this.root.bottomtrack_2];
        this.carPanelColors = [this.root.colours, this.root.colours_1, this.root.colours_2];

        this.root.addEventListener("tick", (e: any) => {
            // const normal = 1000 / this.root.framerate;
            // this.delta = normal / (e as createjs.TickerEvent).delta;
            if ((e as createjs.TickerEvent).paused) return;

            if (this.trainGoOut) this.startNewTrain();
            if (this.onMove) {
                this.moveTrain();
                this.movePanel();
            }
        });

        this.trains.forEach((train) => {
            train.children.forEach((child: MC, i: number) => {
                child.addEventListener("pressup", async () => {
                    if (!this.canGame) return;
                    if (child.body) {
                        playSound("Click");
                        this.resultCarColors[i] !== 6 ? this.resultCarColors[i]++ : (this.resultCarColors[i] = 0);
                        child.body.gotoAndStop(this.resultCarColors[i]);
                    } else {
                        this.resultCarShapes[i - 4] !== 6
                            ? this.resultCarShapes[i - 4]++
                            : (this.resultCarShapes[i - 4] = 0);
                        child.gotoAndStop(this.resultCarShapes[i - 4]);
                    }
                    await this.compareTrains();
                });
            });
        });

        this.startNewTrain();
    };

    startNewTrain = async () => {
        this.trainGoOut = false;
        if (!this.startGame) {
            await this.startSuccessAnim();
        }
        this.newTrain = true;
        this.startGame = false;
        if (this.finishAnimate) {
            this.finishAnimate = false;
            this.trainGoOut = false;
            this.currentTrain = this.trains[this.currentLevel];
            this.currentTrain.cursor = "pointer";
            this.currentPanel = this.carPanelColors[this.currentLevel];
            this.resetTrain(this.currentTrain);
            this.panelCarsColors = [0, 0, 0, 0];
            this.resultCarColors = [0, 0, 0, 0];
            if (this.currentLevel === 2) {
                this.panelCarsShapes = [0, 0, 0, 0];
                this.resultCarShapes = [0, 0, 0, 0];
            }
            //this.currentTrain.x-=5;
            this.newQuestion = true; ///////////////////
            this.correctAnswer = false;
            this.startCarPanelColors();
            this.currentTrain.x = -392;
            this.audio = playSoundSync("exitTrain");
            this.onMove = true;
        }
    };

    compareTrains = async () => {
        if (
            this.panelCarsColors.toString() === this.resultCarColors.toString() &&
            this.panelCarsShapes.toString() === this.resultCarShapes.toString()
        ) {
            this.correctAnswer = true;
            this.newQuestion = false;
            this.canGame = false;
            this.levelScores[this.currentLevel]++;
            this.finishAnimate = true;
            this.onMove = true;
            this.audio = playSoundSync("exitTrain");
        }
    };

    startCarPanelColors = () => {
        switch (this.currentPanel.name) {
            case "colours":
                this.startPanel1();
                break;
            case "colours_1":
                this.startPanel2();
                break;
            case "colours_2":
                this.startPanel3();
                break;
            default:
                break;
        }
    };

    startPanel1 = () => {
        this.root.gotoAndStop(20);
        this.root.bottomtrack.gotoAndStop(0);
        this.root.toptrack.gotoAndStop(0);
        this.currentPanel.colors_panel.children.forEach((car: MC, index: number) => {
            this.panelCarsColors[index] = getRandomNumber(0, 6);
            car.gotoAndStop(this.panelCarsColors[index]);
        });
    };

    startPanel2 = () => {
        this.root.gotoAndStop(50);
        this.root.bottomtrack_1.gotoAndStop(0);
        this.root.toptrack_1.gotoAndStop(0);
        for (let i = 0; i < 4; i++) {
            this.panelCarsColors[i] = getRandomNumber(0, 6);
        }
        this.currentPanel.p_4.gotoAndStop(this.panelCarsColors[0]);
        this.currentPanel.p_3.gotoAndStop(this.panelCarsColors[1]);
        this.currentPanel.p_2.gotoAndStop(this.panelCarsColors[2]);
        this.currentPanel.p_1.gotoAndStop(this.panelCarsColors[3]);
    };

    startPanel3 = () => {
        this.root.gotoAndStop(87);
        this.root.bottomtrack_2.gotoAndStop(0);
        this.root.toptrack_2.gotoAndStop(0);
        for (let i = 0; i < 4; i++) {
            this.panelCarsColors[i] = getRandomNumber(0, 6);
            this.panelCarsShapes[i] = getRandomNumber(0, 6);
        }
        this.currentPanel.p_4.gotoAndStop(this.panelCarsColors[0]);
        this.currentPanel.p_3.gotoAndStop(this.panelCarsColors[1]);
        this.currentPanel.p_2.gotoAndStop(this.panelCarsColors[2]);
        this.currentPanel.p_1.gotoAndStop(this.panelCarsColors[3]);

        this.currentPanel.s_4.gotoAndStop(this.panelCarsShapes[0]);
        this.currentPanel.s_3.gotoAndStop(this.panelCarsShapes[1]);
        this.currentPanel.s_2.gotoAndStop(this.panelCarsShapes[2]);
        this.currentPanel.s_1.gotoAndStop(this.panelCarsShapes[3]);
    };

    resetTrain = (train: MC) => {
        train.children.forEach((child: MC) => {
            child.body ? child.body.gotoAndStop(0) : child.gotoAndStop(0);
        });
        if (train.name === "topTrack_2") {
            train.children.forEach((child: MC) => {
                child.body ? child.sh.gotoAndStop(0) : child.gotoAndStop(0);
            });
        }
        // this.currentTrain.x-=5;
    };

    startSuccessAnim = async () => {
        this.finishAnimate = false;
        await this.trainsColors();
        this.topTrains[this.currentLevel].gotoAndPlay(0);
        await waitForEvent(this.root, "startSound");
        let sound = playSoundSync("rollercoster");
        sound.volume = 0.3;
        await waitForEvent(this.root, "bottomTrack");
        this.topTrains[this.currentLevel].gotoAndStop(107);
        this.buttomTrains[this.currentLevel].gotoAndPlay(6);
        await waitForEvent(this.root, "startSound");
        sound.play();
        await waitForEvent(this.root, "finishBottomTrack");
        this.finishAnimate = true;
        this.changeLevel();
    };

    moveTrain = () => {
        if (this.newQuestion) {
            this.enterTrain();
        }
        if (this.correctAnswer) {
            this.exitTrain();
        }
    };

    movePanel = () => {
        if (this.newQuestion) {
            this.enterColorsPanel();
        }
        if (this.correctAnswer) {
            this.exitColorsPanel();
        }
    };

    getCarRandomColor = () => {
        return getRandomNumber(1, 7);
    };

    enterTrain = () => {
        if (this.currentTrain.x <= 380) {
            this.currentTrain.x += 13;
        } else {
            // this.currentTrain.x=388;
            this.canGame = true;
            this.audio.stop();
            this.onMove = false;
        }
    };

    exitTrain = () => {
        if (this.currentTrain.x <= 1300) {
            this.currentTrain.x += 13;
        } else {
            if (this.newTrain) {
                this.onMove = false;
                this.audio.stop();
                this.trainGoOut = true;
                this.newTrain = false;
            }
        }
    };

    enterColorsPanel = () => {
        if (this.currentPanel.x > 650) {
            this.currentPanel.x -= 10;
        }
    };
    exitColorsPanel = () => {
        if (this.currentPanel.x < 1000) {
            this.currentPanel.x += 10;
        }
    };
    trainsColors = () => {
        this.topTrains[this.currentLevel].car_1.body.gotoAndStop(this.panelCarsColors[0]);
        this.topTrains[this.currentLevel].car_2.body.gotoAndStop(this.panelCarsColors[1]);
        this.topTrains[this.currentLevel].car_3.body.gotoAndStop(this.panelCarsColors[2]);
        this.buttomTrains[this.currentLevel].car_1.body.gotoAndStop(this.panelCarsColors[0]);
        this.buttomTrains[this.currentLevel].car_2.body.gotoAndStop(this.panelCarsColors[1]);
        this.buttomTrains[this.currentLevel].car_3.body.gotoAndStop(this.panelCarsColors[2]);
        if (this.topTrains[this.currentLevel] !== this.root.toptrack) {
            this.topTrains[this.currentLevel].car_4.body.gotoAndStop(this.panelCarsColors[3]);
            this.buttomTrains[this.currentLevel].car_4.body.gotoAndStop(this.panelCarsColors[3]);
        }
    };
    changeLevel = () => {
        if (this.levelScores[this.currentLevel] === 3) {
            if (this.currentLevel === 2) {
                this.currentLevel = 0;
            } else {
                this.currentLevel++;
            }
            this.levelScores[this.currentLevel] = 0;
        }
    };
}
