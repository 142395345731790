import { generatePath } from "react-router-dom";
import { MC, playSoundSync, stopAllSounds } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { SoundToolTip } from "../../utils/soundTooltip";
export class BibleMenu {
    private root: MC;
    private storyBtns: MC = ["btn1", "btn2", "btn3", "btn4"];
    private bgSound: createjs.AbstractSoundInstance;
    private titleSound: createjs.AbstractSoundInstance;

    constructor(private isLoggedIn: boolean) {}
    loaded = (mc: MC) => {
        this.root = mc;
        this.root.gotoAndStop(1);
        this.setIsLoggedIn(this.isLoggedIn);
        this.btnListeners();
        this.initSounds();
    };
    initSounds = async () => {
        this.bgSound = playSoundSync("bgMenu", { loop: -1 });
        this.bgSound.volume = 0.4;
        this.titleSound = playSoundSync("title");
    };

    setIsLoggedIn = (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        //guest example arrow
        if (this.root) {
            this.root.mcArrow.visible = !this.isLoggedIn;
        }
    };
    btnListeners = () => {
        this.storyBtns.forEach((b: string) => {
            this.initStoryBtn(b);
        });
        this.initBackBtn();
    };

    initBackBtn = async () => {
        const back_btn = this.root.btnBack;
        back_btn.gotoAndStop("up");
        back_btn.cursor = "pointer";
        back_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
        back_btn.addEventListener("mouseover", () => {
            back_btn.gotoAndStop("over");
            this.titleSound.stop();
        });
        back_btn.addEventListener("mouseout", () => {
            back_btn.gotoAndStop("up");
        });
        SoundToolTip.addToolTip(this.root.btnBack, "/bible_stories/sounds/back.mp3");
    };

    playBg = () => {
        this.bgSound?.play();
    };

    initStoryBtn = (btnName: string) => {
        const btn = this.root[btnName];
        btn.cursor = "pointer";
        btn.addEventListener("rollover", () => {
            this.titleSound.stop();
            btn.gotoAndPlay("_over");
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("_down");
        });
        btn.addEventListener("click", () => {
            const currentStory = btnName.slice(3);
            stopAllSounds();
            Navigation.openPage(generatePath(routes.bible_stories.story_menu.menu, { type: currentStory }));
        });

        SoundToolTip.addToolTip(btn, `/bible_stories/sounds/${btnName}.mp3`);
    };
}
