import { MC, setupStage, localToGlobal, playSoundSync, playSound } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
export class DuckGame {
    private root: MC;
    private speed: number = 0.8;
    private tcount = 0;
    private startClock: boolean = false;
    private duckRotate: boolean = false;
    private ducksNumber: number = 1;
    private level: number = 4;
    private audio: createjs.AbstractSoundInstance;
    constructor(private frame: any) {}
    private countW: number = 0;

    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        (window as any).DuckGame = mc;
        (window as any).game = this;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.root.gotoAndStop(8);
        this.root.rod.line.gotoAndStop(0);
        this.root.rod.line.lineDuck.shadow.shadow.gotoAndPlay(0);
        this.root.fduck.play = false;
        // createjs.Ticker.framerate = this.root.framerate;
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            const normal = 1000 / this.root.framerate;
            const delta = (e as createjs.TickerEvent).delta / normal;

            if (this.startClock) {
                this.moveRod();
                this.moveDucks(delta);
                this.clock(delta);
            }
            if (this.root.fduck.play) {
                this.fallingDuck();
            }
            if (this.duckRotate) {
                if (this.ducksNumber <= this.level) {
                    this.rotateDucks(this.ducksNumber);
                    this.ducksNumber += 0.5;
                } else this.rotateDucks(this.level);
            }
        });

        this.frame.root.Bar.addEventListener("click", this.clickToFall);
        this.root.addEventListener("click", this.clickToFall);

        this.root.bigDuck1.addEventListener("click", async (e: MouseEvent) => {
            this.audio = playSoundSync("_ducktune", -1);
            this.audio.volume = 0.3;
            // this.root.dd3.gotoAndStop(60);
            // this.root.dd5.gotoAndPlay(90);

            this.level = 4;
            this.root.gotoAndStop(25);
            for (let i = 1; i <= 4; i++) {
                this.root["w" + i].gotoAndStop(0);
                this.root["w" + i].rotation = 0;
            }
            this.reset();
        });
        this.root.bigDuck2.addEventListener("click", async (e: MouseEvent) => {
            this.level = 6;
            this.root.gotoAndStop(141);
            for (let i = 1; i <= 6; i++) {
                this.root["w" + i].gotoAndStop(0);
                this.root["w" + i].rotation = 0;
            }
            this.reset();
        });
        this.root.bigDuck3.addEventListener("click", async (e: MouseEvent) => {
            this.level = 8;
            this.root.gotoAndStop(210);
            for (let i = 1; i <= 8; i++) {
                this.root["w" + i].gotoAndStop(0);
                this.root["w" + i].rotation = 0;
                this.root["w" + i].y = 470.95;
            }
            this.reset();
        });
    };

    unload = () => {
        this.frame.root.Bar.removeEventListener("click", this.clickToFall);
        this.root.removeEventListener("click", this.clickToFall);
    };

    reset = () => {
        this.root.rod.visible = true;
        this.speed = 1.25;
        for (let i = 1; i <= 5; i++) {
            this.root["dd" + i].gotoAndPlay(getRandomNumber(0, 89) + 2);
            if (!this.root["dd" + i].visible) this.root["dd" + i].visible = true;
        }
        this.startClock = true;
        this.root.rod.line.gotoAndStop(0);
    };

    isIntersecting = (duck: MC) => {
        const rod = this.root.rod;
        const rodAreaX = rod.x + 58.3 - 0.25;
        const rodAreaY = rod.y - 0.3;
        const duckAreaX = duck.x + 8.65 - 24.35;
        const global = localToGlobal(duck.duck1, 0, 0);
        const duckAreaY = global.y - 70.35;
        const rodRect = new createjs.Rectangle(rodAreaX, rodAreaY, 3.9, 7);
        const duckRect = new createjs.Rectangle(duckAreaX, duckAreaY, 3.35, 4.7);
        // this.g.graphics.clear();
        // this.g.graphics.clear();
        // this.g.graphics
        //     .f("red")
        //     .drawRect(rodRect.x, rodRect.y, rodRect.width, rodRect.height);
        // duck.shape.graphics.clear();
        // duck.shape.graphics
        //     .f("blue")
        //     .drawRect(duckRect.x, duckRect.y, duckRect.width, duckRect.height);

        // this.root.addChild(duck.shape);
        // this.root.addChild(this.g);
        return rodRect.intersects(duckRect);
    };

    moveRod = () => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        this.root.rod.rodRod.rotation = -(localMouse.x - 400) / 6;
        const globalRodRed = localToGlobal(this.root.rod.rodRod.robRed, 0, 0);
        this.root.rod.x = localMouse.x + this.root.rod.x - globalRodRed.x;
        this.root.rod.y = localMouse.y + this.root.rod.y - globalRodRed.y;
    };

    fallingDuck = () => {
        this.root.fduck.y += 20;
        if (this.root.fduck.y > 560) {
            this.root.fduck.play = false;
            this.root["w" + this.countW].gotoAndStop(1);
            if (this.countW === this.level) {
                playSound("EGOpen_1");
                this.duckRotate = true;
                this.root.rod.visible = false;
                this.startClock = false;
                this.root.dclock.arm.rotation = 0;
            }
        }
    };
    moveDucks = (delta: number) => {
        for (let i = 1; i <= 5; i++) {
            this.root["dd" + i].x -= this.speed * delta;
            if (this.root["dd" + i].x < 100) {
                this.root["dd" + i].x += 750;
                if (this.root["dd" + i].visible === false) {
                    this.root["dd" + i].visible = true;
                }
            }
            if (this.root.dd1.stage)
                if (
                    this.isIntersecting(this.root["dd" + i]) &&
                    this.level >= this.countW + 1 &&
                    this.root.rod.line.currentFrame === 0
                ) {
                    //change it!!!!!!
                    //if (this.isIntersecting(this.root["dd" + i])&&this.root["dd" + i].visible) {
                    playSoundSync("duck_noise");
                    this.root.rod.line.gotoAndStop(1);
                    this.root.rod.line.lineDuck.gotoAndPlay(0);

                    this.root["dd" + i].visible = false;
                    this.speed += 0.4;
                }
        }
    };
    clock = (delta: number) => {
        if (this.startClock) {
            console.log("clock");
            if (delta < 2) {
                this.tcount += delta;
                this.root.dclock.arm.rotation += 0.5 * delta;
            } else {
                this.tcount += 1;
                this.root.dclock.arm.rotation += 0.5;
            }

            if (this.tcount > 720) {
                console.log("finish");

                this.root.gotoAndStop(8);
                this.tcount = 0;
                this.countW = 0;
                this.ducksNumber = 1;
                this.startClock = false;
                this.root.dclock.arm.rotation = 0;
                this.audio.stop();
            }
        }
    };
    rotateDucks = (count: number) => {
        if (this.root["w" + this.level].y < -100) {
            this.countW = 0;
            this.duckRotate = false;
            this.ducksNumber = 1;
            this.tcount = 0;
            this.root.dclock.arm.rotation = 0;
            this.startClock = false;
            switch (this.level) {
                case 4:
                    this.root.gotoAndPlay(139);
                    break;
                case 6:
                    this.root.gotoAndPlay(201);
                    break;
                case 8:
                    this.audio.stop();
                    this.root.gotoAndPlay(1);
                    break;
            }
        }
        for (let i = 1; i <= count; i++) {
            this.root["w" + i].rotation -= 10;
            this.root["w" + i].y -= 10;
        }
    };
    clickToFall = () => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        if (this.root.rod.line.currentFrame === 1) {
            playSound("swanny");
            this.root.fduck.y = this.root.rod.y + 37;
            this.root.fduck.x = localMouse.x - (localMouse.x - 400) / 2;
            this.root.fduck.play = true;
            this.countW += 1;
            this.root.rod.line.gotoAndStop(0);
            //this.root.rod.line.lineDuck.shadow.shadowAnimate.gotoAndPlay(0);
        }
    };
}
