import { localToGlobal, MC, playSound, setupStage } from "../../animate/utils";

const AREA_WIDTH = 31;
const AREA_HEIGHT = 15;

class HammerGame {
    private root: MC;
    private hammer: MC;
    private banger: MC;
    private rspeed: number = 5;
    private acc: number = 1.3;
    private mouseUp: boolean = false;
    private pressUp: boolean = false;
    private speed: number = 0;
    private intersects: boolean = false;

    private readonly g = new createjs.Shape();

    constructor(private frame: any) {}

    loaded = async (mc: createjs.MovieClip) => {
        console.log("in hammer loaded");
        this.root = mc;
        (window as any).hammer = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.hammer = this.root.hammer;
        this.banger = this.root.tower.thing;
        this.root.gotoAndStop(3);
        this.root.tower.topl.gotoAndStop(0);

        this.root.stage.mouseX = this.frame.root.stage.mouseX;
        this.root.stage.mouseY = this.frame.root.stage.mouseY;
        // this.root.stage.mouseY = this.root.localToGlobal(666, 302).y;

        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            const posx = this.root.globalToLocal(this.root.stage.mouseX, 0);
            const posy = this.root.globalToLocal(0, this.root.stage.mouseY);
            this.hammer.x = posx.x;
            this.hammer.y = posy.y;

            // this.debugHitArea();
        });

        this.hammer.addEventListener("mousedown", () => {
            this.root.hardness = 0;
            this.mouseUp = true;
        });
        this.hammer.addEventListener("pressup", () => {
            this.mouseUp = false;
            this.pressUp = true;
        });
        this.acc = 1.3;
        this.rspeed = 5;

        this.root.addChild(this.g);

        this.start();
    };

    start = () => {
        // this.root.gotoAndPlay(0);
        this.root.hardness = 0;
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;
            this.checkRowIntersection();
            if (this.mouseUp) {
                // console.log("mouse up");
                if (this.hammer.rotation < 180) {
                    this.hammer.rotation += 2;
                    this.root.hardness += 1;
                }
            } else {
                // console.log("mouse not up", this.hammer.rotation, this.intersects);
                if (this.hammer.rotation > 0) {
                    this.hammer.rotation -= this.rspeed;
                    this.rspeed *= 1.3;
                }

                if (this.hammer.rotation < 0) {
                    this.hammer.rotation = 0;
                    this.rspeed = 5;
                }
                if (this.pressUp && this.hammer.rotation === 0) {
                    // console.log("check intersection", this.root.hardness);
                    this.checkHammerIntersection(); //banger go to 15
                    this.pressUp = false;
                    this.root.hardness = 0;
                }
            }
            if (this.intersects) {
                // console.log("is intersecting");
                this.banger.y -= this.speed;
                this.speed /= this.acc;
                if (this.speed < 0.3 && this.speed > 0) {
                    // console.log("change position");
                    this.speed *= -1;
                    this.acc = 1 / 1.3;
                } else if (this.banger.y < -340 && this.speed > 0) {
                    playSound("bell_sound");
                    this.speed *= -1;
                    this.acc = 1 / 1.3;
                    // console.log("hit top");
                    this.root.tower.topl.gotoAndPlay(1);
                }
                if (this.banger.y > 0 && this.speed < 0) {
                    // console.log("back to bottom");
                    this.banger.y = 0;
                    this.intersects = false;
                    this.acc = 1.3;
                }
            }
        });
    };

    checkRowIntersection = () => {
        if (this.banger.y < this.root.tower.l_1.y) {
            this.root.tower.l_1.gotoAndPlay(1);
        } else {
            this.root.tower.l_1.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_2.y) {
            this.root.tower.l_2.gotoAndPlay(1);
        } else {
            this.root.tower.l_2.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_3.y) {
            this.root.tower.l_3.gotoAndPlay(1);
        } else {
            this.root.tower.l_3.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_4.y) {
            this.root.tower.l_4.gotoAndPlay(1);
        } else {
            this.root.tower.l_4.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_5.y) {
            this.root.tower.l_5.gotoAndPlay(1);
        } else {
            this.root.tower.l_5.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_6.y) {
            this.root.tower.l_6.gotoAndPlay(1);
        } else {
            this.root.tower.l_6.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_7.y) {
            this.root.tower.l_7.gotoAndPlay(1);
        } else {
            this.root.tower.l_7.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_8.y) {
            this.root.tower.l_8.gotoAndPlay(1);
        } else {
            this.root.tower.l_8.gotoAndStop(0);
        }
        if (this.banger.y < this.root.tower.l_9.y) {
            this.root.tower.l_9.gotoAndPlay(1);
        } else {
            this.root.tower.l_9.gotoAndStop(0);
        }
    };

    checkHammerIntersection = () => {
        const tower = this.root.tower;
        const hammerRect = this.getAreaRect(this.hammer.area);
        let currently_intersects = true;
        if (hammerRect.intersects(this.getAreaRect(tower.area))) {
            this.speed = this.root.hardness;
            // console.log("is intersecting area");
            this.root.sound.gotoAndPlay(1);
        } else if (hammerRect.intersects(this.getAreaRect(tower.area2))) {
            this.speed = this.root.hardness - 10;
            // console.log("is intersecting area2");
        } else if (hammerRect.intersects(this.getAreaRect(tower.area3))) {
            this.speed = this.root.hardness - 10;
            // console.log("is intersecting area3");
        } else if (hammerRect.intersects(this.getAreaRect(tower.area4))) {
            this.speed = this.root.hardness - 20;
            // console.log("is intersecting area4");
        } else if (hammerRect.intersects(this.getAreaRect(tower.area5))) {
            this.speed = this.root.hardness - 20;
            // console.log("is intersecting area5");
        } else if (hammerRect.intersects(this.getAreaRect(tower.area6))) {
            this.speed = this.root.hardness - 25;
            // console.log("is intersecting area6");
        } else if (hammerRect.intersects(this.getAreaRect(tower.area7))) {
            this.speed = this.root.hardness - 25;
            // console.log("is intersecting area7");
        } else if (hammerRect.intersects(this.getAreaRect(tower.area8))) {
            this.speed = this.root.hardness - 30;
            // console.log("is intersecting area8");
        } else if (hammerRect.intersects(this.getAreaRect(tower.area9))) {
            this.speed = this.root.hardness - 30;
            // console.log("is intersecting area9");
        } else {
            currently_intersects = false;
            if (!this.intersects) {
                this.speed = 0;
            }
            // console.log("not intersecting");
        }
        if (currently_intersects) {
            this.acc = 1.3;
            this.intersects = true;
        }
    };

    getAreaRect = (area: MC) => {
        const globalArea = localToGlobal(area, 0, 0);
        // console.log("kk", globalArea);
        return new createjs.Rectangle(
            globalArea.x - AREA_WIDTH / 2,
            globalArea.y - AREA_HEIGHT / 2,
            AREA_WIDTH,
            AREA_HEIGHT
        );
    };

    // noinspection JSUnusedLocalSymbols
    private debugHitArea() {
        const g = this.g.graphics;
        g.clear();
        const rect = this.getAreaRect(this.hammer.area);
        g.s("red").r(rect.x, rect.y, rect.width, rect.height);

        [
            this.root.tower.area,
            this.root.tower.area2,
            this.root.tower.area3,
            this.root.tower.area4,
            this.root.tower.area5,
            this.root.tower.area6,
            this.root.tower.area7,
            this.root.tower.area8,
            this.root.tower.area9,
        ].forEach((value) => {
            const rect = this.getAreaRect(value);
            g.s("blue").r(rect.x, rect.y, rect.width, rect.height);
        });
    }
}

export default HammerGame;
