import React, { useState } from "react";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import musicSound from "./musicSounds.json";

export function MusicGameComponent(props: any) {
    const { animationName, additionalSounds = [], onLoaded, game, levels, ...otherProps } = props;
    const [frameLoaded, setFrameLoaded] = useState(false);
    return (
        <>
            {frameLoaded && (
                <AnimateCC
                    base={`/music/${animationName}`}
                    animationName={animationName}
                    additionalSounds={[...additionalSounds, ...musicSound]}
                    onLoaded={onLoaded}
                    frameWidth={700}
                    frameHeight={550}
                    scale={0.67}
                    {...otherProps}
                />
            )}

            <AnimateCC
                base="/music/frame"
                animationName="music_frame"
                onLoaded={(mc: MC) => {
                    game.current.frameLoaded(mc, levels);
                    setFrameLoaded(true);
                }}
                style={{
                    background: "none",
                }}
                scale={0.74}
                shiftX={110}
                shiftY={110}
            />
        </>
    );
}
