import {
    MC,
    setupStage,
    playSoundSync,
    waitForEvent,
} from "../../animate/utils";
export class MusicGame {
    private root: MC;

    private click_mandolin: boolean = false;
    private click_piano: boolean = false;
    private click_tuba: boolean = false;
    private click_guitar: boolean = false;
    private click_drums: boolean = false;
    private click_flute: boolean = false;
    private key_sound: createjs.AbstractSoundInstance;
    private startTime: number;
    constructor(private frame: any) {}

    loaded = async (mc: createjs.MovieClip) => {
        this.root = mc;
        (window as any).music = mc;
        (window as any).game = this;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();

        for (let i = 1; i <= 8; i++) {
            this.root.trumpet["key_" + i].gotoAndStop(0);
        }
        this.root.gotoAndStop(0);
        this.root.girl.gotoAndStop(0);
        this.root.tuba.tuba.gotoAndStop(0);
        this.root.drums.bong.gotoAndStop(0);
        this.root.mandolin.strings.gotoAndStop(0);
        this.root.guitar.strings.gotoAndStop(0);
        this.root.little_piano.keys.gotoAndStop(0);
        this.root.flute.flu.gotoAndStop(0);
        this.start();
    };

    start = () => {
        this.root.girl.crash.addEventListener(
            "click",
            async (e: MouseEvent) => {
                this.root.girl.gotoAndStop(0);
                this.root.girl.gotoAndPlay(0);
                console.log("frame:", this.root.girl.currentFrame);

                await waitForEvent(this.root, "cymbalsCrash");

                let audio = playSoundSync("crash");
                audio.volume = 0.3;

                await waitForEvent(this.root, "finishCrash");
            }
        );
        // this.root.addEventListener(
        //     "tick",
        //     (event: any) => {
        //      if((e as createjs.TickerEvent).paused) return;
        //      if(!this.crash){
        //         this.root.girl.gotoAndStop(0);
        //         console.log("change frame");

        //      }
        //     }
        // );
        for (let i = 1; i <= 8; i++) {
            this.root.trumpet["key_" + i].addEventListener(
                "mouseover",
                (event: createjs.MouseEvent) => {
                    const key = 9 - i;
                    this.key_sound = playSoundSync("sound" + key, -1);
                }
            );
        }
        for (let i = 1; i <= 8; i++) {
            this.root.trumpet["key_" + i].addEventListener(
                "mouseout",
                (event: createjs.MouseEvent) => {
                    this.key_sound.stop();
                }
            );
        }
        this.startTime = Date.now();
        this.startFlute();
        this.startGuitar();
        this.startTuba();
        this.startPiano();
        this.startMandolin();
        this.startDrums();
        this.addPianoSound(this.root.piano.k1, "CT");
        this.addPianoSound(this.root.piano.k2, "NB");
        this.addPianoSound(this.root.piano.k3, "NA");
        this.addPianoSound(this.root.piano.k4, "NG");
        this.addPianoSound(this.root.piano.k5, "NF");
        this.addPianoSound(this.root.piano.k6, "NE");
        this.addPianoSound(this.root.piano.k7, "ND");
        this.addPianoSound(this.root.piano.k8, "NC");
        this.addPianoSound(this.root.piano.k9, "NBF");
        this.addPianoSound(this.root.piano.k13, "NCS");
        this.addPianoSound(this.root.piano.k11, "NFS");
        this.addPianoSound(this.root.piano.k12, "NEF");
        this.addPianoSound(this.root.piano.k10, "NGS");
    };
    addPianoSound = (key: any, sound: string) => {
        key.addEventListener("mouseover", (e: MouseEvent) => {
            let audio = playSoundSync(sound);
            audio.volume = 0.4;
        });
    };
    syncSound = (sound: createjs.AbstractSoundInstance) => {
        sound.position = (Date.now() - this.startTime) % sound.duration;
    };

    startTuba = () => {
        this.root.tuba.addEventListener("click", (e: MouseEvent) => {
            if (!this.click_tuba) {
                this.root.tuba.tuba.play();
                this.click_tuba = true;
                this.root.tuba.sound = playSoundSync("_bass", -1);
                this.syncSound(this.root.tuba.sound);
            } else {
                this.root.tuba.tuba.stop();
                this.click_tuba = false;
                this.root.tuba.sound.stop();
            }
        });
    };

    startGuitar = () => {
        this.root.guitar.guitar_press.addEventListener(
            "click",
            (e: MouseEvent) => {
                if (!this.click_guitar) {
                    this.root.guitar.strings.gotoAndPlay(0);
                    this.click_guitar = true;
                    this.root.guitar.sound = playSoundSync("_guitar_solo", -1);
                    this.syncSound(this.root.guitar.sound);
                    this.root.guitar.sound1 = playSoundSync("_guitar", -1);
                    this.syncSound(this.root.guitar.sound1);
                } else {
                    this.root.guitar.strings.gotoAndStop(0);
                    this.click_guitar = false;
                    this.root.guitar.sound.stop();
                    this.root.guitar.sound1.stop();
                }
            }
        );
    };
    startDrums = () => {
        this.root.drums.drums_press.addEventListener(
            "click",
            (e: MouseEvent) => {
                if (this.click_drums === false) {
                    this.root.drums.bong.gotoAndPlay(0);
                    this.click_drums = true;
                    this.root.drums.sound = playSoundSync("_bongos", -1);
                    this.syncSound(this.root.drums.sound);
                } else {
                    this.root.drums.bong.gotoAndStop(0);
                    this.click_drums = false;
                    this.root.drums.sound.stop();
                }
            }
        );
    };
    startPiano = () => {
        this.root.little_piano.piano_press.addEventListener(
            "click",
            (e: MouseEvent) => {
                if (this.click_piano === false) {
                    this.root.little_piano.keys.gotoAndPlay(0);
                    this.click_piano = true;
                    this.root.little_piano.sound = playSoundSync("_piano", -1);
                    this.syncSound(this.root.little_piano.sound);
                } else {
                    this.root.little_piano.keys.gotoAndStop(0);
                    this.click_piano = false;
                    this.root.little_piano.sound.stop();
                }
            }
        );
    };
    startFlute = () => {
        this.root.flute.flute_press.addEventListener(
            "click",
            (e: MouseEvent) => {
                if (this.click_flute === false) {
                    this.root.flute.flu.play();
                    this.click_flute = true;
                    this.root.flute.sound = playSoundSync("_flute", -1);
                    this.syncSound(this.root.flute.sound);
                } else {
                    this.root.flute.flu.stop();
                    this.click_flute = false;
                    this.root.flute.sound.stop();
                }
            }
        );
    };

    startMandolin = () => {
        this.root.mandolin.mandolin_press.addEventListener(
            "click",
            (e: MouseEvent) => {
                if (!this.click_mandolin) {
                    this.root.mandolin.strings.gotoAndPlay(0);
                    this.click_mandolin = true;
                    this.root.mandolin.sound = playSoundSync("_banjo", -1);
                    this.syncSound(this.root.mandolin.sound);
                } else {
                    this.root.mandolin.strings.gotoAndStop(0);
                    this.click_mandolin = false;
                    this.root.mandolin.sound.stop();
                }
            }
        );
    };
}
