import { MC, playSound, setupStage } from "../../../animate/utils";

export class TigerGame {
    private root: MC;
    private isMoving: boolean = false;
    private steps: number = 0;
    private playSound: boolean = true;
    constructor(private frame: any) {}

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).tiger = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(19);
        this.start();
    };

    start = () => {
        this.addClickListener();
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;
            if (this.isMoving && this.steps < 25) {
                this.root.tiger.x += 1;
                this.steps += 1;
            }
            if (this.steps === 25) {
                this.isMoving = false;
                this.steps = 0;
            }
        });
    };

    private addClickListener() {
        this.root.btn.addEventListener("click", () => {
            console.log("click");
            if (this.steps !== 0) {
                return;
            }
            this.isMoving = true;
            if (this.playSound) {
                playSound("roar");
            }
            this.playSound = !this.playSound;
        });
    }
}
