import { MC, stopAllSounds, waitFor, waitForEvent, waitForTimer } from "../animate/utils";
import { BibleChannel } from "./BibleChannel";
import { EnglishChannel } from "./EnglishChannel";
import { IslandChannel } from "./IslandChannel";
import { MusicChannel } from "./MusicChannel";
import { Nickoldeon1Channel } from "./Nickoldeon1Channel";
import { Nickoldeon2Channel } from "./Nickoldeon2Channel";
import { VideoPlayer } from "../video/VideoPlayer";
import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { getBibleLockedChannels } from "../api/moviesSection";
import { MutableRefObject } from "react";
import { IChannel } from "./IChannel";
import { loadSound, soundPlayed } from "../animate/sound_utils";
import { SodMayaTracking } from "../navigation/GoogleAnalytics";

const HEAD_MIN = 255;
const HEAD_MAX = 465;

export class MoviesSection {
    root: MC;
    buttonsArr: any[] = [];
    channelNames: string[] = ["nickelodeon", "nickelodeon", "english", "island", "music", "bible"];
    channel: IChannel;

    panelIndex: number = 0;
    panelY: number;

    nextDisabled: boolean = false;
    prevDisabled: boolean = false;
    shouldAnimateIn: boolean = false;
    prevBtn: MC = null;
    prevBtnIndex: number = 0;
    chosenLabel: string;

    disabledBtn: MC;
    lockedChannels: string[] = [];
    messagesShowing: boolean = false;
    clickFirst: boolean = true;
    btnsChecked: number = 0;

    lockedBtns: MC[] = [];
    bibleBtnToPlay: MC = null;
    bibleBtnToPlayIndex: number = 0;

    btnWithLockClicked: MC;

    constructor(
        private currentChannel: number,
        private setVideoSrc: (value: string) => void,
        private videoRef: MutableRefObject<VideoPlayer>
    ) {}

    loaded = (root: MC) => {
        this.root = root;
        (window as any).movies = root;

        this.panelY = this.root.pannel.y;

        for (let i = 0; i < 5; i++) {
            const btn = this.root.pannel[`btn${i + 1}`];
            this.enableBtnEvents(btn, i);
        }

        this.root.btnBack.cursor = "pointer";
        this.root.btnBack.addEventListener("rollover", async () => {
            // this.root.btnBack.mouseChildren = false;
            this.root.btnBack.gotoAndStop("over");
            await soundPlayed((await loadSound("/movies-section/sounds/back.mp3")).play());
        });
        this.root.btnBack.addEventListener("rollout", () => {
            this.root.btnBack.gotoAndStop("up");
        });
        this.root.btnBack.addEventListener("click", () => {
            this.root.btnBack.gotoAndStop("down");
            this.onEndVideo();
            Navigation.openPage(routes.home);
        });

        this.getLockedMovies();
        // currentChannel
        this.root.Icon.gotoAndStop(this.currentChannel);

        // hide lock messages
        this.root.screen_unlock.visible = false;
        this.root.screen_lock.visible = false;

        this.InitChannelSwitchBtn(this.root.btnChannelUp, 0);
        this.InitChannelSwitchBtn(this.root.btnChannelDown, 1);

        this.enablePanelNavigationBtn(this.root.pannel.nextBtn, 1);
        this.prevDisabled = true;

        this.root.preLoaders.gotoAndStop(this.channelNames[this.currentChannel]);

        this.initChosenChannel();
        this.initPlayBar();
    };

    unload = () => {
        if (this.currentChannel === 3) {
            (this.channel as IslandChannel).cleanData();
        }
    };

    getLockedMovies = () => {
        this.lockedChannels = [];
        getBibleLockedChannels().then((res) => {
            this.lockedChannels = res;
        });
    };

    loadBibleLockedChannels = async () => {
        if (this.currentChannel !== 5) return;
        this.bibleBtnToPlay = null;
        this.btnsChecked = 0;
        this.messagesShowing = true;
        this.lockedChannels = [];
        this.lockedChannels = await getBibleLockedChannels();
        await waitFor(() => this.root);
        for (let i = 0; i < 5; i++, this.btnsChecked++) {
            const btn = this.root.pannel[`btn${i + 1}`];
            btn.gotoAndStop("up");
            const channelBlocked = await this.isChannelLocked(i + 1);
            btn.lock.visible = true;
            this.initBtnWithLock(btn, channelBlocked, this.panelIndex + i);
            if (btn === this.btnWithLockClicked) {
                if (channelBlocked) {
                    this.showunLockMessage(this.root.screen_unlock);
                } else {
                    this.clickBtn(btn, i);
                }
            }
        }
    };

    clickChannel = async (index: number) => {
        // await waitForTimer(100);
        if (index === 0) {
            //navigate up
            this.currentChannel === 5 ? (this.currentChannel = 0) : (this.currentChannel += 1);
        } else {
            //navigate down
            this.currentChannel === 0 ? (this.currentChannel = 5) : (this.currentChannel -= 1);
        }

        this.root.Icon.Anim.gotoAndPlay(1);
        await waitForEvent(this.root.Icon.Anim, "animationEnd");
        this.root.Icon.gotoAndStop(this.currentChannel);
        await this.animatePannelOut(this.root.pannel);
        this.shouldAnimateIn = true;
        this.initChosenChannel();
    };

    initChosenChannel = async () => {
        switch (this.currentChannel) {
            case 0:
                this.channel = new Nickoldeon1Channel(this.setVideoSrc);
                break;
            case 1:
                this.channel = new Nickoldeon2Channel(this.setVideoSrc);
                break;
            case 2:
                this.channel = new EnglishChannel(this.setVideoSrc);
                break;
            case 3:
                this.channel = new IslandChannel(this.setVideoSrc);
                break;
            case 4:
                this.channel = new MusicChannel(this.setVideoSrc);
                break;
            case 5:
                this.channel = new BibleChannel(this.setVideoSrc);
                break;
        }
        this.resetMovieBtns();
        this.resetProps();
        this.root.gotoAndStop(0);
        this.root.preLoaders.visible = true;
        this.root.preLoaders.gotoAndStop(this.channelNames[this.currentChannel]); //hide after 1 second
        // await waitForTimer(1000);
        this.hidePanelBtns();
        await waitFor(() => this.btnsChecked === 5);
        if (this.lockedBtns.length === this.channel.getMoviesNum()) {
            this.showunLockMessage(this.root.screen_unlock);
            this.bibleBtnToPlay = null;
        }
        if (this.bibleBtnToPlay) {
            await this.clickBtn(this.bibleBtnToPlay, this.bibleBtnToPlayIndex, true);
            await this.showChannelIcons(0, this.clickFirst, true);
        } else {
            await this.showChannelIcons(0, this.clickFirst);
        }
    };

    resetMovieBtns = () => {
        for (let i = 0; i < 5; i++) {
            const btn = this.root.pannel[`btn${i + 1}`];
            this.disableBtnEvents(btn);
            this.enableBtnEvents(btn, i);
        }
    };

    resetProps = () => {
        this.bibleBtnToPlay = null;
        this.bibleBtnToPlayIndex = 0;
        this.root.screen_lock.visible = false;
        this.root.screen_unlock.visible = false;
        this.panelIndex = 0;
        this.nextDisabled = false;
        this.lockedBtns = [];
        this.disablePanelNavigationBtn(this.root.pannel.nextBtn);
        this.enablePanelNavigationBtn(this.root.pannel.nextBtn, 1);
        this.disablePanelNavigationBtn(this.root.pannel.prevBtn);
        this.prevDisabled = true;
    };

    showChannelIcons = async (beginIndex: number, clickFirst: boolean, bible?: boolean) => {
        this.disableEnablePrevAndNext(false);
        if (this.shouldAnimateIn) {
            await this.animatePannel(this.root.pannel);
            this.shouldAnimateIn = false;
        }
        if (clickFirst) {
            this.clickBtn(this.root.pannel.btn1, 0, true); //default clicking on first button
        }
        for (let i = 0; i < 5; i++, beginIndex++) {
            const btn = this.root.pannel[`btn${i + 1}`];
            const label = this.channel.getIconLabelByIndex(beginIndex + 1);
            if (label === "none") {
                btn.visible = false;
                this.disablePanelNavigationBtn(this.root.pannel.nextBtn);
                this.nextDisabled = true;
            } else {
                if (clickFirst === true && i === 0) {
                    this.chosenLabel = label;
                }
                if (bible && i === this.bibleBtnToPlayIndex) {
                    this.chosenLabel = label;
                }
                if (label === this.chosenLabel) {
                    btn.gotoAndStop("selected");
                    this.disableBtnEvents(btn);
                }
                btn.visible = true;
                btn.btn_icons.gotoAndStop(label);
                if (btn === this.disabledBtn && label !== this.chosenLabel) {
                    this.enableBtnEvents(btn, i);
                }
                await this.animateBtn(btn);
            }
        }
        this.disableEnablePrevAndNext(true);
        if (beginIndex === this.channel.getMoviesNum()) {
            this.disablePanelNavigationBtn(this.root.pannel.nextBtn);
            this.nextDisabled = true;
        }
    };

    showLockMessage = (msg: MC) => {
        msg.visible = true;
        msg.yes_btn.cursor = "pointer";
        msg.no_btn.cursor = "pointer";

        msg.yes_btn.addEventListener("click", () => {
            Navigation.openIframe("/parentsInfo/bibleStories.aspx");
        });
        msg.no_btn.addEventListener("click", () => {
            msg.visible = false;
            this.videoRef.current.pausePlay();
        });
    };

    showunLockMessage = (msg: MC) => {
        // this.root.screen_lock.visible = false;
        console.log("show unlock message");

        this.videoRef.current.pause();

        msg.visible = true;
        msg.pressHere.addEventListener("click", () => {
            Navigation.openIframe("/parentsInfo/bibleStories.aspx");
        });
        if (this.prevBtn && this.lockedBtns.includes(this.prevBtn) === false) {
            this.enableBtnEvents(this.prevBtn, this.prevBtnIndex);
            this.prevBtn.gotoAndStop("down");
        }
    };

    animatePannel = (pannel: MC) => {
        pannel.y += 100;

        return new Promise((resolve) => {
            createjs.Tween.get(pannel)
                .to(
                    {
                        y: this.panelY,
                    },
                    800
                )
                .call(resolve);
        });
    };

    animatePannelOut = (pannel: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(pannel)
                .to(
                    {
                        y: pannel.y + 100,
                    },
                    800
                )
                .call(resolve);
        });
    };

    animateBtn(btn: MC) {
        btn.alpha = 0;
        return new Promise((resolve) => {
            createjs.Tween.get(btn)
                .to(
                    {
                        alpha: 1,
                    },
                    300
                )
                .call(resolve);
        });
    }
    isChannelLocked = async (channelIndex: number) => {
        await waitFor(() => this.lockedChannels.length > 0);
        return this.lockedChannels.includes(channelIndex.toString());
    };

    hidePanelBtns = async () => {
        this.clickFirst = true;
        this.btnsChecked = 0;
        for (let i = 0; i < 5; i++) {
            const btn = this.root.pannel[`btn${i + 1}`];
            btn.gotoAndStop("up");
            btn.visible = false;
            btn.lock.visible = false;
            if (this.channelNames[this.currentChannel] === "bible") {
                const channelBlocked = this.isChannelLocked(i + 1);
                btn.lock.visible = true;
                channelBlocked.then(async (res) => {
                    this.initBtnWithLock(btn, res, this.panelIndex + i);
                });
            } else if (i === 4) {
                this.btnsChecked = 5;
            }
        }
    };

    initBtnWithLock = (btn: MC, res: boolean, movieIndex: number) => {
        // console.log("in initBtnWithLock", btn.name, res);

        if (res) {
            this.lockedBtns.push(btn);
            btn.gotoAndStop("up");
            this.disableBtnEvents(btn); // if channel is locked disable events on it
            console.log("removed because locked", btn.name);

            if (btn.name === "btn1") {
                this.clickFirst = false;
            }
        } else {
            if (this.bibleBtnToPlay === null) {
                this.bibleBtnToPlay = btn;
                this.bibleBtnToPlayIndex = movieIndex;
            }
            this.enableBtnEvents(btn, movieIndex);
        }
        btn.lock.gotoAndStop(res ? "Locked" : "unLocked");
        btn.lock.btnLock.visible = true;
        this.btnsChecked++;
        btn.lock.addEventListener("click", (e: MouseEvent) => {
            e.stopPropagation();
            this.btnWithLockClicked = btn;
            if (this.messagesShowing) {
                this.hideLockMessages();
            }
            this.messagesShowing = true;
            this.videoRef.current.pause();
            res ? this.showunLockMessage(this.root.screen_unlock) : this.showLockMessage(this.root.screen_lock);
        });
    };

    hideLockMessages = () => {
        this.root.screen_unlock.visible = false;
        this.root.screen_unlock.removeAllEventListeners();
        this.root.screen_lock.visible = false;
        this.root.screen_lock.removeAllEventListeners();
        this.messagesShowing = false;
    };

    panelForward = async () => {
        this.panelIndex += 5;
        this.hidePanelBtns();
        await waitFor(() => this.btnsChecked === 5);
        this.disableEnablePrevAndNext(false);
        await this.showChannelIcons(this.panelIndex, false);
        this.disableEnablePrevAndNext(true);

        if (this.prevDisabled) {
            this.enablePanelNavigationBtn(this.root.pannel.prevBtn, 0);
            this.prevDisabled = false;
        }
    };

    panelBackward = async () => {
        this.panelIndex -= 5;

        this.hidePanelBtns();
        await waitFor(() => this.btnsChecked === 5);

        this.disableEnablePrevAndNext(false);
        await this.showChannelIcons(this.panelIndex, false);
        this.disableEnablePrevAndNext(true);

        if (this.panelIndex === 0) {
            this.disablePanelNavigationBtn(this.root.pannel.prevBtn);
            this.prevDisabled = true;
        }
        if (this.nextDisabled) {
            this.enablePanelNavigationBtn(this.root.pannel.nextBtn, 1);
            this.nextDisabled = false;
        }
    };

    disableEnablePrevAndNext = (enable: boolean) => {
        this.root.pannel.nextBtn.mouseEnabled = enable;
        this.root.pannel.prevBtn.mouseEnabled = enable;
    };

    InitChannelSwitchBtn = (btn: MC, index: number) => {
        btn.cursor = "pointer";
        btn.addEventListener("rollover", () => {
            btn.gotoAndStop("over");
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("up");
        });
        btn.addEventListener("click", () => {
            stopAllSounds();
            this.videoRef.current.pause();
            btn.gotoAndStop("down");
            this.clickChannel(index);
        });
    };

    enablePanelNavigationBtn = (btn: MC, i: number) => {
        btn.cursor = "pointer";
        btn.alpha = 1;
        btn.addEventListener("rollover", () => {
            btn.gotoAndStop("over");
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("up");
        });
        btn.addEventListener("click", async () => {
            btn.gotoAndStop("down");
            i === 0 ? await this.panelBackward() : await this.panelForward();
        });
    };

    loadMovie = (index: number) => {
        //this is the function which is promoted on click
        if (this.videoRef.current) {
            this.root.preLoaders.visible = false;
            this.channel.setMovieByIndex(index);
            this.videoRef.current.play();
            SodMayaTracking(`${routes.movies}Channel${this.currentChannel + 1}/Movie${index + 1}`);
        }
    };

    clickBtn = async (btn: MC, movieIndex: number, def?: boolean) => {
        this.onNextVideo();
        this.root.gotoAndStop(0);
        if (this.prevBtn !== null && this.lockedBtns.includes(this.prevBtn) === false) {
            this.enableBtnEvents(this.prevBtn, this.prevBtnIndex);
        }
        if (this.messagesShowing) {
            this.hideLockMessages();
        }
        this.prevBtn = btn;
        this.prevBtnIndex = movieIndex;
        this.disableBtnEvents(btn);
        this.root.preLoaders.gotoAndStop(this.channelNames[this.currentChannel]); //hide after 1 second
        await waitForTimer(100);
        this.root.preLoaders.visible = true;
        if (def !== true) {
            this.chosenLabel = btn.btn_icons.currentLabel;
        }
        await waitForTimer(200);
        btn.gotoAndStop("selected");
        this.loadMovie(movieIndex + this.panelIndex);
        const waitUntilReady = window.setInterval(() => {
            if (this.videoRef.current?.getVideoState()) {
                this.updateVideoPosition();
                this.root.gotoAndStop("videoTime");
                window.clearInterval(waitUntilReady);
            }
        }, 100);
    };

    disablePanelNavigationBtn = (btn: MC) => {
        btn.alpha = 0.5;
        btn.removeAllEventListeners();
        btn.cursor = "default";
    };

    enableBtnEvents = (btn: MC, movieIndex: number) => {
        console.log("enabled", btn.name);

        btn.cursor = "pointer";
        btn.gotoAndStop("up");
        btn.addEventListener("rollover", () => {
            btn.gotoAndStop("over");
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("up");
        });
        btn.addEventListener("click", () => {
            this.clickBtn(btn, movieIndex, false);
        });
    };

    disableBtnEvents = (btn: MC) => {
        btn.removeAllEventListeners();
        if (this.lockedBtns.includes(btn) === false) this.disabledBtn = btn;
    };

    initPlayBar = () => {
        const playBtn = this.root.btnPlay;
        const pauseBtn = this.root.btnPause;

        playBtn.visible = false;
        playBtn.cursor = "pointer";
        playBtn.addEventListener("click", () => {
            this.videoRef.current.play();
        });
        playBtn.addEventListener("rollover", () => {
            playBtn.gotoAndStop("over");
        });
        playBtn.addEventListener("rollout", () => {
            playBtn.gotoAndStop("up");
        });

        pauseBtn.visible = true;
        pauseBtn.cursor = "pointer";
        pauseBtn.addEventListener("rollover", () => {
            pauseBtn.gotoAndStop("over");
        });
        pauseBtn.addEventListener("rollout", () => {
            pauseBtn.gotoAndStop("up");
        });
        pauseBtn.addEventListener("click", () => {
            pauseBtn.gotoAndStop("down");
            this.videoRef.current.pause();
        });

        const muteBtn = this.root.muteBtn;
        muteBtn.cursor = "pointer";
        muteBtn.addEventListener("click", () => {
            this.videoRef.current.muteVideo();
            const state = muteBtn.currentFrame;
            muteBtn.gotoAndStop(!state);
        });
        muteBtn.addEventListener("rollover", () => {
            muteBtn.btnSound.gotoAndStop("over");
            muteBtn.btnMute.gotoAndStop("over");
        });
        muteBtn.addEventListener("rollout", () => {
            muteBtn.btnSound.gotoAndStop("up");
            muteBtn.btnMute.gotoAndStop("up");
        });

        const fullScreenBtn = this.root.btnScreenMode;
        fullScreenBtn.gotoAndStop("regular");
        fullScreenBtn.cursor = "pointer";
        fullScreenBtn.addEventListener("click", () => {
            if (!document.fullscreenElement) {
                document
                    .querySelector("#root")
                    .requestFullscreen()
                    .catch((err) => {
                        console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                    });
            } else {
                document.exitFullscreen().catch((err) => {
                    console.log(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`);
                });
            }
        });
        fullScreenBtn.addEventListener("rollover", () => {
            fullScreenBtn.gotoAndStop("full");
        });
        fullScreenBtn.addEventListener("rollout", () => {
            fullScreenBtn.gotoAndStop("regular");
        });

        this.root.head.cursor = "pointer";
        this.root.head.mouseChildren = false;

        this.root.head.addEventListener("mousedown", () => {
            const startX = this.root.head.x;
            const startMouseX = this.root.stage.mouseX;

            const wasPlaying = this.root.btnPause.visible;

            if (wasPlaying) {
                this.videoRef.current.pause();
            }

            const onMove = () => {
                const mouseDelta = (this.root.stage.mouseX - startMouseX) / this.root.stage.scale;
                const newX = Math.min(Math.max(startX + mouseDelta, HEAD_MIN), HEAD_MAX);
                this.root.head.x = newX;

                this.videoRef.current.setCurrentTime(
                    ((newX - HEAD_MIN) / (HEAD_MAX - HEAD_MIN)) * this.videoRef.current.getVideoDuration()
                );
            };

            const onUp = () => {
                this.root.head.removeEventListener("pressmove", onMove);
                this.root.head.removeEventListener("pressup", onUp);
                if (wasPlaying) {
                    this.videoRef.current.play();
                }
            };
            this.root.head.addEventListener("pressmove", onMove);
            this.root.head.addEventListener("pressup", onUp);
        });
    };

    formatTime = (timeInSeconds?: number) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds - minutes * 60);
        return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    };

    updateVideoPosition = () => {
        const currentTime = this.videoRef.current.getCurrentTime();
        const videoDuration = this.videoRef.current.getVideoDuration();
        if (!isNaN(videoDuration)) {
            this.root.Time.TimeTxt.text = `${this.formatTime(currentTime)} / ${this.formatTime(videoDuration)}`;
            this.root.head.x = HEAD_MIN + (HEAD_MAX - HEAD_MIN) * (currentTime / videoDuration);
        }
    };

    setIsPlaying = (playing: boolean) => {
        this.root.btnPause.visible = playing;
        this.root.btnPlay.visible = !playing;
    };

    onEndVideo = () => {
        this.root.btnPause.visible = false;
        this.root.btnPlay.visible = true;
    };

    onNextVideo = () => {
        this.root.Time.TimeTxt.text = "00:00 / --:--";
        this.root.btnPause.visible = true;
        this.root.btnPlay.visible = false;
    };
}
