import { MC, waitFor } from "../../../animate/utils";
import { Penguin } from "./Penguin";

export class PenguinGame {
    printVersion: any;
    constructor(private sectionIndex: any, private setShouldPrint: (val: boolean) => void) {}
    private penguin: Penguin = null;

    penguinLoaded = (mc: createjs.MovieClip, lettersContext: any) => {
        this.penguin = new Penguin(mc, this.sectionIndex, lettersContext, this.setShouldPrint);
    };

    prepareForPrint = async (mc: createjs.MovieClip) => {
        // debugger
        (window as any).printVersion = mc;
        this.printVersion = mc;
        this.printVersion.scale = 0.7;
        await waitFor(() => this.printVersion.stage);
        this.printVersion.children.forEach((c: MC) => {
            c.visible = false;
        });
        this.printVersion.words.visible = true;
        const words = this.penguin.wordsArr;
        for (let i = 0; i < 5; i++) {
            const word = this.printVersion.words[`w${i + 1}`];
            word.visible = true;
            const text = new createjs.Text(words[i], "bold 45px 'ProntoMF' ", this.penguin.textColors[i]);
            text.textAlign = "center";
            word.addChild(text);
            this.printVersion.words[`pic${i + 1}`].gotoAndStop(this.penguin.picsArr[i]);
        }
        this.printVersion.stage.update();
        window.print();
        this.setShouldPrint(false);
    };

    unload = () => {};
}
