import { MC, setupStage } from "../../../animate/utils";

export class NumberThreeGame {
    private root: MC;
    private counter = 3;

    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).ThreeGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.gotoAndStop(2);
        this.root.needle.gotoAndStop(0);
        this.root.text_1.text = "3";

        this.root.addEventListener("tick", (e: any) => {
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.root.needle.x = localMouse.x;
            this.root.needle.y = localMouse.y;
        });

        this.root.children.forEach((bal: MC) => {
            if (bal.bal) {
                bal.gotoAndStop(0);
                bal.bal.addEventListener("click", (e: any) => {
                    if (!bal.pop) {
                        bal.pop = true;
                        this.root.needle.gotoAndPlay(0);
                        bal.gotoAndPlay(0);
                        this.counter -= 1;
                        this.root.text_1.text = this.counter;
                        if (this.counter === 0) {
                            this.frame.root.Bar.againbut.visible = true;
                        }
                    }
                });
            }
        });
    };
}
