import { MC, playSound } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game12 extends BaseGame {
    heartIndex = 0;
    game: any;
    gameEnd: any;
    helpBtn: any;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.game = game;
        this.gameEnd = gameEnd;
        this.helpBtn = helpBtn;
        game.x = 700;
        game.y = 450;
        game.visible = true;
        game.heartsMc.gotoAndStop(0);
        await playSound(`game12_1`);
        if (!this.shouldPlay) return;
        this.play();
    };
    play = async () => {
        const game = this.game;
        game.heartsMc.gotoAndStop(0);
        game.gotoAndStop(0);
        this.gameEnd();
        this.helpBtn(false);
        game.addBtn.cursor = "pointer";
        game.removeBtn.cursor = "pointer";
        game.addBtn.addEventListener("click", async () => {
            if (this.heartIndex < 10) {
                this.heartIndex++;
                game.heartsMc.gotoAndStop(this.heartIndex);
                game.heartsMc[`heart${this.heartIndex}`].gotoAndPlay(0);
            }
            playSound("hearts");
        });
        game.removeBtn.addEventListener("click", async () => {
            this.heartIndex > 0 && this.heartIndex--;
            if (this.heartIndex >= 0) {
                game.heartsMc.gotoAndStop(this.heartIndex);
                playSound("delete");
            }
        });
    };
    reloadGame = () => {
        this.game.gotoAndStop(0);
        this.heartIndex = 0;
        this.game.addBtn.removeAllEventListeners();
        this.game.removeBtn.removeAllEventListeners();
        this.helpBtn(true);
        this.play();
    };
}
