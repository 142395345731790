import { MC } from "../animate/utils";
import {
    TEXT_GRID_SPACING,
    BOTTOM_BOARD_LIMIT,
    RIGHT_BOARD_LIMIT,
    TEXT_INIT_SCALE,
    LEFT_BOARD_LIMIT,
    TOP_BOARD_LIMIT,
    DANGLE_Y,
    CHARACTERS,
    LETTERS,
    DIGITS,
} from "./utils/constants";
import { CreationGame } from "./CreationGame";
import { characters, Codes, map } from "./utils/KeyboardConstants";
import * as TextFunctions from "./utils/TextFunctions";

export class KeyboardInput {
    private inputField: MC;
    private lineInput: createjs.Shape;
    private isActive: boolean = false;
    private place: createjs.Point;
    private showLine: boolean = true;
    private g = new createjs.Shape();
    private shift_entered: boolean = false;

    constructor(private root: MC, private creationGame: CreationGame) {
        this.addKeyboardEvent();
        this.repositionEvent();
        this.initLineInputField(RIGHT_BOARD_LIMIT, BOTTOM_BOARD_LIMIT - 52 + DANGLE_Y);
        root.addChild(this.g);
    }

    activate = (showLineInput: boolean = true) => {
        this.showLine = showLineInput;
        this.generateInputField(RIGHT_BOARD_LIMIT, BOTTOM_BOARD_LIMIT - DANGLE_Y, showLineInput);
        this.isActive = true;
    };

    deActivate = () => {
        if (this.isActive) {
            this.isActive = false;
            this.toogleLineInputField(false);
            if (this.inputField.text) {
                this.createStaticInput();
            } else {
                this.root.removeChild(this.inputField);
            }
        }
    };

    deleteCharacters = () => {
        if (this.isActive) {
            const lastPlace = this.inputField.x + this.getWidth();
            this.inputField.text = this.inputField.text.slice(0, -1);
            this.inputField.x = lastPlace - this.getWidth();
            this.lineInput.x = this.inputField.x;
            this.place.x = lastPlace;
        }
    };

    generateInputField = (x: number, y: number, showLineInput: boolean = true) => {
        this.inputField = new createjs.Text("", "bold 32px NarkisTamMFO", "#2E5F64");
        this.inputField.textBaseline = "alphabetic";
        this.inputField.x = x;
        this.inputField.y = this.creationGame.applyGridY(y);
        this.inputField.scaleX = TEXT_INIT_SCALE;
        this.inputField.scaleY = TEXT_INIT_SCALE;
        this.place = new createjs.Point(x, y);
        this.root.addChild(this.inputField);
        if (showLineInput) {
            this.moveLineInput(this.inputField.x, this.inputField.y - this.getHeight());
            this.toogleLineInputField(true);
        }
    };

    initLineInputField = (x: number, y: number) => {
        this.lineInput = new createjs.Shape();
        this.lineInput.graphics.beginFill("#000000").drawRect(0, -8, 1.4, 60);
        this.moveLineInput(x, y);
        this.place = new createjs.Point(x, y);
        this.root.addChild(this.lineInput);
        this.toogleLineInputField(false);
        setInterval(() => {
            if (!this.isActive || !this.showLine) return;
            this.lineInput.visible = !this.lineInput.visible;
        }, 400);
    };

    moveLineInput = (x: number, y: number) => {
        this.lineInput.x = x;
        this.lineInput.y = y;
    };

    addKeyboardEvent = () => {
        document.addEventListener("keydown", (e: any) => {
            if (!this.isActive) return;
            if (e.key === "Shift") {
                this.shift_entered = true;
                return;
            }
            if (!characters.includes(e.key)) {
                this.onInputChange(e);
                //   this.debugXY(this.inputField);
            }
            if (e.key === "Backspace") {
                this.deleteCharacters();
            }
        });
    };

    repositionEvent = () => {
        this.root.theWork.addEventListener("mousedown", (event: createjs.MouseEvent) => {
            if (!this.isActive) return;
            this.showLine = true;
            let position = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            if (this.onPreviousInput(position)) {
                return;
            }
            if (position.y - this.getHeight() < TOP_BOARD_LIMIT) {
                position.y = TOP_BOARD_LIMIT + this.getHeight();
            }
            if (position.y + DANGLE_Y > BOTTOM_BOARD_LIMIT) {
                position.y = BOTTOM_BOARD_LIMIT - DANGLE_Y;
            }
            if (this.inputField.text) {
                this.createStaticInput();
            } else {
                this.root.removeChild(this.inputField);
            }

            position.y = Math.round(position.y / TEXT_GRID_SPACING) * TEXT_GRID_SPACING;
            this.generateInputField(position.x, position.y - DANGLE_Y);
        });
    };

    onInputChange = (e: KeyboardEvent) => {
        const key = e.key;
        const code = e.code;
        if (this.getWidth(key) + this.getWidth() >= RIGHT_BOARD_LIMIT - LEFT_BOARD_LIMIT - 3) return;
        if (Codes.includes(code)) {
            this.inputField.text += key;
        } else if (map[key.toLowerCase()] && !this.shift_entered) {
            this.inputField.text += map[key.toLowerCase()];
        } else if (this.isLegalKey(key)) {
            this.inputField.text += key;
        } else {
            return;
        }
        if (this.inputField.x - this.getWidth(key) < LEFT_BOARD_LIMIT) {
            this.inputField.x = LEFT_BOARD_LIMIT + 2;
            this.lineInput.x = this.inputField.x - 3;
        } else {
            this.inputField.x = this.place.x - this.getWidth();
            this.lineInput.x = this.inputField.x - 3;
        }
        this.shift_entered && (this.shift_entered = false);
    };

    toogleLineInputField = (val: boolean) => {
        this.lineInput.visible = val;
    };

    // debugXY = (text: createjs.Text) => {
    //     this.g.graphics.clear();
    //     this.g.graphics.f("red").drawRect(text.x, text.y, 10, 10);
    // };

    getWidth = (text?: string) => {
        if (text) {
            const newText = new createjs.Text(text, "bold 32px NarkisTamMFO");
            return newText.getMeasuredWidth() * TEXT_INIT_SCALE;
        }

        return this.inputField.getMeasuredWidth() * TEXT_INIT_SCALE;
    };

    createStaticInput = () => {
        this.creationGame.addItemToBoard(this.inputField, 4);
        TextFunctions.initStaticInput(
            this.inputField,
            this.inputField.x + this.getWidth(),
            this.inputField.y,
            TEXT_INIT_SCALE,
            this.creationGame
        );
    };

    getHeight = () => {
        return (this.inputField.getMeasuredHeight() - DANGLE_Y) * TEXT_INIT_SCALE;
    };

    private isLegalKey(key: string) {
        return Object.keys(CHARACTERS).includes(key) || LETTERS.includes(key) || DIGITS.includes(key);
    }

    private onPreviousInput(position: any) {
        const newInputBottom = position.y - DANGLE_Y;
        return (
            this.inputField.x < position.x &&
            this.inputField.x + this.getWidth() > position.x &&
            ((this.inputField.y + DANGLE_Y > newInputBottom &&
                this.inputField.y + DANGLE_Y - this.getHeight() < newInputBottom) ||
                (this.inputField.y + DANGLE_Y > newInputBottom - this.getHeight() &&
                    this.inputField.y + DANGLE_Y < newInputBottom))
        );
    }
}
