import React, { useState } from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { QuizGame } from "./QuizGame";
import { quizSounds } from "./quizSounds";
import kitesSounds from "../kites/sounds_kites_game.json";
import boxesGameSounds from "../../review/Boxes/sounds_boxesGame.json";
import fishesGameSounds from "../../review/Fishes/sounds_fishesGame.json";
import { lettersSounds } from "../../common/lettersSounds";
import { objectsSounds } from "../../common/objectsSounds";
import { commomSounds } from "../../common/commonSounds";
import { useParams, useRouteMatch } from "react-router-dom";
import { useLettersContext } from "../../../context/LettersContext";

export default function QuizComponent() {
    const { sectionIndex } = useParams<{ sectionIndex: string }>();
    const index = parseInt(sectionIndex);
    const numberOfQuestion: number = index < 3 ? 6 : 4;
    const lettersContext = useLettersContext();
    const match = useRouteMatch();
    const [currentGame, setCurrentGame] = useState("");

    const game = React.useRef(new QuizGame(match, index, numberOfQuestion, setCurrentGame));

    const renderGames = () => {
        switch (currentGame) {
            case "kites":
                return (
                    <AnimateCC
                        key="kites"
                        base="/letters_island/kites/"
                        animationName="kites"
                        onLoaded={game.current.kiteLoaded}
                        additionalSounds={[...kitesSounds, ...lettersSounds, ...commomSounds]}
                    />
                );
            case "boxes":
                return (
                    <AnimateCC
                        key="boxes"
                        base="/letters_island/boxes/"
                        animationName="boxes"
                        onLoaded={game.current.boxLoaded}
                        additionalSounds={[...boxesGameSounds, ...commomSounds]}
                    />
                );
            case "fishes":
                return (
                    <AnimateCC
                        key="fishes"
                        base="/letters_island/fishes/"
                        animationName="fishes"
                        onLoaded={game.current.fishesLoaded}
                        additionalSounds={[...fishesGameSounds, ...lettersSounds, ...objectsSounds, ...commomSounds]}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {renderGames()}
            <AnimateCC
                base="/letters_island/quiz/"
                animationName="Quiz"
                onLoaded={(mc: MC) => game.current.quizLoaded(mc, lettersContext)}
                additionalSounds={quizSounds}
            />
        </>
    );
}
