import { MC, playSound, setupStage, waitForEvent } from "../../../animate/utils";

export class SunGame {
    private root: MC;
    private sun: boolean = true;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;

        (window as any).sun = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.scale = 0.8;
        this.root.x = 80;
        this.root.y = 80;
        this.root.btn.addEventListener("click", async () => {
            if (this.sun) {
                this.sun = false;
                this.root.gotoAndPlay("moon");

                await waitForEvent(this.root, "stopSun");
                this.root.gotoAndStop("sun");
            } else {
                this.sun = true;
                this.root.gotoAndPlay("sun");
                await waitForEvent(this.root, "play");
                playSound("bird");
                await waitForEvent(this.root, "stopMoon");
                this.root.gotoAndStop("moon");
            }
        });
    };
}
