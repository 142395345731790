import { CancelablePromise } from "../utils/CancelablePromise";

export async function loadSound(url: string): Promise<createjs.AbstractSoundInstance> {
    const ready: any = createjs.Sound.registerSound(url, url, 1);
    if (ready === true) {
        return createjs.Sound.createInstance(url);
    }

    return new Promise((resolve) => {
        const onReady = (e: any) => {
            if (e.src === url) {
                createjs.Sound.removeEventListener("fileload", onReady);
                resolve(createjs.Sound.createInstance(url));
            }
        };

        createjs.Sound.addEventListener("fileload", onReady);
    });
}

export async function soundPlayed(sound: createjs.AbstractSoundInstance) {
    if (!sound) return;

    let reject: (reason?: any) => void;
    let onComplete: () => void;

    const promise = new Promise<void>((resolve, r) => {
        reject = r;
        onComplete = () => {
            sound.removeEventListener("complete", onComplete);
            resolve();
        };
        sound.addEventListener("complete", onComplete);
    });

    (promise as CancelablePromise<any>).cancel = () => {
        sound.stop();
        sound.removeEventListener("complete", onComplete);
        reject("canceled");
    };

    return promise;
}
