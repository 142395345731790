import React from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { MirrorsGame } from "./MirrorsGame";
import additionalSounds from "./MirrorsGameSounds";
const MirrorsComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new MirrorsGame(frame));

    return (
        <AnimateCC
            base="/lunapark/mirrors"
            animationName="mirrors"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default MirrorsComponent;
