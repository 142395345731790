import { MC, setupStage, optimize } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

export class HeartGame {
    private root: MC;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).Heart = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.hand.visible = false;
        this.root.addEventListener("tick", () => {
            this.newHeart();
        });
    };
    newHeart = () => {
        const newHeart = new this.root.lib.hand_copy();
        newHeart.gotoAndStop(getRandomNumber(1, 6));
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        this.root.addChild(newHeart);
        newHeart.x = localMouse.x;
        newHeart.y = localMouse.y;
        newHeart.scale = getRandomNumber(50, 150) / 100;
        newHeart.rotation = getRandomNumber(1, 360);
        newHeart.tickEnabled = true;
        newHeart.paused = true;
        newHeart.mouseChildren = false;
        optimize(newHeart);
    };
}
