import { MC, waitForEvent, playSound } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game20 extends BaseGame {
    helpBtn: any;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.helpBtn = helpBtn;
        game.visible = true;
        game.gotoAndPlay(1);
        game.mouseChildren = false;
        await waitForEvent(game, "questionEnd");
        if (!this.shouldPlay) return;
        helpBtn(false);
        for (let i = 0; i < 3; i++) {
            this.addEventToBtn(game, gameEnd, game[`btn_${i}`]);
        }
        game.mouseChildren = true;
    };
    addEventToBtn = (game: MC, gameEnd: () => void, currentBtn: MC) => {
        currentBtn.gotoAndStop(0);
        currentBtn.cursor = "pointer";
        currentBtn.addEventListener("click", async () => {
            currentBtn.gotoAndStop(0);
            currentBtn.gotoAndStop(1);
            await this.checkIfCorrectAnswer(game, gameEnd, currentBtn);
            if (!this.shouldPlay) return;
        });
        currentBtn.addEventListener("rollover", async () => {
            currentBtn.gotoAndStop(1);
        });
        currentBtn.addEventListener("rollout", async () => {
            currentBtn.gotoAndStop(0);
        });
    };
    checkIfCorrectAnswer = async (game: MC, gameEnd: () => void, currentBtn: MC) => {
        if (currentBtn.name === "btn_1") {
            this.helpBtn(true);
            game.gotoAndPlay(104);
            await waitForEvent(game, "gameEnd");
            if (!this.shouldPlay) return;
            gameEnd();
        } else {
            await playSound("wrong");
            if (!this.shouldPlay) return;
        }
    };
}
