import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import {
    getBeginLetter,
    getLastLetterIndex,
    getLetter,
    MC,
    playSound,
    stopAllSounds,
    waitForEvent,
} from "../../../animate/utils";
import { routes } from "../../../navigation/routesPath";
import { getRandomNumber } from "../../../utils/MathUtils";
import { isEndLetter } from "../LettersUtils";
import { findSentencesToLetter } from "./findSentencesToLetter";
import { getImage } from "./findSentencesToLetter";

const TEXT_WIDTH = 425;
const COLOR_NORMAL = "#0000FF";
const COLOR_OVER = "#FFFFFF";
const COLOR_CLICK = "#00CC00";
const SOUND_TYPE = new Promise<createjs.AbstractSoundInstance>(() => {});

export class FindLetters {
    lastTime: number = 0;
    root: MC;
    letter: number;
    sentences: any[] = [];
    image: any;
    enable: boolean = true;
    lastClicked: number = 0;
    correctAnswers: number = 0;
    arr: createjs.Text[] = [];
    enableHelp: (enableOnce?: boolean, specialSound?: any) => void;
    enableNext: (path: string) => void;
    disableNext: () => void;
    disableHelp: () => void;
    pausePlay: () => void;
    restartVideo: () => void;
    lettersContext: any;
    correctPressed: boolean = false;
    stopInst: boolean = false;
    sounds = {
        intro: SOUND_TYPE,
        text: SOUND_TYPE,
        help: SOUND_TYPE,
        more: SOUND_TYPE,
        wrong: SOUND_TYPE,
        good: SOUND_TYPE,
        letter: SOUND_TYPE,
        f_letter: SOUND_TYPE,
    };
    playing: boolean = false;
    baseIntroUrl = "/letters_island/find_letters/sounds/introletter/introletter";
    constructor(
        root: MC,
        letter: number,
        enableHelp: (enableOnce?: boolean, specialSound?: any) => void,
        disableHelp: () => void,
        enableNext: (path: string) => void,
        disableNext: () => void,
        pausePlay: () => void,
        restartVideo: () => void,
        lettersContext: any
    ) {
        this.root = root;
        this.letter = letter;
        this.enableHelp = enableHelp;
        this.disableHelp = disableHelp;
        this.enableNext = enableNext;
        this.disableNext = disableNext;
        this.pausePlay = pausePlay;
        this.restartVideo = restartVideo;
        this.lettersContext = lettersContext;
        (window as any).find = this.root;
        this.loaded();
    }

    findLetterInsentence() {
        const sentenceArray: any[] = this.sentences;
        for (let i = 0; i < sentenceArray.length; i++) {
            const sentence = sentenceArray[i];
            if (sentence) {
                const arr: createjs.Text[] = [];
                let totalWidth: number = 0;
                for (let j = 0; j < sentence.length; j++) {
                    const letter = sentence.charAt(j);
                    const text = new createjs.Text(letter, "35px 'ProntoMF'", COLOR_NORMAL);
                    this.arr.push(text);
                    arr.push(text);
                    this.root[`text${i + 1}`].addChild(text);
                    totalWidth += text.getMeasuredWidth() / 1.1;
                }

                let x = TEXT_WIDTH - totalWidth;
                // eslint-disable-next-line
                arr.reverse().forEach((text: any) => {
                    text.x = x;
                    x += text.getMeasuredWidth() / 1.1;

                    const hit = new createjs.Shape();
                    hit.graphics.beginFill("#000").drawRect(0, 0, text.getMeasuredWidth(), text.getMeasuredHeight());
                    text.hitArea = hit;
                });
            }
        }
    }

    checkLetter = async (text: MC) => {
        if (text.text === getLetter(this.letter) || getBeginLetter(text.text) === getLetter(this.letter)) {
            if (text.color === COLOR_NORMAL || text.color === COLOR_OVER) {
                text.color = COLOR_CLICK;
                soundPlayed((await this.sounds.good).play());
                return this.starsAnimation(text);
            } else return new Promise<void>((resolve) => resolve());
        } else return new Promise<void>((resolve) => resolve());
    };

    removeListeners = () => {
        stopAllSounds();
        this.stopInst = true;
        for (let i = 0; i < this.arr.length; i++) {
            const text = this.arr[i];
            text.removeAllEventListeners();
            text.cursor = "default";
            if (text.color === COLOR_OVER) {
                text.color = COLOR_NORMAL;
            }
        }
    };
    endGame = async () => {
        for (let i = 0; i < this.arr.length; i++) {
            const text = this.arr[i];
            await this.checkLetter(text);
        }
        if (this.playing) stopAllSounds();

        for (let i = 0; i < this.sentences.length; i++) {
            createjs.Tween.get(this.root[`text${i + 1}`]).to(
                {
                    alpha: 0,
                },
                1000
            );
        }

        createjs.Tween.get(this.image)
            .to(
                {
                    alpha: 0,
                },
                1000
            )
            .call((e) => {
                this.disableNext();
                this.disableHelp();
                this.root.gotoAndPlay(15);
                waitForEvent(this.root, "videoEnd").then(() => {
                    console.log(this.root.children[1].name, this.root.children[1]);
                    this.root.gotoAndStop(27);
                    this.root.children[1].mask = null;
                    this.lettersContext.setLetterStatus(this.letter + 1, "video");
                });
                this.pausePlay();
            });
    };

    addListeners = () => {
        this.playing = true;
        this.arr.forEach((text) => {
            text.addEventListener("click", async () => {
                if (this.enable) {
                    await this.clickLetter(text);
                }
            });
            text.addEventListener("rollover", () => {
                if (this.enable) {
                    text.color = COLOR_OVER;
                    text.cursor = "pointer";
                }
            });
            text.addEventListener("rollout", () => {
                if (this.enable) {
                    text.color = COLOR_NORMAL;
                }
            });
        });
        this.root.videoPlayer.btnPlay.cursor = "pointer";
        this.root.videoPlayer.btnPlay.gotoAndStop(1);

        this.root.videoPlayer.btnPlay.addEventListener("click", () => {
            const state = this.root.videoPlayer.btnPlay.currentFrame;
            this.enableNext(
                isEndLetter(this.letter + 1)
                    ? routes.letters.activities.final_draw
                    : routes.letters.activities.enter_exit.replace(":action", "exit")
            );
            this.root.videoPlayer.btnPlay.gotoAndStop(state + 1);
            this.pausePlay();
        });

        this.root.videoPlayer.btnRewind.cursor = "pointer";
        this.root.videoPlayer.btnRewind.addEventListener("click", () => {
            this.root.videoPlayer.btnPlay.gotoAndStop(2);
            this.enableNext(
                isEndLetter(this.letter + 1)
                    ? routes.letters.activities.final_draw
                    : routes.letters.activities.enter_exit.replace(":action", "exit")
            );
            this.restartVideo();
        });
    };

    clickLetter = async (letterPressed: any) => {
        this.disableHelp();
        this.enable = false;
        stopAllSounds();
        this.stopInst = true;
        if (
            letterPressed.text === getLetter(this.letter) ||
            getBeginLetter(letterPressed.text) === getLetter(this.letter)
        ) {
            this.correctPressed = true;
            this.correctAnswers++;
            letterPressed.removeAllEventListeners();
            letterPressed.color = COLOR_CLICK;
            this.starsAnimation(letterPressed);
            this.root.stars.gotoAndPlay(0);
            const num = getRandomNumber(1, 7);
            await playSound(`m_good${num}`);
            if (letterPressed.text === getLetter(this.letter)) {
                await soundPlayed((await this.sounds.letter).play());
            } else {
                await soundPlayed((await this.sounds.f_letter).play());
            }
            if (this.correctAnswers === 3) {
                stopAllSounds();
                this.removeListeners();
                this.endGame();
            } else {
                this.enable = true;
                this.stopInst = false;
                this.enableHelp(
                    true,
                    `/letters_island/find_letters/sounds/helpletter/Helpletter${this.letter + 1}.mp3`
                );
            }
        } else {
            this.enable = true;
            await soundPlayed((await this.sounds.wrong).play());
            this.enableHelp(true, `/letters_island/find_letters/sounds/helpletter/Helpletter${this.letter + 1}.mp3`);
        }
        this.root.text1.mask = null;
    };
    help = async () => {
        this.disableHelp();
        this.lastClicked = createjs.Ticker.getTime(true);
        await playSound("help");
    };

    starsAnimation = async (letterPressed: any) => {
        this.root.stars.visible = true;
        let letter;
        if (
            letterPressed.text === getLetter(this.letter) ||
            letter === getLetter(this.letter) ||
            getBeginLetter(letterPressed.text) === getLetter(this.letter)
        ) {
            const point = {
                x: letterPressed.parent.x + letterPressed.x + 10,
                y: letterPressed.parent.y + letterPressed.y + 20,
            };
            this.root.stars.x = point.x;
            this.root.stars.y = point.y;
            this.root.stars.gotoAndPlay(0);
            return waitForEvent(this.root.stars, "finishStar").then(() => {
                this.root.stars.visible = false;
            });
        }
    };
    loadSounds = () => {
        this.sounds.intro = loadSound(
            `/letters_island/find_letters/sounds/introletter/introletter${this.letter + 1}.mp3`
        );
        this.sounds.text = loadSound(`/letters_island/find_letters/sounds/Text/Text${this.letter + 1}.mp3`);
        this.sounds.more = loadSound(`/letters_island/find_letters/sounds/more/more${this.letter + 1}.mp3`);
        this.sounds.help = loadSound(`/letters_island/find_letters/sounds/helpletter/Helpletter${this.letter + 1}.mp3`);
        this.sounds.wrong = loadSound(`/letters_island/find_letters/sounds/wrong.mp3`);
        this.sounds.good = loadSound(`/letters_island/find_letters/sounds/VideoTov.mp3`);
        this.sounds.letter = loadSound(`/letters_island/common/letters/she/letter${this.letter + 1}.mp3`);
        const lastLetterIndex = getLastLetterIndex(this.letter);
        if (lastLetterIndex != null) {
            this.sounds.f_letter = loadSound(`/letters_island/common/letters/she/letter${lastLetterIndex}.mp3`);
        }
    };

    play = (sound: createjs.AbstractSoundInstance) => {
        sound.play();
        return soundPlayed(sound);
    };

    playInstenter = async () => {
        const introSound = await this.sounds.intro;
        await this.play(introSound);
        const textSound = await this.sounds.text;
        await this.play(textSound);
        const helpSound = await this.sounds.help;
        return await this.play(helpSound);
    };

    playInstFindLetter = async (sound: any) => {
        const helpSound = await sound;
        this.play(helpSound);
    };

    clearSoundLoop = () => {
        this.stopInst = true;
    };

    helpSound = () => {
        this.correctPressed ? this.playInstFindLetter(this.sounds.more) : this.playInstFindLetter(this.sounds.help);
    };

    loaded = async () => {
        this.root.gotoAndStop(0);
        this.image = getImage(this.letter + 1);
        this.disableHelp();
        this.loadSounds();
        this.root.picture.addChild(this.image);
        this.sentences = findSentencesToLetter(this.letter + 1);
        this.findLetterInsentence();
        await this.playInstenter();
        this.enableHelp(true, `/letters_island/find_letters/sounds/helpletter/Helpletter${this.letter + 1}.mp3`);
        this.lastTime = createjs.Ticker.getTime(true);
        this.root.addEventListener("tick", async (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;
            const time = createjs.Ticker.getTime(true);
            if (time - this.lastTime > 10000) {
                if (!this.stopInst) {
                    this.helpSound();
                }
                this.lastTime = time;
            }
        });
        this.addListeners();
    };
}
