import {
    MC,
    playSound,
    setupStage,
    waitForEvent,
} from "../../../animate/utils";
import { RevealGame } from "../RevealGame";

const SOUND = "bongo2wav";
const NUM_PIECES = 24;
const NUM_COVERS = 5;
export class SpaceGame {
    private root: MC;
    private game: RevealGame;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        console.log("loaded space");
        (window as any).game = this.root;
        (window as any).frame = this.frame.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.game = new RevealGame(
            this.root,
            this.onSuccess,
            SOUND,
            NUM_PIECES,
            NUM_COVERS
        );
        this.game.start();
    };
    unload = () => {
        this.game && this.game.unload();
    };
    onSuccess = async () => {
        console.log("on success");
        this.root.gotoAndPlay(3);
        playSound("endSpace");
        await waitForEvent(this.root, "spaceEnd");
        this.game.start();
    };
}
