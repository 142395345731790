import { MC, playSound } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { getBongoSound } from "./common";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

export class RocketMenu {
    constructor(private frame: LunaparkMenu) {}

    private root: MC;

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).rocket = this.root;
        this.root.gotoAndStop(0);
        this.root.Bar.out.addEventListener("click", () => {
            this.frame.exitGame();
        });
        this.root.board.inner.addEventListener("click", () => playSound(getBongoSound()));
        this.init();
    };

    init = () => {
        this.addPuzzleListeners();
        this.addShadowListeners();
        this.addRevealListeners();
        this.addMatchListeners();
    };

    addPuzzleListeners = () => {
        for (let i = 1; i <= 4; i++) {
            const oGame = this.root[`Puzzle1_${i}`];
            if (oGame != null) {
                oGame.id = routes.lunapark.rocket.puzzle.replace(":num", i + "");
                this.clickGame(oGame);
            }
        }
    };

    addShadowListeners = () => {
        const shadow_routes = [
            routes.lunapark.rocket.french_horns,
            routes.lunapark.rocket.fish,
            routes.lunapark.rocket.veggies,
            routes.lunapark.rocket.insects,
            routes.lunapark.rocket.leaves,
        ];
        for (let i = 1; i <= 5; i++) {
            const oGame = this.root[`shadow_${i}`];
            if (oGame != null) {
                oGame.id = shadow_routes[i - 1];
                this.clickGame(oGame);
            }
        }
    };

    addRevealListeners = () => {
        const reveal_routes = [
            routes.lunapark.rocket.space,
            routes.lunapark.rocket.underwater,
            routes.lunapark.rocket.bugs,
        ];
        for (let i = 1; i <= 3; i++) {
            const oGame = this.root[`reveal_${i}`];
            if (oGame != null) {
                oGame.id = reveal_routes[i - 1];
                this.clickGame(oGame);
            }
        }
    };

    addMatchListeners = () => {
        for (let i = 1; i <= 5; i++) {
            const path = `pic_${i}`;
            const oGame = this.root[path];
            if (oGame != null) {
                oGame.id = routes.lunapark.rocket.pic.replace(":num", i + "");
                this.clickGame(oGame);
            }
        }
    };

    clickGame = (oGame: MC) => {
        oGame.addEventListener("mousedown", () => {
            playSound(getBongoSound());
        });
        oGame.addEventListener("click", () => {
            // console.log("pressup game ", oGame, oGame.id);
            this.LoadGame(oGame);
        });
    };

    toggleInner = async (val: boolean) => {
        this.root.board.inner.visible = val;
    };

    LoadGame = (oGame: MC) => {
        this.toggleInner(true);
        Navigation.openPage(oGame.id);
    };
}
