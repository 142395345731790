import { MC, waitForEvent, playSound, asyncTween, stopAllSounds, playSoundSync } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game2 extends BaseGame {
    gameEnd: any;
    helpBtn: any;
    rootGame2: any;
    questionIndex = 0;
    answrsToQuestions = [0, 3, 4];
    frameToStopForQuestion = [68, 229, 391];
    questionToPlayForQuestion = [1, 208, 366];
    answersToPlayForquestions = [93, 244, 411];
    buttonsToQuestion = [
        { btn1: 0, btn2: 1 },
        { btn1: 2, btn2: 3 },
        { btn1: 4, btn2: 5 },
    ];
    initGame = async (root: MC, gameEnd: () => void, helpBtn: any) => {
        this.gameEnd = gameEnd;
        this.helpBtn = helpBtn;
        const game = root.game;
        game.y = 350;
        game.x = -40;
        game.scaleX = 1.2;
        game.scaleY = 1.2;
        this.rootGame2 = root.game2;
        game.visible = true;
        game.gotoAndPlay(2);
        playSoundSync("walk", { loop: -1 });
        if (!this.shouldPlay) return;
        await playSound(`game_2`);
        await waitForEvent(game, "questionEnd");
        helpBtn(false);
        game.gotoAndStop(68);
        for (let i = 0; i < 2; i++) {
            const currentBtn = game[`btn_${i}`];
            const currentPic = game[`pic${i}`];
            currentBtn.cursor = "pointer";
            currentPic.gotoAndStop(0);
            currentBtn.addEventListener("click", async () => {
                this.checkIfCorrectAnswer(root, game, gameEnd, currentBtn);
            });
            currentBtn.addEventListener("rollover", async () => {
                currentPic.gotoAndStop("over");
            });
            currentBtn.addEventListener("rollout", async () => {
                currentPic.gotoAndStop(0);
            });
        }
    };
    checkIfCorrectAnswer = async (root: MC, game: MC, gameEnd: () => void, currentBtn: MC) => {
        if (currentBtn.name === "btn_1") {
            this.helpBtn(true);
            game.mouseChildren = false;
            stopAllSounds();
            game.gotoAndPlay(101);
            game.removeAllEventListeners();
            this.helpBtn(true);
            await waitForEvent(game, "gameEnd");
            await asyncTween(game, { alpha: 0 }, 400);
            this.game2(root, gameEnd);
        } else {
            if (!this.shouldPlay) return;
            await playSound("wrong");
        }
    };
    game2 = async (root: MC, gameEnd: () => void) => {
        const game2 = root.game2;
        game2.visible = true;
        this.helpBtn(true);
        this.nextQuestion(game2);
    };
    game2ButtonsEvents = async (game: MC, minIndex: number, maxIndex: number) => {
        for (let i = minIndex; i < maxIndex + 1; i++) {
            const currentButton = game[`btn_${i}`];
            currentButton.cursor = "pointer";
            currentButton.addEventListener("rollover", () => {
                currentButton.gotoAndStop(1);
            });
            currentButton.addEventListener("rollout", () => {
                currentButton.gotoAndStop(0);
            });
            currentButton.addEventListener("click", async () => {
                currentButton.gotoAndStop(2);
                await this.game2CheckIfCorrectAnswer(game, currentButton);
                currentButton.gotoAndStop(0);
            });
        }
    };
    game2CheckIfCorrectAnswer = async (game: MC, currentBtn: MC) => {
        if (currentBtn.name === `btn_${this.answrsToQuestions[this.questionIndex]}`) {
            this.helpBtn(true);

            game.gotoAndPlay(this.answersToPlayForquestions[this.questionIndex]);
            await waitForEvent(game, `game${this.questionIndex}End`);
            if (this.questionIndex < 2) {
                this.questionIndex++;
                game.removeAllEventListeners();
                this.nextQuestion(game);
            } else {
                await asyncTween(this.rootGame2, { alpha: 0 }, 300);
                this.gameEnd();
            }
        } else {
            await playSound("wrong");
            this.helpBtn(false);
        }
    };
    nextQuestion = async (game: MC) => {
        game.mouseChildren = false;
        if (this.questionIndex === 0) {
            game.gotoAndPlay(this.questionToPlayForQuestion[this.questionIndex]);
            await waitForEvent(game, `question${this.questionIndex}End`);
            this.helpBtn(false, "game2Instructios");
        }
        game.mouseChildren = true;
        game.gotoAndStop(this.frameToStopForQuestion[this.questionIndex]);
        const btn1 = game[`btn_${this.buttonsToQuestion[this.questionIndex].btn1}`];
        const btn2 = game[`btn_${this.buttonsToQuestion[this.questionIndex].btn2}`];
        const minIndex = this.buttonsToQuestion[this.questionIndex].btn1;
        const maxIndex = this.buttonsToQuestion[this.questionIndex].btn2;
        btn1.gotoAndStop(0);
        btn2.gotoAndStop(0);
        this.game2ButtonsEvents(game, minIndex, maxIndex);
    };
}
