import {
    getLetter,
    MC,
    playSound,
    playSoundSync,
    stopAllSounds,
    waitForEvent,
    waitForTimer,
} from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { findObjectsToLetter, returnCorrectAnswerText, getLetterForIndex } from "../jsonFileFunction";
import { ReviewGameBasic } from "../ReviewGameBasic";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { routes } from "../../../navigation/routesPath";
import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import { Navigation } from "../../../navigation/navigation";

export class Boxes extends ReviewGameBasic {
    letterSound: any = null;
    objectSound: any = null;
    currentObject: MC;
    currentLetterIndex: number = null;
    questionIndex: number = 0;
    lettersForQuestions: any = [];
    firstChoiceArr: number[];
    secondChoiceArr: number[];
    quizModeNumberOfQuestion: number;
    mode: string = "";
    letterSoundName: any;
    addPrizes: any;
    setHelpSound: any;
    shouldPlay: boolean;
    correctAnswerSound: any;
    wrongAnswersSounds: any[] = [];
    extaObj = [
        "lid_0",
        "lid_1",
        "lid_2",
        "letter_shelf",
        "letter0",
        "box_0",
        "box_1",
        "box_2",
        "lid_0",
        "lid_1",
        "lid_2",
        "spring_0",
        "spring_1",
        "spring_2",
        "in_word0",
        "in_word1",
        "in_word2",
        "in_word0.word_2",
        "in_word1.word_2",
        "in_word2.word_2",
        "word_tail",
        "word_capitol",
        "word_4",
        "word_bg",
    ];
    constructor(
        public root: MC,
        private history: any,
        public sectionIndex: number,
        private lettersContext: any,
        numberOfQuestion: number,
        mode: string,
        addPrizes?: any,
        setHelpSound?: any,
        private enabledButtonFunc?: any
    ) {
        super();
        (window as any).boxes = this;
        this.mode = mode;
        this.quizModeNumberOfQuestion = numberOfQuestion;
        this.addPrizes = addPrizes;
        this.setHelpSound = setHelpSound;

        if (this.mode !== "quiz") {
            this.loaded();
        } else {
            this.root.children.forEach((child: MC) => {
                child.visible = false;
                child.alpha = 1;
            });
            this.setHelpSound("matchgame_general");

            this.quizLoded();
        }
        // (window as any).boxes = root;
    }

    showLoader = async () => {
        if (this.mode !== "quiz") {
            this.root.LoadBlock.visible = true;
            this.root.LoadBlock.gotoAndPlay(1);
            this.root.splash.gotoAndPlay(27);
            this.root.splash.visible = true;
            await waitForEvent(this.root, "animationEnd");
            if (!this.shouldPlay) return;
        } else {
            this.enabledButtonFunc(false);

            this.root.splash.visible = true;
        }
        this.soundPlayed = true;
        this.objectSound = await playSound("gameName");
        if (!this.shouldPlay) return;
        this.objectSound = await playSound("matchgame_general");
        if (!this.shouldPlay) return;
        this.soundPlayed = false;
        if (this.mode === "quiz") {
            this.enabledButtonFunc(true);
        }
        this.root.splash.visible = false;
        this.root.splash.paused = true;
    };

    initBoxesAndShelf = async () => {
        if (this.mode !== "quiz") {
            this.enableButtons(["reset", "help", "exit"]);
            this.root.LoadBlock.visible = false;
            this.root.splash.visible = false;
            this.root.BGStationed.visible = false;
        }

        this.root.letter_shelf.gotoAndStop(0);
        this.root.letter_shelf.visible = true;
        this.root.letter_shelf.shelf.mouseEnabled = false;
        this.root.letter_shelf.poster.mouseEnabled = true;
        this.root.letter0.mouseEnabled = true;
        this.root.letter_shelf.shelf_glow.addEventListener("rollover", () => {
            if (!this.currentObject) return;
            this.root.letter_shelf.shelf.gotoAndStop(1);
            this.root.letter_shelf.poster.gotoAndStop(1);
        });

        this.root.letter_shelf.shelf_glow.addEventListener("rollout", () => {
            if (!this.currentObject) return;
            this.root.letter_shelf.shelf.gotoAndStop(0);
            this.root.letter_shelf.poster.gotoAndStop(0);
        });
        let letter = this.randomLetterForQuestion();
        this.root.letter0.visible = true;
        this.root.letter0.text = getLetter(letter);
        const letterName = getLetterForIndex(getLetter(letter));

        const answers = findObjectsToLetter(`${letter + 1}`);
        this.correctAnswerSound = await loadSound(`/letters_island/common/objects/${answers.correctAnswer}.mp3`);
        for (let i = 0; i < 3; i++) {
            let currentAnswer = answers.worngAnswers[i];
            if (currentAnswer !== null) {
                this.wrongAnswersSounds[i] = await loadSound(`/letters_island/common/objects/${currentAnswer}.mp3`);
            } else {
                this.wrongAnswersSounds[i] = null;
            }
        }
        this.letterSoundName = `letters${letter + 1}`;
        const letterSound = await loadSound(`/letters_island/common/letters/${letterName}.mp3`);

        if (!this.shouldPlay) return;
        await soundPlayed(letterSound.play());

        if (!this.shouldPlay) return;
        SoundToolTip.addToolTip(this.root.letter0, `/letters_island/common/letters/${letterName}.mp3`, true);

        await waitForTimer(400);
        if (!this.shouldPlay) return;
        playSoundSync("boxesAppear");
        if (!this.shouldPlay) return;
        for (let i = 0; i < 3; i++) {
            this.root[`box_${i}`].visible = true;
            this.root[`lid_${i}`].InnerLid.gotoAndStop(0);
            this.root[`lid_${i}`].visible = true;
            this.root[`box_${i}`].cursor = "pointer";
            this.root[`lid_${i}`].cursor = "pointer";
            this.root[`box_${i}`].addEventListener("click", async () => {
                this.boxEvent(answers, i);
            });
            this.root[`lid_${i}`].addEventListener("click", async () => {
                this.boxEvent(answers, i);
            });
            await waitForTimer(400);
            if (!this.shouldPlay) return;
        }
    };

    boxEvent = async (answers: any, i: number) => {
        this.root[`box_${i}`].removeAllEventListeners();
        this.root[`lid_${i}`].removeAllEventListeners();
        this.showObjInBox(this.root[`lid_${i}`].InnerLid, async () => {
            stopAllSounds();
            this.root[`spring_${i}`].gotoAndPlay(0);
            this.root[`spring_${i}`].visible = true;
            if (
                this.history.path === routes.letters.review.wood_boxes ||
                this.history.path === routes.letters.review.quiz
            )
                this.objectSound = playSoundSync("spring");
            this.showObjToLetter(answers, i);
        });
    };
    showObjToLetter = async (answers: any, i: number) => {
        this.root[`in_word${i}`].visible = true;
        const currentObj = this.root[`in_word${i}`].word_2;
        let objSound: any;
        if (i === answers.correctAnswerIndex) {
            this.root[`in_word${i}`].gotoAndPlay(1);
            currentObj.gotoAndStop(answers.correctAnswer);
            objSound = this.correctAnswerSound;
            this.currentObjectEvent(currentObj, answers.correctAnswer, answers, i, objSound);
        } else {
            objSound = this.wrongAnswersSounds[i];
            this.root[`in_word${i}`].gotoAndPlay(1);
            currentObj.gotoAndStop(answers.worngAnswers[i]);
            this.currentObjectEvent(currentObj, answers.worngAnswers[i], answers, i, objSound);
        }

        if (!this.shouldPlay) return;

        await soundPlayed(objSound.play());
    };

    currentObjectEvent = async (currentObj: MC, answer: string, answers: any, i: number, objSound: any) => {
        const currntObjX = currentObj.x;
        const currntObjY = currentObj.y;
        currentObj.visible = true;
        currentObj.cursor = "pointer";
        this.root.letter_shelf.shelf_glow.visible = true;
        this.root.letter_shelf.shelf_glow.alpha = 0.01;
        this.root.letter_shelf.shelf_glow.gotoAndStop(1);
        SoundToolTip.addToolTip(currentObj, `/letters_island/common/objects/${answer}.mp3`, true);

        const startMove = async (event: createjs.Event) => {
            if (this.currentObject) return;
            this.root.letter_shelf.poster.mouseEnabled = false;
            this.root.letter0.mouseEnabled = false;
            event.stopImmediatePropagation();
            this.currentObject = currentObj;
            currentObj.alpha = 0.65;
            this.objectSound = await playSound("pickup");
            if (!this.shouldPlay) return;
            if (objSound) {
                stopAllSounds();
                soundPlayed(objSound.play());
            }

            currentObj.mouseEnabled = false;
            this.root.cursor = "pointer";
            this.root[`spring_${i}`].gotoAndPlay(0);

            currentObj.addEventListener("tick", (e: any) => {
                if ((e as createjs.TickerEvent).paused) return;

                const parent: createjs.DisplayObject = currentObj.parent;
                const position = parent.globalToLocal(parent.stage.mouseX, parent.stage.mouseY);
                currentObj.x = position.x;
                currentObj.y = position.y;
            });

            currentObj.removeEventListener("click", startMove);
            this.root.addEventListener("click", stopMove);
        };

        const stopMove = () => {
            this.currentObject = null;
            currentObj.mouseEnabled = true;
            this.root.cursor = "default";
            currentObj.alpha = 1;
            this.root.letter_shelf.poster.mouseEnabled = true;
            this.root.letter0.mouseEnabled = true;
            this.root.removeEventListener("click", stopMove);
            currentObj.removeAllEventListeners();
            SoundToolTip.addToolTip(currentObj, `/letters_island/common/objects/${answer}.mp3`, true);

            currentObj.addEventListener("click", startMove);
            currentObj.sound = answer;
            if (answer !== answers.correctAnswer) {
                if (this.root.letter_shelf.shelf.currentFrame === 1) {
                    if (!this.shouldPlay) return;
                    playSoundSync("bad");
                    if (this.mode === "quiz") {
                        this.questionIndex++;
                        this.quizModeReset(currentObj, i, currntObjX, currntObjY, "wrong");
                    }
                }
                currentObj.x = currntObjX;
                currentObj.y = currntObjY;
                this.root.letter_shelf.shelf.gotoAndStop(0);
                this.root.letter_shelf.poster.gotoAndStop(0);
            } else if (this.root.letter_shelf.shelf.currentFrame !== 1 && answer === answers.correctAnswer) {
                currentObj.x = currntObjX;
                currentObj.y = currntObjY;
            } else if (this.root.letter_shelf.shelf.currentFrame === 1 && answer === answers.correctAnswer) {
                this.correctAnswer(currentObj, i, answers, currntObjX, currntObjY);
            }
        };

        currentObj.addEventListener("click", startMove);
    };

    correctAnswer = async (currentObj: MC, i: number, answers: any, currntObjX: any, currntObjY: any) => {
        for (let i = 0; i < 3; i++) {
            this.root[`in_word${i}`].word_2.removeAllEventListeners();
            this.root[`box_${i}`].removeAllEventListeners();
            this.root[`lid_${i}`].removeAllEventListeners();
        }
        this.root.letter_shelf.shelf.gotoAndStop(0);
        this.root.letter_shelf.poster.gotoAndStop(0);
        this.root.word_4.gotoAndStop(currentObj.currentFrame);
        currentObj.visible = false;
        this.root.word_4.visible = true;
        currentObj.removeAllEventListeners();
        SoundToolTip.addToolTip(currentObj, `/letters_island/common/objects/${currentObj.sound}.mp3`, true);
        this.root.word_capitol.visible = true;
        this.root.word_tail.visible = true;
        this.root.word_bg.visible = true;
        this.root.setChildIndex(this.root.word_tail, this.root.children.length - 1);
        this.root.setChildIndex(this.root.word_capitol, this.root.children.length - 1);
        let answerText: "";
        this.root.word_capitol.TextBox.font = "italic  20px 'ProntoMF'";
        this.root.word_tail.children[0].font = "italic  15px 'ProntoMF'";
        answerText = returnCorrectAnswerText(answers.correctAnswer);
        this.root.word_capitol.children[0].text = answerText[0];
        this.root.word_tail.children[0].text = answerText.substr(1);
        await waitForTimer(200);
        if (!this.shouldPlay) return;
        if (this.questionIndex < 5 || this.questionIndex < this.quizModeNumberOfQuestion) {
            this.questionIndex++;
        }
        if (this.mode !== "quiz") {
            this.root[`prize${this.questionIndex}`].visible = true;
            if (
                this.history.path === routes.letters.review.wood_boxes ||
                this.history.path === routes.letters.review.quiz
            )
                this.objectSound = playSoundSync("end");

            await waitForTimer(1200);
            if (!this.shouldPlay) return;
            this.reset(currentObj, i, currntObjX, currntObjY);
        } else {
            this.objectSound = playSoundSync("end");
            await waitForTimer(1200);
            if (!this.shouldPlay) return;
            this.quizModeReset(currentObj, i, currntObjX, currntObjY, "correct");
        }
    };

    showObjInBox = (currntObj: MC, callFuction?: () => void) => {
        createjs.Tween.get(currntObj)
            .to(currntObj.gotoAndPlay(1), 100, createjs.Ease.cubicOut)
            .call(() => {
                callFuction && callFuction();
            });
    };

    initLetterArr = () => {
        let letterIndex;
        for (let index = 0; index < 2; index++) {
            do {
                letterIndex = getRandomNumber(0, this.secondChoiceArr.length - 1);
            } while (this.lettersForQuestions.includes(this.secondChoiceArr[letterIndex]));
            this.lettersForQuestions.push(this.secondChoiceArr[letterIndex]);
        }
        let lettersNotToShow = [];
        lettersNotToShow.push(getRandomNumber(0, this.firstChoiceArr.length - 1));
        if (this.sectionIndex === 4 || this.sectionIndex === 5) {
            lettersNotToShow.push(getRandomNumber(0, this.firstChoiceArr.length - 1));
        }
        for (let index = 0; index <= this.firstChoiceArr.length - 1; index++) {
            if (!lettersNotToShow.includes(index)) {
                this.lettersForQuestions.push(this.firstChoiceArr[index]);
            }
        }
    };

    randomLetters = () => {
        switch (this.sectionIndex) {
            case 1:
                this.firstChoiceArr = [1, 2, 3, 4];
                this.secondChoiceArr = [1, 2, 3, 4];
                break;
            case 2:
                this.firstChoiceArr = [5, 6, 7, 8];
                this.secondChoiceArr = [2, 3, 4];
                break;
            case 3:
                this.firstChoiceArr = [9, 10, 11, 12];
                this.secondChoiceArr = [1, 2, 3, 4, 6, 7, 8];
                break;
            case 4:
                this.firstChoiceArr = [13, 14, 15, 16, 17];
                this.secondChoiceArr = [2, 3, 4, 6, 7, 8, 9, 10, 11, 12];
                break;
            case 5:
                this.firstChoiceArr = [18, 19, 20, 21, 22];
                this.secondChoiceArr = [2, 3, 4, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17];
                break;
        }
        if (this.mode !== "quiz") this.initLetterArr();
    };

    randomLetterForQuestion = () => {
        let letterIndex: any;
        let letter: any;
        letterIndex = getRandomNumber(1, this.lettersForQuestions.length - 1);
        if (this.lettersForQuestions.length > 1) {
            letter = this.lettersForQuestions[letterIndex];
        } else {
            letter = this.lettersForQuestions[0];
        }
        this.lettersForQuestions.splice(letterIndex, 1);
        this.currentLetterIndex = letterIndex;
        return letter - 1;
    };

    initFrame = () => {
        if (this.mode !== "quiz") {
            const objToShow = ["reset", "help", "exit"];
            this.root.children.forEach((child: MC) => {
                if (!objToShow.includes(child.name)) {
                    child.visible = false;
                    child.alpha = 1;
                }
            });
            this.root.frame.visible = true;
            if (this.questionIndex > 0) {
                for (let i = 0; i < this.questionIndex; i++) {
                    this.root[`prize${i + 1}`].visible = true;
                }
            }
        } else {
            this.root.children.forEach((child: MC) => {
                child.visible = false;
                child.alpha = 1;
            });
        }
        this.root.bg.visible = true;
        this.root.box_jump.gotoAndStop(0);
    };
    reset = async (currentObj: MC, i: number, currntObjX: any, currntObjY: any) => {
        this.root.letter_shelf.shelf.gotoAndStop(0);
        this.root.letter_shelf.poster.gotoAndStop(0);
        this.correctAnswerSound = null;
        this.wrongAnswersSounds = [];
        for (let i = 0; i < 3; i++) {
            this.root[`in_word${i}`].word_2.removeAllEventListeners();
            this.root[`box_${i}`].removeAllEventListeners();
            this.root[`lid_${i}`].removeAllEventListeners();
        }
        this.letterSoundName = "";
        currentObj.x = currntObjX;
        currentObj.y = currntObjY;
        this.root.letter_shelf.shelf_glow.removeAllEventListeners();
        currentObj.removeAllEventListeners();
        const promises: any[] = [];
        this.extaObj.forEach((child: string) => {
            if (this.root[child] !== undefined) {
                promises.push(
                    new Promise((resolve) =>
                        createjs.Tween.get(this.root[child]).to({ alpha: 0 }, 700, createjs.Ease.cubicOut).call(resolve)
                    )
                );
            }
        });
        await Promise.all(promises);
        if (!this.shouldPlay) return;
        if (this.questionIndex >= 5) {
            this.resetAll();
        } else {
            this.currentLetterIndex = null;
            await this.initFrame();
            if (!this.shouldPlay) return;
            await waitForTimer(300);
            if (!this.shouldPlay) return;
            this.initBoxesAndShelf();
        }
    };
    quizModeReset = async (currentObj: MC, i: number, currntObjX: any, currntObjY: any, answerStatus: string) => {
        for (let i = 0; i < 3; i++) {
            this.root[`in_word${i}`].word_2.removeAllEventListeners();
            this.root[`box_${i}`].removeAllEventListeners();
            this.root[`lid_${i}`].removeAllEventListeners();
        }
        this.addPrizes("boxes", this.questionIndex, answerStatus);

        await waitForTimer(1200);
        if (!this.shouldPlay) return;
        this.root.letter_shelf.shelf.gotoAndStop(0);
        this.root.letter_shelf.poster.gotoAndStop(0);
        currentObj.x = currntObjX;
        currentObj.y = currntObjY;
        this.root.letter_shelf.shelf_glow.removeAllEventListeners();
        currentObj.removeAllEventListeners();
        const promises: any[] = [];
        this.extaObj.forEach((child: string) => {
            if (this.root[child] !== undefined) {
                promises.push(
                    new Promise((resolve) =>
                        createjs.Tween.get(this.root[child]).to({ alpha: 0 }, 700, createjs.Ease.cubicOut).call(resolve)
                    )
                );
            }
        });
        await Promise.all(promises);
        if (!this.shouldPlay) return;
        if (this.questionIndex !== this.quizModeNumberOfQuestion) {
            this.currentLetterIndex = null;
            await this.initFrame();
            if (!this.shouldPlay) return;

            await waitForTimer(300);
            if (!this.shouldPlay) return;

            this.initBoxesAndShelf();
        }
    };
    resetAll = async () => {
        this.disableButtons(["reset", "help"]);
        this.lettersContext.setGameStatus(this.sectionIndex, 2);
        await this.initFrame();
        if (!this.shouldPlay) return;
        this.root.box_jump.visible = true;
        this.root.box_jump.scaleX = this.root.box_jump.scaleY = 0.6;
        this.root.box_jump.gotoAndPlay(0);
        await waitForEvent(this.root, "animationEnd");
        if (!this.shouldPlay) return;
        this.setAlertBox(this.root.AlertBox, this.playAgain, this.sectionIndex);
    };
    playAgain = () => {
        this.letterSoundName = "";
        this.lettersForQuestions = [];
        this.randomLetters();
        this.questionIndex = 0;
        this.root.AlertBox.visible = false;
        this.initFrame();
        this.initBoxesAndShelf();
    };
    restart = async () => {
        this.extaObj.forEach((child: string) => {
            if (this.root[child] !== undefined) {
                if (child.startsWith("in_word")) {
                    this.root[child].word_2.removeAllEventListeners();
                }
                this.root[child].visible = false;
                this.root[child].removeAllEventListeners();
            }
        });
        this.letterSoundName = "";
        this.lettersForQuestions = [];
        this.randomLetters();
        await waitForTimer(600);
        if (!this.shouldPlay) return;
        this.questionIndex = 0;
        this.initFrame();
        this.initBoxesAndShelf();
    };
    quisModerandomLetters = () => {
        let letterIndex;
        this.randomLetters();
        for (let index = 0; index < this.quizModeNumberOfQuestion; index++) {
            letterIndex = getRandomNumber(0, this.firstChoiceArr.length - 1);
            if (this.sectionIndex === 4 || this.sectionIndex === 5 || this.sectionIndex === 3) {
                if (!this.lettersForQuestions.includes(this.firstChoiceArr[letterIndex])) {
                    this.lettersForQuestions.push(this.firstChoiceArr[letterIndex]);
                } else {
                    index--;
                }
            } else {
                this.lettersForQuestions.push(this.firstChoiceArr[letterIndex]);
            }
        }
    };

    loaded = async () => {
        this.shouldPlay = true;
        this.initFrame();
        this.randomLetters();
        this.initButtons(this.root, this.restart, "matchgame_general", this.sectionIndex, null, () => {
            this.unloaded();
            Navigation.openPage(routes.letters.review.menu.replace(":sectionIndex", this.sectionIndex + ""));
        });
        this.disableButtons(["reset", "help"]);
        await this.showLoader();
        if (!this.shouldPlay) return;
        this.initBoxesAndShelf();
    };

    quizLoded = async () => {
        this.shouldPlay = true;
        this.initFrame();
        this.quisModerandomLetters();
        await this.showLoader();
        if (!this.shouldPlay) return;
        this.initBoxesAndShelf();
    };

    unloaded = async () => {
        this.shouldPlay = false;
    };
}
