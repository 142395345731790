import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, playSoundSync, waitForEvent } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import MathGameBase from "../MathGame/MathGameBase";
import { CancelablePromise } from "../../utils/CancelablePromise";

export class Wizard extends MathGameBase {
    chosenLevel: number;
    barBtnyPos: number;
    number: any;
    saveHat: any;
    enableEvents: boolean = true;
    startX = [0, 260, 210, 210, 260, 220, 210, 190, 130, 130, 90];
    rabbit_anim: any[] = [];
    isBarOpen: any;
    correctAnswer: number = 0;
    gamesPlayed: number = 0;
    name = "wizard";
    bgSound: createjs.AbstractSoundInstance;
    private currentPromise: CancelablePromise<any>;
    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).wizard = root;
    }

    randomNumber = () => {
        return (this.number = getRandomNumber(0, 10));
    };

    chooserUp = () => {
        this.currentPromise?.cancel?.();
        this.root.wizard_mc.stop();
        this.root.wizard2_mc.stop();
        this.stopRabbit();
        this.closeBar();
    };

    chooserDown = () => {
        this.startGame();
    };

    handleLevel = (level?: number) => {
        this.correctAnswer = 0;
        this.chosenLevel = level;
        this.cleanPrizes(true);
        this.enableEvents = true;
        this.root.wizard_mc.gotoAndPlay(0);
        this.root.wizard2_mc.gotoAndPlay(0);
        this.startGame();
    };

    resetBarBtns = () => {
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.gotoAndStop(0);
            btn.num_mc.all_num.gotoAndStop(i);
            btn.num_mc.all_num[`num${i}`].gotoAndStop(0);
        }
    };

    openBar = async () => {
        this.isBarOpen = true;
        this.enableEvents = false;
        this.root.btns_anim_mc.visible = true;
        return new Promise<void>((resolve) => {
            createjs.Tween.get(this.root.btns_anim_mc)
                .to({ y: 350 }, 800)
                .call(() => {
                    this.enableEvents = true;
                    resolve();
                });
        });
    };

    closeBar = async () => {
        if (this.isBarOpen) {
            this.resetBarBtns();
        }
        this.isBarOpen = false;
        return new Promise<void>((resolve) => {
            createjs.Tween.get(this.root.btns_anim_mc)
                .to({ y: 800 }, 500)
                .call(() => {
                    this.enableEvents = false;
                    resolve();
                });
        });
    };

    initBarBtns = () => {
        this.enableHelp();
        this.randomNumber();
        this.enableEvents = true;
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.gotoAndStop(0);
            btn.num_mc.all_num.gotoAndStop(i);
            btn.num_mc.all_num[`num${i}`].gotoAndStop(0);
            btn.mouseChildren = false;
            this.addBarBtnEvents(btn, i);
        }
    };

    startGame = async () => {
        this.rabbit_anim = [];
        loadSound("/math/sounds/wizard/kosem_help.mp3");
        this.disableHelp();
        this.bgSound = playSoundSync(`/math/sounds/wizard/wizard.mp3`, { loop: -1 });
        this.bgSound.volume = 0.6;
        this.root.wizard_mc.gotoAndPlay("start");
        this.root.wizard2_mc.gotoAndPlay("start");
        await this.cancelableWait(waitForEvent(this.root, "sayGuss"));
        await playSound(`/math/sounds/wizard/kosem_ques.mp3`);
        this.openBar();
        this.initBarBtns();
    };

    showRabbit = async () => {
        this.closeBar();
        this.bgSound.volume = 0;
        const hat = new this.root.lib.hat_mc();
        this.saveHat = this.root.addChild(hat);
        hat.x = 199;
        hat.y = 223;
        for (let index = 0; index < this.number; index++) {
            loadSound(`/math/sounds/wizard/${index + 1}_end.mp3`);
            const rabbit = new this.root.lib.rabbit_mc();
            this.rabbit_anim.push(rabbit);
            this.root.addChild(rabbit);
            createjs.Tween.get(hat)
                .to({ x: this.startX[this.number] + index * 56 }, 200)
                .call(() => {
                    hat.gotoAndPlay(0);
                });
            rabbit.x = this.startX[this.number] + index * 56;
            rabbit.y = 223;
            if (index % 2 === 0) {
                rabbit.y = 215;
            }
            rabbit.gotoAndPlay("start");
            await this.cancelableWait(waitForEvent(this.root, "songJump"));
            playSound(`/math/sounds/wizard/${index + 1}_end.mp3`);
            await this.cancelableWait(waitForEvent(this.root, "finishJump"));
        }
        for (let index = 0; index < this.rabbit_anim.length; index++) {
            const rabbit = this.rabbit_anim[index];
            rabbit.x = this.startX[this.number] + index * 56;
            rabbit.y = 223;
            rabbit.gotoAndPlay("jump");
            await this.cancelableWait(waitForEvent(this.root, "popSong"));
            playSound(`/math/sounds/rabbit3.mp3`);
            await this.cancelableWait(waitForEvent(this.root, "Jump"));
        }
        createjs.Tween.get(hat)
            .to({ x: 199 }, 400)
            .call(() => {
                this.root.removeChild(hat);
                this.root.wizard_mc.gotoAndPlay("end");
                this.root.wizard2_mc.gotoAndPlay("end");
            });
        await this.cancelableWait(waitForEvent(this.root, "finishend"));
    };

    stopRabbit = () => {
        for (let index = 0; index < this.rabbit_anim.length; index++) {
            this.rabbit_anim[index].stop();
            this.root.removeChild(this.rabbit_anim[index]);
        }
        this.root.removeChild(this.saveHat);
        this.rabbit_anim = [];
    };

    loadSounds = () => {
        loadSound(`/math/sounds/wizard/kosem_ques_more.mp3`);
        loadSound(`/math/sounds/wizard/kosem_ques_less.mp3`);
        loadSound(`/math/sounds/wizard/Kosem_magic.mp3`);
        loadSound(`/math/sounds/wizard/kosem_zero.mp3`);
        loadSound(`/math/sounds/wizard/kosem_ques.mp3`);
        loadSound(`/math/sounds/rabbit3.mp3`);
    };

    addBarBtnEvents = async (mc: MC, index: number) => {
        this.barBtnyPos = mc.y;
        mc.cursor = "pointer";
        mc.addEventListener("rollover", () => {
            if (!this.enableEvents) return;
            createjs.Tween.get(mc)
                .to({ y: mc.y - 10 }, 50, createjs.Ease.quadIn)
                .call(() => {});
        });
        mc.addEventListener("rollout", () => {
            if (!this.enableEvents) return;
            createjs.Tween.get(mc)
                .to({ y: this.barBtnyPos }, 50, createjs.Ease.quadIn)
                .call(() => {});
        });
        mc.addEventListener("click", () => {
            if (!this.enableEvents) return;
            mc.y = this.barBtnyPos;
            this.clickNum(mc, index);
        });
    };

    clickNum = async (btn: MC, num: number) => {
        let index = num;
        if (this.chosenLevel === 1) {
            if (num > this.number) {
                this.root.wizard_mc.gotoAndPlay("less");
                this.root.wizard2_mc.gotoAndPlay("less");
                playSound(`/math/sounds/wizard/kosem_ques_less.mp3`);
                while (index <= 10) {
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].gotoAndStop("disabled");
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].num_mc.all_num[`num${index}`].gotoAndStop(1);
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].removeAllEventListeners();
                    createjs.Tween.get(this.root.btns_anim_mc.btns_mc[`btn${index}_mc`]).to(
                        { y: this.barBtnyPos },
                        50,
                        createjs.Ease.quadIn
                    );
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].cursor = null;
                    index++;
                }
            } else if (num < this.number) {
                this.root.wizard_mc.gotoAndPlay("more");
                this.root.wizard2_mc.gotoAndPlay("more");
                playSound(`/math/sounds/wizard/kosem_ques_more.mp3`);
                while (index >= 0) {
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].gotoAndStop("disabled");
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].num_mc.all_num[`num${index}`].gotoAndStop(1);
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].removeAllEventListeners();
                    createjs.Tween.get(this.root.btns_anim_mc.btns_mc[`btn${index}_mc`]).to(
                        { y: this.barBtnyPos },
                        50,
                        createjs.Ease.quadIn
                    );
                    this.root.btns_anim_mc.btns_mc[`btn${index}_mc`].cursor = null;
                    index--;
                }
            } else this.correctPlay(btn, num);
        } else if (this.chosenLevel === 2) {
            if (num > this.number) {
                this.root.wizard_mc.gotoAndPlay("less");
                this.root.wizard2_mc.gotoAndPlay("less");
                await playSound(`/math/sounds/wizard/kosem_ques_less.mp3`);
            } else if (num < this.number) {
                this.root.wizard_mc.gotoAndPlay("more");
                this.root.wizard2_mc.gotoAndPlay("more");
                await playSound(`/math/sounds/wizard/kosem_ques_more.mp3`);
            } else {
                this.correctPlay(btn, num);
            }
        }
    };

    correctPlay = async (btn: MC, num: number) => {
        this.disableHelp();
        this.enableEvents = false;
        btn.gotoAndStop("pressed");
        btn.num_mc.all_num[`num${num}`].gotoAndStop(1);
        if (this.number !== 0) {
            this.root.wizard_mc.gotoAndPlay("hocus");
            this.root.wizard2_mc.gotoAndPlay("hocus");
            await playSound(`/math/sounds/wizard/Kosem_magic.mp3`);
            await this.cancelableWait(waitForEvent(this.root, "hat"));
            await this.showRabbit();
        } else {
            this.root.wizard_mc.gotoAndPlay("none");
            this.root.wizard2_mc.gotoAndPlay("none");
            await playSound(`/math/sounds/wizard/kosem_zero.mp3`);
            this.closeBar();
        }
        this.correctAnswer++;
        this.addPrize("prize_rabbit");
        if (this.correctAnswer < 5) {
            this.startGame();
        } else this.showEnd();
    };

    loaded = () => {
        this.root.y = 5;
        this.root.wizard_mc.gotoAndStop(0);
        this.root.wizard2_mc.gotoAndStop(0);
        loadSound(`/math/sounds/wizard/wizard.mp3`);
        this.bgSound = playSoundSync(`/math/sounds/wizard/wizard.mp3`, { loop: -1 });
        this.bgSound.volume = 0.6;
        this.loadSounds();
    };

    showEnd = async () => {
        this.gamesPlayed++;
        this.closeBar();
        this.root.wizard_mc.gotoAndPlay("end");
        this.root.wizard2_mc.gotoAndPlay("end");
        await loadSound(`/math/sounds/wizard/wizard_big_end.mp3`);
        this.root.wizard_mc.gotoAndPlay("big_end");
        this.root.wizard2_mc.gotoAndPlay("big_end");
        await playSound(`/math/sounds/wizard/wizard_big_end.mp3`);
        this.correctAnswer = 0;
        this.cleanPrizes();
    };

    async cancelableWait(p: Promise<any>) {
        this.currentPromise = p as CancelablePromise<any>;
        await p;
        this.currentPromise = null;
    }
}
