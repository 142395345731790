import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { PenguinGame } from "./PenguinGame";
const PenguinComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new PenguinGame(frame));

    return <AnimateCC base="/lunapark/abc/penguin" animationName="penguin_abc" onLoaded={game.current.loaded} />;
};
export default PenguinComponent;
