import { MC, playSoundSync, setupStage, waitForEvent, waitForTimer } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

export class PopcornGame {
    private root: MC;
    private potcount: number;
    private newex: number;
    private oldex: number;
    private calc_position: boolean = true;
    private flakes: MC[] = [];
    private cup_frame: number = 0;
    private cup_counter: number = 4;
    private should_move_cup: boolean = true;
    private moveCupX: number = 0;
    private moveCupY: number = 0;
    constructor(private frame: LunaparkMenu) {}
    loaded = (mc: MC) => {
        this.root = mc;
        console.log("popcorn game loaded");
        (window as any).popcorn = this.root;
        (window as any).frame = this.frame;
        this.frame.toggleSweetsBtn(true);
        this.root.gotoAndStop(9);
        this.root.cup.gotoAndStop(0);
        this.root.bigcup.gotoAndStop(0);
        this.root.cup.x = 91;
        this.root.cup.y = 500;
        this.potcount = 0;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();

        this.root.cup.addEventListener("mousedown", () => {
            if (this.should_move_cup) {
                return;
            }
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.moveCupX = localMouse.x - this.root.cup.x;
            this.moveCupY = localMouse.y - this.root.cup.y;
        });

        this.root.cup.addEventListener("click", async () => {
            if (!this.should_move_cup) {
                this.should_move_cup = true;
                return;
            }
            if (this.inShadow() && this.cup_frame === 21) {
                this.should_move_cup = false;
                this.root.cup.gotoAndPlay(22);
                await waitForEvent(this.root, "playPop");
                let audio = playSoundSync("pop");
                audio.volume = 0.5;
                // playSound("pop");
                await waitForTimer(40);
                // playSound("pop");
                let audio2 = playSoundSync("pop");
                audio2.volume = 0.5;
                await waitForTimer(40);
                // playSound("pop");
                let audio3 = playSoundSync("pop");
                audio3.volume = 0.5;
                await waitForTimer(40);
                let audio4 = playSoundSync("pop");
                audio4.volume = 0.5;
                // playSound("pop");

                this.cup_counter += 5;
                this.root.bigcup.gotoAndPlay(this.cup_counter);
                if (this.cup_counter === 29) {
                    await waitForEvent(this.root, "cupEnd");
                    // console.log?("gotEvent")
                    this.frame.LoadGame(routes.lunapark.sweets.menu);
                }
                this.cup_frame = 0;
            }
        });
        this.start();
    };

    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            this.moveCup();
            this.movePot();
            if (Math.floor(Math.random() * 2) === 1) {
                this.makeFlake();
            }
            this.flakes.forEach((flake: MC) => {
                this.moveFlake(flake);
            });
        });
    };

    moveCup = () => {
        if (this.should_move_cup) {
            if (this.root.stage.mouseX && this.root.stage.mouseY) {
                const pos = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
                this.root.cup.x = pos.x - this.moveCupX;
                this.root.cup.y = pos.y - this.moveCupY;
            }
        }
    };

    movePot = () => {
        if (this.calc_position) {
            if (Math.floor(Math.random() * 50) === 1) {
                this.newex = Math.random() * 400 + 100;
                this.oldex = this.root.pot.x;
                this.calc_position = false;
                this.potcount = 0;
            }
        } else {
            this.root.pot.x += (this.newex - this.oldex) / 30;
            this.potcount += 1;
            if (this.root.pot.x < 0) {
                this.root.pot.x = 0;
            }
            if (this.root.pot.x > 800) {
                this.root.pot.x = 800;
            }
            if (this.potcount === 30) {
                this.calc_position = true;
            }
        }
    };

    makeFlake = () => {
        const flake = new this.root.lib.popcorn_flake();
        flake.y = -50;
        flake.x = this.root.pot.x + Math.floor(Math.random() * 50) - 25;
        this.root.addChild(flake);

        this.root.setChildIndex(flake, this.root.getChildIndex(this.root.cup) - 1);
        this.flakes.push(flake);
    };

    moveFlake = (flake: MC) => {
        flake.y += 20;
        if (flake.y > 650) {
            flake.gotoAndStop(10);
            (flake as createjs.MovieClip).cache(-26, -114, 52, 114);
            this.flakes.splice(this.flakes.indexOf(flake), 1);
            this.root.removeChild(flake);
        }
        if (this.inCup(flake) && this.cup_frame < 21) {
            let audio = playSoundSync("pop");
            audio.volume = 0.5;
            this.cup_frame += 1;
            this.root.cup.gotoAndStop(this.cup_frame);
        }
    };

    inCup = (flake: MC) => {
        const flakeRect = new createjs.Rectangle(flake.x - 19 / 2, flake.y - 20 / 2, 19, 20);
        const cupArea = new createjs.Rectangle(this.root.cup.x - 27 / 2, this.root.cup.y - 88 + 12 / 2, 27, 12);

        return flakeRect.intersects(cupArea);
    };

    inShadow = () => {
        /* 
        huristic intersection algorithm :
            x distance is less than half the cup base width,
            and y distance is less than 1/3 of the cup hight
        */
        return (
            Math.abs(this.root.cup.x - this.root.shadow.x) < 20 &&
            Math.abs(this.root.shadow.y + 50 - this.root.cup.y) < 117 / 3
        );
    };
}
