export const additionalSounds = [
    {
        src: "sounds/robotGrow.mp3",
        id: "robotGrow",
    },
    {
        src: "sounds/step2.mp3",
        id: "walk",
    },
];
