export const ACTION_BTNS: string[] = ["iQuit", "iPrint", "iSave", "iNew", "iMail", "iHelp"];
export const PREVIEW_BTNS: string[] = ["iQuit", "iPrint", "iMail"];
export const MAIL_PREVIEW_BTNS: string[] = ["iPrint"]; //"iQuit"
//export const DISABLED_ACTIONS: string[] = [];
export const STORE_BTNS: string[] = ["btnColor", "btnBackPic", "btnPics", "btnTxt"];
export const EDIT_BTNS = ["btnEdit", "btnDrag", "btnEraser", "btnSize"];
export const NEXT_BTN = "btnTopicNext";
export const PREV_BTN = "btnTopicPrev";
export const CUR_COLOR = "curColor";
export const CUR_SIZE = "curSize";
export const CUR_ERASER = "curEraser";
export const CUR_DRAG = "curDrag";
export const CUR_EDIT = "curEdit";
export const RIGHT_BOARD_LIMIT = 558;
export const LEFT_BOARD_LIMIT = 34;
export const TOP_BOARD_LIMIT = 65;
export const BOTTOM_BOARD_LIMIT = 465;
export const CURSORS = [CUR_COLOR, CUR_DRAG, CUR_ERASER, CUR_EDIT, CUR_SIZE];
export const TEXT_INIT_SCALE = 1.4;
export const TEXT_INIT_SIZE = 32;
export const TEXT_SCALES: number[] = [1.4, 1.8, 2.5, 1];
export const TOOLTIP_TIMEOUT = 750;
export const GRAPHIC_INIT_SCALE = 0.3;
export const GRAPHIC_SCALES: number[] = [0.3, 0.6, 0.15];
export const TEXT_GRID_SPACING: number = 11;
export const DANGLE_Y = 4;

export const STAMP_MIN_WIDTH = 87.2 / 2; //bootle
export const STAMP_MIN_HEIGHT = 137.25 / 2; //shofar

export const PREVIEW_FULLSCREEN_SCALE = 1.53;
export const PREVIEW_MYWORKS_SCALE = 0.23;
export const PREVIEW_DELETE_SCALE = 1.25;
export const LETTERS = [
    "א",
    "ב",
    "ג",
    "ד",
    "ה",
    "ו",
    "ז",
    "ח",
    "ט",
    "י",
    "כ",
    "ל",
    "מ",
    "נ",
    "ס",
    "ע",
    "פ",
    "צ",
    "ק",
    "ר",
    "ש",
    "ת",
    "ך",
    "ם",
    "ן",
    "ף",
    "ץ",
];
export const LETTERS_MAP = [
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
    "9A",
    "9B",
    "9C",
    "9D",
    "9E",
    "9F",
    "A0",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "AA",
];
export const DIGITS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
//Characters

export const SHEKEL = "%E2%82%AA";
export const SHEKEL_REPLACEMENT = "%QQ";
export const LETTER_SEPARATOR = "%D7%";
export const CHAR_SEPARATOR = "%";
export const LTR = "\u202B";
export const RTL = "\u202C";
export const HASHTAG = "%23";
export const RTL_CHARACHTERS = [SHEKEL_REPLACEMENT, HASHTAG];
export const CHARACTERS: any = {
    ".": "%2E",
    ",": "%2C",
    "|": "%7C",
    "{": "%7B",
    "}": "%7D",
    "[": "%5B",
    "]": "%5D",
    "(": "%29",
    ")": "%28",
    "*": "%2A",
    "₪": SHEKEL_REPLACEMENT, //"%E2%82%AA",
    "^": "%5E",
    "%": "%25",
    $: "%24",
    "#": "%23",
    "@": "%40",
    "!": "%21",
    "=": "%3D",
    "+": "%2B",
    "-": "%2D",
    _: "%5F",
    " ": "%20",
    "&": "%26",
    "?": "%51",
    "/": "%2F",
    "\\": "%5C",
    ">": "%11",
    "<": "%12",
    "`": "%13",
    "~": "%14",
    ":": "%15",
    ";": "%16",
    '"': "%17",
    "'": "%18",
    "׳": "%27",
};
export const VALID_EMAIL_CHARACTERS: string =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890#!%$‘&+*–/=?^_`.{|}~@";
export const VALID_ALERT = ["letterTooltip", "noEmailTooltip", "noNameTooltip", "noValidEmailTooltip"];
// export const UNICODE_SEPARATOR = "%u";
export const LEFT_TAG = "%3C";
export const RIGHT_TAG = "%3E";
export const CONTACTS_OPEN = `${LEFT_TAG}Contacts${RIGHT_TAG}`;
export const CONTACTS_CLOSE = `${LEFT_TAG}/Contacts${RIGHT_TAG}`;
export const CONTACT_OPEN = `${LEFT_TAG}Contact${RIGHT_TAG}`;
export const CONTACT_CLOSE = `${LEFT_TAG}/Contact${RIGHT_TAG}`;
export const NAME_OPEN = `${LEFT_TAG}sName${RIGHT_TAG}`;
export const NAME_CLOSE = `${LEFT_TAG}/sName${RIGHT_TAG}`;
export const EMAIL_OPEN = `${LEFT_TAG}sEmail${RIGHT_TAG}`;
export const EMAIL_CLOSE = `${LEFT_TAG}/sEmail${RIGHT_TAG}`;
export const PIC_OPEN = `${LEFT_TAG}nPic${RIGHT_TAG}`;
export const PIC_CLOSE = `${LEFT_TAG}/nPic${RIGHT_TAG}`;
