import { MC, playSound, playSoundSync, stopAllSounds, waitFor, waitForEvent, waitForTimer } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { SoundToolTip } from "../../utils/soundTooltip";
import { Navigation } from "../../navigation/navigation";

export class LunaparkMenu {
    root: MC;
    mouseX: number;
    private selectedGame: string;
    // private isLoaded: boolean = false;
    private moveRight: boolean = false;
    private iconSound: createjs.AbstractSoundInstance;
    // private bGuest: boolean = false;

    constructor(private isLoggedIn: boolean) {}

    loaded = async (mc: createjs.MovieClip) => {
        this.root = mc;
        (window as any).lunapark = this.root;
        this.root.visible = false;
        this.root.nextArrow.cursor = "pointer";
        this.root.backArrow.cursor = "pointer";
        this.root.menu.arrow_mc.cursor = "pointer";

        this.root.menu.train.gotoAndStop(0);
        this.root.menu.train.trainin.gotoAndStop(0);
        this.root.menu.lights.gotoAndStop(0);

        this.root.nextArrow.addEventListener("click", async () => {
            playSound("arrowsPop");
            this.moveRight = true;
            this.root.gotoAndPlay(38);
            await waitForEvent(this.root, "animationEnd");
            console.log("got back");
            this.root.backArrow.gotoAndPlay(0);
        });
        this.root.backArrow.addEventListener("click", async () => {
            playSound("arrowsPop");
            this.moveRight = false;
            this.root.backArrow.gotoAndPlay(20);
            this.root.gotoAndPlay(59);
            await waitForEvent(this.root, "animationEnd");
            console.log("got next");
            this.root.nextArrow.gotoAndPlay(0);
        });

        this.root.btnBack.addEventListener("click", () => {
            Navigation.openPage(routes.home);
            stopAllSounds();
        });
        SoundToolTip.addToolTip(this.root.btnBack, "back");

        this.root.Bar.out.addEventListener("click", () => {
            //exit game
            this.exitGame();
        });

        this.root.Bar.againbut.addEventListener("click", () => {
            //replaying game
            this.LoadGame(this.selectedGame);
        });
        this.root.Bar.sweetsbut.addEventListener("click", () => {
            this.LoadGame(routes.lunapark.sweets.menu);
        });
        this.root.Bar.onetwothreebut.addEventListener("click", () => {
            this.LoadGame(routes.lunapark.wheel.menu);
        });
        this.root.gotoAndStop(0);
        this.init(true);
    };

    init = async (isLoading: boolean) => {
        //true if needs to load game
        this.root.quality = "high";
        this.hideAndResetBar();
        this.root.btnBack.visible = true;
        this.setIsLoggedIn(this.isLoggedIn);
        const sound = playSoundSync("rekaSound", { loop: -1, duration: 500 });
        sound.volume = 0.4;
        if (isLoading) {
            await waitFor(() => this.root);
            this.root.visible = true;
            this.root.gotoAndPlay(0);
            await waitForEvent(this.root, "animationEnd");
            this.root.nextArrow.gotoAndPlay(0);
            this.addListeners();
        }
    };

    setIsLoggedIn = (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        //guest example arrow
        if (this.root) {
            this.root.menu.arrow_mc.visible = !this.isLoggedIn;
        }
    };

    hideAndResetBar = () => {
        this.toggleBar(false);
        this.toggleAgainBtn(false);
        this.toggleOneTwoThreeBtn(false);
        this.toggleAbcBtn(false);
        this.toggleMainCounter(false);
        this.toggleSweetsBtn(false);
    };

    addListeners = () => {
        for (var i = 1; i < 15; i++) {
            const iGame = `icon_game${i}`;
            var oGame = this.root.menu[iGame];
            if (oGame != null) {
                oGame.id = i;
                this.clickGame(oGame);
                this.hoverGameIcon(oGame);
                this.mouseOutGameIcon(oGame);
            }
        }
        // setting the arrow to act like button number 3
        this.root.menu.arrow_mc.addEventListener("click", () => {
            this.LoadGame(this.sGetGameByID(3));
            oGame = null;
        });
    };

    clickGame = (oGame: MC) => {
        oGame.addEventListener("click", (event: createjs.MouseEvent) => {
            this.selectedGame = this.sGetGameByID(event.target.id);
            this.LoadGame(this.selectedGame);
            oGame = null;
        });
    };

    hoverGameIcon = (oGame: MC) => {
        oGame.addEventListener("mouseover", async (event: createjs.MouseEvent) => {
            this.selectedGame = this.sGetGameByID(event.target.id);
            this.onMouseOver(oGame);
        });
    };

    onMouseOver = async (oGame: MC) => {
        switch (this.selectedGame) {
            case routes.lunapark.hammer:
                oGame.hammerBtn.hammer_anim.gotoAndPlay(0);
                await waitForEvent(this.root, "hammerLanded");
                this.iconSound = playSoundSync("hammer");
                break;
            case routes.lunapark.sweets.menu:
                oGame.popcorn.content.gotoAndPlay(0);
                oGame.popcorn.content.flakes.gotoAndPlay(0);
                this.iconSound = playSoundSync("popcorn");
                break;
            case routes.lunapark.trampoline:
                this.iconSound = playSoundSync("trampoline");
                break;
            case routes.lunapark.ghost_cave:
                this.root.menu.icon_game10.pumpkin.gotoAndPlay(0);
                this.iconSound = playSoundSync("ghost", { loop: -1 });
                break;
            case routes.lunapark.puppets:
                oGame.puppets.king.gotoAndStop(0); //hide king
                oGame.puppets.trees.gotoAndPlay(0); // tree appear
                await waitForEvent(this.root, "theaterEnd");
                oGame.puppets.king.gotoAndPlay(1); //king appear
                this.iconSound = playSoundSync("theater");
                break;
            case routes.lunapark.duck:
                oGame.duck.gotoAndPlay(0);
                await waitForEvent(this.root, "duckAppeared");
                this.iconSound = playSoundSync("duck");
                break;
            case routes.lunapark.mirrors:
                oGame.tent.mirror.gotoAndPlay(0);
                this.iconSound = playSoundSync("mirrors");
                break;
            case routes.lunapark.music_room:
                oGame.gazibo.music_anim.gotoAndPlay(0);
                this.iconSound = playSoundSync("musicRoom");
                break;
            case routes.lunapark.wheel.menu:
                for (let i = 1; i < 8; i++) oGame.wheel["seat" + i].gotoAndPlay(0);
                oGame.wheel.wheelLights.visible = false;
                this.root.menu.lights.gotoAndPlay(1);
                this.iconSound = playSoundSync("bigWheel");
                break;
            case routes.lunapark.horses:
                // this.root.menu.marygoroundBtn.visible = false;
                for (let i = 1; i < 6; i++) oGame.marygoround["hourse" + i].gotoAndPlay(0);
                oGame.marygoround.lights.gotoAndPlay(0);
                // oGame.marygoround.flag.gotoAndPlay(0);
                this.iconSound = playSoundSync("karusel");
                break;
            case routes.lunapark.balloons:
                oGame.baloons.gotoAndPlay(0);
                this.iconSound = playSoundSync("baloon");
                break;
            case routes.lunapark.roller_coaster:
                this.root.menu.train.gotoAndPlay(1);
                this.root.menu.train.trainin.gotoAndPlay(1);
                this.iconSound = playSoundSync("train");
                break;
            case routes.lunapark.rocket.menu:
                oGame.rocket.rocket_door.rocket_door_anim.gotoAndPlay(0);
                this.iconSound = playSoundSync("door");
                break;
            case routes.lunapark.abc.menu:
                oGame.gotoAndStop(1);
                this.iconSound = playSoundSync("tent");
                break;
        }
    };
    mouseOutGameIcon = (oGame: MC) => {
        oGame.addEventListener("mouseout", async (event: createjs.MouseEvent) => {
            this.selectedGame = this.sGetGameByID(event.target.id);
            if (this.selectedGame === routes.lunapark.wheel.menu) {
                this.root.menu.lights.gotoAndStop(0);
                oGame.wheel.wheelLights.visible = true;
            }
            if (this.selectedGame === routes.lunapark.roller_coaster) {
                this.root.menu.train.gotoAndStop(0);
                this.root.menu.train.trainin.gotoAndStop(0);
            }
            if (this.iconSound && this.iconSound.playState === createjs.Sound.PLAY_SUCCEEDED) {
                this.iconSound.stop();
            }
        });
    };

    exitGame = async () => {
        await stopAllSounds();
        this.showBackground();
        this.init(false);
        if (this.moveRight) {
            this.root.gotoAndStop(59);
            this.root.backArrow.gotoAndPlay(0);
        } else {
            this.root.gotoAndStop(16);
            this.root.nextArrow.gotoAndPlay(0);
        }
        Navigation.openPage(routes.lunapark.menu);
    };

    sGetGameByID = (nIndex: number) => {
        switch (nIndex) {
            case 1:
                return routes.lunapark.sweets.menu;
            case 2:
                return routes.lunapark.puppets;
            case 3:
                return routes.lunapark.wheel.menu;
            case 4:
                return routes.lunapark.mirrors;
            case 5:
                return routes.lunapark.music_room;
            case 6:
                return routes.lunapark.horses;
            case 7:
                return routes.lunapark.balloons;
            case 8:
                return routes.lunapark.roller_coaster;
            case 9:
                return routes.lunapark.abc.menu;
            case 10:
                return routes.lunapark.ghost_cave;
            case 11:
                return routes.lunapark.trampoline;
            case 12:
                return routes.lunapark.rocket.menu;
            case 13:
                return routes.lunapark.duck;
            case 14:
                return routes.lunapark.hammer;
        }
    };

    LoadGame = async (game: string) => {
        console.log("load", game);
        Navigation.openPage(game);
        await waitForTimer(0);
        this.mouseX = this.root.stage.mouseX;
        stopAllSounds();
        this.root.gotoAndStop("gamebg");
        this.root.btnBack.visible = false;
        this.toggleBar(true);
        this.showBackground();
    };

    hideBackground = () => {
        this.root.children.forEach((child: MC) => {
            if (child !== this.root.Bar) {
                child.visible = false;
            }
        });
    };

    showBackground = () => {
        this.root.children.forEach((child: MC) => {
            if (child !== this.root.btnBack) {
                child.visible = true;
            }
        });
    };

    toggleBar = (val: boolean) => {
        this.root.Bar.visible = val;
    };

    toggleMainCounter = (val: boolean) => {
        this.root.Bar.maincounter.visible = val;
    };
    toggleAgainBtn = (val: boolean) => {
        this.root.Bar.againbut.visible = val;
    };
    toggleOneTwoThreeBtn = (val: boolean) => {
        this.root.Bar.onetwothreebut.visible = val;
    };
    toggleAbcBtn = (val: boolean) => {
        this.root.Bar.abcbut.visible = val;
    };
    toggleSweetsBtn = (val: boolean) => {
        this.root.Bar.sweetsbut.visible = val;
    };

    updateMainCounter = (val: number) => {
        this.root.Bar.maincounter.counterValue.text = val;
    };
}
