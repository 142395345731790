import { MC } from "../../animate/utils";
import { DubbyBaseGame } from "./DubbyBaseGame";

export default class DubbyBedGame extends DubbyBaseGame {
    root: MC;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    hidePreloader: () => void;
    objects: string[] = [];
    genericObjects = ["Closet", "Bed", "Computer", "Shelves", "CompactDisk", "Window", "Chair", "Rug"];
    buildObjects = ["closet", "bed", "computer", "shelves", "CompactDisk", "window", "chair", "rug"];
    objectsName = ["closet", "bed", "computer", "shelves", "compact disk", "window", "chair", "rug"];

    loaded = () => {
        super.loaded();
        this.hidePreloader();
        this.root.visible = true;
    };
    beeMode = () => {
        this.objects = [...this.genericObjects];
        super.beeMode();
    };
    whatInMode = async () => {
        this.objects = [...this.genericObjects];
        super.whatInMode();
    };
    buildMode = async () => {
        this.objects = [...this.buildObjects];
        super.buildMode();
    };
}
