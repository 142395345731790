import { getCookie, setCookie } from "../utils/cookieUtils";
import { Status } from "./messages";

declare var cet: any;
var sessionTimerID: NodeJS.Timeout;

/**
 * Do login on server, and return status.
 * The server is responsibe for setting the cookies:
 * "sodMaya_gUserID"
 * @returns Status (the enum)
 */
export async function makeRequest(userInput: string, toDoInput: string, pattern: RegExp): Promise<Status> {
    const data = new URLSearchParams();
    data.set("UserInput", userInput);
    data.set("ToDoInput", toDoInput);

    try {
        const response = await fetch("/loginguest.aspx", {
            method: "post",
            body: data,
        });
        if (response.ok) {
            const text = await response.text();
            let match = text.match(pattern);
            if (match && match.groups.code) {
                return match.groups.code as Status;
            }
        }
        return "SomethingWrong";
    } catch (ignore) {
        return "SomethingWrong";
    }
}

/**
 * Do login on server by sending cetSession, and return status.
 * The server is responsibe for setting the cookies:
 * "sodMaya_gUserID"
 * @returns Status (the enum)
 */
export async function makeRequestVieCetSession(): Promise<Status> {
    const data = new URLSearchParams();
    data.set("UserInput", "DeveloperNothing"); //Just need to put something in here.
    data.set("ToDoInput", "sendXML"); //Probably important?

    try {
        const response = await fetch("/loginguest.aspx", {
            method: "post",
            body: data,
        });
        if (response.ok) {
            const text = await response.text();
            let match = text.match(/parent\.CheckFormState\('(?<code>.*)', '(.*)', '(.*)','.*'\)/);
            if (match && match.groups.code) {
                return match.groups.code as Status;
            }
        }
        return "SomethingWrong";
    } catch (ignore) {
        return "SomethingWrong";
    }
}

/**
 * Look in cookies and get gUserID.
 * @returns gUserID
 */
export function getUserId(): string {
    SetAutoSessionExtender();
    const sodMayaGUserID = getCookie("sodMaya_gUserID");
    return new URLSearchParams(sodMayaGUserID).get("userID");
}

/**
 * Notify server and clear cookies.
 */
export async function logout(): Promise<void> {
    RemoveAutoSessionExtender();

    const data = new URLSearchParams();
    data.set("ToDoInput", "ExitLogin");
    try {
        await fetch("'/Ajax/LogOut.aspx'");
        setCookie("sodMaya_gUserID", "");
        setCookie("sodmayaEntries", "");
        await cet.accessmanagement.LogoutFromIdp(document.location.origin);
    } catch (ignore) {}
}

function SetAutoSessionExtender() {
    sessionTimerID = setInterval(() => {
        cet.accessmanagement.ExtendSession();
    }, 10 * 60 * 1000);
}

function RemoveAutoSessionExtender() {
    clearInterval(sessionTimerID);
}
