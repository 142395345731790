import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AnimateCC from "../animate";
import { MC } from "../animate/utils";
import { CreationGame, ViewMode } from "./CreationGame";
import { MeterStaticPreloaderComponent } from "../loaders/meterStatic/MeterStaticPreloaderComponent";
import { useLettersContext } from "../context/LettersContext";
import { additionalSounds } from "./CreationSounds";
import {
    LETTERS,
    PREVIEW_DELETE_SCALE,
    PREVIEW_FULLSCREEN_SCALE,
    PREVIEW_MYWORKS_SCALE,
    VALID_EMAIL_CHARACTERS,
} from "./utils/constants";
import styles from "./Contacts.module.scss";

const MY_WORK_SHIFT_X = [750, 1450, 2140];
const CreationComponent: React.FC<{
    viewMode?: ViewMode;
    workId?: string;
    workIndex?: number;
    onLoaded?: () => void;
    setShouldRefresh?: (val: boolean) => void;
    preloader?: boolean;
}> = (props) => {
    const params = useParams<{ letterIndex: string; viewMode?: ViewMode; workId?: string }>();
    const [shouldPrint, setShouldPrint] = useState(null);
    const [showInput, setShowInput] = useState(null);
    const letterIndex = params.letterIndex;
    const [screenReady, setScreenReady] = useState(false);
    const { setLetterStatus } = useLettersContext();
    const viewMode = props.viewMode || params.viewMode;
    const workId = props.workId || params.workId;
    const isMyWorkMode = viewMode === "myWorks";
    const isPreviewMode = viewMode === "preview";
    const isDeleteMode = viewMode === "delete";
    const isMailMode = viewMode === "mail";
    const isContactsMode = viewMode === "contacts";
    const [nameText, setNameText] = useState("");
    const [emailText, setEmailText] = useState("");
    const emailInput = useRef(null);
    const [showSelectImages, setShowSelectImages] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [isRTL, setIsRTL] = useState(true);
    const [isParentsInfo, setIsParetsInfo] = useState(false);
    //  const [text2, setText2] = useState("");

    useEffect(() => {
        document.querySelector("body").classList.add("creation_corner");

        return () => {
            document.querySelector("body").classList.remove("creation_corner");
        };
    }, []);

    const game = React.useRef(
        new CreationGame(
            letterIndex ? parseInt(letterIndex) + 1 + "" : null,
            setLetterStatus,
            setScreenReady,
            viewMode,
            workId,
            setShouldPrint,
            setShowInput,
            setNameText,
            setEmailText,
            props.setShouldRefresh,
            setShowSelectImages,
            setShowDeleteAlert,
            setIsParetsInfo
        )
    );
    const getLeft = useCallback(
        (start: number, space: number, space2: number) => {
            switch (props.workIndex) {
                case 0:
                    return start + space2;
                case 1:
                    return start + space;
                case 2:
                    return start;
            }
        },
        [props.workIndex]
    );
    const getPreloaderStyle = useCallback(() => {
        if (!isMyWorkMode || !props.workId) return null;
        let canvas = document.querySelectorAll("[id^='MyWorks']")[0] as HTMLCanvasElement;
        if (!canvas) return;
        const height = parseInt(canvas.style.height);
        const width = parseInt(canvas.style.width);
        const top = parseInt(canvas.style.top) + height * 0.426;
        const prewidth = 0.164 * width;
        const preHeight = 0.149 * height;
        const startX = parseInt(canvas.style.left) + width * 0.217;
        const space = prewidth + 0.04 * width;
        const space2 = space + prewidth + 0.0331 * width;
        return {
            style: {
                width: prewidth,
                height: preHeight,
                top: top,
                left: getLeft(startX, space, space2),
                position: "absolute",
            },
        };
    }, [isMyWorkMode, props.workId, getLeft]);

    const getProps = () => {
        if (isMyWorkMode) {
            return {
                scale: PREVIEW_MYWORKS_SCALE,
                shiftX: MY_WORK_SHIFT_X[2 - props.workIndex],
                shiftY: 1040,
                style: { pointerEvents: "none" },
            };
        } else if (isPreviewMode || isMailMode) {
            return {
                scale: PREVIEW_FULLSCREEN_SCALE,
                shiftX: -35,
                shiftY: -65,
            };
        } else if (isDeleteMode) {
            return {
                scale: PREVIEW_DELETE_SCALE,
                shiftX: 25,
                shiftY: -25,
            };
        }
    };

    const getInputStyle = useCallback(
        (inputNum: number) => {
            const list = document.querySelectorAll("[id^='creation_corner']");
            const canvas = list[list.length - 1] as HTMLCanvasElement;
            if (!canvas) return;
            const height = parseInt(canvas.style.height);
            const width = parseInt(canvas.style.width);
            const top = parseInt(canvas.style.top);
            const left = parseInt(canvas.style.left);
            const newHeight = height * 0.035;
            const newWidth = width * 0.258;
            const newTop = isContactsMode
                ? [top + height * 0.45, top + height * 0.495]
                : [top + height * 0.316, top + height * 0.37];
            const newLeft = left + width * 0.2;
            const fontSize = ((height / 1000) * 32).toString() + "px";
            return {
                width: newWidth,
                height: newHeight,
                top: newTop[inputNum],
                left: newLeft,
                fontSize: fontSize,
            };
        },
        [isContactsMode]
    );

    const [input1Style, setInput1Style] = useState(getInputStyle(0));
    const [input2Style, setInput2Style] = useState(getInputStyle(1));
    const [preloaderStyle, setPreloaderStyle] = useState(getPreloaderStyle());

    useEffect(() => {
        shouldPrint && game.current.startPrint();
    }, [shouldPrint]);
    useEffect(() => {
        showInput && game.current.shouldShowInput();
    }, [showInput]);
    useEffect(() => {
        nameText && game.current.updateNameText(nameText);
    }, [nameText]);
    useEffect(() => {
        emailText && game.current.updateEmailText(emailText);
    }, [emailText]);

    useEffect(() => {
        const resize_listener = () => {
            setPreloaderStyle(getPreloaderStyle());
            setInput1Style(getInputStyle(0));
            setInput2Style(getInputStyle(1));
        };
        window.addEventListener("resize", resize_listener);
        if (screenReady) {
            setPreloaderStyle(getPreloaderStyle());
            setInput1Style(getInputStyle(0));
            setInput2Style(getInputStyle(1));
        }
        return () => {
            window.removeEventListener("resize", resize_listener);
        };
    }, [getPreloaderStyle, getInputStyle, screenReady]);

    useEffect(() => {
        const creation_game = game.current;
        return () => {
            creation_game.unload();
        };
    }, []);

    const validText = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const c = e.key;
        game.current.hideNotValidAlerts();
        if (!VALID_EMAIL_CHARACTERS.includes(c) && c) {
            if (c.length === 1) {
                game.current.showNotValidAlert(c);
            }
            if (c !== "Backspace") e.preventDefault();
        }
    };
    const changeNameText = (value: string) => {
        setIsRTL(LETTERS.indexOf(value.charAt(0)) !== -1);
    };

    return (
        <>
            {(!screenReady || props.preloader) && <MeterStaticPreloaderComponent {...preloaderStyle} />}
            {!isMyWorkMode && !isDeleteMode && !props.preloader && !isContactsMode && (
                <AnimateCC
                    base="/creation_corner"
                    animationName="creation_corner"
                    onLoaded={game.current.prepareForPrint}
                    print
                />
            )}
            {!props.preloader && !isParentsInfo && (
                <AnimateCC
                    base="/creation_corner"
                    animationName="creation_corner"
                    onLoaded={(mc: MC) => {
                        props.onLoaded?.();
                        game.current.loaded(mc);
                    }}
                    changeFramerate={true}
                    additionalSounds={additionalSounds}
                    frameWidth={800}
                    frameHeight={600}
                    {...getProps()}
                />
            )}
            {showInput && !isParentsInfo && (
                <>
                    <input
                        name="nameInput"
                        className={styles.input}
                        style={{
                            position: "absolute",
                            direction: isRTL ? "rtl" : "ltr",
                            ...input1Style,
                        }}
                        value={nameText}
                        onChange={(e) => {
                            game.current.updateNameText(e.target.value);
                            changeNameText(e.target.value);
                        }}
                        autoComplete="off"
                        spellCheck="false"
                        maxLength={22}
                        onClick={() => game.current.hideNotValidAlerts()}
                    />
                    <input
                        name="emailInput"
                        className={styles.input}
                        style={{ position: "absolute", direction: "ltr", ...input2Style }}
                        value={emailText}
                        onKeyDown={validText}
                        onChange={(e) => {
                            const v = e.target.value;
                            setEmailText(v);
                            game.current.updateEmailText(v);
                        }}
                        autoComplete="off"
                        spellCheck="false"
                        ref={emailInput}
                        onClick={() => game.current.hideNotValidAlerts()}
                    />
                </>
            )}
            <AnimateCC
                base="/creation_corner/select_image"
                animationName="select_image"
                onLoaded={(mc: MC) => {
                    game.current.selectImageLoaded(mc);
                }}
                visible={showSelectImages}
            />
            <AnimateCC
                base="/creation_corner/delete_alert"
                animationName="delete_alert"
                onLoaded={(mc: MC) => {
                    game.current.deleteAlert(mc);
                }}
                visible={showDeleteAlert}
            />
        </>
    );
};
export default CreationComponent;
