import React from "react";
import { getLetterBg } from "./LettersUtils";
import { MC } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { airplainEntersSound, airplainExitSound } from "./sounds_letterActivities";
import AnimateCC from "../../animate";
import { LetterActivitiesdGame } from "./LetterActivitiesGame";
import { useParams } from "react-router-dom";

export const AirplaneEntersComponent: React.FC<{ frame: LetterActivitiesdGame; letterNumber: number }> = ({
    frame,
    letterNumber,
}) => {
    const { action } = useParams<{ action: string }>();

    return (
        <AnimateCC
            key="airplane"
            base="/letters_island/activities_enter"
            animationName={`${getLetterBg(letterNumber)}`}
            onLoaded={(mc: MC) =>
                frame.airplaneEntersLoaded(
                    mc,
                    action === "enter" ? routes.letters.activities.draw : routes.letters.activities.menu,
                    action === "enter"
                )
            }
            additionalSounds={action === "enter" ? airplainEntersSound(letterNumber) : airplainExitSound(letterNumber)}
            frameWidth={1200}
            frameHeight={900}
            scale={1.12}
            shiftX={107}
            shiftY={125}
        />
    );
};
