import React, { useState } from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { PreloaderComponent } from "../../../loaders/PreloaderComponent";
import { HanukaGame } from "./HanukaGame";

const HanukaGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new HanukaGame());
    const directLink = new URLSearchParams(window.location.search).get("path");
    const [fromDirectLink] = useState(directLink);
    const [screenReady, setScreenReady] = useState(false);

    return (
        <>
            {fromDirectLink && !screenReady && <PreloaderComponent name="plain" />}
            <AnimateCC
                base="/special_games/hanuka"
                animationName="hanuka"
                onLoaded={(mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc);
                }}
            />
        </>
    );
};
export default HanukaGameComponent;
