import React, { useCallback, useState } from "react";
import {
    getLetterMenuStatus as getLetterMenuStatusApi,
    getReviewMenuStatus,
    setLetterDone,
    setReviewGameCompleted,
} from "../api/lettersIsland";

interface LettersContextValue {
    setLetterStatus: (letter: number, activity: string) => void;
    letterMenuData: any;
    setLetterMenuData: (val: any) => void;
    getLetterMenuStatus: (letter: number) => void;
    gameMenuData: any;
    setGameStatus: (unit: number, activity: string) => void;
    getGamesStatus: (unit: string) => void;
    setGameMenuData: (val: any) => void;
    completedLetter: number;
    setCompletedLetter: (val: number) => void;
}

function useLetterContextValue(): LettersContextValue {
    const [letterMenuData, setLetterMenuData] = useState<any>({});
    const [gameMenuData, setGameMenuData] = useState<any>({});
    const [completedLetter, setCompletedLetter] = useState(null);

    const setGameStatus = async (unit: number, activity: string) => {
        const activityKey = activity.length > 1 && activity !== "test" && activity !== "quiz" ? activity[0] : activity;
        setGameMenuData({ ...gameMenuData, [unit]: { ...gameMenuData[unit], [activityKey]: true } });
        setReviewGameCompleted(unit, activity);
        return;
    };

    const getGamesStatus = useCallback((unit: string) => {
        getReviewMenuStatus(unit).then((res) => {
            const retVal: any = { [unit]: {} };
            res.forEach((val, index) => {
                if (index < res.length - 1) {
                    retVal[unit][index + 1] = val;
                } else {
                    retVal[unit].test = val;
                }
            });
            setGameMenuData((value: any) => ({ ...value, ...retVal }));
        });
    }, []);

    const getLetterMenuStatus = useCallback((letter: number) => {
        getLetterMenuStatusApi(letter).then((res) => {
            setLetterMenuData(res);
        });
    }, []);

    const setLetterStatus = (letter: number, activity: string) => {
        const activities = ["lesson", "video", "word", "write"];
        let counter = 0;
        Object.keys(letterMenuData).forEach((act: any) => {
            if (letterMenuData[act] && activities[act] !== activity) {
                counter++;
            }
        });
        if (counter === 3 && !letterMenuData[activities.indexOf(activity)]) {
            setCompletedLetter(letter);
        } else {
            setCompletedLetter(null);
        }
        setLetterMenuData({ ...letterMenuData, [activities.indexOf(activity)]: true });
        setLetterDone(letter, activity);
    };

    return {
        setLetterStatus,
        letterMenuData,
        setLetterMenuData,
        getLetterMenuStatus,
        gameMenuData,
        setGameMenuData,
        setGameStatus,
        getGamesStatus,
        completedLetter,
        setCompletedLetter,
    };
}

const LettersContext = React.createContext<LettersContextValue>({} as LettersContextValue);

export const LettersContextProvider: React.FC = (props) => {
    const value = useLetterContextValue();

    return <LettersContext.Provider value={value}>{props.children}</LettersContext.Provider>;
};

export const useLettersContext = () => React.useContext(LettersContext);
