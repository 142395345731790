import { MC, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

export class AbcMenu {
    private root: MC;
    private isUnloaded: boolean = false;

    constructor(private frame: LunaparkMenu) {}

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).abc = this.root;
        this.root.gotoAndStop(9);
        this.initLetters();
        this.frame?.hideBackground();
        this.animateLettersIn();
        this.resetBar();
        this.addBarListeners();
    };

    unload = () => {
        this.isUnloaded = true;
    };

    initLetters = () => {
        for (let i = 1; i <= 22; i++) {
            const letterName = "let" + i;
            const letter = this.root.letter_sq[letterName];
            letter.visible = false;
            letter.gotoAndStop(0);
            this.letterListeners(letter, letterName);
        }
    };
    animateLettersIn = async () => {
        for (let i = 1; i <= 22; i++) {
            if (this.isUnloaded) {
                break;
            }
            const letter = this.root.letter_sq["let" + i];
            letter.gotoAndStop(0);
            letter.visible = true;
            await waitForTimer(50);
        }
    };

    animateLettersOut = async () => {
        for (let i = 1; i <= 22; i++) {
            if (this.isUnloaded) {
                break;
            }
            const letter = this.root.letter_sq["let" + i];
            letter.visible = false;
            await waitForTimer(50);
        }
    };

    letterListeners = (letter: MC, letterName: string) => {
        letter.addEventListener("mouseover", async () => {
            letter.gotoAndPlay(1);
            await waitForEvent(this.root, "animationEnd");
            letter.gotoAndStop(5);
        });
        letter.addEventListener("mouseout", () => {
            letter.gotoAndPlay(6);
        });
        letter.addEventListener("click", () => {
            this.LoadGame(letterName);
        });
    };

    addBarListeners = () => {
        this.root.Bar.abcbut.cursor = "pointer";
        this.root.Bar.out.addEventListener("click", () => {
            stopAllSounds();
            this.frame.exitGame();
        });
        this.root.Bar.abcbut.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.lunapark.abc.menu);
            this.toggleAbcbut(false);
            this.toggleInner(true);
            this.animateLettersIn();
        });
    };

    resetBar = () => {
        this.root.Bar.againbut.visible = false;
        this.root.Bar.abcbut.visible = false;
        this.root.Bar.maincounter.visible = false;
    };

    toggleInner = async (val: boolean) => {
        this.root.letter_sq.board.visible = val;
    };

    LoadGame = async (name: string) => {
        this.toggleAbcbut(true);
        this.toggleInner(true);
        this.animateLettersOut();
        setTimeout(() => {
            switch (name) {
                case "let1":
                    Navigation.openPage(routes.lunapark.abc.nose);
                    break;
                case "let2":
                    Navigation.openPage(routes.lunapark.abc.egg);
                    break;
                case "let3":
                    Navigation.openPage(routes.lunapark.abc.icecream);
                    break;
                case "let4":
                    Navigation.openPage(routes.lunapark.abc.bee);
                    break;
                case "let5":
                    Navigation.openPage(routes.lunapark.abc.upsidedown);
                    break;
                case "let6":
                    Navigation.openPage(routes.lunapark.abc.curtain);
                    break;
                case "let7":
                    Navigation.openPage(routes.lunapark.abc.glass);
                    break;
                case "let8":
                    Navigation.openPage(routes.lunapark.abc.bulb);
                    break;
                case "let9":
                    Navigation.openPage(routes.lunapark.abc.rocket);
                    break;
                case "let10":
                    Navigation.openPage(routes.lunapark.abc.cake);
                    break;
                case "let11":
                    Navigation.openPage(routes.lunapark.abc.star);
                    break;
                case "let12":
                    Navigation.openPage(routes.lunapark.abc.heart);
                    break;
                case "let13":
                    Navigation.openPage(routes.lunapark.abc.mandoline);
                    break;
                case "let14":
                    Navigation.openPage(routes.lunapark.abc.tiger);
                    break;
                case "let15":
                    Navigation.openPage(routes.lunapark.abc.window);
                    break;
                case "let16":
                    Navigation.openPage(routes.lunapark.abc.tree);
                    break;
                case "let17":
                    Navigation.openPage(routes.lunapark.abc.penguin);
                    break;
                case "let18":
                    Navigation.openPage(routes.lunapark.abc.yellow);
                    break;
                case "let19":
                    Navigation.openPage(routes.lunapark.abc.kaleidoscope);
                    break;
                case "let20":
                    Navigation.openPage(routes.lunapark.abc.robot);
                    break;
                case "let21":
                    Navigation.openPage(routes.lunapark.abc.sun);
                    break;
                case "let22":
                    Navigation.openPage(routes.lunapark.abc.chicken);
                    break;
            }
        }, 700);
    };
    toggleAbcbut = (val: boolean) => {
        this.root.Bar.abcbut.visible = val;
    };
}
