import { MC, waitForEvent, playSound, asyncTween } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game16 extends BaseGame {
    gameEnd: any;
    game: any;
    helpBtn: any;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.gameEnd = gameEnd;
        this.game = game;
        this.helpBtn = helpBtn;
        game.visible = true;
        game.x = 300;
        game.gotoAndStop(0);
        const btn = game.words;
        game.gotoAndPlay(1);
        await waitForEvent(game, "questionEnd");
        if (!this.shouldPlay) return;
        console.log("here");

        helpBtn(false);
        this.addEvents(btn.bag);
        this.addEvents(btn.crown);
        this.addEvents(btn.pacifier);
    };
    addEvents = (obj: MC) => {
        obj.gotoAndStop(0);
        obj.cursor = "pointer";
        obj.addEventListener("click", async () => {
            obj.gotoAndStop(1);
            await this.checkAnswer(obj);
            obj.gotoAndStop(0);
        });
        obj.addEventListener("rollover", () => {
            obj.gotoAndStop(1);
        });
        obj.addEventListener("rollout", () => {
            obj.gotoAndStop(0);
        });
    };
    checkAnswer = async (answer: MC) => {
        this.helpBtn(true);
        if (answer.name === "bag") {
            this.game.gotoAndPlay(190);
            await waitForEvent(this.game, "gameEnd");
            if (!this.shouldPlay) return;
            await asyncTween(this.game, { alpha: 0 }, 300);
            if (!this.shouldPlay) return;
            this.gameEnd();
        } else {
            await playSound("wrong");
            if (!this.shouldPlay) return;
            this.helpBtn(false);
        }
    };
}
