import React, { useEffect } from "react";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import { generatePath, RouteProps } from "react-router";
import { useUserContext } from "../context/UserContext";
import LoginComponent from "../loginnew/LoginComponent";
import { routes } from "./routesPath";
import { Navigation } from "./navigation";

export const SodRoute: React.FC<RouteProps> = ({ children, render, component, ...rest }) => {
    const { isLoggedIn, permissions } = useUserContext();
    const { pathname } = useLocation();

    const wheel = useRouteMatch(routes.lunapark.wheel.menu);
    const sweetRhymes = useRouteMatch(routes.letters.sweets);
    const allLettersSong = useRouteMatch(routes.letters.video);
    const englishTime = useRouteMatch<{ gameType: string }>(routes.english_time.game);
    const letterActivity = useRouteMatch<{ letterIndex: string }>(routes.letters.activities.menu);
    const fancyWord = useRouteMatch<{ letterIndex: string }>(routes.letters.activities.fancy_word);
    const mathWizard = useRouteMatch(routes.math.wizard);
    const creation_mail = useRouteMatch<{ viewMode: string; workId: string }>(routes.creation);
    const bible_noa = useRouteMatch(generatePath(routes.bible_stories.story_menu.menu, { type: 3 }));
    const bible_noa_game = useRouteMatch(routes.bible_stories.story_menu.noach_game);
    const musicTrain = useRouteMatch(routes.music.train);

    const isGuestAllowed =
        wheel ||
        sweetRhymes ||
        allLettersSong ||
        englishTime?.params?.gameType === "face" ||
        mathWizard ||
        musicTrain ||
        letterActivity?.params?.letterIndex === "0" ||
        fancyWord?.params?.letterIndex === "1" ||
        creation_mail?.params?.viewMode === "mail" ||
        (bible_noa && bible_noa.isExact) ||
        bible_noa_game;

    const hasPermission = permissions && (permissions as any)[pathname?.split("/")[1]];
    const shouldReturnToHome = hasPermission !== undefined && !hasPermission;

    useEffect(() => {
        shouldReturnToHome && Navigation.openPage(routes.home);
    }, [shouldReturnToHome]);

    return (
        !(isLoggedIn && !permissions) &&
        !shouldReturnToHome && (
            <Route
                {...rest}
                render={(props) => {
                    if (isLoggedIn || isGuestAllowed) {
                        if (children) {
                            return children;
                        }
                        if (component) {
                            return React.createElement(component, props);
                        }
                        return render ? render(props) : null;
                    } else {
                        return <LoginComponent loginAfterLocation={props.location.pathname} />;
                    }
                }}
            />
        )
    );
};
