const additionalSounds = [
    {
        src: "sounds/ball.mp3",
        id: "ball",
    },
    {
        src: "sounds/up.mp3",
        id: "up",
    },
];
export default additionalSounds;
