import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { DogGame } from "./DogGame";

const DogGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new DogGame());
    return (
        <>
            <AnimateCC
                base="/special_games/dog"
                animationName="dog"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                }}
            />
        </>
    );
};
export default DogGameComponent;
