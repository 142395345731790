import { MC, playSound, setupStage, waitForEvent } from "../../../animate/utils";
export class NumberEightGame {
    private root: MC;
    private count = 0;
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).EightGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.gotoAndStop(5);
        this.root.count.gotoAndStop(0);
        this.root.hammer.gotoAndStop(0);
        for (let i = 1; i <= 8; i++) {
            this.root["bottle" + i].gotoAndStop(0);
            this.root["bottle" + i].pop = false;
        }
        this.root.addEventListener("tick", (e: any) => {
            this.moveHammer();
        });
        this.root.hammer.btn.addEventListener("click", () => {
            this.clickHammer();
        });
    };
    moveHammer = () => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        this.root.hammer.x = localMouse.x;
        this.root.hammer.y = localMouse.y;
    };
    clickHammer = async () => {
        this.root.hammer.gotoAndPlay(0);
        await waitForEvent(this.root, "clicking");
        this.root.hammer.gotoAndStop(0);
        for (let i = 1; i <= 8; i++) {
            if (this.isIntersecting(this.root["bottle" + i])) {
                this.popBottle(this.root["bottle" + i]);
            }
        }
    };
    popBottle = async (bottle: MC) => {
        if (!bottle.pop) {
            bottle.pop = true;
            bottle.gotoAndPlay(0);
            playSound("pop");
            await waitForEvent(bottle, "count");
            this.count += 1;
            this.root.count.gotoAndStop(this.count);
            bottle.gotoAndStop(19);
            if (this.count === 8) {
                this.frame.root.Bar.againbut.visible = true;
            }
        }
    };
    isIntersecting = (bottle: MC) => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        const bottleAreaX = bottle.x;
        const bottleAreaY = bottle.y - 119.2;
        const spotAreaX = localMouse.x + 1;
        const spotAreaY = localMouse.y + 1.05;
        const bottleRect = new createjs.Rectangle(bottleAreaX, bottleAreaY, 31, 119.2);
        const spotRect = new createjs.Rectangle(spotAreaX, spotAreaY, 2.2, 2.55);

        return bottleRect.intersects(spotRect);
    };
}
