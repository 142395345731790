import { MC } from "../../animate/utils";

export default class MathGameBase {
    root: MC;

    helpNum: number;
    bgSound: createjs.AbstractSoundInstance;
    onGame = true;
    onInnerGame = false;
    isChooserDown: boolean;
    openMenu: boolean = false;
    isFirstGame: boolean = true;

    openSelector: (isNotRedPhone?: boolean) => void;
    addPrize: (prizeName: string, y?: number, pos?: number) => void;
    cleanPrizes: (fromRest?: boolean) => void;
    enableHelp: () => void;
    disableHelp: () => void;
    initChooser: (levels: number) => void;
    ask: () => void;

    backToOuterMenu() {}
    loaded() {}
    unload() {}
    handleLevel(level?: number) {}
    disableGame() {}
    chooserUp() {}
    chooserDown() {}
    name: string;
}
