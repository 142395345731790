import React from "react";
import styles from "./MeterStaticPreloader.module.scss";

export const MeterStaticPreloaderComponent: React.FC<{ style?: any }> = ({ style }) => {
    return (
        <div className={`${styles.preloader}`} style={style}>
            <img src={require("./meter.jpg")} alt="loading..." />
        </div>
    );
};
