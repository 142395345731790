import React from "react";
import { Switch } from "react-router-dom";
import AnimateCC from "../../animate";
import { SodRoute } from "../../navigation/PrivateRoot";
import { routes } from "../../navigation/routesPath";
import BiblePuzzleComponent from "./BiblePuzzleComponent";
import { PuzzleFrame } from "./PuzzleFrame";
import { additionalSounds } from "./PuzzleSounds";
const PuzzleFrameComponent: React.FC<{ type: string }> = ({ type }) => {
    const game = React.useRef(new PuzzleFrame(type));
    return (
        <>
            <Switch>
                <SodRoute path={routes.bible_stories.story_menu.puzzle.game}>
                    <BiblePuzzleComponent frame={game.current} type={type} />
                </SodRoute>
            </Switch>
            <AnimateCC
                base="/bible_stories/puzzle_frame"
                animationName="puzzle_frame"
                onLoaded={game.current.loaded}
                additionalSounds={additionalSounds}
            />
        </>
    );
};
export default PuzzleFrameComponent;
