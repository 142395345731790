import React from "react";
import AnimateCC from "../../../animate";
import { BigWheelGame } from "../BigWheelGame";
import { NumberThreeGame } from "./NumberThreeGame";

const NumberThreeComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberThreeGame(frame));

    return (
        <AnimateCC
            //base="/lunapark/sweets/popcorn"
            base="/lunapark/big_wheel/number_three"
            animationName="number_three"
            onLoaded={game.current.loaded}
        />
    );
};
export default NumberThreeComponent;
