import { loadSound } from "../../animate/sound_utils";
import {
    asyncTween,
    MC,
    playSound,
    playSoundSync,
    setupStage,
    stopAllSounds,
    waitFor,
    waitForEvent,
} from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
const STAGE_WIDTH = 800;
const LEFT_LIMIT = 429;
const RIGHT_LIMIT = 775;

export class NoachGame {
    private root: MC;
    private animalScrollArr: MC[] = [];
    private randomAnimals: number[] = [];
    private nCurQuestion = -1;
    private arrData: Animal[] = [];
    private arrScroll: Animal[] = [];
    private arrOrder: any = [];
    private removedChild: MC = [];
    private answer: Animal;
    private windowNum: number;
    private finished_instructions: boolean;
    private firstGame: boolean = true;
    private bgSound: createjs.AbstractSoundInstance;
    private localMouse: number;
    private start = false;
    private winArr: MC = [];
    private maxStep = 8;
    private upWindow = ["tortoise", "frog", "parrot", "squirrel", "chicken", "duck", "ping", "rabbit"];
    private allWindow = [
        "hipo",
        "tortoise",
        "flamingo",
        "yaen",
        "deer",
        "lion",
        "pil",
        "sheep",
        "frog",
        "parrot",
        "squirrel",
        "bear",
        "chicken",
        "karnaf",
        "horse",
        "tavas",
        "duck",
        "gamal",
        "kengeroo",
        "hasida",
        "giraffe",
        "ping",
        "gorilla",
        "zebra",
        "rabbit",
    ];
    private isVisible = true;
    constructor(private frame: any) {}
    loaded = async (mc: MC) => {
        this.root = mc;
        (window as any).game = this;
        stopAllSounds();
        this.overListener();
        await waitFor(() => this.root);
        await waitFor(() => this.frame);
        setupStage(this.frame.root, this.root);
        window.addEventListener("visibilitychange", this.visibiltyChange);
        this.init();
        this.root.gotoAndStop(0);
        this.setUpView();
        this.timerListener();
        this.initFrame();
        await this.startAnimation();
        !this.start && this.startGame();
    };

    initFrame = () => {
        this.frame.togglePrint(false);
        this.frame.toggleHelp(true);
        this.frame.toggleReplay(false);
        this.initHelpBtn();
    };

    startAnimation = async () => {
        //
        this.frame.disableBtn(this.frame.root.frame.btnHelp);
        this.finished_instructions = false;
        this.root.gotoAndPlay(1);
        await this.shipSounds();
        await waitForEvent(this.root, "startGame");
        await this.explainSound();
        this.root.gotoAndStop("startGame");
    };

    timerListener = () => {
        this.root.addEventListener("tick", () => {
            if (!this.animalScrollArr[0]) return;
            if (this.localMouse) {
                this.localMouse = null;
                this.maxStep = 45;
            }
            const position = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            const n = (position.x - 500) / STAGE_WIDTH;
            const step = this.maxStep * n;
            this.animalScrollArr.forEach((item) => {
                item.x -= step;
                if (item.x > RIGHT_LIMIT) {
                    item.x -= 1204;
                } else if (item.x < -LEFT_LIMIT) {
                    item.x += 1204;
                }
            });
        });
    };
    randomNumber = (length: number, arr: any[]) => {
        let random;
        while (!random) {
            const i = getRandomNumber(0, length);
            if (!this.randomAnimals.includes(arr[i])) {
                this.randomAnimals.push(arr[i]);
                random = i;
                return i;
            }
        }
    };

    hideWindows = (ark: MC) => {
        let i = 0;
        while (ark["win" + i] !== undefined) {
            ark["win" + i].left.alpha = 0;
            ark["win" + i].right.alpha = 0;
            i++;
        }
    };
    setDataArray = async () => {
        this.arrData = [];
        this.arrScroll = [];
        for (let i = 0; i < 7; i++) {
            const arr = i ? this.allWindow : this.upWindow;
            const num = await this.randomNumber(arr.length - 1, arr);
            const name = arr[num];
            let ques = new Animal();
            let ans = new Animal();
            ques.name = ans.name = name;
            if (getRandomNumber(0, 1)) {
                ans.sex = "female";
                ques.sex = "male";
            } else {
                ans.sex = "male";
                ques.sex = "female";
            }
            if (getRandomNumber(0, 1)) {
                ans.side = "left";
                ques.side = "right";
            } else {
                ans.side = "right";
                ques.side = "left";
            }
            this.arrData[i] = ques;
            this.arrScroll[i] = ans;
        }
    };

    showQuestion = async (n: number) => {
        let o: Animal = this.arrData[n];
        let tag = o.name + (o.sex === "male" ? "_m" : "_f");
        let clip = this.winArr[n][o.side];
        clip.gotoAndStop(tag);
        await asyncTween(clip, { alpha: 1 }, 1000);
        this.answer = o;
        this.windowNum = n;
    };
    showNextQuestion = async () => {
        if (++this.nCurQuestion < 5) {
            const order = this.arrOrder[getRandomNumber(0, this.arrOrder.length - 1)];
            this.arrOrder.splice(this.arrOrder.indexOf(order), 1);
            this.showQuestion(order);
        } else {
            this.reset();
        }
    };
    reset = async () => {
        this.bgSound.stop();
        this.arrData = [];
        this.arrOrder = [];
        this.arrScroll = [];
        this.randomAnimals = [];

        this.animalScrollArr.forEach((a: MC) => {
            this.root.removeChild(a);
        });
        this.animalScrollArr = [];
        this.nCurQuestion = -1;

        this.root.gotoAndPlay("endGame");
        this.initEndArk();
        await this.shipSounds();
        await waitForEvent(this.root, "startGame");
        !this.start && this.startGame();
    };
    initEndArk = () => {
        for (let i = 7; i < 14; i++) {
            this.winArr[i].left.gotoAndStop(this.winArr[i - 7].left.currentFrame);
            this.winArr[i].right.gotoAndStop(this.winArr[i - 7].right.currentFrame);
            if (this.winArr[i - 7].right.alpha) {
                this.winArr[i].right.alpha = 1;
                this.winArr[i].left.alpha = 1;
                this.winArr[i - 7].left.alpha = 0;
                this.winArr[i - 7].right.alpha = 0;
            } else {
                this.winArr[i].right.alpha = 0;
                this.winArr[i].left.alpha = 0;
            }
        }
    };

    createStartWin = (lib: string, i: string, x: number, y: number, ark: MC) => {
        let win = new this.root.lib[lib]();
        if (x) {
            win.x = x;
            win.y = y;
        }
        ark.addChild(win);
        win.left.alpha = 0;
        win.right.alpha = 0;
        this.winArr.push(win);
    };

    setUpView = () => {
        this.hideWindows(this.root.gameArk);
    };
    startGame = async () => {
        this.start = true;
        await this.setDataArray();
        this.arrOrder = [];
        for (let i = 0; i < 5; i++) {
            this.arrOrder[i] = i;
            if (i === 1) {
                if (getRandomNumber(0, 1)) {
                    this.arrOrder[1] = 1;
                    this.arrOrder[2] = 6;
                } else {
                    this.arrOrder[1] = 5;
                    this.arrOrder[2] = 2;
                }
                i = 2;
            }
        }

        this.createScrollAnimals();
        if (this.firstGame) {
            await waitFor(() => this.finished_instructions);
            this.animalScrollArr.forEach((a: MC) => {
                a.mouseEnabled = true;
            });
            this.firstGame = false;
        } else {
            this.bgSound.play();
            this.removedChild.forEach((c: MC) => {
                this.root.gameArk.addChild(c);
            });
            this.hideWindows(this.root.gameArk);
        }

        this.showNextQuestion();
        this.start = false;
    };
    createScrollAnimals = () => {
        for (let i = 0; i < 7; i++) {
            let o: Animal = this.arrScroll[i];
            const animal = new this.root.lib.ScrollItem();
            animal.scale = 0.8;
            let tag = o.name + (o.sex === "male" ? "_m" : "_f");
            animal.gotoAndStop(tag);
            this.root.addChild(animal);
            animal.x = i * 172;
            animal.y = 350;
            this.animalScrollArr.push(animal);
            animal.mouseEnabled = this.finished_instructions ? true : false;
            animal.addEventListener("mousedown", async () => {
                if (o.name === this.answer.name) {
                    playSound("yes");
                    let tag = this.answer.name + (this.answer.sex === "female" ? "_m" : "_f");
                    const side = this.answer.side === "left" ? "right" : "left";
                    let clip = this.winArr[this.windowNum][side];
                    clip.gotoAndStop(tag);
                    animal.visible = false;
                    await asyncTween(clip, { alpha: 1 }, 1000);

                    this.animalScrollArr.splice(this.animalScrollArr.indexOf(animal), 1);
                    this.showNextQuestion();
                } else {
                    playSound("no");
                }
            });
            animal.cursor = "pointer";
        }
    };

    initHelpBtn = () => {
        const helpBtn = this.frame.root.frame.btnHelp;
        helpBtn.addEventListener("click", async () => {
            const sound = await loadSound("/bible_stories/noach_game/sounds/noa_help.mp3");
            sound.play();
        });
    };

    overListener = () => {
        this.root.addEventListener("mouseover", () => {
            this.localMouse = this.root.globalToLocal(this.root.stage.mouseX, 0);
            this.root.removeEventListener("mouseover");
        });
    };

    shipSounds = async () => {
        await waitForEvent(this.root, "shipEnter");
        playSound("shipEnter");
        await waitForEvent(this.root, "yabasha");
        playSound("Yabasha");
    };

    visibiltyChange = async () => {
        this.isVisible = !this.isVisible;
        if (this.isVisible) {
            if (this.root.currentFrame < 85 || this.root.currentFrame > 87) {
                if (this.firstGame) {
                    this.root.gotoAndStop("startGame");
                    await this.explainSound();
                    !this.start && this.startGame();
                } else {
                    this.root.gotoAndStop("startGame");
                    !this.start && this.startGame();
                }
            }
        }
    };

    unload = () => {
        window.removeEventListener("visibilitychange", this.visibiltyChange);
    };

    explainSound = async () => {
        const sound = await loadSound(`/bible_stories/noach_game/sounds/noa_inst.mp3`);
        sound.play().on("complete", () => {
            this.frame.enableBtn(this.frame.root.frame.btnHelp);
            this.finished_instructions = true;
            this.bgSound.play();
        });
    };

    init = () => {
        this.initFirstGameArk();
        this.initFirstEndArk();
        this.bgSound = playSoundSync("reka", -1).stop();
        this.answer = new Animal();
        this.answer.name = "";
        this.answer.sex = "";
        this.answer.side = "";
        this.isVisible = true;
    };

    private initFirstGameArk() {
        const gameArk = this.root.gameArk;
        this.createStartWin("win1copy", "0", 343, 77, gameArk);
        this.createStartWin("win1", "1", 152, 105, gameArk);
        this.createStartWin("win1", "2", 419, 93, gameArk);
        this.createStartWin("win2", "3", 168, 205, gameArk);
        this.createStartWin("win2copy", "4", 399, 202, gameArk);
        this.createStartWin("wintopleft", "5", 139, 127, gameArk);
        this.createStartWin("wintopright", "6", 413, 202, gameArk);
    }

    private initFirstEndArk() {
        const endArk = this.root.endArk;
        this.createStartWin("win1copy", "0", 343, 77, endArk);
        this.createStartWin("win1", "1", 152, 105, endArk); //
        this.createStartWin("win1", "2", 419, 93, endArk); //
        this.createStartWin("win2", "3", 168, 205, endArk); //
        this.createStartWin("win2copy", "4", 399, 202, endArk); //
        this.createStartWin("wintopleft", "5", 139, 127, endArk); //
        this.createStartWin("wintopright", "6", 413, 202, endArk);
    }
}
export class Animal {
    public name: string;
    public sex: string;
    public side: string;
}
