import { bibleStoryMovies } from "../video/videoUrl";
import { IChannel } from "./IChannel";

export class BibleChannel implements IChannel {
    channelMovies: any[];
    moviesNum: number;

    constructor(public setVideoSrc: (value: string) => void) {
        this.channelMovies = bibleStoryMovies;
        this.moviesNum = this.channelMovies.length;
    }

    setMovieByIndex(index: number) {
        this.setVideoSrc(this.channelMovies[index]);
    }

    getMoviesNum() {
        return this.moviesNum;
    }

    getIconLabelByIndex(index: number) {
        let label: string;
        if (index > this.moviesNum) {
            label = "none";
        } else {
            label = `bible_0${index}`;
        }
        return label;
    }
}
