import { MC, waitForEvent, playSound, stopAllSounds } from "../../../animate/utils";
import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import { routes } from "../../../navigation/routesPath";
const CANVAS_WIDTH = 800;
export class WoodBoxes {
    root: MC;
    showSwitcher: () => void;
    letter: number;
    enableHelp: () => void;
    enableNext: (path: string) => void;
    disableHelp: () => void;
    sylUrl = "/letters_island/wood_boxes/sounds/syles/Letter1syl_a";
    word: MC;
    sketches: MC;
    enableEvents: boolean = false;
    prevLetterClicked: MC;
    prevIndex: number;
    wordsWidthArr = [
        [245.2, 168.4, 186.4],
        [156, 139.35, 141],
        [126, 216.25, 178.4],
        [247.75, 144.75, 126.2],
        [150.65, 374, 103.05],
        [123.85, 47.8, 147.3],
        [193, 170, 126],
        [209.8, 225.85, 225.55],
        [180.85, 223, 187.7],
        [227.75, 130.85, 146.25],
        [177.65, 170.3, 165],
        [163.6, 187.3, 288],
        [201, 280.65, 152.5],
        [80.6, 145.8, 166.3],
        [242.1, 156.65, 208.05],
        [102.15, 213.9, 176.8],
        [158, 225.2, 149.65],
        [226.35, 155, 177.05],
        [179.9, 207.35, 202.5],
        [235, 144.3, 168.1],
        [177.6, 204.3, 207.65],
        [157.9, 131.85, 142.95],
    ];
    skectchesWidthArr = [
        [73.65, 123, 107.25],
        [79.3, 53, 120],
        [102.35, 64.6, 100.55],
        [115, 89.3, 89.2],
        [120, 128, 185.15],
        [55.8, 50.3, 73.7],
        [131.2, 96.25, 154],
        [106.9, 117, 152.5],
        [92.55, 102.15, 50],
        [112, 73.3, 90.3],
        [89, 71.1, 89.85],
        [120.1, 125.95, 109.8],
        [78.95, 58.7, 89.4],
        [59.05, 97.8, 102.45],
        [134.7, 79.75, 70.2],
        [92.3, 119.4, 50.65],
        [131.25, 96.4, 109.95],
        [135.4, 108.25, 88.6],
        [121.8, 128.25, 109.2],
        [57.5, 34.55, 89.75],
        [94, 128.2, 161.15],
        [149.4, 101.9, 121.25],
    ];

    sounds = {
        intro: new Promise<createjs.AbstractSoundInstance>(() => {}),
        syls: [] as any[],
    };

    constructor(
        root: createjs.MovieClip,
        showSwitcher: () => void,
        letter: number,
        enableHelp: () => void,
        disableHelp: () => void,
        enableNext: (path: string) => void
    ) {
        this.root = root;
        this.letter = letter;
        (window as any).wood = root;
        this.showSwitcher = showSwitcher;
        this.enableHelp = enableHelp;
        this.disableHelp = disableHelp;
        this.enableNext = enableNext;
        this.loaded();
    }

    clearPrevWord = () => {
        this.root.sketch_mc.letter_holder_mc.removeChild(this.sketches);
        this.root.word_mc.letter_holder_mc.removeChild(this.word);
    };
    showWord = async (index: number, letterBox: MC) => {
        stopAllSounds();
        this.enableEvents = false;
        if (this.prevLetterClicked) {
            this.prevLetterClicked.gotoAndStop(this.prevIndex);
        }
        letterBox.gotoAndStop(index + 3);
        this.prevLetterClicked = letterBox;
        this.prevIndex = index;
        this.disableHelp();
        this.clearPrevWord();
        this.sketches = new this.root.lib[`sketches${this.letter + 1}`]();
        this.sketches.name = "sketches";
        this.word = new this.root.lib[`words${this.letter + 1}`]();
        this.word.name = "word";
        this.word.gotoAndStop(index - 1);
        this.sketches.gotoAndStop(index - 1);

        const wordWidth = this.wordsWidthArr[this.letter][index - 1];
        const sketchWidth = this.skectchesWidthArr[this.letter][index - 1];
        const padding = 30;
        const margin = (CANVAS_WIDTH - wordWidth - sketchWidth - padding) / 2 + 30;
        this.root.sketch_mc.x = margin;
        this.root.word_mc.x = margin + sketchWidth + padding;
        this.root.word_mc.letter_holder_mc.addChild(this.word);
        this.root.sketch_mc.letter_holder_mc.addChild(this.sketches);
        this.root.sketch_mc.letter_holder_mc.alpha = 0;
        this.playSylSound(index);

        await this.animateIn(this.root.word_mc.letter_holder_mc);
        await this.animateIn(this.root.sketch_mc.letter_holder_mc);

        // letterBox.gotoAndStop(index);
        this.enableEvents = true;
        this.enableHelp();
    };

    playSylSound = async (index: number) => {
        let sound;

        switch (index) {
            case 1:
                sound = loadSound(`/letters_island/wood_boxes/sounds/words/WordL${this.letter + 1}Syl_1.mp3`);
                break;
            case 2:
                sound = loadSound(`/letters_island/wood_boxes/sounds/words/WordL${this.letter + 1}Syl_2.mp3`);
                break;
            case 3:
                sound = loadSound(`/letters_island/wood_boxes/sounds/words/WordL${this.letter + 1}Syl_3.mp3`);
                break;
        }

        await soundPlayed((await sound).play());
    };

    animateIn = (mc: MC) => {
        mc.alpha = 0;
        mc.scale = 0;
        return new Promise((resolve) => {
            createjs.Tween.get(mc)
                .to(
                    {
                        alpha: 1,
                        scale: 1,
                    },
                    500
                )
                .call(resolve);
        });
    };
    learnSyllables = async () => {
        const syllable = new this.root.lib[`letter${this.letter + 1}_open_set`]();
        this.root.first_nikud_mc.letter_holder_mc.addChild(syllable);
        for (let index = 0; index < 5; index++) {
            const sound = await this.sounds.syls[index];
            sound.play();
            syllable.gotoAndStop(index);
            this.root.first_nikud_mc.gotoAndPlay("show");
            await waitForEvent(this.root.first_nikud_mc, "animationEnd");
        }
        this.root.first_nikud_mc.visible = false;
    };

    async showLetter() {
        const letterMC = new this.root.lib[`letter${this.letter + 1}_shape`]();
        letterMC.name = "letter_shape";
        this.root.first_letter_mc.letter_holder_mc.addChild(letterMC);
        this.root.first_letter_mc.gotoAndPlay("show");
        waitForEvent(this.root.first_letter_mc, "animationEnd").then(() => {
            this.root.first_letter_mc.letter_holder_mc.alpha = 1;
            this.root.first_letter_mc.letter_holder_mc.scale = 1;
        });
        const sound = await this.sounds.intro;
        return new Promise((resolve) => {
            sound.play();
            sound.on("complete", resolve);
        });
    }

    loadSounds = () => {
        this.sounds.intro = loadSound(`/letters_island/wood_boxes/sounds/lettersIntro/Letter${this.letter + 1}.mp3`);
        ["a", "e", "i", "o", "u"].forEach((syl) => {
            this.sounds.syls.push(
                loadSound(`/letters_island/wood_boxes/sounds/syles/Letter${this.letter + 1}syl_${syl}.mp3`)
            );
        });
    };

    showBox = (index: number) => {
        const letter = new this.root.lib[`letter${this.letter + 1}`]();
        const box = this.root[`letter_box${index}_mc`];
        this.root[`box${index}`].addEventListener("click", () => {
            if (this.enableEvents) {
                this.showWord(index, letter);
            }
        });
        box.addEventListener("click", () => {
            if (this.enableEvents) {
                this.showWord(index, letter);
            }
        });
        box.addEventListener("rollover", () => {
            if (this.enableEvents) {
                box.cursor = "pointer";
            }
        });
        this.root[`box${index}`].addEventListener("rollover", () => {
            if (this.enableEvents) {
                this.root[`box${index}`].cursor = "pointer";
            }
        });
        letter.gotoAndStop(index);
        letter.name = "letter";
        box.addChild(letter);
        box.gotoAndPlay("show");
        return waitForEvent(box, "animationEnd");
    };
    introEnd = () => {
        this.enableHelp();
        this.enableEvents = true;
        this.enableNext(routes.letters.activities.crabs);
    };
    loaded = async () => {
        this.loadSounds();

        this.root.gotoAndPlay("start");
        await waitForEvent(this.root, "show");
        this.root.gotoAndStop(this.root.duration - 1);

        await this.showLetter();

        await this.learnSyllables();
        playSound("inst").then(this.introEnd);
        this.root.first_letter_mc.gotoAndPlay("hide");
        waitForEvent(this.root.first_letter_mc, "animationEnd").then(() => {
            this.root.first_letter_mc.letter_holder_mc.alpha = 0;
            this.root.first_letter_mc.letter_holder_mc.scale = 0;
        });
        for (let index = 1; index <= 3; index++) {
            await this.showBox(index);
        }
    };
}
