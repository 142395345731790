import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "./routesPath";
import HomeComponent from "../mainscreen/HomeComponent";
import LoginComponent from "../loginnew/LoginComponent";
import LunaparkComponent from "../lunapark/lunapark_menu/LunaparkComponent";
import LetterIslandMenuComponent from "../letters_island/menu/LetterIslandMenuComponent";
import EnglishTimeMenuComponent from "../english_time/EnglishTimeMenuComponent";
import DubbyEnglishMenuComponent from "../dubby_english/DubbyEnglishMenuComponent";
import MovieSectionComponent from "../movies-section/MoviesSectionComponent";
import ColoringPagesMenuComponent from "../coloring_pages/ColoringPagesMenuComponent";
import MyWorksComponent from "../myWorks/MyWorksComponent";
import { SodRoute } from "./PrivateRoot";
import CreationComponent from "../creation_corner/CreationComponent";
import { SessionRefresher } from "./SessionRefresher";
import { NavigationInitializer } from "./NavigationInitializer";
import { CheckIsLogin } from "./CheckIsLogin";
import MathMenuComponent from "../math/math_menu/MathMenuComponent";
import MusicMenuComponent from "../music/music_menu/MusicMenuComponent";
import { GoogleAnalytics } from "./GoogleAnalytics";
import BibleStoriesComponent from "../bible_stories/menu/BibleStoriesComponent";
import DogGameComponent from "../special_games/week_games/dog/DogGameComponent";
import SeekersGameComponent from "../special_games/week_games/seekers/SeekersGameComponent";
import MemoryGameComponent from "../special_games/week_games/memory/MemoryGameComponent";
import SpacerocksGameComponent from "../special_games/week_games/spacerocks/SpacerocksGameComponent";
import SimonWeekGameComponent from "../special_games/week_games/simonGame/SimonWeekGameComponent";
import HipomazeGameComponent from "../special_games/week_games/hipomaze/HipomazeGameComponent";
import LetterRaceGameComponent from "../special_games/week_games/letter_Race/LetterRaceComponent";
import PesahGameComponent from "../special_games/holidays/pesah/PesahGameComponent";
import PurimGameComponent from "../special_games/holidays/purim/PurimGameComponent";
import SucotGameComponent from "../special_games/holidays/socut/SocutGameComponent";
import HanukaGameComponent from "../special_games/holidays/hanuka/HanukaGameComponent";
import FrogGameComponent from "../special_games/week_games/frog/FrogGameComponent";
import DifferencesGameComponent from "../special_games/week_games/differences/DifferencesGameComponent";

export const Root = () => {
    return (
        <>
            <NavigationInitializer />
            <CheckIsLogin />
            <SessionRefresher />
            <GoogleAnalytics />
            <HomeComponent />
            <Switch>
                <Route path={routes.login} component={LoginComponent} />
                <Route path={routes.lunapark.menu} component={LunaparkComponent} />
                <Route path={routes.letters.menu} component={LetterIslandMenuComponent} />
                <Route path={routes.english_time.menu} component={EnglishTimeMenuComponent} />
                <Route path={routes.math.menu} component={MathMenuComponent} />
                <Route path={routes.bible_stories.menu} component={BibleStoriesComponent} />
                <Route path={routes.music.menu} component={MusicMenuComponent} />
                <Route path={routes.coloring_pages.menu} component={ColoringPagesMenuComponent} />
                <Route path={routes.week_game.simon} component={SimonWeekGameComponent} />
                <Route path={routes.week_game.hipomaze} component={HipomazeGameComponent} />
                <Route path={routes.week_game.dog} component={DogGameComponent} />
                <Route path={routes.week_game.seekers} component={SeekersGameComponent} />
                <Route path={routes.week_game.frog} component={FrogGameComponent} />
                <Route path={routes.week_game.memory} component={MemoryGameComponent} />
                <Route path={routes.week_game.letter_race} component={LetterRaceGameComponent} />
                <Route path={routes.week_game.spacerocks} component={SpacerocksGameComponent} />
                <Route path={routes.week_game.differences} component={DifferencesGameComponent} />

                <Route path={routes.dubby_english.menu} component={DubbyEnglishMenuComponent} />
                {/* <SodRoute path={routes.dubby_english.menu}>
                    <DubbyEnglishMenuComponent />
                </SodRoute> */}

                <SodRoute path={routes.movies}>
                    <MovieSectionComponent />
                </SodRoute>
                <SodRoute path={routes.creation}>
                    <CreationComponent />
                </SodRoute>
                <SodRoute path={routes.my_works.list}>
                    <MyWorksComponent />
                </SodRoute>
                <Route path={routes.holiday.purim} component={PurimGameComponent} />
                <Route path={routes.holiday.pesah} component={PesahGameComponent} />
                <Route path={routes.holiday.hanuka} component={HanukaGameComponent} />
                <Route path={routes.holiday.sukkot} component={SucotGameComponent} />
            </Switch>
        </>
    );
};
