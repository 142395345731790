import React from "react";
import AnimateCC from "../../animate";
import { PuppetTheaterGame } from "./PuppetTheaterGame";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import additionalSounds from "./PuppetTheaterSounds";

const PuppetTheaterComponent: React.FC<{ frame: LunaparkMenu }> = ({
    frame,
}) => {
    const game = React.useRef(new PuppetTheaterGame(frame));

    return (
        <AnimateCC
            base="/lunapark/puppet_theater"
            animationName="puppet_theater"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default PuppetTheaterComponent;
