import { MC, waitForEvent, playSound, asyncTween, waitForTimer } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game10 extends BaseGame {
    helpBtn: any;
    initGame = async (root: MC, gameEnd: () => void, helpBtn: any) => {
        const game = root.game;
        this.helpBtn = helpBtn;
        game.visible = true;
        game.gotoAndPlay(0);
        await waitForEvent(game, "questionEnd");
        if (!this.shouldPlay) return;
        helpBtn(false);
        game.gotoAndStop(111);
        this.addEventToBtn(root, game, gameEnd, game.spoon, "game1");
        this.addEventToBtn(root, game, gameEnd, game.plate, "game1");
        this.addEventToBtn(root, game, gameEnd, game.fork, "game1");
        this.addEventToBtn(root, game, gameEnd, game.scarecrow, "game1");
    };
    addEventToBtn = (root: MC, game: MC, gameEnd: () => void, currentBtn: MC, whichGame: string) => {
        currentBtn.gotoAndStop(0);
        currentBtn.cursor = "pointer";
        currentBtn.addEventListener("click", async () => {
            await waitForTimer(500);
            if (!this.shouldPlay) return;
            currentBtn.gotoAndStop(0);
            currentBtn.gotoAndStop(1);
            await this.checkIfCorrectAnswer(root, game, gameEnd, currentBtn, whichGame);
        });
        currentBtn.addEventListener("rollover", async () => {
            currentBtn.gotoAndStop(1);
        });
        currentBtn.addEventListener("rollout", async () => {
            currentBtn.gotoAndStop(0);
        });
    };
    checkIfCorrectAnswer = async (root: MC, game: MC, gameEnd: () => void, currentBtn: MC, whichGame: string) => {
        if (whichGame === "game1") {
            if (currentBtn.name === "scarecrow") {
                this.helpBtn(true);
                game.gotoAndPlay(135);
                await waitForEvent(game, "gameEnd");
                if (!this.shouldPlay) return;
                await asyncTween(game, { alpha: 0 }, 300);
                if (!this.shouldPlay) return;
                game.removeAllEventListeners();
                this.game2(root, gameEnd);
            } else {
                playSound("wrong");
            }
        } else {
            if (currentBtn.name === "btn_0") {
                this.helpBtn(true);
                if (!this.shouldPlay) return;
                playSound("good");
                game.gotoAndPlay(168);
                await waitForEvent(game, "gameEnd");
                if (!this.shouldPlay) return;
                await asyncTween(game, { alpha: 0 }, 300);
                if (!this.shouldPlay) return;
                gameEnd();
            } else {
                await playSound("wrong");
            }
        }
    };
    game2 = async (root: MC, gameEnd: () => void) => {
        const game2 = root.game2;
        game2.x = 240;
        game2.y = 120;
        game2.visible = true;
        game2.gotoAndPlay(1);
        await waitForEvent(game2, "questionEnd");
        if (!this.shouldPlay) return;
        this.helpBtn();
        game2.gotoAndStop(137);
        for (let i = 0; i < 4; i++) {
            this.addEventToBtn(root, game2, gameEnd, game2[`btn_${i}`], "game2");
        }
    };
}
