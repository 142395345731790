import React, { useEffect } from "react";
import AnimateCC from "../../../animate";
import { RocketMenu } from "../RocketMenu";
import { BugsGame } from "./BugsGame";
import additionalSounds from "./BugsSounds";

const BugsComponent: React.FC<{
    frame: RocketMenu;
}> = ({ frame }) => {
    const game = React.useRef(new BugsGame(frame));
    useEffect(() => {
        const game_instance = game.current;
        return () => {
            game_instance.unload();
        };
    }, []);
    return (
        <AnimateCC
            base="/lunapark/rocket/bugs"
            animationName="bugs"
            scale={0.75}
            shiftX={140}
            shiftY={5}
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default BugsComponent;
