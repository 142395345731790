export const lettersSounds = [
    {
        src: "/letters_island/common/letters/letter1.mp3",
        id: "letter1",
    },
    {
        src: "/letters_island/common/letters/letter2.mp3",
        id: "letter2",
    },
    {
        src: "/letters_island/common/letters/letter3.mp3",
        id: "letter3",
    },
    {
        src: "/letters_island/common/letters/letter4.mp3",
        id: "letter4",
    },
    {
        src: "/letters_island/common/letters/letter5.mp3",
        id: "letter5",
    },
    {
        src: "/letters_island/common/letters/letter6.mp3",
        id: "letter6",
    },
    {
        src: "/letters_island/common/letters/letter7.mp3",
        id: "letter7",
    },
    {
        src: "/letters_island/common/letters/letter8.mp3",
        id: "letter8",
    },
    {
        src: "/letters_island/common/letters/letter9.mp3",
        id: "letter9",
    },
    {
        src: "/letters_island/common/letters/letter10.mp3",
        id: "letter10",
    },
    {
        src: "/letters_island/common/letters/letter11.mp3",
        id: "letter11",
    },
    {
        src: "/letters_island/common/letters/letter12.mp3",
        id: "letter12",
    },
    {
        src: "/letters_island/common/letters/letter13.mp3",
        id: "letter13",
    },
    {
        src: "/letters_island/common/letters/letter14.mp3",
        id: "letter14",
    },
    {
        src: "/letters_island/common/letters/letter15.mp3",
        id: "letter15",
    },
    {
        src: "/letters_island/common/letters/letter16.mp3",
        id: "letter16",
    },
    {
        src: "/letters_island/common/letters/letter17.mp3",
        id: "letter17",
    },
    {
        src: "/letters_island/common/letters/letter18.mp3",
        id: "letter18",
    },
    {
        src: "/letters_island/common/letters/letter19.mp3",
        id: "letter19",
    },
    {
        src: "/letters_island/common/letters/letter20.mp3",
        id: "letter20",
    },
    {
        src: "/letters_island/common/letters/letter21.mp3",
        id: "letter21",
    },
    {
        src: "/letters_island/common/letters/letter22.mp3",
        id: "letter22",
    },
    {
        src: "/letters_island/common/letters/aleph.mp3",
        id: "letters1",
    },
    {
        src: "/letters_island/common/letters/bet.mp3",
        id: "letters2",
    },
    {
        src: "/letters_island/common/letters/gimel.mp3",
        id: "letters3",
    },
    {
        src: "/letters_island/common/letters/dalet.mp3",
        id: "letters4",
    },
    {
        src: "/letters_island/common/letters/he.mp3",
        id: "letters5",
    },
    {
        src: "/letters_island/common/letters/vav.mp3",
        id: "letters6",
    },
    {
        src: "/letters_island/common/letters/zain.mp3",
        id: "letters7",
    },
    {
        src: "/letters_island/common/letters/khet.mp3",
        id: "letters8",
    },
    {
        src: "/letters_island/common/letters/tet.mp3",
        id: "letters9",
    },
    {
        src: "/letters_island/common/letters/yod.mp3",
        id: "letters10",
    },
    {
        src: "/letters_island/common/letters/kaf.mp3",
        id: "letters11",
    },
    {
        src: "/letters_island/common/letters/lamed.mp3",
        id: "letters12",
    },
    {
        src: "/letters_island/common/letters/mem.mp3",
        id: "letters13",
    },
    {
        src: "/letters_island/common/letters/nun.mp3",
        id: "letters14",
    },
    {
        src: "/letters_island/common/letters/samekh.mp3",
        id: "letters15",
    },
    {
        src: "/letters_island/common/letters/ain.mp3",
        id: "letters16",
    },
    {
        src: "/letters_island/common/letters/pe.mp3",
        id: "letters17",
    },
    {
        src: "/letters_island/common/letters/tsadi.mp3",
        id: "letters18",
    },
    {
        src: "/letters_island/common/letters/kof.mp3",
        id: "letters19",
    },
    {
        src: "/letters_island/common/letters/resh.mp3",
        id: "letters20",
    },
    {
        src: "/letters_island/common/letters/shin.mp3",
        id: "letters21",
    },
    {
        src: "/letters_island/common/letters/tav.mp3",
        id: "letters22",
    },
    {
        src: "/letters_island/common/letters/kaf_f_d.mp3",
        id: "letters23",
    },
    {
        src: "/letters_island/common/letters/mem_f_d.mp3",
        id: "letters24",
    },
    {
        src: "/letters_island/common/letters/nun_f_d.mp3",
        id: "letters25",
    },
    {
        src: "/letters_island/common/letters/pe_f_d.mp3",
        id: "letters26",
    },
    {
        src: "/letters_island/common/letters/tsadi_f_d.mp3",
        id: "letters27",
    },
    {
        src: "/letters_island/common/letters/she/letter1.mp3",
        id: "maya_letter1",
    },
    {
        src: "/letters_island/common/letters/she/letter2.mp3",
        id: "maya_letter2",
    },
    {
        src: "/letters_island/common/letters/she/letter3.mp3",
        id: "maya_letter3",
    },
    {
        src: "/letters_island/common/letters/she/letter4.mp3",
        id: "maya_letter4",
    },
    {
        src: "/letters_island/common/letters/she/letter5.mp3",
        id: "maya_letter5",
    },
    {
        src: "/letters_island/common/letters/she/letter6.mp3",
        id: "maya_letter6",
    },
    {
        src: "/letters_island/common/letters/she/letter7.mp3",
        id: "maya_letter7",
    },
    {
        src: "/letters_island/common/letters/she/letter8.mp3",
        id: "maya_letter8",
    },
    {
        src: "/letters_island/common/letters/she/letter9.mp3",
        id: "maya_letter9",
    },
    {
        src: "/letters_island/common/letters/she/letter10.mp3",
        id: "maya_letter10",
    },
    {
        src: "/letters_island/common/letters/she/letter11.mp3",
        id: "maya_letter11",
    },
    {
        src: "/letters_island/common/letters/she/letter12.mp3",
        id: "maya_letter12",
    },
    {
        src: "/letters_island/common/letters/she/letter13.mp3",
        id: "maya_letter13",
    },
    {
        src: "/letters_island/common/letters/she/letter14.mp3",
        id: "maya_letter14",
    },
    {
        src: "/letters_island/common/letters/she/letter15.mp3",
        id: "maya_letter15",
    },
    {
        src: "/letters_island/common/letters/she/letter16.mp3",
        id: "maya_letter16",
    },
    {
        src: "/letters_island/common/letters/she/letter17.mp3",
        id: "maya_letter17",
    },
    {
        src: "/letters_island/common/letters/she/letter18.mp3",
        id: "maya_letter18",
    },
    {
        src: "/letters_island/common/letters/she/letter19.mp3",
        id: "maya_letter19",
    },
    {
        src: "/letters_island/common/letters/she/letter20.mp3",
        id: "maya_letter20",
    },
    {
        src: "/letters_island/common/letters/she/letter21.mp3",
        id: "maya_letter21",
    },
    {
        src: "/letters_island/common/letters/she/letter22.mp3",
        id: "maya_letter22",
    },
];
export const lettersMyasSounds = [
    {
        src: "/letters_island/common/letters/she/letter1.mp3",
        id: "letter1",
    },
    {
        src: "/letters_island/common/letters/she/letter2.mp3",
        id: "letter2",
    },
    {
        src: "/letters_island/common/letters/she/letter3.mp3",
        id: "letter3",
    },
    {
        src: "/letters_island/common/letters/she/letter4.mp3",
        id: "letter4",
    },
    {
        src: "/letters_island/common/letters/she/letter5.mp3",
        id: "letter5",
    },
    {
        src: "/letters_island/common/letters/she/letter6.mp3",
        id: "letter6",
    },
    {
        src: "/letters_island/common/letters/she/letter7.mp3",
        id: "letter7",
    },
    {
        src: "/letters_island/common/letters/she/letter8.mp3",
        id: "letter8",
    },
    {
        src: "/letters_island/common/letters/she/letter9.mp3",
        id: "letter9",
    },
    {
        src: "/letters_island/common/letters/she/letter10.mp3",
        id: "letter10",
    },
    {
        src: "/letters_island/common/letters/she/letter11.mp3",
        id: "letter11",
    },
    {
        src: "/letters_island/common/letters/she/letter12.mp3",
        id: "letter12",
    },
    {
        src: "/letters_island/common/letters/she/letter13.mp3",
        id: "letter13",
    },
    {
        src: "/letters_island/common/letters/she/letter14.mp3",
        id: "letter14",
    },
    {
        src: "/letters_island/common/letters/she/letter15.mp3",
        id: "letter15",
    },
    {
        src: "/letters_island/common/letters/she/letter16.mp3",
        id: "letter16",
    },
    {
        src: "/letters_island/common/letters/she/letter17.mp3",
        id: "letter17",
    },
    {
        src: "/letters_island/common/letters/she/letter18.mp3",
        id: "letter18",
    },
    {
        src: "/letters_island/common/letters/she/letter19.mp3",
        id: "letter19",
    },
    {
        src: "/letters_island/common/letters/she/letter20.mp3",
        id: "letter20",
    },
    {
        src: "/letters_island/common/letters/she/letter21.mp3",
        id: "letter21",
    },
    {
        src: "/letters_island/common/letters/she/letter22.mp3",
        id: "letter22",
    },
];

export const getMayaSoundsByLetter = (letter: number) => {
    return [
        {
            src: `/letters_island/common/letters/she/letter${letter}.mp3`,
            id: `maya_letter${letter}`,
        },
    ];
};
