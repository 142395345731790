import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import gameSounds from "./sounds_fishesGame.json";
import { feedbacksSounds } from "../../common/feedBacksSounds";
import { commomSounds } from "../../common/commonSounds";
import { FishesGame } from "./FishesGame";
import { useParams, useRouteMatch } from "react-router-dom";
import { useLettersContext } from "../../../context/LettersContext";
export default function FishesGameComponent() {
    const { sectionIndex } = useParams<{ sectionIndex: string }>();
    const match = useRouteMatch();

    const game = React.useRef(new FishesGame(match, parseInt(sectionIndex)));
    const lettersContext = useLettersContext();

    return (
        <AnimateCC
            base="/letters_island/fishes/"
            animationName="fishes"
            additionalSounds={[...gameSounds, ...feedbacksSounds, ...commomSounds]}
            onLoaded={(mc: MC) => game.current.fishesLoaded(mc, lettersContext)}
        />
    );
}
