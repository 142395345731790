import { loadSound } from "../../../animate/sound_utils";
import { MC, playSound, waitFor, waitForEvent } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { SpecialGameBasic } from "../../SpecialGameBasic";

const NUM_ROWS = 4;
const NUM_ANS_PER_ROW = 4;
const SERIES_LENGS = 5;

const SHAPES_ARR = [
    [
        [0, 3],
        [1, 4],
        [2, 5],
    ],
    [
        [6, 9],
        [7, 10],
        [8, 11],
    ],
    [
        [12, 15],
        [13, 16],
        [14, 17],
    ],
];
const DIM_1 = 3; // colors, number of options for each dimesion
const DIM_2 = 3; // shapes
const DIM_3 = 2; // has dot

export class FrogGame extends SpecialGameBasic {
    helpSound = "/special_games/frog/sounds/inst.mp3";
    bGSound = "/special_games/frog/sounds/music.mp3";
    instructionSound = "/special_games/frog/sounds/inst.mp3";
    goodSound = "/special_games/frog/sounds/good.mp3";
    missSound = "/special_games/frog/sounds/miss.mp3";
    gameOverSound = "/special_games/frog/sounds/Winner2wav.mp3";
    gameData: any[][] = [[], [], [], []];
    seriesArray: any;
    currentRow = 0;
    questionArr: any = [];
    currentLeaf: MC;
    frogPrevLocation: any;
    frogStartLocation = { x: 298, y: 169.5 };
    isLocked: boolean = false;

    loaded = async (root: MC) => {
        this.root = root;
        (window as any).fr = root;
        await waitFor(() => this.root);
        this.root.gotoAndPlay(0);
        this.initBtns();
        //hide leaves?
        this.initLeaves();
        this.setGameData();
        this.root.gameOverLocation.visible = false;
        this.frogPrevLocation = { x: this.root.frog.x, y: this.root.frog.y };
    };
    initBtns() {
        this.setBgSound();
        this.initSpeker();
        this.initReset(this.onReset);
        this.initHelp();
        this.enableHelp();
        this.playInstSound();
        this.initExit();
        this.initIconMc();
        //new game btn:
        this.setBtn(this.root.gameOver2_mc.board.newGame, this.startNewGame);
    }
    startNewGame = () => {
        this.root.frog.visible = true;
        this.root.gameOver2_mc.visible = false;
        this.root.gameOver2_mc.gotoAndStop(0);
        this.setGameData();
    };
    initLeaves() {
        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 4; j++) {
                this.setBtn(this.root[`leaf_${i}${j}`], this.checkSelectedLeaf, i);
            }
        }
    }
    setBtn(btn: MC, callback: Function, i?: number) {
        btn.cursor = "pointer";
        btn.addEventListener("rollover", () => {
            btn.gotoAndStop("over");
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("up");
        });
        btn.addEventListener("click", () => {
            if (this.isLocked) return;
            callback(i, btn);
        });
    }
    checkSelectedLeaf = async (i: number, leaf: MC) => {
        if (this.currentRow === i) {
            this.isLocked = true;
            this.currentLeaf = leaf;
            await this.jumpFrog();
            if (leaf.isCorrect) {
                await this.correctLeaveSelected();
            } else {
                await this.wrongLeaveSelected();
            }
            this.isLocked = false;
        } else {
            return;
        }
    };

    onReset = () => {
        this.setGameData();
    };
    setGameData() {
        this.clearGameData();
        this.setShapesSeries();
        this.setLeaves();
        this.setCurrentExercise();
    }
    setCurrentExercise() {
        if (this.currentRow > 0) {
            // hide previous row
            this.hideRow(this.currentRow - 1);
        }
        if (this.currentRow < NUM_ROWS) {
            // show current row

            for (var col = 0; col < NUM_ANS_PER_ROW; col++) {
                this.currentLeaf = this.root[`leaf_${this.currentRow}${col}`];
                this.currentLeaf.shapes.visible = true;
            }
            this.showCurrentSeries();
        } else {
            this.gameOver();
        }
    }
    setShapesSeries() {
        for (var row = 0; row < NUM_ROWS; row++) {
            var i = Math.floor(Math.random() * DIM_1);
            var j = Math.floor(Math.random() * DIM_2);
            var k = Math.floor(Math.random() * DIM_3);
            const firstShape = SHAPES_ARR[i][j][k];
            const dimToChange = Math.floor(Math.random() * 3);
            const secondShapeResp = this.getSecondShape(dimToChange, i, j, k);
            const secondShape = secondShapeResp[0];
            this.questionArr[row] = [firstShape, secondShape, firstShape, secondShape];
            let gameDataIndex = 0;
            this.gameData[row][gameDataIndex++] = this.questionArr[row]; // correct answer
            this.gameData[row][gameDataIndex++] = firstShape; // correct answer
            this.gameData[row][gameDataIndex++] = secondShape; // wrong answer

            while (gameDataIndex <= NUM_ANS_PER_ROW) {
                // rest of the answers
                let isExist = true;
                const maxItems = SHAPES_ARR.length * SHAPES_ARR[0].length * SHAPES_ARR[0][0].length;
                let itemIndex;
                while (isExist) {
                    isExist = false;
                    itemIndex = Math.floor(Math.random() * maxItems);

                    for (let i = 1; i < this.gameData[row].length; i++) {
                        if (itemIndex === this.gameData[row][i]) {
                            isExist = true;
                            break;
                        }
                    }
                }
                this.gameData[row][gameDataIndex++] = itemIndex;
            }
        }
    }
    getSecondShape(dimToChange: number, i: number, j: number, k: number) {
        let arTemp = [];
        let otherObjectsIndex = 0;
        let randIndex;
        switch (dimToChange) {
            //set an array with all objects of the selected dim but the exist one, and pick from it
            case 0:
                //color:
                while (otherObjectsIndex < SHAPES_ARR.length) {
                    if (otherObjectsIndex !== i) arTemp.push(otherObjectsIndex);
                    otherObjectsIndex++;
                }
                randIndex = arTemp[Math.floor(Math.random() * arTemp.length)];
                return [SHAPES_ARR[randIndex][j][k], randIndex];
            case 1:
                //shape:
                while (otherObjectsIndex < SHAPES_ARR[0].length) {
                    if (otherObjectsIndex !== j) arTemp.push(otherObjectsIndex);
                    otherObjectsIndex++;
                }
                randIndex = arTemp[Math.floor(Math.random() * arTemp.length)];
                return [SHAPES_ARR[i][randIndex][k], randIndex];
            case 2:
                //dot:
                while (otherObjectsIndex < SHAPES_ARR[0][0].length) {
                    if (otherObjectsIndex !== k) arTemp.push(otherObjectsIndex);
                    otherObjectsIndex++;
                }
                randIndex = arTemp[Math.floor(Math.random() * arTemp.length)];
                return [SHAPES_ARR[i][j][randIndex], randIndex];
        }
    }
    setLeaves() {
        for (let row = 0; row < NUM_ROWS; row++) {
            let tempArr = [];
            for (let i = 0; i < NUM_ANS_PER_ROW; i++) {
                tempArr.push(i);
            }
            let c = 1;
            while (tempArr.length > 0) {
                var rand = getRandomNumber(0, tempArr.length - 1);
                var col = tempArr[rand];
                var leaf_mc = this.root[`leaf_${row}${col}`];
                leaf_mc.isCorrect = c === 1 ? true : false;
                leaf_mc.shapes.gotoAndStop(this.gameData[row][c]);

                tempArr.splice(rand, 1);
                c++;
            }
        }
    }
    hideRow(row: Number) {
        for (var col = 0; col < NUM_ANS_PER_ROW; col++) {
            let leaf = this.root[`leaf_${row}${col}`];
            leaf.shapes.visible = false;
        }
    }

    async correctLeaveSelected() {
        this.currentLeaf.visible = true;
        this.currentLeaf.shapes.gotoAndStop("empty");
        this.root.frog.gotoAndPlay("correct");
        this.currentLeaf.gotoAndStop("empty");
        await waitForEvent(this.root.frog, "correctEnded");
        this.currentRow++;
        this.setCurrentExercise();
    }
    async wrongLeaveSelected() {
        this.root.frog.gotoAndPlay("wrong");
        await waitForEvent(this.root.frog, "wrongEnded");
        this.currentLeaf.visible = true;
        await loadSound(this.missSound);
        playSound(this.missSound);
        this.root.frog.x = this.frogPrevLocation.x;
        this.root.frog.y = this.frogPrevLocation.y;
        this.root.frog.visible = false;
        this.root.frog.gotoAndStop("start");
        this.root.frog.visible = true;
    }
    clearGameData() {
        this.currentRow = 0;
        this.questionArr = [];
        this.gameData = [[], [], [], []];
        this.root.gameOver2_mc.visible = false;
        this.displayLeaves();
        this.hideAllRows();
        this.resetShapes();
        this.hideCloud();
        this.resetFrogLocation();
    }
    async gameOver() {
        this.hideCloud();
        this.currentLeaf = this.root.gameOverLocation;
        await this.jumpFrog();
        this.root.frog.gotoAndPlay("finish");
        await waitForEvent(this.root.frog, "finishEnded");
        this.root.frog.visible = false;
        this.resetFrogLocation();
        this.root.gameOver2_mc.visible = true;
        await loadSound(this.gameOverSound);
        playSound(this.gameOverSound);
        this.root.gameOver2_mc.gotoAndPlay(0);
    }
    hideAllRows() {
        for (let index = 0; index < NUM_ROWS; index++) {
            this.hideRow(index);
        }
    }
    hideCloud() {
        for (let index = 1; index <= SERIES_LENGS; index++) {
            this.root.cloud_mc[`shapes${index}`].gotoAndStop("empty");
        }
    }
    resetFrogLocation() {
        this.root.frog.x = this.frogStartLocation.x;
        this.root.frog.y = this.frogStartLocation.y;
    }
    displayLeaves() {
        for (let i = 0; i < NUM_ROWS; i++) {
            for (let j = 0; j < NUM_ANS_PER_ROW; j++) {
                this.root[`leaf_${i}${j}`].visible = true;
            }
        }
    }
    showCurrentSeries() {
        for (let index = 1; index < SERIES_LENGS; index++) {
            const shape = this.gameData[this.currentRow][0];
            this.root.cloud_mc[`shapes${index}`].gotoAndStop(shape[index - 1]);
        }
        this.root.cloud_mc[`shapes5`].gotoAndStop("questionMark");
    }
    async jumpFrog() {
        if (this.currentLeaf.x > this.root.frog.x) {
            this.root.frog.gotoAndPlay("jump_right");
        } else {
            this.root.frog.gotoAndPlay("jump_left");
        }
        this.frogPrevLocation = { x: this.root.frog.x, y: this.root.frog.y };

        createjs.Tween.get(this.root.frog)
            .to({ x: this.currentLeaf.x, y: this.currentLeaf.y }, 600)
            .call(() => {});
        await waitForEvent(this.root.frog, "jumpEnded");
        this.currentLeaf.visible = false;
    }
    resetShapes() {
        for (let i = 0; i < NUM_ROWS; i++) {
            for (let j = 0; j < NUM_ANS_PER_ROW; j++) {
                this.root[`leaf_${i}${j}`].gotoAndStop("empty");
            }
        }
    }
}
