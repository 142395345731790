import { MC, waitForEvent, playSound } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game19 extends BaseGame {
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        game.visible = true;
        game.gotoAndPlay(1);
        game.mousecurrentBtnren = false;
        await waitForEvent(game, "animationGameEnd");
        if (!this.shouldPlay) return;
        helpBtn(false);
        for (let i = 0; i < 3; i++) {
            const currentBtn = game[`btn_${i}`];
            currentBtn.gotoAndStop(0);
            currentBtn.cursor = "pointer";
            currentBtn.addEventListener("click", async () => {
                currentBtn.gotoAndStop(0);
                currentBtn.gotoAndStop(1);
                await this.checkIfCorrectAnswer(currentBtn, game, gameEnd);
                if (!this.shouldPlay) return;
            });
            currentBtn.addEventListener("rollover", async () => {
                currentBtn.gotoAndStop("over");
            });
            currentBtn.addEventListener("rollout", async () => {
                currentBtn.gotoAndStop("up");
            });
        }
        game.mousecurrentBtnren = true;
    };
    checkIfCorrectAnswer = async (current_btn: MC, game: MC, gameEnd: () => void) => {
        if (current_btn.name === "btn_2") {
            game.gotoAndPlay(105);
            await waitForEvent(game, "gameEnd");
            if (!this.shouldPlay) return;
            gameEnd();
        } else {
            playSound("wrong");
        }
    };
}
