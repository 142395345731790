import cookie from "cookie";

export const getCookie = (name: string) => {
    return cookie.parse(document.cookie)[name];
};

export const setCookie = (name: string, value: string, options: any = {}) => {
    options = {
        path: "/",
        ...options,
    };

    document.cookie = cookie.serialize(name, value, options);
};
