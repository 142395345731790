const jsonData = require("./findLetter_data.json");

export function findSentencesToLetter(letter: number) {
    let sentenceArray: any[] = [];
    sentenceArray.push(jsonData[letter].sentence1);
    sentenceArray.push(jsonData[letter].sentence2);
    if (jsonData[letter].sentence3 !== null) {
        sentenceArray.push(jsonData[letter].sentence3);
    }
    return sentenceArray;
}

export function getImage(letter: number) {
    const imagePath = jsonData[letter].image;
    const image = new createjs.Bitmap(
        `/letters_island/find_letters/images/lettersImages/${imagePath}`
    );
    return image;
}
