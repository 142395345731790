import { MC, playSound, waitForEvent } from "../../../animate/utils";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { BaseGame } from "./BaseGame";
export default class Game13 extends BaseGame {
    gameEnd: any;
    game: any;
    helpBtn: any;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.gameEnd = gameEnd;
        this.game = game;
        game.scaleX = 1.3;
        game.scaleY = 1.3;
        game.y = 300;
        game.x = 650;
        this.helpBtn = helpBtn;
        game.visible = true;
        game.gotoAndStop(0);
        game.gotoAndPlay(1);
        game.sen0.gotoAndStop(0);
        game.sen1.gotoAndStop(0);
        await waitForEvent(game, "questionEnd");
        if (!this.shouldPlay) return;
        this.helpBtn(false);
        this.gameEnd();
        for (let i = 0; i < 2; i++) {
            const currentSen = game[`sen${i}`];
            currentSen.cursor = "pointer";
            currentSen.gotoAndStop(0);
            SoundToolTip.addToolTip(currentSen, `sen${i}`);
            currentSen.addEventListener("click", async () => {
                this.checkAnswer(currentSen);
            });
        }
    };
    checkAnswer = async (sen: MC) => {
        this.game.mouseChildren = false;
        if (sen.name === "sen0") {
            this.helpBtn(true);
            await playSound(`sen0`);
            if (!this.shouldPlay) return;
            await playSound("solution");
            if (!this.shouldPlay) return;
            this.game.mouseChildren = true;
            this.helpBtn(false);
        } else {
            await playSound(`sen1`);
            if (!this.shouldPlay) return;
            playSound("game13_answer");
            this.game.mouseChildren = true;
        }
    };
}
