import React, { useEffect } from "react";
import { useUserContext } from "../context/UserContext";
// import { ALL_PERMISSIONS, useUserContext } from "../context/UserContext";
import { useIframeContext } from "../context/IframeContext";
import { permissions } from "../api/permissions";

export const CheckIsLogin: React.FC = () => {
    const { isLoggedIn, setCurrentUser, logout, setPermissions } = useUserContext();
    const { iframeSrc } = useIframeContext();

    useEffect(() => {
        (window as any).UpdateIsLogin = async (resultIsLoggedIn: string, name: string, userId: string) => {
            if (resultIsLoggedIn === "True") {
                const response = await permissions();
                setCurrentUser(userId);
                setPermissions(response);
            } else {
                //If the user was logged-in, then logout him now.

                //behave as guest
                // setPermissions(ALL_PERMISSIONS);
                if (isLoggedIn) {
                    logout();
                }
            }
        };
    }, [setCurrentUser, isLoggedIn, logout, setPermissions]);

    return (
        <iframe
            id="checkIsLogin"
            key={iframeSrc}
            title="check if user logged in"
            src="/CheckIsLogin.aspx"
            style={{
                display: "none",
            }}
        />
    );
};
