import { MC, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

export class WindowGame {
    private root: MC;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).Window = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(19);
        this.root.window.gotoAndStop(0);
        for (let i = 1; i <= 31; i++) {
            this.root["_" + i].gotoAndStop(getRandomNumber(1, 4));
        }
        this.root.window.btn_open.addEventListener("click", () => {
            this.root.window.gotoAndStop(0);
        });
        this.root.window.btn_close.addEventListener("click", () => {
            this.root.window.gotoAndStop(1);
        });
    };
}
