const additionalSounds = [
    {
        src: "sounds/Prize3.mp3",
        id: "Prize3",
    },
    {
        src: "sounds/shut.mp3",
        id: "shut",
    },
    {
        src: "sounds/jack.mp3",
        id: "jack",
    },
    {
        src: "sounds/boom.mp3",
        id: "boom",
    },
    {
        src: "sounds/crash.mp3",
        id: "crash",
    },
    {
        src: "sounds/pop.mp3",
        id: "pop",
    },
    {
        src: "sounds/whstlewav.mp3",
        id: "whstlewav",
    },
    {
        src: "sounds/flower.mp3",
        id: "flower",
    },
    {
        src: "sounds/RUSSIANDANCE.mp3",
        id: "RUSSIANDANCE",
    },
];
export default additionalSounds;
