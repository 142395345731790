import { localToGlobal, MC, playSound, setupStage, waitForEvent } from "../../../animate/utils";
const AREA_WIDTH = 4.8;
const AREA_HEIGHT = 4.1;
const WIDTHS = [51.55, 59.05, 68.8, 109.25, 37.85, 29.6, 39.45];
const HEIGHTS = [125.55, 142.75, 129.6, 108.5, 32.5, 40.85, 30.9];

export class CakeGame {
    private root: MC;
    private moveX: number;
    private moveY: number;
    private should_move: boolean = false;
    private pressed_index: number = 0;
    private numIntersections: number = 0;
    constructor(private frame: any) {}

    loaded = async (mc: MC) => {
        this.root = mc;
        (window as any).cake = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.cake.gotoAndStop(0);
        playSound("birthday");
        this.start();
    };

    start = () => {
        this.root.addEventListener("tick", () => {
            this.moveElement();
        });
        for (let i = 1; i <= 7; i++) {
            const piece = this.root[`piece${i}`];
            piece.gotoAndStop(0);
            this.addListeners(piece, i);
        }
    };

    addListeners = (piece: MC, index: number) => {
        this.mouseDown(piece, index);
        this.pressup(piece, index);
        this.mouseout(piece, index);
    };

    mouseDown = (piece: MC, i: number) => {
        piece.addEventListener("mousedown", async () => {
            if (this.should_move) {
                if (this.pressed_index === i) {
                    return;
                }
                this.should_move = false;
            }
            this.pressed_index = i;
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.moveX = localMouse.x - piece.x;
            this.moveY = localMouse.y - piece.y;
        });
    };

    mouseout = (piece: MC, i: number) => {
        piece.addEventListener("mouseout", () => {
            if (this.pressed_index === i && !this.should_move) {
                this.pressed_index = 0;
            }
        });
    };

    pressup = (piece: MC, i: number) => {
        piece.addEventListener("pressup", async () => {
            if (this.should_move && this.pressed_index === i) {
                this.should_move = false;
                this.checkIntersection(piece, i);
                return;
            }
            if (this.pressed_index === i) {
                this.should_move = true;
            }
        });
    };

    moveElement = () => {
        if (this.should_move) {
            if (this.root.stage.mouseX && this.root.stage.mouseY) {
                const pos = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
                const piece = this.root[`piece${this.pressed_index}`];
                piece.x = pos.x - this.moveX;
                piece.y = pos.y - this.moveY;
            }
        }
    };

    checkIntersection = async (piece: MC, i: number) => {
        const shadow = this.root[`shadow${this.pressed_index}`];
        const globalShadowArea = localToGlobal(shadow, 0, 0);
        const globalPieceArea = localToGlobal(piece.area, 0, 0);

        const shadowRect = new createjs.Rectangle(
            globalShadowArea.x - WIDTHS[i - 1] / 2,
            globalShadowArea.y - HEIGHTS[i - 1] / 2,
            WIDTHS[i - 1],
            HEIGHTS[i - 1]
        );

        const pieceRect = new createjs.Rectangle(
            globalPieceArea.x - AREA_WIDTH / 2,
            globalPieceArea.y - AREA_HEIGHT / 2,
            AREA_WIDTH,
            AREA_HEIGHT
        );
        if (shadowRect.intersects(pieceRect)) {
            piece.gotoAndPlay(1);
            await waitForEvent(this.root, "piece" + i + "End");
            piece.visible = false;
            shadow.visible = false;
            this.numIntersections += 1;
            if (this.numIntersections === 7) {
                this.root.gotoAndPlay(25);
                await waitForEvent(this.root, "animationEnd");
                this.root.cake.gotoAndPlay(1);
            }
            return true;
        }
        return false;
    };
}
