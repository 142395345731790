import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import MathGameBase from "../MathGame/MathGameBase";

export class Swing extends MathGameBase {
    chosenLevel: number;
    method: string;
    answer: number;
    number1: number;
    number2: number;
    currentMethod: string;
    fakeMethod: string;
    realBear: MC;
    fakeBear: MC;
    realBearTxt: MC;
    fakeBearTxt: MC;
    correctAnswers: number = 0;
    enableClick: boolean = true;
    gamePlayed: number = 0;
    resetSwing: boolean = false;
    loop: boolean = true;
    name = "swing";
    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).swing = root;
    }

    handleLevel = async (level?: number) => {
        this.resetProps();
        this.disableHelp();
        this.cleanPrizes(true);
        this.chosenLevel = level;
        this.loop = false;
        this.startGame();
    };

    clearBears = () => {
        this.realBear && this.realBear.gotoAndStop(0);
        this.fakeBear && this.fakeBear.gotoAndStop(0);
        this.realBear && this.realBear.removeAllEventListeners();
        this.fakeBear && this.fakeBear.removeAllEventListeners();
        this.realBearTxt && this.realBearTxt.removeAllEventListeners();
        this.fakeBearTxt && this.fakeBearTxt.removeAllEventListeners();
    };

    resetProps = () => {
        this.clearBears();
        this.realBear = null;
        this.fakeBear = null;
        this.realBearTxt = null;
        this.fakeBearTxt = null;
        this.enableClick = true;
        this.root.swing_mc.paused = false;
    };

    resetGameProps = () => {
        this.correctAnswers = 0;
    };

    chooserUp = () => {
        stopAllSounds();
        this.root.swing_mc.stop();
        this.realBear.stop();
        this.fakeBear.stop();
        // this.root.swing_mc.children.forEach((child: MC) => {
        //     child.stop();
        // });
        this.enableClick = false;
        this.resetSwing = true;
    };

    chooserDown = () => {
        this.enableClick = true;
        this.root.swing_mc.paused = false;
        // this.root.
        this.resetSwing = true;
        this.newQuestion();
    };

    startGame = () => {
        this.resetGameProps();
        this.newQuestion();
    };

    newQuestion = () => {
        this.resetProps();
        this.resetSwing && this.root.swing_mc.gotoAndPlay(0);
        this.generateQuestion();
        this.helpNum = this.answer;
        this.root.swing_mc.answer_sign.txt.text = this.answer;
        this.randomCorrectBear();
        this.displayQuestion();
    };

    displayQuestion = async () => {
        this.root.swing_mc.sign_anim.sign.txt.text = this.answer;
        await waitForEvent(this.root, "restartAnim");
        await loadSound(`/math/sounds/car_swing/Swing_${this.answer}.mp3`);
        this.root.swing_mc.gotoAndStop("sign_up");
        this.root.swing_mc.gotoAndPlay("sign_up");
        await waitForEvent(this.root, "signUp");
        await playSound(`/math/sounds/car_swing/Swing_${this.answer}.mp3`);
        this.enableHelp();
        this.realBearTxt.txt.text = `${this.number1}${this.currentMethod}${this.number2}`;
        this.fakeBearTxt.txt.text = `${this.number1}${this.fakeMethod}${this.number2}`;
        this.realBear.gotoAndPlay("start");
        await waitForTimer(300);
        this.fakeBear.gotoAndPlay("start");
        this.addClickEvents();
    };

    addClickEvents = () => {
        this.realBear.cursor = "pointer";
        this.fakeBear.cursor = "pointer";
        this.realBear.addEventListener("click", async () => {
            if (this.enableClick) {
                this.disableHelp();
                this.enableClick = false;
                this.fakeBear.gotoAndPlay("remove");
                this.addPrize("prize_swing");
                this.sayGood();
                this.realBear.gotoAndPlay("sit");
                this.root.swing_mc.gotoAndPlay("end");
                this.correctAnswers++;

                await waitForEvent(this.realBear, "finishedSit");
                await this.sayQuestion();
                if (this.correctAnswers === 5) {
                    this.endGame();
                } else {
                    this.enableClick = true;
                    this.resetSwing = true;
                    this.newQuestion();
                }
            }
        });
        this.fakeBear.addEventListener("click", async () => {
            if (this.enableClick) {
                this.enableClick = false;
                await this.sayBad();
                this.enableClick = true;
            }
        });
    };

    randomCorrectBear = () => {
        const num = getRandomNumber(1, 2);
        switch (num) {
            case 1:
                this.realBear = this.root.swing_mc.bear1;
                this.fakeBear = this.root.swing_mc.bear2;
                this.realBearTxt = this.root.swing_mc.bear1.children[0].txt_holder;
                this.fakeBearTxt = this.root.swing_mc.bear2.txt_holder;
                this.root.swing_mc.bear1.children[0].txt2_holder.txt.text = `${this.number1}${this.currentMethod}${this.number2}`;
                break;
            case 2:
                this.realBear = this.root.swing_mc.bear2;
                this.fakeBear = this.root.swing_mc.bear1;
                this.realBearTxt = this.root.swing_mc.bear2.txt_holder;
                this.fakeBearTxt = this.root.swing_mc.bear1.children[0].txt_holder;
                this.root.swing_mc.bear1.children[0].txt2_holder.txt.text = `${this.number1}${this.fakeMethod}${this.number2}`;
                break;
        }
    };

    generateQuestion = () => {
        const rnd = getRandomNumber(0, 1);
        if (rnd === 0) this.method = "add";
        else this.method = "sub";
        switch (this.method) {
            case "add":
                this.answer = getRandomNumber(2, 9);
                this.number1 = getRandomNumber(1, this.answer - 1);
                if (this.chosenLevel === 1) this.answer = this.number1 + 1;
                this.number2 = this.answer - this.number1;
                this.currentMethod = "+";
                this.fakeMethod = "-";
                break;
            case "sub":
                this.number1 = getRandomNumber(2, 9);
                this.answer = getRandomNumber(0, this.number1 - 1);
                if (this.chosenLevel === 1) this.answer = this.number1 - 1;
                this.number2 = this.number1 - this.answer;
                this.currentMethod = "-";
                this.fakeMethod = "+";
                break;
        }
        this.loadQuestionSounds();
    };

    sayQuestion = async () => {
        // await waitFor(() => this.shouldPlayInst === false);
        await playSound(`/math/sounds/car_swing/Excercize_${this.number1}_first.mp3`);

        this.currentMethod === "+"
            ? await playSound(`/math/sounds/car_swing/cars_gues_plus.mp3`)
            : await playSound(`/math/sounds/car_swing/cars_gues_minus.mp3`);
        await playSound(`/math/sounds/car_swing/Excercize_${this.number2}_middle.mp3`);
        await playSound(`/math/sounds/car_swing/cars_gues_equales.mp3`);
        await playSound(`/math/sounds/car_swing/Excercize_${this.answer}_last.mp3`);
    };

    loadQuestionSounds = async () => {
        await loadSound(`/math/sounds/car_swing/Excercize_${this.number1}_first.mp3`);
        this.currentMethod === "+"
            ? await loadSound(`/math/sounds/car_swing/cars_gues_plus.mp3`)
            : await loadSound(`/math/sounds/car_swing/cars_gues_minus.mp3`);
        await loadSound(`/math/sounds/car_swing/Excercize_${this.number2}_middle.mp3`);
        await loadSound(`/math/sounds/car_swing/cars_gues_equales.mp3`);
        await loadSound(`/math/sounds/car_swing/Excercize_${this.answer}_last.mp3`);
    };

    sayGood = async () => {
        const num = getRandomNumber(1, 9);
        await playSound(`m_good${num}`);
    };

    sayBad = async () => {
        const num = getRandomNumber(1, 2);
        await loadSound(`/math/sounds/frogs/wrong${num}.mp3`);
        await playSound(`/math/sounds/frogs/wrong${num}.mp3`);
    };

    endGame = async () => {
        this.root.swing_mc.answer_sign_end.txt.text = this.answer;
        this.root.swing_mc.answer_sign_end_1.txt.text = this.answer;
        await loadSound("/math/sounds/car_swing/EndNadned.mp3");
        this.realBear.visible = false;
        this.realBear.gotoAndStop(0);
        this.realBear.visible = true;
        this.gamePlayed++;
        playSound("/math/sounds/car_swing/EndNadned.mp3");
        this.root.swing_mc.gotoAndPlay("big_end");
        await waitForEvent(this.root, "animationEnd");
        this.resetSwing = true;
        this.cleanPrizes();
        await waitForTimer(1000);
    };

    loaded = async () => {};
}
