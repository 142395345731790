import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { BulbGame } from "./BulbGame";
import additionalSounds from "./BulbGameSounds";

const BulbComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new BulbGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/bulb"
            animationName="bulb"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default BulbComponent;
