export const getCampaignPage = async () => {
    try {
        const res = await fetch("/parentsInfo/campaigns.aspx", {
            method: "get",
        });
        if (res.ok) {
            return res;
        }
        return "SomethingWrong";
    } catch (ignore) {
        return "SomethingWrong";
    }
};
