import { MC, playSoundSync, waitForTimer, waitForEvent } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { Navigation } from "../../navigation/navigation";
import { loadSound, soundPlayed } from "../../animate/sound_utils";

export class LetterMenu {
    root: MC;
    bgSound: any = null;
    buttonSound: any = null;
    confetti: MC;
    letterData: boolean[];

    constructor(private letter: number) {}

    initButton = (bottomButton: boolean, button: MC, path: string, buttonNum?: number) => {
        let soundName: any;
        const sounds = ["lessonTooltip", "videoTooltip", "fancyTooltip", "creativeTooltip", "home", "back"];
        const done = this.letterData ? this.letterData[buttonNum] : false;
        button.gotoAndStop(done ? "selected_up" : 0);
        button.addEventListener("rollover", () => {
            if (bottomButton) {
                soundName = button.name[button.name.length - 1];
            } else {
                soundName = button.name === "closeBtn" ? 4 : 5;
            }
            this.buttonSound = playSoundSync(sounds[soundName]);
            const done = this.letterData ? this.letterData[buttonNum] : false;
            button.gotoAndStop(`${done ? "selected_" : ""}over`);
        });
        button.addEventListener("rollout", () => {
            if (bottomButton) this.buttonSound.volume = 0;
            const done = this.letterData ? this.letterData[buttonNum] : false;
            button.gotoAndStop(`${done ? "selected_" : ""}up`);
        });
        path !== "" &&
            button.addEventListener("click", () => {
                if (this.bgSound) this.bgSound.volume = 0;
                const done = this.letterData ? this.letterData[buttonNum] : false;
                button.gotoAndStop(`${done ? "selected_" : ""}up`);
                Navigation.openPage(path);
            });
        button.cursor = "pointer";
    };

    letterDone = async () => {
        const sound = loadSound(`/letters_island/letter_menu/sounds/letterCompleted/Letter${this.letter + 1}.mp3`);
        soundPlayed((await sound).play());
        this.root.addChild(this.confetti);
        this.confetti.x = 60;
        this.confetti.y = -20;
        this.confetti.scale = 1.3;
        this.confetti.visible = true;
        this.confetti.gotoAndPlay(0);
        this.root.setChildIndex(this.confetti, this.root.children.length - 5);
        if ([0, 1, 2, 3, 4].includes(this.letter)) {
            const bgEnd = new this.root.lib.bg_end1();
            this.root.addChild(bgEnd);
            this.root.setChildIndex(bgEnd, 3);
            bgEnd.scale = 0.429;
            bgEnd.x = 186;
            bgEnd.y = -105;
            bgEnd.gotoAndPlay(0);
        } else if ([12, 13, 14, 15, 16].includes(this.letter)) {
            const bgEnd = new this.root.lib.bg_end3();
            this.root.addChild(bgEnd);
            this.root.setChildIndex(bgEnd, 3);
            bgEnd.scale = 0.5;
            bgEnd.x = 187;
            bgEnd.y = -145;
            bgEnd.gotoAndPlay(0);
        }

        this.confetti.visible = true;
        this.confetti.gotoAndPlay(0);
        return waitForEvent(this.confetti, "animationEnd");
    };

    loaded = async (root: MC, letterData: boolean[]) => {
        (window as any).menu = this;
        this.root = root;
        this.letterData = letterData;
        this.root.gotoAndStop(this.letter);
        this.root.children.forEach((child: MC) => {
            child.gotoAndStop && child.gotoAndStop(0);
        });
        this.root.btn0.inner.gotoAndStop(this.letter);
        this.initButton(false, this.root.backBtn, routes.letters.menu);
        this.initButton(false, this.root.closeBtn, routes.home);
        this.initButton(
            true,
            this.root.btn0,
            routes.letters.activities.enter_exit.replace(":letterIndex", this.letter + "").replace(":action", "enter"),
            0
        );
        this.initButton(
            true,
            this.root.btn1,
            routes.letters.activities.video.replace(":letterIndex", this.letter + ""),
            1
        );
        this.initButton(
            true,
            this.root.btn2,
            routes.letters.activities.fancy_word.replace(":letterIndex", this.letter + 1 + ""),
            2
        );
        this.initButton(
            true,
            this.root.btn3,
            routes.letters.activities.creation.replace(":letterIndex", this.letter + ""),
            3
        );
        if (this.root.foregroundMc) this.root.foregroundMc.visible = false;
        this.bgSound = playSoundSync("bgLetterMenu", { loop: -1 });
        this.bgSound.volume = 0.3;
        this.confetti = new this.root.lib.confettiToShow();
        await waitForTimer(1500);
    };

    updateLetterData = (letterData: boolean[]) => {
        this.letterData = letterData;
    };

    unload = () => {};
}
