import { waitFor } from "../animate/utils";

const jsonData = require("../creation_corner/mapping/letterImages.json");

export class CreationApi {
    private letterIndex?: string;
    private nID: string;
    private nView: string;

    private userInfo: any = null;
    private workInfo: any = null;
    private workIdInfo: any = null;

    static forLetter(letterIndex: string) {
        const api = new CreationApi();
        api.letterIndex = letterIndex;
        return api;
    }

    static forWork(nID: string = "0", nView: string = "1") {
        const api = new CreationApi();
        api.nID = nID;
        api.nView = nView;

        return api;
    }

    getInfo = async () => {
        let response;
        if (this.letterIndex) {
            response = await fetch(
                `/gallery/singlework.aspx?sGalleryMode=2&nView=1&nFrom=menu&nLetter=${this.letterIndex}`
            );
        } else if (this.nView === "2") {
            response = await fetch(`gallery/SingleWork.aspx?nID=${this.nID}&nView=${this.nView}&sMode=mail`);
            //mail
        } else {
            response = await fetch(` gallery/SingleWork.aspx?nID=${this.nID}&nView=${this.nView}&nFrom=menu`);
        }
        const text = await response.text();
        this.workInfo = this.getWorkInfo(text);
        this.userInfo = this.getUserInfo(text);
    };

    getDisplayForLetter = (index: string) => {
        const letterDump = jsonData[parseInt(index) - 1].sDump;
        const res = this.parseLetterDump(letterDump);
        return res;
    };

    private parseLetterDump = (letterDump: any) => {
        const objects = letterDump.split("|");
        const result = [];
        for (let i = 1; i < objects.length; i++) {
            const params = objects[i].split("&amp;").map((pair: any) => pair.split(":"));
            result.push(Object.fromEntries(params));
        }
        return result;
    };

    private parseDump = (letterDump: any) => {
        const objects = letterDump.split("|");
        const result = [];
        for (let i = 0; i < objects.length; i++) {
            const params = objects[i].split("&").map((pair: any) => pair.split(":"));
            result.push(Object.fromEntries(params));
        }
        return result;
    };

    private getUserInfo = (text: string) => {
        const matches = text.matchAll(/object\.SetVariable\("(.+)", "(.+)"\);/g);
        let result: any = [];
        for (const match of matches) {
            result.push(match.slice(1, match.length));
        }
        const responseObj = Object.fromEntries(result);
        return responseObj;
    };

    private getWorkInfo = (text: string) => {
        const matches = text.matchAll(/oMyWork\.SetVariable\("(.+)", (.+)\);/g);
        let result: any = [];
        for (const match of matches) {
            result.push(match.slice(1, match.length));
            // console.log(match[1], match[2]);
        }
        const responseObj = Object.fromEntries(result);
        return responseObj;
    };
    getWorkId = () => {
        return this.workIdInfo["HTML_nWorkID"];
    };
    getUserName = async () => {
        await waitFor(() => this.userInfo);
        return this.userInfo["HTML_sUserName"];
    };
    getSiteUrl = async () => {
        await waitFor(() => this.userInfo);
        return this.userInfo["HTML_sURL"];
    };
    getContactsList = () => {
        return this.workInfo["/cContacts:contactList"];
    };
    getDump = async () => {
        await waitFor(() => this.userInfo);
        const dump = this.userInfo["HTML_sWorkDump"];
        return this.parseDump(dump);
    };

    saveRequest = (sDump: string, nWorkID: string = "0") => {
        const data = new URLSearchParams();
        data.set("sToDo", "save");
        data.set("nWorkID", nWorkID);
        data.set("sDump", sDump);
        return data;
    };
    deleteRequest = (nActualDelete: string) => {
        const data = new URLSearchParams();
        data.set("nActualDelete", nActualDelete);
        return data;
    };
    // saveCreationFromLettersIsland = async (
    //     sDump: string,
    //     mode: number,
    //     nView: number,
    //     from: string,
    //     letterNumber: string
    // ) => {
    //     const response = await fetch(
    //         `/gallery/singlework.aspx?sGalleryMode=${mode}&nView=${nView}&nFrom=${from}&nLetter=${letterNumber}`,
    //         {
    //             body: this.saveRequest(sDump),
    //             method: "POST",
    //             mode: "cors",
    //             credentials: "include",
    //         }
    //     );
    //     const text = await response.text();
    //     this.workIdInfo = this.getUserInfo(text);
    //     return this.getWorkId();
    // };

    saveCreation = async (sDump: string, nId: string, nView: number, from: string, nRefreshParameter?: number) => {
        const response = await fetch(`/gallery/SingleWork.aspx?nID=${nId}&nView=${nView}&nFrom=${from}`, {
            body: this.saveRequest(sDump, nId),
            method: "POST",
            mode: "cors",
            credentials: "include",
        });
        const text = await response.text();
        this.workIdInfo = this.getUserInfo(text);
        return this.getWorkId();
    };

    deleteCreation = async (nId: string) => {
        return await fetch(`/gallery/DeleteWork.aspx?nPotentialDelete=${nId}`, {
            body: this.deleteRequest(nId),
            method: "POST",
            mode: "cors",
            credentials: "include",
        });
    };
}
