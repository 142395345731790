import React from "react";
import AnimateCC from "../../../animate";
import { NumberTenGame } from "./NumberTenGame";
import { BigWheelGame } from "../BigWheelGame";
import additionalSounds from "./NumberTenSounds";
const NumbeTenComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberTenGame(frame));

    return (
        <AnimateCC
            //base="/lunapark/sweets/popcorn"
            base="/lunapark/big_wheel/number_ten"
            animationName="number_ten"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default NumbeTenComponent;
