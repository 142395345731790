import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { NoseGame } from "./NoseGame";
import { additionalSounds } from "./NoseSounds";
const NoseComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new NoseGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/nose"
            animationName="nose"
            onLoaded={game.current.loaded}
            additionalSounds={additionalSounds}
        />
    );
};
export default NoseComponent;
