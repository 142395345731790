import { Kite } from "./Kite";

export class KiteGame {
    constructor(private sectionIndex: any) {}

    private Kite: Kite = null;
    kiteLoaded = (mc: createjs.MovieClip, lettersContext: any) => {
        this.Kite = new Kite(mc, this.sectionIndex, lettersContext, null, null);
    };

    unload = () => {};
}
