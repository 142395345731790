export const additionalSounds = [
    {
        src: "sounds/DeleteWork.mp3",
        id: "delete",
    },
    {
        src: "sounds/EditWork.mp3",
        id: "edit",
    },
    {
        src: "sounds/viewWork.mp3",
        id: "view",
    },
    {
        src: "sounds/MyWorksLoop.mp3",
        id: "bgSound",
    },
];
