import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MusicGameComponent } from "../musicGame/MusicGameComponent";
import { MusicSectionGame } from "../musicGame/MusicSectionGame";
import { Simon } from "./Simon";

export function SimonGameComponent() {
    const game = React.useRef(new MusicSectionGame());
    return (
        <MusicGameComponent
            animationName="simon"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Simon(mc))}
            levels={3}
            game={game}
            shiftX={110}
            shiftY={110}
            scaleX={0.97}
            changeFramerate={true}
        />
    );
}
