import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { WindowGame } from "./WindowGame";
const WindowComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new WindowGame(frame));

    return <AnimateCC base="/lunapark/abc/window" animationName="window" onLoaded={game.current.loaded} />;
};
export default WindowComponent;
