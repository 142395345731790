import { MC, optimize, playSound, playSoundSync, setupStage, waitForEvent } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

export class TreeGame {
    private root: MC;
    private fallingApples: MC = [];
    private fallingLeaves: MC = [];
    private leaves: MC = [];
    private flowers: MC = [];
    private apples: MC = [];
    private should_change_bg: boolean = true;
    private snow_started: boolean = false;
    private delta: number;
    private normal: number;
    private snow = false;
    private snowFallingArr: MC = [];
    private snowArr: MC = [];
    private finishSnow = false;
    private lastSnowX = 0;
    private lastSnowY = 0;
    private finishedBirdFlight = false;
    private overed: MC;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).tree = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.hand.visible = false;
        this.start();
        playSound("tree_sound");
        this.addEventListeners();
        this.resetBirds();
        this.addMouseOverListener();
    };
    start = () => {
        this.root.children.forEach((child: MC) => {
            if (child.leaf) {
                this.changeReferencePoint(child);
                this.initLeaf(child);
                //   this.addLeafListener(child);
                child.cursor = "default";
                child.mouseChildren = false;
            } else if (child.flower) {
                this.initFlower(child);
                this.addFlowerListener(child);
                child.initialY = child.y;
            }
        });
    };
    addMouseOverListener = () => {
        this.root.addEventListener("mouseover", (e: createjs.MouseEvent) => {
            this.overed = e.target;
            if (this.overed.leaf) {
                if (!this.flowers.length && !this.apples.length) {
                    this.overed.gotoAndStop(1);
                    this.fallingLeaves.push(this.overed);
                    this.overed.mouseEnabled = false;
                }
            }
        });
    };

    changeReferencePoint = (leaf: MC) => {
        leaf.regY += 18.5;
        leaf.y += 18.5;
        leaf.initialY = leaf.y;
    };

    initLeaf = (leaf: MC) => {
        this.chooseChild(leaf);
        if (!leaf.visible) return;
        leaf.gotoAndStop(0);
        leaf.scale = (getRandomNumber(0, 50) + 50) / 100;
        leaf.rotation = getRandomNumber(1, 360);
        leaf.drop = 0.5;
    };
    initFlower = (flower: MC) => {
        this.chooseChild(flower);
        flower.gotoAndStop(0);
        flower.rotation = getRandomNumber(0, 50) - 25;
        flower.scale = (getRandomNumber(0, 50) + 50) / 100;
        flower.drop = 1;
    };

    addFlowerListener = (flower: MC) => {
        flower.addEventListener("mouseover", () => {
            if (this.isFlower(flower)) {
                flower.gotoAndStop(1); //apple
                let num = getRandomNumber(1, 4);
                const dropSound = playSoundSync("drop_" + num);
                dropSound.volume = 0.3;
                this.flowers.splice(this.fallingApples.indexOf(flower), 1);
                this.apples.push(flower);
            } else {
                if (this.flowers.length === 0) {
                    //can fall
                    this.fallingApples.push(flower); //start falling
                }
            }
        });
    };
    addEventListeners = () => {
        this.root.addEventListener("tick", this.playGame);
    };

    playGame = (e: any) => {
        this.normal = 1000 / this.root.framerate;
        this.delta = (e as createjs.TickerEvent).delta / this.normal;
        this.fallingApples.forEach((apple: MC) => {
            this.dropApple(apple);
        });
        if (this.leaves.length > 0) {
            this.fallingLeaves.forEach((leaf: MC) => {
                this.dropLeaf(leaf);
            });
        } else if (this.finishedBirdFlight) {
            !this.snow_started && this.startSnow();
        }
        if (this.snowFallingArr.length) {
            this.fallingSnow();
        }
        if (this.snow) {
            if (!this.finishSnow) this.newSnow();
        }
    };

    dropApple = async (apple: MC) => {
        apple.y += apple.drop * this.delta;
        apple.drop *= 1.1 * this.delta;
        if (apple.y > 380) {
            this.fallingApples.splice(this.fallingApples.indexOf(apple), 1);
            apple.y = 396;
            apple.removeAllEventListeners();
            if (this.fallingApples.indexOf(apple) < 0) {
                this.apples.splice(this.apples.indexOf(apple), 1);
                // apple.fall = true;
                let num = getRandomNumber(1, 4);
                const dropSound = playSoundSync("drop_" + num);
                dropSound.volume = 0.3;
            }
        }
        if (!this.apples.length && this.should_change_bg) {
            //&& this.flowers.length === 0
            this.should_change_bg = false;
            this.root.gotoAndPlay(25);
            await waitForEvent(this.root, "birds");
            this.root.gotoAndStop(49);
            this.startBirds();
        }
    };
    dropLeaf = async (leaf: MC) => {
        leaf.y += leaf.drop * this.delta;
        leaf.drop *= 1.05 * this.delta;
        if (leaf.y > 380) {
            leaf.y = 400;
            this.fallingLeaves.splice(this.fallingLeaves.indexOf(leaf), 1);
            this.leaves.splice(this.leaves.indexOf(leaf), 1);
        }
    };

    startSnow = async () => {
        if (!this.snow) {
            this.snow_started = true;
            this.root.gotoAndPlay(49);
            await waitForEvent(this.root, "stopbg");
            this.root.gotoAndStop(74);
            this.root.snow.alpha = 0;
            this.snow = true;
        }
    };
    newSnow = () => {
        let num = getRandomNumber(0, 1);
        if (num === 0) {
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            if (this.lastSnowX !== localMouse.x || this.lastSnowY !== localMouse.y) {
                const newSnow = new this.root.lib.snowHand();
                newSnow.x = localMouse.x;
                newSnow.y = localMouse.y;
                this.root.addChild(newSnow);
                optimize(newSnow);
                newSnow.gotoAndStop(1);
                this.snowFallingArr.push(newSnow);
                this.snowArr.push(newSnow);
                this.lastSnowX = localMouse.x;
                this.lastSnowY = localMouse.y;
            }
        }
    };
    fallingSnow = () => {
        this.snowFallingArr.forEach((snow: MC) => {
            snow.y += 4 * this.delta;
            if (snow.y > 406) {
                snow.gotoAndStop(4);
                this.root.snow.alpha += 0.01;
                this.snowFallingArr.splice(this.snowFallingArr.indexOf(snow), 1);
                if (this.root.snow.alpha >= 1) {
                    if (!this.finishSnow) {
                        this.finishSnow = true;
                        // this.root.removeEventListener("tick", this.playGame );
                        this.finishGame();
                    }
                }
            }
        });
    };

    finishGame = async () => {
        this.snow = false;
        this.root.gotoAndPlay(75);
        await waitForEvent(this.root, "removeSnow");
        this.snowArr.forEach((s: MC) => {
            s.visible = false;
            //   this.snowArr.pop();
        });
        this.snowArr = [];
        await waitForEvent(this.root, "finishGame");
        this.resetGame();
    };
    startBirds = () => {
        const birdSound = playSoundSync("geese", -1);
        birdSound.volume = 0.3;
        this.finishedBirdFlight = false;
        createjs.Tween.get(this.root.bird_1)
            .to(
                {
                    x: 0,
                },
                12000
            )
            .call(async () => {
                this.finishedBirdFlight = true;
                birdSound.stop();
            });
        createjs.Tween.get(this.root.bird_2).to(
            {
                x: 0,
            },
            11000
        );
    };
    resetGame = () => {
        this.resetArrays();
        this.resetBirds();
        this.root.gotoAndStop(24);
        this.root.children.forEach((child: MC) => {
            if (child.flower || child.leaf) {
                child.visible = true;
                child.y = child.initialY;
                child.mouseEnabled = true;
                if (child.flower) {
                    this.initFlower(child);
                    this.addFlowerListener(child);
                } else {
                    this.initLeaf(child);
                }
            }
        });
        this.resetBooleans();
    };

    isFlower = (flower: any) => {
        return flower.currentFrame === 0;
    };
    chooseChild = (child: MC) => {
        if (getRandomNumber(0, 2) === 0) {
            child.visible = false;
        } else {
            if (child.flower) this.flowers.push(child);
            else this.leaves.push(child);
        }
    };
    resetArrays = () => {
        this.flowers = [];
        this.leaves = [];
        this.fallingApples = [];
        this.fallingLeaves = [];
        this.leaves = [];
        this.flowers = [];
        this.snowFallingArr = [];
        this.snowArr = [];
        this.apples = [];
    };
    resetBooleans = () => {
        this.should_change_bg = true;
        this.snow_started = false;
        this.snow = false;
        this.finishSnow = false;
    };
    resetBirds = () => {
        this.root.bird_1.x = 990.75;
        this.root.bird_2.x = 1015.35;
    };
}
