import React from "react";
import { MC } from "../../animate/utils";
import { MusicGameComponent } from "../musicGame/MusicGameComponent";
import sounds from "./chicken_sound.json";
import { MusicSectionGame } from "../musicGame/MusicSectionGame";
import { Chickens } from "./Chickens";

export function ChickensGameComponent() {
    const game = React.useRef(new MusicSectionGame());
    return (
        <MusicGameComponent
            animationName="chickens"
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Chickens(mc))}
            game={game}
            levels={3}
            shiftX={130}
            shiftY={140}
            scale={0.637}
            additionalSounds={sounds}
        />
    );
}
