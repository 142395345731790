import { MC, setupStage, stopAllSounds, waitForTimer } from "../animate/utils";
import ZigiColorsGame from "./ZigiGames/ZigiColorsGame";
import ZigiFaceGame from "./ZigiGames/ZigiFaceGame";
import ZigiNumbersGame from "./ZigiGames/ZigiNumbersGame";
import ZigiNounsGame from "./ZigiGames/ZigiNounsGames";
import ZigiVerbsGame from "./ZigiGames/ZigiVerbsGame";
import { ZigiGameBase } from "./ZigiGames/ZigiGameBase";
import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { loadSound, soundPlayed } from "../animate/sound_utils";
import { MutableRefObject } from "react";
import { VideoPlayer } from "../video/VideoPlayer";

const VIDEOS = {
    nouns: "/movies/english/0.mp4",
    face: "/movies/english/1.mp4",
    colors: "/movies/english/2.mp4",
    verbs: "/movies/english/3.mp4",
    numbers: "/movies/english/4.mp4",
};
const helpSoundPath = loadSound("/english/sounds/frameSounds/help.mp3");
const exitSoundPath = loadSound("/english/sounds/frameSounds/exit.mp3");

export type EnglishTimeGameTypes = keyof typeof VIDEOS;

export class EnglishTime {
    frame: MC;
    gameType: EnglishTimeGameTypes;
    private gameMode: "song" | "instilling" | "exercise" = "song";

    constructor(private videoPlayer: MutableRefObject<VideoPlayer>, private setVideoSrc: (value: string) => void) {}

    englishTimeFrameLoaded = (root: MC) => {
        this.frame = root;
        setupStage(this.frame, this.zigiCurrentGame?.root);
        this.updateFrameButtons();
        this.frameButtonsEvents();
        this.exitButtonEvents();
        this.helpBtn();
        this.startVideo(true);
    };

    frameButtonsEvents = () => {
        const frameButtons = ["instilling", "exercise", "song"];
        for (let i = 0; i < 3; i++) {
            const currentBtn = this.frame[`${frameButtons[i]}_btn`];
            const btnSound = loadSound(`/english/sounds/frameSounds/${frameButtons[i]}.mp3`);
            currentBtn.gameMode = frameButtons[i];
            currentBtn.cursor = "pointer";
            currentBtn.addEventListener("click", () => {
                this.gameMode = currentBtn.gameMode;
                stopAllSounds();
                this.zigiCurrentGame.resetGame();
                this.updateFrameButtons();
                switch (this.gameMode) {
                    case "song":
                        this.startVideo(false);
                        break;
                    case "instilling":
                        this.stopVideo();
                        this.zigiCurrentGame.startLearn();
                        break;
                    case "exercise":
                        this.stopVideo();
                        this.zigiCurrentGame.startExercise();
                        break;
                }
            });
            currentBtn.addEventListener("rollover", async () => {
                if (currentBtn.gameMode !== this.gameMode) {
                    currentBtn.gotoAndStop("over");
                    if (this.zigiCurrentGame && this.gameMode !== "song" && this.zigiCurrentGame.enabledFramBtn) {
                        soundPlayed((await btnSound).play());
                    }
                }
            });
            currentBtn.addEventListener("rollout", () => {
                if (currentBtn.gameMode !== this.gameMode) {
                    currentBtn.gotoAndStop("up");
                }
            });
        }
    };

    updateFrameButtons = () => {
        const songBtn = this.frame.song_btn;
        const learnBtn = this.frame.instilling_btn;
        const exerciseBtn = this.frame.exercise_btn;

        const updateButton = (btn: MC, isDown: boolean) => {
            btn.gotoAndStop(isDown ? "down" : "up");
            btn.mouseEnabled = !isDown;
        };
        updateButton(songBtn, this.gameMode === "song");
        updateButton(learnBtn, this.gameMode === "instilling");
        updateButton(exerciseBtn, this.gameMode === "exercise");

        this.frame.frame_mc.gotoAndStop(this.gameMode);
    };

    helpBtn = () => {
        const btn = this.frame.help_btn;
        if (this.zigiCurrentGame && this.zigiCurrentGame.enabledFramBtn && this.gameMode !== "song") {
            btn.cursor = "pointer";
        }
        btn.mouseChildren = false;
        btn.gotoAndStop(0);
        btn.addEventListener("rollover", async () => {
            if (this.zigiCurrentGame && this.zigiCurrentGame.enabledFramBtn && this.gameMode !== "song") {
                soundPlayed((await helpSoundPath).play());
                btn.cursor = "pointer";
                btn.gotoAndStop("over");
            }
        });
        btn.addEventListener("rollout", () => {
            if (this.zigiCurrentGame && this.zigiCurrentGame.enabledFramBtn && this.gameMode !== "song") {
                stopAllSounds();
                btn.gotoAndStop("up");
            }
        });
        btn.addEventListener("click", () => {
            if (this.zigiCurrentGame && this.zigiCurrentGame.enabledFramBtn && this.gameMode !== "song") {
                this.zigiCurrentGame.help();
                btn.cursor = "auto";
                btn.gotoAndStop("up");
            }
        });
    };

    exitButtonEvents = () => {
        const btn = this.frame.exit_btn;
        btn.gotoAndStop(0);
        btn.cursor = "pointer";
        btn.mouseChildren = false;
        btn.addEventListener("click", () => {
            stopAllSounds();
            this.stopVideo();
            this.zigiCurrentGame.shouldPlay = false;
            this.frame = null;
            this.zigiCurrentGame = null;
            this.gameMode = "song";
            Navigation.openPage(routes.english_time.menu);
        });
        btn.addEventListener("rollover", async () => {
            if (this.zigiCurrentGame && this.gameMode !== "song" && this.zigiCurrentGame.enabledFramBtn) {
                soundPlayed((await exitSoundPath).play());
            }
            btn.gotoAndStop("over");
        });
        btn.addEventListener("rollout", () => {
            if (this.zigiCurrentGame && this.zigiCurrentGame.enabledFramBtn && this.gameMode !== "song") {
                stopAllSounds();
            }
            btn.gotoAndStop("up");
        });
    };

    startVideo = (fromHomePage: boolean) => {
        this.setVideoSrc(VIDEOS[this.gameType]);
        if (fromHomePage) {
            this.frame.preloader_mc.visible = true;
        }
    };

    stopVideo = () => {
        this.setVideoSrc("");
    };

    videoCanBeStarted = async () => {
        this.frame.preloader_mc.visible = false;
        await waitForTimer(0);
        this.videoPlayer.current.play();
    };

    videoEnded = () => {
        this.setVideoSrc("");
        this.gameMode = "instilling";
        this.zigiCurrentGame.resetGame();
        this.zigiCurrentGame.startLearn();
        this.updateFrameButtons();
    };

    private zigiCurrentGame: ZigiGameBase;

    englishTimeGameLoaded = (root: MC) => {
        this.zigiCurrentGame = this.returnCurrentGame();
        this.zigiCurrentGame.loaded(root);

        setupStage(this.frame, this.zigiCurrentGame?.root);
    };

    returnCurrentGame = () => {
        switch (this.gameType) {
            case "colors":
                return new ZigiColorsGame();
            case "verbs":
                return new ZigiVerbsGame();
            case "numbers":
                return new ZigiNumbersGame();
            case "face":
                return new ZigiFaceGame();
            case "nouns":
                return new ZigiNounsGame();
        }
    };
}
