import { Frame } from "./Frame";
import MusicGameBase from "./MusicGameBase";
import { setupStage, stopAllSounds } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { getInstSoundForHelp } from "../../utils/MusicGameUtil";
export class MusicSectionGame {
    private frame: Frame = null;
    private game: MusicGameBase = null;
    private level: number = 1;

    gameLoaded = (mc: createjs.MovieClip, game: MusicGameBase) => {
        this.game = game;
        game.enableHelp = this.enableHelp;
        game.disableHelp = this.disableHelp;
        this.stopPreloader();
        !(this.game as any).help && this.enableHelp();
        this.game.enableHelp = this.enableHelp;
        this.game.disableHelp = this.disableHelp;
        this.game.loaded(this.level);
        setupStage(this.frame?.root, this.game?.root);
    };

    help = async () => {
        this.disableHelp();
        if ((this.game as any).help) {
            (this.game as any).help();
        } else {
            getInstSoundForHelp(
                this.game.name,
                (this.game as any).instrument ? (this.game as any).instrument : null
            ).then(() => {
                this.enableHelp();
            });
        }
    };

    disableHelp = () => {
        const btn = this.frame.root.btnHelp;
        btn.cursor = "default";
        btn.removeAllEventListeners();
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("up");
        });
    };

    enableHelp = () => {
        const btn = this.frame.root.btnHelp;
        btn.gotoAndStop("up");
        this.frame.setFrameBtn(btn, "help");
        btn.addEventListener("click", this.help);
    };

    stopPreloader = () => {
        this.frame?.stopPreloader();
    };

    chooseLevel = (level: number) => {
        const levelMc = this.frame.root[`btnLevel${level}`];
        levelMc.cursor = "default";
        levelMc.gotoAndStop("down");
        levelMc.removeAllEventListeners();
    };

    InitLevel = (level: number) => {
        const levelMc = this.frame.root[`btnLevel${level}`];
        levelMc.cursor = "pointer";
        levelMc.gotoAndStop("up");
        levelMc.removeAllEventListeners();
        levelMc.addEventListener("rollover", () => {
            levelMc.gotoAndStop("over");
        });
        levelMc.addEventListener("rollout", () => {
            levelMc.gotoAndStop("up");
        });
        levelMc.addEventListener("click", () => {
            this.handleLevel(level);
        });
    };

    handleLevel = (level: number, isFirst?: boolean) => {
        !isFirst && this.game && this.game.handleLevel(level);
        this.level = level;
        [1, 2, 3].forEach((levelIndex: number) => {
            if (levelIndex === level) {
                this.chooseLevel(levelIndex);
            } else {
                this.InitLevel(levelIndex);
            }
        });
    };

    frameLoaded = (mc: createjs.MovieClip, levels: number) => {
        this.frame = new Frame(mc);
        setupStage(this.frame?.root, this.game?.root);
        this.frame.loaded(levels);
        this.handleLevel(1, true);
        this.frame.root.btnExit.addEventListener("click", () => {
            (this.game as any).clearPatternListener?.();
            stopAllSounds();
            Navigation.openPage(routes.music.menu);
        });
    };

    unload = () => {
        if (this.game) {
            this.game.unload();
            this.game = null;
        }
        if (this.frame) {
            this.frame.unload();
            this.game = null;
        }
    };
}
