import MusicGameBase from "../musicGame/MusicGameBase";
import { MC, waitForEvent, waitForTimer, playSound, stopAllSounds } from "../../animate/utils";
import { CancelablePromise } from "../../utils/CancelablePromise";
import beatsJson from "./BeatsData.json";
import { getRandomArr, getRandomNumber } from "../../utils/MathUtils";
const GAMES_TO_COMPLETE = 3;
export class Chickens extends MusicGameBase {
    name = "chicken";
    level = 0;
    drumSetList: any[];
    drumbCounter = 0;
    prizesCounter = 0;
    waitForTimeout: any = null;
    wrongCount = 0;
    listIndexes: number[] = [];
    patternInterval: any[] = [];
    awaitingPromise: CancelablePromise<any>;
    gameOver = false;
    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).chickens = root;
    }

    async cancelableWait(p: Promise<any>) {
        this.awaitingPromise = p as CancelablePromise<any>;
        await p;
        this.awaitingPromise = null;
    }

    resetWings = () => {
        this.root.chick1_mc.rightWing_mc.gotoAndStop(0);
        this.root.chick1_mc.leftWing_mc.gotoAndStop(0);
        this.root.chick2_mc.rightWing_mc.gotoAndStop(0);
        this.root.chick2_mc.leftWing_mc.gotoAndStop(0);
    };

    playWing = async (wing: MC) => {
        wing.gotoAndStop(0);
        playSound(`drum_${wing.name}`);
        wing.gotoAndPlay(1);
        return this.cancelableWait(
            waitForEvent(wing, "wingPlayed").then(() => {
                this.resetWings();
            })
        );
    };

    disableWings = () => {
        this.disableHelp();
        window.removeEventListener("keydown", this.checkKey);
        clearTimeout(this.waitForTimeout);
        [0, 1].forEach((index) => {
            const darbuka = this.root[`hit${index}_mc`];
            darbuka.cursor = "default";
            darbuka.removeAllEventListeners();
        });
    };

    handleBigEnd = async () => {
        this.root.chick1_mc.framerate = 25;
        this.root.chick2_mc.framerate = 25;
        this.root.chick1_mc.gotoAndPlay("level_end_anim");
        this.root.chick2_mc.gotoAndPlay("level_end_anim");
        await this.cancelableWait(waitForEvent(this.root.chick1_mc, "LevelAnimDone"));
        this.playGame();
    };

    handleSucess = async () => {
        this.root.chick2_mc.playing_mc.gotoAndStop(0);
        this.root[`sombrero${this.prizesCounter}`].gotoAndPlay("prize");
        this.prizesCounter++;
        if (this.prizesCounter === GAMES_TO_COMPLETE) {
            this.handleBigEnd();
            return;
        }
        this.root.chick1_mc.gotoAndPlay("good_anim");
        this.root.chick2_mc.gotoAndPlay("good_anim");
        await this.cancelableWait(waitForEvent(this.root.chick1_mc, "GoodAnimDone"));
        this.playPattern(false);
    };

    handleError = async () => {
        this.root.chick2_mc.playing_mc.gotoAndStop(0);
        this.disableWings();
        this.root.chick1_mc.gotoAndPlay("bad_anim");
        await this.cancelableWait(waitForEvent(this.root.chick1_mc, "BadAnimDone"));
        this.playPattern(true);
    };

    timeOver = async () => {
        this.disableWings();
        this.root.chick1_mc.gotoAndPlay("waiting_anim");
        await this.cancelableWait(waitForEvent(this.root.chick1_mc, "WaitAnimDone"));
        this.playPattern(true);
    };

    playAndCheck = async (wing: string, index: number) => {
        clearTimeout(this.waitForTimeout);
        this.disableWings();
        await this.playWing(this.root.chick2_mc[wing]);
        const indexes = ["B", "A"];
        const currentClicked = indexes[index];
        const { beat: beats } = this.drumSetList[this.prizesCounter];
        const currentRight = beats[this.drumbCounter].id;
        if (currentClicked === currentRight) {
            this.drumbCounter++;
            if (this.drumbCounter === beats.length) {
                this.handleSucess();
            } else {
                this.enableWings(4000);
            }
        } else {
            this.handleError();
        }
    };

    resetTimer = (timer: number) => {
        this.waitForTimeout = setTimeout(() => {
            this.timeOver();
        }, timer);
    };

    enableWings = (timeout: number) => {
        this.enableHelp();
        this.resetTimer(timeout);
        window.addEventListener("keydown", this.checkKey);
        ["leftWing_mc", "rightWing_mc"].forEach((wing, index) => {
            const darbuka = this.root[`hit${index}_mc`];
            darbuka.cursor = "pointer";
            darbuka.addEventListener("rollover", () => {
                this.root.chick2_mc.playing_mc.gotoAndStop(18);
            });
            darbuka.addEventListener("click", () => {
                this.playAndCheck(wing, index);
            });
        });
    };

    clearPatternListener = () => {
        for (let index = 0; index < this.patternInterval.length; index++) {
            clearTimeout(this.patternInterval[index]);
        }
    };

    resetGame = () => {
        this.clearPatternListener();
        this.awaitingPromise?.cancel?.();
        stopAllSounds();
        this.resetWings();
        this.disableWings();
        this.root.chick1_mc.gotoAndStop(0);
        this.root.chick2_mc.gotoAndStop(0);
    };

    handleLevel = async (level: number) => {
        this.resetGame();
        this.level = level;
        this.root.bg_mc.gotoAndStop(level - 1);
        this.playGame();
    };

    getNewValue = () => {
        const data = (beatsJson as any)[this.level];
        let index;
        do {
            index = getRandomNumber(0, data.set.length - 1);
        } while (this.listIndexes.includes(index));
        return data.set[index];
    };

    showDarbukaBorder = () => {
        this.root.chick2_mc.playing_mc.gotoAndPlay(1);
        this.cancelableWait(waitForEvent(this.root.chick2_mc.playing_mc, "playing_mc_done")).then(() => {
            this.root.chick2_mc.playing_mc.gotoAndStop(18);
        });
    };

    playPattern = async (isWrong: boolean) => {
        this.root.chick1_mc.framerate = 20;
        this.root.chick2_mc.framerate = 20;
        this.resetWings();
        if (isWrong) {
            this.wrongCount++;
            if (this.wrongCount === 2) {
                this.wrongCount = 0;
                this.drumSetList[this.prizesCounter] = this.getNewValue();
            }
        } else {
            this.wrongCount = 0;
        }
        this.root.chick2_mc.playing_mc.gotoAndStop(0);
        this.disableWings();
        this.drumbCounter = 0;
        var currentSet = this.drumSetList[this.prizesCounter];
        const { beat: beats, beatLength: interval } = currentSet;

        for (let index = 0; index < beats.length; index++) {
            this.patternInterval[index] = setTimeout(() => {
                const beat = beats[index];
                const wing = this.root.chick1_mc[beat.id === "A" ? "rightWing_mc" : "leftWing_mc"];
                this.playWing(wing);
                this.cancelableWait(waitForTimer(beat.size * interval));
            }, this.getPatternInterval(index));
        }
        const leftLogic = setTimeout(() => {
            this.showDarbukaBorder();
            this.enableWings(7000);
        }, this.getPatternInterval(beats.length));
        this.patternInterval.push(leftLogic);
    };

    getPatternInterval = (index: number) => {
        let retVal = 0;
        var currentSet = this.drumSetList[this.prizesCounter];
        const { beat: beats, beatLength: interval } = currentSet;
        for (let i = 0; i < index; i++) {
            retVal += beats[i].size * interval;
        }
        return retVal;
    };

    playGame = () => {
        const data = (beatsJson as any)[this.level];
        this.listIndexes = getRandomArr(0, data.set.length - 1, 3);
        this.drumSetList = [];
        for (let i = 0; i < 3; i++) {
            this.drumSetList.push(data.set[this.listIndexes[i]]);
            this.root[`sombrero${i}`].visible = true;
            this.root[`sombrero${i}`].gotoAndStop(0);
        }
        this.prizesCounter = 0;
        this.playPattern(false);
    };

    checkKey = (e: any) => {
        if (e.key === "ArrowLeft") {
            this.playAndCheck("leftWing_mc", 0);
        } else if (e.key === "ArrowRight") {
            this.playAndCheck("rightWing_mc", 1);
        }
    };

    help = async () => {
        clearTimeout(this.waitForTimeout);
        this.disableWings();
        this.root.chick2_mc.playing_mc.gotoAndStop(0);
        await this.cancelableWait(playSound("inst"));
        this.playPattern(false);
    };

    visibiltyChange = () => {
        if (document.visibilityState === "visible") {
            this.resetGame();
            if (!this.drumSetList) {
                this.handleLevel(1);
            } else if (this.gameOver) {
                this.playGame();
                this.gameOver = false;
            } else {
                this.clearPatternListener();
                this.playPattern(false);
            }
        } else {
            this.gameOver = this.prizesCounter === 3;
        }
    };

    unload = () => {
        window.removeEventListener("visibilitychange", this.visibiltyChange);
    };

    loaded = async () => {
        window.addEventListener("visibilitychange", this.visibiltyChange);
        for (let i = 0; i < 3; i++) {
            this.root[`sombrero${i}`].visible = false;
        }
        this.disableHelp();
        await this.cancelableWait(playSound("inst"));
        this.handleLevel(1);
    };
}
