import { MC } from "../../animate/utils";

export default class MusicGameBase {
    root: MC;

    enableHelp: () => void;
    disableHelp: () => void;
    loaded(level?: number) {}
    unload() {}
    handleLevel(level?: number) {}
    name: string;
}
