import { MC, playSound, playSoundSync, setupStage, waitForEvent } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
export class NumberOneGame {
    private root: MC;
    private open: boolean = false;
    private sound = playSoundSync("shut", -1).stop();
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).WheelGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.gotoAndStop(0);
        this.root.box.gotoAndStop(0);
        this.root.box.boxfront.addEventListener("click", async () => {
            if (!this.open) {
                if (this.sound) this.sound.stop();
                this.root.box.gotoAndPlay(0);
                playSound("jack");
                this.root.box.jack.hair.gotoAndStop(getRandomNumber(0, 6));
                this.root.box.jack.face.gotoAndStop(getRandomNumber(0, 6));
                this.root.box.jack.tie.gotoAndStop(getRandomNumber(0, 6));
                this.root.box.jack.body.gotoAndStop(getRandomNumber(0, 6));
                this.root.box.jack.hat.gotoAndStop(getRandomNumber(0, 6));
                this.open = true;
                await waitForEvent(this.root, "start sound");
                this.sound.play();
            } else {
                if (this.sound) this.sound.stop();
                this.root.box.gotoAndStop(0);
                this.open = false;
            }
        });
    };
}
