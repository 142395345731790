import {
    MC,
    asyncTween,
    playSound,
    waitForTimer,
    stopAllSounds,
    // waitForEvent,
} from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { BaseGame } from "./BaseGame";

export default class Game1 extends BaseGame {
    answersCounter = 0;
    feedbacksIndexes: any = [];
    frame: MC;
    helpBtn: any;

    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.helpBtn = helpBtn;
        game.visible = true;
        game.x = 950;
        game.y = 400;
        game.answers.visible = false;
        const booksIndex = [2, 0, 1];
        this.randomIndexes();

        await this.tweenObjects(game, booksIndex);
        if (!this.shouldPlay) return;

        await playSound("Instructions");
        if (!this.shouldPlay) return;

        helpBtn(false);
        await waitForTimer(300);

        if (!this.shouldPlay) return;
        for (let i = 0; i < 3; i++) {
            const currentBook = game[`home${i}`];
            currentBook.index = i;
            currentBook.mouseChildren = false;
            SoundToolTip.addToolTip(currentBook, `book_${i}`);
        }
        for (let i = 0; i < 3; i++) {
            const currentPic = game[`pic${i}`];
            currentPic.index = i;
            const currentX = currentPic.x;
            const currentY = currentPic.y;
            currentPic.cursor = "pointer";
            const startMove = (event: createjs.Event) => {
                stopAllSounds();
                event.stopImmediatePropagation();
                currentPic.addEventListener("tick", (e: any) => {
                    if ((e as createjs.TickerEvent).paused) return;
                    const parent: createjs.DisplayObject = currentPic.parent;
                    const position = parent.globalToLocal(parent.stage.mouseX, parent.stage.mouseY);
                    currentPic.x = position.x;
                    currentPic.y = position.y;
                });
                currentPic.mouseEnabled = false;
                currentPic.removeEventListener("click", startMove);
                game.addEventListener("click", stopMove);
                this.frame.addEventListener("click", stopMove);
            };

            const stopMove = async (e: createjs.Event) => {
                currentPic.mouseEnabled = true;
                this.frame.cursor = "default";
                game.removeEventListener("click", stopMove);
                this.frame.removeEventListener("click", stopMove);
                currentPic.removeAllEventListeners();
                currentPic.addEventListener("click", startMove);
                if (e.target.name?.startsWith("home") && e.target.index === currentPic.index) {
                    this.helpBtn(true);
                    this.answersCounter++;
                    asyncTween(currentPic, { alpha: 0 }, 400);
                    asyncTween(game[`home${currentPic.index}`][`pic${currentPic.index}_answer`], { alpha: 1 }, 600);
                    if (!this.shouldPlay) return;
                    await playSound(`feedback${this.feedbacksIndexes.pop()}`);
                    if (!this.shouldPlay) return;
                    await playSound(`book_${currentPic.index}`);
                    this.helpBtn(false);
                    if (this.answersCounter === 3) {
                        await waitForTimer(600);
                        gameEnd();
                    }
                } else {
                    currentPic.x = currentX;
                    currentPic.y = currentY;
                    if (!this.shouldPlay) return;
                    await playSound("wrong");
                    this.helpBtn(false);
                }
            };

            currentPic.addEventListener("click", startMove);
        }
    };

    tweenObjects = async (game: MC, objects: any) => {
        const promises: any[] = [];
        game.children.forEach((child: MC) => {
            child.alpha = 0;
        });

        for (let i = 0; i < 3; i++) {
            console.log("this.shouldPlay", this.shouldPlay);

            game[`home${objects[i]}`].title.alpha = 0;
            game[`home${objects[i]}`][`pic${objects[i]}_answer`].alpha = 0;
            await asyncTween(game[`home${objects[i]}`], { alpha: 1 }, 500);
            if (!this.shouldPlay) return;
            await playSound("books");
            if (!this.shouldPlay) return;
        }

        await playSound(`game_1`);
        if (!this.shouldPlay) return;
        for (let i = 0; i < 3; i++) {
            await asyncTween(game[`home${objects[i]}`].title, { alpha: 1 }, 500).then(async () => {
                if (!this.shouldPlay) return;
                await playSound(`book_${objects[i]}`);
                if (!this.shouldPlay) return;
            });
        }
        for (let i = 0; i < 3; i++) {
            await asyncTween(game[`pic${i}`], { alpha: 1 }, 500);
        }
        return Promise.all(promises);
    };

    randomIndexes = () => {
        let index: any;
        for (let i = 0; i < 3; i++) {
            index = getRandomNumber(0, 7);
            if (!this.feedbacksIndexes.includes(index)) {
                this.feedbacksIndexes.push(index);
            } else {
                i--;
            }
        }
    };
}
