import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import { getLetter, MC, playSound, stopAllSounds, waitForEvent, waitForTimer } from "../../../animate/utils";
import { Navigation } from "../../../navigation/navigation";
import { routes } from "../../../navigation/routesPath";
import { getRandomArr, getRandomNumber } from "../../../utils/MathUtils";
import { returnModeForLetter, returnObjectsForLetter } from "./crabJsonFileData";

const TEXT_WIDTH = 190;
const COLOR_NORMAL = "#E67300";
const COLOR_LETTER = "#62265C";
export class Crabs {
    root: MC;
    allCrabs: MC = null;
    letter: number;
    objects: any[] = [];
    enableHelp: (enableOnce?: boolean, specialSound?: any) => void;
    disableHelp: () => void;
    level: number = 0;
    correctAnswers: number = 0;
    rounds: number = 0;
    gameMode: string;
    specialSound: any;
    soundsArr: any[] = [];
    beginGame: boolean = true;
    beginEndGame: boolean = false;
    speed: number;
    intrevalId: any;
    correctIndex: number;
    soundCorrect: any;
    isOver: boolean = false;
    overIndex: string;
    birdIndexes: number[] = [];
    foundLetter: boolean = false;
    enableEvents: boolean = false;
    lettersContext: any;
    baseUrl = "/letters_island/common/words/she/default/";
    baseInstUrl = "/letters_island/crabs/sounds/instructions/Intsletter";
    baseEndUrl = "/letters_island/crabs/sounds/instructions/Lastletter";
    showSwitcher: () => void;
    constructor(
        root: createjs.MovieClip,
        letter: number,
        enableHelp: (enableOnce?: boolean, specialSound?: any) => void,
        disableHelp: () => void,
        level: number,
        showSwitcher: () => void,
        lettersContext: any
    ) {
        this.root = root;
        this.letter = letter;
        this.level = level;
        this.enableHelp = enableHelp;
        this.disableHelp = disableHelp;
        this.showSwitcher = showSwitcher;
        this.lettersContext = lettersContext;
        (window as any).crabs = this;
        this.loaded();
    }

    showObjects = async (objects: any) => {
        this.disableHelp();
        this.enableEvents = false;
        const objectsArr = [objects.sCorrect, objects.sWrong1, objects.sWrong2];
        let randomIndexes: number[];
        let soundIndex: number;
        randomIndexes = getRandomArr(0, 2, 3);
        if (!this.beginGame) {
            this.showCrabSigns();
        } else {
            this.beginGame = false;
        }
        for (let i = 3; i > 0; i--) {
            const crab = this.allCrabs[`crab${i}`];
            // crab.handsAnim.gotoAndStop(0);
            crab.handsAnim.sign.pictureMc.visible = true;
            soundIndex = randomIndexes[i - 1];
            if (soundIndex === 0) {
                this.correctIndex = i - 1;
            }
            const signObject = objectsArr[randomIndexes[i - 1]];
            crab.handsAnim.sign.pictureMc.gotoAndStop(signObject);
            crab.handsAnim.sign.pictureMc.alpha = 0;
            await waitForTimer(500); //between each pbject
            this.showObject(crab.handsAnim.sign.pictureMc);
            await soundPlayed((await loadSound(this.soundsArr[soundIndex])).play());
            crab.handsAnim.sign.addEventListener("click", async () => {
                if (this.enableEvents) {
                    crab.handsAnim.sign.cursor = "default";
                    this.isOver = false;
                    stopAllSounds();
                    this.clickObject(signObject, objects.sCorrect, crab, objects.sWord);
                }
            });
            crab.handsAnim.sign.addEventListener("rollover", () => {
                if (this.enableEvents) {
                    crab.handsAnim.sign.cursor = "pointer";
                    this.isOver = true;
                    this.overIndex = crab.handsAnim.sign.pictureMc.currentLabel;
                    setTimeout(this.hoverSound, 500);
                }
            });
            crab.handsAnim.sign.addEventListener("rollout", () => {
                if (this.enableEvents) {
                    crab.handsAnim.sign.cursor = "default";
                    this.isOver = false;
                }
            });
        }
        this.enableEvents = true;
        if (this.beginEndGame) {
            this.enableHelp(false, this.baseInstUrl + (this.letter + 1).toString() + "e.mp3");
            this.beginEndGame = false;
        } else {
            this.enableHelp(false, this.baseInstUrl + (this.letter + 1).toString() + "b.mp3");
        }
    };

    hoverSound = () => {
        if (this.isOver) {
            this.soundsArr.forEach(async (sound: string) => {
                if (sound.includes(this.overIndex)) {
                    await soundPlayed((await loadSound(sound)).play());
                }
            });
        }
    };

    clickObject = async (signObject: any, correctObject: any, crab: MC, ObjectText: string) => {
        if (signObject === correctObject) {
            this.enableEvents = false;
            this.disableHelp();
            stopAllSounds();
            crab.handsAnim.sign.removeAllEventListeners();
            this.showText(ObjectText, crab.handsAnim.sign.txtHolder);
            const num = getRandomNumber(1, 7);
            await playSound(`m_good${num}`);
            await this.correctAnim(crab, true, this.soundCorrect);
            this.hideCrabObjects();
            this.removeTxtChildren(crab);
            if (this.correctAnswers < this.rounds) {
                this.removeListeners();
                this.loadScreen();
            } else {
                this.endGame();
            }
            this.enableEvents = true;
        } else {
            playSound("wrong");
        }
    };

    correctAnim = async (crab: MC, hide: boolean, sound?: any) => {
        if (hide) {
            await this.hideCrabSigns(crab);
        }
        if (sound) {
            await soundPlayed((await sound).play());
        }
        this.correctAnswers++;
        playSound("correct");
        crab.handsAnim.gotoAndPlay(1);
        await waitForEvent(crab.handsAnim, "animationEnd");
        await this.animateSignOut(crab.handsAnim.sign);
        // crab.handsAnim.gotoAndStop(0);
        await waitForTimer(500);
    };

    showText = (word: string, holder: MC) => {
        let totalWidth: number = 0;
        for (let i = 0; i < word.length; i++) {
            const letter = word.charAt(i);
            const text = new createjs.Text(letter, "70px 'ProntoMF'");
            holder.visible = false;
            totalWidth += text.getMeasuredWidth();
            if (text.text === getLetter(this.letter) && this.foundLetter === false) {
                text.color = COLOR_LETTER;
                this.foundLetter = true;
            } else {
                text.color = COLOR_NORMAL;
            }
            holder.addChild(text);
        }
        this.foundLetter = false;
        let x = (TEXT_WIDTH - totalWidth) / 2;
        holder.children.reverse().forEach((text: createjs.Text) => {
            text.x = x;
            x += text.getMeasuredWidth();
        });
        holder.visible = true;
    };

    removeListeners = () => {
        for (let i = 0; i < 3; i++) {
            const crab = this.allCrabs[`crab${i + 1}`];
            crab.removeAllEventListeners();
            crab.handsAnim.sign.removeAllEventListeners();
        }
    };

    playInst = async (url: string) => {
        const sound = loadSound(url);
        await soundPlayed((await sound).play());
    };

    showCrabSigns = () => {
        for (let i = 0; i < 3; i++) {
            const crab = this.allCrabs[`crab${i + 1}`];
            crab.handsAnim.sign.visible = true;
            this.animateSign(crab.handsAnim.sign);
        }
    };

    loadScreen = async () => {
        this.enableEvents = false;
        this.removeListeners();
        const objects = this.objects[this.correctAnswers];
        if (this.beginGame) {
            await this.playInst(this.baseInstUrl + (this.letter + 1).toString() + "b.mp3");
        }
        if (this.beginEndGame) {
            await this.playInst(this.baseInstUrl + (this.letter + 1).toString() + "e.mp3");
        }
        if (objects.sType === "special") {
            this.specialSound = loadSound(this.baseUrl + objects.sCorrect + ".mp3");
            this.showSpecialScreen(objects);
        } else {
            await this.loadSounds(objects);
            this.showObjects(objects);
        }
    };

    showSpecialScreen = async (object: any) => {
        this.removeListeners();
        this.hideCrabObjects();
        const crab = this.allCrabs.crab2;
        await this.hideCrabSigns(crab);
        this.root.nightMc.visible = true;
        this.root.nightMc.gotoAndPlay(0);
        await this.playInst(this.baseEndUrl + (this.letter + 1).toString() + ".mp3");
        // await waitForEvent(this.root.nightMc, "finish");
        crab.handsAnim.sign.visible = true;
        await this.animateSign(crab.handsAnim.sign);
        crab.handsAnim.sign.pictureMc.visible = true;
        crab.handsAnim.sign.pictureMc.alpha = 0;
        crab.handsAnim.sign.pictureMc.gotoAndStop(object.sCorrect);
        this.showObject(crab.handsAnim.sign.pictureMc);
        await soundPlayed((await this.specialSound).play());
        this.showText(object.sWord, crab.handsAnim.sign.txtHolder);
        await waitForTimer(1000);
        await this.correctAnim(crab, false);
        crab.handsAnim.sign.pictureMc.visible = false;
        this.removeTxtChildren(crab);
        this.beginEndGame = true;
        await this.showCrabSigns();
        this.loadScreen();
    };

    showObject = (pic: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(pic)
                .to(
                    {
                        alpha: 1,
                    },
                    500
                )
                .call(resolve);
        });
    };

    hideCrabSigns = async (correctCrab: MC) => {
        for (let i = 0; i < 3; i++) {
            const crab = this.allCrabs[`crab${i + 1}`];
            if (crab !== correctCrab) {
                this.animateSignOut(crab.handsAnim.sign);
                // crab.handsAnim.sign.visible = false;
            }
        }
    };
    animateSignOut = (sign: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(sign)
                .to(
                    {
                        alpha: 0,
                    },
                    500
                )
                .call(resolve);
        });
    };

    foundCorrect = () => {
        if (this.correctAnswers < this.rounds) {
            this.loadScreen();
        }
    };
    loadSounds = async (objects: any) => {
        this.soundsArr[0] = this.baseUrl + objects.sCorrect + ".mp3";
        this.soundsArr[1] = this.baseUrl + objects.sWrong1 + ".mp3";
        this.soundsArr[2] = this.baseUrl + objects.sWrong2 + ".mp3";
        this.soundCorrect = loadSound(`/letters_island/common/words/she/loud/${objects.sCorrect}.mp3`);
    };

    randomObjects = () => {
        this.gameMode = returnModeForLetter(this.letter);
        this.objects = returnObjectsForLetter(this.letter);
        this.rounds = this.objects.length;
        this.loadScreen();
    };

    hideCrabs = () => {
        for (let i = 0; i < 3; i++) {
            const crab = this.allCrabs[`crab${i + 1}`];
            crab.visible = false;
        }
    };

    hideCrabObjects = () => {
        for (let i = 0; i < 3; i++) {
            const crab = this.allCrabs[`crab${i + 1}`];

            crab.handsAnim.gotoAndStop(39);
            crab.handsAnim.sign.gotoAndStop(0);
            crab.handsAnim.sign.pictureMc.visible = false;
            crab.handsAnim.sign.txtHolder.visible = false;

            // crab.handsAnim.sign.txtHolder
        }
    };

    removeTxtChildren(crab: MC) {
        crab.handsAnim.sign.txtHolder.visible = false;
        crab.handsAnim.sign.txtHolder.removeAllChildren();
    }
    showCrabs = () => {
        for (let i = 0; i < 3; i++) {
            const crab = this.allCrabs[`crab${i + 1}`];
            crab.visible = true;
        }
    };

    crabsIn = async () => {
        await waitForTimer(0);
        this.hideCrabObjects();
        this.root.nightMc.visible = false;
        playSound("crabDrop");
        this.allCrabs.crab1.gotoAndPlay(1);
        this.allCrabs.crab2.gotoAndPlay(1);
        this.allCrabs.crab3.gotoAndPlay(1);
        const promises = [
            await waitForEvent(this.allCrabs.crab1, "crab1animationEnd"),
            await waitForEvent(this.allCrabs.crab2, "crab2animationEnd"),
        ];
        Promise.all(promises).then(async () => {
            this.root.nightMc.visible = false;
            this.root.signMc.titleMc.titleTxt.text = getLetter(this.letter);
            this.root.signMc.gotoAndPlay(1);
            await waitForEvent(this.root.signMc, "finish");
            this.randomObjects();
        });
    };

    cloudsSetup = () => {
        for (let i = 0; i < 4; i++) {
            const cloud = this.root.cloudsMC[`clouds${i + 1}`];
            this.speed = getRandomNumber(4, 7) * 10000;
            cloud.x = 0;
            this.cloudsAnim(cloud, this.speed);
        }
    };

    cloudsAnim = (cloud: MC, speed: number) => {
        createjs.Tween.get(cloud, { loop: -1 }).to(
            {
                x: 1000,
            },
            speed
        );
    };

    birdsSetup = async () => {
        for (let index = 0; index < 10; index++) {
            const bird = this.root.birdsMc[`bird${index + 1}`];
            if (index % 3) {
                await waitForTimer(800);
            }
            bird.gotoAndPlay(1);
        }
    };

    endGame = async () => {
        for (let i = 0; i < 3; i++) {
            const crab = this.allCrabs[`crab${i + 1}`];
            crab.gotoAndPlay("jump");
        }

        await playSound("gameEnd");
        this.lettersContext.setLetterStatus(this.letter + 1, "lesson");

        Navigation.openPage(routes.letters.activities.find_letter.replace(":letterIndex", this.letter + ""));
        this.showSwitcher();
    };

    animateSign = (sign: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(sign)
                .to(
                    {
                        alpha: 1,
                    },
                    800
                )
                .call(resolve);
        });
    };

    loaded = async () => {
        this.allCrabs = new this.root.lib.allCrabs();
        const mask = new createjs.Shape();
        mask.graphics.beginFill("#000").drawRect(0, 0, 800, 600);
        this.allCrabs.mask = mask;
        this.allCrabs.y = 70;
        this.allCrabs.x = 290;
        this.root.addChild(this.allCrabs);
        this.birdsSetup();
        this.cloudsSetup();
        this.crabsIn();
    };
}
