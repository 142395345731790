import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, setupStage } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { BOUND_X, BOUND_Y, getBongoSound, HEIGHT, WIDTH } from "./common";

const P_WIDTH = 175;
const P_HEIGHT = 125;
export class PuzzleGame {
    private moveX: number;
    private moveY: number;
    private should_move: boolean = false;
    private pressed_index: number = 0;
    private readonly g = new createjs.Shape();
    private root: MC;
    private success_num = 0;

    constructor(private frame: any, private isBibleSection: boolean = false) {}

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(0);
        this.success_num = 0;
        this.start();
    };

    start = () => {
        this.should_move = false;
        this.initPieces();
        this.root.addChild(this.g);
        this.root.board.addEventListener("click", () => {
            playSound(getBongoSound());
        });
        this.root.whiteBoard.addEventListener("click", () => {
            playSound(getBongoSound());
        });
        this.root.addEventListener("tick", () => {
            this.moveElement();
        });
    };

    initPieces = async () => {
        for (let k = 1; k <= 12; k++) {
            this.root[`piece${k}`].visible = false;
        }

        let x = 262.5;
        let y = 125;
        let index = 1;
        for (let i = 1; i <= 3; i++) {
            x = 262.5;
            for (let j = 1; j <= 4; j++) {
                const piece = this.root[`piece${index}`];
                piece.pic.x = x;
                piece.pic.y = y;
                piece.gotoAndStop(0);
                piece.x = getRandomNumber(0, 590) + 110;
                piece.y = getRandomNumber(0, 50) + 440;
                x -= 175;
                this.addListeners(piece, index);
                index += 1;
            }
            y -= 125;
        }

        for (let k = 1; k <= 12; k++) {
            this.root[`piece${k}`].visible = true;
        }
    };

    mouseOver = (piece: MC) => {
        piece.addEventListener("mouseover", () => {
            if (!this.should_move) this.root.setChildIndex(piece, this.root.numChildren - 1);
        });
    };
    mouseDown = (piece: MC, index: number) => {
        piece.addEventListener("mousedown", async () => {
            if (this.should_move) {
                // press while someone is moving
                await playSound(getBongoSound());
                playSound("puzzleClick");
                return;
            }
            this.pressed_index = index;
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.moveX = localMouse.x - piece.x;
            this.moveY = localMouse.y - piece.y;
            playSound(getBongoSound());
        });
    };

    pressup = (piece: MC, index: number) => {
        piece.addEventListener("pressup", async () => {
            if (this.should_move && this.pressed_index === index) {
                //current piece was the last one pressed, and is on move.
                this.should_move = false;
                if (this.checkIntersection(piece)) {
                    if (this.isBibleSection) {
                        this.onBibleSuccess();
                    }
                }
                return;
            }
            if (this.pressed_index === index) {
                this.should_move = true;
            }
        });
    };

    mouseout = (piece: MC, index: number) => {
        piece.addEventListener("mouseout", () => {
            if (this.pressed_index === index && !this.should_move) {
                //current piece was the last one pressed.
                this.pressed_index = 0;
            }
        });
    };

    addListeners = (piece: MC, index: number) => {
        this.mouseOver(piece);
        this.mouseDown(piece, index);
        this.pressup(piece, index);
        this.mouseout(piece, index);
    };

    moveElement = () => {
        if (this.should_move) {
            if (this.root.stage.mouseX && this.root.stage.mouseY) {
                const pos = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);

                const new_x = pos.x - this.moveX;
                const new_y = pos.y - this.moveY;
                const piece = this.root[`piece${this.pressed_index}`];
                this.move_x(new_x, piece);
                this.move_y(new_y, piece);
            }
        }
    };

    checkIntersection = (piece: MC) => {
        const area = this.root["area" + this.pressed_index];
        // const globalPieceArea = localToGlobal(piece.area, 0, 0);
        const areaRect = new createjs.Rectangle(area.x - 25 / 2, area.y - 25 / 2, 25, 25);

        const pieceRect = new createjs.Rectangle(piece.x - 25 / 2, piece.y - 25 / 2, 25, 25);
        // this.debugHitArea(areaRect, pieceRect);
        if (areaRect.intersects(pieceRect)) {
            piece.x = area.x;
            piece.y = area.y;
            piece.scale = 1.01;
            piece.gotoAndStop(2);
            piece.mouseEnabled = false;
            return true;
        }
        return false;
    };

    private debugHitArea(rect: createjs.Rectangle, rect2: createjs.Rectangle) {
        const g = this.g.graphics;
        g.clear();
        g.f("red").r(rect.x, rect.y, rect.width, rect.height);
        g.f("blue").r(rect2.x, rect2.y, rect2.width, rect2.height);
    }

    private move_y(new_y: number, piece: any) {
        if (new_y - P_HEIGHT / 2 >= BOUND_Y && new_y + P_HEIGHT / 2 <= BOUND_Y + HEIGHT) {
            piece.y = new_y;
        } else {
            if (new_y - P_HEIGHT / 2 < BOUND_Y) {
                piece.y = BOUND_Y + P_HEIGHT / 2;
            } else {
                piece.y = BOUND_Y + HEIGHT - P_HEIGHT / 2;
            }
        }
    }

    private move_x(new_x: number, piece: any) {
        if (new_x - P_WIDTH / 2 >= BOUND_X && new_x + P_WIDTH / 2 <= BOUND_X + WIDTH) {
            piece.x = new_x;
        } else {
            if (new_x - P_WIDTH / 2 - 4 < BOUND_X) {
                piece.x = BOUND_X + P_WIDTH / 2;
            } else {
                piece.x = BOUND_X + WIDTH - P_WIDTH / 2;
            }
        }
    }
    onBibleSuccess = async () => {
        this.success_num += 1;
        const i = getRandomNumber(0, 5);
        if (this.success_num === 12) {
            const sound = await loadSound(`/bible_stories/puzzle_frame/sounds/good${i}.mp3`);
            sound.play();
        }
    };
}
