import React from "react";
import AnimateCC from "../../animate";
import { RocketMenu } from "./RocketMenu";
import { PicGame } from "./PicGame";
import { bubbleSounds } from "./PicSounds";
import { useParams } from "react-router-dom";

const PicComponent: React.FC<{ frame: RocketMenu }> = ({ frame }) => {
    const game = React.useRef(new PicGame(frame));
    const { num } = useParams<{ num: string }>();

    return (
        <AnimateCC
            key={Date.now()}
            base={`/lunapark/rocket/pic_${num}`}
            animationName={`pic_${num}`}
            scale={0.75}
            shiftX={130}
            shiftY={5}
            additionalSounds={bubbleSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default PicComponent;
