import { asyncTween, MC, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { ZigiGameBase } from "./ZigiGameBase";
import { loadSound } from "../../animate/sound_utils";
import { getRandomNumber } from "../../utils/MathUtils";

export default class ZigiNumbersGame extends ZigiGameBase {
    enabledFramBtn: boolean = true;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    objects = ["apple", "cookie", "spider"];
    zigiModes = ["belly", "sleep", "yawn"];
    objectToShow: MC = null;
    buttonClicked: boolean = false;
    buttonDownYPosition = 97.65;

    loaded(root: MC) {
        super.loaded(root);
        const allObjects = new this.root.lib.Cookies();
        this.objectToShow = allObjects;
        this.objectToShow.visible = false;
        this.root.cookies_holder_mc.addChild(allObjects);
    }

    startLearn = async () => {
        try {
            super.startLearn();
            await this.showNumbers();
            const instPath = loadSound("/english/sounds/zigi_numbers_game_sound/learn/inst.mp3");
            this.helpSrc = instPath;
            this.helpSrc2 = null;
            await this.cancelableWait(super.playSound(instPath));
            this.addEventsToNumbersButtons(false, null, null);
        } catch (ignore) {}
    };

    startExercise = async () => {
        try {
            super.startExercise();
            await this.showNumbers();
            const instPath = loadSound("/english/sounds/zigi_numbers_game_sound/practice/inst.mp3");
            this.helpSrc = instPath;
            await this.cancelableWait(super.playSound(instPath));
            this.askQuestion();
        } catch (ignore) {}
    };

    showNumbers = async () => {
        for (let i = 0; i < 10; i++) {
            const currentNumBtn = this.root.btns_mc[`btn${i + 1}_mc`];
            currentNumBtn.num_mc.num_txt.text = i + 1;
            currentNumBtn.alpha = 1;
        }
        this.root.btns_mc.gotoAndStop(0);

        const yPosition = 37.65;

        for (let i = 1; i <= 10; i++) {
            if (!this.shouldPlay) return;
            const currentBtn = this.root.btns_mc[`btn${i}_mc`];
            const currentNumber = this.mapNumbers(i);
            const currentNumberSound = loadSound(
                `/english/sounds/zigi_numbers_game_sound/learn/Show/${currentNumber}.mp3`
            );

            await this.cancelableWait(asyncTween(currentBtn, { y: yPosition }, 100, createjs.Ease.quadIn));
            await this.cancelableWait(this.playSound(currentNumberSound));
        }
    };
    resetObjects = () => {
        this.root.zigi_mc.gotoAndStop(0);
        for (let i = 0; i < 10; i++) {
            const currentNumBtn = this.root.btns_mc[`btn${i + 1}_mc`];
            this.objectToShow[`cookie${i + 1}`].visible = false;
            currentNumBtn.removeAllEventListeners();
        }
    };

    resetGame = () => {
        super.resetGame();
        this.resetObjects();
        this.buttonClicked = false;
        this.root.btns_mc.gotoAndStop(0);
        for (let i = 0; i < 10; i++) {
            const currentNumBtn = this.root.btns_mc[`btn${i + 1}_mc`];
            currentNumBtn.gotoAndStop(0);
            currentNumBtn.y = 97.65;
        }
    };

    mapNumbers = (index: number) => {
        const numbers = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];
        return numbers[index - 1];
    };

    addEventsToNumbersButtons = (
        fromExercise: boolean = false,
        correctAnswer: number | null,
        objects: string | null
    ) => {
        this.root.btns_mc.mouseEnabled = true;
        for (let i = 1; i <= 10; i++) {
            const currentNumBtn = this.root.btns_mc[`btn${i}_mc`];
            currentNumBtn.mouseChildren = false;
            currentNumBtn.cursor = "pointer";
            currentNumBtn.number = i;
            const Tween = createjs.Tween;
            const Ease = createjs.Ease;
            const yPosition = 37.65;
            currentNumBtn.addEventListener("rollover", () => {
                if (!this.buttonClicked) {
                    Tween.get(currentNumBtn)
                        .to({ y: yPosition - 20 }, 100, Ease.quadIn)
                        .call(() => {});
                    currentNumBtn.gotoAndStop("over");
                }
            });
            currentNumBtn.addEventListener("rollout", () => {
                if (!this.buttonClicked) {
                    currentNumBtn.gotoAndStop("down");
                    Tween.get(currentNumBtn)
                        .to({ y: yPosition }, 100, Ease.quadOut)
                        .call(() => {});
                }
            });
            currentNumBtn.addEventListener("click", () => {
                if (!this.buttonClicked) {
                    this.buttonClicked = true;
                    this.root.btns_mc.mouseEnabled = false;
                    currentNumBtn.gotoAndStop("preesed");
                    Tween.get(currentNumBtn)
                        .to({ y: yPosition }, 100, Ease.quadOut)
                        .call(() => {});
                    this.showObjects(
                        fromExercise,
                        currentNumBtn.number,
                        fromExercise ? correctAnswer : null,
                        fromExercise ? objects : null
                    );
                }
            });
        }
    };

    returnSoundPath = (object: string, numOfObjects: number) => {
        const number = this.mapNumbers(numOfObjects);
        switch (object) {
            case "spider":
                return `/english/sounds/zigi_numbers_game_sound/learn/choose/spider/${number}Sp.mp3`;
            case "apple":
                return `/english/sounds/zigi_numbers_game_sound/learn/choose/apple/${number}Ap.mp3`;
            case "cookie":
                return `/english/sounds/zigi_numbers_game_sound/learn/choose/cookie/${number}Co.mp3`;
        }
    };

    zigiEatingAnim = async (fromExercise: boolean = false, object: string, btnNum: number) => {
        const zigiMode = this.randomZigiModeAnimate();
        this.enabledFramBtn = false;
        if (!this.shouldPlay) return;
        this.root.zigi_mc.gotoAndPlay("eat");
        await this.cancelableWait(waitForEvent(this.root.zigi_mc, "readyToEat"));
        this.playSound(loadSound(this.returnSoundPath(object, btnNum)));
        this.enabledFramBtn = false;

        for (let i = btnNum - 1; i >= 0; i--) {
            if (!this.shouldPlay) return;
            await this.cancelableWait(waitForEvent(this.root.zigi_mc, "startEat"));
            this.objectToShow.gotoAndStop(i - 1);
            if (i === 0) {
                this.objectToShow.visible = false;
            }
            await this.cancelableWait(waitForEvent(this.root.zigi_mc, "stopEating"));
            if (i !== 0) {
                this.root.zigi_mc.gotoAndPlay("loop_eat");
            }
        }
        stopAllSounds();
        this.root.zigi_mc.gotoAndPlay(zigiMode);
        await this.cancelableWait(waitForEvent(this.root.zigi_mc, "animationEnd"));
        this.root.zigi_mc.gotoAndStop("normal");
        this.enabledFramBtn = true;
        this.root.btns_mc.mouseEnabled = true;
        this.buttonClicked = false;
        this.root.btns_mc[`btn${btnNum}_mc`].gotoAndStop("down");
        if (fromExercise) {
            this.resetObjects();
            this.askQuestion();
        }
    };

    showObjects = async (
        fromExercise: boolean = false,
        btnNum: any,
        correctAnswer: number | null,
        object: string | null
    ) => {
        try {
            if (!fromExercise) {
                object = this.randomObjects();
            }
            const num = this.mapNumbers(btnNum);
            if (fromExercise && correctAnswer !== btnNum) {
                const correctAnswerNum = this.mapNumbers(correctAnswer);
                await this.cancelableWait(
                    this.playSound(
                        loadSound(`/english/sounds/zigi_numbers_game_sound/practice/${object}/${correctAnswerNum}W.mp3`)
                    )
                );
                this.enabledFramBtn = true;
                this.root.btns_mc.mouseEnabled = true;
                this.buttonClicked = false;
                this.root.btns_mc[`btn${btnNum}_mc`].gotoAndStop("down");
            } else {
                await this.cancelableWait(
                    super.playSound(loadSound(`/english/sounds/zigi_numbers_game_sound/learn/choose/${num}.mp3`))
                );

                this.objectToShow.gotoAndStop(btnNum - 1);
                for (let i = 1; i <= 10; i++) {
                    this.objectToShow[`cookie${i}`].gotoAndStop(object);
                    this.objectToShow[`cookie${i}`].visible = false;
                }

                this.objectToShow.visible = true;
                for (let i = 1; i <= btnNum; i++) {
                    this.objectToShow[`cookie${i}`].visible = true;
                    let sound: any;
                    const number = this.mapNumbers(i);
                    if (i === btnNum) {
                        sound = `/english/sounds/zigi_numbers_game_sound/learn/choose/${number}E.mp3`;
                    } else {
                        sound = `/english/sounds/zigi_numbers_game_sound/learn/choose/${number}M.mp3`;
                    }
                    await this.cancelableWait(this.playSound(loadSound(sound)));
                    await this.cancelableWait(waitForTimer(250));
                }
                this.zigiEatingAnim(fromExercise, object, btnNum);
            }
        } catch (ignore) {}
    };

    askQuestion = async () => {
        const objects = this.randomObjects();
        const answer = this.rendomNmber();
        const num = this.mapNumbers(answer);
        const sound = `/english/sounds/zigi_numbers_game_sound/practice/${objects}/${num}.mp3`;
        const helpSound = loadSound(`/english/sounds/zigi_numbers_game_sound/practice/${objects}/inst.mp3`);
        await this.cancelableWait(super.playSound(loadSound(sound)));
        this.helpSrc = helpSound;
        this.helpSrc2 = loadSound(sound);
        this.addEventsToNumbersButtons(true, answer, objects);
    };

    randomObjects = () => {
        const index = getRandomNumber(0, this.objects.length - 1);
        return this.objects[index];
    };

    rendomNmber = () => {
        const numbers = getRandomNumber(1, 10);
        return numbers;
    };
    randomZigiModeAnimate = () => {
        const index = getRandomNumber(0, this.zigiModes.length - 1);
        return this.zigiModes[index];
    };
}
