export const gameSounds = (letter: number): any[] => {
    return [
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM${letter}_1.mp3`,
            id: `letter_${letter}`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM${letter}_2.mp3`,
            id: `word_${letter}`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM${letter}_3.mp3`,
            id: `question_${letter}`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM${letter}_4.mp3`,
            id: `definition_${letter}`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM${letter}_8.mp3`,
            id: `game_${letter}`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM3_game.mp3`,
            id: `game3`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/home0.mp3`,
            id: `book_2`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/home1.mp3`,
            id: `book_0`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/home2.mp3`,
            id: `book_1`,
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/books.mp3`,
            id: "books",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/Instructions.mp3`,
            id: "Instructions",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/IntroLetter1.mp3`,
            id: "IntroLetter1",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/IntroLetter2.mp3`,
            id: "IntroLetter2",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/moveword.mp3`,
            id: "moveword",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/Wrong.mp3`,
            id: "wrong",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/Earthwav.mp3`,
            id: "Earthwav",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM13_10.mp3`,
            id: "sen0",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM13_12.mp3`,
            id: "sen1",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/MM13_solution.mp3`,
            id: "solution",
        },
        {
            src: `/letters_island/fancy_words/sounds/Goodwav.mp3`,
            id: "good",
        },
        {
            src: `/letters_island/fancy_words/sounds/TicTac1wav.mp3`,
            id: "TicTac",
        },
        {
            src: `/letters_island/fancy_words/sounds/nextAndReload.mp3`,
            id: "nextAndReload",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM14_g_0.mp3`,
            id: "cat_0",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM14_g_1.mp3`,
            id: "cat_1",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM14_g_2.mp3`,
            id: "cat_2",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM15_g_0.mp3`,
            id: "btn_0",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM15_g_1.mp3`,
            id: "btn_1",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM15_g_2.mp3`,
            id: "btn_2",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM15_g_3.mp3`,
            id: "btn_3",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM15_a_1.mp3`,
            id: "answer_1",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM15_a_2.mp3`,
            id: "answer_2",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM15_Instructios.mp3`,
            id: "MM15_Instructios",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM6_a_1.mp3`,
            id: "feedback_6_1",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM6_a_2.mp3`,
            id: "feedback_6_2",
        },
        {
            src: `/letters_island/fancy_words/sounds/walk.mp3`,
            id: "walk",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM2_10.mp3`,
            id: "game2Instructios",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM7_game2.mp3`,
            id: "game2song",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM5_game.mp3`,
            id: "Letter",
        },
        {
            src: `/letters_island/fancy_words/sounds/Prahimwav.mp3`,
            id: "pic2",
        },
        {
            src: `/letters_island/fancy_words/sounds/Parparim.mp3`,
            id: "pic1",
        },
        {
            src: `/letters_island/fancy_words/sounds/zvi.mp3`,
            id: "pic0",
        },
        {
            src: `/letters_island/fancy_words/sounds/Mahakwav.mp3`,
            id: "delete",
        },
        {
            src: `/letters_island/fancy_words/sounds/hearts.mp3`,
            id: "hearts",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/line_0.mp3`,
            id: "line_0",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/line_1.mp3`,
            id: "line_1",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/line_2.mp3`,
            id: "line_2",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/line_3.mp3`,
            id: "line_3",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/note_0.mp3`,
            id: "note_0",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/note_1.mp3`,
            id: "note_1",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/note_2.mp3`,
            id: "note_2",
        },
        {
            src: `/letters_island/fancy_words/sounds/wordsSounds/note_3.mp3`,
            id: "note_3",
        },
        {
            src: `/letters_island/fancy_words/sounds/game9.mp3`,
            id: "game9",
        },
        {
            src: `/letters_island/fancy_words/sounds/game13_answer.mp3`,
            id: "game13_answer",
        },
        {
            src: `/letters_island/fancy_words/sounds/MM12_8_1.mp3`,
            id: "game12_1",
        },
        {
            src: `/letters_island/fancy_words/sounds/game9/word0.mp3`,
            id: "word0",
        },
        {
            src: `/letters_island/fancy_words/sounds/game9/word1.mp3`,
            id: "word1",
        },
        {
            src: `/letters_island/fancy_words/sounds/game9/word2.mp3`,
            id: "word2",
        },
        {
            src: `/letters_island/fancy_words/sounds/game9/word3.mp3`,
            id: "word3",
        },
        {
            src: `/letters_island/fancy_words/sounds/game9/paper.mp3`,
            id: "paper",
        },
        {
            src: `/letters_island/fancy_words/sounds/instructions.mp3`,
            id: "instructions",
        },
        {
            src: `/letters_island/fancy_words/sounds/good_letter15.mp3`,
            id: "good",
        },
        {
            src: `/letters_island/fancy_words/sounds/Pum.mp3`,
            id: "Pum",
        },
    ];
};
export const menuSounds = [
    {
        src: `/letters_island/fancy_words/sounds/wordsSounds/bg.mp3`,
        id: "menuBg",
    },
];
