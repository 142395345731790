import { MC, stopAllSounds } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { Navigation } from "../../navigation/navigation";
import { SoundToolTip } from "../../utils/soundTooltip";

export class LetterActivitiesFrame {
    root: MC;
    letter: number;

    constructor(root: createjs.MovieClip, letter: number) {
        this.root = root;
        this.letter = letter;
        (window as any).letterActivities = root;
        this.loaded();
    }

    loaded = async () => {
        this.root.switcher_mc.gotoAndStop("show");

        SoundToolTip.addToolTip(this.root.exit_btn, "exit");

        this.root.exit_btn.addEventListener("rollover", () => {
            this.root.exit_btn.gotoAndStop("over");
        });
        this.root.exit_btn.addEventListener("rollout", () => {
            this.root.exit_btn.gotoAndStop("normal");
        });
        this.root.exit_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.letters.activities.menu.replace(":letterIndex", this.letter + ""));
        });
        this.root.exit_btn.cursor = "pointer";
        this.root.next_btn.gotoAndStop("disabled");
    };

    showSwitcher = () => {
        this.root.switcher_mc.gotoAndStop("show");
    };

    hideSwitcher = () => {
        this.root.switcher_mc.gotoAndStop("hide");
        // return waitForEvent(this.root.switcher_mc, "animationEnd");
    };

    unload = () => {};
}
