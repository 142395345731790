import { MC, stopAllSounds } from "../animate/utils";
import { routes } from "../navigation/routesPath";
import { Navigation } from "../navigation/navigation";
import { generatePath } from "react-router-dom";
import { loadSound, soundPlayed } from "../animate/sound_utils";
import { SoundToolTip } from "../utils/soundTooltip";

export class DubbyEnglishMenu {
    root: MC;

    addEventsToGamesBtn = () => {
        for (let i = 0; i < 4; i++) {
            const currentBtn = this.root[`btn${i}`];
            let currentsBtnName: string;
            switch (i) {
                case 0:
                    currentsBtnName = "living";
                    break;
                case 1:
                    currentsBtnName = "bed";
                    break;
                case 2:
                    currentsBtnName = "kitchen";
                    break;
                case 3:
                    currentsBtnName = "bath";
                    break;
            }
            this.adddEventsToGameMode(currentBtn, currentsBtnName);
            currentBtn.cursor = "pointer";
            currentBtn.addEventListener("rollover", () => {
                currentBtn.gotoAndPlay(2);
            });
            currentBtn.addEventListener("rollout", async () => {
                currentBtn.gotoAndPlay(7);
            });
        }
        const back_btn = this.root.back_btn;
        back_btn.cursor = "pointer";
        back_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
        back_btn.addEventListener("rollover", () => {
            back_btn.gotoAndStop("over");
        });
        back_btn.addEventListener("rollout", () => {
            back_btn.gotoAndStop("up");
        });
        SoundToolTip.addToolTip(back_btn, "/dubby_english/sounds/common/back.mp3");
    };

    adddEventsToGameMode = (currentBtn: MC, currentsBtnName: string) => {
        for (let i = 0; i < 3; i++) {
            const currentModeBtn = currentBtn[`btn${i}`];
            const clickSoundPath = `/dubby_english/sounds/menu/0${i + 1}.mp3`;

            let currentModeBtnName: string;
            switch (i) {
                case 0:
                    currentModeBtnName = "bee";
                    break;
                case 1:
                    currentModeBtnName = "whatin";
                    break;
                case 2:
                    currentModeBtnName = "build";
                    break;
            }

            currentModeBtn.mouseChildren = false;
            currentModeBtn.cursor = "pointer";
            SoundToolTip.addToolTip(currentModeBtn, clickSoundPath);
            currentModeBtn.addEventListener("rollover", () => {
                currentModeBtn.gotoAndStop("_over");
            });
            currentModeBtn.addEventListener("rollout", () => {
                currentModeBtn.gotoAndStop("_up");
            });
            currentModeBtn.addEventListener("click", async () => {
                currentModeBtn.gotoAndStop("_down");
                stopAllSounds();
                Navigation.openPage(
                    generatePath(routes.dubby_english.game, {
                        roomType: `${currentsBtnName}`,
                        gameType: currentModeBtnName,
                    })
                );
            });
        }
    };

    loaded = async (root: MC) => {
        this.root = root;
        const intro = loadSound("/dubby_english/sounds/menu/intro.mp3");
        soundPlayed((await intro).play());
        this.addEventsToGamesBtn();
    };
}
