import React, { useEffect } from "react";
import "./initialScreen.css";

export const InitialScreenComponent: React.FC<{
    showMayaRoom: () => void;
    style: React.CSSProperties;
    isPopup: boolean;
}> = ({ showMayaRoom, style, isPopup }) => {
    const checkIsMobile = () => {
        if (matchMedia("(pointer:fine)").matches) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        //const isFromExternalLogin = new URLSearchParams(window.location.search).has("autologin");
        if (isPopup) {
            showMayaRoom();
        }
    });

    return (
        <div id="site" style={style}>
            <div id="siteContent" className="siteContent">
                <div id="MainLink" style={{ display: "block" }}>
                    <div className="main">
                        <div className="enter-site-btn" onClick={showMayaRoom} />
                    </div>
                </div>
            </div>
            {checkIsMobile() && <div className="message-center">*לצפיה באתר המלא אנא גלוש/י ממחשב שולחני</div>}
            <div className="footer">
                <div className="footer-center">במידה ונתקלת בבעיה, אנא פנה לתמיכה בטלפון 09-8922913</div>
            </div>
        </div>
    );
};
