import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, playSoundSync, setupStage, waitFor } from "../../animate/utils";

export class TreeGame {
    root: MC;
    clothing_sections: string[] = ["shirt", "pants", "shoes", "hat", "accessories"];
    closet: MC;
    printVersion: MC;
    prevBtn: MC;
    enableHelp: boolean;
    setShouldPrint: (val: boolean) => void;
    pressedItem_Boy: MC;
    pressedItem_Girl: MC;

    constructor(private frame: any) {}
    loaded = async (root: MC, setShouldPrint: (val: boolean) => void) => {
        this.root = root;
        this.root.gotoAndStop(0);
        await waitFor(() => this.frame);
        setupStage(this.frame.root, this.root);

        this.root.gotoAndStop(1);
        this.frame.disableBtn(this.frame.root.frame.btnHelp);
        this.setShouldPrint = setShouldPrint;
        this.setGame(this.root.girl);
        this.setGame(this.root.boy);
        this.initFrame();
        this.initClosets();
        this.loadAndPlaySound("/bible_stories/tree_game/sounds/inst.mp3").then(async () => {
            await loadSound("/bible_stories/tree_game/sounds/reka.mp3");
            playSoundSync("/bible_stories/tree_game/sounds/reka.mp3", { loop: -1, volume: 0.5 });
            this.frame.enableBtn(this.frame.root.frame.btnHelp);
        });
        this.setPrinter();
        (window as any).tree = root;
    };

    initFrame = () => {
        this.frame.toggleReplay(false);
        this.frame.togglePrint(true);
        this.frame.toggleHelp(true);
        this.setHelpBtn();
    };

    loadAndPlaySound = async (soundUrl: string) => {
        await loadSound(soundUrl);
        await playSound(soundUrl);
    };

    setGame = (figure: MC) => {
        figure.shirt.gotoAndStop(0);
        figure.pants.gotoAndStop(0);
        figure.hat.gotoAndStop(0);
        figure.shoes.gotoAndStop(0);
        figure.accessories.gotoAndStop(0);
    };

    setHelpBtn = async () => {
        await loadSound("/bible_stories/tree_game/sounds/help.mp3");
        const help_btn = this.frame.root.frame.btnHelp;
        help_btn.addEventListener("click", async () => {
            this.enableHelp = false;
            help_btn.gotoAndStop("up");
            await loadSound("/bible_stories/tree_game/sounds/help.mp3");
            playSound("/bible_stories/tree_game/sounds/help.mp3").then(() => {
                this.enableHelp = true;
            });
        });
    };

    setPrinter = () => {
        this.frame.root.frame.btnPrint.addEventListener("click", (e: MouseEvent) => {
            this.setShouldPrint(true);
        });
    };

    initClosets = async () => {
        await loadSound("/bible_stories/tree_game/sounds/closet.mp3");
        const boy_closet = this.root.closet_boy;
        const girl_closet = this.root.closet_girl;
        boy_closet.closet_items_boy.gotoAndStop(0);
        girl_closet.closet_items_girl.gotoAndStop(0);
        for (let index = 0; index < 5; index++) {
            // const element = array[index];
            const boy_btn = boy_closet[`btn_boy_${index}`];
            const girl_btn = girl_closet[`btn_girl_${index}`];

            [boy_btn, girl_btn].forEach((btn: MC) => {
                btn.gotoAndStop("off");
                btn.cursor = "pointer";
                btn.addEventListener("click", async () => {
                    btn === boy_btn
                        ? await this.clickCloset("boy", boy_closet.closet_items_boy, boy_btn, index)
                        : await this.clickCloset("girl", girl_closet.closet_items_girl, girl_btn, index);
                });
            });
        }
        this.initItemsInSections();
    };

    clickCloset = async (figure: string, closet: MC, btn: MC, index: number) => {
        playSound("/bible_stories/tree_game/sounds/closet.mp3");
        this.prevBtn && this.prevBtn.gotoAndStop("off");
        btn.gotoAndStop("on");
        this.prevBtn = btn;
        closet.gotoAndStop(this.clothing_sections[index]);
        await this.animateItemsIn(closet, index + 1, figure);
    };

    animateItemsIn = async (closet: MC, i: number, figure: string) => {
        for (let index = 1; index < 7; index++) {
            const item = closet[figure === "boy" ? `boy_item_${i}_${index}` : `girl_item_${i}_${index}`];
            item && (item.alpha = 0);
        }
        for (let index = 0; index < 7; index++) {
            const item = closet[figure === "boy" ? `boy_item_${i}_${index}` : `girl_item_${i}_${index}`];
            if (item) {
                await this.itemIn(item);
            }
        }
    };

    itemIn = (item: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(item)
                .to(
                    {
                        alpha: 1,
                    },
                    350
                )
                .call(resolve);
        });
    };

    initItemsInSections = async () => {
        await loadSound("/bible_stories/tree_game/sounds/item.mp3");
        for (let i = 1; i < 6; i++) {
            const section_name = this.clothing_sections[i - 1];
            for (let n = 1; n < 7; n++) {
                const boy_item = this.root.closet_boy.closet_items_boy[`boy_btn_${i}_${n}`];
                const boy_inner_item = this.root.closet_boy.closet_items_boy[`boy_item_${i}_${n}`];

                const girl_item = this.root.closet_girl.closet_items_girl[`girl_btn_${i}_${n}`];
                const girl_inner_item = this.root.closet_girl.closet_items_girl[`girl_item_${i}_${n}`];

                [boy_item, girl_item].forEach((item: MC) => {
                    item &&
                        item.addEventListener("rollover", async () => {
                            if (this.pressedItem_Boy === item || this.pressedItem_Girl === item) {
                                item.cursor = "default";
                                return;
                            }
                            item.cursor = "pointer";
                            item === boy_item ? this.enlargeItem(boy_inner_item) : this.enlargeItem(girl_inner_item);
                        });
                    item &&
                        item.addEventListener("rollout", () => {
                            if (this.pressedItem_Boy === item || this.pressedItem_Girl === item) return;
                            item === boy_item
                                ? this.backToOriginal(boy_inner_item)
                                : this.backToOriginal(girl_inner_item);
                        });
                    item &&
                        item.addEventListener("click", () => {
                            if (this.pressedItem_Boy === item || this.pressedItem_Girl === item) return;
                            playSound("/bible_stories/tree_game/sounds/item.mp3");
                            item === boy_item
                                ? this.root.boy[section_name].gotoAndStop(`${section_name}_${n - 1}`)
                                : this.root.girl[section_name].gotoAndStop(`${section_name}_${n - 1}`);
                            item === boy_item ? (this.pressedItem_Boy = item) : (this.pressedItem_Girl = item);
                            item === boy_item ? (boy_inner_item.scale = 1) : (girl_inner_item.scale = 1);
                        });
                });
            }
        }
    };

    backToOriginal = (item: MC) => {
        item.scale = 1;
        item.y -= 5;
    };

    enlargeItem = (item: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(item)
                .to(
                    {
                        scale: 1.2,
                        y: item.y + 5,
                    },
                    70
                )
                .call(resolve);
        });
    };

    resetEvents = () => {
        for (let btnIndex = 0; btnIndex < 5; btnIndex++) {
            for (let closetIndex = 0; closetIndex < 5; closetIndex++) {}
        }
    };

    prepareForPrint = async (mc: createjs.MovieClip) => {
        // debugger
        (window as any).printVersion = mc;
        this.printVersion = mc;
        this.printVersion.scale = 0.7;
        await waitFor(() => this.printVersion.stage);
        // this.printVersion.children.forEach((c: MC) => {
        //     c.visible = false;
        // });
        this.printVersion.gotoAndStop(6);
        ["boy", "girl"].forEach((name: string) => {
            const figure = this.printVersion[name];
            const root_figure = this.root[name];
            figure.visible = true;
            figure.hat.gotoAndStop(root_figure.hat.currentFrame);
            figure.shirt.gotoAndStop(root_figure.shirt.currentFrame);
            figure.pants.gotoAndStop(root_figure.pants.currentFrame);
            figure.shoes.gotoAndStop(root_figure.shoes.currentFrame);
            figure.accessories.gotoAndStop(root_figure.accessories.currentFrame);
        });
        this.printVersion.stage.update();
        window.print();
        this.setShouldPrint(false);
    };

    unload = () => {};
}
