import { MC, waitForEvent, playSound, playSoundSync, stopAllSounds } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game11 extends BaseGame {
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        game.visible = true;
        game.animation.earth.gotoAndStop(1);
        game.gotoAndPlay(2);
        game.animation.earth.gotoAndStop(1);
        game.animation.gotoAndStop(1);
        await playSound("game_11");
        if (!this.shouldPlay) return;
        await waitForEvent(game, "animationEnd");
        if (!this.shouldPlay) return;
        helpBtn(false);
        game.animation.earth.gotoAndStop(0);
        game.animation.earth.cursor = "pointer";
        game.animation.earth.addEventListener("click", async () => {
            game.mouseChildren = false;
            game.animation.gotoAndPlay(2);
            game.animation.earth.gotoAndPlay(1);
            playSoundSync("Earthwav", { loop: -1 });
            await waitForEvent(game.animation, "animationEnd");
            if (!this.shouldPlay) return;
            stopAllSounds();
            gameEnd();
        });
    };
}
