import React, { useState } from "react";
import AnimateCC from "../animate";
import { MC } from "../animate/utils";
import { ColoringPagesMenu } from "./ColoringPagesMenu";
import styles from "./coloring-pages.module.scss";

const HOLIDAY_PAGES = { pesah: 5, sukkot: 5, hanuka: 4, purim: 6 };
export default function ColoringPagesMenuComponent() {
    const [images, setImages] = useState<number[]>([]);

    const setHolidayPagesLength = (holidayString: string) => {
        switch (holidayString) {
            case "pesah":
                return HOLIDAY_PAGES.pesah;
            case "sukkot":
                return HOLIDAY_PAGES.sukkot;
            case "hanuka":
                return HOLIDAY_PAGES.hanuka;
            case "purim":
                return HOLIDAY_PAGES.purim;
            default:
                return 0;
        }
    };
    const [screenReady, setScreenReady] = useState(false);
    const game = React.useRef(new ColoringPagesMenu(setImages, setScreenReady, setHolidayPagesLength));

    return (
        <>
            {!screenReady && <img src={require("./meter.jpg")} alt="loading..." />}
            <AnimateCC
                key="coloringBoard"
                base="/coloring_pages/"
                animationName="coloring_board"
                onLoaded={async (mc: MC) => {
                    game.current.loaded(mc);
                }}
                scale={0.7}
                shiftX={175}
                shiftY={125}
            />
            <div className={styles.footer_english}>http://www.sodmaya.co.il</div>
            <div className={styles.footer_Hebrew}>הסוד של מיה ©</div>
            {images.map((imageNum) => {
                let imageSrc: string = "";

                let hAdditional_pages_num = setHolidayPagesLength(game.current.holiday);
                if (imageNum <= hAdditional_pages_num) {
                    const holiday = game.current.holiday;
                    imageSrc = `/coloring_pages/print/${holiday}_${imageNum.toString().padStart(4, "0")}.svg`;
                } else {
                    imageSrc = `/coloring_pages/print/image_${(imageNum - hAdditional_pages_num)
                        .toString()
                        .padStart(4, "0")}.svg`;
                }
                return (
                    <img
                        alt="coloring page"
                        key={`image_${imageNum - hAdditional_pages_num}`}
                        className={styles.only_print}
                        src={imageSrc}
                    />
                );
            })}
        </>
    );
}
