import { getRandomNumber } from "../../../utils/MathUtils";
import { loadSound } from "../../../animate/sound_utils";
import { MC, playSound, playSoundSync, waitFor, waitForEvent, waitForTimer } from "../../../animate/utils";
import { SpecialGameBasic } from "../../SpecialGameBasic";
import { getPageSetup } from "./hipomazeFileFunctions";

const HIPO_TILE_NUM = 17;
const LEFT_EDGES = [1, 12, 23, 34, 45, 56, 67];
const RIGHT_EDGES = [11, 22, 33, 44, 55, 66, 77];

export class HipomazeGame extends SpecialGameBasic {
    initLevels: boolean = true;
    chosenLevel: number;
    setupData: any;
    rowData: number[] = [];
    objInitx: any;
    objInity: any;
    hipo: MC;
    playerItems: MC;
    playerTileNum: number;
    item_num: number;
    prizeIndex = 0;
    soundsLoaded: boolean;
    instSound: createjs.AbstractSoundInstance;
    finishedInst: boolean = false;
    setBg: boolean = true;
    screensShown: number[] = [];
    selectorOpen: boolean = false;
    isPlacing: boolean = false;
    endSound: createjs.AbstractSoundInstance;

    loaded = async (root: MC) => {
        this.root = root;
        await waitFor(() => this.root);
        (window as any).hipomaze = this.root;
        // this.root.end_anim.gotoAndStop(0);
        // await waitForTimer(300);
        this.setupFrame();
        this.root.gotoAndStop("game");
        this.root.trans.gotoAndStop(0);
        this.initLevelSelector();
        await waitForTimer(1500);
        this.root.start.gotoAndPlay(1);
        await this.openSelector();
        this.addTileMc();
        this.addKeyboardEvents();
        // this.openSelector();
        this.loadSounds();
        this.disableSpeaker();
        await this.playInst();
        // this.setBgSound();
        this.enableHelp();
        this.disableSpeaker();
    };

    playInst = async () => {
        await loadSound("/week_games/sounds/hipomaze/hipo_inst.mp3");
        this.instSound = playSoundSync("/week_games/sounds/hipomaze/hipo_inst.mp3");
        this.instSound.on("complete", () => {
            this.finishedInst = true;
            // this.setBgSound();
        });
    };

    setupFrame = () => {
        this.stopPrizes();
        this.helpSound = "/week_games/sounds/hipomaze/hipo_inst.mp3";
        // this.instructionSound = "/week_games/sounds/hipomaze/hipo_inst.mp3";
        this.bGSound = "/week_games/sounds/hipomaze/MusicHipo.mp3";
        this.initExit();
        this.initHelp();
        this.initSpeker();
        this.initIconMc();
    };

    stopPrizes = () => {
        for (let index = 0; index < 3; index++) {
            const prize = this.root.prizes[`prize${index}`];
            prize.gotoAndStop("off");
        }
    };

    loadSounds = async () => {
        this.soundsLoaded = false;
        for (let index = 4; index < 7; index++) {
            await loadSound(`/week_games/sounds/hipomaze/good${index}.mp3`);
        }
        await loadSound("/week_games/sounds/hipomaze/end.mp3");
        this.soundsLoaded = true;
    };
    addTileMc = () => {
        this.hipo = new this.root.lib.hipo();
        this.playerItems = new this.root.lib.Player();
        this.hipo.visible = false;
        this.hipo.name = "hipo";
        this.playerItems.visible = false;
        this.hipo.gotoAndStop(0);
        this.root.addChild(this.hipo);
        this.root.addChild(this.playerItems);
        for (let i = 0; i < 77; i++) {
            const tiles_mc = new this.root.lib.Tile();
            tiles_mc.name = "tiles_mc";
            tiles_mc.gotoAndStop("empty");
            this.root.tiles[`t${i + 1}`].addChild(tiles_mc);
        }
    };

    initLevelSelector = () => {
        this.btnOverAndOut(this.root.btn_levels, "/week_games/simon_game/sounds/Levels.mp3", "chooser");
        this.root.btn_levels.addEventListener("click", () => {
            if (!this.enableLevelBtn) return;
            this.root.btn_levels.gotoAndStop("down");

            this.selectorOpen ? this.closeSelector() : this.openSelector();
        });
        // this.initLevelBtns(this.root,this.root.levelSelector,false,this.chooseLevel)
    };

    openSelector = async () => {
        this.levelSelected = false;
        this.selectorOpen = true;
        this.disableSpeaker();
        this.root.levelSelector.gotoAndPlay("in");
        // this.disableLevelChooser();
        await waitForEvent(this.root, "in");
        if (this.initLevels) {
            const pannel = this.root.levelSelector;
            this.initLevelBtns(pannel, false, this.chooseLevel);
            this.initLevels = true;
        }
    };

    closeSelector = () => {
        this.selectorOpen = false;
        this.resetGameProps();
        this.root.levelSelector.gotoAndStop(0);
        this.enableLevelChooser();
    };

    resetGameProps = () => {
        this.rowData = [];
        this.setupData = [];
        this.prizeIndex = 0;
        this.screensShown = [];
    };

    chooseLevel = (level: number) => {
        this.root.gotoAndStop("game");
        this.stopPrizes();
        if (this.speakerOn) {
            this.bgSound && this.bgSound.play();
        }
        this.closeSelector();
        this.enableSpeaker();
        this.chosenLevel = level;
        this.startGame();
    };

    startGame = async () => {
        this.hipo.visible = false;
        this.isPlacing = false;
        this.root.start.gotoAndStop(0);
        this.root.trans.gotoAndPlay(1);
        !this.finishedInst && this.instSound.stop();
        this.endSound && this.endSound.stop();
        this.setBg && this.setBgSound();
        this.enableSpeaker();
        this.setBg = false;
        this.finishedInst = true;

        this.setupData = getPageSetup(this.chosenLevel, this.screensShown);
        this.screensShown.push(this.setupData.index);
        console.log("screen", this.setupData.index);

        this.rowData = this.setupData.rowData;
        await waitForEvent(this.root, "finishFadeOut");

        this.initPlayer();
        this.placeTiles();
        this.root.setChildIndex(this.root.trans, this.root.children.length - 1);
        await waitForEvent(this.root, "finishFade");
        // this.hipo.visible = true;
    };

    placeTiles = () => {
        let tile_num: number;
        for (let index = 0; index < 77; index++) {
            tile_num = this.rowData[index];
            this.root.tiles[`t${index + 1}`].getChildByName("tiles_mc").gotoAndStop(tile_num - 1);
            if (tile_num === HIPO_TILE_NUM) {
                this.hipo.name = "hipo";
                this.hipo.visible = true;
                this.hipo.scale = 0.85;
                this.hipo.gotoAndStop(0);
                this.hipo.x = this.root.tiles[`t${index + 1}`].x;
                this.hipo.y = this.root.tiles[`t${index + 1}`].y;

                this.hipo.y -= 15;
                this.hipo.x += 10;
            }
        }
    };

    initPlayer = () => {
        this.item_num = getRandomNumber(0, 6);
        this.playerItems.gotoAndStop(this.item_num);
        this.playerItems.visible = true;
        this.playerItems.glow.gotoAndPlay(0);
        const playerX = Number(this.setupData.initx) + 1;
        const playerY = Number(this.setupData.inity) * 11;
        this.playerTileNum = playerX + playerY;
        this.placePlayerOnTile();
    };

    placePlayerOnTile = async () => {
        this.playerItems.x = this.root.tiles[`t${this.playerTileNum}`].x;
        this.playerItems.y = this.root.tiles[`t${this.playerTileNum}`].y;
        this.playerItems.x += 49;
        this.playerItems.y += 34;
        await waitForTimer(5);
    };

    addKeyboardEvents = async () => {
        await loadSound("/week_games/sounds/hipomaze/HezHipo.mp3");
        document.addEventListener("keydown", async (e: any) => {
            this.playerItems.glow.gotoAndStop("none");
            await waitFor(() => !this.isPlacing);
            playSound("/week_games/sounds/hipomaze/HezHipo.mp3");
            this.isPlacing = true;
            switch (e.key) {
                case "ArrowRight":
                    if (
                        this.isPathTile(
                            this.root.tiles[`t${this.playerTileNum + 1}`],
                            RIGHT_EDGES.includes(this.playerTileNum)
                        )
                    ) {
                        this.playerTileNum += 1;
                        await this.placePlayerOnTile();
                        this.isPlacing = false;
                    } else {
                        this.isPlacing = false;
                    }
                    return;
                case "ArrowLeft":
                    if (
                        this.isPathTile(
                            this.root.tiles[`t${this.playerTileNum - 1}`],
                            LEFT_EDGES.includes(this.playerTileNum)
                        )
                    ) {
                        this.playerTileNum -= 1;
                        await this.placePlayerOnTile();
                        this.isPlacing = false;
                    } else {
                        this.isPlacing = false;
                    }
                    return;
                case "ArrowUp":
                    if (this.isPathTile(this.root.tiles[`t${this.playerTileNum - 11}`], false)) {
                        this.playerTileNum -= 11;
                        await this.placePlayerOnTile();
                        this.isPlacing = false;
                    } else {
                        this.isPlacing = false;
                    }
                    return;
                case "ArrowDown":
                    if (this.isPathTile(this.root.tiles[`t${this.playerTileNum + 11}`], false)) {
                        this.playerTileNum += 11;
                        await this.placePlayerOnTile();
                        this.isPlacing = false;
                    } else {
                        this.isPlacing = false;
                    }
                    return;
                default:
                    this.isPlacing = false;
                    break;
            }
        });
    };

    isPathTile = (tile: MC, isEdge: boolean): boolean => {
        if (isEdge) return false;
        if (tile && tile.getChildByName("tiles_mc").currentFrame < 16) {
            return true;
        } else if (tile && tile.getChildByName("tiles_mc").currentFrame === 16) {
            this.playEnd();
            return true;
        }
        return false;
    };

    addPrize = () => {
        this.root.prizes[`prize${this.prizeIndex}`].gotoAndStop("on");
        this.prizeIndex++;
    };

    playEnd = async () => {
        this.addPrize();
        this.playerItems.visible = false;
        this.hipo.gotoAndPlay("eat");
        await waitForEvent(this.root, "initItem");
        this.hipo.stop();
        this.hipo.thing.otherThing.gotoAndStop(this.item_num);
        this.hipo.gotoAndPlay("item_fall");
        await waitForEvent(this.root, "endAnim");
        if (this.prizeIndex === 3) {
            this.playEndLevelAnim();
        } else {
            await waitForTimer(500);
            this.startGame();
        }
    };

    playEndLevelAnim = async () => {
        await waitFor(() => this.soundsLoaded);
        this.disableLevelChooser();
        this.bgSound.stop();
        this.root.gotoAndStop("end");
        this.root.setChildIndex(this.root.end_anim, this.root.children.length - 2);
        this.root.end_anim.gotoAndPlay("play_anim");
        await waitForEvent(this.root, "finishedEndAnim");
        this.openSelector();
        this.root.setChildIndex(this.root.levelSelector, this.root.children.length - 1);
        await this.sayGood();
        this.endSound = playSoundSync("/week_games/sounds/hipomaze/end.mp3");
        this.enableLevelChooser();
    };

    sayGood = async () => {
        await waitFor(() => this.soundsLoaded);
        const num = getRandomNumber(4, 6);
        await playSound(`/week_games/sounds/hipomaze/good${num}.mp3`);
    };
}
