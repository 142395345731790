import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, setupStage, stopAllSounds, waitFor, waitForEvent } from "../../animate/utils";
import { CancelablePromise } from "../../utils/CancelablePromise";

export class BavelGame {
    root: MC;
    onMove = true;
    maxBlocks: number = 14;
    blockIndex: number = 0;
    playingInst: boolean = true;
    endPrevClick: boolean = true;
    awaitingPromise: CancelablePromise<any>;

    constructor(private frame: any) {}
    loaded = async (mc: MC) => {
        this.root = mc;
        this.root.framerate = 25;
        await waitFor(() => this.frame);
        setupStage(this.frame.root, this.root);
        (window as any).game = this.root;
        this.blockIndex = 1;
        this.initFrame();
        await loadSound("/bible_stories/bavel_game/sounds/Click7wav.mp3");
        this.startGame();
    };
    initFrame = () => {
        this.frame.togglePrint(false);
        this.frame.toggleHelp(true);
        this.frame.toggleReplay(false);
        this.initHelpBtn();
    };

    startGame = async () => {
        this.frame.disableBtn(this.frame.root.frame.btnHelp);
        this.loadAndPlaySound("/bible_stories/sounds/Bavel_inst.mp3").then(() => {
            this.playingInst = false;
            this.frame.enableBtn(this.frame.root.frame.btnHelp);
        });
        await waitFor(() => this.root);
        this.root.gotoAndPlay(1);
        await waitForEvent(this.root, "finishedLoad");
        this.showFirstBlock();
    };

    showFirstBlock = async () => {
        this.root.gotoAndPlay("stage_1");
        // this.root.b1.scale = 0.8;
        // event.stopPropagation();
        this.root.btn.removeAllEventListeners();
        await waitForEvent(this.root, "stop1");
        this.root.gotoAndStop(`stage_1_end`);
        this.onMove = false;
        this.endPrevClick = true;
        this.addBtnEvents();
    };

    addBtnEvents = () => {
        this.root.btn.addEventListener("click", async (event: Event) => {
            if (this.inEnd) {
                // event.stopImmediatePropagation();
                return;
            }
            if (this.onMove) {
                if (this.blockIndex < 14) {
                    this.blockIndex++;
                } else {
                    return;
                }
            } else {
                this.blockIndex++;
                this.buildBlock(this.blockIndex);
            }
        });
    };

    buildBlock = (blockNum: number) => {
        this.onMove = true;
        this.playingInst && stopAllSounds();
        this.playingInst = false;
        this.onMove = true;
        waitForEvent(this.root, "endClouds").then(() => {
            this.root.clouds.gotoAndStop("full");
        });
        playSound("/bible_stories/bavel_game/sounds/Click7wav.mp3");
        this.root.gotoAndStop(`stage_ ${blockNum}`);
        this.root.gotoAndPlay(`stage_ ${blockNum}`);
        waitForEvent(this.root, `stop${blockNum}`).then(async () => {
            console.log("recieved stop event");

            this.root.gotoAndStop(`stage_${blockNum}_end`);
            if (blockNum === this.maxBlocks) {
                await this.playEnd();
                this.blockIndex = 1;
            } else {
                if (this.blockIndex > blockNum) {
                    this.buildBlock(blockNum + 1);
                } else {
                    this.onMove = false;
                }
            }
        });
    };
    initHelpBtn = async () => {
        this.frame.root.frame.btnHelp.addEventListener("click", async () => {
            if (this.playingInst) return;
            await playSound("/bible_stories/sounds/Bavel_inst.mp3");
        });
    };

    playEnd = async () => {
        this.inEnd = true;
        // this.awaitingPromise?.cancel?.();
        this.root.gotoAndPlay("fall");
        await waitForEvent(this.root, "finishedEndAnim");
        this.inEnd = false;
        await this.showFirstBlock();
    };

    async cancelableWait(p: Promise<any>) {
        this.awaitingPromise = p as CancelablePromise<any>;
        await p;
        this.awaitingPromise = null;
    }

    loadAndPlaySound = async (soundUrl: string) => {
        await loadSound(soundUrl);
        await playSound(soundUrl);
    };
    inEnd: boolean;
}
