import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { IcecreamAbcGame } from "./IcecreamAbcGame";

const IcecreamAbcComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new IcecreamAbcGame(frame));

    return <AnimateCC base="/lunapark/abc/icecream_abc" animationName="icecream_abc" onLoaded={game.current.loaded} />;
};
export default IcecreamAbcComponent;
