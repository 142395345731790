import { MC, playSoundSync, stopAllSounds } from "../../animate/utils";
import { returnAlertMessage } from "./jsonFileFunction";
import { SoundToolTip } from "../../utils/soundTooltip";
import { routes } from "../../navigation/routesPath";
import { Navigation } from "../../navigation/navigation";
//import { setReviewGameCompleted } from "../../api/lettersIsland";

export class ReviewGameBasic {
    public helpSound: string;
    public sound: any;
    public root: MC;
    objectSound: any;
    funcs: any = { reset: null, help: null, exit: null };
    clickDone: boolean = false;
    soundPlayed: boolean = false;

    public setAlertBox(alert: MC, playAgain: () => void, letterIndex: any, game?: string) {
        // game && setReviewGameCompleted(letterIndex, game);
        this.disableButtons(["reset", "help", "exit"]);
        const initButton = (btn: string) => {
            SoundToolTip.addToolTip(alert[btn], btn);
            alert[btn].addEventListener("rollover", () => {
                alert[btn].gotoAndStop("over");
            });
            alert[btn].addEventListener("rollout", () => {
                alert[btn].gotoAndStop("up");
            });
            alert[`giraff_${btn}`].mouseEnabled = false;
        };

        alert.visible = true;
        initButton("yes");
        initButton("no");
        // alert.yes.children[0].cursor = "pointer"
        alert.message.visible = true;

        alert.message.font = "bold 18px 'ProntoMF'";
        alert.message.text = returnAlertMessage("playAgain");
        this.objectSound = playSoundSync("PlayAgain");

        alert.yes.addEventListener("click", () => {
            alert.yes.removeAllEventListeners();
            playAgain();
        });
        alert.no.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.letters.review.menu.replace(":sectionIndex", letterIndex));
        });
    }

    enableButtons(buttonsArr: string[]) {
        buttonsArr.forEach((btn: string) => {
            this.root[btn].removeAllEventListeners();
            this.root[btn].cursor = "pointer";
            this.initButton(this.root[btn], this.funcs[btn]);
        });
    }

    disableButtons(buttonsArr: string[]) {
        buttonsArr.forEach((btn: string) => {
            this.root[btn].cursor = "default";
            this.root[btn].removeAllEventListeners();
        });
    }

    initButton(btn: MC, onClick: any) {
        btn.innerButton.gotoAndStop(0);
        if (this.root.newSet) {
            this.root.newSet[`${btn.name}_icon`].mouseEnabled = false;
        } else if (btn[`${btn.name}_icon`]) {
            btn[`${btn.name}_icon`].mouseEnabled = false;
        }
        btn.visible = true;
        btn.cursor = "pointer";
        btn.addEventListener("rollover", () => {
            btn.innerButton.gotoAndStop("over");
            if (!this.soundPlayed) {
                this.objectSound = playSoundSync(btn.name);
            }
        });
        btn.addEventListener("rollout", () => {
            btn.innerButton.gotoAndStop("up");
            if (this.objectSound) this.objectSound.volume = 0;
        });
        btn.addEventListener("mousedown", () => {
            btn.innerButton.gotoAndStop("down");
            if (this.sound) this.sound.volume = 0;
            onClick();
        });
        btn.addEventListener("pressup", () => {
            btn.innerButton.gotoAndStop("over");
        });
    }

    public initButtons(
        root: MC,
        resetFunc: () => void,
        helpSound: string,
        letterIndex: number,
        backgroundSound?: any,
        backFunc?: any,
        helpFunc?: any
    ) {
        this.root = root;
        this.funcs.reset = () => {
            resetFunc();
        };

        this.funcs.help = () => {
            helpFunc && helpFunc();
            this.sound = playSoundSync(helpSound);
            this.soundPlayed = true;
            this.sound.on("complete", () => {
                this.soundPlayed = false;
            });
        };

        this.funcs.exit = () => {
            stopAllSounds();
            if (backFunc) {
                backFunc();
            } else {
                Navigation.openPage(routes.letters.review.menu.replace(":sectionIndex", letterIndex + ""));
            }
        };

        this.initButton(this.root.reset, this.funcs.reset);
        this.initButton(this.root.help, this.funcs.help);
        this.initButton(this.root.exit, this.funcs.exit);
    }
}
