import { globalToLocal, MC } from "../animate/utils";
import { CreationApi } from "../api/creationApi";
import { CHARACTERS, LETTERS, LETTERS_MAP, LTR, RTL } from "./utils/constants";
import { CreationGame } from "./CreationGame";
import { colorMap } from "./mapping/colorMap";
import { stampMap } from "./mapping/stampMap";
import { getIdForSentence } from "./utils/mapUtils";

export class SaveCreation {
    private Api = new CreationApi();
    private lastSDump: string = "";
    constructor(private root: MC) {}

    saveCreation = (itemsOnBoard: any[], creationGame: CreationGame) => {
        if (!itemsOnBoard.length) return false;
        let sDump = this.createSDump(creationGame, itemsOnBoard);
        console.log("sDump", sDump);

        if (!sDump.localeCompare(this.lastSDump)) return false;
        this.lastSDump = sDump;
        this.saveToServer(creationGame, sDump);
        return true;
    };

    createSDump = (creationGame: CreationGame, itemsOnBoard: any[]) => {
        let sDump = "nPic:",
            nPic,
            X,
            Y,
            sContent = "",
            nType,
            nSize,
            nColor = "-1",
            nID = "";
        nPic = creationGame.getCurrentBg().toString();
        sDump += nPic;
        itemsOnBoard.forEach((item: any) => {
            nSize = item.sizeIndex + 1;
            nType = itemsOnBoard[itemsOnBoard.indexOf(item)].type;
            const global = globalToLocal(this.root.theWork, item.x, item.y);
            Y = global.y.toFixed(2);
            X = global.x.toFixed(2);
            if (item.text) {
                nColor = (parseInt(this.findColor(item).slice(1, 3)) - 1).toString();
            }
            switch (parseInt(nType)) {
                case 0: //stamp
                    nID = "999";
                    sContent = stampMap[item.lib];
                    nColor = "-1";
                    break;
                case 1: //sentence
                    nID = getIdForSentence(item.text);
                    sContent = "";
                    break;
                case 2: //letter
                    nID = this.findLetterId(item);
                    sContent = "";
                    break;
                case 3: //digit
                    nID = item.text;
                    sContent = "";
                    break;
                case 4: //keyboardinput
                    nID = "";
                    sContent = this.inputContent(item.text);
                    break;
                default:
                    break;
            }
            sDump = this.toPathString(X, Y, nType, nID, nSize, nColor, sContent, sDump);
        });
        return sDump;
    };
    toPathString = (
        X: any,
        Y: any,
        nType: any,
        nID: string,
        nSize: any,
        nColor: string,
        sContent: string,
        sDump: string
    ) => {
        const nItem =
            "x:" +
            X +
            "&y:" +
            Y +
            "&nType:" +
            nType +
            "&nID:" +
            nID +
            "&nSize:" +
            nSize +
            "&nColor:" +
            nColor +
            "&sContent:" +
            sContent;

        sDump += "|" + nItem;
        return sDump;
    };

    saveToServer = async (creationGame: CreationGame, sDump: string) => {
        let id;
        const currentId = creationGame.getWorkId();
        id = await this.Api.saveCreation(sDump, currentId, 2, "menu");
        currentId === "0" && creationGame.setWorkId(id);
    };
    findColor = (item: MC) => {
        let color;
        Object.keys(colorMap).forEach((k) => {
            if (colorMap[k] === item.color) {
                color = k;
            }
        });
        if (color) return color;
        return "c0";
    };
    findLetterId = (item: createjs.Text) => {
        let index = -1;
        for (let i = 0; i < LETTERS.length; i++) {
            if (LETTERS[i] === item.text) index = i;
        }
        if (index >= 0) return (index + 1).toString();
        else {
            alert("no letter index");
        }
    };
    inputContent = (text: string) => {
        let content = "";
        for (let index = text.length - 1; index >= 0; index--) {
            const char = text[index];
            if (parseInt(char)) {
                content += char;
            } else {
                if (LETTERS_MAP[char.charCodeAt(0) - 1488]) {
                    content += "%D7%" + LETTERS_MAP[char.charCodeAt(0) - 1488];
                } else if (CHARACTERS[char]) {
                    content += CHARACTERS[char];
                } else if (char === LTR || char === RTL) {
                    continue;
                } else {
                    console.error("char not identified", char);
                    continue;
                }
            }
        }
        return content;
    };
    getWidth = (text: any) => {
        if (text as createjs.Text) return text.getMeasuredWidth();
        return "";
    };
    getHeigt = (text: any) => {
        if (text as createjs.Text) return text.getMeasuredHeight();
        return 0;
    };

    getLastDump = () => {
        return this.lastSDump;
    };
    setLastDump = (sDump: string) => {
        this.lastSDump = sDump;
    };
}
