const Hebcal = require("hebcal");
const DAYS_BEFORE = -21;
const DAYS_AFTER = 1;
const HOLIDAY_DATES = {
    Pesach: { day: 15, month: 1 },
    Sukkot: { day: 15, month: 7 },
    Hanuka: { day: 25, month: 9 },
    Purim: { day: 14 },
};

export function isHoliday(date) {
    const current_hebrew_date = Hebcal.HDate(date);
    const year_calendar = new Hebcal(current_hebrew_date.year);
    if (checkHoliday(current_hebrew_date, year_calendar, HOLIDAY_DATES.Pesach, 6)) {
        return "pesah";
    } else if (checkHoliday(current_hebrew_date, year_calendar, HOLIDAY_DATES.Sukkot, 7)) {
        return "sukkot";
    } else if (checkHoliday(current_hebrew_date, year_calendar, HOLIDAY_DATES.Hanuka, 7)) {
        return "hanuka";
    } else {
        if (checkHoliday(current_hebrew_date, year_calendar, HOLIDAY_DATES.Purim, 1)) {
            return "purim";
        }
        return "none";
    }
}

export function checkHoliday(current_hebrew_date, calendar, holiday, duration) {
    var holiday_month;
    if (holiday === HOLIDAY_DATES.Purim) {
        holiday_month = calendar.isLeapYear() ? 13 : 12;
    } else {
        holiday_month = holiday.month;
    }
    const holiday_begin = calendar.find(holiday.day, holiday_month);
    const holiday_end = calendar.find(holiday.day + duration, holiday_month);
    return shouldShowHoliday(current_hebrew_date, holiday_begin[0], holiday_end[0]);
}

export function shouldShowHoliday(current_date, holiday_begin, holiday_end) {
    const difference_from_first = date_diff_indays(holiday_begin.greg(), current_date.greg());
    const difference_from_last = date_diff_indays(holiday_end.greg(), current_date.greg());
    if (difference_from_first >= DAYS_BEFORE && difference_from_last <= DAYS_AFTER) {
        return true;
    }
    return false;
}

export function date_diff_indays(date1, date2) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
            Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
            (1000 * 60 * 60 * 24)
    );
}
