import { getRandomNumber } from "../../../utils/MathUtils";
import { getSoundsByLetter } from "./NamesSounds";

const jsonData = require("./activities.json");

export function findBeginToLetterActivity(letter: number, nameArr: string[]) {
    let beginArray: any[] = [];
    let index: number;
    jsonData.root.letters.letter.forEach((obj: any) => {
        let letterTxt = letter.toString();
        if (obj.sID === letterTxt) {
            obj.begin.name.forEach((element: any) => {
                beginArray.push(element);
            });
        }
    });
    do {
        index = getRandomNumber(0, beginArray.length - 1);
    } while (nameArr.includes(beginArray[index].sWord));
    return beginArray[index];
}

export function findMidToLetterActivity(letter: number, nameArr: string[]) {
    let midArray: any[] = [];
    let index: number;
    jsonData.root.letters.letter.forEach((obj: any) => {
        let letterTxt = letter.toString();
        if (obj.sID === letterTxt) {
            obj.mid.name.forEach((element: any) => {
                midArray.push(element);
            });
            if (obj.end) {
                if (obj.end.name.length > 1)
                    obj.end.name.forEach((element: any) => {
                        midArray.push(element);
                    });
                else {
                    midArray.push(obj.end.name);
                }
            }
        }
    });
    do {
        index = getRandomNumber(0, midArray.length - 1);
    } while (nameArr.includes(midArray[index].sWord));
    return midArray[index];
}

export function findIncorrectToLetterActivity(
    letter: number,
    nameArr: string[]
) {
    let incorrectArray: any[] = [];
    let index: number;
    jsonData.root.letters.letter.forEach((obj: any) => {
        let letterTxt = letter.toString();
        if (obj.sID === letterTxt) {
            obj.incorrect.name.forEach((element: any) => {
                incorrectArray.push(element);
            });
        }
    });
    do {
        index = getRandomNumber(0, incorrectArray.length - 1);
    } while (nameArr.includes(incorrectArray[index].sWord));
    return incorrectArray[index];
}

export function returnFrameToFigureType(type: any, figureArr: any[]) {
    let typeArr: any[] = [];
    let index: number;
    jsonData.root.settings.types.type.forEach((element: any) => {
        if (element.nID === type) {
            console.log("json", type);
            typeArr = element.aPictures.split(",").map(Number);
            do {
                index = getRandomNumber(0, typeArr.length - 1);
            } while (
                figureArr.includes(typeArr[index]) ||
                typeArr[index] === 2
            );
        }
    });
    return typeArr[index];
}

export function validate(letter: number) {
    console.log("in validate");
    let soundsArr: any[] = [];
    let nameSoundsArr: any;
    let nameSoundsIdArr: any[] = [];
    const lettertxt = (letter + 1).toString();

    jsonData.root.letters.letter.forEach((obj: any) => {
        if (obj.sID === lettertxt) {
            obj.begin.name.forEach((element: any) => {
                soundsArr.push(element.sSound);
            });
            obj.mid.name.forEach((element: any) => {
                soundsArr.push(element.sSound);
            });

            obj.incorrect.name.forEach((element: any) => {
                soundsArr.push(element.sSound);
            });
            if (obj.end) {
                if (obj.end.name.length > 1)
                    obj.end.name.forEach((element: any) => {
                        soundsArr.push(element.sSound);
                    });
                else {
                    soundsArr.push(obj.end.name.sSound);
                }
            }
        }
    });
    nameSoundsArr = getSoundsByLetter(letter + 1);
    nameSoundsArr.forEach((sound: any) => {
        nameSoundsIdArr.push(sound.id);
    });

    // console.log("activities",soundsArr.length,soundsArr);
    // console.log("names",nameSoundsIdArr.length,nameSoundsIdArr);

    soundsArr.forEach((sound: any) => {
        if (nameSoundsIdArr.includes(sound) === false) {
            console.log("in letter", letter, "missing in Name.ts", sound);
        }
    });
    // console.log("activities not in sounds", activityArr);
    // console.log("activities not in sounds", activityArr);
}
