export const characters = [
    "Enter",
    "Meta",
    "Alt",
    "Control",
    "Tab",
    "ArrowLeft",
    "ArrowUp",
    "ArrowDown",
    "ArrowRight",
    "CapsLock",
    "Unidentified",
    "Backspace",
];
export const map: any = {
    a: "ש",
    b: "נ",
    c: "ב",
    d: "ג",
    e: "ק",
    f: "כ",
    g: "ע",
    h: "י",
    i: "ן",
    j: "ח",
    k: "ל",
    l: "ך",
    m: "צ",
    n: "מ",
    o: "ם",
    p: "פ",
    q: "/",
    r: "ר",
    s: "ד",
    t: "א",
    u: "ו",
    v: "ה",
    w: "׳",
    x: "ס",
    y: "ט",
    z: "ז",
    ";": "ף",
    ",": "ת",
    ".": "ץ",
};

export const Codes = ["Quote", "Slash", "IntlBackslash"];
