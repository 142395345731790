import { MC, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { loadSound, soundPlayed } from "../../animate/sound_utils";
import { CancelablePromise } from "../../utils/CancelablePromise";
import { getRandomNumber } from "../../utils/MathUtils";

export class DubbyBaseGame {
    root: MC;
    enabledNextBtn: (btnStatus: boolean) => void;
    enabledHelpBtn: (btnStatus: boolean) => void;
    hidePreloader: () => void;
    showDubby: () => void;
    showDubbyFeedBack: (withReaction?: boolean) => void;
    setText: (objectName: string) => void;
    setSpeaker: (btnStatus: boolean) => void;
    sethelpSrc: (src: string) => void;
    currentPromise: CancelablePromise<any>;
    cancelableWait: (p: Promise<any>) => void;
    objectsToRandom: string[] = [];
    currentObj: any = null;
    selectedObjName: string = null;
    selectedObjIndex: number;
    objects: string[];
    objectsName: string[];
    whatsInInterval: any = null;
    currentItemSound: any = null;
    dubbyReactionSrcSound: string;
    currentRoom: string;
    enabledKeyBoard: boolean = true;
    clickSound: Promise<createjs.AbstractSoundInstance>;

    loaded() {
        const mask = new createjs.Shape();
        mask.graphics.beginFill("white").drawRect(0, 0, 800, 600);
        this.root.mask = mask;
        this.root.gotoAndStop(0);
        for (let i = 0; i < this.objects.length - 1; i++) {
            this.root[`item_${i}`].gotoAndStop(0);
        }
        stopAllSounds();
    }
    beeMode() {
        this.GenericInitAndRegistry("bee", this.correctBeeSelection, this.selectBeeObject);
        this.showDubby();
        this.selectBeeObject();
    }
    selectBeeObject = () => {
        this.selectRandomObject();
        const prefix = this.selectedObjName === "Window" && this.currentRoom === "KitchenRoom" ? "C" : "";
        this.currentItemSound = loadSound(`/dubby_english/sounds/bee/${prefix + this.selectedObjName}.mp3`);
        // this.root["bee" + this.selectedObjName].visible = true;
    };
    correctBeeSelection = async () => {
        this.dubbyReactionSrcSound = "bee/Question" + getRandomNumber(1, 4);
        await this.cancelableWait(soundPlayed((await this.currentItemSound).play()));
        // when bee is not displayed:
        this.root["bee" + this.selectedObjName].visible = true;
        this.root["bee" + this.selectedObjName].gotoAndPlay(0);
        await this.cancelableWait(waitForEvent(this.root["bee" + this.selectedObjName], "out"));
        this.root["bee" + this.selectedObjName].gotoAndPlay("out" + getRandomNumber(0, 1));
        await this.cancelableWait(waitForEvent(this.root["bee" + this.selectedObjName], "animationEnd"));
        this.root["bee" + this.selectedObjName].visible = false;
    };

    async whatInMode() {
        this.GenericInitAndRegistry("whatsIn", this.correctWhatsInSelection, this.selectWhatsInObject);
        await this.showDubby();
        this.selectWhatsInObject();
    }
    selectWhatsInObject = async () => {
        this.setText("");
        this.selectRandomObject();
        this.currentItemSound = loadSound(`/dubby_english/sounds/whatsIn/${this.selectedObjName}.mp3`);
        await this.cancelableWait(soundPlayed((await this.currentItemSound).play()));
        this.enabledHelpBtn(true);
        clearInterval(this.whatsInInterval);
        this.whatsInInterval = this.whatInSetInterval();
    };
    whatInSetInterval = () => {
        return setInterval(async () => {
            await this.cancelableWait(soundPlayed((await this.currentItemSound).play()));
        }, 7000);
    };

    correctWhatsInSelection = async () => {
        this.dubbyReactionSrcSound = "common/Good" + getRandomNumber(0, 9);
        clearInterval(this.whatsInInterval);
        this.setText(`${this.getPrefix()} ${this.objectsName[this.selectedObjIndex]}`);
        await this.cancelableWait(soundPlayed((await this.currentItemSound).play()));
        this.currentObj.gotoAndPlay(1);
        await this.cancelableWait(waitForEvent(this.currentObj, "animationEnd"));
    };

    async GenericInitAndRegistry(type: string, correctSelection: Function, selectItem: Function) {
        this.sethelpSrc(`/dubby_english/sounds/${type}/help.mp3`);

        this.objects.forEach((item, index) => {
            this.root[item].cursor = "pointer";
            this.root["hila" + item].visible = false;
            if (type === "bee") this.root[type + item].visible = false;
            this.root[item].gotoAndStop(0);

            this.root[item].addEventListener("rollover", async () => {
                this.root["hila" + item].visible = true;
            });
            this.root[item].addEventListener("rollout", async () => {
                this.root["hila" + item].visible = false;
            });
            this.root[item].addEventListener("click", async () => {
                if (this.currentObj !== null && this.root[item] === this.currentObj) {
                    stopAllSounds();
                    this.enabledHelpBtn(false);
                    this.root.mouseEnabled = false;
                    this.root["hila" + item].visible = false;
                    await correctSelection();
                    stopAllSounds();
                    await this.showDubbyFeedBack(true);
                    this.enabledHelpBtn(true);
                    selectItem();
                    this.root.mouseEnabled = true;
                } else {
                    this.enabledHelpBtn(true);
                    const currentItemSound = loadSound(`/dubby_english/sounds/common/Wrong.mp3`);
                    await this.cancelableWait(soundPlayed((await currentItemSound).play()));
                }
            });
        });
    }

    async buildMode() {
        this.clickSound = loadSound("/dubby_english/sounds/build/click.mp3");
        this.showDubby();
        this.selectObjectToBuild();
    }

    selectObjectToBuild = async () => {
        this.enabledNextBtn(false);
        this.enabledHelpBtn(true);
        this.setSpeaker(false);
        this.sethelpSrc("/dubby_english/sounds/build/help.mp3");
        this.setText("");
        this.selectRandomObject(true);
        this.root.gotoAndStop(this.selectedObjName);
        this.currentObj.gotoAndStop(0);
        await waitForTimer(0);

        let i = 0;
        const clickSound = loadSound("/dubby_english/sounds/build/click.mp3");
        const next = async () => {
            if (this.enabledKeyBoard) {
                i++;
                this.root.mouseEnabled = false;
                this.enabledKeyBoard = false;
                await this.cancelableWait(soundPlayed((await clickSound).play()));
                this.currentObj.gotoAndStop(i);
                await this.cancelableWait(waitForTimer(0));
                this.root.mouseEnabled = true;
                this.enabledKeyBoard = true;
                if (i > 3) {
                    window.removeEventListener("keydown", next);
                    this.root.removeAllEventListeners();
                    this.currentObj.gotoAndPlay("animationLoop");
                    this.enabledHelpBtn(false);
                    this.setText(`${this.getPrefix()} ${this.objectsName[this.selectedObjIndex]}`);

                    if (this.selectedObjName === "KitchenTable") {
                        await this.playTableAnimWithSounds();
                    }
                    await this.cancelableWait(waitForEvent(this.currentObj, "animationEnd"));
                    stopAllSounds();
                    await this.showDubbyFeedBack();
                    this.enabledHelpBtn(true);
                    this.enabledNextBtn(true);
                    this.setSpeaker(true);
                    this.sethelpSrc("/dubby_english/sounds/build/help2.mp3");
                }
            }
        };
        this.root.addEventListener("click", next);
        window.addEventListener("keydown", next);
    };
    nextObject = () => {
        this.selectObjectToBuild();
    };

    playAgain = () => {
        this.playObjectAnimation();
    };
    playObjectAnimation = async () => {
        this.currentObj.gotoAndPlay("animationLoop");
        this.enabledHelpBtn(false);
        this.setSpeaker(false);
        this.enabledNextBtn(false);
        if (this.selectedObjName === "KitchenTable") {
            await this.playTableAnimWithSounds();
        }
        await this.cancelableWait(waitForEvent(this.currentObj, "animationEnd"));
        stopAllSounds();
        this.enabledHelpBtn(true);
        this.enabledNextBtn(true);
        this.setSpeaker(true);
    };
    selectRandomObject = (isBuild: boolean = false) => {
        if (this.objectsToRandom.length === 0) {
            this.objectsToRandom = [...this.objects];
        }
        const index = getRandomNumber(0, this.objectsToRandom.length - 1);
        this.selectedObjName = this.objectsToRandom[index];
        this.selectedObjIndex = this.objects.indexOf(this.selectedObjName);
        this.currentObj = this.root[isBuild ? `item_${this.selectedObjIndex}` : this.selectedObjName];
        this.objectsToRandom.splice(index, 1);
    };

    cleanWhatsIn() {
        clearInterval(this.whatsInInterval);
        stopAllSounds();
    }
    playTableAnimWithSounds = async () => {
        await this.cancelableWait(waitForEvent(this.currentObj, "playSound"));
        soundPlayed((await loadSound("/dubby_english/sounds/build/dishes.mp3")).play());
        await this.cancelableWait(waitForEvent(this.currentObj, "water"));
        soundPlayed((await loadSound("/dubby_english/sounds/build/water.mp3")).play());
    };
    getPrefix = () => {
        let prefix: string;
        const objectname = this.objectsName[this.selectedObjIndex];
        if (objectname.endsWith("s")) {
            prefix = "";
        } else if (objectname.startsWith("a") || objectname.startsWith("u") || objectname.startsWith("o")) {
            prefix = "an";
        } else {
            prefix = "a";
        }
        return prefix;
    };
}
