import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { BeeGame } from "./BeeGame";
import { additionalSounds } from "./BeeSounds";

const BeeComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new BeeGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/bee"
            animationName="bee"
            onLoaded={game.current.loaded}
            changeFramerate={true}
            additionalSounds={additionalSounds}
        />
    );
};
export default BeeComponent;
