export const reviewGames = {
    KITES: "1",
    BOXES: "2",
    FISHES: "3",
    PENG_START: "401",
    PENG_END: "402",
    PENG_ALL: "403",
};
export const setLetterDone = (letter: number, activity: string) => {
    return fetch(`/Ajax/abcUserStatus.aspx?nUnit=&nLetter=${letter}&sActivity=${activity}&nMark=`, {
        body: "<root></root>",
        method: "POST",
        mode: "cors",
        credentials: "include",
    });
};

export const getLettersStatus = async () => {
    const res = await fetch("/abcsection/mainMenu/GetAbcStatus.aspx", {
        mode: "cors",
        credentials: "include",
    });

    const body = await res.text();
    const parser = new DOMParser();
    const xml = parser.parseFromString(body, "text/xml");

    const letters = [];
    for (let index = 1; index <= 22; index++) {
        const letterStatus = xml.querySelector(`Letter[nLetter="${index}"]`)?.getAttribute("nStatus");
        letters[index] = letterStatus === "1";
    }
    const boxes = [];
    for (let index = 1; index <= 5; index++) {
        const boxesStatus = xml.querySelector(`Unit[nUnit="${index}"]`)?.getAttribute("nReviewStatus");
        boxes[index] = boxesStatus === "1";
    }
    return { letters, boxes };
};

export const getLetterMenuStatus = (letter: number) => {
    return fetch(`/abcSection/LetterMenu/GetLetterStatus.aspx?nLetter=${letter}`, {
        mode: "cors",
        credentials: "include",
    }).then(async (res) => {
        const body = await res.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(body, "text/xml");
        const letters: boolean[] = [];
        let index = 0;
        ["lesson", "video", "word", "write"].forEach((activity) => {
            const letterStatus = xml.querySelector(`Icon[sName="${activity}"]`)?.getAttribute("nStatus");
            letters[index++] = letterStatus === "1";
        });
        return letters;
    });
};

export const setReviewGameCompleted = (unit: number, activity: string) => {
    return fetch(`/Ajax/abcUserStatus.aspx?nUnit=${unit}&nLetter=&sActivity=${activity}&nMark=`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
    });
};

export const getReviewMenuStatus = (unit: string) => {
    return fetch(`/abcSection/Games/GetReviewStatus.aspx?nUnit=${unit}`, {
        mode: "cors",
        credentials: "include",
    }).then(async (res) => {
        const body = await res.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(body, "text/xml");
        const games: boolean[] = [];
        xml.querySelectorAll("Game").forEach((game) => {
            games.push(game?.getAttribute("nStatus") === "1");
        });
        return games;
    });
};

export const getDoneFancyWords = () => {
    return fetch("/abcSection/FancyMenu/getFancyWordsStatus.aspx", {
        mode: "cors",
        credentials: "include",
    }).then(async (res) => {
        const body = await res.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(body, "text/xml");
        const letters: boolean[] = [];
        xml.querySelectorAll("Letter").forEach((game) => {
            letters.push(game?.getAttribute("nStatus") === "1");
        });
        return letters;
    });
};
