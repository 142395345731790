import React, { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
import AnimateCC from "../../animate";
import { routes } from "../../navigation/routesPath";

import TrampolineComponent from "../trampolin/TrampolineComponent";
import HammerComponent from "../hammer/HammerComponent";
import BalloonsComponent from "../balloons/BalloonsComponent";
import GhostCaveComponent from "../ghost_cave/GhostCaveComponent";
import MirrorsComponent from "../mirrors/MirrorsComponent";
import PuppetTheaterComponent from "../puppet_theater/PuppetComponent";
import SweetsMenuComponent from "../sweets/SweetsMenuComponent";
import AppleComponent from "../sweets/AppleComponent";
import IceCreamComponent from "../sweets/IceCreamComponent";
import PopcornComponent from "../sweets/PopcornComponent";
import MusicComponent from "../music/MusicComponent";
import HorsesComponent from "../horses/HorsesComponent";
import RollerCoasterComponent from "../roller_coaster/RollerCoasterComponent";
import DuckComponent from "../ducks/DuckComponent";
import additionalSounds from "./LunaparkMenuSounds";
import RocketComponent from "../rocket/RocketComponent";
import { useUserContext } from "../../context/UserContext";
import { MC } from "../../animate/utils";
import { LunaparkMenu } from "./LunaparkMenu";
import { SodRoute } from "../../navigation/PrivateRoot";
import BigWheelComponent from "../big_wheel/BigWheelComponent";
import AbcComponent from "../abc/AbcComponent";
import { PreloaderComponent } from "../../loaders/PreloaderComponent";
import { useLunaparkContext } from "../../context/LunaparkContext";

export const GUEST_GAMES = [routes.lunapark.menu, routes.lunapark.wheel.menu];

const LunaparkComponent: React.FC = () => {
    const { pathname } = useLocation();
    const [screenReady, setScreenReady] = useState(false);

    const { isLoggedIn } = useUserContext();
    const { wantedGame, setWantedGame } = useLunaparkContext();

    const menu = new LunaparkMenu(isLoggedIn);
    const frame = React.useRef(menu);

    const isLunaparkMenu = pathname === routes.lunapark.menu;
    const showFrame = isLunaparkMenu || GUEST_GAMES.includes(pathname) || isLoggedIn;

    useEffect(() => {
        frame.current.setIsLoggedIn(isLoggedIn);
    }, [isLoggedIn]);

    return (
        <>
            {!screenReady && <PreloaderComponent name="maya" />}
            <Switch>
                <SodRoute path={routes.lunapark.ghost_cave}>
                    <GhostCaveComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.balloons}>
                    <BalloonsComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.ghost_cave}>
                    <GhostCaveComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.horses}>
                    <HorsesComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.roller_coaster}>
                    <RollerCoasterComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.duck}>
                    <DuckComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.puppets}>
                    <PuppetTheaterComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.music_room}>
                    <MusicComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.mirrors}>
                    <MirrorsComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.hammer}>
                    <HammerComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.trampoline}>
                    <TrampolineComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute exact path={routes.lunapark.sweets.menu}>
                    <SweetsMenuComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.sweets.apple}>
                    <AppleComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.sweets.ice_cream}>
                    <IceCreamComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
                <SodRoute path={routes.lunapark.sweets.popcorn}>
                    <PopcornComponent frame={frame.current} key={Date.now()} />
                </SodRoute>
            </Switch>
            <AnimateCC
                visible={showFrame}
                base="/lunapark/lunapark_menu"
                animationName="lunapark_menu"
                additionalSounds={additionalSounds}
                onLoaded={(mc: MC) => {
                    setScreenReady(true);
                    frame.current.loaded(mc);

                    //Check if we need to load specific game.
                    if (wantedGame !== "") {
                        menu.LoadGame(wantedGame);
                        setWantedGame("");
                    }
                }}
            />
            <Switch>
                <SodRoute path={routes.lunapark.rocket.menu}>
                    <RocketComponent frame={frame.current} />
                </SodRoute>
                <SodRoute path={routes.lunapark.wheel.menu}>
                    <BigWheelComponent frame={frame.current} />
                </SodRoute>
                <SodRoute path={routes.lunapark.abc.menu}>
                    <AbcComponent frame={frame.current} />
                </SodRoute>
            </Switch>
        </>
    );
};

export default LunaparkComponent;
