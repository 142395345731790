import { loadSound } from "../animate/sound_utils";
import { MC, playSound, playSoundSync, stopAllSounds } from "../animate/utils";
import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { SoundToolTip } from "../utils/soundTooltip";

export class SpecialGameBasic {
    public root: MC;
    helpSound: string;
    instructionSound: string;
    bGSound: string;
    bgSound: createjs.AbstractSoundInstance;
    speakerOn: boolean = true;
    levelSelected: boolean = false;
    enableHelpBtn: boolean = false;
    enableLevelBtn: boolean = false;
    enableSpeakerBtn: boolean = false;
    helpSoundInst: createjs.AbstractSoundInstance;

    isBtnRollout: boolean = false;
    disableHelpInClicking: boolean = false;

    public async setBgSound() {
        await loadSound(this.bGSound);
        this.bgSound = playSoundSync(this.bGSound, { loop: -1 });
        this.bgSound.volume = 0.7;
        this.enableSpeaker();
    }

    public async playInstSound() {
        this.disableSpeaker();
        await loadSound(this.instructionSound);
        await playSound(this.instructionSound);
        // this.enableSpeaker();
    }

    public initIconMc() {
        // this.root.icon_mc.mouseChildren = false;
        const hit = new createjs.Shape();
        hit.graphics.beginFill("#000").drawRect(0, 0, 90, 90);
        this.root.icon_mc.hitArea = hit;
        this.root.icon_mc.cursor = "pointer";
        this.root.icon_mc.addEventListener("click", this.exit);
    }

    public async btnOverAndOut(btn: MC, toolTipSound?: string, enable: string = "none") {
        toolTipSound && loadSound(toolTipSound);
        toolTipSound && SoundToolTip.addToolTip(btn, toolTipSound);
        btn.gotoAndStop(0);
        btn.mouseChildren = false;
        btn.addEventListener("rollover", () => {
            if (!this.checkIsEnable(enable)) return;
            btn.cursor = "pointer";
            btn.gotoAndStop("over");
            this.isBtnRollout = false;
        });
        btn.addEventListener("rollout", () => {
            if (!enable) return;
            btn.cursor = "default";
            btn.gotoAndStop("up");
            this.isBtnRollout = true;
        });
        btn.addEventListener("mousedown", () => {
            if (!this.checkIsEnable(enable)) return;
            btn.gotoAndStop("down");
        });
        btn.addEventListener("pressup", () => {
            if (!this.checkIsEnable(enable)) return;
            this.isBtnRollout ? btn.gotoAndStop("up") : btn.gotoAndStop("over");
        });
    }
    checkIsEnable(enable: string) {
        if (enable !== "none") {
            switch (enable) {
                case "help":
                    return this.enableHelpBtn;
                case "speaker":
                    return this.enableSpeakerBtn;
                default:
                    return this.enableLevelBtn;
            }
        }
        return true;
    }
    public enableHelp() {
        this.enableHelpBtn = true;
    }

    public disableHelp() {
        this.enableHelpBtn = false;
    }
    public enableSpeaker() {
        this.enableSpeakerBtn = true;
    }
    public disableSpeaker() {
        this.enableSpeakerBtn = false;
    }
    public async initHelp(helpChooseLevel?: string, hasLevels: boolean = false) {
        this.btnOverAndOut(this.root.btnHelp, "/special_games/sounds/Help.mp3", "help");
        helpChooseLevel && (await loadSound(helpChooseLevel));
        if (this.helpSound) this.helpSoundInst = await loadSound(this.helpSound);
        this.root.btnHelp.addEventListener("click", async (e: Event) => {
            if (!this.enableHelpBtn) return;
            if (this.disableHelpInClicking) {
                this.disableHelp();
            }
            if (hasLevels && !this.levelSelected) {
                await playSound(helpChooseLevel);
            } else {
                this.helpSoundInst?.play();
                await playSound(this.helpSound).then(() => {
                    this.enableHelp();
                });
            }
        });
    }

    public initExit() {
        this.btnOverAndOut(this.root.btnExit, "/special_games/sounds/Exit.mp3");
        this.root.btnExit.addEventListener("click", this.exit);
    }

    exit() {
        // this.root.btnExit.gotoAndStop("down");
        stopAllSounds();
        Navigation.openPage(routes.home);
    }

    public enableLevelChooser() {
        this.enableLevelBtn = true;
    }

    public disableLevelChooser() {
        this.enableLevelBtn = false;
    }

    public initReset(resetFunc: () => void) {
        this.btnOverAndOut(this.root.btnReset);
        this.root.btnReset.addEventListener("click", () => {
            resetFunc();
        });
    }

    public initSpeker() {
        this.btnOverAndOut(this.root.btnSpeaker.btn, null, "speaker");
        // this.root.btnSpeaker.mouseChildren = false;
        this.root.btnSpeaker.addEventListener("mousedown", () => {
            if (!this.enableSpeakerBtn) return;
            this.speakerOn ? this.root.btnSpeaker.gotoAndStop("off") : this.root.btnSpeaker.gotoAndStop("on");
            this.speakerOn ? this.bgSound.stop() : this.bgSound.play();
            this.speakerOn = !this.speakerOn;
        });
    }

    public async initLevelBtns(pannel: MC, addHover: boolean = false, chooseLevelFunc: (level: number) => void) {
        this.levelSelected = false;
        [1, 2, 3].forEach(async (level: number) => {
            const level_btn = pannel[`level_${level}`];
            level_btn.cursor = "pointer";
            level_btn.mouseChildren = false;
            await loadSound(`/special_games/sounds/Level${level}.mp3`);
            SoundToolTip.addToolTip(level_btn, `/special_games/sounds/Level${level}.mp3`);
            addHover && this.btnOverAndOut(level_btn);
            level_btn.addEventListener("click", () => {
                this.root.btn_levels.flag.gotoAndStop(`level_${level}`);
                this.levelSelected = true;
                chooseLevelFunc(level);
            });
        });
    }
}
