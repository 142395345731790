export const additionalSounds = [
    {
        src: "sounds/background_music.mp3",
        id: "creationBg",
    },
    {
        src: "sounds/help_tool.mp3",
        id: "help_tool",
    },
    {
        src: "sounds/yes.mp3",
        id: "correct",
    },
    {
        src: "sounds/no.mp3",
        id: "mistake",
    },
];
