import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, playSoundSync, stopAllSounds, waitFor, waitForEvent } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { reverseString } from "../../utils/StringUtils";
import MathGameBase from "../MathGame/MathGameBase";

const LTR = "\u200E";
// const RTL = "\u200F";

export class Cars extends MathGameBase {
    usedCars: number[] = [];
    currentCar: MC;
    currentCarIndex: number;
    isBarOpen: boolean;
    chosenLevel: number;
    showPopup: () => void;
    answer: number;
    number1: number;
    number2: number;
    currentMethod: string;
    currentQuestionBox: MC;
    correctAnswer: number = 0;
    shouldPlayInst: boolean = true;
    wrongAnswers: number = 0;
    btnsDisabled: boolean = false;
    enableEvents: boolean = true;
    gamesPlayed: number = 0;
    carStandSounds: string[] = [
        "/math/sounds/MasaitStandwav.mp3",
        "/math/sounds/TractorMedwav.mp3",
        "/math/sounds/GlidaCarwav.mp3",
    ];
    btnAllowClick: MC = null;
    name = "cars";
    standSound: createjs.AbstractSoundInstance;
    stopPlaying: boolean;
    currentQuestionAsk: string;
    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        this.root.gotoAndStop(0);
        (window as any).cars = root;
    }

    loaded = () => {
        this.root.gotoAndStop(0);
        this.root.btns_anim_mc.visible = false;
        this.root.btns_anim_mc.x = 322;
        this.root.btns_anim_mc.y = 355;
    };

    chooserUp = () => {
        stopAllSounds();
        this.currentCar.stop();
        this.currentCar.children.forEach((child: any) => {
            child.stop();
        });
        this.enableEvents = false;
        this.stopPlaying = true;
        this.standSound && this.standSound.stop();
    };

    chooserDown = () => {
        this.enableEvents = true;
        this.stopPlaying = false;
        this.closeBar();
        this.newQuestion();
    };

    handleLevel = async (level?: number) => {
        this.disableHelp();
        this.cleanPrizes(true);
        this.correctAnswer = 0;
        this.closeBar();
        this.chosenLevel = level;
        this.enableEvents = true;
        this.stopPlaying = false;
        this.startGame();
    };

    startGame = () => {
        if (this.stopPlaying) return;
        this.initBarBtns();
        loadSound("/math/sounds/car_swing/cars_help.mp3");
        setTimeout(this.playInst, 4000);
        this.newQuestion();
    };

    addBarBtnEvents = (mc: MC, index: number) => {
        const yPos = mc.y;
        mc.cursor = "pointer";
        mc.addEventListener("rollover", () => {
            if (!this.enableEvents) return;
            createjs.Tween.get(mc)
                .to({ y: mc.y - 10 }, 50, createjs.Ease.quadIn)
                .call(() => {});
        });
        mc.addEventListener("rollout", () => {
            if (!this.enableEvents) return;
            createjs.Tween.get(mc)
                .to({ y: yPos }, 50, createjs.Ease.quadIn)
                .call(() => {});
        });
        mc.addEventListener("click", () => {
            if (!this.enableEvents) return;
            mc.y = yPos;
            this.clickNum(mc, index);
        });
    };

    initBarBtns = () => {
        if (this.stopPlaying) return;
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.gotoAndStop(0);
            btn.num_mc.all_num.gotoAndStop(i);
            btn.num_mc.all_num[`num${i}`].gotoAndStop(0);
            btn.mouseChildren = false;
            this.addBarBtnEvents(btn, i);
        }
    };

    disableBarBtns = () => {
        if (this.stopPlaying) return;
        this.btnsDisabled = true;
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.removeAllEventListeners();
        }
    };

    clickNum = async (btn: MC, numClicked: number) => {
        if (this.stopPlaying) return;
        this.enableEvents = false;
        if (numClicked === this.answer) {
            this.disableHelp();
            btn.gotoAndStop("pressed");
            btn.num_mc.all_num[`num${numClicked}`].gotoAndStop(1);
            this.currentQuestionBox.text = reverseString(
                `${this.number1}${this.currentMethod}${this.number2}=${this.answer}`
            );
            this.addPrize("prize_car");
            await this.sayGood();
            this.correctAnswer++;
            this.currentCar.gotoAndPlay("end");
            await waitForEvent(this.currentCar, "animationEnd");
            this.standSound.stop();
            this.correctAnswer < 5 ? this.newQuestion() : this.showEnd();
        } else {
            this.wrongAnswers++;
            if (this.wrongAnswers < 3) {
                const num = getRandomNumber(1, 3);
                await loadSound(`/math/sounds/math/again${num}.mp3`);
                await playSound(`/math/sounds/math/again${num}.mp3`);
                this.enableEvents = true;
            } else {
                this.showCorrect();
                const num = getRandomNumber(0, 1);
                await loadSound(`/math/sounds/math/wrong${num}.mp3`);
                await playSound(`/math/sounds/math/wrong${num}.mp3`);
            }
        }
    };

    sayGood = async () => {
        if (this.stopPlaying) return;
        const num = getRandomNumber(1, 9);
        await playSound(`m_good${num}`);
        this.closeBar();
        await this.sayQuestion(true);
    };

    showCorrect = () => {
        if (this.stopPlaying) return;
        this.disableBarBtns();
        const btn = this.root.btns_anim_mc.btns_mc[`btn${this.answer}_mc`];
        btn.gotoAndStop("pressed");
        btn.num_mc.all_num[`num${this.answer}`].gotoAndStop(1);
        this.enableEvents = true;
        this.btnAllowClick = btn;
        btn.addEventListener("click", () => {
            if (!this.enableEvents) return;
            this.clickNum(btn, this.answer);
        });
    };

    playInst = async () => {
        if (this.stopPlaying) return;
        await playSound("/math/sounds/car_swing/cars_help.mp3");
        this.shouldPlayInst = false;
        this.enableHelp();
    };

    resetQuestionProps = async () => {
        if (this.stopPlaying) return;
        this.btnAllowClick && (await this.btnAllowClick.removeAllEventListeners());
        if (this.btnsDisabled) {
            this.initBarBtns();
            this.btnsDisabled = false;
        }
        this.btnAllowClick = null;
        this.enableEvents = true;
        this.wrongAnswers = 0;
        this.standSound && this.standSound.stop();
        this.standSound = null;
    };

    playStandSound = async () => {
        if (this.stopPlaying) return;
        await loadSound(this.carStandSounds[this.currentCarIndex]);
        this.standSound = playSoundSync(this.carStandSounds[this.currentCarIndex], { loop: -1 });
    };

    newQuestion = async () => {
        if (this.stopPlaying) return;
        this.resetQuestionProps();
        this.randomCar();
        this.generateQuestion(this.chosenLevel === 1 ? "add" : this.chosenLevel === 2 ? "sub" : "both");
        this.displayQuestion();
        this.currentCar.gotoAndPlay(1);
        await waitForEvent(this.currentCar, "finishedEnter");
        this.enableHelp();
        this.playStandSound();
        this.openBar();
    };

    sayQuestion = async (sayEnd: boolean) => {
        if (this.stopPlaying) return;
        await waitFor(() => this.shouldPlayInst === false);
        await playSound(`/math/sounds/car_swing/Excercize_${this.number1}_first.mp3`);

        this.currentQuestionAsk === "+"
            ? await playSound(`/math/sounds/car_swing/cars_gues_plus.mp3`)
            : await playSound(`/math/sounds/car_swing/cars_gues_minus.mp3`);
        await playSound(`/math/sounds/car_swing/Excercize_${this.number2}_middle.mp3`);
        await playSound(`/math/sounds/car_swing/cars_gues_equales.mp3`);

        if (sayEnd) {
            await playSound(`/math/sounds/car_swing/Excercize_${this.answer}_last.mp3`);
        }
    };

    loadQuestionSounds = async () => {
        if (this.stopPlaying) return;
        await loadSound(`/math/sounds/car_swing/Excercize_${this.number1}_first.mp3`);
        this.currentQuestionAsk === "+"
            ? await loadSound(`/math/sounds/car_swing/cars_gues_plus.mp3`)
            : await loadSound(`/math/sounds/car_swing/cars_gues_minus.mp3`);
        await loadSound(`/math/sounds/car_swing/Excercize_${this.number2}_middle.mp3`);
        await loadSound(`/math/sounds/car_swing/cars_gues_equales.mp3`);
        await loadSound(`/math/sounds/car_swing/Excercize_${this.answer}_last.mp3`);
    };

    generateQuestion = async (method: string) => {
        if (this.stopPlaying) return;
        switch (method) {
            case "add":
                this.answer = getRandomNumber(2, 9);
                this.number1 = getRandomNumber(1, this.answer - 1);
                this.number2 = this.answer - this.number1;
                this.currentMethod = LTR + "+";
                this.currentQuestionAsk = "+";
                break;
            case "sub":
                this.number1 = getRandomNumber(2, 9);
                this.answer = getRandomNumber(1, this.number1 - 1);
                this.number2 = this.number1 - this.answer;
                this.currentMethod = LTR + "-";
                this.currentQuestionAsk = "-";

                break;
            case "both":
                var rnd = getRandomNumber(0, 1);
                if (rnd === 0) method = "add";
                else method = "sub";
                this.generateQuestion(method);
                return;
        }
        await this.loadQuestionSounds();
    };

    displayQuestion = () => {
        if (this.stopPlaying) return;

        switch (this.currentCarIndex) {
            case 0:
                this.currentQuestionBox = this.currentCar.box.q_txt;
                break;
            case 1:
                this.currentQuestionBox = this.currentCar.caf_mc.in_caf.q_txt;
                break;
            case 2:
                this.currentQuestionBox = this.currentCar.in_IceCar_text.in_ice_text.q_txt;
                break;
        }
        this.currentQuestionBox.text = reverseString(`${this.number1}${this.currentMethod}${this.number2}=`);
    };

    randomCar = () => {
        if (this.stopPlaying) return;
        let carNum;
        const maxCars = 3;
        if (this.usedCars.length === maxCars) {
            this.usedCars.splice(0, 1);
        }
        do {
            carNum = getRandomNumber(0, 2);
        } while (this.usedCars.includes(carNum));
        this.root.gotoAndStop(carNum);
        this.usedCars.push(carNum);
        this.currentCar = this.root[`car_mc_${carNum}`];
        this.currentCarIndex = carNum;
    };

    openBar = async () => {
        if (this.stopPlaying) return;
        this.isBarOpen = true;
        this.enableEvents = false;
        this.root.btns_anim_mc.visible = true;
        return new Promise<void>((resolve) => {
            createjs.Tween.get(this.root.btns_anim_mc)
                .to({ y: 355 }, 800)
                .call(() => {
                    this.enableEvents = true;
                    resolve();
                });
        });
    };

    resetBarBtns = () => {
        if (this.stopPlaying) return;
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.gotoAndStop(0);
            btn.num_mc.all_num.gotoAndStop(i);
            btn.num_mc.all_num[`num${i}`].gotoAndStop(0);
        }
    };

    closeBar = async () => {
        if (this.stopPlaying) return;
        if (this.isBarOpen) {
            this.resetBarBtns();
        }
        this.isBarOpen = false;
        return new Promise<void>((resolve) => {
            createjs.Tween.get(this.root.btns_anim_mc)
                .to({ y: 800 }, 500)
                .call(() => {
                    this.enableEvents = false;
                    resolve();
                });
        });
    };

    showEnd = async () => {
        if (this.stopPlaying) return;
        this.gamesPlayed++;
        this.closeBar();
        this.root.gotoAndStop("big_end");
        this.root.bigEnd.gotoAndPlay(1);
        await waitForEvent(this.root, "animationEnd");
        this.correctAnswer = 0;
        this.cleanPrizes();
    };
}
