import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { ChickencGame } from "./ChickenGame";
import { additionalSounds } from "./ChickenSounds";

const ChickenComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new ChickencGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/chicken"
            animationName="chicken"
            scale={0.8}
            shiftX={100}
            shiftY={100}
            onLoaded={game.current.loaded}
            additionalSounds={additionalSounds}
            changeFramerate={true}
        />
    );
};
export default ChickenComponent;
