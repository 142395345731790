import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { MC, playSound, playSoundSync, stopAllSounds, waitFor } from "../animate/utils";
import { loadSound } from "../animate/sound_utils";
import { MyWorksApi } from "../api/myWorksApi";
import { SoundToolTip } from "../utils/soundTooltip";
import { generatePath } from "react-router-dom";

const ARROW_DISABLED_ALPHA = 0.3;
const BTN_ALPHA = 0.5;
const EDIT_BTNS = ["delete_btn", "edit_btn", "view_btn"];
export class myWork {
    private root: MC;
    private api: MyWorksApi = null;
    private myWorks: any[];
    private totalWorks: number;
    private CurrentFrame: number = 0;
    private selectedThumb: number;
    private bgSound: createjs.AbstractSoundInstance;

    constructor(private setCurrentWorks: (arr: any[]) => void) {}
    loaded = async (mc: MC) => {
        this.root = mc;
        (window as any).myWork = this.root;
        this.addExitListener();
        this.addSongEnter();
        this.root.userName_txt.text = "";
        this.hideAllThumbs();
        await this.initApi();
        await this.setUserName();
        this.initEditBtns();
        this.initArrows();
        this.initThumbs();
        this.bgSound = playSoundSync("bgSound", { loop: -1 });
        this.bgSound.volume = 0.4;
    };

    initApi = async () => {
        if (this.api) return;
        this.api = new MyWorksApi();
        this.myWorks = await this.api.init();
        this.totalWorks = this.api.getTotalWorks();
        this.updateCurrentWorks();
    };

    addSongEnter = async () => {
        let soundPath;
        soundPath = `/creation_corner/sounds/PersonalGeneral.mp3`;
        await loadSound(soundPath);
        await playSound(soundPath);
    };

    addExitListener = async () => {
        await loadSound(`/creation_corner/sounds/back.mp3`);
        SoundToolTip.addToolTip(this.root.back_btn, `/creation_corner/sounds/back.mp3`);
        this.root.back_btn.cursor = "pointer";
        this.root.back_btn.addEventListener("click", () => {
            Navigation.openPage(routes.home);
            stopAllSounds();
        });
    };
    initThumbs = () => {
        [1, 2, 3].forEach((i) => {
            const thumb = this.root[`thumb${i}_mc`];
            thumb.cursor = "pointer";
            thumb.addEventListener("click", () => {
                if (this.selectedThumb) {
                    if (this.selectedThumb === i) {
                        this.gotoCreationCorner();
                        return;
                    }
                    this.root[`thumb_highlight${this.selectedThumb}_mc`].gotoAndPlay("normal");
                }
                this.root[`thumb_highlight${i}_mc`].gotoAndPlay("down");
                this.selectEditBtns();
                this.selectedThumb = i;
            });
        });
    };
    initArrows = async () => {
        await waitFor(() => this.totalWorks >= 0);
        this.root.arrow_left_btn.addEventListener("click", this.displayNext);
        this.root.arrow_right_btn.addEventListener("click", this.displayPrev);
        this.toggleRightArrow(false);
        if (this.totalWorks > 3) {
            this.toggleLeftArrow(true);
        } else {
            this.toggleLeftArrow(false);
        }
    };
    initEditBtns = () => {
        this.blurEditBtns();
        SoundToolTip.addToolTip(this.root.edit_btn, "edit");
        SoundToolTip.addToolTip(this.root.view_btn, "view");
        SoundToolTip.addToolTip(this.root.delete_btn, "delete");
        this.root.edit_btn.addEventListener("click", this.gotoCreationCorner);
        this.root.view_btn.addEventListener("click", this.previewCreation);
        this.root.delete_btn.addEventListener("click", this.deleteCreation);
    };

    blurEditBtns = () => {
        EDIT_BTNS.forEach((btn) => {
            this.root[btn].alpha = BTN_ALPHA;
            this.root[btn].cursor = "default";
            this.root[btn].mouseEnabled = false;
        });
    };
    selectEditBtns = () => {
        EDIT_BTNS.forEach((btn) => {
            this.root[btn].alpha = 1;
            this.root[btn].cursor = "pointer";
            this.root[btn].mouseEnabled = true;
        });
    };

    toggleRightArrow = (active: boolean) => {
        const rightArrow = this.root.arrow_right_btn;
        if (active) {
            rightArrow.alpha = 1;
            rightArrow.cursor = "pointer";
            rightArrow.mouseEnabled = true;
        } else {
            rightArrow.alpha = ARROW_DISABLED_ALPHA;
            rightArrow.cursor = "default";
            rightArrow.mouseEnabled = false;
        }
    };

    toggleLeftArrow = (active: boolean) => {
        const leftArrow = this.root.arrow_left_btn;
        if (active) {
            leftArrow.alpha = 1;
            leftArrow.cursor = "pointer";
            leftArrow.mouseEnabled = true;
        } else {
            leftArrow.alpha = ARROW_DISABLED_ALPHA;
            leftArrow.cursor = "default";
            leftArrow.mouseEnabled = false;
        }
    };

    setUserName = async () => {
        this.root.userName_txt.text = await this.api.getUserName();
    };

    displayNext = async () => {
        if ((this.CurrentFrame + 1) * 3 >= this.totalWorks) return;
        this.unselectThumb();
        this.CurrentFrame += 1;
        this.toggleRightArrow(true);
        this.myWorks = await this.api.getWorksByFrame(this.CurrentFrame * 3 + 1);
        this.totalWorks = this.api.getTotalWorks();
        this.updateCurrentWorks();
        this.checkLeftArrow();
    };

    displayPrev = async () => {
        if (this.CurrentFrame <= 0) return;
        this.unselectThumb();
        this.CurrentFrame -= 1;
        this.myWorks = await this.api.getWorksByFrame(this.CurrentFrame * 3 + 1);
        this.totalWorks = this.api.getTotalWorks();
        this.updateCurrentWorks();
        this.checkLeftArrow();
        this.checkRightArrow();
    };

    unselectThumb = () => {
        if (this.selectedThumb) {
            this.root[`thumb_highlight${this.selectedThumb}_mc`].gotoAndPlay("normal");
        }
        this.blurEditBtns();
        this.selectedThumb = null;
    };

    checkLeftArrow = () => {
        if ((this.CurrentFrame + 1) * 3 >= this.totalWorks) {
            this.toggleLeftArrow(false);
        } else {
            this.toggleLeftArrow(true);
        }
    };

    checkRightArrow = () => {
        if (this.CurrentFrame === 0) {
            this.toggleRightArrow(false);
        } else {
            this.toggleRightArrow(true);
        }
    };

    updateCurrentWorks = () => {
        switch (this.myWorks.length) {
            case 0:
                this.hideThumb(3);
                this.hideThumb(2);
                this.hideThumb(1);
                break;
            case 1:
                this.hideThumb(3);
                this.hideThumb(2);
                this.showThumb(1);
                break;
            case 2:
                this.hideThumb(3);
                this.showThumb(2);
                this.showThumb(1);
                break;
            default:
                this.showThumb(1);
                this.showThumb(2);
                this.showThumb(3);
                break;
        }

        this.setCurrentWorks(this.myWorks);
    };

    hideThumb = (i: number) => {
        this.root[`thumb${i}_mc`].visible = false;
        this.root[`thumb${i}_mc`].mouseEnabled = false;
    };
    showThumb = (i: number) => {
        this.root[`thumb${i}_mc`].visible = true;
        this.root[`thumb${i}_mc`].mouseEnabled = true;
    };
    gotoCreationCorner = () => {
        this.muteSound();
        Navigation.openPage(generatePath(routes.my_works.create, { workId: this.myWorks[this.selectedThumb - 1] }));
        this.unselectThumb();
    };

    previewCreation = () => {
        this.muteSound();
        Navigation.openPage(generatePath(routes.my_works.preview, { workId: this.myWorks[this.selectedThumb - 1] }));
        this.unselectThumb();
    };

    deleteCreation = () => {
        this.muteSound();
        Navigation.openPage(generatePath(routes.my_works.remove, { workId: this.myWorks[this.selectedThumb - 1] }));
        this.unselectThumb();
    };

    refreshWorks = async () => {
        this.disableThumbs();
        this.myWorks = await this.api.getWorksByFrame(this.CurrentFrame * 3 + 1);
        this.totalWorks = this.api.getTotalWorks();
        this.checkLeftArrow();
        this.checkRightArrow();
        this.updateCurrentWorks();
        this.enableThumbs();
    };

    muteSound = () => {
        this.bgSound.stop();
    };
    resumeSound = () => {
        if (!this.bgSound) return;
        this.bgSound.play();
    };

    private hideAllThumbs() {
        this.hideThumb(1);
        this.hideThumb(2);
        this.hideThumb(3);
    }

    private enableThumbs() {
        [1, 2, 3].forEach((i) => {
            this.root[`thumb${i}_mc`].mouseEnabled = true;
        });
    }

    private disableThumbs() {
        [1, 2, 3].forEach((i) => {
            this.root[`thumb${i}_mc`].mouseEnabled = false;
        });
    }
}
