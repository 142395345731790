import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { SunGame } from "./SunGame";

import { additionalSounds } from "./SunSounds";
const SunComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new SunGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/sun"
            animationName="sun"
            onLoaded={game.current.loaded}
            additionalSounds={additionalSounds}
        />
    );
};
export default SunComponent;
