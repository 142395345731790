export const additionalSounds = [
    {
        src: "sounds/title.mp3",
        id: "title",
    },
    {
        src: "sounds/Bible_Open_Menu.mp3",
        id: "bgMenu",
    },
    {
        src: "sounds/Bible_Game_Menu.mp3",
        id: "bgGameMenu",
    },
    {
        src: "sounds/puzzels.mp3",
        id: "puzzels",
    },
    {
        src: "sounds/game.mp3",
        id: "game",
    },
    {
        src: "sounds/Coloring.mp3",
        id: "coloring",
    },
];
