import {
    getLetter,
    getLetterIndex,
    MC,
    playSound,
    playSoundSync,
    waitForEvent,
    waitForTimer,
} from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { ReviewGameBasic } from "../ReviewGameBasic";

export class Kite extends ReviewGameBasic {
    root: MC;
    playing: boolean = false;
    lastKite: number = 0;
    kitesNum = 0;
    lettersArr: any = [];
    lastLetter: string;
    btnSound: any;
    onHover: boolean = false;
    kitesArr: MC[] = [];
    prizeArr: MC[] = [];
    lastXpos: number = -1;
    minLetterIndex: number;
    maxLetterIndex: number;
    correctKite: MC;
    shouldHaveCorrect: boolean = false;
    letterToAsk: number = -1;
    correctAnswers: number = 0;
    btnPressed: boolean = false;
    prevType: number;
    backgroundSound: any;
    splashHeight: number = 274.5;
    lettersLeft: number = 5;
    lettersIndex = [
        [1, 4, 1, 4],
        [5, 8, 1, 4],
        [9, 12, 1, 8],
        [13, 17, 1, 12],
        [18, 22, 1, 17],
    ];
    alreadyAsked: boolean = false;
    soundPlayed: any;
    posToAsk: number = 500;
    quizModeNumberOfQuestion: number;
    mode: string = "";
    addPrizes: any;
    quizModeQuestionIndex: number = 0;
    setHelpSound: any;

    constructor(
        root: createjs.MovieClip,
        private sectionIndex: number,
        private lettersContext: any,
        numberOfQuestion: number,
        mode: string,
        addPrizes?: any,
        setHelpSound?: any,
        private enabledButtonFunc?: any
    ) {
        super();
        this.root = root;
        this.mode = mode;
        this.quizModeNumberOfQuestion = numberOfQuestion;
        this.setHelpSound = setHelpSound;
        this.addPrizes = addPrizes;
        if (mode === "quiz") {
            this.enabledButtonFunc(true);
            this.setHelpSound("instructions");
        }
        this.loaded();
        // (window as any).kite = root;
    }

    createKite = () => {
        this.lastKite = createjs.Ticker.getTime(true);
        let kiteType;
        do {
            kiteType = getRandomNumber(1, 5);
        } while (kiteType === this.prevType);
        this.prevType = kiteType;
        const kite: MC = new this.root.lib[`kite${kiteType}`]();
        this.initKite(kite);
        this.kitesArr.push(kite);
        this.root.addChild(kite);
        this.root.setChildIndex(kite, this.kitesArr.indexOf(kite) + 10);
        this.kitesNum++;
    };

    showPrize = () => {
        let prize = new this.root.lib.PrizeKite();
        prize.x = 650 - 100 * (this.correctAnswers - 1);
        prize.y = 560;
        prize.scale = 0.85;
        prize.visible = true;
        prize.name = `!prize${this.correctAnswers}`;
        this.prizeArr.push(prize);
        this.root.addChild(prize);
    };

    positionKite = () => {
        let posX;
        do {
            posX = getRandomNumber(162, 567);
        } while (Math.abs(posX - this.lastXpos) < 65);
        this.lastXpos = posX;
        return posX;
    };

    initKite = (kite: MC) => {
        kite.gotoAndStop(0);
        kite.visible = true;
        kite.x = this.positionKite();
        kite.y = 700;
        kite.cursor = "pointer";
        this.initKiteLetterText(kite);
        kite.mouseChildren = false;
        kite.addEventListener("click", async () => {
            this.quizModeQuestionIndex++;
            if (kite === this.correctKite && this.alreadyAsked) {
                kite.letters_txt.visible = false;
                kite.removeAllEventListeners();
                playSound("pop");
                this.correctAnswers++;
                if (this.correctAnswers > 6) {
                    console.error("error: enabled more than 6 kites");
                }
                if (this.mode === "quiz") {
                    this.addPrizes("kites", this.quizModeQuestionIndex, "correct");
                }
                this.showPrize();
                kite.gotoAndStop(1);
                await waitForEvent(kite, "animationEnd");
                this.removeKite(kite);
                let index = this.lettersArr.indexOf(this.letterToAsk + 1);
                this.lettersArr.splice(index, 1);
                this.lettersLeft--;
                if (this.correctAnswers === 6) {
                    this.endGame();
                }
            } else {
                playSound("wrong");
                if (this.mode === "quiz") {
                    this.addPrizes("kites", this.quizModeQuestionIndex, "wrong");
                }
            }
        });
        this.animateKite(kite);
    };

    initKiteLetterText = (kite: MC) => {
        if (this.kitesNum === 3 || this.shouldHaveCorrect) {
            kite.letters_txt.text = this.randomCorrectLetter();
            this.correctKite = kite;
            this.letterToAsk = getLetterIndex(this.correctKite.letters_txt.text);
            this.shouldHaveCorrect = false;
        } else {
            kite.letters_txt.text = this.randomLetter();
        }
    };

    animateKite = (currentObj: MC) => {
        if (currentObj != null) {
            createjs.Tween.get(currentObj)
                .to(
                    {
                        y: 82,
                    },
                    8000
                )
                .call(() => {
                    currentObj.visible = false;
                    currentObj.removeAllEventListeners();
                    this.removeKite(currentObj);
                });
        }
    };

    randomCorrectLetter = () => {
        let letterIndex;
        do {
            letterIndex = getRandomNumber(0, this.lettersLeft);
        } while (this.letterExistsOnScreen(getLetter(letterIndex)));
        this.letterToAsk = this.lettersArr[letterIndex] - 1;
        return getLetter(this.letterToAsk);
    };

    letterExistsOnScreen = (letter: string) => {
        this.kitesArr.forEach((element) => {
            if (element.letters_txt.text === letter) {
                return true;
            }
        });
        return false;
    };

    randomLetter = () => {
        let letter;
        do {
            const letterIndex = getRandomNumber(0, 26);
            letter = getLetter(letterIndex);
        } while (this.letterExistsOnScreen(letter) === true);
        return letter;
    };

    askLetter = () => {
        if (this.letterToAsk !== -1) {
            let path = `letter${this.letterToAsk + 1}`;
            playSound(path);
        }
        // this.alreadyAsked = true;
    };

    endGame = async () => {
        this.disableButtons(["reset", "help"]);
        this.playing = false;
        this.removeAllKites();
        this.root.instFrame.gotoAndStop(0);
        this.backgroundSound.volume = 0;
        this.root.instTotalPrize.visible = true;
        this.root.instTotalPrize.paused = false;
        this.root.instTotalPrize.gotoAndPlay(0);
        this.root.instTotalPrize.fbKite.gotoAndStop(19);
        const winSound = playSoundSync("win");
        await waitForTimer(3000);
        winSound.stop();
        this.root.instTotalPrize.fbKite.visible = false;
        this.setAlertBox(this.root.AlertBox, this.playAgain, this.sectionIndex);
        this.lettersContext.setGameStatus(this.sectionIndex, 1);
    };
    quizModeEndOfGame = async () => {
        this.playing = false;
        this.removeAllKites();
        this.backgroundSound.volume = 0;
    };

    removeKite = (kite: MC) => {
        const index = this.kitesArr.indexOf(kite);
        this.kitesArr.splice(index, 1);
        this.root.removeChild(kite);
        createjs.Tween.removeTweens(kite);
        this.kitesArr.forEach((element, i) => {
            this.root.setChildIndex(element, 10 + i);
        });
        if (kite === this.correctKite) {
            this.correctKite = null;
            this.shouldHaveCorrect = true;
            this.alreadyAsked = false;
            this.kitesArr.forEach((element) => {
                if (element.y > 120 && this.correctKite === null) {
                    const index = getLetterIndex(element.letters_txt.text);
                    if (this.lettersArr.includes(index + 1)) {
                        this.correctKite = element;
                        this.alreadyAsked = false;
                        this.letterToAsk = index;
                        this.shouldHaveCorrect = false;
                    }
                }
            });
        }
    };

    initLetterArr = (minIndex: number, maxIndex: number, pMinIndex: number, pMaxIndex: number) => {
        let letterIndex;
        for (let index = 0; index < 2; index++) {
            do {
                letterIndex = getRandomNumber(pMinIndex, pMaxIndex);
            } while (this.lettersArr.includes(letterIndex));
            this.lettersArr.push(letterIndex);
        }
        let letterNotToShow = -1;
        if (this.sectionIndex === 4 || this.sectionIndex === 5) {
            letterNotToShow = getRandomNumber(minIndex, maxIndex);
        }
        for (let index = minIndex; index <= maxIndex; index++) {
            if (index !== letterNotToShow) {
                this.lettersArr.push(index);
            }
        }
    };

    initIndexes = () => {
        const minIndex = this.lettersIndex[this.sectionIndex - 1][0];
        const maxIndex = this.lettersIndex[this.sectionIndex - 1][1];
        const pMinIndex = this.lettersIndex[this.sectionIndex - 1][2];
        const pMaxIndex = this.lettersIndex[this.sectionIndex - 1][3];
        this.initLetterArr(minIndex, maxIndex, pMinIndex, pMaxIndex);
    };

    removeAllKites = () => {
        this.kitesArr.forEach((kite) => {
            this.root.removeChild(kite);
            createjs.Tween.removeTweens(kite);
        });
        this.kitesArr = [];
    };

    removeAllPrizes = () => {
        this.prizeArr.forEach((element) => {
            this.root.removeChild(element);
        });
        this.prizeArr = [];
    };

    resetProps = () => {
        this.removeAllKites();
        this.removeAllPrizes();
        this.kitesNum = 0;
        this.lastKite = createjs.Ticker.getTime(true);
        this.shouldHaveCorrect = false;
        this.correctAnswers = 0;
        this.correctKite = null;
        this.alreadyAsked = false;
        this.letterToAsk = 0;
        this.lettersLeft = 5;
        this.lettersArr = [];
        this.initIndexes();
    };

    startPlay = () => {
        if (this.mode !== "quiz") {
            this.enableButtons(["reset", "help", "exit"]);
        }
        this.playing = true;
    };

    reset = async () => {
        this.playing = false;
        this.root.instFrame.gotoAndStop(0);
        this.resetProps();
        this.root.instFrame.gotoAndPlay(0);
        this.startPlay();
    };

    playAgain = async () => {
        this.root.instTotalPrize.visible = false;
        this.root.instTotalPrize.fbKite.visible = true;
        this.root.instTotalPrize.gotoAndStop(0);
        this.root.instTotalPrize.fbKite.gotoAndStop(0);

        this.root.AlertBox.visible = false;
        await this.reset();
        this.backgroundSound = playSoundSync("background", { loop: -1 });
    };

    loaded = async () => {
        this.root.AlertBox.visible = false;
        this.root.instButtons.visible = false;
        const duration = this.mode === "quiz" ? 1200 : 2000;
        this.root.addEventListener("tick", async (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (this.playing && createjs.Ticker.getTime(true) - this.lastKite > duration) {
                this.createKite();
            }
            if (this.correctKite) {
                if (this.correctKite.y <= this.posToAsk && !this.btnPressed && !this.alreadyAsked) {
                    await this.askLetter();
                    this.alreadyAsked = true;
                }
            }
        });

        this.root.instTotalPrize.gotoAndStop(0);
        this.root.instTotalPrize.fbKite.gotoAndStop(0);
        if (this.mode !== "quiz") {
            this.root.LoadBlock.gotoAndPlay(1);
            this.initButtons(this.root, this.reset, "instructions", this.sectionIndex, this.backgroundSound);
            this.disableButtons(["reset", "help"]);
            await waitForEvent(this.root.LoadBlock, "animationEnd");
            this.root.splash.visible = true;
            this.root.splash.stop();
            this.soundPlayed = true;
            await playSound("kiteGameName");
            await playSound("instructions");
            this.soundPlayed = false;

            this.root.splash.visible = false;
            this.root.splash.paused = true;
        } else {
            this.root.LoadBlock.visible = false;
            this.root.instFrame.visible = false;
            this.root.splash.visible = false;
        }
        this.backgroundSound = playSoundSync("background", { loop: -1 });
        this.initIndexes();
        this.startPlay();
    };
}
