import { MC, playSoundSync, setupStage, waitForEvent } from "../../../animate/utils";

export class NumberSevenGame {
    private root: MC;
    private step = 1;

    constructor(private frame: any) {}

    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.gotoAndStop(0);
        let audio = playSoundSync("RUSSIANDANCE", -1);
        audio.volume = 0.3;
        for (let i = 1; i <= 7; i++) {
            this.root["doll_" + i].addEventListener("click", async () => {
                if (!this.root["doll_" + i].onMove) {
                    this.root["doll_" + i].onMove = true;
                    this.root.gotoAndPlay(`step_${this.step}`);
                    await waitForEvent(this.root, "stepEnd");
                    this.root.gotoAndStop(`step_${this.step}_end`);
                    this.step += 1;

                    //reset
                    if (this.step === 13) {
                        this.step = 1;
                        this.root.gotoAndStop(0);
                    }
                    this.root["doll_" + i].onMove = false;
                }
            });
        }
    };
}
