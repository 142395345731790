import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { HeartGame } from "./HeartGame";
const HeartComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new HeartGame(frame));

    return <AnimateCC base="/lunapark/abc/heart" animationName="heart" onLoaded={game.current.loaded} />;
};
export default HeartComponent;
