import { MC, stopAllSounds, waitForEvent, waitForTimer } from "../animate/utils";
import { routes } from "../navigation/routesPath";
import { Navigation } from "../navigation/navigation";
import { generatePath } from "react-router-dom";
import { SoundToolTip } from "../utils/soundTooltip";

function stopAndHide(mc: createjs.MovieClip) {
    mc.stop();
    mc.visible = false;
}

function showAndPlay(mc: createjs.MovieClip) {
    mc.visible = true;
    mc.gotoAndPlay(0);
}

export class EnglishTimeMenu {
    root: MC;
    buttonsArr = ["Face", "Nouns", "Colors", "Verbs", "Numbers"];

    constructor(private isLoggedIn: boolean) {}

    loaded = async (root: MC) => {
        this.root = root;
        this.root.back_btn.gotoAndStop(0);
        this.init(true);
        this.addEventsToButtons();
    };

    reset = () => {
        this.init(false);
    };

    init = async (firstTime: boolean) => {
        this.root.gotoAndStop(0);
        this.setObjectsVisibility();
        await waitForTimer(0);

        this.root.gotoAndPlay(0);
        await waitForEvent(this.root, "animationEnd");
        this.setObjectsVisibility();

        this.setIsLoggedIn(this.isLoggedIn);

        stopAndHide(this.root.silence);

        if (firstTime) {
            //only on first time entering english time
            //say "welcome to english time"
            showAndPlay(this.root.welcome);
            await waitForEvent(this.root, "welcomeEnd");
            stopAndHide(this.root.welcome);
        }

        const randomTalkNumber = Math.floor(Math.random() * 5) + 1;
        showAndPlay(this.root[`talk_${randomTalkNumber}`]);
        await waitForEvent(this.root, "talkEnd");
        stopAndHide(this.root[`talk_${randomTalkNumber}`]);

        showAndPlay(this.root.silence);
    };

    setObjectsVisibility = () => {
        this.root.sample_mc.visible = false;
        this.root.back_btn.gotoAndStop(0);
        stopAndHide(this.root.welcome);
        for (let i = 1; i <= 5; i++) {
            stopAndHide(this.root[`talk_${i}`]);
        }
        for (let i = 0; i < 5; i++) {
            const btn = this.root[`${this.buttonsArr[i]}_btn`];
            btn.gotoAndStop(0);
        }
        stopAllSounds();
    };

    addEventsToButtons = () => {
        for (let i = 0; i < 5; i++) {
            const currentBtn = this.root[`${this.buttonsArr[i]}_btn`];
            currentBtn.cursor = "pointer";
            currentBtn.addEventListener("click", () => {
                currentBtn.gotoAndStop("down");
                stopAllSounds();
                this.goToGame(currentBtn);
            });
            currentBtn.addEventListener("rollover", () => {
                currentBtn.gotoAndStop("over");
            });
            currentBtn.addEventListener("rollout", () => {
                currentBtn.gotoAndStop("normal");
            });
        }

        const back_btn = this.root.back_btn;
        back_btn.cursor = "pointer";
        back_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
        back_btn.addEventListener("rollover", () => {
            back_btn.gotoAndStop("over");
        });
        back_btn.addEventListener("rollout", () => {
            back_btn.gotoAndStop("up");
        });
        SoundToolTip.addToolTip(back_btn, "/english/sounds/common/back.mp3");
    };

    goToGame = (btn: MC) => {
        this.setObjectsVisibility();
        let gameType: string;
        switch (btn.name) {
            case "Colors_btn":
                gameType = "colors";
                break;
            case "Numbers_btn":
                gameType = "numbers";
                break;
            case "Verbs_btn":
                gameType = "verbs";
                break;
            case "Nouns_btn":
                gameType = "nouns";
                break;
            case "Face_btn":
                gameType = "face";
                break;
        }
        Navigation.openPage(generatePath(routes.english_time.game, { gameType }));
    };

    setIsLoggedIn = (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        if (this.root) {
            this.root.sample_mc.visible = !this.isLoggedIn;
        }
    };
}
