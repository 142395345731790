import { CreationGame } from "../CreationGame";
import {
    RIGHT_BOARD_LIMIT,
    LEFT_BOARD_LIMIT,
    TOP_BOARD_LIMIT,
    BOTTOM_BOARD_LIMIT,
    DANGLE_Y,
    TEXT_INIT_SCALE,
    TEXT_SCALES,
} from "./constants";

export const generateStaticInput = (
    x: number,
    y: number,
    text: string,
    scale: number,
    creationGame: CreationGame,
    type: number,
    color: string = "#2E5F64",
    shouldDrag: boolean = true,
    applyGrid?: boolean
) => {
    let inputfield = new createjs.Text(text, "bold 32px NarkisTamMFO", color);
    initStaticInput(inputfield, x, y, scale, creationGame, applyGrid);
    creationGame.addItemToBoard(inputfield, type, TEXT_SCALES.indexOf(scale));
    shouldDrag && creationGame.setNewItemToDrag(inputfield, inputfield.x, inputfield.y);
    return inputfield;
};

const addMouseDownListener = (text: createjs.Text, creationGame: CreationGame) => {
    text.addEventListener("mousedown", () => {
        if (creationGame.getCurrentItemToDrag() === text) {
            const isItem = checkX(creationGame);
            if (isItem) checkY(creationGame);
            creationGame.setNewItemToDrag(null);
        } else {
            creationGame.chooseAction(text, "text");
        }
        // const g = new createjs.Shape();
        // g.graphics.f("blue").drawRect(text.x, text.y, 10, 10);
        // creationGame.addItemToBoard(g, 0);
    });
};
export const checkX = (creationGame: CreationGame, item?: createjs.Text) => {
    let currentItem;
    if (!item) {
        currentItem = creationGame.getCurrentItemToDrag();
    } else {
        currentItem = item;
    }
    if (currentItem.x > RIGHT_BOARD_LIMIT) {
        if (currentItem.x - getSentenceWidth(currentItem, creationGame) > RIGHT_BOARD_LIMIT) {
            creationGame.removeItemFromBoard(currentItem);
            return 0;
        } else {
            currentItem.x = RIGHT_BOARD_LIMIT;
        }
    } else if (currentItem.x - getSentenceWidth(currentItem, creationGame) < LEFT_BOARD_LIMIT) {
        if (currentItem.x < LEFT_BOARD_LIMIT) {
            creationGame.removeItemFromBoard(currentItem);
            return 0;
        } else {
            currentItem.x = LEFT_BOARD_LIMIT + getSentenceWidth(currentItem, creationGame);
        }
    }

    return 1;
};

export const checkY = (creationGame: CreationGame, item?: createjs.Text) => {
    let currentItem;
    if (!item) {
        currentItem = creationGame.getCurrentItemToDrag();
    } else {
        currentItem = item;
    }
    const bottomY = getBottomY(currentItem);
    if (bottomY - getSentenceHeight(currentItem, creationGame) < TOP_BOARD_LIMIT) {
        if (bottomY < TOP_BOARD_LIMIT) {
            creationGame.removeItemFromBoard(currentItem);
        } else {
            currentItem.y = TOP_BOARD_LIMIT + getSentenceHeight(currentItem, creationGame) - DANGLE_Y;
        }
    } else if (bottomY > BOTTOM_BOARD_LIMIT) {
        if (bottomY - getSentenceHeight(currentItem, creationGame) > 480) {
            creationGame.removeItemFromBoard(currentItem);
        } else {
            currentItem.y = BOTTOM_BOARD_LIMIT - DANGLE_Y;
        }
    }
    currentItem.y = creationGame.applyGridY(currentItem.y);

    // const g = new createjs.Shape();
    // g.graphics
    //     .s("red")
    //     .drawRect(
    //         currentItem.x - getSentenceWidth(currentItem, creationGame),
    //         bottomY - getSentenceHeight(currentItem, creationGame),
    //         getSentenceWidth(currentItem, creationGame),
    //         getSentenceHeight(currentItem, creationGame)
    //     );
    // creationGame.addItemToBoard(g);

    // const g2 = new createjs.Shape();
    // const hit = currentItem.hitArea as createjs.Shape;
    // console.log("hit", hit.graphics.command);
    // const p = localToGlobal(currentItem, (hit.graphics.command as any).x, (hit.graphics.command as any).y);
    // g2.graphics.s("green").drawRect(p.x, p.y, (hit.graphics.command as any).w, (hit.graphics.command as any).h);
    // creationGame.addItemToBoard(g2);
};
export const getSentenceWidth = (item: createjs.Text, creationGame: CreationGame) => {
    const scale = creationGame.getTextScale(item);
    return item.getMeasuredWidth() * scale;
};
const getSentenceHeight = (item: createjs.Text, creationGame: CreationGame) => {
    const scale = creationGame.getTextScale(item);
    return (item.getMeasuredHeight() - DANGLE_Y) * scale;
};

const getBottomY = (item: any) => {
    return item.y + DANGLE_Y * item.scale;
};
const initText = (text: createjs.Text) => {
    text.textBaseline = "alphabetic";

    const w = text.getMeasuredWidth();
    const h = text.getMeasuredHeight();
    text.regX += w;
    const hit = new createjs.Shape();
    if (w < 13) {
        hit.graphics.beginFill("#000").drawRect(-14, -h + DANGLE_Y * TEXT_INIT_SCALE, 27, h);
    } else {
        hit.graphics.beginFill("#000").drawRect(0, -h + DANGLE_Y * TEXT_INIT_SCALE, w, h);
    }
    text.hitArea = hit;

    // text.cursor = "pointer";
};

export const initStaticInput = (
    inputfield: any,
    x: number,
    y: number,
    scale: number,
    creationGame: CreationGame,
    applyGrid?: boolean,
    isKB: boolean = false
) => {
    inputfield.x = x;
    inputfield.y = y;
    inputfield.scaleX = scale;
    inputfield.scaleY = inputfield.scaleX;
    initText(inputfield);
    addMouseDownListener(inputfield, creationGame);
    creationGame.initCursorIndex();

    applyGrid && (inputfield.y = creationGame.applyGridY(inputfield.y));
};

export const checkPosition = (creationGame: CreationGame, item: createjs.Text) => {
    checkX(creationGame, item);
    checkY(creationGame, item);
};
