import { getRandomNumber } from "../../utils/MathUtils";

export const BOUND_X = 19;
export const BOUND_Y = 16;
export const WIDTH = 770;
export const HEIGHT = 543;

export const getBongoSound = () => {
    const num = getRandomNumber(0, 2);
    switch (num) {
        case 0:
            return "bongo1wav";
        case 1:
            return "bongo2wav";
        case 2:
            return "bongo3wav";
    }
};

export const getBubbleSound = () => {
    const num = getRandomNumber(0, 2);
    switch (num) {
        case 0:
            return "bubble1";
        case 1:
            return "bubble2";
        case 2:
            return "bubble3";
    }
};
