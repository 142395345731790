import { MC, stopAllSounds, waitForTimer } from "../animate/utils";
import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { loadSound, soundPlayed } from "../animate/sound_utils";
import { SoundToolTip } from "../utils/soundTooltip";
import { getRandomArr } from "../utils/MathUtils";
import { isMac, isWin } from "../utils/BrowserUtils";
import { isHoliday } from "../utils/Heb-Utils";

const currentDateParam = new URLSearchParams(window.location.search).get("currentDate");

const currentDate = currentDateParam ? new Date(currentDateParam) : new Date();
export class ColoringPagesMenu {
    constructor(
        private setImages: (value: number[]) => void,
        private setScreenReady: (value: boolean) => void,
        private setHolidayPagesLength: (value: any) => number
    ) {}

    root: MC;
    pageIndex: number = 0;
    prevButtonStatus: boolean = false;
    nextButtonStatus: boolean = false;
    helpBtnClicked: boolean = false;
    canPrint: boolean = false;
    images: MC[] = [];
    chosenImages: number[] = [];
    randomImagesIndexes: number[] = [];
    arrowSound: Promise<createjs.AbstractSoundInstance> = loadSound("/coloring_pages/sounds/coloring_bord/arrow.mp3");
    helpSound: Promise<createjs.AbstractSoundInstance> = loadSound("/coloring_pages/sounds/coloring_bord/inst.mp3");
    holiday: string;
    holidayPagesNum: number;

    loaded = async (root: MC) => {
        this.root = root;
        this.setScreenReady(true);
        this.holiday = isHoliday(currentDate);
        this.holiday !== "none" && (this.holidayPagesNum = this.setHolidayPagesLength(this.holiday));
        this.setRandomIndexes();
        this.generateImages();
        this.loadedImages();
        this.addEventstoBackButton();
        this.addEventsToHelpButton();
        this.setArrowsActivity();
        this.addEventsToArrowsButtons(this.root.btnPrev);
        this.addEventsToArrowsButtons(this.root.btnNext);
        this.listenToPrint();
        this.prinImages();
        this.setPrintBtnStatus();
        this.root.addEventListener("click", (e: createjs.MouseEvent) => {
            const currentImage = e.target;
            const imagePrintName = currentImage.imagePrintName;
            if (!imagePrintName) return;
            if (!this.chosenImages.includes(imagePrintName)) {
                currentImage.choosen.gotoAndStop("yes");
                this.chosenImages = [...this.chosenImages, imagePrintName];
            } else {
                currentImage.choosen.gotoAndStop(0);
                this.chosenImages = this.chosenImages.filter((i) => i !== imagePrintName);
            }
            this.canPrint = this.chosenImages.length > 0;
            this.setImages(this.randomImagesIndexes.filter((i) => this.chosenImages.includes(i)));
            this.setPrintBtnStatus();
        });
    };
    setRandomIndexes = () => {
        if (this.holiday !== "none") {
            for (let i = 1; i <= this.holidayPagesNum; i++) {
                this.randomImagesIndexes.push(i);
            }
            const arr = getRandomArr(this.holidayPagesNum + 1, 48 + this.holidayPagesNum, 48);
            arr.forEach((num: number) => {
                this.randomImagesIndexes.push(num);
            });
        } else {
            this.randomImagesIndexes = getRandomArr(1, 48, 48);
        }
    };
    generateImages = () => {
        let begin_index = 1;
        let end_index = 49;
        let icon_index = 1;
        if (this.holiday !== "none") {
            for (let i = 1; i <= this.holidayPagesNum; i++) {
                const image = new this.root.lib[`icon_print_${this.holiday}_${i}`]();
                this.images.push(image);
            }
            begin_index = this.holidayPagesNum + 1;
            end_index += this.holidayPagesNum;
        }
        for (let i = begin_index; i < end_index; i++, icon_index++) {
            const image = new this.root.lib[`icon_print_${icon_index}`]();
            this.images.push(image);
        }
    };

    addEventstoBackButton = () => {
        const back = this.root.back_btn;
        back.cursor = "pointer";
        back.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
        back.addEventListener("rollover", () => {
            back.gotoAndStop("over");
        });
        back.addEventListener("rollout", () => {
            back.gotoAndStop("up");
        });
        SoundToolTip.addToolTip(back, "/coloring_pages/sounds/coloring_bord/back.mp3");
    };

    addEventsToHelpButton = () => {
        const help = this.root.helpBtn;
        help.cursor = "pointer";
        help.addEventListener("click", async () => {
            if (!this.helpBtnClicked) {
                this.helpBtnClicked = true;
                help.gotoAndStop("disabled");
                help.cursor = null;
                await soundPlayed((await this.helpSound).play());
                help.gotoAndStop("up");
                help.cursor = "pointer";
                this.helpBtnClicked = false;
            }
        });
        help.addEventListener("rollover", () => {
            if (!this.helpBtnClicked) {
                help.gotoAndStop("over");
            }
        });
        help.addEventListener("rollout", () => {
            if (!this.helpBtnClicked) {
                help.gotoAndStop("up");
            }
        });
    };
    setArrowsActivity = () => {
        const prev = this.root.btnPrev;
        const next = this.root.btnNext;
        next.name = "next";
        prev.name = "prev";

        if (this.pageIndex === 0) {
            prev.gotoAndStop("disabled");
            this.prevButtonStatus = false;
            prev.cursor = null;
        } else {
            prev.gotoAndStop("up");
            prev.cursor = "pointer";
            this.prevButtonStatus = true;
        }
        if (this.pageIndex === 5) {
            next.gotoAndStop("disabled");
            next.cursor = null;
            this.nextButtonStatus = false;
        } else {
            next.gotoAndStop("up");
            next.cursor = "pointer";
            this.nextButtonStatus = true;
        }
    };
    addEventsToArrowsButtons = (currentArrow: MC) => {
        currentArrow.addEventListener("rollover", async () => {
            if (
                (currentArrow.name === "prev" && this.prevButtonStatus) ||
                (currentArrow.name === "next" && this.nextButtonStatus)
            ) {
                soundPlayed((await this.arrowSound).play());
                currentArrow.gotoAndStop("active");
            }
        });
        currentArrow.addEventListener("rollout", () => {
            if (
                (currentArrow.name === "prev" && this.prevButtonStatus) ||
                (currentArrow.name === "next" && this.nextButtonStatus)
            ) {
                currentArrow.gotoAndStop("up");
            }
        });
        currentArrow.addEventListener("click", async () => {
            if (
                (currentArrow.name === "prev" && this.prevButtonStatus) ||
                (currentArrow.name === "next" && this.nextButtonStatus)
            ) {
                currentArrow.gotoAndStop("active");
                await waitForTimer(100);
                if (currentArrow.name === "prev") {
                    this.pageIndex--;
                } else {
                    this.pageIndex++;
                }
                this.loadedImages();
                this.setArrowsActivity();
            }
        });
    };

    loadedImages = async () => {
        for (let i = 0; i < 8; i++) {
            this.root[`stamps_container_${i}`].visible = false;
        }
        this.setChosenImages();
        for (let i = 0; i < 8; i++) {
            const currentStampContainer = this.root[`stamps_container_${this.pageIndex}`];
            currentStampContainer.visible = true;
            const currentImage = this.root[`stamps_container_${this.pageIndex}`][`stamp_${i}`];
            currentStampContainer.gotoAndStop(i);
            const image: MC = this.images[this.randomImagesIndexes[this.pageIndex * 8 + i] - 1];

            const imagePrintName = this.randomImagesIndexes[this.pageIndex * 8 + i];
            if (image) {
                image.x = 0;
                image.y = 0;
                currentImage.Image.addChild(image);
                currentImage.cursor = "pointer";
                currentImage.mouseChildren = false;
                currentImage.imagePrintName = imagePrintName;
            }
            await waitForTimer(100);
        }
    };
    setPrintBtnStatus = () => {
        const printBtn = this.root.btnPrint;
        if (!this.canPrint) {
            printBtn.gotoAndStop("disabled");
            printBtn.cursor = null;
            printBtn.mouseEnabled = false;
        } else {
            printBtn.cursor = "pointer";
            printBtn.mouseEnabled = true;
            printBtn.gotoAndStop("up");
        }
    };
    prinImages = () => {
        const printBtn = this.root.btnPrint;
        printBtn.addEventListener("click", async () => {
            if (this.canPrint) {
                printBtn.gotoAndStop("down");
                await waitForTimer(50);
                window.print();
                printBtn.gotoAndStop("up");
            }
        });
    };

    listenToPrint = () => {
        window.addEventListener("keydown", (e) => {
            if (e.code === "KeyP" && ((isWin() && e.ctrlKey) || (isMac() && e.metaKey))) {
                e.preventDefault();
            }
        });
    };
    setChosenImages = () => {
        for (let i = 0; i < 8; i++) {
            this.root[`stamps_container_${this.pageIndex}`][`stamp_${i}`].choosen.gotoAndStop("no");
        }
        this.chosenImages = [];
        this.canPrint = this.chosenImages.length > 0;
        this.setPrintBtnStatus();
        this.setImages([]);
    };
}
