import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { LetterRaceGame } from "./LetterRaceGame";

const LetterRaceGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new LetterRaceGame());
    return (
        <>
            <AnimateCC
                base="/week_games/letter_race"
                animationName="letter_race"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                }}
                shiftY={30}
                shiftX={30}
                scale={0.88}
            />
        </>
    );
};
export default LetterRaceGameComponent;
