import { MC, playSound, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

export class UpsidedownGame {
    private root: MC;
    private elements: MC[] = null; //giraffe, house,tree, boat
    private moveElement: boolean[] = [false, false, false, false];
    private rotation: number[] = [0, 0, 0, 0];

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).upside = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.elements = [this.root.gir, this.root.hou, this.root.tre, this.root.boa];
        this.addListeners();
        this.start();
    };
    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;
            for (let i = 0; i < 4; i++) {
                if (this.moveElement[i]) {
                    this.animateElement(i);
                }
            }
        });
    };

    addListeners = () => {
        for (let i = 0; i < 4; i++) {
            const element = this.elements[i];
            element.cursor = "pointer";
            element.addEventListener("click", () => {
                this.playRandomSound();
                this.moveElement[i] = true;
            });
        }
    };
    animateElement = (i: number) => {
        const element = this.elements[i];
        element.rotation += 10;
        this.rotation[i] += 1;
        if (this.rotation[i] === 36) {
            this.moveElement[i] = false;
            this.rotation[i] = 0;
        }
    };
    playRandomSound = () => {
        switch (getRandomNumber(0, 2)) {
            case 0:
                playSound("swanny1wav");
                break;
            case 1:
                playSound("swany2wav");
                break;
            case 2:
                playSound("swany3wav");
                break;
        }
    };
}
