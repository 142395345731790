import { loadSound } from "../animate/sound_utils";
import { MC, playSoundSync, stopAllSounds, waitForEvent } from "../animate/utils";
import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { getRandomNumber } from "../utils/MathUtils";
import { SoundToolTip } from "../utils/soundTooltip";
import { MovieContextType } from "../context/MovieContext";
import { Madorim } from "../context/UserContext";
import { generatePath } from "react-router-dom";
import { isHoliday } from "../utils/Heb-Utils";
import { getWeek } from "../utils/DateUtils";
import { getCampaignPage } from "../api/campaign";

const WEEK_GAMES_ROUTES = [
    routes.week_game.dog,
    routes.week_game.differences,
    routes.week_game.hipomaze,
    routes.week_game.seekers,
    routes.week_game.frog,
    routes.week_game.memory,
    routes.week_game.simon,
    routes.week_game.spacerocks,
];

export class MainscreenMenu {
    private root: MC;
    private randomSounds: string[] = [];
    private nRandomSoundsInterval: any;
    private nRandomSoundsDelay: number = 10000;
    private bRandomSoundStart: boolean = false;
    private currRandomSound: createjs.AbstractSoundInstance;
    private aMagics: MC[] = [];
    private aMagicsSounds: string[] = [];
    private bActiveSound: boolean = true;
    private bgSound: createjs.AbstractSoundInstance = null;
    private magic_mc: MC;
    private holidayBtn: MC;
    private allRoutes: MC[];
    private enableCampaign: boolean;
    private licenseCategories = ["english", "math", "abc", "bible", "music"];
    private openBtn: string = "";
    public isLoaded: boolean = false;
    constructor(
        private isLoggedIn: boolean,
        private logout: () => void,
        private permissions: Madorim,
        private movieContext: MovieContextType
    ) {}

    loaded = (mc: MC, currentDate: Date, visible: boolean) => {
        this.root = mc;
        this.allRoutes = [
            this.root.myworks_btn,
            this.root.creation_btn,
            this.root.bible_btn,
            this.root.dubi_btn,
            this.root.music_btn,
            this.root.paint_btn,
            this.root.lunapark_btn,
            this.root.abc_btn,
            this.root.math_btn,
            this.root.movie_btn,
            this.root.bible_btn,
            this.root.english_btn,
        ];
        this.hideHolidays();
        this.initWeekGameBtn(currentDate);
        this.root.crayon_btn.visible = false; //hide holiday crayon btn
        this.isHolidayDate(currentDate);
        (window as any).main = this.root;
        //this.root.campaign_outer_btn.visible = false; //hide campaign btn
        this.root.close_btn.visible = false; //hide close btn
        this.root.gotoAndStop(0);
        this.selectRandomChannel();
        this.initEnterBtn(this.isLoggedIn);
        this.initCampaign();
        // this.updateCampaignBtn(this.isLoggedIn);
        this.addListeners();
        this.initLocks();
        this.defineObjects();
        this.initSounds();
        if (visible) {
            this.bgSound = playSoundSync("bgSound", { loop: -1 });
            this.bgSound.volume = 0.4;
            this.startRandomSounds();
        }
        this.hideSmallPopup();
        this.root.soonPopup.mouseEnabled = false;
        this.isLoaded = true;
    };

    initLocks = () => {
        this.licenseCategories.forEach((item) => {
            // if ((this.permissions as any)[item]) return;
            if (item === "english" && this.permissions.english_time) return;
            if (item === "math" && this.permissions.math) return;
            if (item === "abc" && this.permissions.letters) return;
            if (item === "music" && this.permissions.music) return;
            if (item === "bible" && this.permissions.bible_stories) return;

            const lock = this.root[item + "_lock"];
            lock.gotoAndStop(1);
            lock.cursor = "pointer";
            lock.addEventListener("click", () => {
                if (this.openBtn === item) {
                    lock.lock_btn.gotoAndStop(0);
                    this.openBtn = "";
                } else {
                    this.openBtn && this.root[this.openBtn + "_lock"].lock_btn.gotoAndStop(0);
                    lock.lock_btn.gotoAndStop(1);
                    this.openBtn = item;
                }
            });

            lock.lock_btn.info.addEventListener("click", (e: MouseEvent) => {
                e.stopPropagation();
            });

            lock.lock_btn.info.infoBtn.addEventListener("click", (e: MouseEvent) => {
                e.stopPropagation();
                lock.lock_btn.gotoAndStop(0);
                window.location.href = "mailto:sherut@cet.ac.il";
            });
        });
    };

    updateCampaignBtn = (enable: boolean) => {
        this.enableCampaign = enable;
        this.root && (this.root.campaign_outer_btn.visible = enable);
    };

    initCampaign = () => {
        this.root.campaign_outer_btn.addEventListener("click", () => {
            if (!this.enableCampaign) return;
            getCampaignPage().then(() => {
                Navigation.openIframe("/parentsInfo/campaigns.aspx");
            });
        });
    };
    initWeekGameBtn = (date: Date) => {
        const current_date = new Date(date);
        const week_num = getWeek(current_date);
        const game_num = week_num % 8;
        this.root.week_btn.all_game_pictures.gotoAndStop(`game_${game_num}`);
        this.root.week_btn.addEventListener("click", async () => {
            this.disableAllButtons();
            await this.doMagic(7);
            await waitForEvent(this.root, "bgEnd");
            Navigation.openPage(WEEK_GAMES_ROUTES[game_num]);
            this.enableAllButtons();
        });
    };

    hideHolidays = () => {
        ["hanuka_btn", "purim_btn", "pesach_btn", "sukkot_btn"].forEach((btn: string) => {
            this.root[btn].visible = false;
        });
    };

    isHolidayDate = (date: Date) => {
        let btn: MC;
        let route: any;
        switch (isHoliday(date)) {
            case "pesah":
                btn = this.root.pesach_btn;
                route = routes.holiday.pesah;
                break;
            case "sukkot":
                btn = this.root.sukkot_btn;
                route = routes.holiday.sukkot;
                break;
            case "hanuka":
                btn = this.root.hanuka_btn;
                route = routes.holiday.hanuka;
                break;
            case "purim":
                btn = this.root.purim_btn;
                route = routes.holiday.purim;
                break;
            case "none":
                btn = null;
                route = null;
                break;
        }

        if (btn && route) {
            btn.visible = true;
            this.holidayBtn = btn;
            btn.holidayGame_btn.addEventListener("click", async () => {
                this.disableAllButtons();
                await this.doMagic(14);
                await waitForEvent(this.root, "bgEnd");
                Navigation.openPage(route);
                this.enableAllButtons();
            });
            this.root.crayon_btn.visible = true;
            this.root.crayon_btn.cursor = "pointer";
            this.root.crayon_btn.framerate = 20;

            this.root.crayon_btn.addEventListener("click", async () => {
                this.disableAllButtons();
                this.root.crayon_btn.gotoAndStop("down");
                await this.doMagic(3);
                await waitForEvent(this.root, "bgEnd");
                Navigation.openPage(generatePath(routes.coloring_pages.menu));
                this.enableAllButtons();
            });
        }
    };

    initEnterBtn = (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        if (isLoggedIn) {
            this.root?.enter_btn.gotoAndStop(1);
        } else {
            this.root?.enter_btn.gotoAndStop(0);
        }
    };

    selectRandomChannel = () => {
        let num: number;
        if (this.movieContext.selectedChannel === null) {
            num = getRandomNumber(0, 5);
            this.movieContext.setSelectedChannel(num);
        } else {
            num = this.movieContext.selectedChannel;
        }

        this.root.movie_btn.tv.screen.gotoAndStop(num);
        this.root.movie_btn.tv_glow.guy.screen.gotoAndStop(num);
        this.root.movie_btn.tv_glow.guy_1.screen.gotoAndStop(num);

        this.root.movie_btn.tv.square.gotoAndStop(num);
        this.root.movie_btn.tv_glow.guy.square.gotoAndStop(num);
        this.root.movie_btn.tv_glow.guy_1.square.gotoAndStop(num);
    };

    initSounds = () => {
        for (let x = 1; x <= 8; x++) {
            this.randomSounds[x - 1] = "random_invite" + x;
        }
        SoundToolTip.addToolTip(this.root.myworks_btn, "my_creations");
        SoundToolTip.addToolTip(this.root.creation_btn, "creations");
        SoundToolTip.addToolTip(this.root.lunapark_btn, "lunapark");
        SoundToolTip.addToolTip(this.root.movie_btn, "video");
        SoundToolTip.addToolTip(this.root.paint_btn, "paint");
        SoundToolTip.addToolTip(this.root.week_btn, "weekGame");
        SoundToolTip.onToolTipStart = this.stopRandomSounds;
        SoundToolTip.onToolTipEnd = this.startRandomSounds;
        this.initLockSounds();
    };

    updatePermissions = (permissions: Madorim) => {
        this.permissions = permissions;
        if (this.isLoaded) {
            this.initLockSounds();
            this.initLocks();
        }
    };

    initLockSounds = () => {
        this.permissions.english_time && SoundToolTip.addToolTip(this.root.english_btn, "english");
        this.permissions.dubby_english && SoundToolTip.addToolTip(this.root.dubi_btn, "dubi");
        this.permissions.math && SoundToolTip.addToolTip(this.root.math_btn, "math");
        this.permissions.bible_stories && SoundToolTip.addToolTip(this.root.bible_btn, "bible");
        this.permissions.music && SoundToolTip.addToolTip(this.root.music_btn, "music");
        this.permissions.letters && SoundToolTip.addToolTip(this.root.abc_btn, "letters");
    };

    hasPermission = (mc: MC) => {
        switch (mc) {
            case this.root.dubi_btn:
                return this.permissions.dubby_english;
            case this.root.english_btn:
                return this.permissions.english_time;
            case this.root.math_btn:
                return this.permissions.math;
            case this.root.abc_btn:
                return this.permissions.letters;
            case this.root.music_btn:
                return this.permissions.music;
            case this.root.bible_btn:
                return this.permissions.bible_stories;
            default:
                return true;
        }
    };

    addRoutesListeners = () => {
        const magics = [5, 5, 6, 9, 2, 3, 2, 1, 3, 8, 6, 9];
        const pages = [
            routes.my_works.list,
            generatePath(routes.creation, { viewMode: undefined, workId: undefined }),
            routes.bible_stories.menu,
            routes.dubby_english.menu,
            routes.music.menu,
            generatePath(routes.coloring_pages.menu),
            routes.lunapark.menu,
            routes.letters.menu,
            routes.math.menu,
            routes.movies,
            routes.bible_stories.menu,
            routes.english_time.menu,
        ];
        this.allRoutes.forEach((mc, index) => {
            mc.addEventListener("rollout", () => {
                mc.gotoAndStop(0);
            });
            mc.addEventListener("rollover", () => {
                if (this.hasPermission(mc)) mc.gotoAndStop(1);
                else mc.gotoAndStop(0);
            });

            mc.addEventListener("mousedown", () => {
                if (!this.hasPermission(mc)) {
                    mc.gotoAndStop(0);
                }
            });
            mc.addEventListener("pressup", () => {
                if (!this.hasPermission(mc)) {
                    mc.gotoAndStop(0);
                }
            });

            mc.addEventListener("click", async () => {
                if (!this.hasPermission(mc)) {
                    return;
                }
                if (mc === this.root.dubi_btn && !this.isLoggedIn) {
                    this.gotoLogin();
                    return;
                }
                this.disableAllButtons();
                await this.doMagic(magics[index]);
                await waitForEvent(this.root, "bgEnd");
                Navigation.openPage(pages[index]);
                this.enableAllButtons();
            });
        });
    };

    addIframeListeners = () => {
        const pathes = ["/parentsInfo/index.aspx", "/reports/UserReports.aspx?sFrom=Menu", "/parentsInfo/about.aspx"];
        [this.root.info_btn, this.root.report_btn, this.root.about_btn].forEach((btn, index) => {
            btn.addEventListener("click", async () => {
                Navigation.openIframe(pathes[index]);
            });
        });
    };

    addListeners = () => {
        this.addRoutesListeners();
        this.addIframeListeners();
        this.enterBtnListener();
        this.registrationBtnListener();
        this.guestsBtnListener();
        this.muteBtnListener();
    };

    public startRandomSounds = () => {
        clearInterval(this.nRandomSoundsInterval);
        this.nRandomSoundsInterval = setTimeout(this.playRandomSounds, this.nRandomSoundsDelay);
    };

    playRandomSounds = () => {
        let sSound;
        if (SoundToolTip.bActive) {
            return;
        }
        if (!this.bRandomSoundStart) {
            sSound = this.randomSounds[0];
            this.bRandomSoundStart = true;
        } else {
            sSound = this.randomSounds[getRandomNumber(0, this.randomSounds.length - 1)];
        }
        this.currRandomSound = playSoundSync(sSound);
        this.currRandomSound.on("complete", () => {
            this.startRandomSounds();
        });
    };

    public stopRandomSounds = () => {
        this.currRandomSound && this.currRandomSound.stop();
        clearInterval(this.nRandomSoundsInterval);
    };

    private defineObjects = () => {
        this.aMagics[1] = this.root.magic1_mc; // ABC
        this.aMagics[2] = this.root.magic2_mc; // lunapark
        this.aMagics[3] = this.root.magic3_mc; // math
        this.aMagics[3] = this.root.magic3_mc; // paint
        this.aMagics[4] = this.root.magic4_mc; // myWorks
        this.aMagics[5] = this.root.magic5_mc; // creation
        this.aMagics[6] = this.root.magic2_mc; // bible
        this.aMagics[7] = this.root.magic4_mc; // weekgame
        this.aMagics[8] = this.root.magicVideo_mc; // weekmovie
        this.aMagics[9] = this.root.magic1_mc; // english
        this.aMagics[10] = this.root.magic5_mc; // music
        this.aMagics[11] = this.root.magicFeatured_mc; // featured games
        this.aMagics[12] = this.root.magic2_mc; // summer
        this.aMagics[13] = this.root.magic3_mc; // extra
        this.aMagics[14] = this.root.magic3_mc; // holiday
        this.aMagics[15] = this.root.magic3_mc; // song
        //MARK

        this.aMagicsSounds[1] = "/mainscreen/sounds/magic1.mp3"; // ABC
        this.aMagicsSounds[2] = "/mainscreen/sounds/magic2.mp3"; // lunapark
        this.aMagicsSounds[3] = "/mainscreen/sounds/magic3.mp3"; // math
        this.aMagicsSounds[3] = "/mainscreen/sounds/magic3.mp3"; // paint
        this.aMagicsSounds[4] = "/mainscreen/sounds/magic2.mp3"; // myWorks
        this.aMagicsSounds[5] = "/mainscreen/sounds/magic1.mp3"; // creation
        this.aMagicsSounds[6] = "/mainscreen/sounds/magic3.mp3"; // bible
        this.aMagicsSounds[7] = "/mainscreen/sounds/magic1.mp3"; // weekgame
        this.aMagicsSounds[8] = "/mainscreen/sounds/magic2.mp3"; // weekmovie
        this.aMagicsSounds[9] = "/mainscreen/sounds/magic3.mp3"; // english
        this.aMagicsSounds[10] = "/mainscreen/sounds/magic2.mp3"; // music
        this.aMagicsSounds[11] = "/mainscreen/sounds/magic1.mp3"; // featured games
        this.aMagicsSounds[12] = "/mainscreen/sounds/magic3.mp3"; // summer
        //MARK
        this.aMagicsSounds[13] = "/mainscreen/sounds/magic1.mp3"; // extra
        this.aMagicsSounds[14] = "/mainscreen/sounds/magic1.mp3"; // holiday game
        this.aMagicsSounds[15] = "/mainscreen/sounds/magic1.mp3"; // song_btn
    };

    private doMagic = async (nMagic: number) => {
        await stopAllSounds();
        this.stopRandomSounds();
        SoundToolTip.onToolTipStart = () => {};
        SoundToolTip.onToolTipEnd = () => {};
        this.currRandomSound && this.currRandomSound.removeAllEventListeners();
        this.magic_mc = this.aMagics[nMagic];
        this.magic_mc.gotoAndPlay("start");
        await loadSound(this.aMagicsSounds[nMagic]).then((sound) => {
            sound.play();
        });
    };

    undoMagic = () => {
        this.startRandomSounds();
        SoundToolTip.onToolTipStart = this.stopRandomSounds;
        SoundToolTip.onToolTipEnd = this.startRandomSounds;
        if (this.bActiveSound) {
            if (!this.bgSound) {
                // from direct link
                this.bgSound = playSoundSync("bgSound", { loop: -1 });
                this.bgSound.volume = 0.4;
            }
            this.bgSound.play();
        }
        if (this.magic_mc) {
            this.magic_mc.gotoAndStop(0);
            this.magic_mc = null;
        }
    };
    gotoLogin = async () => {
        await stopAllSounds();
        this.stopRandomSounds();
        // Navigation.gotoLogin(routes.dubby_english.menu);
        Navigation.gotoLogin();
    };
    enterBtnListener = () => {
        this.root.enter_btn.addEventListener("click", async () => {
            if (!this.isLoggedIn) {
                this.gotoLogin();
            } else {
                this.logout();
            }
        });
    };

    disableAllButtons = () => {
        [...this.allRoutes, this.root.week_btn, this.holidayBtn, this.root.crayon_btn].forEach((btn) => {
            btn && (btn.mouseEnabled = false);
        });
    };

    enableAllButtons = () => {
        [...this.allRoutes, this.root.week_btn, this.holidayBtn, this.root.crayon_btn].forEach((btn) => {
            btn && (btn.mouseEnabled = true);
        });
    };

    registrationBtnListener = () => {
        this.root.registration_btn.addEventListener("click", async () => {
            Navigation.gotoRegistration();
        });
    };

    guestsBtnListener = () => {
        this.root.guests_btn.addEventListener("click", async () => {
            const top = window.outerHeight / 2 + window.screenY - 500 / 2;
            const left = window.outerWidth / 2 + window.screenX - 500 / 2;
            window.open(
                "/guests.aspx",
                "NewWin",
                "toolbar=no,status=no,width=460,height=360 top=" + top + " left=" + left
            );
        });
    };

    muteBtnListener = () => {
        this.root.mute_btn.addEventListener("click", () => {
            if (this.bActiveSound) {
                this.bgSound.stop();
                this.bActiveSound = false;
                this.root.mute_btn.gotoAndStop(1);
            } else {
                this.bgSound.play();
                this.bActiveSound = true;
                this.root.mute_btn.gotoAndStop(0);
            }
            SoundToolTip.stopCurrentToolTip();
        });
    };

    hideSmallPopup = () => {
        this.root.soonPopup.alpha = 0;
    };

    updateMuteStatus = () => {
        if (!this.bgSound) return;
        if (this.bActiveSound) {
            this.startRandomSounds();
            this.bgSound.play();
        } else {
            this.bgSound.stop();
        }
    };

    stopSounds = async () => {
        await stopAllSounds();
        this.stopRandomSounds();
    };
}
