export const bubbleSounds = [
    {
        src: "/lunapark/rocket/bubbleSounds/bubble_1.mp3",
        id: "bubble1",
    },
    {
        src: "/lunapark/rocket/bubbleSounds/bubble2_1.mp3",
        id: "bubble2",
    },
    {
        src: "/lunapark/rocket/bubbleSounds/bubble3_1.mp3",
        id: "bubble3",
    },
];
