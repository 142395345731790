import React, { useRef, useState, useEffect } from "react";
import AnimateCC from "../../../animate";
import menuSounds from "./ReviewMenuSounds";
import { useLettersContext } from "../../../context/LettersContext";
import { MC } from "../../../animate/utils";
import { Switch, useParams, useRouteMatch } from "react-router-dom";
import { SodRoute } from "../../../navigation/PrivateRoot";
import { routes } from "../../../navigation/routesPath";
import FishesGameComponent from "../Fishes/FishesGameComponent";
import PenguinGameComponent from "../Penguin/PenguinGameComponent";
import BoxesGameComponent from "../Boxes/BoxesGameComponent";
import KiteGameComponent from "../kites/KiteGameComponent";
import QuizComponent from "../quiz/QuizComponent";
import { PreloaderComponent } from "../../../loaders/PreloaderComponent";
import { ReviewMenu } from "./ReviewMenu";

export default function ReviewMenuComponent() {
    const { isExact: isReviewMenu } = useRouteMatch(routes.letters.review.menu);
    const [screenReady, setScreenReady] = useState(false);
    const { sectionIndex } = useParams<{ sectionIndex: string }>();
    const game = useRef(new ReviewMenu(parseInt(sectionIndex)));
    const { gameMenuData, getGamesStatus } = useLettersContext();

    useEffect(() => {
        getGamesStatus(sectionIndex);
    }, [getGamesStatus, sectionIndex]);

    useEffect(() => {
        if (!gameMenuData[sectionIndex]) return;
        game.current.updateGameData(gameMenuData[sectionIndex]);
    }, [gameMenuData, sectionIndex]);

    useEffect(() => {
        game.current.muteUnmuteBgSound(!isReviewMenu);
    }, [isReviewMenu]);

    return (
        <>
            {!screenReady && <PreloaderComponent name="meter" />}
            <AnimateCC
                visible={isReviewMenu}
                base="/letters_island/review_menu/"
                animationName="review_menu"
                additionalSounds={menuSounds}
                onLoaded={async (mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc);
                }}
                scale={0.85}
                shiftX={80}
                shiftY={40}
            />
            <Switch>
                <SodRoute path={routes.letters.review.penguins}>
                    <PenguinGameComponent key="penguin" />
                </SodRoute>
                <SodRoute path={routes.letters.review.wood_boxes}>
                    <BoxesGameComponent key="boxes" />
                </SodRoute>
                <SodRoute path={routes.letters.review.fishes}>
                    <FishesGameComponent key="fishes" />
                </SodRoute>
                <SodRoute path={routes.letters.review.kites}>
                    <KiteGameComponent key="kites" />
                </SodRoute>
                <SodRoute path={routes.letters.review.quiz}>
                    <QuizComponent key="quiz" />
                </SodRoute>
            </Switch>
        </>
    );
}
