import { contains, MC, waitForEvent, waitForTimer, playSound } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { getRandomArr } from "../../utils/MathUtils";
import textures from "./LetterTextures.json";

export class ColorLetter {
    root: MC;
    isFirst: boolean = true;
    letterIndex: number;
    textures: any[];
    overPalette: boolean = false;
    colors: number[];
    enableNext: (path: string) => void;
    enableHelp: () => void;
    currentBtn: string = null;

    constructor(
        root: createjs.MovieClip,
        letterIndex: number,
        enableNext: (path: string) => void,
        enableHelp: () => void
    ) {
        this.root = root;
        (window as any).colorLetter = root;
        this.letterIndex = letterIndex;
        this.enableNext = enableNext;
        this.enableHelp = enableHelp;
    }

    delete = () => {
        if (this.isFirst) {
            this.isFirst = false;
            return Promise.resolve(() => {});
        } else {
            this.root.erase.gotoAndStop(0);
            this.root.erase.gotoAndPlay(0);
            waitForEvent(this.root.erase, "deleted").then(() => {
                this.root.myLetter.visible = false;
            });
            return waitForEvent(this.root.erase, "animationEnd");
        }
    };

    activateAnimation = async (index: number) => {
        this.enableNext(routes.letters.activities.children_in_boxes);
        await this.delete();
        this.root.myLetter.visible = true;
        const currenLetter = this.root.myLetter[`letter${this.letterIndex + 1}`];

        currenLetter.visible = true;
        currenLetter.gotoAndStop(0);
        currenLetter.text1.gotoAndStop(index);
        for (let i = 1; i < 5; i++) {
            waitForEvent(currenLetter, `draw${i}`).then(() => {
                playSound(`effect${i}`);
            });
        }
        currenLetter.gotoAndPlay(0);
        waitForEvent(currenLetter, "animationEnd").then(() => {
            this.addButtonsListeners();
        });
    };

    setBtnState = (btn: MC, state: string) => {
        for (let i = 1; i <= 5; i++) {
            btn[`btn_${i}`].InnerBtn.gotoAndStop(state);
        }
    };

    removeButtonsListeners = () => {
        this.root.brush.visible = false;
        this.root.removeAllEventListeners();
        this.root.palette.mouseChildren = true;
        this.root.palette.cursor = "default";
        const colorBtns = [this.root.color_btn1, this.root.color_btn2, this.root.color_btn3, this.root.color_btn4];
        const textureBtns = [this.root.texture_btn1, this.root.texture_btn2];

        const init = (btn: MC) => {
            btn.cursor = "default";
            btn.mouseChildren = true;
            btn.removeAllEventListeners();
        };

        colorBtns.forEach((btn: MC) => {
            init(btn);
            if (btn.name !== this.currentBtn) {
                this.setBtnState(btn, "normal");
            } else {
                this.setBtnState(btn, "down");
            }
        });
        textureBtns.forEach((btn: MC, index: number) => {
            init(btn);
            if (btn.name !== this.currentBtn) {
                const currentTexture = this.textures[index] - 1;
                btn.InnerBtn.gotoAndStop("normal");
                btn.InnerBtn.texture.gotoAndStop(currentTexture);
            } else {
                btn.InnerBtn.gotoAndStop("down");
            }
        });
    };

    addButtonsListeners = () => {
        this.root.palette.mouseChildren = false;
        this.root.palette.cursor = "none";
        this.root.addEventListener("mouseover", this.startCursor);

        const parent: createjs.DisplayObject = this.root;
        const position = parent.globalToLocal(parent.stage.mouseX, parent.stage.mouseY);

        this.root.brush.x = position.x;
        this.root.brush.y = position.y;

        const textureBtns = [this.root.texture_btn1, this.root.texture_btn2];
        textureBtns.forEach((btn: MC, index: number) => {
            if (!this.currentBtn || this.currentBtn !== btn.name) {
                this.setTextureButton(btn, index);
            }
        });

        const coloBtns = [this.root.color_btn1, this.root.color_btn2, this.root.color_btn3, this.root.color_btn4];
        coloBtns.forEach((btn: MC, index: number) => {
            if (!this.currentBtn || this.currentBtn !== btn.name) {
                this.setColorBtn(btn, index);
            }
        });
    };

    initColorBtns = () => {
        this.colors = getRandomArr(1, 5, 4);
        for (let index = 1; index <= 4; index++) {
            const button = this.root[`color_btn${index}`];
            button.mouseChildren = false;
            button.cursor = "none";
            button.gotoAndStop(this.colors[index - 1]);
        }
    };
    setColorBtn = (button: MC, index: number) => {
        button.mouseChildren = false;
        button.cursor = "none";

        button.addEventListener("rollover", () => {
            this.setBtnState(button, "over");
        });

        button.addEventListener("rollout", () => {
            this.setBtnState(button, "normal");
        });

        button.addEventListener("click", () => {
            this.setBtnState(button, "down");
            this.currentBtn = button.name;
            this.removeButtonsListeners();
            this.activateAnimation(21 + this.colors[index]);
        });
    };

    setTextureButton = (button: MC, index: number) => {
        button.cursor = "none";
        button.mouseChildren = false;
        const currentTexture = this.textures[index] - 1;
        button.InnerBtn.texture.gotoAndStop(currentTexture);
        button.InnerBtn.gotoAndStop("normal");
        button.InnerBtn.texture_over.texture.gotoAndStop(currentTexture);
        button.addEventListener("rollover", () => {
            button.InnerBtn.gotoAndStop("over");
        });
        button.addEventListener("rollout", () => {
            button.InnerBtn.gotoAndStop("normal");
            button.InnerBtn.texture.gotoAndStop(currentTexture);
        });
        button.addEventListener("click", () => {
            button.cursor = "none";
            button.InnerBtn.gotoAndStop("down");
            this.currentBtn = button.name;
            this.removeButtonsListeners();
            button.InnerBtn.texture.gotoAndStop(currentTexture);
            this.activateAnimation(currentTexture);
        });
    };

    startCursor = (e: any) => {
        const palette = [
            this.root.color_btn1,
            this.root.color_btn2,
            this.root.color_btn3,
            this.root.color_btn4,
            this.root.texture_btn1,
            this.root.texture_btn2,
            this.root.palette,
        ];
        this.overPalette = palette.some((mc) => contains(mc, e.target));
        this.root.brush.visible = this.overPalette;
    };

    loaded = async () => {
        const btns = [
            this.root.color_btn1,
            this.root.color_btn2,
            this.root.color_btn3,
            this.root.color_btn4,
            this.root.texture_btn1,
            this.root.texture_btn2,
        ].reverse();

        btns.forEach((btn: MC) => {
            btn.visible = false;
        });

        this.root.myLetter.children.forEach((child: MC) => {
            child.visible = false;
        });

        this.root.myLetter[`letter${this.letterIndex + 1}`].gotoAndStop(0);
        this.textures = textures[this.letterIndex].sTextureNum.split(",");
        this.initColorBtns();
        this.addButtonsListeners();
        this.root.brush.mouseEnabled = false;
        this.root.palette.mouseChildren = false;
        this.root.palette.cursor = "none";
        this.root.addEventListener("mouseover", this.startCursor);

        this.root.palette.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (this.overPalette) {
                const parent: createjs.DisplayObject = this.root;
                const position = parent.globalToLocal(parent.stage.mouseX, parent.stage.mouseY);

                this.root.brush.x = position.x;
                this.root.brush.y = position.y;
            }
        });
        playSound("inst").then(() => {
            this.enableHelp();
        });

        const showBtn = (btn: MC) => {
            return waitForTimer(300).then(() => {
                btn.visible = true;
            });
        };
        await showBtn(this.root.texture_btn1);
        await showBtn(this.root.texture_btn2);
        await showBtn(this.root.color_btn4);
        await showBtn(this.root.color_btn3);
        await showBtn(this.root.color_btn2);
        await showBtn(this.root.color_btn1);
    };
}
