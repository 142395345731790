import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { KaliedascopeGame } from "./KaliedascopeGame";
const KaliedascopeComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new KaliedascopeGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/kaliedascope"
            animationName="kaliedascope"
            onLoaded={game.current.loaded}
            scale={0.8}
            shiftX={120}
            changeFramerate={true}
        />
    );
};
export default KaliedascopeComponent;
