import React, { useState } from "react";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import mathSounds from "../mathSounds.json";

export function MathGameComponent(props: any) {
    const {
        animationName,
        additionalSounds,
        onLoaded,
        onUnload = null,
        game,
        changeFramerate = false,
        shiftX = 84,
        shiftY = 73,
    } = props;
    const [frameLoaded, setFrameLoaded] = useState(false);
    return (
        <>
            {frameLoaded && (
                <AnimateCC
                    base="/math/"
                    animationName={animationName}
                    additionalSounds={additionalSounds}
                    onLoaded={onLoaded}
                    onUnload={onUnload}
                    shiftX={shiftX}
                    shiftY={shiftY}
                    frameWidth={800}
                    frameHeight={600}
                    changeFramerate={changeFramerate}
                />
            )}

            <AnimateCC
                base="/math/"
                animationName="MathLoader"
                additionalSounds={mathSounds}
                onLoaded={(mc: MC) => {
                    game.current.frameLoaded(mc);
                    setFrameLoaded(true);
                }}
                style={{
                    background: "none",
                }}
            />
        </>
    );
}
