import React, { useEffect, useState } from "react";
import { Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";
import AnimateCC from "../../animate";
import { SodRoute } from "../../navigation/PrivateRoot";
import { routes } from "../../navigation/routesPath";
import PuzzleFrameComponent from "../puzzles/PuzzleFrameComponent";
import BavelGameComponent from "../bavel_game/BavelGameComponent";
import TreeGameComponent from "../tree_game/TreeGameComponent";
import NoachGameComponent from "../noach_game/NoachGameComponent";
import BibleColoringComponent from "../Coloring/BibleColoringComponent";
import CreationGameComponent from "../creation_game/CreationGameComponent";
import { BibleFrame } from "./BibleFrame";
import { MeterStaticPreloaderComponent } from "../../loaders/meterStatic/MeterStaticPreloaderComponent";

const BibleFrameComponent: React.FC = () => {
    const [gameReady, setGameReady] = useState(false);
    const { type } = useParams<{ type: string }>();
    const { isExact: isStoryMenu } = useRouteMatch(routes.bible_stories.story_menu.menu);
    const isPuzzle = useRouteMatch(routes.bible_stories.story_menu.puzzle.frame);
    const isCreationGame = useRouteMatch(routes.bible_stories.story_menu.creation_game);
    const { pathname } = useLocation();
    const frame = React.useRef(new BibleFrame(type, isCreationGame && isCreationGame.isExact));
    useEffect(() => {
        setGameReady(false);
    }, [pathname]);

    useEffect(() => {
        frame.current.setIsCreationGame(isCreationGame && isCreationGame.isExact);
    }, [isCreationGame]);
    return (
        <>
            <Switch>
                <SodRoute path={routes.bible_stories.story_menu.puzzle.frame}>
                    <PuzzleFrameComponent type={type} />
                </SodRoute>
                <SodRoute path={routes.bible_stories.story_menu.bavel_game}>
                    <BavelGameComponent frame={frame.current} onLoaded={() => setGameReady(true)} />
                </SodRoute>
                <SodRoute path={routes.bible_stories.story_menu.tree_game}>
                    <TreeGameComponent frame={frame.current} onLoaded={() => setGameReady(true)} />
                </SodRoute>
                <SodRoute path={routes.bible_stories.story_menu.noach_game}>
                    <NoachGameComponent frame={frame.current} onLoaded={() => setGameReady(true)} />
                </SodRoute>
                <SodRoute path={routes.bible_stories.story_menu.creation_game}>
                    <CreationGameComponent type={type} frame={frame.current} onLoaded={() => setGameReady(true)} />
                </SodRoute>
            </Switch>
            <Switch>
                <SodRoute path={routes.bible_stories.story_menu.coloring}>
                    <BibleColoringComponent type={type} frame={frame.current} onLoaded={() => setGameReady(true)} />
                </SodRoute>
            </Switch>
            {!gameReady && !isPuzzle && <MeterStaticPreloaderComponent />}
            {!isStoryMenu && !isPuzzle && (
                <AnimateCC
                    base="/bible_stories/bible_frame"
                    animationName="bible_frame"
                    onLoaded={frame.current.loaded}
                />
            )}
        </>
    );
};
export default BibleFrameComponent;
