import React from "react";
import AnimateCC from "../../../animate";
import { RocketMenu } from "../RocketMenu";
import { InsectsGame } from "./InsectsGame";

const InsectsComponent: React.FC<{
    frame: RocketMenu;
}> = ({ frame }) => {
    const game = React.useRef(new InsectsGame(frame));

    return (
        <AnimateCC
            base="/lunapark/rocket/insects"
            animationName="insects"
            scale={0.75}
            shiftX={130}
            shiftY={5}
            onLoaded={game.current.loaded}
        />
    );
};
export default InsectsComponent;
