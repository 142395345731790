export const getBibleLockedChannels = async () => {
    let channels: string[] = [];

    try {
        const res = await fetch("/VideoSection/data/Channelconfig6.aspx", {
            method: "get",
        });
        const body = await res.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(body, "text/xml");
        const channelBlocked = xml.querySelector("LockedMovies").getAttribute("movies");
        channelBlocked.split(",").forEach((channel: string) => {
            channels.push(channel);
        });
        return channels;
    } catch {
        return channels;
    }
};
// /VideoSection/data/Channelconfig6.aspx
