import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { YellowGame } from "./YellowGame";
const YellowComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new YellowGame(frame));

    return <AnimateCC base="/lunapark/abc/yellow" animationName="yellow" onLoaded={game.current.loaded} />;
};
export default YellowComponent;
