import sentencesMap from "../mapping/sentences.json";
import { stampMap } from "../mapping/stampMap";
export const getSentenceById = (searchId: string) => {
    let result;
    sentencesMap.forEach((line) => {
        const sentence = line.sentences.find((sentence) => sentence.id === searchId);
        if (sentence) {
            result = sentence.sentence;
        }
    });

    if (!result) {
        console.error("sentence not found by id!", searchId);
    }
    return result;
};
export const getIdForSentence = (text: string) => {
    let result = "";
    sentencesMap.forEach((line) => {
        const sentence = line.sentences.find((sentence) => sentence.sentence === text);
        if (sentence) {
            result = sentence.id;
        }
    });
    if (!result) {
        console.error("sentence id not found!", text);
    }
    return result;
};
export const getStampByName = (searchName: string) => {
    return Object.keys(stampMap).find((key) => stampMap[key] === searchName);
};
