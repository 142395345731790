import React from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { DuckGame } from "./DuckGame";
import additionalSounds from "./DuckGameSounds";

const DuckComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new DuckGame(frame));

    return (
        <AnimateCC
            base="/lunapark/ducks"
            animationName="ducks"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
            onUnload={game.current.unload}
        />
    );
};
export default DuckComponent;
