import { loadSound } from "../../../animate/sound_utils";
import { loadFont, MC, playSound, stopAllSounds, waitFor, waitForEvent } from "../../../animate/utils";
import { SpecialGameBasic } from "../../SpecialGameBasic";
import { lettersArray } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

const MAX_HEIGHT = 9;
const CUBE_WIDTH = 127.925;
const CUBE_HEIGHT = 36;

interface Tile {
    name: string;
    x: number;
    y: number;
    empty: boolean;
    isRightSide: boolean;
    cubeNum: number;
}
export class SpacerocksGame extends SpecialGameBasic {
    root: MC;
    game: MC;
    cubeArr: MC[] = []; // array containing all cubes on board
    countCubes: number;
    onCube: MC; // pointer to current cube
    onTile: MC;
    newTile: MC;
    rows: number;
    gamePaused: boolean;
    intId: any; // interval for cube down movement
    dropInt: any;
    duration: number; // duration for the interval speed
    chkToAddSpeed: number = 0;
    letters: string[] = lettersArray;
    numbers: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
    isRightSide: boolean;
    formulaString: string;
    beginningMatX: number;
    beginningMatY: number;
    tileMatriza: Tile[][];
    stopArrowDown: boolean;
    inDrop: boolean = false;
    isCubeLocked: boolean = false;
    board: any;
    lettersCubeAmount: number = 0;
    numbersCubeAmount: number = 0;

    bgnSound = "/week_games/spacerocks_game/sounds/Buddaboo.mp3";
    helpSound = "/week_games/spacerocks_game/sounds/help.mp3";
    bGSound = "/week_games/spacerocks_game/sounds/bg.mp3";
    wrongSound = "/week_games/spacerocks_game/sounds/s_wrong.mp3";
    downSound = "/week_games/spacerocks_game/sounds/s_down.mp3";
    rowSound = "/week_games/spacerocks_game/sounds/s_row.mp3";
    trueSound = "/week_games/spacerocks_game/sounds/s_true.mp3";

    loaded = async (root: MC) => {
        this.root = root;
        (window as any).spacerocks = this.root;

        await loadSound(this.bgnSound);
        await loadSound(this.helpSound);
        loadFont(this.root, "NarkisTamMFO");
        loadFont(this.root, "ProntoMF");
        this.disableHelpInClicking = true;
        this.initExit();
        this.initHelp();
        this.initSpeker();
        this.disableSpeaker();
        this.openingAnimate();
        this.initIconMc();
        this.root.stop();
    };

    exit = () => {
        clearInterval(this.intId);
        super.exit();
    };

    openingAnimate = async () => {
        await playSound(this.bgnSound);
        playSound(this.helpSound);
        this.initHelp();
        this.enableHelp();
        this.root.game.btnGame.mouseChildren = false;
        this.game = this.root.game;
        this.beginningMatX = this.root.game.bottom.x - CUBE_WIDTH;
        this.beginningMatY = this.root.game.bottom.y - 326 - 47.4 / 2;
        this.root.game.btnGame.addEventListener("click", () => this.startGame());
    };

    startGame = () => {
        stopAllSounds();
        this.setBgSound();
        loadSound(this.downSound);
        loadSound(this.wrongSound);
        loadSound(this.trueSound);
        loadSound(this.rowSound);
        this.startNewGame();
    };

    /* updates score and rows */
    addRows = () => {
        this.rows++;
        this.game.points.rows.text = this.rows;
    };

    pauseGame = async () => {
        this.gamePaused = true;
        clearInterval(this.intId);
        this.gamePaused = true;
        if (this.game["currentFrame"] === 19) {
            await this.game.parent.door.gotoAndPlay("in");
        }
    };

    continueGame = async () => {
        this.gamePaused = false;
        if (this.game["currentFrame"] === 19) await this.game.parent.door.gotoAndPlay("out");
        this.intId = setInterval(this.moveCube, this.duration);
    };

    /* update tile in new location */
    updateTile = () => {
        this.onTile = this.tileMatriza[this.onCube.xPlace][this.onCube.yPlace];
        this.onCube.y += CUBE_HEIGHT;
    };

    removeCube = async (obj: Tile) => {
        this.cubeArr[obj.cubeNum - 1].gotoAndPlay("remove");
        obj.cubeNum = 0;
        obj.empty = true;
        obj.isRightSide = null;
    };

    /* converts newTile to oldTile and does something nully with oldTile.*/
    replaceTiles = (oldTile: Tile, newTile: Tile) => {
        newTile.empty = false;
        newTile.cubeNum = oldTile.cubeNum;
        newTile.isRightSide = oldTile.isRightSide;
        if (this.cubeArr[oldTile.cubeNum - 1]) this.cubeArr[oldTile.cubeNum - 1].y += CUBE_HEIGHT;
        oldTile.isRightSide = null;
        oldTile.empty = true;
    };

    /* move all tiles one line lower */
    dropDownRows = (num: number) => {
        this.inDrop = true;
        this.speakerOn && playSound(this.rowSound);
        for (let i = num - 1; i >= 0; i--) {
            let useTile = this.tileMatriza[1][i];
            let useTile1 = this.tileMatriza[2][i];
            let useTile2 = this.tileMatriza[3][i];
            if (useTile.empty && useTile1.empty && useTile2.empty) {
                break;
            }
            if (!useTile.empty) {
                let newTilePosition = this.tileMatriza[1][i + 1];
                this.replaceTiles(useTile, newTilePosition);
            }
            if (!useTile1.empty) {
                let newTilePosition = this.tileMatriza[2][i + 1];
                this.replaceTiles(useTile1, newTilePosition);
            }
            if (!useTile2.empty) {
                let newTilePosition = this.tileMatriza[3][i + 1];
                this.replaceTiles(useTile2, newTilePosition);
            }
        }
        this.inDrop = false;
    };

    /* check match between 2 cubes in same row */
    findCorrectRows = async (onCube: MC) => {
        await waitFor(() => !this.inDrop);
        const leftSide = this.tileMatriza[1][onCube.yPlace];
        const rightSide = this.tileMatriza[3][onCube.yPlace];

        if (
            !leftSide.empty &&
            !leftSide.isRightSide &&
            !rightSide.empty &&
            rightSide.isRightSide &&
            onCube.xPlace !== 2
        ) {
            this.isCubeLocked = true;
            this.removeCube(this.tileMatriza[1][onCube.yPlace]);
            this.tileMatriza[2][onCube.yPlace].cubeNum = 0;
            this.tileMatriza[2][onCube.yPlace].empty = true;
            this.tileMatriza[2][onCube.yPlace].isRightSide = null;
            this.removeCube(this.tileMatriza[3][onCube.yPlace]);
            waitForEvent(this.root, "cubeRemoved").then(() => {
                this.dropDownRows(onCube.yPlace);
                this.isCubeLocked = false;
            });
            this.addRows();
        }
    };

    placeInTile = (obj: Tile) => {
        obj.empty = false;
        obj.cubeNum = this.countCubes;
    };

    disableTile = (obj: Tile) => {
        obj.empty = false;
    };

    /* stops cube and checks for match */
    HaltCube = async (onCube: MC) => {
        this.stopArrowDown = true;
        this.placeInTile(this.tileMatriza[onCube.xPlace][onCube.yPlace]);
        this.disableTile(this.tileMatriza[2][onCube.yPlace]);
        switch (onCube.xPlace) {
            case 1:
                this.tileMatriza[1][onCube.yPlace].empty = false;
                this.tileMatriza[1][onCube.yPlace].isRightSide = onCube.isRightSide;
                break;
            case 2:
                this.disableTile(this.tileMatriza[1][onCube.yPlace]);
                this.disableTile(this.tileMatriza[3][onCube.yPlace]);
                break;
            case 3:
                this.tileMatriza[3][onCube.yPlace].empty = false;
                this.tileMatriza[3][onCube.yPlace].isRightSide = onCube.isRightSide;
                break;
        }
        if (
            (onCube.xPlace < 3 && onCube.isRightSide) ||
            (onCube.xPlace > 1 && !onCube.isRightSide) ||
            onCube.xPlace === 2
        ) {
            let wrongCube = onCube;
            let wrong = new this.root.lib.wrong();
            wrongCube.addChild(wrong);
            this.speakerOn && playSound(this.wrongSound);
        } else {
            this.speakerOn && playSound(this.trueSound);
        }
        this.stopArrowDown = false;
        this.findCorrectRows(onCube);
        if (onCube.yPlace > 2) {
            this.createNewCube();
        } else {
            this.root.game.removeChild(this.onCube);
            this.gameOver();
        }
    };

    moveCube = (): Boolean => {
        this.onCube.visible = true;
        if (this.isCubeLocked) return;
        this.speakerOn && playSound(this.downSound);
        let movedCube = this.onCube;
        var q = parseInt(movedCube.xPlace);
        var w = parseInt(movedCube.yPlace);
        if (!this.tileMatriza[1][1].empty || !this.tileMatriza[2][1].empty || !this.tileMatriza[3][1].empty) {
            this.gameOver();
            return false;
        } else {
            if (movedCube.yPlace < MAX_HEIGHT && this.tileMatriza[q][w + 1].empty) {
                movedCube.yPlace++;
                this.updateTile();
                return true;
            } else {
                this.HaltCube(movedCube);
                return false;
            }
        }
    };

    chkKey = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Escape":
                if (this.gamePaused) return;
                clearInterval(this.intId);
                this.startNewGame();
                break;
            case "ArrowLeft":
                if (this.gamePaused) break;
                if (this.onCube.xPlace > 1 && this.tileMatriza[1][this.onCube.yPlace].empty) {
                    this.onCube.xPlace = 1;
                    this.onCube.x = this.game.masking.x - CUBE_WIDTH + 2;
                    this.onTile = this.tileMatriza[this.onCube.xPlace][this.onCube.yPlace];
                }
                break;
            case "ArrowRight":
                if (this.gamePaused) break;
                if (this.onCube.xPlace < 3 && this.tileMatriza[3][this.onCube.yPlace].empty) {
                    this.onCube.xPlace = 3;
                    this.onCube.x = this.game.masking.x + 2;
                    this.onTile = this.tileMatriza[this.onCube.xPlace][this.onCube.yPlace];
                }
                break;
            case "ArrowDown":
                if (this.stopArrowDown) break;
                if (this.gamePaused) break;
                clearInterval(this.intId);
                while (this.moveCube()) {}
                break;
            case " ":
                if (!this.gamePaused) {
                    this.pauseGame();
                } else {
                    this.continueGame();
                }
                break;
            default:
                break;
        }
    };

    createLettersFormula = () => {
        let n: number;
        this.isRightSide = true;
        n = getRandomNumber(0, this.letters.length - 1);
        this.formulaString = this.letters[n];
    };

    createNumbersFormula = () => {
        let n: number;
        this.isRightSide = false;
        n = getRandomNumber(0, this.numbers.length - 1);
        this.formulaString = this.numbers[n];
    };

    createNewFormula = () => {
        // let n: number;
        if (this.numbersCubeAmount >= 4) {
            this.numbersCubeAmount = 0;
            this.createLettersFormula();
            this.lettersCubeAmount++;
        } else if (this.lettersCubeAmount >= 4) {
            this.lettersCubeAmount = 0;
            this.createNumbersFormula();
            this.numbersCubeAmount++;
        } else {
            if (getRandomNumber(1, 2) === 1) {
                // letters
                this.createLettersFormula();
                this.lettersCubeAmount++;
            } else {
                // numbres
                this.createNumbersFormula();
                this.numbersCubeAmount++;
            }
        }
    };

    /* create new cube and init its movement */
    createNewCube = async () => {
        if (this.intId) {
            clearInterval(this.intId);
        }
        this.createNewFormula();
        this.countCubes++;
        let cube = new this.root.lib.box();
        cube.x = this.beginningMatX + CUBE_WIDTH / 2;
        cube.y = this.beginningMatY - CUBE_HEIGHT;
        cube.xPlace = 2;
        cube.yPlace = 0;
        cube.id = this.countCubes;
        cube.name = `cube_${this.countCubes}`;
        cube.isRightSide = this.isRightSide;
        cube.isRightSide ? cube.gotoAndStop("type1") : cube.gotoAndStop("type2");
        cube.base.gotoAndStop(getRandomNumber(1, 6));
        cube.txt.text = this.formulaString;
        cube.visible = false;
        this.root.game.addChild(cube);
        this.onCube = cube;
        this.cubeArr.push(cube);
        this.intId = setInterval(this.moveCube, this.duration);
    };

    /* create board matrix */
    createMatriza = () => {
        let bgnMatX = this.beginningMatX;
        let bgnMatY = this.beginningMatY;
        this.tileMatriza = [];
        for (let i = 1; i <= 3; i++) {
            this.tileMatriza[i] = [];
            for (let j = 0; j <= MAX_HEIGHT; j++) {
                const newTile: Tile = {
                    name: `x_${i}y_${j}`,
                    x: bgnMatX,
                    y: bgnMatY,
                    empty: true,
                    isRightSide: null,
                    cubeNum: 0,
                };
                this.tileMatriza[i][j] = newTile;
                bgnMatY += CUBE_HEIGHT;
            }

            bgnMatX += CUBE_WIDTH / 2;
            bgnMatY = this.beginningMatY;
        }
        this.tileMatriza[1][0].empty = false;
        this.tileMatriza[3][0].empty = false;
        this.createNewCube();
    };

    /* starts a new game */
    startNewGame = () => {
        this.gamePaused = false;
        this.stopArrowDown = false;
        this.duration = 1500;
        this.game.gotoAndStop("start");
        this.game.bottom.gotoAndStop("numbers");
        window.addEventListener("keydown", this.chkKey);
        this.enableSpeaker();
        this.removeAllCubes();
        this.countCubes = 0;
        this.onTile = null;
        this.onCube = null;
        this.rows = 0;
        this.game.points.rows.text = this.rows;
        this.tileMatriza = [];
        this.createMatriza();
    };

    /* removes all cubes from board */
    removeAllCubes = () => {
        for (let i = 0; i <= this.cubeArr.length; i++) {
            this.game.removeChild(this.cubeArr[i]);
        }
        this.cubeArr = [];
    };

    gameOver = () => {
        clearInterval(this.intId);
        window.removeEventListener("keydown", this.chkKey);
        this.removeAllCubes();
        this.game.gotoAndStop("gameOver");
        this.game.gameOverScreen.playAgainBtn.cursor = "pointer";
        this.game.gameOverScreen.playAgainBtn.addEventListener("click", () => {
            this.startNewGame();
        });
    };
}
