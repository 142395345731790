/*
[315061] Sodmaya; בכניסת מטח מתוך משחק בלונים תצוגת לא תקינה
* Basically, all this context is for, is to load Lunapark game immidiently after loading page.
because we returning from external login page, we cannot load the game directly.
so HomeComponent or something similar, use setWantedGame, and the lunapak will read wantedGame
and load the game after everything is loaded.
*/

import React from "react";

const LunaparkContext = React.createContext<{
    wantedGame: string;
    setWantedGame: (val: string) => void;
}>({
    wantedGame: null,
    setWantedGame: null,
});

export const LunaparkContextProvider: React.FC = (props) => {
    const [wantedGame, setWantedGame] = React.useState<string>("");

    return <LunaparkContext.Provider value={{ wantedGame, setWantedGame }}>{props.children}</LunaparkContext.Provider>;
};

export const useLunaparkContext = () => React.useContext(LunaparkContext);
