import { MC, stopAllSounds, waitForEvent } from "../../animate/utils";
import { ZigiGameBase } from "./ZigiGameBase";
import { loadSound } from "../../animate/sound_utils";
import { getRandomNumber } from "../../utils/MathUtils";

export default class ZigiFaceGame extends ZigiGameBase {
    shouldPlay: boolean = true;
    enabledFramBtn: boolean;

    partialSoundSrc = [
        "/english/sounds/zigi_face_game_sounds/learn/",
        "/english/sounds/zigi_face_game_sounds/practice/",
    ];
    customArray = ["hair", "eyes", "nose", "lips", "ear1", "ear2"];
    btnsArray = ["hair", "eyes", "nose", "mouth", "ears"];
    currentExerciseBtn: number;
    testedQuetion: number = 0;
    helpSrc: Promise<createjs.AbstractSoundInstance>;

    loaded = (root: MC) => {
        super.loaded(root);
        this.root.banana_anim_mc.gotoAndStop(0);
        this.root.banana_anim_mc.banana_all_mc.gotoAndStop(0);
        this.clearCustoms();
    };

    startLearn = async () => {
        try {
            super.startLearn();
            this.iniBtns(false);
            const instSound = loadSound(`${this.partialSoundSrc[0]}inst.mp3`);
            this.helpSrc = instSound;
            this.helpSrc2 = null;
            await this.cancelableWait(super.playSound(instSound));
        } catch (ignore) {}
    };

    startExercise = async () => {
        try {
            super.startExercise();
            this.iniBtns(true);
            const instSound = loadSound(`${this.partialSoundSrc[1]}inst.mp3`);
            this.helpSrc = instSound;
            await this.cancelableWait(super.playSound(instSound));
            this.testQuestion();
        } catch (ignore) {}
    };
    testQuestion = async () => {
        if (this.testedQuetion === this.btnsArray.length) {
            await this.restartTest();
        } //start game from new

        this.testedQuetion++;
        let randomBtn = getRandomNumber(0, 4);
        let currentBtn = this.root[`${this.btnsArray[randomBtn]}_btn`];
        while (!currentBtn.visible) {
            randomBtn = getRandomNumber(0, 4);
            currentBtn = this.root[`${this.btnsArray[randomBtn]}_btn`];
        } //check if buttons is already tested

        this.currentExerciseBtn = randomBtn;
        const instSound = loadSound(`${this.partialSoundSrc[1]}${this.btnsArray[randomBtn]}.mp3`);
        await this.cancelableWait(super.playSound(instSound));
        this.helpSrc2 = instSound;
    };
    restartTest = async () => {
        const bananaAnim = this.root.banana_anim_mc;
        const bananaAllAnim = bananaAnim.banana_all_mc;

        bananaAnim.gotoAndPlay(0);
        bananaAllAnim.gotoAndPlay(0);
        await this.cancelableWait(waitForEvent(bananaAllAnim, "animationEnd"));
        await this.cancelableWait(waitForEvent(bananaAnim, "animationEnd"));
        console.log("ended");
        bananaAnim.gotoAndStop(0);
        bananaAllAnim.gotoAndStop(0);
        this.resetExercise();
        const instSound = loadSound(`${this.partialSoundSrc[1]}inst.mp3`);
        await super.playSound(instSound);
    };
    resetExercise = () => {
        this.clearCustoms();
        for (let i = 0; i < this.btnsArray.length; i++) {
            const currentBtn = this.root[`${this.btnsArray[i]}_btn`];
            currentBtn.visible = true;
        }
        this.testedQuetion = 0;
    };

    iniBtns = (isPractice: boolean) => {
        for (let i = 0; i < this.btnsArray.length; i++) {
            const currentBtn = this.root[`${this.btnsArray[i]}_btn`];
            currentBtn.cursor = "pointer";
            currentBtn.addEventListener("rollover", () => {
                currentBtn.gotoAndStop("over");
            });
            currentBtn.addEventListener("rollout", () => {
                currentBtn.gotoAndStop("up");
            });

            currentBtn.addEventListener("click", async () => {
                let additionalSoundParams = "";

                //exercise
                if (isPractice) {
                    if (this.currentExerciseBtn !== null && i === this.currentExerciseBtn) {
                        //currect answer
                        additionalSoundParams = "C";
                        currentBtn.visible = false;
                    } else {
                        additionalSoundParams = "W";
                        const instSound = loadSound(
                            `${this.partialSoundSrc[Number(isPractice)] + this.btnsArray[this.currentExerciseBtn]}W.mp3`
                        );
                        await this.cancelableWait(super.playSound(instSound));
                        return;
                    }
                }
                const soundSrc = `${
                    this.partialSoundSrc[Number(isPractice)] + this.btnsArray[i] + additionalSoundParams
                }.mp3`;
                const instSound = loadSound(soundSrc);
                //set custom
                const bananasCustoms = this.root.banana_anim_mc.banana_all_mc;
                const randomNumber = this.generateRandomNumber(
                    bananasCustoms[`${this.customArray[i]}_mc`].currentFrame
                );
                bananasCustoms[`${this.customArray[i]}_mc`].gotoAndStop(randomNumber);
                //second ear
                if (i === this.btnsArray.length - 1) {
                    const secondRandomNumber = isPractice
                        ? randomNumber
                        : this.generateRandomNumber(
                              bananasCustoms[`${this.customArray[i + 1]}_mc`].currentFrame,
                              randomNumber
                          );
                    bananasCustoms[`${this.customArray[i + 1]}_mc`].gotoAndStop(secondRandomNumber);
                }

                await this.cancelableWait(super.playSound(instSound));
                if (isPractice) this.testQuestion();
            });
        }
    };

    generateRandomNumber = (prev: number, prevEar?: number) => {
        let randomNumber = Math.floor(Math.random() * Math.floor(4)) + 1;
        while (randomNumber === prev || (prevEar && randomNumber === prevEar)) {
            //prevent prev value
            randomNumber = Math.floor(Math.random() * Math.floor(4)) + 1;
        }
        return randomNumber;
    };
    enableDisableCustomBtns = (enable: boolean) => {
        for (let i = 0; i < this.btnsArray.length; i++) {
            const currentBtn = this.root[`${this.btnsArray[i]}_btn`];
            currentBtn.mouseEnabled = enable;
        }
    };
    clearCustoms = () => {
        const bananasStuf = this.root.banana_anim_mc.banana_all_mc;
        for (let i = 0; i < this.customArray.length; i++) {
            bananasStuf[`${this.customArray[i]}_mc`].gotoAndStop(0);
        }
    };

    resetGame() {
        super.resetGame();
        stopAllSounds();
        this.currentExerciseBtn = null;
        this.testedQuetion = 0;
        this.clearEvents();
        this.clearCustoms();
    }
    clearEvents = () => {
        for (let i = 0; i < this.btnsArray.length; i++) {
            const currentBtn = this.root[`${this.btnsArray[i]}_btn`];
            currentBtn.cursor = "auto";
            currentBtn.visible = true;
            currentBtn.removeAllEventListeners();
        }
    };
}
