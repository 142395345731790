import { MC, playSound, stopAllSounds, waitForEvent } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { BaseGame } from "./BaseGame";
export default class Game3 extends BaseGame {
    correctAnswerIndex: any;
    game: any;
    gameEnd: any;
    helpBtn: any;
    shouldPlaySound: boolean = true;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.game = game;
        this.gameEnd = gameEnd;
        this.helpBtn = helpBtn;
        game.visible = true;
        game.gotoAndStop(0);
        game.scaleX = 0.8;
        game.scaleY = 0.8;
        game.x = 520;
        game.y = 280;
        this.startGame("game3");
    };
    startGame = async (sound: string) => {
        const game = this.game;
        game.mouseChildren = false;
        this.correctAnswerIndex = this.randomCat();
        for (let i = 0; i < 5; i++) {
            const currentHiddenButton = game[`hiddenCat${i + 1}`];
            const currentBtn = game[`btn${i + 1}`];
            currentBtn.index = i + 1;
            currentHiddenButton.visible = false;
            currentBtn.gotoAndStop("normal");
            currentBtn.cursor = "pointer";
            currentBtn.addEventListener("rollover", () => {
                currentBtn.gotoAndStop("over");
            });
            currentBtn.addEventListener("rollout", () => {
                currentBtn.gotoAndStop("normal");
            });
            currentBtn.addEventListener("click", () => {
                currentBtn.gotoAndStop("pressed");
                this.checkAnswer(currentBtn);
            });
        }
        game[`hiddenCat${this.correctAnswerIndex}`].visible = true;
        if (sound !== "") {
            await playSound(sound);
        }
        this.gameEnd();
        this.helpBtn(false);
        game.mouseChildren = true;
        this.game.mouseEnabled = true;
    };
    reloadGame = () => {
        this.shouldPlaySound = false;
        stopAllSounds();
        for (let i = 0; i < 5; i++) {
            this.game[`clip${i + 1}`].visible = false;
        }
        this.startGame("");
    };
    randomCat = () => {
        const catToShow = getRandomNumber(1, 5);
        return catToShow;
    };
    checkAnswer = async (answer: MC) => {
        this.game.mouseEnabled = false;
        if (answer.index === this.correctAnswerIndex) {
            this.game[`hiddenCat${this.correctAnswerIndex}`].visible = false;
            this.game[`clip${this.correctAnswerIndex}`].visible = true;
            this.game[`clip${this.correctAnswerIndex}`].gotoAndPlay(3);
            await waitForEvent(this.game, "catStop");
            this.game.removeAllEventListeners();
            this.helpBtn(true);
            if (this.shouldPlaySound) {
                playSound("nextAndReload");
            }
        } else {
            await playSound("wrong");
            this.game.mouseEnabled = true;
        }
    };
}
