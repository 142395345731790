export const childrenInBoxes = [
    {
        src: "sounds/wrong_letter.mp3",
        id: "wrong_letter",
    },
    {
        src: "sounds/HelpLetter1.mp3",
        id: "HelpLetter1",
    },
    {
        src: "sounds/HelpLetter2.mp3",
        id: "HelpLetter2",
    },
    {
        src: "sounds/HelpLetter3.mp3",
        id: "HelpLetter3",
    },
    {
        src: "sounds/HelpLetter4.mp3",
        id: "HelpLetter4",
    },
    {
        src: "sounds/HelpLetter5.mp3",
        id: "HelpLetter5",
    },
    {
        src: "sounds/HelpLetter6.mp3",
        id: "HelpLetter6",
    },
    {
        src: "sounds/HelpLetter7.mp3",
        id: "HelpLetter7",
    },
    {
        src: "sounds/HelpLetter8.mp3",
        id: "HelpLetter8",
    },
    {
        src: "sounds/HelpLetter9.mp3",
        id: "HelpLetter9",
    },
    {
        src: "sounds/HelpLetter10.mp3",
        id: "HelpLetter10",
    },
    {
        src: "sounds/HelpLetter11.mp3",
        id: "HelpLetter11",
    },
    {
        src: "sounds/HelpLetter12.mp3",
        id: "HelpLetter12",
    },
    {
        src: "sounds/HelpLetter13.mp3",
        id: "HelpLetter13",
    },
    {
        src: "sounds/HelpLetter14.mp3",
        id: "HelpLetter14",
    },
    {
        src: "sounds/HelpLetter15.mp3",
        id: "HelpLetter15",
    },
    {
        src: "sounds/HelpLetter16.mp3",
        id: "HelpLetter16",
    },
    {
        src: "sounds/HelpLetter17.mp3",
        id: "HelpLetter17",
    },
    {
        src: "sounds/HelpLetter18.mp3",
        id: "HelpLetter18",
    },
    {
        src: "sounds/HelpLetter19.mp3",
        id: "HelpLetter19",
    },
    {
        src: "sounds/HelpLetter20.mp3",
        id: "HelpLetter20",
    },
    {
        src: "sounds/HelpLetter21.mp3",
        id: "HelpLetter21",
    },
    {
        src: "sounds/HelpLetter22.mp3",
        id: "HelpLetter22",
    },
    {
        src: "sounds/instLetter1.mp3",
        id: "instLetter1",
    },
    {
        src: "sounds/instLetter2.mp3",
        id: "instLetter2",
    },
    {
        src: "sounds/instLetter3.mp3",
        id: "instLetter3",
    },
    {
        src: "sounds/instLetter4.mp3",
        id: "instLetter4",
    },
    {
        src: "sounds/instLetter5.mp3",
        id: "instLetter5",
    },
    {
        src: "sounds/instLetter6.mp3",
        id: "instLetter6",
    },
    {
        src: "sounds/instLetter7.mp3",
        id: "instLetter7",
    },
    {
        src: "sounds/instLetter8.mp3",
        id: "instLetter8",
    },
    {
        src: "sounds/instLetter9.mp3",
        id: "instLetter9",
    },
    {
        src: "sounds/instLetter10.mp3",
        id: "instLetter10",
    },
    {
        src: "sounds/instLetter11.mp3",
        id: "instLetter11",
    },
    {
        src: "sounds/instLetter12.mp3",
        id: "instLetter12",
    },
    {
        src: "sounds/instLetter13.mp3",
        id: "instLetter13",
    },
    {
        src: "sounds/instLetter14.mp3",
        id: "instLetter14",
    },
    {
        src: "sounds/instLetter15.mp3",
        id: "instLetter15",
    },
    {
        src: "sounds/instLetter16.mp3",
        id: "instLetter16",
    },
    {
        src: "sounds/instLetter17.mp3",
        id: "instLetter17",
    },
    {
        src: "sounds/instLetter18.mp3",
        id: "instLetter18",
    },
    {
        src: "sounds/instLetter19.mp3",
        id: "instLetter19",
    },
    {
        src: "sounds/instLetter20.mp3",
        id: "instLetter20",
    },
    {
        src: "sounds/instLetter21.mp3",
        id: "instLetter21",
    },
    {
        src: "sounds/instLetter22.mp3",
        id: "instLetter22",
    },
    {
        src: "sounds/intro.mp3",
        id: "intro",
    },
    {
        src: "sounds/KufsaotNoflot.mp3",
        id: "boxDrop",
    },
    {
        src: "sounds/KufsaotOlot.mp3",
        id: "boxUp",
    },
    {
        src: "sounds/more1.mp3",
        id: "more1",
    },
    {
        src: "sounds/more2.mp3",
        id: "more2",
    },
    {
        src: "sounds/more3.mp3",
        id: "more3",
    },
    {
        src: "sounds/more4.mp3",
        id: "more4",
    },
    {
        src: "sounds/more5.mp3",
        id: "more5",
    },
    {
        src: "sounds/more6.mp3",
        id: "more6",
    },
    {
        src: "sounds/more7.mp3",
        id: "more7",
    },
    {
        src: "sounds/more8.mp3",
        id: "more8",
    },
    {
        src: "sounds/more9.mp3",
        id: "more9",
    },
    {
        src: "sounds/more10.mp3",
        id: "more10",
    },
    {
        src: "sounds/more11.mp3",
        id: "more11",
    },
    {
        src: "sounds/more12.mp3",
        id: "more12",
    },
    {
        src: "sounds/more13.mp3",
        id: "more13",
    },
    {
        src: "sounds/more14.mp3",
        id: "more14",
    },
    {
        src: "sounds/more15.mp3",
        id: "more15",
    },
    {
        src: "sounds/more16.mp3",
        id: "more16",
    },
    {
        src: "sounds/more17.mp3",
        id: "more17",
    },
    {
        src: "sounds/more18.mp3",
        id: "more18",
    },
    {
        src: "sounds/more19.mp3",
        id: "more19",
    },
    {
        src: "sounds/more20.mp3",
        id: "more20",
    },
    {
        src: "sounds/more21.mp3",
        id: "more21",
    },
    {
        src: "sounds/more22.mp3",
        id: "more22",
    },
];

export const getHelpSoundsByLetter = (letter: number) => {
    return [
        {
            src: `sounds/HelpLetter${letter}.mp3`,
            id: `help`,
        },
    ];
};

export const getMoreSoundsByLetter = (letter: number) => {
    return [
        {
            src: `sounds/more${letter}.mp3`,
            id: `more${letter}`,
        },
    ];
};

export const getInstSoundsByLetter = (letter: number) => {
    return [
        {
            src: `sounds/instLetter${letter}.mp3`,
            id: `instLetter${letter}`,
        },
    ];
};

export const getGeneralSounds = () => {
    return [
        {
            src: `sounds/wrong_letter.mp3`,
            id: `wrong_letter`,
        },
        {
            src: `sounds/intro.mp3`,
            id: `intro`,
        },
        {
            src: "sounds/KufsaotNoflot.mp3",
            id: "boxDrop",
        },
        {
            src: "sounds/KufsaotOlot.mp3",
            id: "boxUp",
        },
    ];
};
