import { MC, waitFor } from "../../../animate/utils";
import { SpecialGameBasic } from "../../SpecialGameBasic";

export class PurimGame extends SpecialGameBasic {
    private choosedCostume: MC;
    helpSound = "/special_games/purim/sounds/help.mp3";
    instructionSound = "/special_games/purim/sounds/inst.mp3";
    bGSound = "/special_games/purim/sounds/music.mp3";

    loaded = async (root: MC) => {
        this.root = root;
        await waitFor(() => this.root);
        this.initCostumes();
        this.addTickEvent();
        await this.playInstSound();
        await this.setBgSound();
        this.bgSound.volume = 0.2;
        this.initExit();
        this.initIconMc();
        this.initIconMc();
        this.enableHelp();
        await this.initHelp();
        this.helpSoundInst.volume = 2;
        this.initSpeker();
    };

    initCostumes = () => {
        for (let i = 1; i <= 15; i++) {
            this.dragCostume(this.root["costume_" + i]);
            this.root["costume_" + i].cursor = "pointer";
        }
    };
    dragCostume = (costume: MC) => {
        costume.addEventListener("mousedown", () => {
            this.root.setChildIndex(costume, this.root.numChildren - 1);
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            costume.moveX = localMouse.x - costume.x;
            costume.moveY = localMouse.y - costume.y;
            this.choosedCostume = costume;
        });
        costume.addEventListener("pressup", () => {
            this.choosedCostume = null;
        });
    };
    addTickEvent = () => {
        this.root.addEventListener("tick", (e: any) => {
            if (!this.choosedCostume) return;
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.choosedCostume.x = localMouse.x - this.choosedCostume.moveX;
            this.choosedCostume.y = localMouse.y - this.choosedCostume.moveY;
        });
    };
}
