import { MC, waitForEvent } from "../../animate/utils";
import { ZigiGameBase } from "./ZigiGameBase";
import { loadSound } from "../../animate/sound_utils";
import { getRandomNumber } from "../../utils/MathUtils";

export default class ZigiNounsGame extends ZigiGameBase {
    shouldPlay: boolean = true;
    nouns = ["boy", "tree", "girl", "ball", "bicycle", "flowers"];
    enabledFramBtn: boolean = true;
    helpSrc: Promise<createjs.AbstractSoundInstance>;

    loaded(root: MC) {
        super.loaded(root);
        this.root.anim_mc.bicycle_btn.alpha = 0.01;
        this.root.anim_mc.bicycle.gotoAndStop(0);
    }
    startLearn = async () => {
        try {
            super.startLearn();
            const instSound = loadSound("/english/sounds/zigi_nouns_game_sounds/inst2.mp3");
            this.helpSrc = instSound;
            this.helpSrc2 = null;
            await this.cancelableWait(super.playSound(instSound));
            this.addEventsToNouns(false, null);
        } catch (ignore) {}
    };

    addEventsToNouns = (fromExercise: boolean = false, correctAnswer: any = null) => {
        this.root.anim_mc.mouseEnabled = true;
        this.nouns.forEach((noun) => {
            const currentNoun = this.root.anim_mc[`${noun}_btn`];
            let currentNounSound = loadSound(`/english/sounds/zigi_nouns_game_sounds/${noun}.mp3`);
            let wrongSound: any;
            if (correctAnswer) {
                wrongSound = loadSound(`/english/sounds/zigi_nouns_game_sounds/W_${correctAnswer}.mp3`);
            }
            currentNoun.cursor = "pointer";
            currentNoun.mouseChildren = false;
            currentNoun.addEventListener("rollover", () => {
                if (noun === "bicycle") {
                    this.root.anim_mc.bicycle.gotoAndStop("over");
                } else {
                    currentNoun.gotoAndStop("over");
                }
            });
            currentNoun.addEventListener("rollout", () => {
                if (noun === "bicycle") {
                    this.root.anim_mc.bicycle.gotoAndStop("up");
                } else {
                    currentNoun.gotoAndStop("up");
                }
            });
            currentNoun.addEventListener("click", async () => {
                this.root.anim_mc.mouseEnabled = false;
                this.enabledFramBtn = false;
                if (!fromExercise || (fromExercise && correctAnswer === noun)) {
                    await this.cancelableWait(super.playSound(currentNounSound));
                    if (!this.shouldPlay) return;
                    this.root.anim_mc.gotoAndPlay(noun);
                    if (!this.shouldPlay) return;
                    await this.cancelableWait(waitForEvent(this.root.anim_mc, "animationEnd"));
                    this.restartAnimation();
                    this.root.anim_mc.mouseEnabled = true;
                    this.enabledFramBtn = true;
                    if (fromExercise) {
                        correctAnswer = null;
                        this.askQuestion();
                        return;
                    }
                } else if (correctAnswer && correctAnswer !== noun) {
                    await this.cancelableWait(super.playSound(wrongSound));
                    this.restartAnimation();
                    this.root.anim_mc.mouseEnabled = true;
                    this.enabledFramBtn = true;
                }
            });
        });
    };
    restartAnimation = () => {
        this.root.anim_mc.gotoAndStop(0);
        this.root.anim_mc.bicycle_btn.alpha = 0.01;
        this.root.anim_mc.bicycle.gotoAndStop(0);
        this.nouns.forEach((noun) => {
            this.root.anim_mc[`${noun}_btn`].gotoAndStop(0);
        });
    };
    startExercise = async () => {
        try {
            super.startExercise();
            const instSound = loadSound("/english/sounds/zigi_nouns_game_sounds/inst.mp3");
            this.helpSrc = instSound;
            await this.cancelableWait(super.playSound(instSound));
            this.askQuestion();
        } catch (ignore) {}
    };
    askQuestion = async () => {
        const answer = this.randomNoun();
        const answerSound = loadSound(`/english/sounds/zigi_nouns_game_sounds/${answer}.mp3`);
        await this.cancelableWait(super.playSound(answerSound));
        this.helpSrc2 = answerSound;

        this.addEventsToNouns(true, answer);
    };
    randomNoun = () => {
        const noun = getRandomNumber(0, 5);
        const answer = this.nouns[noun];
        return answer;
    };

    resetGame = () => {
        super.resetGame();
        this.restartAnimation();
        this.nouns.forEach((noun) => {
            this.root.anim_mc[`${noun}_btn`].removeAllEventListeners();
            this.root.anim_mc[`${noun}_btn`].cursor = "default";
        });
    };
}
