import React, { useState } from "react";
import AnimateCC from "../../animate";
import { BibleColoring } from "./BibleColoring";
import styles from "./bible-coloring.module.scss";
import { MC } from "../../animate/utils";
import { BibleFrame } from "../story_menu/BibleFrame";

const BibleColoringComponent: React.FC<{ type: string; frame: BibleFrame; onLoaded: () => void }> = ({
    type,
    frame,
    onLoaded,
}) => {
    const [printImage, setPrintImage] = useState<string>("");

    const game = React.useRef(new BibleColoring(type, frame, setPrintImage));
    return (
        <>
            <AnimateCC
                base="/bible_stories/coloring_bible"
                animationName="coloring_bible"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                    onLoaded();
                }}
            />
            <div className={styles.footer_english}>http://www.sodmaya.co.il</div>
            <div className={styles.footer_Hebrew}>הסוד של מיה ©</div>
            <img alt="coloring pages" className={styles.only_print} src={printImage} />
        </>
    );
};

export default BibleColoringComponent;
