import { MC, playSound, setupStage, stopAllSounds, waitForEvent } from "../../../animate/utils";

export class EggGame {
    private root: MC;
    private counter = 0;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).egg = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.scale = 0.8;
        this.root.x += 90;
        this.root.y += 50;
        this.start();
    };

    start = () => {
        this.root.little_egg.addEventListener("click", async () => {
            this.root.gotoAndPlay("growStart");
            await waitForEvent(this.root, "growStop");
            stopAllSounds();
            this.root.gotoAndStop("growStop");
            this.root.standingChicken.alpha = 1;
        });

        for (let i = 1; i <= 28; i++) {
            this.root[`_${i}`].mouseChildren = false;
        }
        this.root.addEventListener("mouseover", (e: createjs.MouseEvent) => {
            if (e.target.name?.startsWith("_")) {
                e.target.visible = false;
                this.counter += 1;
                playSound("egg_1");
                if (this.counter === 28) {
                    this.moveChicken();
                }
            }
        });
    };

    moveChicken = async () => {
        playSound("chickenWord");
        this.root.gotoAndPlay("chickenStart");
        this.counter = 0;
        for (let i = 1; i <= 28; i++) {
            this.root[`_${i}`].visible = true;
        }
        await waitForEvent(this.root, "end");
        await stopAllSounds();
        this.root.gotoAndStop("init");
        playSound("egg_sound");
    };
}
