import { getRandomNumber } from "../../utils/MathUtils";

const SET_NAMES = [
    "baa",
    "bayaar",
    "bimdinat",
    "eser",
    "haoto",
    "hashafan",
    "ken",
    "ruz",
    "sinim",
    "tasa",
    "yem",
    "yona",
];
const jsonData = require("./TrainData.json");

export function getMusicSet(chosenLevel: number) {
    const setName = randomSet();
    let setSongs: any[] = [];
    jsonData.Train.Songs.Song.forEach((set: any) => {
        if (set.id === setName) {
            set.Level.forEach((level: any) => {
                if (level.id === chosenLevel.toString()) {
                    level.Part.forEach((part: any) => {
                        setSongs.push(part);
                    });
                }
            });
        }
    });
    return setSongs;
}

export function randomSet() {
    const num = getRandomNumber(0, SET_NAMES.length - 1);
    return SET_NAMES[num];
}
