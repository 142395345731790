import React from "react";
import AnimateCC from "../../animate";
import { IceCreamGame } from "./IceCreamGame";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import icecreamSounds from "./sounds_icecream.json";

const IceCreamComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new IceCreamGame(frame));

    return (
        <AnimateCC
            base="/lunapark/sweets/icecream/"
            animationName="icecream"
            additionalSounds={icecreamSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default IceCreamComponent;
