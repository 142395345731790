import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import { MC, playSound, waitForEvent } from "../../../animate/utils";
import { Navigation } from "../../../navigation/navigation";
import { routes } from "../../../navigation/routesPath";
import { getLetterBg } from "../LettersUtils";

export class FinalLetterDraw {
    root: MC;
    letter: number;
    showSwitcher: () => void;
    disableNext: () => void;
    constructor(root: MC, letter: number, showSwitcher: () => void, disableNext: () => void) {
        this.root = root;
        this.letter = letter;
        this.showSwitcher = showSwitcher;
        this.disableNext = disableNext;
        (window as any).fDraw = this.root;
        this.loaded();
    }
    play = async (url: string) => {
        const sound = loadSound(url);
        await soundPlayed((await sound).play());
    };

    loaded = async () => {
        let animation: MC;
        this.disableNext();
        this.root.children.forEach((child: MC) => {
            child.visible = false;
        });
        console.log("in final draw");
        if (getLetterBg(this.letter) === "airplane_enter") {
            console.log("in if");

            this.root.bgMvc.gotoAndStop(0);
            this.root.bgMvc.visible = true;
        } else {
            console.log("in else");

            this.root.bgMvc.gotoAndStop(1);
            this.root.bgMvc.visible = true;
        }
        animation = this.root[`anim${this.letter + 1}`];
        animation.visible = true;
        await this.play(`letters_island/draw_letter/sounds/Showletter${this.letter + 1}.mp3`);

        await this.play(`letters_island/draw_letter/sounds/Showletter${this.letter + 1}_f.mp3`);

        animation.gotoAndPlay("finalLetter");
        for (let index = 1; index <= 4; index++) {
            waitForEvent(animation, `draw${index}`).then(async () => {
                console.log("in draw");
                playSound(`draw${index}`);
            });
        }
        await waitForEvent(animation, `finishedDraw`);
        await this.play(`letters_island/draw_letter/sounds/letter${this.letter + 1}f.mp3`);
        await this.play(`letters_island/draw_letter/sounds/letter${this.letter + 1}f_sound.mp3`);
        Navigation.openPage(routes.letters.activities.final_letter.replace(":letterIndex", this.letter + ""));
        await this.showSwitcher();

        // animation.gotoAndPlay(0);
    };
}
