import React, { useRef, useState } from "react";
import AnimateCC from "../../animate";
import { allLettersMovie, letterMovies } from "../../video/videoUrl";
import { VideoPlayer, VideoPlayerComponent } from "../../video/VideoPlayer";
import { routes } from "../../navigation/routesPath";
import { Navigation } from "../../navigation/navigation";
import { LettersSong } from "./LettersSong";
import { displayIf } from "../../utils/StyleUtils";
import { useRouteMatch } from "react-router-dom";
import { useLettersContext } from "../../context/LettersContext";

export function LettersSongComponent() {
    const match = useRouteMatch<{ letterIndex: string }>();
    const isAllLetters = match.path === routes.letters.video;

    const [screenReady, setScreenReady] = useState(false);
    const video = useRef<VideoPlayer>();
    const lettersContext = useLettersContext();
    const playPause = () => {
        video.current.pausePlay();
    };

    const restartVideo = () => {
        video.current.restartVideo();
    };

    const onBack = () => {
        Navigation.openPage(
            isAllLetters
                ? routes.letters.menu
                : routes.letters.activities.menu.replace(":letterIndex", match.params.letterIndex)
        );
    };

    const game = useRef(new LettersSong(playPause, restartVideo, onBack));

    const onEndFunction = () => {
        video.current.restartVideo();
        game.current.root.btnPlay.gotoAndStop(2);
    };

    const letterVideoDone = () => {
        if (isAllLetters) return;
        lettersContext.setLetterStatus(parseInt(match.params.letterIndex) + 1, "video");
    };
    return (
        <>
            <VideoPlayerComponent
                src={isAllLetters ? allLettersMovie : letterMovies[parseInt(match.params.letterIndex)]}
                ref={video}
                frameWidth={800}
                frameHeight={600}
                onEndFunction={onEndFunction}
                width={340}
                height={260}
                shiftX={5}
                shiftY={-40}
                style={displayIf(screenReady)}
            />
            <AnimateCC
                base="/letters_island/video_play/"
                animationName="video_player"
                onLoaded={(mc: createjs.MovieClip) => {
                    setScreenReady(true);
                    game.current.loaded(mc, letterVideoDone);
                }}
            />
        </>
    );
}
