import React from "react";
import AnimateCC from "../../../animate";
import { NumberFourGame } from "./NumberFourGame";
import additionalSounds from "../BigWheelSounds";
import { BigWheelGame } from "../BigWheelGame";
const NumberFourComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberFourGame(frame));

    return (
        <AnimateCC
            //base="/lunapark/sweets/popcorn"
            base="/lunapark/big_wheel/number_four"
            animationName="number_four"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default NumberFourComponent;
