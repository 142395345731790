import { MC, playSound, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
const BOUND = 100;

export class ChickencGame {
    private root: MC;
    private cows_speed: number = 3;
    private tracs_speed: number = 3.2;
    private trees_speed: number = 2.7;
    private isMoving: boolean = false;
    private appleSpeeds: number[] = [];
    private isDropping: boolean[] = [];
    private initYPositions: number[] = [];
    constructor(private frame: any) {}
    loaded = async (mc: MC) => {
        this.root = mc;
        (window as any).chicken = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.chicken.gotoAndStop(0);
        this.selectTractorColor();
        this.addTractorListener();
        this.addChickenListener();
        this.addCowListener();
        this.initTree();
        this.start();
    };

    start = () => {
        this.root.addEventListener("tick", () => {
            if (this.isMoving) {
                this.root.tractor.x += this.tracs_speed;
                if (this.root.tractor.x - BOUND >= 800) {
                    this.root.tractor.x = -BOUND;
                    this.selectTractorColor();
                }
                this.root.cow.x += this.cows_speed;
                if (this.root.cow.x - BOUND >= 800) {
                    this.root.cow.x = -BOUND;
                }
                this.root.tree.x += this.trees_speed;
                if (this.root.tree.x - BOUND >= 800) {
                    this.root.tree.x = -BOUND;
                    this.resetTree();
                }
            }
            this.dropApples();
        });
    };

    selectTractorColor = () => {
        this.root.tractor.gotoAndStop(getRandomNumber(0, 3));
    };

    initTree = () => {
        for (let i = 0; i < 26; i++) {
            const apple = this.root.tree["apple" + (i + 1)];
            if (getRandomNumber(0, 1) === 0) {
                apple.visible = false;
            }
            this.appleSpeeds[i] = 1;
            this.isDropping[i] = false;
            this.initYPositions[i] = apple.y;
            apple.addEventListener("mouseover", () => {
                this.isDropping[i] = true;
            });
        }
    };
    dropApples = () => {
        for (let i = 0; i < 26; i++) {
            if (this.isDropping[i]) {
                const apple = this.root.tree["apple" + (i + 1)];
                //twice every tick
                apple.y += this.appleSpeeds[i];
                this.appleSpeeds[i] *= 1.1;
                apple.y += this.appleSpeeds[i];
                this.appleSpeeds[i] *= 1.1;
                if (apple.y > -20) {
                    apple.y = 0;
                    this.isDropping[i] = false;
                }
            }
        }
    };

    resetTree = () => {
        for (let i = 0; i < 26; i++) {
            const apple = this.root.tree["apple" + (i + 1)];
            this.appleSpeeds[i] = 1;
            this.isDropping[i] = false;
            if (getRandomNumber(0, 1) === 0) {
                apple.visible = false;
            } else {
                apple.visible = true;
            }
            apple.y = this.initYPositions[i];
        }
    };
    addChickenListener = () => {
        this.root.chicken.addEventListener("mouseover", () => {
            this.isMoving = true;
            playSound("chickensounbd");
            this.root.chicken.gotoAndPlay(1);
        });
        this.root.chicken.addEventListener("mouseout", () => {
            this.isMoving = false;
            this.root.chicken.gotoAndStop(0);
        });
    };
    addCowListener = () => {
        this.root.cow.addEventListener("mouseover", () => {
            playSound("cow");
            if (this.root.cow.currentFrame === 0) {
                this.root.cow.gotoAndPlay(0);
            } else {
                this.root.cow.play();
            }
        });
        this.root.cow.addEventListener("mouseout", () => {
            this.root.cow.stop();
        });
    };

    addTractorListener = () => {
        this.root.tractor.addEventListener("mouseover", () => {
            playSound("tractor");
        });
    };
}
