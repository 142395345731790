import { MC, stopAllSounds, waitForEvent } from "../../animate/utils";
import { ZigiGameBase } from "./ZigiGameBase";
import { getRandomNumber } from "../../utils/MathUtils";
import { loadSound } from "../../animate/sound_utils";

export default class ZigiVerbsGame extends ZigiGameBase {
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    verbsList = ["dance", "sing", "run", "sleep", "drink", "eat"];
    selectedVerb: string = "";
    enabledFramBtn: boolean = true;
    verbClicked: boolean = false;
    shouldPlay: boolean = true;
    gameMode: string = "learn";

    loaded(root: MC) {
        super.loaded(root);
        const mask = new createjs.Shape();
        mask.graphics.beginFill("#000").drawRect(0, 0, 800, 600);
        this.root.mask = mask;
        this.root.gotoAndPlay(0);
    }

    verbsButtonsEvents = (exercise: boolean = false, correctAnser: any) => {
        this.verbsList.forEach((verb) => {
            let answerPath: any;
            const verbPath = loadSound(`/english/sounds/zigi_verbs_game_sounds/learn/${verb}.mp3`);
            const verbAnimatePath = loadSound(`/english/sounds/verbs/${verb}Ef.mp3`);
            if (exercise) {
                answerPath = loadSound(`/english/sounds/zigi_verbs_game_sounds/practice/W_${correctAnser}.mp3`);
            }

            const btnVerb = this.root[`${verb}_btn`];
            btnVerb.cursor = "pointer";
            btnVerb.gotoAndStop("up");
            btnVerb.addEventListener("rollover", () => {
                if (!this.verbClicked) {
                    btnVerb.gotoAndStop("over");
                }
            });

            btnVerb.addEventListener("rollout", () => {
                if (!this.verbClicked) {
                    btnVerb.gotoAndStop("up");
                }
            });
            btnVerb.addEventListener("click", async () => {
                if (!this.verbClicked) {
                    this.verbClicked = true;
                    this.root.mouseEnabled = false;
                    btnVerb.gotoAndStop("over");
                    if (!exercise || (exercise && verb === correctAnser)) {
                        await this.cancelableWait(this.playSound(verbPath));
                        this.enabledFramBtn = false;
                        this.root.zigi_mc.gotoAndStop(verb);
                        this.root.curtain_mc.gotoAndPlay("open");
                        super.playSound(verbAnimatePath);
                        await this.cancelableWait(waitForEvent(this.root.curtain_mc, "animationEnd"));
                        this.root.zigi_mc.gotoAndPlay(verb);
                        await this.cancelableWait(waitForEvent(this.root.zigi_mc, "animationEnd"));
                        this.root.curtain_mc.gotoAndPlay("close");
                        await this.cancelableWait(waitForEvent(this.root.curtain_mc, "animationEnd"));
                        this.enabledFramBtn = true;
                        if (exercise) {
                            this.verbClicked = false;
                            if (!this.shouldPlay) return;
                            this.askQuestion();
                        }
                    } else if (exercise && verb !== correctAnser) {
                        await this.cancelableWait(super.playSound(answerPath));
                    }
                    this.root.zigi_mc.gotoAndStop(0);
                    this.root.mouseEnabled = true;
                    btnVerb.gotoAndStop("up");
                    this.verbClicked = false;
                    this.enabledFramBtn = true;
                }
            });
        });
    };

    startLearn = async () => {
        try {
            super.startLearn();
            this.gameMode = "learn";
            const instPath = loadSound(`/english/sounds/zigi_verbs_game_sounds/learn/inst.mp3`);
            this.helpSrc = instPath;
            await super.playSound(instPath);
            this.verbsButtonsEvents(false, null);
        } catch (ignore) {}
    };

    startExercise = async () => {
        try {
            super.startExercise();
            this.gameMode = "exercise";
            const inst2Path = loadSound(`/english/sounds/zigi_verbs_game_sounds/practice/inst.mp3`);
            this.helpSrc = inst2Path;
            await super.playSound(inst2Path);
            this.askQuestion();
        } catch (ignore) {}
    };

    resetGame = () => {
        super.resetGame();
        this.gameMode = "";
        stopAllSounds();
        this.verbClicked = false;
        this.root.gotoAndPlay(0);
        this.root.zigi_mc.gotoAndStop(0);
        this.root.curtain_mc.gotoAndStop(0);
        this.verbsList.forEach((verb) => {
            const btnVerb = this.root[`${verb}_btn`];
            btnVerb.gotoAndStop("up");
            btnVerb.removeAllEventListeners();
        });
    };
    askQuestion = async () => {
        if (this.gameMode === "exercise") {
            if (!this.shouldPlay) return;

            this.verbsList.forEach((verb) => {
                const btnVerb = this.root[`${verb}_btn`];
                btnVerb.gotoAndStop("up");
                btnVerb.removeAllEventListeners();
            });

            const num = getRandomNumber(0, 5);
            const answer = this.verbsList[num];
            const answerPath = loadSound(`/english/sounds/zigi_verbs_game_sounds/practice/${answer}.mp3`);
            await super.playSound(answerPath);
            this.verbsButtonsEvents(true, answer);
        }
    };
}
