import { MC, waitForEvent, playSound, stopAllSounds, waitForTimer, playSoundSync } from "../../../animate/utils";
import { Navigation } from "../../../navigation/navigation";
import { routes } from "../../../navigation/routesPath";
//import { setReviewGameCompleted } from "../../../api/lettersIsland";
export class Quiz {
    constructor(public root: MC, private letterIndex: number, private lettersContext: any) {}

    endOfGame = async () => {
        //setReviewGameCompleted(this.letterIndex, "quiz");
        let success: number = 0;
        for (let i = 0; i < 12; i++) {
            if (this.root[`prize${i}`].currentFrame !== 1) {
                success++;
            }
        }
        if (success < 2) {
            this.lettersContext.setGameStatus(this.letterIndex, "quiz");
            this.root.confetti.visible = true;
            await playSound(`well${this.letterIndex}`);
        } else {
            await playSound("again");
        }
        await waitForTimer(600);
        Navigation.openPage(routes.letters.review.menu.replace(":sectionIndex", this.letterIndex + ""));
    };

    loaded = async () => {
        for (let i = 0; i < 12; i++) {
            this.root[`prize${i}`].gotoAndStop(0);
        }
        this.root.exit.addEventListener("click", () => {
            this.root.exit.gotoAndStop("over");
            stopAllSounds();
            Navigation.openPage(routes.letters.review.menu.replace(":sectionIndex", this.letterIndex + ""));
        });
        this.root.exit.addEventListener("rollover", () => {
            playSoundSync("exit");
            this.root.exit.gotoAndStop("over");
        });
        this.root.boxes_bg.visible = false;
        this.root.help.help_icon.mouseEnabled = false;
        this.root.exit.exit_icon.mouseEnabled = false;
        this.root.help.help_icon_shadow.mouseEnabled = false;
        this.root.splash.visible = false;
        this.root.confetti.visible = false;
        this.root.splash.visible = true;
        await waitForEvent(this.root, "animationEnd");
        await playSound("welcome");
        this.root.BGStationed.visible = false;
    };

    addResult = (answerStatus: string, answerIndex: number) => {
        this.root[`prize${answerIndex - 1}`].gotoAndStop(answerStatus);
    };
}
