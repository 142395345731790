import { MC, waitForEvent, playSound, stopAllSounds, asyncTween } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game7 extends BaseGame {
    root: any;
    gameEnd: any;
    game2: any;
    game: any;
    helpBtn: any;
    questionIndex = 0;
    initGame = async (root: MC, gameEnd: () => void, helpBtn: any) => {
        this.root = root;
        this.gameEnd = gameEnd;
        this.helpBtn = helpBtn;
        const game = root.game;
        this.game = game;
        game.y = 400;
        game.visible = true;
        game.gotoAndPlay(1);
        await waitForEvent(game, "questionEnd");
        this.helpBtn(false);
        this.addEventsToBtn(game.btn_0);
        this.addEventsToBtn(game.btn_1);
    };
    initGame2 = async () => {
        this.questionIndex = 2;
        const game2 = this.root.game2;
        this.game2 = game2;
        game2.x = 600;
        game2.visible = true;
        game2.gotoAndPlay(1);
        if (!this.shouldPlay) return;
        await waitForEvent(game2, "questionEnd");
        if (!this.shouldPlay) return;
        this.helpBtn(false, "game2song");
        this.addEventsToBtn(game2.btn_0);
        this.addEventsToBtn(game2.btn_1);
    };
    addEventsToBtn = (btn: MC) => {
        btn.gotoAndStop(0);
        btn.cursor = "pointer";
        btn.addEventListener("click", async () => {
            if (btn.name === "btn_1") {
                stopAllSounds();
                this.helpBtn(true);
                if (this.questionIndex === 2) {
                    this.game2.gotoAndPlay(428);
                    if (!this.shouldPlay) return;
                    await waitForEvent(this.game2, "game2End");
                    if (!this.shouldPlay) return;
                    await asyncTween(this.game2, { alpha: 0 }, 300);
                    if (!this.shouldPlay) return;
                    this.gameEnd();
                } else {
                    this.game.gotoAndPlay(443);
                    await waitForEvent(this.game, "gameEnd");
                    if (!this.shouldPlay) return;
                    this.initGame2();
                }
            } else {
                playSound("wrong");
            }
        });
        btn.addEventListener("rollover", () => {
            btn.gotoAndStop(2);
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop(0);
        });
    };
}
