import React from "react";
import AnimateCC from "../../../animate";
import { NumberOneGame } from "./NumberOneGame";
import additionalSounds from "../BigWheelSounds";
import { BigWheelGame } from "../BigWheelGame";
const NumberOneComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberOneGame(frame));

    return (
        <AnimateCC
            //base="/lunapark/sweets/popcorn"
            base="/lunapark/big_wheel/number_one"
            animationName="number_one"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default NumberOneComponent;
