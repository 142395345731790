import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, playSoundSync, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { getRandomArr, getRandomNumber } from "../../utils/MathUtils";
import MathGameBase from "../MathGame/MathGameBase";

const SERIES_TO_FILL = 5;

export class Ducks extends MathGameBase {
    root: MC;
    chosenLevel: number = 0;
    levelInfo = [
        { level: 1, missingNum: [1], digits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], bExactOptions: false },
        { level: 2, missingNum: [2, 3], digits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], bExactOptions: false },
        { level: 3, missingNum: [4], digits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], bExactOptions: true },
    ];
    correctAnswers: number = 0;
    missingNum: number[] = [];
    bExactOptions: boolean;
    digits: number[] = [];
    series: number[] = [];
    ducksMissing: number[] = [];
    openBall: MC;
    openBallNum: number;
    numOfDucksMissing: number;
    ballsVisible: number[] = [];
    enableBallHovering: boolean = false;
    allowHoverBtn: MC;
    enableEvents: boolean = true;
    resetPompa: boolean = false;
    name = "ducks";
    openBallIndex: number;
    isVisible: boolean = true;
    isChooserUP: boolean = false;
    finishedEnter: boolean;
    finishedTurn: boolean;
    inEnd: boolean;
    isFirstGame: boolean = true;

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).ducks = root;
    }

    stopDucks = () => {
        for (let i = 0; i < 5; i++) {
            const duck = this.root[`duck${i + 1}`];
            if (duck.currentLabel === "moving") {
                duck.gotoAndStop(0);
            }
            duck.stop();
        }
    };

    chooserUp = () => {
        this.isChooserUP = true;
        this.stopGame();
    };

    stopGame = () => {
        stopAllSounds();
        this.enableBallHovering = false;
        this.enableEvents = false;
        this.root.stop();
        this.stopDucks();
        this.stopPompas();
        this.resetPompa = true;
    };

    chooserDown = async () => {
        this.isChooserUP = false;
        this.resetGame();
    };

    resetGame = async () => {
        console.log("in reset game");
        this.resetPompas();
        this.resetDucksTexts();
        this.defaultDuckCursor();
        this.resetPompa = false;
        this.openBall = null;
        this.openBallIndex = -1;
        this.enableBallHovering = true;
        this.enableEvents = true;
        this.root.gotoAndStop(1);
        await waitForTimer(0);
        if (this.correctAnswers === 5) {
            this.endGame();
        } else {
            await this.newQuestion();
            this.root.gotoAndPlay(1);
            // if(this.)
            await waitForEvent(this.root, "sayInst");
            // this.enableEvents && this.playInst();
            waitForEvent(this.root, "gameReady").then(() => {
                this.root.gotoAndStop("gameReady");
                this.finishedEnter = true;
            });
        }
    };

    resetDucksTexts = () => {
        for (let i = 0; i < 5; i++) {
            const duck = this.root[`duck${i + 1}`];
            duck.ducks_number_mc.ducks_number_txt.text = "";
            duck.door_mc.duck_txt_mc.txt_mc.text = "";
        }
    };

    stopPompas = () => {
        for (let index = 0; index < 5; index++) {
            const pompa = this.root[`pompaAll${index}`];
            pompa.stop();
        }
    };

    resetPompas = () => {
        for (let index = 0; index < 5; index++) {
            const pompa = this.root[`pompaAll${index}`];
            pompa.gotoAndStop(0);
        }
    };

    handleLevel = (level: number) => {
        this.resetPompa && this.stopPompas();
        this.disableHelp();
        this.chosenLevel = level;
        this.openBall = null;
        this.openBallIndex = -1;
        this.isChooserUP = false;
        this.finishedEnter = false;
        this.root.gotoAndStop(1);
        this.correctAnswers = 0;
        this.cleanPrizes(true);
        this.levelInfo.forEach((info: any) => {
            if (info.level === this.chosenLevel) {
                this.missingNum = info.missingNum;
                this.bExactOptions = info.bExactOptions;
                info.digits.forEach((digit: number) => {
                    this.digits.push(digit);
                });
            }
        });
        this.startGame();
        waitForEvent(this.root, "gameReady").then(() => {
            this.root.gotoAndStop("gameReady");
            this.finishedEnter = true;
        });
    };

    resetQuestionProps = () => {
        for (let index = 0; index < 11; index++) {
            const ball = this.root[`ball${index}`];
            ball.gotoAndStop(0);
            ball.visible = false;
            ball.removeAllEventListeners();
        }
        for (let index = 0; index < 5; index++) {
            const duck = this.root[`duck${index + 1}`];
            duck.ducks_number_mc.ducks_number_txt.text = " ";
            duck.door_mc.duck_txt_mc.txt_mc.text = " ";
            duck.door_mc.gotoAndStop(0);
            duck.removeAllEventListeners();
        }
        this.finishedTurn = false;
        this.inEnd = false;
        this.ducksMissing = [];
        this.enableBallHovering = false;
        this.enableEvents = true;
        this.series = [];
        this.allowHoverBtn = null;
        this.ballsVisible = [];
    };

    playInst = async () => {
        await loadSound(`/math/sounds/ducks/Ducks_inst_level${this.chosenLevel}.mp3`);
        playSoundSync(`/math/sounds/ducks/Ducks_inst_level${this.chosenLevel}.mp3`).on("complete", () => {
            this.enableHelp();
        });
    };

    startGame = async () => {
        this.root.gotoAndStop(1);
        await this.newQuestion();
        this.root.gotoAndPlay(1);
        await waitForEvent(this.root, "sayInst");
        this.isFirstGame && this.enableEvents && this.playInst();
    };

    newQuestion = async () => {
        this.defaultDuckCursor();
        this.resetQuestionProps();
        this.generateQuestion();
        this.chosenLevel === 3 ? this.initExactBalls() : this.initBalls();
        await this.ballsIn();
        this.enableBallHovering = true;
    };

    generateQuestion = () => {
        this.numOfDucksMissing = this.missingNum[getRandomNumber(0, this.missingNum.length - 1)];
        const startIndexSeries = getRandomNumber(0, 5);
        this.series = this.digits.slice(startIndexSeries, startIndexSeries + SERIES_TO_FILL);
        this.randomEmptyDucks();
    };

    randomEmptyDucks = () => {
        for (let index = 0; index < this.numOfDucksMissing; index++) {
            let ducknum;
            do {
                ducknum = getRandomNumber(0, 4);
            } while (this.ducksMissing.includes(ducknum));
            this.ducksMissing.push(ducknum);
        }
        this.initDucks();
    };

    initDucks = () => {
        for (let i = 0; i < 5; i++) {
            const duck = this.root[`duck${i + 1}`];
            const duckSeriesNum = this.series[i];

            if (!this.ducksMissing.includes(i)) {
                duck.ducks_number_mc.ducks_number_txt.text = duckSeriesNum;
                duck.door_mc.duck_txt_mc.txt_mc.text = duckSeriesNum;
            }
            duck.addEventListener("click", () => {
                if (!this.enableEvents) return;
                if (this.ducksMissing.includes(i)) {
                    this.clickDuck(duck, i, duckSeriesNum);
                }
            });
        }
    };

    pointerDuckCursor = () => {
        for (let i = 0; i < this.ducksMissing.length; i++) {
            const duck = this.root[`duck${this.ducksMissing[i] + 1}`];
            duck.cursor = "pointer";
        }
    };

    defaultDuckCursor = () => {
        for (let i = 0; i < 5; i++) {
            const duck = this.root[`duck${i + 1}`];
            duck.cursor = "default";
        }
    };

    clickDuck = async (duck: MC, duckNum: number, duckSeriesNum: number) => {
        if (!this.openBall) return;
        this.disableHelp();
        this.enableEvents = false;
        if (duckSeriesNum === this.openBallNum) {
            const pompa = this.root[`pompaAll${duckNum}`];
            duck.cursor = "default";
            this.removeDuckFromMissing(duckNum);
            if (this.ducksMissing.length === 0) {
                this.sayGood();
            }
            pompa.ball_txt.n_txt.text = duckSeriesNum.toString();
            duck.door_mc.gotoAndPlay("open");
            pompa.gotoAndPlay(`takeBall${this.openBallIndex}`);
            await waitForEvent(pompa, "taken");
            pompa.gotoAndPlay(`taken${this.openBallIndex}`);
            this.openBall.gotoAndPlay("close_no_ball");
            this.openBall.ball_g_mc_1.visible = false;
            this.openBall.ball_g_mc.visible = false;
            this.openBall.shadow_mc.visible = false;
            this.openBall.ball_txt.n_txt.text = "";
            this.openBall.ball1_txt.n_txt.text = "";
            await waitForEvent(pompa, "end");
            pompa.gotoAndStop(0);
            pompa.gotoAndStop("wait");
            duck.door_mc.gotoAndPlay("close");
            await waitForEvent(duck.door_mc, "closed");
            duck.door_mc.gotoAndStop("closed");
            duck.ducks_number_mc.ducks_number_txt.text = duckSeriesNum;
            duck.door_mc.duck_txt_mc.txt_mc.text = duckSeriesNum;
            if (this.openBall) {
                this.openBall.enableHover = true;
            }
            this.allowHoverBtn = this.openBall;
            this.openBall = null;
            this.openBallIndex = -1;
            this.enableEvents = true;
            this.ducksMissing.length === 0 ? this.endTurn() : this.correctAnswers === 0 && this.enableHelp();
        } else {
            duck.door_mc.gotoAndPlay("open");
            this.playWrong();
            await waitForEvent(duck.door_mc, "endOpen");
            duck.door_mc.gotoAndPlay("close");
            this.openBall.gotoAndPlay("close");
            if (this.openBall) {
                this.openBall.enableHover = true;
            }
            this.allowHoverBtn = this.openBall;
            this.openBall = null;
            this.openBallIndex = -1;
            this.correctAnswers === 0 && this.enableHelp();
            this.enableEvents = true;
        }
    };

    playWrong = async () => {
        const num = getRandomNumber(1, 2);
        await loadSound(`/math/sounds/frogs/wrong${num}.mp3`);
        await playSound(`/math/sounds/frogs/wrong${num}.mp3`);
    };

    endTurn = async () => {
        this.finishedTurn = true;
        this.defaultDuckCursor();
        this.addPrize("prize_duck");
        this.correctAnswers++;
        if (this.correctAnswers === 5) this.inEnd = true;
        await this.duckJumpAnim();
        this.enableEvents = false;
        await this.ballsOut();
        this.correctAnswers === 5 && (await this.ducksDive());
        this.correctAnswers < 5 ? this.newQuestion() : this.endGame();
    };

    removeDuckFromMissing = (duckNum: number) => {
        const num = this.ducksMissing.indexOf(duckNum);
        this.ducksMissing.splice(num, 1);
    };

    duckJumpAnim = async () => {
        await loadSound(`/math/sounds/ducks/Kryawav.mp3`);
        await loadSound(`/math/sounds/ducks/jump2.mp3`);

        playSound(`/math/sounds/ducks/Kryawav.mp3`);
        for (let i = 0; i < 5; i++) {
            const duck = this.root[`duck${i + 1}`];
            duck.gotoAndPlay("jump");
            if (i !== 0) {
                playSound(`/math/sounds/ducks/jump2.mp3`);
            }
            await waitForEvent(duck, "endAnim");
            duck.ducks_number_mc.ducks_number_txt.text = "";
            duck.door_mc.duck_txt_mc.txt_mc.text = "";
        }
    };

    sayGood = async () => {
        const num = getRandomNumber(1, 9);
        playSound(`m_good${num}`);
    };

    ballsIn = () => {
        let promises: any[] = [];
        for (let index = 0; index < this.ballsVisible.length; index++) {
            const num = this.ballsVisible[index];
            const ball = this.root[`ball${num}`];
            ball.gotoAndStop("enter");
            ball.visible = true;
            ball.ball_g_mc.visible = true;
            ball.shadow_mc.visible = true;
            ball.gotoAndPlay("enter");
            promises.push(
                waitForEvent(ball, "finishedEnter").then(() => {
                    ball.gotoAndStop("wait");
                })
            );
        }
        return Promise.all(promises);
    };

    ballsOut = () => {
        let promises: any[] = [];
        for (let index = 0; index < this.ballsVisible.length; index++) {
            const num = this.ballsVisible[index];
            const ball = this.root[`ball${num}`];
            ball.gotoAndStop("remove");
            ball.gotoAndPlay("remove");
            promises.push(waitForEvent(ball, "finishedRemove"));
        }
        return Promise.all(promises);
    };

    addBallEvents = (ball: MC, ballNum: number, ballIndex: number) => {
        let enableHover = true;
        ball.cursor = "pointer";
        ball.addEventListener("rollover", async () => {
            if (!this.enableEvents) return;
            if (ball === this.allowHoverBtn) {
                enableHover = true;
                this.openBallIndex = -1;
                this.allowHoverBtn = null;
            }
            if (this.enableBallHovering && enableHover) {
                ball.gotoAndPlay("over");
                await waitForEvent(ball, "finishedOver");
                ball.gotoAndStop("wait");
            }
        });
        ball.addEventListener("click", () => {
            if (!this.enableEvents) return;
            if (this.openBall) {
                this.openBall.gotoAndPlay("close");
                this.defaultDuckCursor();
                this.allowHoverBtn = this.openBall;
            }
            if (this.openBallIndex === ballIndex) {
                ball.gotoAndPlay("close");
                this.defaultDuckCursor();
                this.openBall = null;
                this.openBallNum = -1;
                enableHover = true;
                this.openBallIndex = -1;
            } else {
                ball.gotoAndPlay("open");
                this.pointerDuckCursor();
                this.openBall = ball;
                this.openBallNum = ballNum;
                this.openBallIndex = ballIndex;
                enableHover = false;
            }
        });
    };

    initBalls = () => {
        for (let index = 0; index < 11; index++) {
            const ball = this.root[`ball${index}`];
            ball.visible = false;

            this.ballsVisible.push(index);
            ball.txt_roll_grow.txt_roll.n_txt.text = index;
            ball.ball_txt.n_txt.text = index;
            ball.ball1_txt.n_txt.text = index;
            ball.ball_g_mc_1.visible = true;
            ball.ball_g_mc.visible = true;
            ball.shadow_mc.visible = true;
            this.addBallEvents(ball, index, index);
        }
    };

    initExactBalls = () => {
        let seriesIndex = 0;
        const mixedSeries = getRandomArr(0, this.series.length - 1, this.series.length);
        [0, 3, 7, 10].forEach((index: number) => {
            const ball = this.root[`ball${index}`];
            this.ballsVisible.push(index);
            if (!this.ducksMissing.includes(mixedSeries[seriesIndex])) {
                seriesIndex++;
            }
            const ballNum = this.series[mixedSeries[seriesIndex]];
            ball.txt_roll_grow.txt_roll.n_txt.text = ballNum;
            ball.ball_txt.n_txt.text = ballNum;
            ball.ball1_txt.n_txt.text = ballNum;
            ball.ball_g_mc_1.visible = true;
            ball.ball_g_mc.visible = true;
            ball.shadow_mc.visible = true;
            this.addBallEvents(ball, ballNum, index);
            seriesIndex++;
        });
    };

    ducksDive = async () => {
        let promises: any[] = [];
        this.inEnd = true;
        for (let i = 0; i < 5; i++) {
            const duck = this.root[`duck${i + 1}`];
            duck.gotoAndPlay("dive");
            promises.push(
                waitForEvent(duck, "endAnim").then(() => {
                    duck.ducks_number_mc.ducks_number_txt.text = "";
                    duck.door_mc.duck_txt_mc.txt_mc.text = "";
                })
            );
            await waitForTimer(600);
        }
        return Promise.all(promises);
    };

    endGame = async () => {
        this.cleanPrizes();
        this.correctAnswers = 0;
    };

    positionTexts = () => {
        for (let index = 0; index < 11; index++) {
            const ball = this.root[`ball${index}`];
            ball.gotoAndStop(0);
            ball.visible = false;
            ball.txt_roll_grow.txt_roll.n_txt.y += 10;
            ball.ball_txt.n_txt.y += 10;
            ball.ball1_txt.n_txt.y += 10;
        }
        for (let index = 0; index < 5; index++) {
            const duck = this.root[`duck${index + 1}`];
            duck.ducks_number_mc.ducks_number_txt.y += 10;
            duck.door_mc.duck_txt_mc.txt_mc.y += 10;
            duck.ducks_number_mc.ducks_number_txt.text = " ";
            duck.door_mc.duck_txt_mc.txt_mc.text = " ";
            duck.door_mc.gotoAndStop(0);
        }
        for (let index = 0; index < 5; index++) {
            const pompa = this.root[`pompaAll${index}`];
            pompa.ball_txt.n_txt.y += 10;
        }
    };

    visibiltyChange = () => {
        this.isVisible = !this.isVisible;
        if (this.isVisible) {
            if (!this.isChooserUP && this.chosenLevel >= 1) {
                if (!this.finishedEnter || this.inEnd) {
                    this.resetGame();
                } else if (this.finishedTurn) {
                    for (let i = 0; i < 5; i++) {
                        const duck = this.root[`duck${i + 1}`];
                        duck.gotoAndStop("on");
                    }
                    this.newQuestion();
                }
            }
        } else {
            if (!this.finishedEnter || this.finishedTurn) {
                this.stopGame();
            }
        }
    };

    loaded = () => {
        this.positionTexts();
        window.addEventListener("visibilitychange", this.visibiltyChange);
    };

    unload = () => {
        window.removeEventListener("visibilitychange", this.visibiltyChange);
    };
}
