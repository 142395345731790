import { MC } from "../../animate/utils";
import { DubbyBaseGame } from "./DubbyBaseGame";

export default class DubbyKitchenGame extends DubbyBaseGame {
    root: MC;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    hidePreloader: () => void;
    objects: string[] = [];
    genericObjects = ["Fridge", "Microwave", "Kettle", "Oven", "Mixer", "Table", "Sink", "Window"];
    buildObjects = ["fridge", "microwave", "kettle", "oven", "mixer", "KitchenTable", "sink", "window"];
    objectsName = ["refrigerator", "microwave", "kettle", "oven", "mixer", "table", "sink", "window"];
    loaded = () => {
        super.loaded();
        this.hidePreloader();
        this.root.visible = true;
    };
    beeMode = () => {
        this.currentRoom = "KitchenRoom";
        this.objects = [...this.genericObjects];
        super.beeMode();
    };
    whatInMode = async () => {
        this.objects = [...this.genericObjects];
        super.whatInMode();
    };
    buildMode = async () => {
        this.objects = [...this.buildObjects];
        super.buildMode();
    };
}
