import { loadSound } from "../animate/sound_utils";
import { MC, waitFor, playSound, waitForEvent, waitForTimer, asyncTween, playSoundSync } from "../animate/utils";
import { getRandomNumber } from "../utils/MathUtils";
import { SpecialGameBasic } from "./SpecialGameBasic";

export class DiffrencesBasic extends SpecialGameBasic {
    root: MC;
    private counter: number = 0;
    private picNum = 1;
    private arrOrder: number[] = [1, 2, 3];
    playAgain: string;
    name: string;
    instructions: createjs.AbstractSoundInstance;

    loaded = async (root: MC, name: string) => {
        this.root = root;
        this.name = name;
        (window as any).game = root;
        await waitFor(() => this.root);
        this.root.gotoAndStop(4);
        this.setupView();
        this.setupFrame();
        this.resetGame();
        this.addClickListener();
        this.initExit();
        this.initIconMc();
        this.enableHelp();
        this.initHelp();
        this.initReset(this.resetGame);
        this.initSpeker();
        await this.loadSounds();
        await this.playInstSound();
        await this.setBgSound();
        this.bgSound.volume = 0.3;
        this.initDif();
        this.initEndMassesge();
    };

    setupFrame = () => {
        this.helpSound = "/special_games/sucot/sounds/instruction.mp3";
        this.instructionSound = "/special_games/sucot/sounds/instruction.mp3";
        this.bGSound =
            this.name === "sucot" ? "/special_games/sucot/sounds/music.mp3" : "/week_games/differences/sounds/bg.mp3"; //to do change according to game name
        this.playAgain = "/special_games/sucot/sounds/playAgain.mp3";
        this.disableHelpInClicking = true;
    };

    loadSounds = async () => {
        await loadSound("/special_games/diffrences_sounds/miss.mp3");
        await loadSound("/special_games/diffrences_sounds/match.mp3");
        await loadSound("/special_games/diffrences_sounds/enter1.mp3");
    };

    addClickListener = () => {
        for (let i = 1; i <= 6; i++) {
            this.root.PictureStore["pic" + i].addEventListener("click", () => {
                console.log("in click");
                playSound("/special_games/diffrences_sounds/miss.mp3");
            });
        }
    };
    initEndMassesge = () => {
        this.root.EndMessage.TryAgain.addEventListener("click", () => {
            this.resetGame();
            this.root.EndMessage.gotoAndStop("hide");
        });
    };
    initDif = () => {
        for (let k = 0; k < 2; k++) {
            for (let i = 0; i < 3; i++) {
                for (let j = 0; j < 7; j++) {
                    this.root.PictureStore["difference" + k + "_" + j + "_" + i].addEventListener("click", (e: any) => {
                        console.log("in find difference");
                        this.findDif(j, i);
                    });
                }
            }
        }
    };
    findDif = async (i: number, j: number) => {
        if (this.root.PictureStore["difference0_" + i + "_" + j].find) return;
        this.root.PictureStore["difference0_" + i + "_" + j].find = true;
        this.root.PictureStore["difference1_" + i + "_" + j].find = true;
        this.root.PictureStore["marker0_" + i + "_" + j].gotoAndPlay(1);
        this.root.PictureStore["marker1_" + i + "_" + j].gotoAndPlay(1);
        this.root.prizes["prize" + this.counter].gotoAndStop(1);
        this.counter++;
        playSound("/special_games/diffrences_sounds/match.mp3");
        await waitForEvent(this.root.PictureStore["marker0_" + i + "_" + j], "end");
        this.root.PictureStore["marker0_" + i + "_" + j].gotoAndStop(7);
        this.root.PictureStore["marker1_" + i + "_" + j].gotoAndStop(7);
        if (this.counter === 7) {
            this.newPic();
        }
    };
    newPic = async () => {
        await waitForTimer(1000);
        if (!this.arrOrder.length) {
            this.root.EndMessage.gotoAndStop("regular");
            await loadSound(this.playAgain);
            playSound(this.playAgain);
            this.root.EndMessage.alpha = 0;
            await asyncTween(this.root.EndMessage, { alpha: 1 }, 1000);
        } else {
            this.nextPic();
        }
    };

    setupView = () => {
        this.root.EndMessage.gotoAndStop("hide");
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 7; j++) {
                this.root.PictureStore["marker1_" + j + "_" + i].gotoAndStop(0);
                this.root.PictureStore["marker0_" + j + "_" + i].gotoAndStop(0);
                this.root.PictureStore["difference1_" + j + "_" + i].alpha = 0.01;
                this.root.PictureStore["difference0_" + j + "_" + i].alpha = 0.01;
                this.root.prizes["prize" + j].gotoAndStop(0);
            }
        }
    };
    resetGame = () => {
        this.instructions && this.instructions.stop();
        this.instructions = playSoundSync("/special_games/sucot/sounds/instruction.mp3");
        this.arrOrder = [1, 2, 3];
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 7; j++) {
                this.root.PictureStore["marker1_" + j + "_" + i].gotoAndStop(0);
                this.root.PictureStore["marker0_" + j + "_" + i].gotoAndStop(0);
                this.root.PictureStore["difference1_" + j + "_" + i].find = false;
                this.root.PictureStore["difference0_" + j + "_" + i].find = false;
            }
        }
        this.nextPic();
    };

    nextPic = () => {
        playSound("/special_games/diffrences_sounds/enter1.mp3");
        this.picNum = getRandomNumber(0, this.arrOrder.length - 1);
        this.root.PictureStore.gotoAndStop(this.arrOrder[this.picNum]);
        this.arrOrder.splice(this.arrOrder.indexOf(this.arrOrder[this.picNum]), 1);

        this.counter = 0;
        this.setupView();
        for (let i = 0; i < 7; i++) {
            this.root.prizes["prize" + i].gotoAndStop(0);
        }
    };
}
