import React, { useEffect } from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { TrampolineGame } from "./TrampolinGame";
import additionalSounds from "./TrampolineSounds";

const TrampolineComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new TrampolineGame(frame));

    useEffect(() => {
        frame.toggleMainCounter(true);
    }, [frame]);

    return (
        <AnimateCC
            base="/lunapark/trampoline/"
            animationName="trampoline"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default TrampolineComponent;
