import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { SpacerocksGame } from "./SpacerocksGame";

const SpacerocksGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new SpacerocksGame());
    return (
        <>
            <AnimateCC
                base="/week_games/spacerocks_game"
                animationName="spacerocks"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                }}
                shiftY={5}
                shiftX={30}
                scale={0.95}
            />
        </>
    );
};
export default SpacerocksGameComponent;
