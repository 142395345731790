import React, { useEffect, useState } from "react";
import AnimateCC from "../../animate";
import { LetterActivitiesdGame } from "./LetterActivitiesGame";
import { colorLetterSoounds, drawLetterCountSound } from "./sounds_letterActivities";
import { routes } from "../../navigation/routesPath";
import {
    getGeneralSounds,
    getHelpSoundsByLetter,
    getInstSoundsByLetter,
    getMoreSoundsByLetter,
} from "./childrenInBox/childrenInBoxesSounds";
import { getMayaFeedbackWithoutEffect } from "../common/feedBacksSounds";
import { getMayaSoundsByLetter } from "../common/lettersSounds";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import crabSounds from "./crabs/crabSounds.json";
import { getFrameSounds } from "./frameSounds";
import woodBoxes_sounds from "./woodBoxes/woodBoxes_sounds.json";
import { MC } from "../../animate/utils";

import VideoActivitisComponent from "./video/VideoActivitiesComponent";
import { useLettersContext } from "../../context/LettersContext";
import { LettersSongComponent } from "../letters_song/LettersSongComponent";
import { SodRoute } from "../../navigation/PrivateRoot";
import { AirplaneEntersComponent } from "./AirplaneEntersComponent";
import SpecialWordComponent from "../FancyWords/specialWordComponent";
import CreationComponent from "../../creation_corner/CreationComponent";
import { getSoundsByLetter } from "./childrenInBox/NamesSounds";

const FRAME_SIZE = {
    frameWidth: 1200,
    frameHeight: 900,
};

const LetterActivitiesComponent: React.FC<{ letterNumber: number }> = ({ letterNumber }) => {
    const { pathname } = useLocation();
    const matchVideo = useRouteMatch(routes.letters.activities.video);
    const matchFancyWord = useRouteMatch(routes.letters.activities.fancy_word);
    const matchCreation = useRouteMatch(routes.letters.activities.creation);
    const frame = React.useRef(new LetterActivitiesdGame(letterNumber));
    const [frameLoaded, setFrameLoaded] = useState(false);
    const lettersContext = useLettersContext();

    useEffect(() => frame.current.showSwitcher(), [pathname]);

    return (
        <>
            {frameLoaded && (
                <Switch>
                    <SodRoute path={routes.letters.activities.enter_exit}>
                        <AirplaneEntersComponent frame={frame.current} letterNumber={letterNumber} />
                    </SodRoute>
                    <SodRoute
                        path={routes.letters.activities.draw}
                        render={() => (
                            <AnimateCC
                                key="draw"
                                base="/letters_island/draw_letter"
                                animationName={`draw_letter${letterNumber + 1}`}
                                onLoaded={frame.current.drawLetterLoaded}
                                additionalSounds={drawLetterCountSound(letterNumber)}
                                shiftX={190}
                                shiftY={150}
                                {...FRAME_SIZE}
                            />
                        )}
                    />
                    <SodRoute
                        path={routes.letters.activities.color}
                        render={() => (
                            <AnimateCC
                                key="color"
                                base="/letters_island/color_letter"
                                animationName="color_letter"
                                onLoaded={frame.current.colorLoaded}
                                additionalSounds={colorLetterSoounds(letterNumber)}
                                shiftX={225}
                                shiftY={80}
                                {...FRAME_SIZE}
                            />
                        )}
                    />
                    <SodRoute
                        path={routes.letters.activities.children_in_boxes}
                        render={() => (
                            <AnimateCC
                                key="cbox"
                                base="/letters_island/children_in_box"
                                animationName="children_in_box"
                                onLoaded={frame.current.childInBoxLoaded}
                                additionalSounds={[
                                    ...getGeneralSounds(),
                                    ...getMayaSoundsByLetter(letterNumber + 1),
                                    ...getSoundsByLetter(letterNumber + 1),
                                    ...getInstSoundsByLetter(letterNumber + 1),
                                    ...getHelpSoundsByLetter(letterNumber + 1),
                                    ...getMoreSoundsByLetter(letterNumber + 1),
                                    ...getMayaFeedbackWithoutEffect(),
                                ]}
                                shiftX={155}
                                shiftY={132}
                                scale={1.05}
                                {...FRAME_SIZE}
                            />
                        )}
                    />
                    <SodRoute
                        path={routes.letters.activities.wood_boxes}
                        render={() => (
                            <AnimateCC
                                key="wood"
                                base="/letters_island/wood_boxes"
                                animationName="wood_boxes"
                                onLoaded={frame.current.woodBoxesLoaded}
                                additionalSounds={woodBoxes_sounds}
                                shiftX={128}
                                shiftY={130}
                                scale={1.1}
                                {...FRAME_SIZE}
                            />
                        )}
                    />
                    <SodRoute
                        path={routes.letters.activities.crabs}
                        render={() => (
                            <AnimateCC
                                key="crabs"
                                base="/letters_island/crabs"
                                animationName="Crabs"
                                onLoaded={(mc: MC) => frame.current.crabsLoaded(mc, lettersContext)}
                                additionalSounds={[...getMayaFeedbackWithoutEffect(), ...crabSounds]}
                                shiftX={168}
                                shiftY={130}
                                scale={1.05}
                                {...FRAME_SIZE}
                            />
                        )}
                    />
                    <SodRoute path={routes.letters.activities.find_letter}>
                        <VideoActivitisComponent frame={frame.current} />;
                    </SodRoute>
                    <SodRoute
                        path={routes.letters.activities.final_letter}
                        render={() => (
                            <AnimateCC
                                key="final_letter"
                                base="/letters_island/final_letter"
                                animationName={`finalLetter${letterNumber + 1}`}
                                onLoaded={frame.current.finalLettersLoaded}
                                additionalSounds={[...getMayaFeedbackWithoutEffect()]}
                                shiftX={160}
                                shiftY={140}
                                scale={1.05}
                                {...FRAME_SIZE}
                            />
                        )}
                    />
                    <SodRoute
                        path={routes.letters.activities.final_draw}
                        render={() => (
                            <AnimateCC
                                key="final_draw"
                                base="/letters_island/draw_letter"
                                animationName={`f_letter_draw`}
                                additionalSounds={drawLetterCountSound(letterNumber)}
                                onLoaded={frame.current.finalDrawsLoaded}
                                shiftX={168}
                                shiftY={130}
                                scale={1.05}
                                {...FRAME_SIZE}
                            />
                        )}
                    />
                    <SodRoute path={routes.letters.activities.video}>
                        <LettersSongComponent />
                    </SodRoute>
                    <SodRoute path={routes.letters.activities.fancy_word}>
                        <SpecialWordComponent />
                    </SodRoute>
                    <SodRoute path={routes.letters.activities.creation}>
                        <CreationComponent />
                    </SodRoute>
                </Switch>
            )}
            <AnimateCC
                visible={!(matchVideo || matchFancyWord || matchCreation)}
                base="/letters_island/activities_frame"
                animationName="activities_frame"
                onLoaded={(mc: MC) => {
                    setFrameLoaded(true);
                    frame.current.frameLoaded(mc);
                }}
                additionalSounds={getFrameSounds()}
            />
        </>
    );
};
export default LetterActivitiesComponent;
