import { MC, setupStage, waitForEvent } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
export class NumberFourGame {
    private root: MC;
    private finish = true;
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).EightGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.gotoAndStop(2);
        this.root.man.gotoAndStop(0);
        this.root.man.shirt.gotoAndStop(getRandomNumber(0, 3));
        this.root.man.hat.gotoAndStop(getRandomNumber(0, 3));
        this.root.bongo_1.addEventListener("click", async () => {
            if (this.finish) {
                this.finish = false;
                this.root.man.gotoAndPlay(1);
                this.root.man.x = this.root.bongo_1.x;
                await waitForEvent(this.root, "finish");
                this.finish = true;
            }
        });
        this.root.bongo_2.addEventListener("click", async () => {
            if (this.finish) {
                this.finish = false;
                this.root.man.gotoAndPlay(21);
                this.root.man.x = this.root.bongo_2.x;
                await waitForEvent(this.root, "finish");
                this.finish = true;
            }
        });
        this.root.bongo_3.addEventListener("click", async () => {
            if (this.finish) {
                this.finish = false;
                this.root.man.gotoAndPlay(46);
                this.root.man.x = this.root.bongo_3.x;
                await waitForEvent(this.root, "finish");
                this.finish = true;
            }
        });
        this.root.bongo_4.addEventListener("click", async () => {
            if (this.finish) {
                this.finish = false;
                this.root.man.gotoAndPlay(83);
                this.root.man.x = this.root.bongo_4.x;
                await waitForEvent(this.root, "finish");
                this.finish = true;
            }
        });
    };
}
