import React, { FC } from "react";
import ReactDOM from "react-dom";
import { UserContextProvider } from "./context/UserContext";
import { HashRouter, MemoryRouter } from "react-router-dom";

import { Root } from "./navigation/Root";
import "./index.css";
import { MovieContextProvider } from "./context/MovieContext";
import { LettersContextProvider } from "./context/LettersContext";
import { IframeContextProvider } from "./context/IframeContext";
import "./utils/VersionInfo";
import { LunaparkContextProvider } from "./context/LunaparkContext";

const Router: FC<any> = (props) => {
    if (localStorage.getItem("debug-router")) {
        return <HashRouter {...props} />;
    } else {
        return <MemoryRouter {...props} />;
    }
};

ReactDOM.render(
    <React.StrictMode>
        <IframeContextProvider>
            <UserContextProvider>
                <LettersContextProvider>
                    <MovieContextProvider>
                        <LunaparkContextProvider>
                            <Router>
                                <Root />
                            </Router>
                        </LunaparkContextProvider>
                    </MovieContextProvider>
                </LettersContextProvider>
            </UserContextProvider>
        </IframeContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

createjs.Ticker.timingMode = createjs.Ticker.RAF_SYNCHED;
