import { MC, setupStage } from "../../../animate/utils";
import { ShadowGame } from "../ShadowGame";

const AREA_WIDTH = 24;
const AREA_HEIGHT = 24;
const NUM_ELEMENTS = 7;
const BEGIN = 4;

const WIDTHS = [139.05, 109.75, 123.65, 247.6, 142.65, 115.65, 115.25];
const HEIGHTS = [107.45, 137.15, 115.4, 98.35, 112.05, 123.65, 109.2];
export class VeggiesGame {
    private root: MC;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        const game = new ShadowGame(
            this.root,
            AREA_WIDTH,
            AREA_HEIGHT,
            NUM_ELEMENTS,
            WIDTHS,
            HEIGHTS,
            BEGIN
        );
        game.start();
    };
}
