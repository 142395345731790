export const lettersMaya = [
    {
        src: "sounds/Letters/she/letter1.mp3",
        id: "letter1",
    },
    {
        src: "sounds/Letters/she/letter2.mp3",
        id: "letter2",
    },
    {
        src: "sounds/Letters/she/letter3.mp3",
        id: "letter3",
    },
    {
        src: "sounds/Letters/she/letter4.mp3",
        id: "letter4",
    },
    {
        src: "sounds/Letters/she/letter5.mp3",
        id: "letter5",
    },
    {
        src: "sounds/Letters/she/letter6.mp3",
        id: "letter6",
    },
    {
        src: "sounds/Letters/she/letter7.mp3",
        id: "letter7",
    },
    {
        src: "sounds/Letters/she/letter8.mp3",
        id: "letter8",
    },
    {
        src: "sounds/Letters/she/letter9.mp3",
        id: "letter9",
    },
    {
        src: "sounds/Letters/she/letter10.mp3",
        id: "letter10",
    },
    {
        src: "sounds/Letters/she/letter11.mp3",
        id: "letter11",
    },
    {
        src: "sounds/Letters/she/letter12.mp3",
        id: "letter12",
    },
    {
        src: "sounds/Letters/she/letter13.mp3",
        id: "letter13",
    },
    {
        src: "sounds/Letters/she/letter14.mp3",
        id: "letter14",
    },
    {
        src: "sounds/Letters/she/letter15.mp3",
        id: "letter15",
    },
    {
        src: "sounds/Letters/she/letter16.mp3",
        id: "letter16",
    },
    {
        src: "sounds/Letters/she/letter17.mp3",
        id: "letter17",
    },
    {
        src: "sounds/Letters/she/letter18.mp3",
        id: "letter18",
    },
    {
        src: "sounds/Letters/she/letter19.mp3",
        id: "letter19",
    },
    {
        src: "sounds/Letters/she/letter20.mp3",
        id: "letter20",
    },
    {
        src: "sounds/Letters/she/letter21.mp3",
        id: "letter21",
    },
    {
        src: "sounds/Letters/she/letter22.mp3",
        id: "letter22",
    },
];

const menuSounds = [
    {
        src: "sounds/Icecream.mp3",
        id: "icecream",
    },
    {
        src: "sounds/Welcome.mp3",
        id: "welcome",
    },
    {
        src: "sounds/song.mp3",
        id: "tv",
    },
    {
        src: "sounds/fancy.mp3",
        id: "book",
    },
    {
        src: "sounds/bg.mp3",
        id: "bg",
    },
    {
        src: "sounds/forward.mp3",
        id: "forward",
    },
    {
        src: "sounds/backward.mp3",
        id: "backward",
    },
    {
        src: "sounds/review.mp3",
        id: "review",
    },
];

const allSounds = menuSounds.concat(lettersMaya);

export default allSounds;
