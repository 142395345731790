import React, {
    CSSProperties,
    forwardRef,
    ForwardRefRenderFunction,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import "./video.css";

export interface VideoPlayer {
    play(): Promise<void>;
    pause(): void;

    pausePlay(): void;

    restartVideo(): void;

    muteVideo(): void;

    getVideoDuration(): number;

    getCurrentTime(): number;
    setCurrentTime(currentTime: number): void;

    getVideoState(): number;
}

const VideoPlayerComponentInternal: ForwardRefRenderFunction<
    VideoPlayer,
    {
        autoplay?: boolean;
        src?: string;
        style?: CSSProperties;
        shiftX?: number;
        shiftY?: number;
        frameWidth: number;
        frameHeight: number;
        width: number;
        onPause?: () => void;
        onCanPlay?: () => void;
        onPlay?: () => void;
        onEndFunction: () => void;
        onTimeUpdate?: () => void;
        height: number;
    }
> = ({ src, ...props }, ref) => {
    const myVideo = useRef<HTMLVideoElement>();

    useImperativeHandle(ref, () => ({
        play() {
            return myVideo.current.play().catch((ignore) => {});
        },
        pause() {
            myVideo.current.pause();
        },
        pausePlay: () => {
            if (myVideo.current.paused) {
                myVideo.current.play().catch((ignore) => {});
            } else {
                myVideo.current.pause();
            }
        },
        restartVideo: () => {
            myVideo.current.currentTime = 0;
            myVideo.current.pause();
        },
        muteVideo: () => {
            myVideo.current.muted = !myVideo.current.muted;
        },
        getVideoDuration: () => {
            return myVideo.current.duration;
        },
        getCurrentTime: () => {
            return myVideo.current.currentTime;
        },
        setCurrentTime: (currentTime: number) => {
            myVideo.current.currentTime = currentTime;
        },
        getVideoState: () => {
            return myVideo.current.readyState;
        },
    }));

    const [style, setStyle] = useState<any>({});

    const resizeVideo = useCallback(() => {
        const iw = window.innerWidth;
        const ih = window.innerHeight;

        const ratio = Math.min(iw / props.frameWidth, ih / props.frameHeight);

        const w = props.width * ratio;
        const h = props.height * ratio;

        const shiftX = (props.shiftX || 0) * ratio;
        const shiftY = (props.shiftY || 0) * ratio;

        setStyle({
            position: "absolute",
            left: shiftX + (iw - w) / 2 + "px",
            top: shiftY + (ih - h) / 2 + "px",
            width: w + "px",
            height: h + "px",
        });
    }, [props.frameHeight, props.frameWidth, props.height, props.shiftX, props.shiftY, props.width]);

    useEffect(() => {
        window.addEventListener("resize", resizeVideo);
        resizeVideo();

        return () => {
            window.removeEventListener("resize", resizeVideo);
        };
    }, [resizeVideo]);

    return (
        <video
            autoPlay={props.autoplay}
            style={{ ...props.style, ...style }}
            src={src}
            ref={myVideo}
            onCanPlay={props.onCanPlay}
            onPlay={props.onPlay}
            onPause={props.onPause}
            onEnded={props.onEndFunction}
            onTimeUpdate={() => {
                props.onTimeUpdate && props.onTimeUpdate();
            }}
        />
    );
};

export const VideoPlayerComponent = forwardRef(VideoPlayerComponentInternal);
