import { MC, waitForEvent, playSound } from "../../animate/utils";

export class AirplaneEnters {
    root: MC;
    constructor(root: createjs.MovieClip) {
        this.root = root;
        (window as any).airplaneEnters = root;
    }

    loaded = async () => {
        waitForEvent(this.root.animMvc, "maya_appeared").then(() => {
            playSound("enter_letter");
        });
        this.root.gotoAndPlay(0);
        return waitForEvent(this.root, "animationEnd");
    };

    unload = () => {};
}
