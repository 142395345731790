const jsonData = require("./specialWords.json");

export function getWordForLetter(letter) {
    let wordToReturn, definitionToReturn, firstLetter;
    jsonData.Words.Word.forEach((word) => {
        if (word.sLetter === letter) {
            wordToReturn = word.sWord;
            definitionToReturn = word.sDefinition;
            firstLetter = word.sLetter;
        }
    });
    return { wordToReturn, definitionToReturn, firstLetter };
}
