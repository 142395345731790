const tooltip_dir: String = "sounds/tooltips";
export const additionalSounds = [
    {
        src: "sounds/menuloop.mp3",
        id: "bgSound",
    },
    {
        src: "../sounds/tooltips/exit_site.mp3",
        id: "exit_site",
    },
    {
        src: tooltip_dir + "/my_creations.mp3",
        id: "my_creations",
    },
    {
        src: tooltip_dir + "/creation.mp3",
        id: "creations",
    },
    {
        src: tooltip_dir + "/luna_park.mp3",
        id: "lunapark",
    },
    {
        src: tooltip_dir + "/Bible1.mp3",
        id: "bible",
    },
    {
        src: tooltip_dir + "/letters.mp3",
        id: "letters",
    },
    {
        src: tooltip_dir + "/math_games.mp3",
        id: "math",
    },
    {
        src: tooltip_dir + "/mishak_hashavua.mp3",
        id: "weekGame",
    },
    {
        src: tooltip_dir + "/newgame.mp3",
        id: "newGame",
    },
    {
        src: tooltip_dir + "/english.mp3",
        id: "english",
    },
    {
        src: tooltip_dir + "/music.mp3",
        id: "music",
    },
    {
        src: tooltip_dir + "/video_section.mp3",
        id: "video",
    },
    {
        src: tooltip_dir + "/dubi.mp3",
        id: "dubi",
    },
    {
        src: tooltip_dir + "/coloring_pages.mp3",
        id: "paint",
    },
    {
        src: "sounds/random_invite1.mp3",
        id: "random_invite1",
    },
    {
        src: "sounds/random_invite2.mp3",
        id: "random_invite2",
    },
    {
        src: "sounds/random_invite3.mp3",
        id: "random_invite3",
    },
    {
        src: "sounds/random_invite4.mp3",
        id: "random_invite4",
    },
    {
        src: "sounds/random_invite5.mp3",
        id: "random_invite5",
    },
    {
        src: "sounds/random_invite6.mp3",
        id: "random_invite6",
    },
    {
        src: "sounds/random_invite7.mp3",
        id: "random_invite7",
    },
    {
        src: "sounds/random_invite8.mp3",
        id: "random_invite8",
    },
];
