import { MC, setupStage } from "../../../animate/utils";
import { ShadowGame } from "../ShadowGame";

const AREA_WIDTH = 125;
const AREA_HEIGHT = 170;
const NUM_ELEMENTS = 6;
const BEGIN = 9;

const WIDTHS = [129.05, 138.7, 205, 205, 138.8, 129.15];
const HEIGHTS = [174.9, 191.75, 123.8, 123.75, 191.8, 175.05];
export class FrenchHornsGame {
    private root: MC;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        const game = new ShadowGame(
            this.root,
            AREA_WIDTH,
            AREA_HEIGHT,
            NUM_ELEMENTS,
            WIDTHS,
            HEIGHTS,
            BEGIN
        );
        game.start();
    };
}
