import { loadSound } from "../../../animate/sound_utils";
import { MC, waitForEvent, stopAllSounds, playSound } from "../../../animate/utils";
import { SpecialGameBasic } from "../../SpecialGameBasic";

export class LetterRaceGame extends SpecialGameBasic {
    root: MC;
    exile: number = -2000; // out of stage
    initLevels: boolean = true;
    interval: number;
    intervalFrequence: number;

    outOfScreen: number = -850;
    objectsOutOfScreen: number = -100;
    wallWidth: number = 901.75;
    currentWall: MC;
    prizeInitX: number;
    prizeInitY: number;
    overlap: number = 20;
    prizeJump: number = 30; // how many px up (deep) prizes go
    playerJump: number = 30; // how many px up (deep) player goes
    prizeCastAway: number = 2000;
    soldierCastAway: number = 200;
    objectCastAway: number = 800;

    maxTop: number = 2;

    prizeLevels: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    soldierLevels: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    crashedSoldiers: boolean[] = [false, false, false, false, false, false, false, false, false, false];
    resetGame: boolean = false;

    playerHeightLevel: number = 0;
    correctItems: number = 0;
    prizeMinDistance: number = 80; // spacing issues
    soldierMinDistance: number = 80;
    prizesOnStage: number = 0;
    shouldProducePrizes: boolean = true;
    shouldProduceSoldiers: boolean = true;
    soldierInitX: number;
    soldierInitY: number;
    firstSoldier: boolean = true;

    wallIntervals: any;
    prizeIntervals: any[] = [];
    soldierIntervals: any[] = [];
    endInterval: any;
    lives: number = 3;
    playerInitX: number;
    playerInitY: number;

    playInst: boolean;
    doEndInterval: boolean = false;
    loaded = async (root: MC) => {
        this.root = root;
        this.root.gotoAndStop("game");
        this.root.player.gotoAndStop(0);
        console.log("stopping player in loaded");

        (window as any).race = this.root;
        this.setupFrame();
        this.playInst = true;
        this.openSelector();
        await waitForEvent(this.root, "selectorIn");
        const pannel = this.root.startClip;
        this.root.btn_levels.flag.gotoAndStop(`level_1`);
        this.initLevelBtns(pannel, true, this.chooseLevel);
        // this.initGameOverPannel();
        this.initLevels = true;
        this.root.endObject.x = this.objectCastAway * 2;
        this.initLevelSelector();
        this.initPlayerActions();
        this.initPositions();
        this.loadSounds();
    };

    loadSounds = async () => {
        await loadSound("/week_games/letter_race/sounds/good.mp3");
        await loadSound("/week_games/letter_race/sounds/bad.mp3");
        await loadSound("/week_games/letter_race/sounds/end.mp3");
    };

    setupFrame = () => {
        this.root.btnSpeaker.gotoAndStop(0);
        this.stopPrizes();
        this.helpSound = "/week_games/letter_race/sounds/Letters_help.mp3";
        this.instructionSound = "/week_games/letter_race/sounds/Letters.mp3";
        this.bGSound = "/week_games/letter_race/sounds/music.mp3";
        this.initExit();
        this.initHelp();
        this.initSpeker();
        // this.initIconMc();
        // this.disableSpeaker();
        this.enableHelp();
    };

    stopPrizes = () => {
        for (let index = 0; index < 8; index++) {
            const prize = this.root[`ball${index}`];
            prize.gotoAndStop("off");
        }
    };

    initLevelSelector = () => {
        this.btnOverAndOut(this.root.btn_levels, "/week_games/simon_game/sounds/Levels.mp3", "levels");
        this.root.btn_levels.addEventListener("click", () => {
            this.root.btn_levels.gotoAndStop("down");
            this.openSelector();
            this.resetGame = true;
        });
        // this.initLevelBtns(this.root,this.root.levelSelector,false,this.chooseLevel)
    };

    initGameOverPannel = () => {
        const pannel = this.root.bubbleEnd;
        this.initLevelBtns(pannel, true, this.chooseLevel);
    };

    initPlayerActions = () => {
        document.addEventListener("keydown", (e: any) => this.keyDown(e));
    };

    keyDown = (e: any) => {
        switch (e.key) {
            case "ArrowRight":
                if (this.root.player.x + this.playerJump < 730) this.root.player.x += this.playerJump;
                return;
            case "ArrowLeft":
                if (this.root.player.x - this.playerJump >= this.playerInitX) this.root.player.x -= this.playerJump;
                return;
            case "ArrowUp":
                console.log("playerHeightLevel", this.playerHeightLevel, "+1");
                if (this.playerHeightLevel + 1 < 3) {
                    console.log("in");

                    this.root.player.y -= this.playerJump;
                    this.playerHeightLevel += 1;
                }
                return;
            case "ArrowDown":
                if (this.playerHeightLevel - 1 >= 0) {
                    this.playerHeightLevel -= 1;
                    this.root.player.y += this.playerJump;
                }
                return;
        }
    };

    initPositions = () => {
        this.prizeInitX = this.root.prize0.x;
        this.prizeInitY = this.root.prize0.y;
        this.soldierInitX = this.root.soldier0.x;
        this.soldierInitY = this.root.soldier0.y;
        this.playerInitX = this.root.player.x;
        this.playerInitY = this.root.player.y;
    };

    openSelector = async () => {
        console.log("open selector");
        // this.disableHelp();
        this.clearIntervals();
        this.disableLevelChooser();
        this.disableSpeaker();
        stopAllSounds();
        this.root.startClip.gotoAndPlay("start");
        if (this.playInst) {
            this.playInst = false;
            await this.playInstSound();
        }
    };

    chooseLevel = async (level: number) => {
        console.log("in choose level");
        this.root.gotoAndStop("game");
        switch (level) {
            case 1:
                this.interval = 8;
                break;
            case 2:
                this.interval = 11;
                break;
            case 3:
                this.interval = 14;
                break;
        }
        this.enableLevelChooser();
        stopAllSounds();
        await this.setBgSound();
        this.enableSpeaker();
        this.bgSound.volume = 0.5;
        this.resetGame && this.resetGameProps();
        this.root.startClip.gotoAndStop(0);
        // this.root.bubbleEnd.gotoAndStop("stop");
        this.positionPlayer();
        console.log("moving player in choose level");

        this.shouldProducePrizes = true;
        this.shouldProduceSoldiers = true;
        this.moveBg();
        this.placePrizes();
        this.placeSoldiers();
    };

    positionPlayer = () => {
        this.root.player.x = this.playerInitX;
        this.root.player.y = this.playerInitY;
        this.playerHeightLevel = 0;
        this.root.player.gotoAndPlay("loop");
    };

    resetGameProps = () => {
        console.log("in resetGameProps");
        this.root.endObject.visible = false;
        this.root.gotoAndStop("game");
        this.clearIntervals();
        this.stopPrizes();
        this.correctItems = 0;
        this.crashedSoldiers = [];
        this.soldierLevels = [];
        this.prizeLevels = [];
        this.lives = 3;
        console.log("end reset game props");
    };

    moveBg = () => {
        this.wallIntervals = setInterval(() => {
            this.moveWall(this.root.wall0);
            this.moveWall(this.root.wall1);
            this.doEndInterval && this.endObjInterval();
        }, 50);
    };

    moveWall = (wall: MC) => {
        wall.x -= this.interval;
        if (wall.x < this.outOfScreen) {
            wall.x += this.wallWidth * 2 - 20;
        }
    };

    placePrizes = () => {
        for (let i = 0; i < 14; i++) {
            const prize = this.root[`prize${i}`];
            this.prizeLapInit(prize, i);
            this.prizeIntervals.push(
                setInterval(() => {
                    this.prizeEnterFrame(prize, i);
                }, 50)
            );
        }
    };

    prizeLapInit = (prize: MC, index: number) => {
        if (!this.shouldProducePrizes) {
            return;
        }

        let bHitTest = false;
        prize.visible = true;
        prize.x = this.prizeInitX + this.intRandom(this.prizeCastAway);
        // prize.x = getRandomNumber(0,800);
        const level = this.intRandom(this.maxTop + 1);
        prize.y = this.prizeInitY - level * this.prizeJump;
        prize.visible = true;
        this.prizeLevels[index] = level;
        for (let j = 0; j < 14; j++) {
            if (j === index) {
                continue;
            } else {
                const other_prize = this.root[`prize${j}`];
                if (Math.abs(other_prize.x - prize.x) < this.prizeMinDistance) {
                    prize.visible = false;
                    bHitTest = true;
                }
                if (!bHitTest) {
                    this.prizesOnStage++;
                }
                // not hitting soldiers:
                for (let j = 1; j < 10; j++) {
                    const soldier = this.root[`soldier${j}`];
                    if (soldier.visible && Math.abs(soldier.x - prize.x) < 50) {
                        prize.visible = false;
                    }
                }
            }
        }
    };

    prizeEnterFrame = (prize: MC, index: number) => {
        prize.x -= this.interval;
        if (prize.x < this.objectsOutOfScreen) {
            this.prizeLapInit(prize, index);
        }
        if (prize.visible) {
            console.log("checking prize");

            //if (this._visible && this.hitTest(player._x, player._y+25, true)) {
            if (
                Math.abs(this.root.player.x - prize.x) < 50 &&
                this.prizeLevels[index] === this.playerHeightLevel &&
                prize.visible
            ) {
                // objGoodSound.start();
                prize.visible = false;
                playSound("/week_games/letter_race/sounds/good.mp3");
                this.root[`ball${this.correctItems}`].gotoAndStop("on");
                this.correctItems++;
                // score.text = nPrizes;
                //nPrizesOnBoard
                if (this.correctItems === 8) {
                    // end of game

                    this.endGame();
                }
            }
        }
        console.log("finished prize");
    };

    placeSoldiers = () => {
        for (let i = 0; i < 10; i++) {
            const soldier = this.root[`soldier${i}`];
            this.soldierLapInit(soldier, i);
            console.log("soldier", i);

            this.root[`soldierOnTop${i}`].x = this.exile;
            this.soldierIntervals.push(
                setInterval(() => {
                    this.soldierEnterFrame(soldier, i);
                }, 50)
            );
        }
    };

    soldierLapInit = (soldier: MC, index: number) => {
        let level: number;
        if (!this.shouldProducePrizes) {
            // if should not create more soldiers:
            this.shouldProduceSoldiers = false;
            return;
        }
        soldier.visible = true;
        soldier.x = this.soldierInitX + this.intRandom(this.soldierCastAway);
        // make sure soldier not too close to any other soldiers:
        for (let j = 0; j < 10; j++) {
            const other_soldier = this.root[`soldier${j}`];
            if (j === index) {
                continue;
            }
            if (Math.abs(other_soldier.x - soldier.x) < this.soldierMinDistance) {
                soldier.visible = false;
            }
        }
        // make sure soldier is not too close to prizes:
        for (let i = 0; i < 14; i++) {
            const prize = this.root[`prize${i}`];
            if (prize.visible && Math.abs(prize.x - soldier.x) < 50) {
                soldier.visible = false;
            }
        }

        level = this.intRandom(this.maxTop + 1);
        soldier.y = this.soldierInitY - level * this.playerJump;
        this.soldierLevels[index] = level;
    };

    soldierEnterFrame = (soldier: MC, index: number) => {
        soldier.x -= this.interval;
        // wrap:
        if (soldier.x < this.objectsOutOfScreen) {
            this.soldierLapInit(soldier, index);
        }
        if (soldier.visible) {
            // soldier on top:
            //if (Math.abs(this._x-player._x) < 200 && this._y > player._y && this._visible) {
            if (
                Math.abs(soldier.x - this.root.player.x) < 200 &&
                this.soldierLevels[index] < this.playerHeightLevel &&
                soldier.visible
            ) {
                this.root[`soldierOnTop${index}`].x = soldier.x;
                this.root[`soldierOnTop${index}`].y = soldier.y;
            } else if (this.root[`soldierOnTop${index}`].x !== this.exile) {
                this.root[`soldierOnTop${index}`].x = this.exile;
            }

            if (
                this.playerHeightLevel === this.soldierLevels[index] &&
                Math.abs(this.root.player.x - soldier.x) < 30 &&
                !this.crashedSoldiers[index]
            ) {
                this.crashedSoldiers[index] = true;
                console.log("sending to decrease", soldier.name);
                playSound("/week_games/letter_race/sounds/bad.mp3");
                this.decreaseLife();
                // objBadSound.start(0, 0);
            }
        }
    };

    decreaseLife = () => {
        this.lives--;
        console.log("in decrease", this.lives);

        if (this.lives <= 0) {
            //TODO gameOver
            this.gameOver();
        }
    };

    clearIntervals = () => {
        clearInterval(this.wallIntervals);
        for (let i = 0; i < this.prizeIntervals.length; i++) {
            clearInterval(this.prizeIntervals[i]);
            // this.prizeIntervals[i] = null;
        }
        for (let i = 0; i < this.soldierIntervals.length; i++) {
            clearInterval(this.soldierIntervals[i]);
        }
    };

    gameOver = () => {
        this.clearIntervals();
        this.root.player.gotoAndStop(0);
        this.disableLevelChooser();
        this.resetGame = true;
        this.root.startClip.gotoAndPlay("startEnd");
        this.bgSound.stop();
        this.disableSpeaker();
    };

    intRandom = (randomLimit: number) => {
        return Math.floor(Math.random() * randomLimit);
    };

    endGame = async () => {
        this.shouldProducePrizes = false;
        for (let i = 0; i < 14; i++) {
            this.root[`prize${i}`].visible = false;
        }
        for (let i = 0; i < 10; i++) {
            this.root[`soldier${i}`].visible = false;
            this.root[`soldierOnTop${i}`].visible = false;
        }
        // show end:
        if (this.root.wall0.x + this.wallWidth > 800) {
            this.root.endObject.x = this.root.wall0.x + this.wallWidth - this.overlap;
        } else {
            this.root.endObject.x = this.root.wall1.x + this.wallWidth - this.overlap;
        }
        this.root.endObject.visible = true;
        this.doEndInterval = true;
    };

    endObjInterval = () => {
        if (this.root.endObject.x <= this.root.endMark.x) {
            clearInterval(this.wallIntervals);
            this.doEndInterval = false;
            stopAllSounds();
            // objEndSound.start(0, 0);
            playSound("/week_games/letter_race/sounds/end.mp3");
            this.gameOver();
        } else {
            this.root.endObject.x -= this.interval;
        }
    };
    exit = () => {
        this.clearIntervals();
        document.removeEventListener("keydown", this.keyDown);
        super.exit();
    };
}
