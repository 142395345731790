import { generatePath } from "react-router-dom";
import { loadSound, soundPlayed } from "../../animate/sound_utils";
import { MC, playSound, playSoundSync, setupStage, stopAllSounds, waitFor } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { CancelablePromise } from "../../utils/CancelablePromise";

const GOOD_ITEMS = 7;
const BAD_ITEMS = 6;
const DAYS = ["tuesday", "wednesday", "thursday", "friday"];
const NUM_ITEMS = [10, 8, 12, 15];
const SPACING = 100;
const MAX_STEP = 25;
const STAGE_WIDTH = 800;
export class CreationBibleGame {
    private root: MC;
    private bgSound: createjs.AbstractSoundInstance;
    private current_day: string = null;
    private nItems = 0;
    private scroll_array: any = [];
    private arr_order = ["g", "b", "g", "b", "g", "b", "g", "b", "b", "g", "g", "b", "g"];
    private scroll_items: MC[] = [];
    private step = 3;
    private finished_instructions: boolean = false;
    private correct_answers = 0;
    private to_finish: number = 0;
    private show_interval: any = null;
    currentPromise: CancelablePromise<any>;

    constructor(private type: string, private frame: any) {}
    loaded = async (mc: MC) => {
        this.root = mc;
        await waitFor(() => this.frame);
        setupStage(this.frame.root, this.root);
        this.root.gotoAndStop(5);
        this.initFrame();
        this.current_day = null;
        this.initBgSound();
        this.initDays();
    };

    initBgSound = async () => {
        this.bgSound = playSoundSync("creationBg", { loop: -1 });
        this.bgSound.volume = 0.4;
    };
    initDays = () => {
        DAYS.forEach((d) => {
            const day = this.root.days[d];
            day.gotoAndStop(0);
            day.cursor = "pointer";
            day.addEventListener("rollover", async () => {
                stopAllSounds();
                day.gotoAndPlay(1);
                await this.cancelableWait(
                    soundPlayed((await loadSound(`/bible_stories/creation_game/sounds/${d}_title.mp3`)).play())
                );
            });
            day.addEventListener("rollout", () => {
                day.gotoAndPlay(10);
            });
            day.addEventListener("click", () => {
                this.current_day = d;
                this.root.days.gotoAndStop(d);
                this.startDayGame();
            });
        });

        this.root.days.gotoAndStop("menu");
    };
    async cancelableWait(p: Promise<any>) {
        this.currentPromise = p as CancelablePromise<any>;
        await p;
        this.currentPromise = null;
    }

    initExitBtn = () => {
        const exitBtn = this.frame.root.frame.btnExit;
        exitBtn.addEventListener("click", (e: MouseEvent) => {
            stopAllSounds();
            if (this.current_day) {
                this.frame.toggleReplay(false);
                this.bgSound.play();
                this.clearState();
                this.current_day = null;
                this.root.days.gotoAndStop("menu");
                DAYS.forEach((d) => {
                    const day = this.root.days[d];
                    day.gotoAndStop(0);
                });
            } else {
                e.stopPropagation();
                Navigation.openPage(generatePath(routes.bible_stories.story_menu.menu, { type: this.type }));
            }
        });
    };

    initHelpBtn = () => {
        const helpBtn = this.frame.root.frame.btnHelp;
        helpBtn.addEventListener("click", async () => {
            if (this.current_day) {
                const sound = await loadSound(`/bible_stories/creation_game/sounds/${this.current_day}_help.mp3`);
                sound.play();
            } else {
                playSound("help_tool");
            }
        });
    };

    initReplayBtn = () => {
        const replayBtn = this.frame.root.frame.btnReplay;
        replayBtn.addEventListener("click", () => {
            stopAllSounds();
            this.show_interval && clearInterval(this.show_interval);
            this.clearState();
            this.startDayGame();
        });
    };

    initFrame = () => {
        this.frame.toggleReplay(false);
        this.frame.togglePrint(false);
        this.frame.toggleHelp(true);
        this.initExitBtn();
        this.initHelpBtn();
        this.initReplayBtn();
    };

    startDayGame = async () => {
        this.frame.toggleReplay(true);
        this.bgSound.stop();
        this.playInstruction();
        this.initDay();
        this.scroll_array = [];
        await waitFor(() => this.finished_instructions);
        if (this.scroll_array.length) return; // a few function instances were waiting....
        const good_items = this.pickGoodItems();
        const wrong_items = this.pickWrongItems();
        for (let i = this.arr_order.length - 1; i >= 0; i--) {
            if (this.arr_order[i] === "g") {
                this.scroll_array.push(good_items.pop());
            } else {
                this.scroll_array.push(wrong_items.pop());
            }
        }
        for (var i = 0; i < this.scroll_array.length; i++) {
            // attach clips and add them to arscroll
            this.initScrollItem(i);
        }
        this.moveItems();
    };

    playInstruction = async () => {
        this.finished_instructions = false;
        this.frame.disableBtn(this.frame.root.frame.btnHelp);
        this.frame.disableBtn(this.frame.root.frame.btnReplay);
        const sound = await loadSound(`/bible_stories/creation_game/sounds/${this.current_day}_inst.mp3`);
        sound.play().on("complete", () => {
            this.frame.enableBtn(this.frame.root.frame.btnHelp);
            this.frame.enableBtn(this.frame.root.frame.btnReplay);
            this.bgSound.play();
            this.finished_instructions = true;
        });
    };

    moveItems = () => {
        this.root.addEventListener("tick", this.moveScroll);
    };

    moveScroll = () => {
        const position = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        const n = (position.x - STAGE_WIDTH / 2) / STAGE_WIDTH;
        this.step = MAX_STEP * n;
        this.scroll_items.forEach((item) => {
            item.x -= this.step;
            if (item.x < -SPACING) {
                item.x = (GOOD_ITEMS + BAD_ITEMS - 1) * SPACING;
            } else if (item.x > (GOOD_ITEMS + BAD_ITEMS) * SPACING) {
                item.x = 0;
            }
        });
    };

    initDay = () => {
        this.nItems = 0;
        const day_screen = this.root.days[this.current_day + "_day"];
        while (day_screen["item" + this.nItems] !== undefined) {
            day_screen["item" + this.nItems].visible = false;
            this.nItems += 1;
        }
    };

    pickGoodItems = () => {
        const arGood = [];
        const temp2 = [];
        for (let i = 0; i < this.nItems; ++i) {
            temp2[i] = i;
        }
        for (let i = 0; i < GOOD_ITEMS; ++i) {
            const n = Math.floor(Math.random() * temp2.length);
            const c = this.root.days[this.current_day + "_day"]["item" + temp2[n]];
            arGood.push({ sDay: this.current_day, nFrame: temp2[n], oCorrect: c });
            temp2.splice(n, 1);
        }
        return arGood;
    };

    pickWrongItems = () => {
        const tempItems: { sDay: string; nFrame: number; oCorrect: any }[] = [];
        const tempNums: any = []; // keeps day*100+frame to avoid duplicates
        let nn: number = 0;
        const idx = DAYS.indexOf(this.current_day);
        const others = [...DAYS];
        others.splice(idx, 1);
        while (nn < BAD_ITEMS) {
            // pick random day
            const nd = Math.floor(Math.random() * 3);
            const d = others[nd];
            // pick item
            const total = NUM_ITEMS[nd];
            const n = Math.floor(Math.random() * total) + 1;
            const t = nd * 100 + n;
            if (!tempNums.includes(t)) {
                tempNums.push(t);
                tempItems.push({ sDay: d, nFrame: n, oCorrect: null });
                nn++;
            }
        }
        return tempItems;
    };

    clearState = () => {
        this.scroll_items.forEach((item) => {
            this.root.days.scrollbar.removeChild(item);
        });
        this.root.removeEventListener("tick", this.moveScroll);
        this.scroll_array = [];
        this.scroll_items = [];
        this.correct_answers = 0;
    };

    initScrollItem = (i: number) => {
        const details = this.scroll_array[i];
        const item = new this.root.lib[details.sDay + "_menu"]();
        item.gotoAndStop(details.nFrame);
        item.x = i * SPACING;
        item.y = 10;
        this.root.days.scrollbar.addChild(item);
        this.scroll_items.push(item);
        item.cursor = "pointer";
        this.onClickItem(item, details);
    };

    onClickItem = (item: MC, details: any) => {
        item.addEventListener("mousedown", async () => {
            if (details.oCorrect) {
                details.oCorrect.visible = true;
                details.oCorrect.gotoAndStop(0);
                this.root.days.scrollbar.removeChild(item);
                const idx = this.scroll_items.indexOf(item);
                this.scroll_items.splice(idx, 1);
                playSound("correct");
                this.correct_answers += 1;
                if (this.correct_answers === GOOD_ITEMS) {
                    this.endGame();
                }
            } else {
                playSound("mistake");
            }
        });
    };

    disableBtn = (btn: MC) => {
        btn.mouseEnabled = false;
        btn.cursor = "default";
    };

    enableBtn = (btn: MC) => {
        btn.mouseEnabled = true;
        btn.cursor = "pointer";
    };

    endGame = async () => {
        this.to_finish = 0;
        this.scroll_items.forEach((item) => {
            this.root.days.scrollbar.removeChild(item);
        });
        this.bgSound.stop();
        (await loadSound(`/bible_stories/creation_game/sounds/Sof_31.mp3`)).play();
        this.show_interval = setInterval(this.showItem, 400);
    };

    showItem = () => {
        const item = this.root.days[this.current_day + "_day"]["item" + this.to_finish];
        if (item) {
            if (item.visible === false) {
                item.alpha = 0;
                item.visible = true;
                createjs.Tween.get(item).to(
                    {
                        alpha: 1,
                    },
                    400
                );
            }
            this.to_finish++;
        } else {
            clearInterval(this.show_interval);
            this.to_finish = 0;
            for (let i = 0; i < this.nItems; i++) {
                this.root.days[this.current_day + "_day"]["item" + i].gotoAndPlay(1);
            }
        }
    };
}
