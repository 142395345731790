import { MC, playSoundSync, stopAllSounds, waitForEvent } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";

const MENU_ITEMS = [
    "wizard_btn",
    "phone_btn",
    "hipo_btn",
    "swing_btn",
    "cars_btn",
    "elephant_btn",
    "aqua_btn",
    "frogs_btn",
    "ducks_btn",
];

export class MathMenu {
    inOver: boolean;
    stopLoop: boolean;
    constructor(private isMenu: boolean) {}
    root: MC;
    private _isLoggedIn: boolean;
    bgSound: createjs.AbstractSoundInstance;
    mySound: createjs.AbstractSoundInstance;
    additionalSound: createjs.AbstractSoundInstance;
    loaded = (root: MC) => {
        this.root = root;
        (window as any).MathMenu = root;
        this.root.back_btn.addEventListener("click", () => {
            stopAllSounds();
            this.bgSound.stop();
            Navigation.openPage(routes.home);
        });
        this.menuItemsEvents();
        this.initMenuBtns();
        this.isMenu && this.setBgSound();
        this.updateGuestArrow();
    };

    setBgSound = () => {
        this.bgSound = playSoundSync("bg", { loop: -1 });
        this.bgSound.volume = 0.4;
        // this.bgSound.volume = 0;
    };

    menuItemsEvents = () => {
        MENU_ITEMS.forEach((item: string) => {
            const btn = this.root[item];
            btn.addEventListener("rollout", () => {
                this.stopLoop = true;
                this.mySound && this.mySound.stop();
                this.additionalSound && this.additionalSound.stop();
            });
            btn.addEventListener("rollover", () => {
                // this.mySound = playSoundSync(item);
                this.mySound && this.mySound.stop();
                const eventName = `begin_${item}`;
                waitForEvent(this.root, eventName).then(() => {
                    this.loopSound(item, eventName);
                });
            });
        });
    };

    loopSound = (item: string, eventName: string) => {
        this.mySound = playSoundSync(item);
        if (item === "phone_btn") {
            this.mySound.volume = 0.5;
        }
        waitForEvent(this.root, eventName).then(() => {
            if (this.stopLoop) return;
            this.loopSound(item, eventName);
        });
    };

    initMenuBtns = () => {
        this.root.hipo_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.hippo);
        });

        this.root.cars_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.cars);
        });

        this.root.wizard_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.wizard);
        });

        this.root.aqua_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.aqua);
        });

        this.root.ducks_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.ducks);
        });

        this.root.frogs_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.frogs);
        });

        this.root.elephant_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.monkey);
        });

        this.root.phone_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.phone);
        });

        this.root.swing_btn.addEventListener("click", () => {
            this.bgSound.stop();
            stopAllSounds();
            Navigation.openPage(routes.math.swing);
        });
    };

    restartSounds = () => {
        this.setBgSound();
    };

    set isLoggedIn(value: boolean) {
        this._isLoggedIn = value;
        this.updateGuestArrow();
    }

    private async updateGuestArrow() {
        if (!this.root) return;
        // const { arrow_mc } = this.root.arrow_mc;
        this.root.ducks_btn.framerate = 20;
        if (this._isLoggedIn) {
            this.root.arrow_mc.visible = false;
        } else {
            this.root.arrow_mc.visible = true;
            this.root.arrow_mc.alpha = 1;
            this.root.arrow_mc.gotoAndPlay(0);
        }
        this.root.ducks_btn.framerate = 20;
    }
}
