import { Fishes } from "./Fishes";
import { loadFont } from "../../../animate/utils";

export class FishesGame {
    constructor(private history: any, private sectionIndex: any) {}

    private Fishes: Fishes = null;
    fishesLoaded = (mc: createjs.MovieClip, lettersContext: any) => {
        this.Fishes = new Fishes(mc, this.history, this.sectionIndex, lettersContext, null, null);
        loadFont(mc, "ProntoMF");
    };

    unload = () => {};
}
