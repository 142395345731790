const additionalSounds = [
    {
        src: "sounds/kidscheerwav.mp3",
        id: "kidscheerwav",
    },
    {
        src: "sounds/exitTrain.mp3",
        id: "exitTrain",
    },
    {
        src: "sounds/rollercoster.mp3",
        id: "rollercoster",
    },
];
export default additionalSounds;
