import { MutableRefObject } from "react";
import { generatePath } from "react-router-dom";
import { loadSound } from "../../animate/sound_utils";
import { MC, playSoundSync, stopAllSounds, waitFor } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { SoundToolTip } from "../../utils/soundTooltip";
import { VideoPlayer } from "../../video/VideoPlayer";
import { BibleMovies } from "../bible_movies/BibleMovies";

export class StoryMenu {
    private root: MC;
    private moovies: BibleMovies;
    private bgSound: createjs.AbstractSoundInstance;
    private titleSound: createjs.AbstractSoundInstance;
    private gameBtn: MC;
    private isLoggedIn: boolean;

    constructor(
        private type: string,
        private setVideoSrc: (value: string) => void,
        private videoRef: MutableRefObject<VideoPlayer>,
        private setParentsInfo: (value: boolean) => void
    ) {}

    loaded = async (mc: MC) => {
        this.root = mc;
        await waitFor(() => this.root);
        this.moovies = new BibleMovies(
            this.root,
            this.type,
            this.setVideoSrc,
            this.videoRef,
            this.setParentsInfo,
            this.isLoggedIn
        );
        this.root.gotoAndPlay(1);
        this.initStoryMenu();
        (window as any).story = this.root;
    };
    initView = () => {
        this.root?.gotoAndStop(67);
    };

    updateVideoPosition = () => {
        if (!this.moovies) return;
        this.moovies.updateVideoPosition();
    };
    restartVideo = () => {
        this.moovies.restartVideo();
    };
    updateLockedMoovies = () => {
        this.moovies && this.moovies.updateLockedMoovies();
    };

    setIsLoggedIn = async (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
        if (this.root) {
            this.initArrow();
        }
    };

    initStoryMenu = () => {
        this.initBackBtn();
        this.initHomeBtn();
        this.initSounds();
        this.initGameBtn();
        this.initTitle();
        this.initBarBtns();
        this.initArrow();
    };

    initSounds = async () => {
        this.bgSound = playSoundSync("bgGameMenu", { loop: -1 });
        this.bgSound.volume = 0.4;
        const sound = await loadSound(`/bible_stories/sounds/btn${this.type}.mp3`);
        this.titleSound = sound.play();
    };
    initBackBtn = () => {
        const back_btn = this.root.backBtn;
        back_btn.gotoAndStop("up");
        back_btn.cursor = "pointer";
        back_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.bible_stories.menu);
        });
        back_btn.addEventListener("mouseover", () => {
            back_btn.gotoAndStop("over");
        });
        back_btn.addEventListener("mouseout", () => {
            back_btn.gotoAndStop("up");
        });
        SoundToolTip.addToolTip(back_btn, "/bible_stories/sounds/back.mp3");
    };

    initHomeBtn = () => {
        const home_btn = this.root.closeBtn;
        home_btn.gotoAndStop("up");
        home_btn.cursor = "pointer";
        home_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
        home_btn.addEventListener("mouseover", () => {
            home_btn.gotoAndStop("over");
        });
        home_btn.addEventListener("mouseout", () => {
            home_btn.gotoAndStop("up");
        });
        SoundToolTip.addToolTip(home_btn, "/bible_stories/sounds/home.mp3");
    };

    getGameBtn = () => {
        switch (this.type) {
            case "1":
                return "creation";
            case "2":
                return "tree";
            case "3":
                return "noa";
            case "4":
                return "tower";
        }
    };

    initGameBtn = () => {
        this.root.game_btn.gotoAndStop(this.getGameBtn());
        this.gameBtn = this.root.game_btn["btn_" + this.getGameBtn()];
    };

    initTitle = () => {
        this.root.mcTitle.titleHolder.titles.gotoAndStop(this.getGameBtn());
    };

    initBarBtns = () => {
        [this.root.puzzels_btn, this.gameBtn, this.root.coloring_btn].forEach((btn) => {
            btn.cursor = "pointer";
            btn.addEventListener("mouseover", () => {
                btn.gotoAndStop("over");
            });
            btn.addEventListener("mouseout", () => {
                btn.gotoAndStop("up");
            });
            btn.addEventListener("click", () => {
                this.selectAction(btn);
            });
        });
        SoundToolTip.addToolTip(this.root.coloring_btn, "coloring");
        SoundToolTip.addToolTip(this.gameBtn, "game");
        SoundToolTip.addToolTip(this.root.puzzels_btn, "puzzels");
    };

    initArrow = () => {
        this.root.mcArrow.visible = !this.isLoggedIn;
        !this.isLoggedIn && this.root.mcArrow.gotoAndPlay(1);
    };

    selectAction = (btn: MC) => {
        stopAllSounds();
        this.moovies.restartVideo();
        console.log("btn", btn);

        switch (btn) {
            case this.root.puzzels_btn:
                Navigation.openPage(generatePath(routes.bible_stories.story_menu.puzzle.frame, { type: this.type }));
                break;
            case this.root.game_btn.btn_tower:
                Navigation.openPage(generatePath(routes.bible_stories.story_menu.bavel_game, { type: this.type }));
                break;
            case this.root.game_btn.btn_noa:
                Navigation.openPage(generatePath(routes.bible_stories.story_menu.noach_game, { type: this.type }));
                break;
            case this.root.game_btn.btn_tree:
                Navigation.openPage(generatePath(routes.bible_stories.story_menu.tree_game, { type: this.type }));
                break;
            case this.root.game_btn.btn_creation:
                Navigation.openPage(generatePath(routes.bible_stories.story_menu.creation_game, { type: this.type }));
                break;
            case this.root.coloring_btn:
                Navigation.openPage(generatePath(routes.bible_stories.story_menu.coloring, { type: this.type }));
                break;
        }
    };
    resumeBg = () => {
        this.bgSound?.play();
    };
}
