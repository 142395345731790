export const commomSounds = [
    {
        src: "/letters_island/common/commonSounds/no.mp3",
        id: "no",
    },
    {
        src: "/letters_island/common/commonSounds/yes.mp3",
        id: "yes",
    },
    {
        src: "/letters_island/common/commonSounds/PlayAgain.mp3",
        id: "PlayAgain",
    },
];
