const additionalSounds = [
    {
        src: "sounds/EndAqua1.mp3",
        id: "end",
    },
    {
        src: "sounds/aqua.mp3",
        id: "aqua",
    },
];
export default additionalSounds;
