import React from "react";
import AnimateCC from "../../animate";
import { RocketMenu } from "./RocketMenu";
import { PuzzleGame } from "./PuzzleGame";
import { clickSound } from "./puzzleSounds";
import { useParams } from "react-router-dom";

const PuzzleComponent: React.FC<{ frame: RocketMenu }> = ({ frame }) => {
    const game = React.useRef(new PuzzleGame(frame));
    const { num } = useParams<{ num: string }>();

    return (
        <AnimateCC
            key={Date.now()}
            base={`/lunapark/rocket/puzzle_${num}`}
            animationName={`puzzle_${num}`}
            scale={0.75}
            shiftX={130}
            shiftY={5}
            onLoaded={game.current.loaded}
            additionalSounds={clickSound}
        />
    );
};
export default PuzzleComponent;
