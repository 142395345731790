import { MC, waitForEvent, playSound } from "../animate/utils";
import { SoundToolTip } from "../utils/soundTooltip";

export class Frame {
    root: MC;
    level: number = 0;
    prizes: createjs.MovieClip[] = [];
    actionNow: boolean = false;
    bgPlay: any = null;
    firstTime: boolean = true;
    interval: any;
    bgPlayStatus: string;
    instPlayStatus: string;
    buttonSound: any = null;
    maxLevel: number;

    constructor(root: MC) {
        this.root = root;
        (window as any).math = root;
        this.root.popup.visible = false;

        window.onerror = function (error) {
            return (
                error.toString().startsWith("Uncaught TypeError: item.setPlaybackResource") ||
                error.toString().startsWith("Uncaught TypeError: Cannot read property '1' of null")
            );
        };
    }

    showLevelUpPopup = () => {
        playSound("questLevelUp");
        this.root.popup.visible = true;
        this.root.popup.alpha = 1;
        this.root.popup.msg_txt.text = "האם ברצונך לעלות רמה?";
        this.root.popup.proceed_btn.visible = false;
    };

    handleLevel = (level: number) => {
        if (this.bgPlay) this.bgPlay.volume = 0;
        this.level = level;
        this.root.btn_levels.flags_mc.gotoAndStop(level - 1);
    };

    addPrize = (prizeName: string, prizeHeight?: number, prizePos: number = 500) => {
        const prize = new this.root.lib[prizeName]();
        this.prizes.push(prize);
        prize.y = prizeHeight ? prizeHeight : 520;
        prize.x = prizePos - (this.prizes.length - 1) * 65;
        this.root.addChild(prize);
        //TODO ADD SOUND
        // prize.gotoAndPlay(0);
    };

    initFlag = (flag: MC, index: number) => {
        const hit_level = new createjs.Shape();
        let w,
            h,
            y = 0;
        if (index === 1) {
            w = 43;
            h = 40;
        } else if (index === 1) {
            w = 43;
            h = 54;
            y = 15;
        } else {
            w = 43;
            h = 64;
            y = 20;
        }
        hit_level.graphics.beginFill("#000").drawRect(0, y, w, h);
        hit_level.regX = 5;
        hit_level.regY = 5;

        flag.hitArea = hit_level;
        flag.alpha = 1;
        flag.gotoAndPlay(0);

        flag.cursor = "pointer";
        SoundToolTip.addToolTip(flag, `level${index}`);
    };
    openSelector = () => {
        this.root.levels_anim_mc.gotoAndPlay("open");
        return waitForEvent(this.root, "animationEnd");
    };
    initLevel2 = (level_btn1: MC, level_btn2: MC) => {
        this.initFlag(level_btn1, 1);
        this.initFlag(level_btn2, 2);
    };

    initLevel3 = (level_btn1: MC, level_btn2: MC, level_btn3: MC) => {
        this.initFlag(level_btn1, 1);
        this.initFlag(level_btn2, 2);
        this.initFlag(level_btn3, 3);
    };

    initChooser = async (levels: number) => {
        const levelSelector = this.root.levels_anim_mc;
        this.maxLevel = levels;
        levelSelector.alpha = 1;
        this.root.switch_movie_mc.alpha = 1;
        this.openSelector();
        levelSelector.levels_mc.close_btn.cursor = "pointer";
        const { level_btn1, level_btn2, level_btn3 } = levelSelector.levels_mc;

        levelSelector.levels_mc.close_btn.alpha = this.level > 0;
        levelSelector.levels_mc.gotoAndStop(levels === 2 ? "two" : "three");
        levels === 2 ? this.initLevel2(level_btn1, level_btn2) : this.initLevel3(level_btn1, level_btn2, level_btn3);
    };

    setFrameButton = (btn_mc: MC) => {
        btn_mc.cursor = "pointer";
        btn_mc.mouseChildren = false;
        SoundToolTip.addToolTip(btn_mc, btn_mc.name);
        btn_mc.addEventListener("rollover", async () => {
            btn_mc.gotoAndStop("over");
        });
        btn_mc.addEventListener("mousedown", async () => {
            btn_mc.gotoAndStop("down");
        });
        btn_mc.addEventListener("rollout", () => {
            btn_mc.gotoAndStop("normal");
        });

        btn_mc.addEventListener("pressup", () => {
            btn_mc.gotoAndStop("normal");
        });
    };

    stopPreloader = (levels: number, innerMenu: boolean) => {
        this.root.preloader_mc.alpha = 0;
        this.root.frame_mc.alpha = 1;
        if (!innerMenu) {
            this.initChooser(levels);
            return playSound("chooseLevel");
        } else {
            return Promise.resolve();
        }
    };

    loaded = async () => {
        this.root.children.forEach((c: createjs.MovieClip) => (c.alpha = 0));
        this.root.preloader_mc.alpha = 1;
        this.root.preloader_mc.gotoAndPlay(1);
        [this.root.btn_help, this.root.btn_levels, this.root.btn_exit].forEach((btn: MC) => {
            btn.alpha = 1;
        });
    };

    unload = () => {};
}
