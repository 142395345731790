import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { RobotGame } from "./RobotGame";
import { additionalSounds } from "./RobotSounds";
const RobotComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new RobotGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/robot"
            animationName="robot"
            onLoaded={game.current.loaded}
            scale={0.9}
            shiftX={78}
            shiftY={10}
            additionalSounds={additionalSounds}
        />
    );
};
export default RobotComponent;
