import { MC, playSoundSync, setupStage, waitForEvent } from "../../../animate/utils";

export class NumberFiveGame {
    private root: MC;
    private counter = 0;
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).FiveGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.gotoAndStop(0);
        this.root.rocket1.gotoAndStop(0);
        this.root.rocket2.gotoAndStop(0);
        this.root.rocket3.gotoAndStop(0);
        this.root.rocket4.gotoAndStop(0);
        this.root.rocket5.gotoAndStop(0);
        this.root.addEventListener("tick", (e: any) => {
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.root.match.x = localMouse.x;
            this.root.match.y = localMouse.y;
            let i = this.isIntersecting();
            if (i > 0) {
                this.root.cursor = "pointer";
            } else {
                this.root.cursor = "default";
            }
        });
        this.root.match.addEventListener("mousedown", (e: any) => {
            let i = this.isIntersecting();
            if (i !== 0) {
                this.startBoom(this.root["rocket" + i]);
            }
        });
    };
    startBoom = async (rocket: MC) => {
        this.counter += 1;
        rocket.number = this.counter;
        rocket.gotoAndPlay(0);
        playSoundSync("whstlewav");
        await waitForEvent(rocket, "finish");
        playSoundSync("crash");
        this.boom(rocket);
        //rocket.rocket.visible=false;
        if (this.counter === 5) {
            await waitForEvent(this.root, "replay");
            this.frame.root.Bar.againbut.visible = true;
        }
    };
    boom = (rocket: MC) => {
        switch (rocket.number) {
            case 1:
                rocket.boom.gotoAndPlay(1);
                break;
            case 2:
                rocket.boom.gotoAndPlay(31);
                break;
            case 3:
                rocket.boom.gotoAndPlay(62);
                break;
            case 4:
                rocket.boom.gotoAndPlay(97);
                break;
            case 5:
                rocket.boom.gotoAndPlay(127);
                break;
        }
    };
    isIntersecting = () => {
        for (let i = 1; i <= 5; i++) {
            const localMouse = this.root["rocket" + i].rocket.head.globalToLocal(
                this.root.stage.mouseX,
                this.root.stage.mouseY
            );
            if (this.root["rocket" + i].rocket.head.hitTest(localMouse.x, localMouse.y)) {
                return i;
            }
        }
        return 0;
    };
}
