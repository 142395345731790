import React from "react";
import AnimateCC from "../../../animate";
import { NumberSixGame } from "./NumberSixGame";
import additionalSounds from "../BigWheelSounds";
import { BigWheelGame } from "../BigWheelGame";
const NumberSixComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberSixGame(frame));

    return (
        <AnimateCC
            //base="/lunapark/sweets/popcorn"
            base="/lunapark/big_wheel/number_six"
            animationName="number_six"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default NumberSixComponent;
