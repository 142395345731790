import { MC, playSoundSync, setupStage, stopAllSounds } from "../../animate/utils";

export class PuppetTheaterGame {
    private root: MC;
    private itemsAddedToScreen: MC[] = [];
    private skyStatus: number = 0;
    private play: boolean = false;
    private startY: number;
    private startScale: number;
    private characters: MC[];
    private items: MC[];

    private beeSound: any;

    constructor(private frame: any) {}

    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        stopAllSounds();
        (window as any).PuppetGame = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();

        this.root.gotoAndStop(9);

        this.characters = [this.root.giraffe, this.root.girl, this.root.lady, this.root.man];
        this.items = [this.root.castle, this.root.flowers, this.root.tree, this.root.hedge];

        this.root.sky_instance.gotoAndStop(0);
        this.root.arr.gotoAndPlay(0);
        this.root.arr.mouseEnabled = false;
        this.root.rect.mouseEnabled = false;
        this.root.lady.scale = 1.3;
        this.root.giraffe.scale = 1.2;
        this.root.man.scale = 1.2;
        this.root.girl.scale = 1.2;

        this.initItems();
        this.addCastleListener();
        this.addFlowersListener();
        this.addTreeListener();
        this.addHedgeListener();
        this.addSkyListener();
        this.initCharachters();
        this.addPlayListener();
        this.addStopListener();
        this.start();
    };

    onScreen = (item: MC) => {
        return this.itemsAddedToScreen.indexOf(item) >= 0;
    };

    startPuppet = () => {
        if (!this.play) {
            this.play = true;
            this.itemsAddedToScreen.forEach((item) => {
                if (item === this.root.flowers) {
                    if (this.beeSound) {
                        this.beeSound.stop();
                    }
                    this.beeSound = playSoundSync("soundBee", { loop: 1 });
                    this.beeSound.volume = 0.4;
                }
                item.play();
            });
            this.root.instance.gotoAndPlay(0);
            this.root.arr.gotoAndPlay(20);
            let bgAudio = playSoundSync("_puppet_tune", { loop: 1 });
            bgAudio.volume = 0.6;
        } else {
            this.itemsAddedToScreen.forEach((item) => {
                if (item === this.root.flowers) {
                    this.root.flowers.gotoAndStop(0);
                    this.root.flowers.gotoAndPlay(0);
                    if (this.beeSound) {
                        this.beeSound.stop();
                    }
                    this.beeSound = playSoundSync("soundBee", { loop: 1 });
                    this.beeSound.volume = 0.4;
                } else {
                    item.play();
                }
            });
        }
    };

    characterAnimate = () => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        this.root.lady.torso.larm.rotation = (localMouse.y + 50) / 5;
        this.root.lady.torso.rarm.rotation = -(localMouse.y + 50) / 5;
        this.root.lady.torso.rotation = localMouse.x / 10 - 40;

        this.root.girl.torso.larm.rotation = (localMouse.y + 50) / 5;
        this.root.girl.torso.rarm.rotation = -(localMouse.y + 50) / 5;

        this.root.man.head.rotation = localMouse.x / 10 - 40;

        this.root.giraffe.head.rotation += 5;
    };

    moveCharacter = (e: createjs.MouseEvent) => {
        const char: MC = e.currentTarget;
        if (!this.play) {
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);

            char.scale = this.startScale + (this.root.stage.mouseY - this.startY) / 200;
            char.x = localMouse.x;
        }
    };

    changeSky = () => {
        if (this.skyStatus < 3) {
            this.skyStatus++;
        } else {
            this.skyStatus = 0;
        }
        this.root.sky_instance.gotoAndStop(this.skyStatus);
    };
    changeSkyOnPlay = () => {
        if (this.play) {
            this.changeSky();
        }
    };

    addCastleListener = () => {
        this.root.castle.addEventListener("click", (event: MouseEvent) => {
            if (!this.onScreen(this.root.castle)) {
                this.itemsAddedToScreen.push(this.root.castle);
            } else {
                this.changeSkyOnPlay();
            }
            this.root.castle.x = 150;
            this.root.castle.y = 235;
            this.root.castle.scale = 0.4;
        });
    };

    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (this.play) {
                this.characterAnimate();
            }
        });
    };

    addStopListener = () => {
        this.root.stop_btn.cursor = "pointer";
        this.root.stop_btn.addEventListener("click", (event: MouseEvent) => {
            if (this.play) {
                this.play = false;
                this.itemsAddedToScreen.forEach((item) => {
                    item.stop();
                });
                if (this.itemsAddedToScreen.includes(this.root.flowers)) {
                    this.root.flowers.gotoAndStop(0);
                }
                this.root.arr.gotoAndStop(0);
                stopAllSounds();
            }
        });
    };

    addPlayListener = () => {
        this.root.play_btn.cursor = "pointer";
        this.root.play_btn.addEventListener("click", (event: MouseEvent) => {
            this.startPuppet();
        });
    };

    initCharachters = () => {
        this.characters.forEach((character) => {
            character.addEventListener("mouseover", () => {
                character.cursor = "pointer";
            });
        });

        this.characters.forEach((character) => {
            character.addEventListener("mousedown", (event: MouseEvent) => {
                this.startY = this.root.stage.mouseY;
                this.startScale = character.scale;
                character.addEventListener("pressmove", this.moveCharacter);
                character.addEventListener("pressup", () => {
                    this.changeSkyOnPlay();
                    character.removeEventListener("pressmove", this.moveCharacter);
                });
            });
        });
    };

    addSkyListener = () => {
        this.root.sky_instance.addEventListener("click", (event: MouseEvent) => {
            this.changeSky();
        });
    };

    addHedgeListener = () => {
        this.root.hedge.addEventListener("click", (event: MouseEvent) => {
            if (!this.onScreen(this.root.hedge)) {
                this.itemsAddedToScreen.push(this.root.hedge);
            } else {
                this.changeSkyOnPlay();
            }
            this.root.hedge.x = 480;
            this.root.hedge.y = 411;
            this.root.hedge.scale = 1.2;
        });
    };

    addTreeListener = () => {
        this.root.tree.addEventListener("click", (event: MouseEvent) => {
            if (!this.onScreen(this.root.tree)) {
                this.itemsAddedToScreen.push(this.root.tree);
            } else {
                this.changeSkyOnPlay();
            }
            this.root.tree.x = 500;
            this.root.tree.y = 234;
        });
    };

    addFlowersListener = () => {
        this.root.flowers.addEventListener("click", (event: MouseEvent) => {
            if (!this.onScreen(this.root.flowers)) this.itemsAddedToScreen.push(this.root.flowers);
            this.root.flowers.x = 145;
            this.root.flowers.y = 396;
            this.root.flowers.scale = 1.28;
        });
    };

    initItems = () => {
        this.items.forEach((item) => {
            item.gotoAndStop(0);
            item.addEventListener("mouseover", () => {
                item.cursor = "pointer";
            });
        });
    };
}
