export const airplainEntersSound = (letter: number): any[] => {
    return [
        {
            src: `sounds/letter${letter + 1}.mp3`,
            id: "enter_letter",
        },
    ];
};

export const airplainExitSound = (letter: number): any[] => {
    return [
        {
            src: `sounds/exit/letter${letter + 1}.mp3`,
            id: "enter_letter",
        },
    ];
};

const drawLetterCount = [
    ["she", 3],
    ["she", 3],
    ["she", 3],
    ["she", 2],
    ["he", 3],
    ["he", 2],
    ["he", 2],
    ["he", 3],
    ["she", 4],
    ["she", 2],
    ["she", 3],
    ["she", 3],
    ["he", 4],
    ["he", 3],
    ["he", 2],
    ["he", 3],
    ["he", 4],
    ["she", 3],
    ["she", 3],
    ["she", 2],
    ["she", 4],
    ["she", 4],
];

export const drawLetterCountSound = (letter: number): any[] => {
    const type = drawLetterCount[letter][0];
    const count = drawLetterCount[letter][1];
    const soundArr: any[] = [
        {
            src: `sounds/${type}/Draw1b.mp3`,
            id: "draw1",
        },
    ];
    for (let index = 2; index <= count; index++) {
        if (index === count) {
            soundArr.push({
                src: `sounds/${type}/Draw${index}e.mp3`,
                id: `draw${index}`,
            });
        } else {
            soundArr.push({
                src: `sounds/${type}/Draw${index}m.mp3`,
                id: `draw${index}`,
            });
        }
    }

    soundArr.push({
        src: `sounds/drawed/letter${letter + 1}.mp3`,
        id: `drawedletter${letter + 1}`,
    });

    return soundArr;
};

export const colorLetterSoounds = (letter: number): any[] => {
    const count = drawLetterCount[letter][1];

    const soundArr: any[] = [
        {
            src: `sounds/effect1b.mp3`,
            id: "effect1",
        },
    ];

    for (let index = 2; index <= count; index++) {
        if (index === count) {
            soundArr.push({
                src: `sounds/effect${index}e.mp3`,
                id: `effect${index}`,
            });
        } else {
            soundArr.push({
                src: `sounds/effect${index}m.mp3`,
                id: `effect${index}`,
            });
        }
    }

    return [
        ...soundArr,
        {
            src: "sounds/help.mp3",
            id: "help",
        },
        {
            src: "sounds/Inst.mp3",
            id: "inst",
        },
    ];
};
