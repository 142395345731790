export const nik1Movies = [
    "/movies/nik1/0.mp4",
    "/movies/nik1/1.mp4",
    "/movies/nik1/2.mp4",
    "/movies/nik1/3.mp4",
    "/movies/nik1/4.mp4",
    "/movies/nik1/5.mp4",
    "/movies/nik1/6.mp4",
    "/movies/nik1/7.mp4",
    "/movies/nik1/8.mp4",
    "/movies/nik1/9.mp4",
];

export const nik2Movies = [
    "/movies/nik2/0.mp4",
    "/movies/nik2/1.mp4",
    "/movies/nik2/2.mp4",
    "/movies/nik2/3.mp4",
    "/movies/nik2/4.mp4",
    "/movies/nik2/5.mp4",
    "/movies/nik2/6.mp4",
    "/movies/nik2/7.mp4",
    "/movies/nik2/8.mp4",
    "/movies/nik2/9.mp4",
];

export const englishMovies = [
    "/movies/english/0.mp4",
    "/movies/english/1.mp4",
    "/movies/english/2.mp4",
    "/movies/english/3.mp4",
    "/movies/english/4.mp4",
];

export const letterMovies = [
    "/movies/abc/Alef.m4v",
    "/movies/abc/Bet.m4v",
    "/movies/abc/Gimel.m4v",
    "/movies/abc/Dalet.m4v",
    "/movies/abc/Hei.m4v",
    "/movies/abc/Vav.m4v",
    "/movies/abc/Zain.m4v",
    "/movies/abc/Het.m4v",
    "/movies/abc/Tet.m4v",
    "/movies/abc/Yud.m4v",
    "/movies/abc/Kaf.m4v",
    "/movies/abc/Lamed.m4v",
    "/movies/abc/Mem.m4v",
    "/movies/abc/Nun.m4v",
    "/movies/abc/Sameh.m4v",
    "/movies/abc/Ain.m4v",
    "/movies/abc/Pey.m4v",
    "/movies/abc/Zadik.m4v",
    "/movies/abc/Kuf.m4v",
    "/movies/abc/Reish.m4v",
    "/movies/abc/Shin.m4v",
    "/movies/abc/Tav.m4v",
];

export const songsMovies = [
    "/movies/songs/Eihefsharlishon.mp4",
    "/movies/songs/Gan-Sagur.mp4",
    "/movies/songs/Hibuk.mp4",
];

export const bibleStoryMovies = [
    "/movies/bibleStory/Briaytulam.mp4",
    "/movies/bibleStory/Adamvehava.mp4",
    "/movies/bibleStory/Noach.mp4",
    "/movies/bibleStory/Migdalbavel.mp4",
];

export const allLettersMovie = "/movies/abc/Letterssong.m4v";
