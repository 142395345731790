import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { MemoryGame } from "./MemoryGame";

const MemoryGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new MemoryGame());
    return (
        <>
            <AnimateCC
                base="/week_games/memory_game"
                animationName="memory_game"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                }}
                shiftY={5}
                shiftX={30}
                scale={0.98}
            />
        </>
    );
};
export default MemoryGameComponent;
