import React from "react";
import styles from "./PreloaderComponent.module.scss";

export const PreloaderComponent: React.FC<{ name: "plain" | "maya" | "boat" | "meter" }> = ({ name }) => {
    return (
        <div className={`${styles.preloader} ${styles[name]}`}>
            <img src={require("./" + name + "/animation.gif")} alt="loading..." />
        </div>
    );
};
