import { MC } from "../../animate/utils";
import { soundPlayed } from "../../animate/sound_utils";
import { CancelablePromise } from "../../utils/CancelablePromise";

export class ZigiGameBase {
    root: MC;
    private currentPromise: CancelablePromise<any>;
    shouldPlay: boolean;
    enabledFramBtn: boolean;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    helpSrc2: Promise<createjs.AbstractSoundInstance>;

    loaded(root: MC) {
        this.root = root;
        this.root.visible = false;
        this.shouldPlay = true;
    }

    startLearn() {
        this.root.visible = true;
        this.shouldPlay = true;
    }

    startExercise() {
        this.root.visible = true;
        this.shouldPlay = true;
    }

    resetGame() {
        this.shouldPlay = false;
        this.currentPromise?.cancel?.();
    }

    async cancelableWait(p: Promise<any>) {
        this.currentPromise = p as CancelablePromise<any>;
        await p;
        this.currentPromise = null;
    }

    async playSound(instSound: Promise<createjs.AbstractSoundInstance>) {
        if (!this.shouldPlay) return;
        this.root.mouseEnabled = false;
        this.enabledFramBtn = false;
        await soundPlayed((await instSound).play());
        this.enabledFramBtn = true;
        this.root.mouseEnabled = true;
    }

    async help() {
        if (this.helpSrc2) {
            await this.playSound(this.helpSrc);
            await this.playSound(this.helpSrc2);
        } else {
            this.playSound(this.helpSrc);
        }
    }
}
