import React from "react";

const MovieContext = React.createContext<{
    selectedChannel: number;
    setSelectedChannel: (val: number) => void;
}>({
    selectedChannel: null,
    setSelectedChannel: null,
});

export const MovieContextProvider: React.FC = (props) => {
    const [selectedChannel, setSelectedChannel] = React.useState<number>(null);
    return (
        <MovieContext.Provider value={{ selectedChannel, setSelectedChannel }}>{props.children}</MovieContext.Provider>
    );
};

export const useMovieContext = () => React.useContext(MovieContext);

export type MovieContextType = ReturnType<typeof useMovieContext>;
