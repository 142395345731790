export const messages = {
    SomethingWrong: "\nלא הצלחנו להיכנס\n\nנסה שוב מאוחר יותר",
    loginSucceed: "",
    loginNotExist: "שם המשתמש או הסיסמה שגויים.\nאם לא נרשמתם לאתר - תוכלו להירשם עכשיו.",
    loginInUse: "שם המשתמש הזה פעיל עכשיו במחשב אחר,\nולכן לא ניתן להיכנס עכשיו בשם זה.",
    SendMailOk: "הפרטים נשלחו לדואר האלקטרוני שלך.",
    SendMailProb:
        "כתובת הדואר האלקטרוני הזאת\n אינה קיימת במערכת.\n\n כתבו את הכתובת המופיעה \n בפרטי הרישום שלכם לאתר.",
    NoEmailAddress:
        "כתובת הדואר האלקטרוני הזאת\n אינה קיימת במערכת.\n\n כתבו את הכתובת המופיעה \n בפרטי הרישום שלכם לאתר.",
    LoginChanged:
        "איזה כיף שהגעת אלינו,\nמיה וכל החברים כבר מחכים לשחק.\nאבל, קודם יש צורך בהרשמה.\n\nהיכנסו למסך הרשמה פה למטה.",
    LoginExpired: 'תוקף המנוי פג. \nכדי להאריך את המנוי, לחצו על "הרשמה".',
    LoginNoPay:
        "איזה כיף שהגעת אלינו,\nמיה וכל החברים כבר מחכים לשחק.\nאבל, קודם יש צורך בהרשמה.\n\nהיכנסו למסך הרשמה פה למטה.",
    LoginNoPayNewLogin: "ההרשמה בתשלום.\nכדי להיכנס, עליכם להירשם מחדש\nולבחור בשם משתמש וסיסמה חדשים.",
    LoginSuspended: 'המנוי מוקפא. \nאם תרצו לחדשו, לחצו על "הרשמה".',
    LoginRejected: 'המנוי בוטל. \nאם תרצו לחדשו, לחצו על "הרשמה".',
    EmptyEmail: "יש למלא כתובת דואר אלקטרוני",
    IllegalPasssword: "הסיסמה שהוקלדה - אינה חוקית",
    EmptyUserName: "יש להכניס שם משתמש",
    EmptyPassword: "יש לכתוב סיסמה",
    IllegalSpaceChar: "לא ניתן להקליד רווח",
    IllegalEmail: "כתובת הדואר האלקטרוני אינה חוקית.",
};

export type Status = keyof typeof messages;
