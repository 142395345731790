export const stampMap: any = {
    stamp01: "stamp",
    stamp02: "scarecrow",
    stamp03: "soldier",
    stamp04: "stork",
    stamp05: "telephone",
    stamp06: "boy",
    stamp07: "girl",
    stamp08: "star",
    stamp09: "locomotive",
    stamp10: "pen",
    stamp11: "flashlight",
    stamp12: "detective",
    stamp13: "rabbit",
    stamp14: "balloon",
    stamp15: "wand",
    stamp16: "zebra",
    stamp17: "cook",
    stamp18: "owl",
    stamp19: "clown",
    stamp20: "sun",
    stamp21: "magician",
    stamp22: "dancer",
    stamp23: "clock",
    stamp24: "drum",
    stamp2: "pear",
    stamp26: "organ",
    stamp27: "schoolbag",
    stamp28: "cheees",
    stamp29: "fish",
    stamp30: "flute",
    stamp31: "cat",
    stamp32: "syringe",
    stamp33: "butterfly",
    stamp34: "paints",
    stamp35: "popcicle",
    stamp36: "orange",
    stamp37: "watermelon",
    stamp38: "bicycle",
    stamp39: "icecream",
    stamp40: "doll",
    stamp41: "ball",
    stamp42: "bread",
    stamp43: "tiger",
    stamp44: "book",
    stamp45: "kite",
    stamp46: "monkey",
    stamp47: "robot",
    stamp48: "chokolate",
    stamp49: "chick",
    stamp50: "house",
    stamp51: "match",
    stamp52: "snail",
    stamp53: "beetle",
    stamp54: "peacock",
    stamp55: "moon",
    stamp56: "tree",
    stamp57: "bell",
    stamp58: "mushroom",
    stamp59: "turtle",
    stamp60: "traficlight",
    stamp61: "bottle",
    stamp62: "rose",
    stamp63: "garland",
    stamp64: "ring",
    stamp65: "heart",
    stamp67: "present",
    stamp68: "candle",
    stamp69: "rattle",
    stamp70: "shofar",
    stamp71: "flag",
    stamp72: "apple",
    stamp73: "bus",
    stamp74: "dwarf",
    stamp75: "lion",
    stamp76: "bee",
    stamp77: "dragon",
    stamp80: "airplain",
    stamp81: "snake",
    stamp82: "elephant",
    stamp83: "penguin",
    stamp84: "cop",
    stamp85: "onion",
    stamp86: "rug",
    stamp87: "apple_with_honey",
    stamp88: "rooster",
    stamp89: "crocodile",
    stamp90: "worm",
    stamp91: "tucan",
    stamp95: "pomegranate",
    stamp96: "piegon_with_card",
    stamp97: "hammer",
    stamp98: "sandals",
    stamp99: "king",
    stamp101: "hadjog",
    stamp102: "binoculars",
    stamp103: "feather",
    stamp104: "flowerpot",
    stamp105: "plate",
    stamp106: "cake",
    stamp107: "candy",
    stamp108: "cow",
    stamp109: "pot",
    stamp110: "frog",
    stamp111: "spider",
    stamp112: "umbrella",
    stamp113: "brush",
    stamp114: "bird",
    stamp115: "show",
    stamp116: "rainbow",
    stamp117: "whale",
    stamp118: "fores",
    stamp119: "drop",
    stamp120: "tv",
    stamp121: "spoon",
    stamp122: "glass",
    stamp123: "hat",
    stamp124: "lemon",
    stamp125: "bun",
    stamp126: "crown",
    stamp127: "piegon",
    stamp128: "camel",
    stamp129: "marbles",
    stamp131: "wheel",
    stamp132: "bucket",
    stamp133: "steeringwheel",
    stamp134: "hippo",
    stamp135: "fly",
    stamp136: "donkey",
    stamp137: "fishes",
    stamp138: "teddybear",
    stamp139: "curtain",
    stamp140: "shirt",
};
