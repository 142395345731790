import { waitFor } from "../animate/utils";

export class MyWorksApi {
    private totalWorks: number;
    private userName: string;

    init = async () => {
        await fetch("/PersonalGallery.aspx?sFrom=menu").then(async (response) => {
            await this.updateUserName(response);
        });
        const result = this.getWorksByFrame(1);
        return result;
    };

    getWorkByIndex = async (index: number) => {
        const response = await fetch(
            `gallery/GalleryWork.aspx?sStore=personal&nPicture=${index}&nSize=1065&nRefreshParameter=1`
        );
        const text = await response.text();
        if (index % 3 === 1) {
            this.setTotalWorks(text);
        }
        return this.getworkData(text);
    };
    getTotalWorks = () => {
        return this.totalWorks;
    };
    private setTotalWorks = (text: string) => {
        const matches = text.matchAll(/parent\.SetTotalPictures\((.+)\);/g);
        let result: any = [];
        for (const match of matches) {
            result.push(match[1]);
        }
        this.totalWorks = parseInt(result[0]);
    };

    private updateUserName = async (response: Response) => {
        const text = await response.text();
        const matches = text.matchAll(/oMyWorksMenu\.SetVariable\("(.+)", "(.+)"\);/g);
        let result: any = [];
        for (const match of matches) {
            result.push(match.slice(1, match.length));
        }
        const responseObj = Object.fromEntries(result);
        this.userName = responseObj["/LoadedMovie/cMyWorks:userNameTitle"];
    };

    getUserName = async () => {
        await waitFor(() => this.userName);
        return this.userName;
    };

    private getworkData = (text: string) => {
        const matches = text.matchAll(/object\.SetVariable\("(.+)", "(.+)"\);/g);
        let result: any = [];
        for (const match of matches) {
            result.push(match.slice(1, match.length));
        }
        const responseObj = Object.fromEntries(result);
        return responseObj;
    };

    getWorksByFrame = async (frame: number) => {
        const promises: Promise<any>[] = [];
        [frame, frame + 1, frame + 2].forEach((index) => {
            promises.push(this.getWorkByIndex(index));
        });
        const results = await Promise.all(promises);
        const ids = results.map((result) => result["HTML_nWorkID"]);
        return ids.filter((id) => id);
    };
}
