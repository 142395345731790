import React from "react";
import AnimateCC from "../../../animate";
import { RocketMenu } from "../RocketMenu";
import { FishGame } from "./FishGame";

const FishComponent: React.FC<{
    frame: RocketMenu;
}> = ({ frame }) => {
    const game = React.useRef(new FishGame(frame));

    return (
        <AnimateCC
            base="/lunapark/rocket/fish"
            animationName="fish"
            scale={0.75}
            shiftX={130}
            shiftY={5}
            onLoaded={game.current.loaded}
        />
    );
};
export default FishComponent;
