import React, { useEffect, useState } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import { useUserContext } from "../../context/UserContext";
import { SodRoute } from "../../navigation/PrivateRoot";
import { routes } from "../../navigation/routesPath";
import { ChickensGameComponent } from "../chickens/ChickensGameComponent";
import { InstrumentsGameComponent } from "../instruments/InstrumentsGameComponent";
import { MemoryGameComponent } from "../memory/MemoryGameComponent";
import { SimonGameComponent } from "../simon/SimonGameComponent";
import { MusicMenu } from "./MusicMenu";
import { TrainGameComponent } from "../train/TrainGameComponent";
import { PreloaderComponent } from "../../loaders/PreloaderComponent";

const MusicMenuComponent: React.FC<{}> = () => {
    const { isExact: isMenu } = useRouteMatch();
    const game = React.useRef(new MusicMenu());
    const { isLoggedIn } = useUserContext();
    const directLink = new URLSearchParams(window.location.search).get("path");
    const [fromDirectLink] = useState(directLink);
    const [screenReady, setScreenReady] = useState(false);

    useEffect(() => {
        game.current.isLoggedIn = isLoggedIn;
    }, [isLoggedIn]);

    useEffect(() => {
        if (isMenu) {
            if (game.current) {
                game.current.loaded();
            }
        }
    }, [isMenu]);

    return (
        <>
            {fromDirectLink && !screenReady && <PreloaderComponent name="plain" />}
            <AnimateCC
                visible={isMenu}
                base="/music/music_menu"
                animationName="music_menu"
                onLoaded={(mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc);
                }}
            />
            <Switch>
                <SodRoute path={routes.music.simon}>
                    <SimonGameComponent />
                </SodRoute>
                <SodRoute path={routes.music.chickens}>
                    <ChickensGameComponent />
                </SodRoute>
                <SodRoute path={routes.music.instruments}>
                    <InstrumentsGameComponent />
                </SodRoute>
                <SodRoute path={routes.music.memory}>
                    <MemoryGameComponent />
                </SodRoute>
                <SodRoute path={routes.music.train}>
                    <TrainGameComponent />
                </SodRoute>
            </Switch>
        </>
    );
};
export default MusicMenuComponent;
