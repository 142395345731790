import Game1 from "./gamesForWords/game1";
import Game2 from "./gamesForWords/game2";
import Game3 from "./gamesForWords/game3";
import Game4 from "./gamesForWords/game4";
import Game5 from "./gamesForWords/game5";
import Game6 from "./gamesForWords/game6";
import Game7 from "./gamesForWords/game7";
import Game8 from "./gamesForWords/game8";
import Game9 from "./gamesForWords/game9";
import Game10 from "./gamesForWords/game10";
import Game11 from "./gamesForWords/game11";
import Game12 from "./gamesForWords/game12";
import Game13 from "./gamesForWords/game13";
import Game14 from "./gamesForWords/game14";
import Game15 from "./gamesForWords/game15";
import Game16 from "./gamesForWords/game16";
import Game17 from "./gamesForWords/game17";
import Game18 from "./gamesForWords/game18";
import Game19 from "./gamesForWords/game19";
import Game20 from "./gamesForWords/game20";
import Game21 from "./gamesForWords/game21";
import Game22 from "./gamesForWords/game22";

export function getGame(letter: any) {
    let gameToShow;

    switch (letter) {
        case 1:
            gameToShow = new Game1();
            break;
        case 2:
            gameToShow = new Game2();
            break;
        case 3:
            gameToShow = new Game3();
            break;
        case 4:
            gameToShow = new Game4();
            break;
        case 5:
            gameToShow = new Game5();
            break;
        case 6:
            gameToShow = new Game6();
            break;
        case 7:
            gameToShow = new Game7();
            break;
        case 8:
            gameToShow = new Game8();
            break;
        case 9:
            gameToShow = new Game9();
            break;
        case 10:
            gameToShow = new Game10();
            break;
        case 11:
            gameToShow = new Game11();
            break;
        case 12:
            gameToShow = new Game12();
            break;
        case 13:
            gameToShow = new Game13();
            break;
        case 14:
            gameToShow = new Game14();
            break;
        case 15:
            gameToShow = new Game15();
            break;
        case 16:
            gameToShow = new Game16();
            break;
        case 17:
            gameToShow = new Game17();
            break;
        case 18:
            gameToShow = new Game18();
            break;
        case 19:
            gameToShow = new Game19();
            break;
        case 20:
            gameToShow = new Game20();
            break;
        case 21:
            gameToShow = new Game21();
            break;
        case 22:
            gameToShow = new Game22();
            break;
    }
    return gameToShow;
}

export function showNextButton(letter: any) {
    const showButtons = [
        false,
        false,
        true,
        true,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
    ];
    return showButtons[letter - 1];
}

export function showReloadBtn(letter: any) {
    const showButtons = [
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];
    return showButtons[letter - 1];
}
