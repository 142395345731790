import React, { useEffect } from "react";
import AnimateCC from "../../../animate";
import { RocketMenu } from "../RocketMenu";
import { UnderwaterGame } from "./UnderwaterGame";
import additionalSounds from "./UnderwaterSounds";

const UnderwaterComponent: React.FC<{
    frame: RocketMenu;
}> = ({ frame }) => {
    const game = React.useRef(new UnderwaterGame(frame));
    useEffect(() => {
        const game_instance = game.current;
        return () => {
            game_instance.unload();
        };
    }, []);
    return (
        <AnimateCC
            base="/lunapark/rocket/underwater"
            animationName="underwater"
            scale={0.75}
            shiftX={140}
            shiftY={5}
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default UnderwaterComponent;
