import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, stopAllSounds, waitFor, waitForEvent, waitForTimer } from "../../animate/utils";
import { getRandomArr } from "../../utils/MathUtils";
import MusicGameBase from "../musicGame/MusicGameBase";

export class Simon extends MusicGameBase {
    name = "simon";
    levelData: number[] = [5, 6, 7];
    chosenLevel: number;
    sequenceLength: number;
    totalTools = 9;
    instrumentsList: number[] = [];
    currentSequence: number[] = [];
    sequenceIndex: number;
    posIndex: number;
    shouldPlayInst: boolean;
    enableEvents: boolean;
    enableHelp: () => void;
    disableHelp: () => void;
    prevTool: MC;

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).simon = root;
        // this.loaded();
    }

    resetProps = () => {
        this.removeBtnActions();
        this.sequenceLength = 0;
        this.currentSequence = [];
        this.sequenceIndex = 0;
        this.posIndex = 0;
        // this.shouldPlayInst = true;
    };

    stopTools = () => {
        for (let index = 0; index < this.totalTools; index++) {
            const tool = this.root[`tool${index}`];
            tool.stop();
            tool.gotoAndStop(0);
        }
    };

    handleLevel = (level: number = 1) => {
        stopAllSounds();
        this.disableHelp();
        this.stopTools();
        this.resetProps();
        this.root.gameover.gotoAndStop(0);
        this.chosenLevel = level;
        this.sequenceLength = this.levelData[level - 1];
        this.startGame();
    };

    startGame = async () => {
        // this.getLevelInfo();

        // this.handleLevel();
        if (this.shouldPlayInst) {
            await this.loadAndPlaySound(`/music/simon/sounds/simon_inst.mp3`);
            this.shouldPlayInst = false;
        }
        // this.checkTools();
        this.generateSequence();
    };

    checkTools = async () => {
        for (let index = 0; index < this.totalTools; index++) {
            const tool = this.root[`tool${index}`];
            let endSound = false;
            playSound(`/music/simon/sounds/tool_${index}.mp3`).then(() => {
                endSound = true;
            });
            tool.gotoAndPlay(1);
            await waitForEvent(tool, "endTool");
            tool.gotoAndStop(0);
            await waitFor(() => endSound);
        }
    };

    generateSequence = () => {
        this.currentSequence = getRandomArr(0, this.totalTools - 1, this.sequenceLength);
        this.sequenceIndex = 2;
        // console.log("after generate sequence is:", this.currentSequence);
        this.addBtnActions();
        this.showSequence();
    };

    showSequence = async () => {
        this.disableHelp();
        this.posIndex = 0;
        this.enableEvents = false;
        this.defaultToolsCursor();
        for (let index = 0; index < this.sequenceIndex; index++) {
            const toolNum = this.currentSequence[index];
            const tool = this.root[`tool${toolNum}`];
            let endSound = false;
            playSound(`/music/simon/sounds/tool_${toolNum}.mp3`).then(() => {
                endSound = true;
            });
            tool.gotoAndPlay(1);
            await waitForEvent(tool, "endTool");
            tool.gotoAndStop(0);
            await waitFor(() => endSound);
        }
        this.enableEvents = true;
        this.pointerToolsCursor();
        this.enableHelp();
    };

    defaultToolsCursor = () => {
        for (let index = 0; index < this.totalTools; index++) {
            const tool = this.root[`tool${index}`];
            tool.cursor = "default";
        }
    };

    pointerToolsCursor = () => {
        for (let index = 0; index < this.totalTools; index++) {
            const tool = this.root[`tool${index}`];
            tool.cursor = "pointer";
        }
    };

    addBtnActions = () => {
        for (let index = 0; index < this.totalTools; index++) {
            const sequencePlace = this.currentSequence.indexOf(index);
            // const toolNum = this.ro[index];

            const tool = this.root[`tool${index}`];
            if (index === 5) {
                const hit = new createjs.Shape();
                hit.graphics.beginFill("#000").drawRect(-30, 0, 120, 300);
                tool.hitArea = hit;
            }
            // tool.cursor = "pointer";
            tool.addEventListener("click", () => {
                if (!this.enableEvents) return;
                this.clickTool(tool, sequencePlace, index);
            });
        }
    };

    clickTool = async (tool: MC, posInSequence: number, toolNum: number) => {
        this.prevTool && this.prevTool.gotoAndStop(0);
        if (posInSequence === this.posIndex) {
            this.posIndex++;
            playSound(`/music/simon/sounds/tool_${toolNum}.mp3`);
            tool.gotoAndPlay(1);
            this.prevTool = tool;
            await waitForEvent(tool, "endTool");
            tool.gotoAndStop(0);
            if (this.posIndex === this.sequenceIndex) {
                if (this.sequenceIndex < this.currentSequence.length) {
                    this.enableEvents = false;
                    this.defaultToolsCursor();
                    await waitForTimer(2000);
                    this.showSequence();
                } else {
                    this.endGame();
                }
                this.sequenceIndex++;
            } else {
                this.pointerToolsCursor();
            }
        } else {
            await playSound("/music/simon/sounds/again.mp3");
            this.showSequence();
        }
    };

    removeBtnActions = () => {
        for (let index = 0; index < this.totalTools; index++) {
            const tool = this.root[`tool${index}`];
            tool.removeAllEventListeners();
        }
    };

    endGame = async () => {
        this.enableEvents = false;
        this.defaultToolsCursor();
        this.root.gameover.gotoAndPlay(1);
        this.removeBtnActions();
        await waitForEvent(this.root.gameover, "endAnim");
        await waitForTimer(1600);
        this.handleLevel(this.chosenLevel);
    };

    loadAndPlaySound = async (soundUrl: string) => {
        await loadSound(soundUrl);
        await playSound(soundUrl);
    };

    loadToolSounds = async () => {
        for (let index = 0; index < this.totalTools; index++) {
            await loadSound(`/music/simon/sounds/tool_${index}.mp3`);
        }
        await loadSound("/music/simon/sounds/again.mp3");
    };

    changeObjectsFramerate = () => {
        let tool: MC;
        for (let index = 0; index < this.totalTools; index++) {
            tool = this.root[`tool${index}`];
            tool.framerate = 20;
        }
        this.root.gameover.framerate = 20;
    };

    loaded = async (level: number = 1) => {
        this.shouldPlayInst = true;
        await waitFor(() => this.root);
        this.handleLevel(level);
        this.changeObjectsFramerate();
        await this.loadToolSounds();
    };
}
