import { MC, waitForEvent } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game4 extends BaseGame {
    game: any;
    helpBtn: any;
    buttons = ["btnK", "btnM", "btnP", "btnR"];
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.game = game;
        this.helpBtn = helpBtn;
        game.visible = true;
        game.letters.gotoAndStop(0);
        (window as any).game = game;
        for (let i = 0; i < 4; i++) {
            game[this.buttons[i]].gotoAndStop(0);
        }
        game.letters.gotoAndStop(0);
        game.gotoAndPlay(1);
        game.letters.gotoAndStop(0);
        if (!this.shouldPlay) return;
        await waitForEvent(game, "questionEnd");
        if (!this.shouldPlay) return;

        for (let i = 0; i < 4; i++) {
            const currentBtn = game[this.buttons[i]];
            currentBtn.cursor = "pointer";
            game.letters.gotoAndStop(0);
            currentBtn.gotoAndStop(3);
            currentBtn.alpha = 0.01;
            currentBtn.addEventListener("click", () => {
                this.checkAnswer(currentBtn);
            });
        }
        this.helpBtn(false);
        gameEnd();
    };
    checkAnswer = async (answer: MC) => {
        this.helpBtn(true);
        if (answer.name === "btnR") {
            this.game.gotoAndPlay(165);
            this.game.letters.gotoAndStop("r");
        } else if (answer.name === "btnP") {
            this.game.gotoAndPlay(361);
            this.game.letters.gotoAndStop("p");
        } else if (answer.name === "btnM") {
            this.game.gotoAndPlay(534);
            this.game.letters.gotoAndStop("m");
        } else if (answer.name === "btnK") {
            this.game.gotoAndPlay(724);
            this.game.letters.gotoAndStop("k");
        }
        if (!this.shouldPlay) return;

        await waitForEvent(this.game, `${answer.name}End`);
        if (!this.shouldPlay) return;

        this.game.letters.gotoAndStop(0);
        this.helpBtn(false);
        this.game.gotoAndStop(157);
        for (let i = 0; i < 4; i++) {
            this.game[this.buttons[i]].gotoAndStop(3);
        }
    };
}
