const additionalSounds = [
    {
        src: "sounds/sound1.mp3",
        id: "sound1",
    },
    {
        src: "sounds/Sound2.mp3",
        id: "sound2",
    },
    {
        src: "sounds/Sound3.mp3",
        id: "sound3",
    },
    {
        src: "sounds/Sound4.mp3",
        id: "sound4",
    },
    {
        src: "sounds/Sound5.mp3",
        id: "sound5",
    },
    {
        src: "sounds/Sound6.mp3",
        id: "sound6",
    },
    {
        src: "sounds/Sound7.mp3",
        id: "sound7",
    },
    {
        src: "sounds/Sound8.mp3",
        id: "sound8",
    },
    {
        src: "sounds/CT.mp3",
        id: "CT",
    },
    {
        src: "sounds/NA.mp3",
        id: "NA",
    },
    {
        src: "sounds/NB.mp3",
        id: "NB",
    },
    {
        src: "sounds/NC.mp3",
        id: "NC",
    },
    {
        src: "sounds/ND.mp3",
        id: "ND",
    },
    {
        src: "sounds/NE.mp3",
        id: "NE",
    },
    {
        src: "sounds/NF.mp3",
        id: "NF",
    },
    {
        src: "sounds/NG.mp3",
        id: "NG",
    },
    {
        src: "sounds/NBF.mp3",
        id: "NBF",
    },
    {
        src: "sounds/NCS.mp3",
        id: "NCS",
    },
    {
        src: "sounds/NEF.mp3",
        id: "NEF",
    },
    {
        src: "sounds/NFS.mp3",
        id: "NFS",
    },
    {
        src: "sounds/NGS.mp3",
        id: "NGS",
    },
    {
        src: "sounds/crash.mp3",
        id: "crash",
    },
];
export default additionalSounds;
