import { History } from "history";
import { getUserId } from "../api/login";
import { routes } from "./routesPath";
import { generatePath } from "react-router-dom";

export class Navigation {
    static history: History;
    static setIframeSrc: (val: string) => void;
    static setShowLogin: (val: boolean) => void;
    static setShowRegistration: (val: boolean) => void;

    static init = (
        h: History,
        setIframeSrc: (val: string) => void,
        setShowLogin: (val: boolean) => void,
        setShowRegistration: (val: boolean) => void
    ) => {
        Navigation.history = h;
        Navigation.setIframeSrc = setIframeSrc;
        Navigation.setShowLogin = setShowLogin;
        Navigation.setShowRegistration = setShowRegistration;

        const w = window as any;

        const hideActiveIframe = () => {
            setIframeSrc("");
            setShowRegistration(false);
        };
        w.hideActiveIframe = hideActiveIframe;

        w.showContacts = () => {
            setIframeSrc("");
            Navigation.openPage(generatePath(routes.creation, { viewMode: "contacts" }));
        };

        window.addEventListener("message", (e) => {
            if (e.data === "hideActiveIframe") {
                hideActiveIframe();
            }
        });

        w.goToLogin = () => {
            setShowLogin(true);
        };

        w.goToRegistration = Navigation.gotoRegistration;
    };

    static openPage = (path: string) => {
        setImmediate(() => {
            Navigation.history.replace(path);
        });
    };

    // static gotoLogin = (returnUrl?: string) => {
    //     Navigation.openPage(
    //         generatePath(routes.login, { returnUrl: returnUrl ?? Navigation.history.location.pathname })
    //     );
    // };
    static gotoLogin = () => {
        Navigation.openPage(generatePath(routes.login, { returnUrl: Navigation.history.location.pathname }));
    };

    static openIframe = (src: string) => {
        Navigation.setIframeSrc(src);
    };

    static gotoRegistration = () => {
        Navigation.setShowRegistration(true);
        const teleromIframe =
            window.location.host.includes("testing") || window.location.host.includes("dev")
                ? "https://telerom.co.il/Sod24testing/index.aspx"
                : "https://telerom.co.il/Sod21Test/index.aspx";
        const url = teleromIframe + "?gUserID=" + (getUserId() ?? "");
        Navigation.openIframe(url);
        Navigation.setShowLogin(false);
    };
}
