import { loadSound } from "../../../animate/sound_utils";
import { localToGlobal, MC, playSound } from "../../../animate/utils";
import { SpecialGameBasic } from "../../SpecialGameBasic";

const FRAME_LIMIT = 430;
const DISH_WIDTH = 151.5;
const DISH_HEIGHT = 54;

const MEASUREMENTS = {
    bad: [
        { height: 56.65, width: 46.4 },
        { height: 44.25, width: 48.15 },
        { height: 74, width: 76.4 },
        { height: 42.25, width: 63.55 },
        { height: 38.4, width: 59.25 },
    ],
    good: [
        { height: 28, width: 75.35 },
        { height: 47, width: 76.15 },
    ],
};

const STEP = 10;
const TIMER = 30;
const SCORE_PER_ITEM = 10;
const TIMER_SCALE = 0.9867;
const LEFT_BOAND = 65;
const RIGHT_BOAND = 624;
const STAGE_BOUND = 815;
const DOG_LOCATION = 284;
const DELAY = 2000;
const DROP_RATE = 2;
const DROP_RANGE = 1;

export class DogGame extends SpecialGameBasic {
    private nScore: number = 0;
    timer: number = TIMER;
    timerInt: any;
    onEndInterval: any;
    helpSound = "/special_games/dog/sounds/help.mp3";
    bGSound = "/special_games/dog/sounds/music.mp3";
    instructionSound = "/special_games/dog/sounds/inst.mp3";
    goodSound = "/special_games/dog/sounds/good.mp3";
    badSound = "/special_games/dog/sounds/bad.mp3";
    endSound = "/special_games/dog/sounds/end.mp3";
    goodThingCnt: number;
    dropedIndex = 0;
    dropSpotCnt: number;
    lastDroppedIndex: number;
    badThingCnt: number;
    dropItemsInterval: number;
    intervalArray: any[] = [];

    g_dish: createjs.Shape = new createjs.Shape();
    g_item: createjs.Shape = new createjs.Shape();

    loaded = async (root: MC) => {
        this.root = root;
        this.root.scorePannel.txtScore.text = "";
        this.root.mcPlayer.dish.visible = false;
        this.root.splash.gotoAndPlay(1);
        this.dropSpotCnt = this.getDropSpotsNumber();
        this.initBtns();
        this.root.splash.bg.addEventListener("click", () => {
            this.onGameStart();
            this.clearOnGameStart();
        });
        await super.playInstSound().then(() => {
            this.setGameActive();
        });

        root.addChild(this.g_dish);
        root.addChild(this.g_item);
    };

    async setGameActive() {
        await this.setBgSound();
        this.bgSound.volume = 0.5;
        this.enableHelp();
        this.enableSpeaker();
    }

    onGameStart() {
        this.addKeyboardEvents();
        this.root.splash.gotoAndStop(0);
        this.startTimer();
        this.root.mcPlayer.dish.visible = true;
        this.root.scorePannel.txtScore.text = this.nScore;
    }

    clearOnGameStart() {
        if (this.dropItemsInterval) {
            window.clearInterval(this.dropItemsInterval);
        }
        this.dropItemsInterval = window.setInterval(this.addItem, DELAY);
        this.root.scorePannel.txtScore.text = this.nScore;
    }

    initBtns() {
        this.disableHelpInClicking = true;
        this.initExit();
        this.initHelp();
        this.initSpeker();
        this.initIconMc();
        this.enableSpeaker();
        this.enableHelp();
    }

    addKeyboardEvents = async () => {
        document.removeEventListener("keydown", this.keyBoardEvents);
        document.addEventListener("keydown", this.keyBoardEvents);
    };

    exit = () => {
        this.clearOnExit();
        super.exit();
    };

    clearOnExit = () => {
        this.clearOnGameOver();
        this.resetTimer();
        this.root.splash.bg.removeEventListener("click");
    };

    clearOnGameOver() {
        window.clearInterval(this.dropItemsInterval);
        this.intervalArray.forEach((interval) => {
            if (interval) {
                window.clearInterval(interval);
            }
        });

        //hide all visible items
        for (let index = 0; index < this.dropedIndex; index++) {
            const elemnt = this.root.getChildByName(`itemDroped${index}`);
            if (elemnt) {
                elemnt.visible = false;
                this.root.removeChild(this.root.getChildByName(elemnt.name));
            }
        }
        this.intervalArray = [];
        this.dropedIndex = 0;
        this.nScore = 0;
        document.removeEventListener("keydown", this.keyBoardEvents);
    }

    keyBoardEvents = (e: any) => {
        switch (e.key) {
            case "ArrowLeft":
                this.playerMoveLeft();
                break;
            case "ArrowRight":
                this.playerMoveRight();
                break;
            default:
                break;
        }
    };

    playerMoveRight() {
        if (this.root.mcPlayer.x + STEP < RIGHT_BOAND) {
            this.root.mcPlayer.x += STEP;
        }
    }

    playerMoveLeft() {
        if (this.root.mcPlayer.x - STEP > LEFT_BOAND) {
            this.root.mcPlayer.x -= STEP;
        }
    }

    async addScore() {
        this.nScore += SCORE_PER_ITEM;
        this.root.scorePannel.txtScore.text = this.nScore;
        await loadSound(this.goodSound);
        await playSound(this.goodSound);
    }

    onItemCaught(item: MC) {
        if (item.type === "good") {
            this.goodItemCatched();
        } else {
            this.badItemCatched();
        }
    }

    goodItemCatched = async () => {
        await loadSound(this.goodSound);
        playSound(this.goodSound);
        this.addScore();
    };

    badItemCatched = async () => {
        await loadSound(this.badSound);
        await playSound(this.badSound);
    };

    updateTimer = () => {
        return window.setInterval(async () => {
            this.timer--;
            if (this.timer <= 0) {
                this.gameOver();
                window.clearInterval(this.timerInt);
            } else {
                this.root.mcTimer.bar.innerBar.scaleX = this.timer / TIMER;
                //  updateAfterEvent();
            }
        }, 1000);
    };

    startTimer() {
        this.resetTimer();
        if (this.timerInt) {
            window.clearInterval(this.timerInt);
        }
        this.timerInt = this.updateTimer();
    }

    resetTimer() {
        window.clearInterval(this.timerInt);
        window.clearInterval(this.onEndInterval);
        this.timer = TIMER;
        this.root.mcTimer.bar.innerBar.scaleX = TIMER_SCALE;
    }

    async gameOver() {
        this.clearOnGameOver();
        await loadSound(this.endSound);
        playSound(this.endSound);
        this.onEndInterval = window.setInterval(this.onEndDogAnimation, 50);
    }

    onEndDogAnimation = async () => {
        this.root.mcPlayer.x += STEP;
        if (this.root.mcPlayer.x >= STAGE_BOUND) {
            this.root.mcPlayer.dish.visible = false;
            this.root.mcPlayer.x = 0;
        }
        if (!!!this.root.mcPlayer.dish.visible && this.root.mcPlayer.x >= DOG_LOCATION) {
            this.resetTimer();
            this.root.mcPlayer.x = DOG_LOCATION;
            this.root.splash.gotoAndPlay(1);
            return;
        }
    };

    getDropSpotsNumber() {
        var cnt = 0;
        while (this.root[`dropSpot${cnt}`] !== undefined) cnt++;
        return cnt;
    }

    addItem = () => {
        let spotToDropFromIndex = Math.floor(Math.random() * this.dropSpotCnt);
        if (this.dropSpotCnt > 1) {
            while (spotToDropFromIndex === this.lastDroppedIndex) {
                spotToDropFromIndex = Math.floor(Math.random() * this.dropSpotCnt);
            }
        }
        this.lastDroppedIndex = spotToDropFromIndex;
        const i = Math.random();
        this.importItem(i < 0.7 ? "good" : "bad");
        const item = this.root.getChildByName(`itemDroped${this.dropedIndex}`);
        item.gotoAndStop(Math.floor(Math.random() * item.duration) + 1);
        const nSpeed = Math.floor(Math.random() * DROP_RANGE + DROP_RATE);
        this.intervalArray[this.dropedIndex] = this.dropItem(item, nSpeed, this.dropedIndex);

        item.x = this.root[`dropSpot${spotToDropFromIndex}`].x;
        item.y = this.root[`dropSpot${spotToDropFromIndex}`].y;
        this.dropedIndex++;
    };

    importItem = (type: string) => {
        const item = new this.root.lib[`${type}Thing`]();
        item.name = `itemDroped${this.dropedIndex}`;
        item.type = type;
        this.root.addChild(item);
    };

    dropItem = (item: MC, speed: number, dropIndex: number) => {
        return window.setInterval(() => {
            item.y += speed;
            if (this.isIntersecting(item) || item.y > FRAME_LIMIT) {
                item.visible = false;
                window.clearInterval(this.intervalArray[dropIndex]);
                if (this.isIntersecting(item)) {
                    this.onItemCaught(item);
                }
                this.root.removeChild(this.root.getChildByName(item.name));

                return false;
            }
        }, 40);
    };

    isIntersecting = (item: MC) => {
        const localDishArea = localToGlobal(this.root.mcPlayer.dish, 0, 0);
        const dishRect = new createjs.Rectangle(localDishArea.x, localDishArea.y, DISH_WIDTH, DISH_HEIGHT);

        const localItemArea = localToGlobal(item, 0, 0);
        const measurement = this.getMeasurement(item);
        const itemRect = new createjs.Rectangle(
            localItemArea.x - measurement.width / 2,
            localItemArea.y - measurement.height / 2,
            measurement.width,
            measurement.height
        );

        /*
                this.g_dish.graphics.c().f("red").r(dishRect.x, dishRect.y, dishRect.width, dishRect.height)
                this.g_item.graphics.c().f("green").r(itemRect.x, itemRect.y, itemRect.width, itemRect.height)
        */

        return dishRect.intersects(itemRect);
    };

    getMeasurement = (item: MC) => {
        return MEASUREMENTS[item.type as "good" | "bad"][item.currentFrame];
    };
}
