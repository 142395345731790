import React, { useState } from "react";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import { BibleFrame } from "../story_menu/BibleFrame";
import { TreeGame } from "./TreeGame";
import styles from "./tree-printing.module.scss";

const TreeGameComponent: React.FC<{ frame: BibleFrame; onLoaded: () => void }> = ({ frame, onLoaded }) => {
    const game = React.useRef(new TreeGame(frame));
    const [shouldPrint, setShouldPrint] = useState(null);

    return (
        <>
            {shouldPrint && (
                <>
                    <AnimateCC
                        base="/bible_stories/tree_game"
                        animationName="tree_game"
                        onLoaded={game.current.prepareForPrint}
                        print
                    />
                    <div className={styles.footer_english}>http://www.sodmaya.co.il</div>
                    <div className={styles.footer_Hebrew}>הסוד של מיה ©</div>
                </>
            )}
            <AnimateCC
                base="/bible_stories/tree_game"
                animationName="tree_game"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc, setShouldPrint);
                    onLoaded();
                }}
                frameHeight={789}
                frameWidth={1034}
                shiftX={103}
                shiftY={108}
                // scale = {0.98}
            />
        </>
    );
};

export default TreeGameComponent;
