import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { TreeGame } from "./TreeGame";
import additionalSounds from "./TreeGameSounds";
const TreeComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new TreeGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/tree"
            animationName="tree"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
            changeFramerate={true}
        />
    );
};
export default TreeComponent;
