import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "./routesPath";
import { isDebug } from "../utils/DebugUtils";

(window as any)._uacct = "UA-424936-3";
const urchinTracker = (window as any).urchinTracker;

export function SodMayaTracking(path: string): void {
    if (isDebug) {
        console.log(path);
    }
    if (urchinTracker && path !== "") {
        urchinTracker(path);
    }
}

export const GoogleAnalytics: React.FC = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname !== routes.home) {
            SodMayaTracking(pathname);
        }
    }, [pathname]);

    return null;
};
