export const objectsSounds = [
    {
        src: "/letters_island/common/objects/airplane.mp3",
        id: "airplane",
    },
    {
        src: "/letters_island/common/objects/apple.mp3",
        id: "apple",
    },
    {
        src: "/letters_island/common/objects/arrow.mp3",
        id: "arrow",
    },
    {
        src: "/letters_island/common/objects/baby.mp3",
        id: "baby",
    },
    {
        src: "/letters_island/common/objects/ball.mp3",
        id: "ball",
    },
    {
        src: "/letters_island/common/objects/balloon.mp3",
        id: "balloon",
    },
    {
        src: "/letters_island/common/objects/basket.mp3",
        id: "basket",
    },
    {
        src: "/letters_island/common/objects/beard.mp3",
        id: "beard",
    },
    {
        src: "/letters_island/common/objects/bee.mp3",
        id: "bee",
    },
    {
        src: "/letters_island/common/objects/beetle.mp3",
        id: "beetle",
    },
    {
        src: "/letters_island/common/objects/bell.mp3",
        id: "bell",
    },
    {
        src: "/letters_island/common/objects/bengurion.mp3",
        id: "bengurion",
    },
    {
        src: "/letters_island/common/objects/bicycle.mp3",
        id: "bicycle",
    },
    {
        src: "/letters_island/common/objects/binoculars.mp3",
        id: "binoculars",
    },
    {
        src: "/letters_island/common/objects/bird.mp3",
        id: "bird",
    },
    {
        src: "/letters_island/common/objects/board.mp3",
        id: "board",
    },
    {
        src: "/letters_island/common/objects/book.mp3",
        id: "book",
    },
    {
        src: "/letters_island/common/objects/bottle.mp3",
        id: "bottle",
    },
    {
        src: "/letters_island/common/objects/boy.mp3",
        id: "boy",
    },
    {
        src: "/letters_island/common/objects/bread.mp3",
        id: "bread",
    },
    {
        src: "/letters_island/common/objects/brush.mp3",
        id: "brush",
    },
    {
        src: "/letters_island/common/objects/bucket.mp3",
        id: "bucket",
    },
    {
        src: "/letters_island/common/objects/building.mp3",
        id: "building",
    },
    {
        src: "/letters_island/common/objects/bun.mp3",
        id: "bun",
    },
    {
        src: "/letters_island/common/objects/bus.mp3",
        id: "bus",
    },
    {
        src: "/letters_island/common/objects/butterfly.mp3",
        id: "butterfly",
    },
    {
        src: "/letters_island/common/objects/button.mp3",
        id: "button",
    },
    {
        src: "/letters_island/common/objects/cake.mp3",
        id: "cake",
    },
    {
        src: "/letters_island/common/objects/camel.mp3",
        id: "camel",
    },
    {
        src: "/letters_island/common/objects/candle.mp3",
        id: "candle",
    },
    {
        src: "/letters_island/common/objects/candy.mp3",
        id: "candy",
    },
    {
        src: "/letters_island/common/objects/carrot.mp3",
        id: "carrot",
    },
    {
        src: "/letters_island/common/objects/cat.mp3",
        id: "cat",
    },
    {
        src: "/letters_island/common/objects/chair.mp3",
        id: "chair",
    },
    {
        src: "/letters_island/common/objects/cheese.mp3",
        id: "cheese",
    },
    {
        src: "/letters_island/common/objects/chick.mp3",
        id: "chick",
    },
    {
        src: "/letters_island/common/objects/children.mp3",
        id: "children",
    },
    {
        src: "/letters_island/common/objects/chokolate.mp3",
        id: "chokolate",
    },
    {
        src: "/letters_island/common/objects/clock.mp3",
        id: "clock",
    },
    {
        src: "/letters_island/common/objects/clown.mp3",
        id: "clown",
    },
    {
        src: "/letters_island/common/objects/cook.mp3",
        id: "cook",
    },
    {
        src: "/letters_island/common/objects/cop.mp3",
        id: "cop",
    },
    {
        src: "/letters_island/common/objects/cow.mp3",
        id: "cow",
    },
    {
        src: "/letters_island/common/objects/crocodile.mp3",
        id: "crocodile",
    },
    {
        src: "/letters_island/common/objects/crown.mp3",
        id: "crown",
    },
    {
        src: "/letters_island/common/objects/cup.mp3",
        id: "cup",
    },
    {
        src: "/letters_island/common/objects/curtain.mp3",
        id: "curtain",
    },
    {
        src: "/letters_island/common/objects/dancer.mp3",
        id: "dancer",
    },
    {
        src: "/letters_island/common/objects/deer.mp3",
        id: "deer",
    },
    {
        src: "/letters_island/common/objects/detective.mp3",
        id: "detective",
    },
    {
        src: "/letters_island/common/objects/dice.mp3",
        id: "dice",
    },
    {
        src: "/letters_island/common/objects/dog.mp3",
        id: "dog",
    },
    {
        src: "/letters_island/common/objects/doll.mp3",
        id: "doll",
    },
    {
        src: "/letters_island/common/objects/donkey.mp3",
        id: "donkey",
    },
    {
        src: "/letters_island/common/objects/dragon.mp3",
        id: "dragon",
    },
    {
        src: "/letters_island/common/objects/drop.mp3",
        id: "drop",
    },
    {
        src: "/letters_island/common/objects/drum.mp3",
        id: "drum",
    },
    {
        src: "/letters_island/common/objects/duck.mp3",
        id: "duck",
    },
    {
        src: "/letters_island/common/objects/dwarf.mp3",
        id: "dwarf",
    },
    {
        src: "/letters_island/common/objects/eagle.mp3",
        id: "eagle",
    },
    {
        src: "/letters_island/common/objects/egg.mp3",
        id: "egg",
    },
    {
        src: "/letters_island/common/objects/elephant.mp3",
        id: "elephant",
    },
    {
        src: "/letters_island/common/objects/feather.mp3",
        id: "feather",
    },
    {
        src: "/letters_island/common/objects/fish.mp3",
        id: "fish",
    },
    {
        src: "/letters_island/common/objects/fishes.mp3",
        id: "fishes",
    },
    {
        src: "/letters_island/common/objects/flag.mp3",
        id: "flag",
    },
    {
        src: "/letters_island/common/objects/flashlight.mp3",
        id: "flashlight",
    },
    {
        src: "/letters_island/common/objects/flower.mp3",
        id: "flower",
    },
    {
        src: "/letters_island/common/objects/flowerpot.mp3",
        id: "flowerpot",
    },
    {
        src: "/letters_island/common/objects/flute.mp3",
        id: "flute",
    },
    {
        src: "/letters_island/common/objects/fly.mp3",
        id: "fly",
    },
    {
        src: "/letters_island/common/objects/forest.mp3",
        id: "forest",
    },
    {
        src: "/letters_island/common/objects/fork.mp3",
        id: "fork",
    },
    {
        src: "/letters_island/common/objects/frog.mp3",
        id: "frog",
    },
    {
        src: "/letters_island/common/objects/garland.mp3",
        id: "garland",
    },
    {
        src: "/letters_island/common/objects/garlic.mp3",
        id: "garlic",
    },
    {
        src: "/letters_island/common/objects/girl.mp3",
        id: "girl",
    },
    {
        src: "/letters_island/common/objects/glass.mp3",
        id: "glass",
    },
    {
        src: "/letters_island/common/objects/guitar.mp3",
        id: "guitar",
    },
    {
        src: "/letters_island/common/objects/hammer.mp3",
        id: "hammer",
    },
    {
        src: "/letters_island/common/objects/hanger.mp3",
        id: "hanger",
    },
    {
        src: "/letters_island/common/objects/hat.mp3",
        id: "hat",
    },
    {
        src: "/letters_island/common/objects/heart.mp3",
        id: "heart",
    },
    {
        src: "/letters_island/common/objects/hedghog.mp3",
        id: "hedghog",
    },
    {
        src: "/letters_island/common/objects/helmet.mp3",
        id: "helmet",
    },
    {
        src: "/letters_island/common/objects/herzel.mp3",
        id: "herzel",
    },
    {
        src: "/letters_island/common/objects/hippo.mp3",
        id: "hippo",
    },
    {
        src: "/letters_island/common/objects/hook.mp3",
        id: "hook",
    },
    {
        src: "/letters_island/common/objects/house.mp3",
        id: "house",
    },
    {
        src: "/letters_island/common/objects/icecream.mp3",
        id: "icecream",
    },
    {
        src: "/letters_island/common/objects/iron.mp3",
        id: "iron",
    },
    {
        src: "/letters_island/common/objects/israel_logo.mp3",
        id: "israel_logo",
    },
    {
        src: "/letters_island/common/objects/jar.mp3",
        id: "jar",
    },
    {
        src: "/letters_island/common/objects/jerusalem.mp3",
        id: "jerusalem",
    },
    {
        src: "/letters_island/common/objects/king.mp3",
        id: "king",
    },
    {
        src: "/letters_island/common/objects/kite.mp3",
        id: "kite",
    },
    {
        src: "/letters_island/common/objects/knife.mp3",
        id: "knife",
    },
    {
        src: "/letters_island/common/objects/leg.mp3",
        id: "leg",
    },
    {
        src: "/letters_island/common/objects/lemon.mp3",
        id: "lemon",
    },
    {
        src: "/letters_island/common/objects/lion.mp3",
        id: "lion",
    },
    {
        src: "/letters_island/common/objects/locomotive.mp3",
        id: "locomotive",
    },
    {
        src: "/letters_island/common/objects/magician.mp3",
        id: "magician",
    },
    {
        src: "/letters_island/common/objects/marbles.mp3",
        id: "marbles",
    },
    {
        src: "/letters_island/common/objects/match.mp3",
        id: "match",
    },
    {
        src: "/letters_island/common/objects/monkey.mp3",
        id: "monkey",
    },
    {
        src: "/letters_island/common/objects/moon.mp3",
        id: "moon",
    },
    {
        src: "/letters_island/common/objects/mountain.mp3",
        id: "mountain",
    },
    {
        src: "/letters_island/common/objects/mouse.mp3",
        id: "mouse",
    },
    {
        src: "/letters_island/common/objects/mushroom.mp3",
        id: "mushroom",
    },
    {
        src: "/letters_island/common/objects/nut.mp3",
        id: "nut",
    },
    {
        src: "/letters_island/common/objects/olive.mp3",
        id: "olive",
    },
    {
        src: "/letters_island/common/objects/onion.mp3",
        id: "onion",
    },
    {
        src: "/letters_island/common/objects/orange.mp3",
        id: "orange",
    },
    {
        src: "/letters_island/common/objects/organ_nos.mp3",
        id: "organ_nos",
    },
    {
        src: "/letters_island/common/objects/organ.mp3",
        id: "organ",
    },
    {
        src: "/letters_island/common/objects/owl.mp3",
        id: "owl",
    },
    {
        src: "/letters_island/common/objects/pacifier.mp3",
        id: "pacifier",
    },
    {
        src: "/letters_island/common/objects/paints.mp3",
        id: "paints",
    },
    {
        src: "/letters_island/common/objects/peacock.mp3",
        id: "peacock",
    },
    {
        src: "/letters_island/common/objects/pear.mp3",
        id: "pear",
    },
    {
        src: "/letters_island/common/objects/pen.mp3",
        id: "pen",
    },
    {
        src: "/letters_island/common/objects/pencil.mp3",
        id: "pencil",
    },
    {
        src: "/letters_island/common/objects/penguin.mp3",
        id: "penguin",
    },
    {
        src: "/letters_island/common/objects/pepper.mp3",
        id: "pepper",
    },
    {
        src: "/letters_island/common/objects/pigeon_with_olive.mp3",
        id: "pigeon_with_olive",
    },
    {
        src: "/letters_island/common/objects/pigeon.mp3",
        id: "pigeon",
    },
    {
        src: "/letters_island/common/objects/pink.mp3",
        id: "pink",
    },
    {
        src: "/letters_island/common/objects/plate.mp3",
        id: "plate",
    },
    {
        src: "/letters_island/common/objects/pomegranate.mp3",
        id: "pomegranate",
    },
    {
        src: "/letters_island/common/objects/popcicle.mp3",
        id: "popcicle",
    },
    {
        src: "/letters_island/common/objects/pot.mp3",
        id: "pot",
    },
    {
        src: "/letters_island/common/objects/present.mp3",
        id: "present",
    },
    {
        src: "/letters_island/common/objects/pumpkin.mp3",
        id: "pumpkin",
    },
    {
        src: "/letters_island/common/objects/rabbit.mp3",
        id: "rabbit",
    },
    {
        src: "/letters_island/common/objects/radish.mp3",
        id: "radish",
    },
    {
        src: "/letters_island/common/objects/rainbow.mp3",
        id: "rainbow",
    },
    {
        src: "/letters_island/common/objects/rattle.mp3",
        id: "rattle",
    },
    {
        src: "/letters_island/common/objects/rhino.mp3",
        id: "rhino",
    },
    {
        src: "/letters_island/common/objects/ring.mp3",
        id: "ring",
    },
    {
        src: "/letters_island/common/objects/robot.mp3",
        id: "robot",
    },
    {
        src: "/letters_island/common/objects/rooster.mp3",
        id: "rooster",
    },
    {
        src: "/letters_island/common/objects/rose.mp3",
        id: "rose",
    },
    {
        src: "/letters_island/common/objects/rug.mp3",
        id: "rug",
    },
    {
        src: "/letters_island/common/objects/ruler.mp3",
        id: "ruler",
    },
    {
        src: "/letters_island/common/objects/sandals.mp3",
        id: "sandals",
    },
    {
        src: "/letters_island/common/objects/scarecrow.mp3",
        id: "scarecrow",
    },
    {
        src: "/letters_island/common/objects/schoolbag.mp3",
        id: "schoolbag",
    },
    {
        src: "/letters_island/common/objects/scissors.mp3",
        id: "scissors",
    },
    {
        src: "/letters_island/common/objects/screw.mp3",
        id: "screw",
    },
    {
        src: "/letters_island/common/objects/screwdriver.mp3",
        id: "screwdriver",
    },
    {
        src: "/letters_island/common/objects/seashell.mp3",
        id: "seashell",
    },
    {
        src: "/letters_island/common/objects/shell.mp3",
        id: "shell",
    },
    {
        src: "/letters_island/common/objects/shirt.mp3",
        id: "shirt",
    },
    {
        src: "/letters_island/common/objects/shoe.mp3",
        id: "shoe",
    },
    {
        src: "/letters_island/common/objects/shofar.mp3",
        id: "shofar",
    },
    {
        src: "/letters_island/common/objects/snail.mp3",
        id: "snail",
    },
    {
        src: "/letters_island/common/objects/snake.mp3",
        id: "snake",
    },
    {
        src: "/letters_island/common/objects/soldier_girl.mp3",
        id: "soldier_girl",
    },
    {
        src: "/letters_island/common/objects/soldier.mp3",
        id: "soldier",
    },
    {
        src: "/letters_island/common/objects/spider.mp3",
        id: "spider",
    },
    {
        src: "/letters_island/common/objects/spoon.mp3",
        id: "spoon",
    },
    {
        src: "/letters_island/common/objects/stamp.mp3",
        id: "stamp",
    },
    {
        src: "/letters_island/common/objects/star.mp3",
        id: "star",
    },
    {
        src: "/letters_island/common/objects/steeringwheel.mp3",
        id: "steeringwheel",
    },
    {
        src: "/letters_island/common/objects/stork.mp3",
        id: "stork",
    },
    {
        src: "/letters_island/common/objects/sun.mp3",
        id: "sun",
    },
    {
        src: "/letters_island/common/objects/syringe.mp3",
        id: "syringe",
    },
    {
        src: "/letters_island/common/objects/table.mp3",
        id: "table",
    },
    {
        src: "/letters_island/common/objects/tap.mp3",
        id: "tap",
    },
    {
        src: "/letters_island/common/objects/teddyBear.mp3",
        id: "teddyBear",
    },
    {
        src: "/letters_island/common/objects/telephone.mp3",
        id: "telephone",
    },
    {
        src: "/letters_island/common/objects/tent.mp3",
        id: "tent",
    },
    {
        src: "/letters_island/common/objects/tiger.mp3",
        id: "tiger",
    },
    {
        src: "/letters_island/common/objects/torch.mp3",
        id: "torch",
    },
    {
        src: "/letters_island/common/objects/traficlight.mp3",
        id: "traficlight",
    },
    {
        src: "/letters_island/common/objects/tree.mp3",
        id: "tree",
    },
    {
        src: "/letters_island/common/objects/tucan.mp3",
        id: "tucan",
    },
    {
        src: "/letters_island/common/objects/turtle.mp3",
        id: "turtle",
    },
    {
        src: "/letters_island/common/objects/tv.mp3",
        id: "tv",
    },
    {
        src: "/letters_island/common/objects/umbrella.mp3",
        id: "umbrella",
    },
    {
        src: "/letters_island/common/objects/undershirt.mp3",
        id: "undershirt",
    },
    {
        src: "/letters_island/common/objects/upsideDown.mp3",
        id: "upsideDown",
    },
    {
        src: "/letters_island/common/objects/waffel.mp3",
        id: "waffel",
    },
    {
        src: "/letters_island/common/objects/walk.mp3",
        id: "walk",
    },
    {
        src: "/letters_island/common/objects/wand.mp3",
        id: "wand",
    },
    {
        src: "/letters_island/common/objects/watermelon.mp3",
        id: "watermelon",
    },
    {
        src: "/letters_island/common/objects/whale.mp3",
        id: "whale",
    },
    {
        src: "/letters_island/common/objects/wheel.mp3",
        id: "wheel",
    },
    {
        src: "/letters_island/common/objects/worm.mp3",
        id: "worm",
    },
    {
        src: "/letters_island/common/objects/zahal_logo.mp3",
        id: "zahal_logo",
    },
    {
        src: "/letters_island/common/objects/zebra.mp3",
        id: "zebra",
    },
];
