import React, { useState, useEffect } from "react";
import AnimateCC from "../../animate";
import additionalSounds from "./sounds_letterMenu.json";
import { MC, playSoundSync } from "../../animate/utils";
import { useParams, useRouteMatch } from "react-router-dom";
import { routes } from "../../navigation/routesPath";
import { LetterMenu } from "./LetterMenu";
import LetterActivitiesComponent from "../letterActivities/LetterActivitiesComponent";
import { useLettersContext } from "../../context/LettersContext";
import { PreloaderComponent } from "../../loaders/PreloaderComponent";

export default function LetterMenuComponent() {
    const { isExact: isLetterMenu } = useRouteMatch(routes.letters.activities.menu);
    const { letterIndex } = useParams<{ letterIndex: string }>();
    const letterNumber = parseInt(letterIndex);

    const [screenReady, setScreenReady] = useState(false);

    const game = React.useRef(new LetterMenu(letterNumber));
    const { letterMenuData, getLetterMenuStatus, completedLetter } = useLettersContext();

    useEffect(() => {
        getLetterMenuStatus(letterNumber + 1);
    }, [getLetterMenuStatus, letterNumber]);

    useEffect(() => {
        if (isLetterMenu) {
            if (game.current.root) {
                // not a first launch;
                game.current.bgSound = playSoundSync("bgLetterMenu", { loop: -1 });
            }
        }
    }, [isLetterMenu]);

    useEffect(() => {
        if (isLetterMenu && completedLetter) {
            game.current.letterDone();
        }
    }, [isLetterMenu, completedLetter]);

    useEffect(() => {
        console.log("on use effect", letterMenuData);
        if (!letterMenuData || !game.current.root) return;
        Object.keys(letterMenuData).forEach((key: string) => {
            const done = letterMenuData[key];
            game.current.root[`btn${key}`].gotoAndStop(done ? "selected_up" : 0);
        });
        game.current.updateLetterData(letterMenuData);
    }, [letterMenuData]);

    return (
        <>
            {!screenReady && isLetterMenu && <PreloaderComponent name="plain" />}
            <AnimateCC
                visible={isLetterMenu}
                base="/letters_island/letter_menu/"
                animationName="letter_menu"
                additionalSounds={additionalSounds}
                onLoaded={async (mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc, letterMenuData);
                }}
            />
            {!isLetterMenu && <LetterActivitiesComponent letterNumber={letterNumber} />}
        </>
    );
}
