import { MC, playSound, setupStage } from "../../animate/utils";
import { routes } from "../../navigation/routesPath";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

export class SweetsMenu {
    private root: MC;

    constructor(private frame: LunaparkMenu) {}

    loaded = (mc: MC) => {
        this.root = mc;
        this.frame.toggleSweetsBtn(false);
        (window as any).sweets = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.root.gotoAndStop(0);
        this.root.i3.gotoAndStop(Math.floor(Math.random() * 7));
        this.root.i2.gotoAndStop(Math.floor(Math.random() * 7));
        this.root.i1.gotoAndStop(Math.floor(Math.random() * 7));
        this.addListeners();
    };

    addListeners = () => {
        this.root.apple.addEventListener("click", () => {
            this.frame.LoadGame(routes.lunapark.sweets.apple);
            playSound("Click");
        });
        this.root.popcorn_cup_menu.addEventListener("click", () => {
            this.frame.LoadGame(routes.lunapark.sweets.popcorn);
            playSound("Click");
        });
        this.root.ice_button.addEventListener("click", () => {
            this.frame.LoadGame(routes.lunapark.sweets.ice_cream);
            playSound("Click");
        });
    };
}
