export function getBrowser() {
    if (window.navigator.userAgent.indexOf("Chrome") !== -1) return "Google Chrome";
    if (window.navigator.userAgent.indexOf("Firefox") !== -1) return "Mozilla Firefox";
    if (window.navigator.userAgent.indexOf("MSIE") !== -1) return "Internet Exployer";
    if (window.navigator.userAgent.indexOf("Edge") !== -1) return "Edge";
    if (window.navigator.userAgent.indexOf("Safari") !== -1) return "Safari";
    if (window.navigator.userAgent.indexOf("Opera") !== -1) return "Opera";
    return window.navigator.userAgent;
}

export function isWin() {
    return window.navigator.platform.toLowerCase().startsWith("win");
}

export function isMac() {
    return window.navigator.platform.toLowerCase().startsWith("mac");
}
