import { MC, setupStage } from "../../../animate/utils";

export class PenguinGame {
    private root: MC;
    private currentPenguin: MC;
    private on = false;
    private nPenguin = 0;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).penguin = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.scale = 90 / 100;
        this.root.x = 100;
        this.root.y = 50;
        this.root.gotoAndStop(24);
        this.currentPenguin = this.root.penguin;
        this.root.btn.addEventListener("click", () => {
            this.on = true;
            this.newPenguin();
        });
        this.root.addEventListener("tick", (e: any) => {
            if (this.on) {
                const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
                this.currentPenguin.x = localMouse.x;
                this.currentPenguin.y = localMouse.y;
            }
        });
    };
    newPenguin = () => {
        this.nPenguin++;
        const penguin = new this.root.lib.penguin_clip();

        penguin.tickEnabled = true;
        penguin.paused = true;
        penguin.scale = this.root.penguin.scale;
        penguin.name = "penguin" + this.nPenguin;
        this.root.addChild(penguin);
        penguin.addEventListener("click", () => {
            this.on = false;
            penguin.mouseChildren = false;
            penguin.removeAllEventListeners();
            penguin.mouseEnabled = false;
        });
        penguin.gotoAndPlay(0);
        this.currentPenguin = penguin;
    };
}
