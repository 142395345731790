import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MusicGameComponent } from "../musicGame/MusicGameComponent";
import { MusicSectionGame } from "../musicGame/MusicSectionGame";
import { Memory } from "./Memory";

export function MemoryGameComponent() {
    const game = React.useRef(new MusicSectionGame());
    return (
        <MusicGameComponent
            animationName="memory"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Memory(mc))}
            game={game}
            levels={3}
            shiftX={85}
            shiftY={77}
        />
    );
}
