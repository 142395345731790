import React from "react";
import AnimateCC from "../../../animate";
import { BigWheelGame } from "../BigWheelGame";
import { NumberTwoGame } from "./NumberTwoGame";
//import additionalSounds from "../BigWheelSounds";

const NumberTwoComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberTwoGame(frame));

    return (
        <AnimateCC
            //base="/lunapark/sweets/popcorn"
            base="/lunapark/big_wheel/number_two"
            animationName="number_two"
            // additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default NumberTwoComponent;
