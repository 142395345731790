import { loadSound } from "../animate/sound_utils";
import { playSound } from "../animate/utils";

export const getInstSoundForHelp = async (game: string, level: number, number?: number) => {
    switch (game) {
        case "hippo":
            return playSound("inst");
        case "frogs":
            await loadSound(`/math/sounds/frogs/frogs_inst_level_${level}.mp3`);
            return playSound(`/math/sounds/frogs/frogs_inst_level_${level}.mp3`);
        case "cars":
            await loadSound(`/math/sounds/car_swing/cars_help.mp3`);
            return playSound(`/math/sounds/car_swing/cars_help.mp3`);
        case "swing":
            // await loadSound(`/math/sounds/car_swing/Swing_${number}.mp3`);
            return playSound(`/math/sounds/car_swing/Swing_${number}.mp3`);
        case "ducks":
            return playSound(`/math/sounds/ducks/Ducks_inst_level${level}.mp3`);
        case "aqua":
            return playSound(`/math/sounds/aqua/fish_inst_level1.mp3`);
        case "wizard":
            return playSound(`/math/sounds/wizard/kosem_help.mp3`);
        case "phone1":
            return playSound(`/math/sounds/phone/phone1_inst_level${level === 3 ? 2 : level}.mp3`);
        case "phone2":
            return playSound(`/math/sounds/phone/phone2_inst_level${level}.mp3`);
        case "monkey":
            await loadSound("/math/sounds/monkey/monkey_help.mp3");
            return playSound("/math/sounds/monkey/monkey_help.mp3");
    }
};
