import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { UpsidedownGame } from "./UpsideDownGame";

const UpsidedownComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new UpsidedownGame(frame));

    return <AnimateCC base="/lunapark/abc/upsidedown" animationName="upsidedown" onLoaded={game.current.loaded} />;
};
export default UpsidedownComponent;
