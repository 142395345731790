import { MC, setupStage } from "../../../animate/utils";
import { ShadowGame } from "../ShadowGame";

const AREA_WIDTH = 24;
const AREA_HEIGHT = 24;
const NUM_ELEMENTS = 8;
const BEGIN = 4;
const WIDTHS = [127.45, 228.5, 197.2, 227.3, 131.5, 154.65, 161.95, 167.95];
const HEIGHTS = [82.05, 78.8, 68.35, 125.5, 97.9, 69.8, 92.6, 51.1];
export class FishGame {
    private root: MC;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        const game = new ShadowGame(
            this.root,
            AREA_WIDTH,
            AREA_HEIGHT,
            NUM_ELEMENTS,
            WIDTHS,
            HEIGHTS,
            BEGIN
        );
        game.start();
    };
}
