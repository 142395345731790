import { nik2Movies } from "../video/videoUrl";
import { IChannel } from "./IChannel";
export class Nickoldeon2Channel implements IChannel {
    channelMovies: any[];
    moviesNum: number;

    constructor(public setVideoSrc: (value: string) => void) {
        this.channelMovies = nik2Movies;
        this.moviesNum = this.channelMovies.length;
    }

    setMovieByIndex(index: number) {
        this.setVideoSrc(this.channelMovies[index]);
    }

    getMoviesNum() {
        return this.moviesNum;
    }

    getIconLabelByIndex(index: number) {
        let label: string;
        if (index > this.moviesNum) {
            label = "none";
        } else {
            index < 10 ? (label = `nickelodeon_1${index}`) : (label = `nickelodeon_20`);
        }
        return label;
    }
}
