import { MC, setupStage } from "../../../animate/utils";

export class IcecreamAbcGame {
    private root: MC;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).ice = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.cone.gotoAndStop(0);
        this.addListeners();
    };

    private addListeners() {
        this.root.chocolate.addEventListener("click", () => {
            this.root.cone.gotoAndStop("cho");
        });
        this.root.strawberry.addEventListener("click", () => {
            this.root.cone.gotoAndStop("str");
        });
        this.root.mint.addEventListener("click", () => {
            this.root.cone.gotoAndStop("min");
        });
        this.root.lemon.addEventListener("click", () => {
            this.root.cone.gotoAndStop("lem");
        });
        this.root.vanilla.addEventListener("click", () => {
            this.root.cone.gotoAndStop("van");
        });
    }
}
