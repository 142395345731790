import { MC, playSound, setupStage, optimize } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

export class StarGame {
    private root: MC;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).star = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.resetStar();
        this.root.addEventListener("click", () => {
            this.resetStar();
            let i = getRandomNumber(1, 4);
            playSound("star_" + i);
        });
    };
    resetStar = () => {
        const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        const star = new this.root.lib.hand();
        this.root.addChild(star);
        star.x = localMouse.x;
        star.y = localMouse.y;
        star.scale = (getRandomNumber(1, 125) + 25) / 100;
        star.rotation = getRandomNumber(1, 360);
        star.tickEnabled = true;
        star.paused = true;
        star.mouseChildren = false;
        optimize(star);
    };
}
