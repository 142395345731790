import { MC, setupStage, waitForEvent } from "../../../animate/utils";

export class CurtainGame {
    private root: MC;
    private isOpen: boolean = false;
    private skyFrame: number = 1;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).curtain = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.addCurtainListener();
        this.root.curtain.gotoAndStop(0);
    };
    addCurtainListener = () => {
        this.root.curtain.addEventListener("click", async () => {
            console.log("curtain click");
            if (this.isOpen) {
                this.root.curtain.mouseEnabled = false;
                this.root.curtain.gotoAndPlay("close");
                await waitForEvent(this.root, "closeEnd");
                this.root.curtain.gotoAndStop("init");
                this.root.curtain.mouseEnabled = true;
                this.isOpen = false;
            } else {
                this.root.curtain.mouseEnabled = false;
                this.changeSky();
                this.root.curtain.gotoAndPlay("open");
                await waitForEvent(this.root, "openEnd");
                this.root.curtain.gotoAndStop("openEnd");
                this.root.curtain.mouseEnabled = true;
                this.isOpen = true;
            }
        });
    };

    changeSky = () => {
        this.skyFrame = this.skyFrame === 0 ? 1 : 0;
        this.root.curtain.sky.gotoAndStop(this.skyFrame);
    };
}
