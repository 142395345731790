export const additionalSounds = [
    {
        src: "sounds/yes.mp3",
        id: "yes",
    },
    {
        src: "sounds/no.mp3",
        id: "no",
    },
    {
        src: "sounds/Yabasha.mp3",
        id: "Yabasha",
    },
    {
        src: "sounds/reka.mp3",
        id: "reka",
    },
    {
        src: "sounds/ShipEnter.mp3",
        id: "shipEnter",
    },
];
