import { MC } from "../animate/utils";
import { Contact } from "./Contacts";
//import { characters } from "./utils/KeyboardConstants";
import { CreationGame } from "./CreationGame";
import {
    CONTACTS_OPEN,
    CONTACTS_CLOSE,
    CONTACT_OPEN,
    NAME_OPEN,
    NAME_CLOSE,
    EMAIL_OPEN,
    EMAIL_CLOSE,
    PIC_OPEN,
    PIC_CLOSE,
    CONTACT_CLOSE,
    LETTERS,
} from "./utils/constants";
export class SendEmail {
    private root: MC;
    // private emailClicked: boolean = false;
    // private nameClicked: boolean = false;
    private contactsString: string;
    private contactsList: Contact[] = [];
    private contactsPic: any[] = [];
    private picList: any[] = [];
    private addContactsPic: any[] = [];
    private creationGame: CreationGame;
    private currentOverContact: any;
    private select_figure_btn: MC;
    private contactList_mc: MC;
    private add_contacts_mc: MC;
    private contacts_mc: MC;
    private select_figure_mc: MC;
    private pressedContact: MC;
    private nameText = "";
    private emailText = "";
    private selectedPic: number = null;
    private selectedContacts: MC[] = [];
    private confirm: MC;
    private contactsMode: string;
    constructor(root: MC, contactsString: string, creationGame: CreationGame, contactsMode?: string) {
        this.contactsString = contactsString;
        this.root = root;
        this.creationGame = creationGame;
        this.contactsMode = contactsMode;
        // this.saveContacts();
        // this.sendMail({ email: "noamijofen@gmail.com", name: "שלמה11abc!", pic: "1" });
        this.start();
    }
    start = async () => {
        await this.declareAlias();
        await this.parseContactsList();
        this.initContactsAlert();
        this.initAddContactsAlert();
        this.initAddContactsPicture();
        !this.contactsMode && this.creationGame.toggleAddContacts(false);
        this.toggleAddContactsPicture(false);
        this.addTickEvent();
        this.initNotValidAlerts();
        this.initDeleteAlerts();
        this.initCursors();
        this.initSendMailAlert();
    };

    initContactsAlert = () => {
        const whiteBg = this.contacts_mc.whiteBg;
        this.addMask(whiteBg, whiteBg.x - 180, whiteBg.y - 127, 364, 253);
        this.contacts_mc.add_contact_btn.addEventListener("click", () => {
            if (!this.addContactsPic.length) {
                this.contactList_mc.visible = false;
            }
            this.root.setChildIndex(this.add_contacts_mc, this.root.numChildren - 1);
            this.add_contacts_mc.mouseEnabled = true;
            this.creationGame.toggleAddContacts(true);
            this.resetSelectedContacts();
            this.selectedContacts = [];
        });
        this.contacts_mc.scroll_down_btn.addEventListener("click", () => {
            if (!this.contactsPic.length) return;
            if (this.contactsPic[this.contactsPic.length - 1].res <= 185) return;
            this.contactsPic.forEach((c) => {
                c.res -= 90;
            });
        });
        this.contacts_mc.scroll_up_btn.addEventListener("click", () => {
            if (!this.contactsPic.length) return;
            if (this.contactsPic[0].res >= 5) return;
            this.contactsPic.forEach((c) => {
                c.res += 90;
            });
        });
        this.initContactPic("cPic");
        this.contactsPicPosition();
        this.contacts_mc.gotoAndStop(1);
        this.contacts_mc.submit_btn.addEventListener("click", async () => {
            this.resetSelectedContacts();
            const id = await this.sendMail();
            if (id) {
                this.creationGame.onEmailSuccess(id);
                this.creationGame.openPopup(this.root.sendAlert);
                this.toggleSendAlert(true);
            } else {
                console.error("send mail failed!");
            }
        });
        this.contacts_mc.close_btn.addEventListener("click", () => {
            this.creationGame.closePopup();
            this.toggleContacts(false);
        });
    };

    toggleContacts = (val: boolean) => {
        this.contacts_mc.visible = val;
    };
    toggleSendAlert = (val: boolean) => {
        this.root.sendAlert.visible = val;
    };

    initAddContactsAlert = () => {
        this.select_figure_btn.figures_in_btn.visible = false;
        this.select_figure_btn.addEventListener("click", () => {
            this.toggleAddContactsPicture(true);
        });
        this.root.add_contacts_mc.close_btn.addEventListener("click", () => {
            this.contactsPicPosition();
            this.clearText();
            this.creationGame.toggleAddContacts(false);
        });
        this.add_contacts_mc.clear_new_mc.addEventListener("click", () => {
            this.clearText();
        });
        this.initContactPic("addCPic");
        const whiteBg = this.contactList_mc.whiteBg;
        this.addMask(whiteBg, whiteBg.x - 228, whiteBg.y - 50, 455, 100);
        this.addContactsPicPosition();
        this.contactList_mc.scroll_right_btn.addEventListener("click", () => {
            if (!this.addContactsPic.length) return;
            if (this.addContactsPic[this.addContactsPic.length - 1].x <= 455) return;
            this.addContactsPic.forEach((c) => {
                c.x -= 90;
            });
        });
        this.contactList_mc.gotoAndStop(1);
        this.contactList_mc.scroll_left_btn.addEventListener("click", () => {
            if (!this.addContactsPic.length) return;
            if (this.addContactsPic[0].x >= 0) return;
            this.addContactsPic.forEach((c) => {
                c.x += 90;
            });
        });
        this.contactList_mc.cl_delete_mc.addEventListener("click", () => {
            if (!this.pressedContact) return;
            this.creationGame.setShowDeleteAlert(true);
        });
        this.add_contacts_mc.add_new_mc.addEventListener("click", () => {
            this.creationGame.hideNotValidAlerts();
            if (this.nameText.length === 0) {
                this.root.setChildIndex(this.root.noNameTooltip, this.root.numChildren - 1);
                this.root.noNameTooltip.visible = true;
            } else if (this.emailText.length === 0) {
                this.root.setChildIndex(this.root.noEmailTooltip, this.root.numChildren - 1);
                this.root.noEmailTooltip.visible = true;
            } else {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(this.emailText)) {
                    this.root.setChildIndex(this.root.noValidEmailTooltip, this.root.numChildren - 1);
                    this.root.noValidEmailTooltip.visible = true;
                } else {
                    if (this.pressedContact) {
                        this.updateContact();
                    } else {
                        this.addNewContact();
                    }
                    this.saveContacts();
                }
            }
        });
        if (this.contactsMode) {
            this.root.add_contacts_mc.close_btn.visible = false;
            this.root.add_contacts_mc.scaleY = 0.9;
            this.root.add_contacts_mc.bg_mc.y -= 15;
            this.root.add_contacts_mc.y += 70;
        }
    };
    updateContact = () => {
        let contact: Contact = this.contactsList.find((c) => this.pressedContact.id === c.id);
        let index = this.contactsList.indexOf(contact);
        if (contact) {
            contact.email = this.emailText;
            contact.name = this.nameText;
            if (this.selectedPic === null) {
                contact.pic = "";
            } else {
                contact.pic = (this.selectedPic + 1).toString();
            }
            this.contactsList[index] = contact;
        } else {
            console.error("not found contact1");
        }
        let changedContact;
        this.contacts_mc.whiteBg.children.forEach((c: MC) => {
            if (c.id === this.pressedContact.id) {
                changedContact = c;
                return;
            }
        });
        if (!changedContact) {
            console.error("not found contact2");
        }
        this.change(this.pressedContact, contact);
        this.change(changedContact, contact);
        this.clearText();
    };
    change = (c: MC, contact: any) => {
        c.mail = this.emailText;
        c.txt.text = this.nameText;
        if (contact.pic) {
            c.images_mc.gotoAndStop(parseInt(contact.pic) - 1);
            c.images_mc.visible = true;
        }
        c.pressed = false;
        this.checkLongName(c);
    };

    addNewContact = () => {
        if (!this.contactList_mc.visible) {
            this.contactList_mc.visible = true;
        }
        let contact: Contact = new Contact();
        contact.name = this.nameText;
        contact.email = this.emailText;
        if (this.contactsList.length) {
            contact.id = this.contactsList.length;
        } else {
            contact.id = 0;
        }
        if (this.selectedPic === null) {
            contact.pic = "";
        } else {
            contact.pic = (this.selectedPic + 1).toString();
        }
        this.contactsList = [contact, ...this.contactsList];
        const newContact = new this.root.lib.contact_mc();
        const newAddContact = new this.root.lib.contact_mc();
        this.contacts_mc.whiteBg.addChild(newContact);
        this.addContactsPic = [newAddContact, ...this.addContactsPic];
        this.addCPic(newAddContact);
        this.initContact(newContact, contact);
        this.initContact(newAddContact, contact);
        this.clickOnPic(newAddContact, "addCPic");
        this.clickOnPic(newContact, "cPic");
        this.contactsPic = [newContact, ...this.contactsPic];
        this.addContactsPicPosition();
        this.clearText();
    };
    initNotValidAlerts = () => {
        this.root.noValidEmailTooltip.txt.text = "כתובת הדואר האלקטרוני אינה חוקית.";
        this.root.noEmailTooltip.txt.text = "יש למלא כתובת דואר אלקטרוני";
        this.root.noNameTooltip.txt.text = "יש להכניס שם פרטי";
    };
    initDeleteAlerts = () => {
        this.confirm = this.creationGame.getDeleteAlert();
        this.confirm.name = "confirm";
        this.confirm.yes_btn.addEventListener("click", () => {
            this.deleteContact();
            this.creationGame.setShowDeleteAlert(false);
        });
        this.confirm.no_btn.addEventListener("click", () => {
            this.creationGame.setShowDeleteAlert(false);
        });
        this.confirm.close_btn.addEventListener("click", () => {
            this.creationGame.setShowDeleteAlert(false);
        });
    };
    initSendMailAlert = () => {
        this.root.sendAlert.cursor = "default";
        this.root.sendAlert.close_btn.cursor = "pointer";
        this.root.sendAlert.close_btn.addEventListener("click", () => {
            this.toggleSendAlert(false);
            this.creationGame.closePopup();
            this.creationGame.openPopup(this.root.contacts_mc);
        });
    };

    initAddContactsPicture = () => {
        this.select_figure_mc.close_btn.addEventListener("click", () => {
            this.toggleAddContactsPicture(false);
            //    this.root.setChildIndex(this.select_figure_mc, this.root.numChildren - 1);
        });
        let x = 15;
        let y = 10;
        const whiteBg = this.select_figure_mc.whiteBg;
        this.addMask(whiteBg, whiteBg.x - 167, whiteBg.y - 130, 314, 260);
        for (let i = 0; i < 21; i++) {
            const pic = new this.root.lib.figure_btn();
            this.picList.push(pic);
            pic.gotoAndStop(i);
            pic.addEventListener("click", () => {
                this.select_figure_btn.figures_in_btn.gotoAndStop(i);
                this.select_figure_btn.figures_in_btn.visible = true;
                this.selectedPic = i;
                this.toggleAddContactsPicture(false);
            });
            pic.cursor = "pointer";
            pic.res = y;
            pic.x = x;
            pic.y = y;
            x += 90;
            whiteBg.addChild(pic);
            if (!((i + 1) % 3)) {
                y += 84;
                x = 15;
            }
        }
        this.select_figure_mc.scroll_down_btn.addEventListener("click", () => {
            if (this.picList[12].res === 10) return;
            this.picList.forEach((c: MC) => {
                c.res -= 84;
            });
        });
        this.select_figure_mc.scroll_up_btn.addEventListener("click", () => {
            if (this.picList[0].res === 10) return;
            this.picList.forEach((c: MC) => {
                c.res += 84;
            });
        });
    };
    toggleAddContacts = (val: boolean) => {
        this.creationGame.setShowInput(val);
        this.add_contacts_mc.visible = val;
    };
    toggleAddContactsPicture = (val: boolean) => {
        this.creationGame.setShowSelectImages(val);
        // this.add_contacts_mc.select_figure_mc.visible = val;
    };
    clearText = () => {
        //this.add_contacts_mc.fname_txt.input_eng_txt.text = "";
        //this.add_contacts_mc.email_txt.input_eng_txt.text = "";
        this.creationGame.setNameText("");
        this.creationGame.setEmailText("");
        this.emailText = "";
        this.nameText = "";
        this.select_figure_btn.figures_in_btn.visible = false;
        this.contactList_mc.gotoAndStop(1);
        this.selectedPic = null;
        if (this.pressedContact) {
            this.pressedContact.btn_fx.gotoAndStop("normal");
            this.pressedContact.pressed = false;
            this.pressedContact = null;
        }
    };
    parseContactsList = () => {
        const contactList = this.contactsString;
        const contacts: Contact[] = [];
        let index = 0;
        const parser = new DOMParser();
        const xml = parser.parseFromString(contactList, "text/html");
        xml.querySelectorAll("sName").forEach((n) => {
            contacts[index] = new Contact();
            contacts[index].name = n.textContent;
            contacts[index].id = index;
            index++;
        });
        index = 0;
        xml.querySelectorAll("sEmail").forEach((e) => {
            contacts[index].email = e.textContent;
            index++;
        });
        index = 0;
        xml.querySelectorAll("nPic").forEach((p) => {
            contacts[index].pic = p.textContent;
            index++;
        });
        this.contactsList = contacts;
    };

    initContactPic = (type: string) => {
        this.contactsList.forEach((c: Contact, i: number) => {
            const contact = new this.root.lib.contact_mc();
            if (type === "cPic") {
                this.contactsPic.push(contact);
                this.contacts_mc.whiteBg.addChild(contact);
            } else {
                this.addContactsPic.push(contact);
                this.addCPic(contact);
            }
            contact.cursor = "pointer";
            contact.mail = c.email;
            contact.id = c.id;
            this.clickOnPic(contact, type);
            this.nameAndPic(contact, c);
        });
    };

    nameAndPic = (contact: any, c: Contact) => {
        contact.txt.text = c.name;
        if (c.pic) {
            contact.images_mc.gotoAndStop(parseInt(c.pic) - 1);
        } else {
            contact.images_mc.visible = false;
        }
    };

    clickOnPic = (contact: any, type: string) => {
        contact.btn_fx.gotoAndStop("normal");
        contact.addEventListener("click", () => {
            if (!contact.pressed) {
                contact.btn_fx.gotoAndStop("pressed");
                contact.pressed = true;
                this.contactList_mc.gotoAndStop(0);
                if (this.select_figure_btn.figures_in_btn.visible) {
                    this.selectedPic = contact.images_mc.currentFrame;
                }
                if (type === "addCPic") {
                    this.pressedContact = contact;
                    this.editPic(contact);
                } else {
                    if (this.selectedContacts.length === 0) {
                        this.contacts_mc.gotoAndStop(0);
                    }
                    this.selectedContacts.push(contact);
                }
            } else {
                if (type === "addCPic") return;
                contact.btn_fx.gotoAndStop("normal");
                contact.pressed = false;
                this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1);
                if (this.selectedContacts.length === 0) {
                    this.contacts_mc.gotoAndStop(1);
                }
            }
        });
    };

    addTickEvent = () => {
        this.root.addEventListener("tick", () => {
            this.contactsPic.forEach((c) => {
                if (!c.res) return;
                if (c.res > c.y) {
                    c.y += 15;
                }
                if (c.res < c.y) {
                    c.y -= 15;
                }
            });
            this.picList.forEach((p) => {
                if (!p.res) return;
                if (p.res > p.y) {
                    p.y += 21;
                }
                if (p.res < p.y) {
                    p.y -= 21;
                }
            });
        });
    };

    initCursors = () => {
        this.contacts_mc.cursor = "default";
        this.contacts_mc.close_btn.cursor = "pointer";
        this.contacts_mc.add_contact_btn.cursor = "pointer";
        this.contacts_mc.scroll_down_btn.cursor = "pointer";
        this.contacts_mc.scroll_up_btn.cursor = "pointer";
        this.add_contacts_mc.cursor = "default";
        this.contactList_mc.scroll_left_btn.cursor = "pointer";
        this.contactList_mc.scroll_right_btn.cursor = "pointer";
        this.select_figure_btn.cursor = "pointer";
        this.root.add_contacts_mc.close_btn.cursor = "pointer";
        this.add_contacts_mc.clear_new_mc.cursor = "pointer";
        this.add_contacts_mc.add_new_mc.cursor = "pointer";
        this.contactList_mc.cl_delete_mc.cursor = "pointer";
        this.select_figure_mc.close_btn.cursor = "pointer";
        this.select_figure_mc.scroll_up_btn.cursor = "pointer";
        this.select_figure_mc.scroll_down_btn.cursor = "pointer";
        this.contacts_mc.submit_btn.cursor = "pointer";
        this.confirm.yes_btn.cursor = "pointer";
        this.confirm.no_btn.cursor = "pointer";
        this.confirm.close_btn.cursor = "pointer";
    };
    addMask = (mc: MC, x: number, y: number, width: number, height: number) => {
        const mask = new createjs.Shape();
        mask.graphics.beginFill("#fff").drawRect(x, y, width, height);
        mc.mask = mask;
    };
    editPic = (contact: any) => {
        this.addContactsPic.forEach((c) => {
            if (c !== contact) {
                if (c.pressed) {
                    c.pressed = false;
                    c.btn_fx.gotoAndStop("normal");
                }
            }
        });
        this.creationGame.setNameText(contact.txt.text);
        this.creationGame.setEmailText(contact.mail);
        if (contact.images_mc.currentFrame) {
            this.select_figure_btn.figures_in_btn.visible = true;
            this.select_figure_btn.figures_in_btn.gotoAndStop(contact.images_mc.currentFrame);
        } else {
            this.select_figure_btn.figures_in_btn.visible = false;
        }
    };

    addCPic = (contact: any) => {
        this.contactList_mc.whiteBg.addChild(contact);
        contact.addEventListener("rollover", () => {
            if (this.currentOverContact === contact || contact.pressed) return;
            this.currentOverContact = contact;
            contact.btn_fx.gotoAndStop("over");
        });
        contact.addEventListener("rollout", () => {
            if (contact.pressed) return;
            this.currentOverContact = "";
            contact.btn_fx.gotoAndStop("normal");
        });
    };

    addContactsPicPosition = () => {
        let x = 10;
        this.addContactsPic.forEach((c) => {
            this.checkLongName(c);
            c.x = x;
            c.y = 3;
            x += 90;
        });
    };
    contactsPicPosition = () => {
        let x = 5;
        let y = 10;
        this.contactsPic.forEach((c, i: number) => {
            c.res = y;
            c.x = x;
            c.y = y;
            this.checkLongName(c);
            if (!((i + 1) % 4)) {
                y += 90;
                x = 10;
            } else {
                x += 90;
            }
        });
    };

    declareAlias = async () => {
        this.select_figure_btn = this.root.add_contacts_mc.select_figure_btn;
        this.contactList_mc = this.root.add_contacts_mc.contactList_mc;
        this.add_contacts_mc = this.root.add_contacts_mc;
        this.contacts_mc = this.root.contacts_mc;
        this.select_figure_mc = await this.creationGame.getSelectFigure();
    };

    checkLongName = (c: any) => {
        if (c.txt.getMeasuredWidth() > 73) {
            if (c.longName) {
                c.longName.text = c.txt.text;
            } else {
                c.longName = new createjs.Text(c.txt.text, "10pt Arial", "#000066");
                c.addChild(c.longName);
                c.longName.x = 0;
                c.longName.y = c.txt.y;
            }
            c.txt.visible = false;
            if (LETTERS.indexOf(c.longName.text.charAt(0)) !== -1) {
                while (c.longName.getMeasuredWidth() > 60) {
                    c.longName.text = c.longName.text.slice(0, c.longName.text.length - 1);
                }
            } else {
                while (c.longName.getMeasuredWidth() > 60) {
                    c.longName.text = c.longName.text.slice(1, c.longName.text.length);
                }
            }
            c.longName.text += "...";
        } else {
            c.txt.visible = true;
            if (c.longName) {
                c.longName.text = " ";
            }
        }
    };

    private resetSelectedContacts() {
        this.selectedContacts.forEach((s) => {
            s.btn_fx.gotoAndStop("normal");
            s.pressed = false;
        });
    }

    private initContact(newContact: MC, contact: Contact) {
        newContact.id = contact.id;
        newContact.cursor = "pointer";
        newContact.mail = contact.email;
        this.nameAndPic(newContact, contact);
    }

    deleteContact = () => {
        this.addContactsPic.splice(this.addContactsPic.indexOf(this.pressedContact), 1);
        this.contactList_mc.whiteBg.removeChild(this.pressedContact);
        const cont = this.contactsPic.find((c) => c.id === this.pressedContact.id);
        this.contactsPic.splice(this.contactsPic.indexOf(cont), 1);
        this.contacts_mc.whiteBg.removeChild(cont);
        let contact: Contact = this.contactsList.find((c) => this.pressedContact.id === c.id);
        this.contactsList.splice(this.contactsList.indexOf(contact), 1);
        //this.contactList_mc.gotoAndStop(1);
        this.addContactsPicPosition();
        this.clearText();
        this.saveContacts();
        if (!this.contactsList.length) {
            this.contactList_mc.visible = false;
        }
    };
    setEmailText = (email: string) => {
        if (!email) {
            this.emailText = "";
        }
        this.emailText = email;
    };
    setNameText = (name: string) => {
        this.nameText = name;
    };
    getEmailText = () => {
        return this.emailText;
    };
    getNameText = () => {
        return this.nameText;
    };

    getContactsToSend = () => {
        // let selectedList: Contact[] = [];
        let contacts_data = "";
        this.selectedContacts.forEach((s) => {
            let contact: Contact = new Contact();
            contact.name = s.txt.text;
            contact.email = s.mail;
            s.images_mc.currentFrame ? (contact.pic = s.images_mc.currentFrame + 1) : (contact.pic = "");
            contacts_data += this.generateContactData(contact);
        });
        return `${CONTACTS_OPEN}${contacts_data}${CONTACTS_CLOSE}`;
    };

    generateContactsData = () => {
        let contacts_data = "";
        this.contactsList.forEach((contact: Contact) => {
            contacts_data += this.generateContactData(contact);
        });
        return `${CONTACTS_OPEN}${contacts_data}${CONTACTS_CLOSE}`;
    };

    generateContactData = (contact: Contact) => {
        return `${CONTACT_OPEN}${NAME_OPEN}${contact.name}${NAME_CLOSE}${EMAIL_OPEN}${contact.email}${EMAIL_CLOSE}${PIC_OPEN}${contact.pic}${PIC_CLOSE}${CONTACT_CLOSE}`;
    };

    saveContactsRequest = () => {
        const data = new URLSearchParams();
        data.set("sToDo", "SaveContacts");
        data.set("sContactsXml", this.generateContactsData());
        return data;
    };

    sendMailRequest = () => {
        const data = new URLSearchParams();
        data.set("sToDo", "sendMail");
        data.set("sContacts", this.getContactsToSend());
        data.set("nWorkID", this.creationGame.getWorkId());
        data.set("sDump", this.creationGame.getDump());
        return data;
    };

    saveContacts = () => {
        fetch(`/gallery/SingleWork.aspx?nID=${this.creationGame.getWorkId()}&nView=1&nFrom=menu`, {
            body: this.saveContactsRequest(),
            method: "POST",
            mode: "cors",
            credentials: "include",
        });
    };

    sendMail = async () => {
        const response = await fetch(
            `/gallery/SingleWork.aspx?nID=${this.creationGame.getWorkId()}&nView=1&nFrom=menu`,
            {
                body: this.sendMailRequest(),
                method: "POST",
                mode: "cors",
                credentials: "include",
            }
        );
        const text = await response.text();
        const id = this.getWorkId(text);
        return id;
    };

    getWorkId = (text: string) => {
        const matches = text.matchAll(/window\.parent\.SendMailDone\(([0-9]+)\)</g);
        let result: any = [];
        for (const match of matches) {
            result.push(match.slice(1, match.length));
        }
        if (result[0]) return result[0];
        else return -1;
    };
}
