import { MC, playSound, setupStage } from "../../../animate/utils";

export class BulbGame {
    private root: MC;
    private on = false;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).bulb = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.switch_btn.gotoAndStop(0);
        this.root.back.gotoAndStop(0);
        this.root.switch_btn.btn.cursor = "pointer";
        playSound("darkness");
        this.root.switch_btn.btn_light.addEventListener("click", async () => {
            this.on = false;
            this.root.lamp.gotoAndStop(0);
            this.root.dark.visible = true;
            this.root.back.gotoAndStop(0);
            this.root.switch_btn.gotoAndStop(0);
            playSound("darkness");
        });
        this.root.switch_btn.btn.addEventListener("click", async () => {
            this.on = true;
            this.root.lamp.gotoAndStop(1);
            this.root.dark.visible = false;
            this.root.back.gotoAndStop(1);
            this.root.switch_btn.gotoAndStop(1);
            playSound("light");
        });
    };
}
