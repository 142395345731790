import { localToGlobal, MC, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";

const GLASS_DIM = 64;
const ANT_WIDTH = 9.8;
const ANT_HEIGHT = 17.2;
export class GlassGame {
    private root: MC;
    // private readonly g = new createjs.Shape();
    private glass: MC;
    constructor(private frame: any) {}

    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).curtain = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.glass = this.root.glass;
        // this.root.addChild(this.g);
        this.initAnts();
        this.start();
    };

    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;
            this.moveGlass(this.glass);
            this.checkIntersection();
        });
    };
    initAnts = () => {
        for (let i = 1; i <= 20; i++) {
            this.root["ant" + i].rotation = getRandomNumber(0, 360);
        }
    };
    moveGlass = (glass: MC) => {
        const pos = this.root.globalToLocal(this.frame.root.stage.mouseX, this.frame.root.stage.mouseY);
        glass.x = pos.x;
        glass.y = pos.y;
        glass.rotation = (pos.x - 50) / 10 + pos.y / 8 - 50;
    };

    checkIntersection = () => {
        for (let i = 1; i <= 20; i++) {
            const ant = this.root["ant" + i];
            if (this.checkAntIntersection(ant)) {
                console.log("intersects");
                this.growAnt(ant);
            }
        }
    };

    growAnt = (ant: MC) => {
        const xdis = ant.x - this.glass.x;
        const ydis = ant.y - this.glass.y;
        const rdis = Math.floor(Math.sqrt(xdis * xdis + ydis * ydis)) / 100;
        ant.scale = 1 + (2 - rdis * 3);
    };

    checkAntIntersection = (ant: MC) => {
        const antRect = new createjs.Rectangle(
            ant.x - (ANT_WIDTH * ant.scale) / 2,
            ant.y - (ANT_HEIGHT * ant.scale) / 2,
            ANT_WIDTH * ant.scale,
            ANT_HEIGHT * ant.scale
        );

        const globalArea = localToGlobal(this.glass.area, 0, 0);
        const glassRect = new createjs.Rectangle(
            globalArea.x - GLASS_DIM / 2,
            globalArea.y - GLASS_DIM / 2,
            GLASS_DIM,
            GLASS_DIM
        );

        // const g = this.g.graphics;
        // g.clear();
        // g.s("red").r(
        //     glassRect.x,
        //     glassRect.y,
        //     glassRect.width,
        //     glassRect.height
        // );

        return glassRect.intersects(antRect);
    };
}
