import { SpecialWords } from "./specialWord";
import { FancylWord } from "./fancyWord";
import { asyncTween, MC, playSound, setupStage, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { getGame, showNextButton } from "./games";
import { routes } from "../../navigation/routesPath";
import { BaseGame } from "./gamesForWords/BaseGame";
import Game9 from "./gamesForWords/game9";
import Game1 from "./gamesForWords/game1";
import Game3 from "./gamesForWords/game3";

export class SpecialWordsGame {
    game: BaseGame;
    shouldPlay: boolean = true;
    routes: any;
    constructor(
        private letter: any,
        private setCurrentLetter: (game: string) => void,
        private exit: any,
        private setLetterStatus: any
    ) {
        this.routes = routes.letters.fancy_words.word;
    }

    showWordAndDefinition = async (word: any, defenition: any, firstLetter: any) => {
        const currentWord = this.specialGame.root.word_anim_mc.word_mc;
        const currentFirstLetter = this.specialGame.root.word_anim_mc.word_mc.first_letter;
        this.specialGame.root.word_anim_mc.visible = true;
        this.specialGame.root.word_anim_mc.scaleX = 0.8;
        this.specialGame.root.word_anim_mc.scaleY = 0.8;
        currentWord.txt.alpha = 0;
        if (word.length > 5) {
            this.specialGame.root.word_anim_mc.x = 500;
        } else if (word.length === 5) {
            this.specialGame.root.word_anim_mc.x = 450;
        } else {
            currentWord.txt.font = "140px 'FbKingFox Regular'";
            currentFirstLetter.txt.font = "140px 'FbKingFox Regular'";
            this.specialGame.root.word_anim_mc.x = 400;
        }
        currentWord.txt.text = word.substr(1);
        this.specialGame.root.word_anim_mc.gotoAndStop(42);
        currentFirstLetter.txt.text = firstLetter;
        await asyncTween(currentFirstLetter.txt, { color: "#890607" }, 500);
        await waitForTimer(100);
        if (this.shouldPlay) {
            playSound(`word_${this.letter}`);
        }
        await asyncTween(currentWord.txt, { alpha: 1 }, 1000);
        await asyncTween(currentFirstLetter.txt, { color: "#FFBA1A" }, 200);
        await waitForTimer(300);
        currentFirstLetter.txt.color = "#000000";
        currentWord.txt.color = "#000000";
        if (!this.shouldPlay) return;

        playSound("moveword");

        await asyncTween(this.specialGame.root.word_anim_mc, { y: 90, x: 640, scaleX: 0.5, scaleY: 0.5 }, 3000);

        if (!this.shouldPlay) return;
        playSound(`question_${this.letter}`);

        await waitForTimer(10);
        await asyncTween(this.specialGame.root.word_anim_mc, { y: 100, x: 610, scaleX: 0.46, scaleY: 0.46 }, 3500);

        if (word.length > 8) {
            await waitForTimer(1000);
        } else if (word.length > 4) {
            await waitForTimer(500);
        } else {
            await waitForTimer(200);
        }
        return this.showDefinition(defenition, `definition_${this.letter}`);
    };

    showDefinition = async (defenition: string, sound?: string) => {
        const currentDefinition = this.specialGame.root.definition_anim_mc;
        currentDefinition.gotoAndPlay(0);
        currentDefinition.y = 145;
        currentDefinition.scaleX = 1.0;
        currentDefinition.scaleY = 1.0;
        currentDefinition.x = 390;
        currentDefinition.visible = true;
        currentDefinition.definition_mc.txt.text = defenition;
        if (sound) {
            if (this.shouldPlay) await playSound(sound);
        }
    };

    private specialGame: SpecialWords = null;
    specialWordsLoaded = async (mc: createjs.MovieClip) => {
        this.specialGame = new SpecialWords(mc, this.letter, this.eventForFrameButton);
        await this.specialGame.loaded();

        if (!this.shouldPlay) return;

        this.setCurrentLetter(this.letter);

        setupStage(this.fancyWord?.root, this.specialGame?.root);
    };
    eventForFrameButton = (currentBtn: MC) => {
        currentBtn.mouseChildren = false;
        currentBtn.cursor = "pointer";
        currentBtn.addEventListener("rollover", () => {
            if (currentBtn.currentFrame === 19 || currentBtn.currentFrame === 0) {
                currentBtn.gotoAndStop("over");
            }
        });
        currentBtn.addEventListener("rollout", () => {
            if (currentBtn.currentFrame === 9) {
                currentBtn.gotoAndStop("normal");
            }
        });
        if (currentBtn.name === "exit_btn") {
            currentBtn.addEventListener("click", () => {
                stopAllSounds();
                this.specialGame.shouldPlay = false;
                if (this.fancyWord) {
                    this.fancyWord.shouldPlay = false;
                }
                this.exit();
            });
        }
    };

    private fancyWord: FancylWord = null;
    nextToEndGame = () => {
        stopAllSounds();
        const nextButton = showNextButton(this.letter);
        if (this.specialGame.root.reload_btn.visible) {
            this.specialGame.root.reload_btn.gotoAndStop("pressed");
        }
        stopAllSounds();
        if (nextButton === true) {
            if (this.letter === 3) {
                (this.game as Game3).shouldPlaySound = false;
            }
            stopAllSounds();
            const next = this.specialGame.root.next_btn;
            next.gotoAndStop("pressed");
            next.mouseChildren = false;
            next.cursor = "pointer";
            next.addEventListener("click", () => {
                this.endGame();
            });
        } else {
            stopAllSounds();
            this.endGame();
        }
    };

    endGame = async () => {
        stopAllSounds();
        this.specialGame.root.help_btn.gotoAndStop("disabled");
        this.specialGame.root.next_btn.gotoAndStop("disabled");
        this.specialGame.root.reload_btn.gotoAndStop("disabled");
        this.specialGame.root.help_btn.removeAllEventListeners();
        this.specialGame.root.next_btn.removeAllEventListeners();
        await waitForTimer(500);
        stopAllSounds();
        await asyncTween(this.fancyWord.root.game, { alpha: 0 }, 400);
        this.fancyWord.root.end.visible = true;
        this.fancyWord.root.end.gotoAndPlay(1);
        await waitForEvent(this.fancyWord.root.end, "animationEnd");
        this.setLetterStatus(this.letter, "word");
        await asyncTween(this.fancyWord.root.end, { alpha: 0 }, 300);
        this.exit();
    };

    help = async (disabled: boolean, sound?: any) => {
        const help = this.specialGame.root.help_btn;
        if (!disabled || help.currentFrame === 29) {
            help.gotoAndStop("pressed");
            help.cursor = "pointer";
            help.addEventListener("click", async () => {
                this.specialGame.root.mouseChildren = false;
                help.gotoAndStop("disabled");
                if (sound) {
                    await playSound(sound);
                } else {
                    await playSound(`game_${this.letter}`);
                }
                help.gotoAndStop("pressed");
                this.specialGame.root.mouseChildren = true;
            });
            // this.eventForFrameButton(help)
        } else {
            this.specialGame.root.help_btn.removeAllEventListeners();
            help.gotoAndStop("disabled");
            help.cursor = "default";
        }
    };

    fancyWordLoaded = async (mc: createjs.MovieClip) => {
        this.fancyWord = new FancylWord(mc, this.letter, this.showWordAndDefinition, this.showDefinition);
        setupStage(this.fancyWord?.root, this.specialGame?.root);

        await this.fancyWord.loaded();

        if (!this.shouldPlay) return;
        if (this.fancyWord.root.explain2) {
            this.fancyWord.root.explain2.visible = false;
        }
        this.fancyWord.root.explain.visible = false;
        this.specialGame.root.definition_anim_mc.visible = false;
        this.specialGame.root.word_anim_mc.visible = false;
        stopAllSounds();
        const game = getGame(this.letter);
        this.game = game;
        if (this.specialGame.root.reload_btn.visible) {
            const reload_btn = this.specialGame.root.reload_btn;
            reload_btn.cursor = "pointer";
            reload_btn.addEventListener("click", async () => {
                (game as any)?.reloadGame();
            });
        }

        if (this.letter === 9) {
            (game as Game9).frame = this.specialGame.root;
        }
        if (this.letter === 1) {
            (game as Game1).frame = this.specialGame.root;
        }

        if (this.fancyWord.root.game2) {
            game.initGame(this.fancyWord.root, this.nextToEndGame, this.help);
        } else {
            game.initGame(this.fancyWord.root.game, this.nextToEndGame, this.help);
        }
    };

    unload = () => {
        this.shouldPlay = false;
        if (this.specialGame) {
            this.specialGame.shouldPlay = false;
        }
        if (this.fancyWord) {
            this.fancyWord.shouldPlay = false;
        }
        (getGame(this.letter) as BaseGame).shouldPlay = false;
    };
}
