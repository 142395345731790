// import { loadSound } from "../animate/sound_utils";
import { playSound, stopAllSounds } from "../animate/utils";

export const getInstSoundForHelp = async (game: string, instrument?: string) => {
    switch (game) {
        case "instruments":
            await playSound("/music/sounds/instruments/inst.mp3");
            return playSound(`/music/sounds/instruments/tool${instrument}.mp3`);
        case "memory":
            return playSound("/music/sounds/memory/Memory.mp3");
        case "train":
            stopAllSounds();
            return playSound("/music/train/sounds/train_help.mp3");
        case "simon":
            return playSound("/music/simon/sounds/simon_inst.mp3");
        case "chicken":
            return playSound("");
    }
};
