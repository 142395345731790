import { MC, waitForEvent, playSound } from "../../../animate/utils";
import { BaseGame } from "./BaseGame";
export default class Game17 extends BaseGame {
    game: any;
    gameEnd: any;
    helpBtn: any;
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.helpBtn = helpBtn;
        game.mouseChildren = false;
        this.game = game;
        this.gameEnd = gameEnd;
        game.y = 190;
        game.visible = true;
        game.gotoAndStop(0);
        game.gotoAndPlay(1);
        await waitForEvent(game, "questionEnd");
        if (!this.shouldPlay) return;
        helpBtn(false);
        this.buttonsEvents(game.bat);
        this.buttonsEvents(game.fish);
        this.buttonsEvents(game.zebra);
    };
    buttonsEvents = (currentBtn: MC) => {
        currentBtn.gotoAndStop(0);
        this.game.mouseChildren = true;
        currentBtn.cursor = "pointer";
        currentBtn.addEventListener("click", async () => {
            currentBtn.gotoAndStop(0);
            currentBtn.gotoAndStop(1);
            await this.checkAnswer(currentBtn);
            if (!this.shouldPlay) return;
        });
        currentBtn.addEventListener("rollover", () => {
            currentBtn.gotoAndStop(1);
        });
        currentBtn.addEventListener("rollout", () => {
            currentBtn.gotoAndStop(0);
        });
    };
    checkAnswer = async (currentAnser: MC) => {
        if (currentAnser.name === "bat") {
            this.helpBtn(true);
            this.game.gotoAndPlay(240);
            await waitForEvent(this.game, "gameEnd");
            if (!this.shouldPlay) return;
            this.gameEnd();
        } else {
            await playSound("wrong");
            if (!this.shouldPlay) return;
        }
    };
}
