import { MC, setupStage } from "../../../animate/utils";
import { ShadowGame } from "../ShadowGame";

const AREA_WIDTH = 24;
const AREA_HEIGHT = 24;
const NUM_ELEMENTS = 6;
const BEGIN = 4;

const WIDTHS = [89.3, 149, 76.8, 80.7, 124.95, 189.85];
const HEIGHTS = [133.45, 102.9, 127.75, 107.9, 117.1, 107.4];
export class InsectsGame {
    private root: MC;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        const game = new ShadowGame(
            this.root,
            AREA_WIDTH,
            AREA_HEIGHT,
            NUM_ELEMENTS,
            WIDTHS,
            HEIGHTS,
            BEGIN
        );
        game.start();
    };
}
