import React from "react";
import AnimateCC from "../../animate";
import { RollerCoasterGame } from "./RollerCoasterGame";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

import additionalSounds from "./RollerCoasterSounds";

const RollerCoasterComponent: React.FC<{ frame: LunaparkMenu }> = ({
    frame,
}) => {
    const game = React.useRef(new RollerCoasterGame(frame));

    return (
        <AnimateCC
            base="/lunapark/roller_coaster"
            animationName="roller_coaster"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default RollerCoasterComponent;
