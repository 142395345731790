import React, { useEffect, useRef, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import AnimateCC from "../../animate";
import { VideoPlayer, VideoPlayerComponent } from "../../video/VideoPlayer";
import { MC } from "../../animate/utils";
import { PreloaderComponent } from "../../loaders/PreloaderComponent";
import { StoryMenu } from "./StoryMenu";
import { useUserContext } from "../../context/UserContext";
import { routes } from "../../navigation/routesPath";
import { useIframeContext } from "../../context/IframeContext";
import { displayIf } from "../../utils/StyleUtils";
import BibleFrameComponent from "./BibleFrameComponent";

const StoryMenuComponent: React.FC = () => {
    const [screenReady, setScreenReady] = useState(false);
    const { type } = useParams<{ type: string }>();
    const myVideo = useRef<VideoPlayer>();
    const [src, setSrc] = useState("");
    const [isParentsInfo, setIsParetsInfo] = useState(false);
    const game = React.useRef(new StoryMenu(type, setSrc, myVideo, setIsParetsInfo));
    const { isExact: isStoryMenu } = useRouteMatch(routes.bible_stories.story_menu.menu);

    const { isLoggedIn } = useUserContext();
    const { iframeSrc } = useIframeContext();

    useEffect(() => {
        game.current.setIsLoggedIn(isLoggedIn);
    }, [isLoggedIn]);

    useEffect(() => {
        if (!iframeSrc) {
            game.current.updateLockedMoovies();
        }
    }, [iframeSrc]);
    const onEndFunction = () => {
        game.current.restartVideo();
    };

    useEffect(() => {
        if (isStoryMenu) {
            game.current.resumeBg();
            game.current.initView();
        }
    }, [isStoryMenu]);

    return (
        <>
            {!screenReady && isStoryMenu && <PreloaderComponent name="plain" />}
            {!isParentsInfo && <BibleFrameComponent />}
            {isStoryMenu && screenReady && (
                <VideoPlayerComponent
                    src={src}
                    ref={myVideo}
                    width={426}
                    height={320}
                    frameHeight={900}
                    frameWidth={1200}
                    shiftY={-110}
                    onEndFunction={onEndFunction}
                    onTimeUpdate={game.current.updateVideoPosition}
                    style={displayIf(!iframeSrc)}
                />
            )}
            <AnimateCC
                base="/bible_stories/story_menu"
                animationName="story_menu"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                    setScreenReady(true);
                }}
                visible={isStoryMenu && !iframeSrc}
            />
        </>
    );
};
export default StoryMenuComponent;
