import { asyncTween, MC, playSound, waitForTimer } from "../../../animate/utils";
import { loadSound, soundPlayed } from "../../../animate/sound_utils";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { BaseGame } from "./BaseGame";
export default class Game9 extends BaseGame {
    gameEnd: any;
    helpBtn: any;
    frame: MC;
    game: any;
    answersiIndex = 0;
    answersOrderIndexes: number[] = [];
    reloadNow: boolean = false;
    baseUrl = "/letters_island/fancy_words/sounds/game9/MM9";
    wordsPositions = [
        { x: 334.55, y: 430.7 },
        { x: 211, y: 430.55 },
        { x: 272.4, y: 431.2 },
        { x: 396, y: 432.3 },
    ];
    initGame = async (game: MC, gameEnd: () => void, helpBtn: any) => {
        this.gameEnd = gameEnd;
        this.game = game;
        this.helpBtn = helpBtn;
        game.mouseEnabled = false;
        game.visible = true;
        for (let i = 0; i < 4; i++) {
            game[`highlight${i}`].visible = false;
        }
        game.gotoAndStop(1);
        if (!this.shouldPlay) return;
        await playSound("game9");
        if (!this.shouldPlay) return;
        game.mouseEnabled = true;
        this.reloadNow = false;
        helpBtn();
        for (let i = 0; i < 4; i++) {
            const currentNote = game[`word${i}`];
            currentNote.index = i;
            this.addEventsToNote(currentNote);
        }
        gameEnd();
    };
    addEventsToNote = (currentNote: MC) => {
        currentNote.cursor = "pointer";
        SoundToolTip.addToolTip(currentNote, `word${currentNote.index}`);
        const startMove = (event: createjs.Event) => {
            for (let i = 0; i < 4; i++) {
                const currentWord = this.game[`word${i}`];

                if (currentWord !== currentNote) {
                    currentWord.mouseEnabled = false;
                }
            }
            event.stopImmediatePropagation();
            currentNote.addEventListener("tick", (e: any) => {
                if ((e as createjs.TickerEvent).paused) return;
                const parent: createjs.DisplayObject = currentNote.parent;
                const position = parent.globalToLocal(parent.stage.mouseX, parent.stage.mouseY);
                currentNote.x = position.x;
                currentNote.y = position.y;
            });
            currentNote.mouseEnabled = false;
            currentNote.removeEventListener("click", startMove);
            this.game.addEventListener("click", stopMove);
            this.frame.addEventListener("click", stopMove);
        };

        const stopMove = async (e: createjs.Event) => {
            currentNote.mouseEnabled = true;
            for (let i = 0; i < 4; i++) {
                const currentWord = this.game[`word${i}`];
                if (currentWord !== currentNote) {
                    currentWord.mouseEnabled = true;
                }
            }
            this.frame.cursor = "default";
            this.game.removeEventListener("click", stopMove);
            this.frame.removeEventListener("click", stopMove);
            currentNote.removeAllEventListeners();
            currentNote.addEventListener("click", startMove);

            if (e.target.parent.name?.startsWith("place")) {
                playSound("paper");
                if (!this.shouldPlay) return;
                currentNote.removeAllEventListeners();
                currentNote.cursor = "default";
                this.answersiIndex++;
                const index = e.target.parent.name[e.target.parent.name.length - 1];
                this.answersOrderIndexes[index] = currentNote.index + 1;
                const currentPlace = e.target.parent;
                const p1 = currentPlace.localToGlobal(currentPlace.picPlace.x, currentPlace.picPlace.y);
                const p = this.game.globalToLocal(p1.x, p1.y);
                await asyncTween(currentNote, { x: p.x, y: p.y }, 300);
                if (!this.shouldPlay) return;
                if (this.answersiIndex === 4) {
                    for (let i = 0; i < 4; i++) {
                        this.game[`word${i}`].removeAllEventListeners();
                    }
                    this.reloadNow = true;
                    this.helpBtn(true);
                    for (let i = 0; i < 4; i++) {
                        this.game.setChildIndex(this.game[`highlight${i}`], this.game.children.length - 1);
                        this.game[`highlight${i}`].visible = true;
                        this.game[`highlight${i}`].alpha = 1;
                        this.game[`highlight${i}`].gotoAndPlay(1);
                        if (!this.shouldPlay) return;
                        await soundPlayed(
                            (
                                await loadSound(
                                    `/letters_island/fancy_words/sounds/game9/MM9_${i + 1}_${
                                        this.answersOrderIndexes[i]
                                    }.mp3`
                                )
                            ).play()
                        );
                        if (!this.shouldPlay) return;
                    }
                    for (let i = 0; i < 4; i++) {
                        this.game[`highlight${i}`].gotoAndPlay(52);
                        await waitForTimer(300);
                        if (!this.shouldPlay) return;
                        this.game[`highlight${i}`].alpha = 0;
                    }
                    this.helpBtn(false);
                    this.reloadNow = false;
                }
            } else {
                currentNote.x = this.wordsPositions[currentNote.index].x;
                currentNote.y = this.wordsPositions[currentNote.index].y;
            }
        };

        currentNote.addEventListener("click", startMove);
    };
    reloadGame = () => {
        if (!this.reloadNow) {
            this.reloadNow = true;
            this.helpBtn(true);
            this.answersiIndex = 0;
            this.answersOrderIndexes = [];
            this.game.removeAllEventListeners();
            this.frame.removeAllEventListeners();
            for (let i = 0; i < 4; i++) {
                const currentNote = this.game[`word${i}`];
                currentNote.x = this.wordsPositions[i].x;
                currentNote.y = this.wordsPositions[i].y;
            }
            this.initGame(this.game, this.gameEnd, this.helpBtn);
        }
    };
}
