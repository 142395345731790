import React, { useEffect, useState } from "react";
import AnimateCC from "../../animate";
import { MathMenu } from "./MathMenu";
import { Switch, useRouteMatch } from "react-router-dom";
import { SodRoute } from "../../navigation/PrivateRoot";
import { routes } from "../../navigation/routesPath";
import { HipoGameComponent } from "../hipo/HipoGameComponent";
import { useUserContext } from "../../context/UserContext";
import mathMenuSounds from "./mathMenuSounds.json";
import { PreloaderComponent } from "../../loaders/PreloaderComponent";
import { MC } from "../../animate/utils";
import { CarsGameComponent } from "../cars/CarsGameComponent";
import { WizardGameComponent } from "../wizard/WizardGameComponent";
import { AquaGameComponent } from "../aqua/AquaGameComponent";
import { DucksGameComponent } from "../ducks/DucksGameComponent";
import { FrogsGameComponent } from "../frogs/FrogsGameComponent";
import { MonkeyGameComponent } from "../monkey/MonkeyGameComponent";
import { PhoneGameComponent } from "../phone/PhoneGameComponent";
import { SwingGameComponent } from "../swing/SwingGameComponent";

const MathMenuComponent: React.FC<{}> = () => {
    const { isExact: isMenu } = useRouteMatch();
    const game = React.useRef(new MathMenu(isMenu));
    const { isLoggedIn } = useUserContext();

    const [screenReady, setScreenReady] = useState(false);

    useEffect(() => {
        game.current.isLoggedIn = isLoggedIn;
    }, [isLoggedIn]);

    useEffect(() => {
        if (isMenu) {
            if (game.current.root) {
                game.current.restartSounds();
            }
        }
    }, [isMenu]);

    return (
        <>
            {!screenReady && <PreloaderComponent name="maya" />}
            <AnimateCC
                visible={isMenu}
                base="/math/math_menu"
                additionalSounds={mathMenuSounds}
                animationName="math_menu"
                // onLoaded={game.current.loaded}
                onLoaded={(mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc);
                }}
            />
            <Switch>
                <SodRoute path={routes.math.hippo}>
                    <HipoGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.cars}>
                    <CarsGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.wizard}>
                    <WizardGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.aqua}>
                    <AquaGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.ducks}>
                    <DucksGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.frogs}>
                    <FrogsGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.monkey}>
                    <MonkeyGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.phone}>
                    <PhoneGameComponent />
                </SodRoute>
                <SodRoute path={routes.math.swing}>
                    <SwingGameComponent />
                </SodRoute>
            </Switch>
        </>
    );
};
export default MathMenuComponent;
