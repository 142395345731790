import { MC, playSound, setupStage, waitForEvent } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
const STAR_WIDTH = 33.55;
const STAR_HEIGHT = 34.7;
export class RocketAbcGame {
    private root: MC;
    private initialFrame: number = 24;
    private progress: number = 0;

    constructor(private frame: any) {}
    loaded = async (mc: MC) => {
        this.root = mc;
        (window as any).rocket = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndPlay(23);
        await waitForEvent(this.root, "animationReady");
        this.root.gotoAndStop(this.initialFrame);
        this.initStars();
    };
    initStars = () => {
        for (let i = 1; i <= 15; i++) {
            const star = this.root["star" + i].star;
            this.resizeStar(star);
            this.addClickListener(i);
            this.addHoverListener(this.root["star" + i]);
        }
    };

    addClickListener = (i: number) => {
        const star = this.root["star" + i];
        star.addEventListener("click", async () => {
            if (i === this.progress + 1) {
                playSound("click");
                this.progress += 1;
                this.root.gotoAndStop(this.initialFrame + this.progress);
            }
            if (this.progress === 15) {
                this.root.gotoAndPlay("startAnimation");
                await waitForEvent(this.root, "animationEnd");
                this.root.gotoAndStop("endAnimation");
                return;
            }
            this.resizeStar(this.root["star" + (i + 1)].star);
        });
    };
    addHoverListener = (star: MC) => {
        star.addEventListener("mouseover", () => {
            this.resizeStar(star.star);
        });
        star.addEventListener("mouseout", () => {
            this.resizeStar(star.star);
        });
    };

    private resizeStar(star: any) {
        const size = getRandomNumber(0, 25) + 1;
        const turn = getRandomNumber(0, 72) + 10;
        createjs.Tween.get(star).to(
            {
                scaleX: (size + 20) / STAR_WIDTH,
                scaleY: (size + 20) / STAR_HEIGHT,
                rotation: turn,
            },
            10
        );
    }
}
