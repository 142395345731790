import React from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { MusicGame } from "./MusicGame";
import additionalSounds from "./MusicSounds";
const MusicComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new MusicGame(frame));

    return (
        <AnimateCC
            base="/lunapark/music"
            animationName="music"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default MusicComponent;
