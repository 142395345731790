import { MC } from "../../animate/utils";
import { SoundToolTip } from "../../utils/soundTooltip";

export class Frame {
    root: MC;
    level: number = 0;

    constructor(root: MC) {
        this.root = root;
        (window as any).frame = root;
    }

    stopPreloader = () => {
        this.root.preloader.visible = false;
    };

    setFrameBtn = (btn: MC, tooltip: string) => {
        btn.cursor = "pointer";
        SoundToolTip.addToolTip(btn, tooltip);
        btn.addEventListener("rollover", () => {
            btn.gotoAndStop("over");
        });
        btn.addEventListener("click", () => {
            btn.gotoAndStop("down");
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("up");
        });
    };

    initLevels = (levels: number) => {
        for (let index = levels + 1; index <= 3; index++) {
            this.root[`btnLevel${index}`].visible = false;
        }
    };
    loaded = async (levels: number) => {
        this.root.scaleY = 0.93;
        this.setFrameBtn(this.root.btnExit, "exit");
        this.setFrameBtn(this.root.btnHelp, "help");
        this.initLevels(levels);
    };

    unload = () => {};
}
