import React from "react";
import { MC } from "../../animate/utils";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Monkey } from "./Monkey";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";

export function MonkeyGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="monkey"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Monkey(mc), 3, true)}
            game={game}
        />
    );
}
