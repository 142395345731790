import React from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { DiffrencesBasic } from "../../DifferencesBasic";

const DifferencesGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new DiffrencesBasic());
    return (
        <>
            <AnimateCC
                base="/week_games/differences"
                animationName="differences"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc, "differences");
                }}
                scale={0.88}
                shiftX={40}
                shiftY={33}
            />
        </>
    );
};
export default DifferencesGameComponent;
