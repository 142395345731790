import { MC, setupStage } from "../../../animate/utils";
import { ShadowGame } from "../ShadowGame";

const AREA_WIDTH = 24;
const AREA_HEIGHT = 24;
const NUM_ELEMENTS = 8;
const BEGIN = 4;

const WIDTHS = [119.65, 87.35, 90.2, 99.55, 114.75, 71.3, 113.35, 105.8];
const HEIGHTS = [122.4, 93.95, 95.6, 90.9, 105.7, 97.25, 101.4, 96.4];
export class LeavesGame {
    private root: MC;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        const game = new ShadowGame(
            this.root,
            AREA_WIDTH,
            AREA_HEIGHT,
            NUM_ELEMENTS,
            WIDTHS,
            HEIGHTS,
            BEGIN
        );
        game.start();
    };
}
