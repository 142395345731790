import { soundPlayed } from "../../../animate/sound_utils";
import { MC, waitFor, localToGlobal, globalToLocal, waitForEvent } from "../../../animate/utils";
import { FindItems } from "../../FindItems";

export class PesahGame extends FindItems {
    root: MC;
    itemsArray: number[][] = [[], []];
    isSoundFinished = false;
    NUM_GOOD_ITEMS = 6;
    NUM_BAD_ITEMS = 3;
    MAX_LEFT = -344.15;
    MAX_RIGHT = -1169.15;
    MAX_UP = -347.6;
    MAX_DOWN = -572.6;
    numPrizes = 6;
    setSpeaker = true;

    constructor() {
        super("pesah/");
        this.helpSound = `special_games/${this.soundUrl}sounds/help.mp3`;
        this.setAnimationFunction = this.setAnimation;
        this.setItemsFunction = this.setItems;
    }

    async loaded(root: MC) {
        this.root = root;
        await waitFor(() => this.root);
        this.root.map.cleaner.visible = false;
        await this.onLoad();
    }

    setItems() {
        ["badItem", "goodItem"].forEach((element, i) => {
            super.setItems(element, 10 + i, this.NUM_BAD_ITEMS * (i + 1), i);
        });
    }
    setAnimation = async (item: MC) => {
        const itemPos = localToGlobal(item, 0, 0);
        const cleanerPos = globalToLocal(this.root.map, itemPos.x, itemPos.y);
        this.root.map.cleaner.x = cleanerPos.x;
        this.root.map.cleaner.y = cleanerPos.y;
        this.root.map.cleaner.visible = true;
        soundPlayed((await this.goodItemSound).play());
        this.root.map.cleaner.gotoAndPlay(0);
        await this.cancelableWait(waitForEvent(this.root.map.cleaner, "itemDisappear"));
        item.visible = false;
        this.root[`prize${this.correctIndex++}`].gotoAndStop("on");
        await this.cancelableWait(waitForEvent(this.root.map.cleaner, "animationEnd"));
        this.root.mouseEnabled = true;
        this.root.map.cleaner.visible = false;
        this.root.map.cleaner.gotoAndStop(0);
        if (this.correctIndex > this.NUM_GOOD_ITEMS - 1) {
            this.finishGame();
        }
    };
}
