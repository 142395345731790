import React from "react";
import { Switch } from "react-router-dom";
import AnimateCC from "../../animate";
import { routes } from "../../navigation/routesPath";
import BugsComponent from "./bugs/BugsComponent";
import FishComponent from "./fish/FishComponent";
import FrenchHornsComponent from "./frenchHorns/FrenchHornsComponent";
import InsectsComponent from "./insects/Insectscomponent";
import LeavesComponent from "./leaves/LeavesComponent";
import { RocketMenu } from "./RocketMenu";
import SpaceComponent from "./space/SpaceComponent";
import UnderwaterComponent from "./underwater/UnderwaterComponent";
import VeggiesComponent from "./veggies/VeggiesComponent";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import PicComponent from "./PicComponent";
import PuzzleComponent from "./PuzzleComponent";
import { SodRoute } from "../../navigation/PrivateRoot";

const RocketComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new RocketMenu(frame));

    return (
        <>
            <Switch>
                <SodRoute path={routes.lunapark.rocket.pic}>
                    <PicComponent frame={game.current} />
                </SodRoute>
                <SodRoute path={routes.lunapark.rocket.puzzle}>
                    <PuzzleComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.french_horns}>
                    <FrenchHornsComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.fish}>
                    <FishComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.insects}>
                    <InsectsComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.leaves}>
                    <LeavesComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.veggies}>
                    <VeggiesComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.underwater}>
                    <UnderwaterComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.space}>
                    <SpaceComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.rocket.bugs}>
                    <BugsComponent frame={game.current} key={Date.now()} />
                </SodRoute>
            </Switch>
            <AnimateCC base="/lunapark/rocket/rocket_menu" animationName="rocket_menu" onLoaded={game.current.loaded} />
        </>
    );
};
export default RocketComponent;
