import { MC, playSoundSync, setupStage } from "../../../animate/utils";

export class BeeGame {
    private root: MC;
    private frames: number[] = [];
    private buzzSound: createjs.AbstractSoundInstance = null;
    private scale: number = 1;
    private speed: number = 15;
    private viscosity: number = 1.1;
    private xvelocity: number = 0;
    private yvelocity: number = 0;
    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).bee = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(27);
        this.initFlowers();
        this.initBee();
        this.start();
        this.addMouseOverListener();
    };

    start = () => {
        const bee = this.root.bee;
        let pos: any = null;
        let prevx = 0;
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (pos) {
                prevx = pos.x;
            }
            pos = this.root.globalToLocal(this.frame.root.stage.mouseX, this.frame.root.stage.mouseY);
            if (prevx !== pos.x) {
                bee.rotation = (400 - pos.x) / 10;
            }
            const diffx = pos.x - bee.x;
            this.xvelocity = (this.xvelocity + diffx / this.speed) / this.viscosity;
            bee.x += this.xvelocity;
            const diffy = pos.y - bee.y;
            this.yvelocity = (this.yvelocity + diffy / this.speed) / this.viscosity;
            bee.y += this.yvelocity;
            bee.scale = pos.y / 500;
            this.buzzSound.volume = pos.y / 500;
        });
    };
    initFlowers = () => {
        for (let i = 1; i <= 337; i++) {
            const flower = this.root["flower" + i];
            flower.cursor = "pointer";
            this.frames[i - 1] = 0;
            flower.gotoAndStop(0);
            flower.mouseChildren = false;
        }
    };

    initBee = () => {
        this.buzzSound = playSoundSync("buzz", { loop: -1 });
        this.buzzSound.volume = 0;
        this.root.bee.scale = this.scale;
        this.root.bee.mouseEnabled = false;
    };

    addMouseOverListener = () => {
        this.root.addEventListener("mouseover", (e: createjs.MouseEvent) => {
            if (e.target.name?.startsWith("flower")) {
                const index = e.target.name.slice(6);
                const flower = e.target;
                const currentFrame = this.frames[index];
                const newFrame = (currentFrame % 5) + 1;
                this.frames[index] = newFrame;
                flower.gotoAndStop(newFrame);
            }
        });
    };
}
