import React from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { PopcornGame } from "./PopcornGame";
import additionalSounds from "./sounds_popcorn.json";

const PopcornComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const menu = React.useRef(new PopcornGame(frame));

    return (
        <AnimateCC
            base="/lunapark/sweets/popcorn"
            animationName="popcorn"
            additionalSounds={additionalSounds}
            onLoaded={menu.current.loaded}
        />
    );
};
export default PopcornComponent;
