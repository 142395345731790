import React, { useEffect, useRef, useState } from "react";
import AnimateCC from "../animate";
import { MoviesSection } from "./MoviesSection";
import { VideoPlayer, VideoPlayerComponent } from "../video/VideoPlayer";
import { useMovieContext } from "../context/MovieContext";
import { useIframeContext } from "../context/IframeContext";
import { displayIf } from "../utils/StyleUtils";
import { PreloaderComponent } from "../loaders/PreloaderComponent";
import { MC } from "../animate/utils";

const FRAME_PARAMS_REGULAR = {
    scale: 0.65,
    shiftX: 177,
    shiftY: 170,
};

const FRAME_PARAMS_FULLSCREEN = {
    scale: 1,
    shiftX: 0,
    shiftY: -7,
};

const VIDEO_PARAMS_REGULAR = {
    frameWidth: 1076,
    frameHeight: 605,
    shiftY: -10,
    shiftX: -50,
};

const VIDEO_PARAMS_FULLSCREEN = {
    frameWidth: 700,
    frameHeight: 340,
    shiftY: -36,
    shiftX: -10,
};

export default function MovieSectionComponent() {
    const myVideo = useRef<VideoPlayer>();
    const [src, setSrc] = useState("");

    const [frameParams, setFrameParams] = useState(FRAME_PARAMS_REGULAR);
    const [videoParams, setVideoParams] = useState(VIDEO_PARAMS_REGULAR);
    const movieContext = useMovieContext();
    const { iframeSrc } = useIframeContext();

    const directLink = new URLSearchParams(window.location.search).get("path");
    const [fromDirectLink] = useState(directLink);
    const [screenReady, setScreenReady] = useState(false);

    useEffect(() => {
        const onFullscreenChange = () => {
            if (document.fullscreenElement) {
                setFrameParams(FRAME_PARAMS_FULLSCREEN);
                setVideoParams(VIDEO_PARAMS_FULLSCREEN);
            } else {
                setFrameParams(FRAME_PARAMS_REGULAR);
                setVideoParams(VIDEO_PARAMS_REGULAR);
            }
        };
        document.addEventListener("fullscreenchange", onFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", onFullscreenChange);
        };
    }, []);

    const game = React.useRef(new MoviesSection(movieContext.selectedChannel || 0, setSrc, myVideo));

    const onEndFunction = () => {
        myVideo.current.restartVideo();
        game.current.onEndVideo();
    };

    useEffect(() => {
        if (!iframeSrc) {
            game.current.loadBibleLockedChannels();
        }
    }, [iframeSrc]);

    return (
        <>
            {fromDirectLink && !screenReady && <PreloaderComponent name="maya" />}
            <VideoPlayerComponent
                src={src}
                ref={myVideo}
                width={426}
                height={320}
                onPlay={() => game.current.setIsPlaying(true)}
                onPause={() => game.current.setIsPlaying(false)}
                onEndFunction={onEndFunction}
                onTimeUpdate={game.current.updateVideoPosition}
                {...videoParams}
                style={displayIf(!iframeSrc)}
            />
            <AnimateCC
                base="/movies-section"
                animationName="movies_section"
                onLoaded={async (mc: MC) => {
                    setScreenReady(true);
                    game.current.loaded(mc);
                }}
                onUnload={game.current.unload}
                {...frameParams}
                visible={!iframeSrc}
            />
        </>
    );
}
