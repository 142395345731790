import { Sweets } from "./SweetRhymes";
export class SweetGame {
    private sweetes: Sweets = null;

    sweetLoaded = (mc: createjs.MovieClip) => {
        this.sweetes = new Sweets(mc);
    };

    unload = () => {};
}
