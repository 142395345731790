import React from "react";
import { MC } from "../../animate/utils";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Phone } from "./Phone";

export function PhoneGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="phone"
            // additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Phone(mc), 2, true)}
            shiftY={68}
            game={game}
        />
    );
}
