import { loadSound } from "../../animate/sound_utils";
import { playSound, stopAllSounds, waitForEvent, waitForTimer } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import MusicGameBase from "../musicGame/MusicGameBase";

export class Instruments extends MusicGameBase {
    instrument: number;
    place: number;
    name = "instruments";
    correctAnswer: number = 0;
    enableEvents: boolean;
    enableHelp: () => void;
    disableHelp: () => void;
    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).instruments = root;
    }

    startGame = async () => {
        this.getRandomInstruments();
        this.getRandomPlace();
        await loadSound(`/music/sounds/instruments/who.mp3`);
        await waitForTimer(1700);
        this.root.host_mc.gotoAndPlay("ask");
        await playSound(`/music/sounds/instruments/who.mp3`);
        this.enableHelp();
        this.placeInstruments();
        this.root.curtain_mc.gotoAndPlay(1);
    };

    getRandomInstruments = async () => {
        this.instrument = getRandomNumber(1, 10);
        await playSound(`/music/sounds/instruments/tool${this.instrument}.mp3`);
    };

    loadSoundsInstruments = async () => {
        for (let i = 0; i < 10; i++) {
            loadSound(`/music/sounds/instruments/tool${i + 1}.mp3`);
        }
    };

    cursorEventDefault = () => {
        for (let i = 0; i < 3; i++) {
            this.root[`instrument${i}`].cursor = "default";
        }
    };
    cursorEventPointer = () => {
        for (let i = 0; i < 3; i++) {
            this.root[`instrument${i}`].cursor = "pointer";
        }
    };

    getRandomPlace = () => {
        this.place = getRandomNumber(0, 2);
    };

    instrumentsEvent = () => {
        this.enableEvents = true;
        for (let i = 0; i < 3; i++) {
            this.root[`instrument${i}`].cursor = "pointer";
            this.root[`instrument${i}`].addEventListener("click", async () => {
                if (!this.enableEvents) return;
                this.cursorEventDefault();
                this.enableEvents = false;
                if (this.place === i) {
                    stopAllSounds();
                    this.root[`star${this.correctAnswer}`].gotoAndStop("on");
                    this.correctAnswer++;
                    this.disableHelp();
                    this.root.host_mc.gotoAndPlay("success");
                    this.sayGood();
                    await waitForEvent(this.root, "success");
                    this.root.curtain_mc.gotoAndPlay("close");
                    await waitForEvent(this.root, "close");
                    if (this.correctAnswer < 5) {
                        this.newSound();
                        this.cursorEventPointer();
                    } else {
                        this.root.host_mc.gotoAndStop("idle");
                        this.correctAnswer = 0;
                        this.root.gameover.gotoAndPlay(1);
                        await waitForEvent(this.root, "newGame");
                        this.restartStar();
                        this.startGame();
                        this.cursorEventPointer();
                    }
                } else {
                    stopAllSounds();
                    this.disableHelp();
                    this.root.host_mc.gotoAndPlay("fail");
                    await loadSound(`/music/sounds/instruments/again.mp3`);
                    await playSound(`/music/sounds/instruments/again.mp3`);
                    this.root.curtain_mc.gotoAndPlay("close");
                    await waitForEvent(this.root, "close");
                    this.root.host_mc.gotoAndStop("idle");
                    await playSound(`/music/sounds/instruments/tool${this.instrument}.mp3`);
                    this.root.curtain_mc.gotoAndPlay(2);
                    this.cursorEventPointer();
                    this.enableHelp();
                }
                this.enableEvents = true;
            });
        }
    };
    newSound = async () => {
        this.root.host_mc.gotoAndStop("idle");
        this.getRandomInstruments();
        this.getRandomPlace();
        this.placeInstruments();
        await waitForTimer(1700);
        this.enableHelp();
        this.root.curtain_mc.gotoAndPlay(1);
    };

    restartGame = async () => {
        this.disableHelp();
        this.correctAnswer = 0;
        await loadSound(`/music/sounds/instruments/inst.mp3`);
        await playSound(`/music/sounds/instruments/inst.mp3`);
    };

    restartStar = () => {
        for (let i = 0; i < 5; i++) {
            this.root[`star${i}`].gotoAndStop("off");
        }
    };

    sayGood = async () => {
        const num = getRandomNumber(1, 10);
        await loadSound(`/music/sounds/instruments/good${num}.mp3`);
        await playSound(`/music/sounds/instruments/good${num}.mp3`);
    };

    placeInstruments = () => {
        let instrument;
        for (let i = 0; i < 3; i++) {
            if (i === this.place) {
                this.root[`instrument${i}`].gotoAndStop(`tool${this.instrument}`);
            } else {
                let number = getRandomNumber(1, 10);
                while (number === this.instrument || number === instrument) {
                    number = getRandomNumber(1, 10);
                }
                instrument = number;
                this.root[`instrument${i}`].gotoAndStop(`tool${number}`);
            }
        }
    };

    loaded = async () => {
        const mask = new createjs.Shape();
        mask.graphics.beginFill("#000").drawRect(0, 0, 800, 600);
        this.root.gameover.mask = mask;
        this.loadSoundsInstruments();
        await this.restartGame();
        await this.startGame();
        this.instrumentsEvent();
    };
}
