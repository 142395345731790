import { MC, playSound, stopAllSounds, waitForTimer } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { getBongoSound } from "./common";

export class RevealGame {
    private frames: number[] = [];
    private should_Change: boolean = false;
    private isUnloaded: boolean = false;
    constructor(
        private root: MC,
        private onSuccess: () => void,
        private sound: string,
        private numPieces: number,
        private numCovers: number
    ) {}
    start = () => {
        this.root.gotoAndStop(0);
        for (let i = 1; i <= this.numPieces; i++) {
            this.root["sq" + i].gotoAndStop(0);
        }

        this.root.board.addEventListener("click", () => {
            playSound(getBongoSound());
        });
        this.initPieces();
    };

    unload = () => {
        this.isUnloaded = true;
    };

    initPieces = async () => {
        await stopAllSounds();
        for (let i = 1; i <= this.numPieces; i++) {
            if (this.isUnloaded) return;
            const piece = this.root["sq" + i];
            piece.cursor = "pointer";
            const frame = getRandomNumber(0, this.numCovers);
            piece.gotoAndStop(frame);
            playSound(this.sound);
            await waitForTimer(60);
            this.frames[i] = frame;
            this.addListeners(piece, i);
        }
    };
    mouseDown = (piece: MC, index: number) => {
        piece.addEventListener("mousedown", async () => {
            if (this.should_Change) {
                return;
            }
            this.should_Change = true;
            await playSound(getBongoSound());
        });
    };

    pressup = (piece: MC, i: number) => {
        piece.addEventListener("pressup", async () => {
            if (this.should_Change) {
                const current_frame = this.frames[i];
                if (current_frame < this.numCovers) {
                    piece.gotoAndStop(current_frame + 1);
                    this.frames[i] = current_frame + 1;
                } else {
                    //move from last frame to 1
                    piece.gotoAndStop(0);
                    this.frames[i] = 0;
                }
                playSound(this.sound);
                if (this.checkSuccess()) {
                    this.disableclicks();
                    this.onSuccess();
                }
                this.should_Change = false;
            }
        });
    };

    mouseout = (piece: MC, index: number) => {
        piece.addEventListener("mouseout", () => {
            this.should_Change = false;
        });
    };

    addListeners = (piece: MC, index: number) => {
        this.mouseDown(piece, index);
        this.pressup(piece, index);
        this.mouseout(piece, index);
    };

    checkSuccess = () => {
        for (let i = 1; i <= this.numPieces; i++) {
            if (this.frames[i] !== 0) return false;
        }
        return true;
    };
    disableclicks = () => {
        for (let i = 1; i <= this.numPieces; i++) {
            const piece = this.root["sq" + i];
            piece.removeAllEventListeners();
        }
    };
}
