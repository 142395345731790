import { getRandomNumber } from "../../../utils/MathUtils";

const jsonData = require("./hipomazeData.json");

export function getPageSetup(level, screensShown) {
    let Screens = [];
    let index;
    const levelString = (level - 1).toString();
    jsonData.Screens.forEach((sc) => {
        if (sc.level === levelString) {
            Screens.push(sc);
        }
    });
    do {
        index = getRandomNumber(0, Screens.length - 1);
    } while (screensShown.includes(index));
    const rowData = Screens[index].Row;
    const initx = Screens[index].initx;
    const inity = Screens[index].inity;

    return { rowData, initx, inity, index };
}
