import React from "react";
import AnimateCC from "../../animate";
import { BigWheelGame } from "./BigWheelGame";
import additionalSounds from "./BigWheelSounds";
import { routes } from "../../navigation/routesPath";
import NumberEightComponent from "./number_eight/NumberEightComponent";
import NumberFiveComponent from "./number_five/NumberFiveComponent";
import NumberFourComponent from "./number_four/NumberFourComponent";
import NumberNineComponent from "./number_nine/NumberNineComponent";
import NumberOneComponent from "./number_one/NumberOneComponent";
import NumberSevenComponent from "./number_seven/NumberSevenComponent";
import NumberSixComponent from "./number_six/NumbeSixComponent";
import NumberThreeComponent from "./number_three/NumberThreeComponent";
import NumberTwoComponent from "./number_two/NumberTwoComponent";
import NumberTenComponent from "./number_ten/NumberTenComponent";
import { Switch } from "react-router-dom";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { SodRoute } from "../../navigation/PrivateRoot";

const BigWheelComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new BigWheelGame(frame));

    return (
        <>
            <Switch>
                <SodRoute path={routes.lunapark.wheel.one}>
                    <NumberOneComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.two}>
                    <NumberTwoComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.three}>
                    <NumberThreeComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.four}>
                    <NumberFourComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.five}>
                    <NumberFiveComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.six}>
                    <NumberSixComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.seven}>
                    <NumberSevenComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.eight}>
                    <NumberEightComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.nine}>
                    <NumberNineComponent frame={game.current} key={Date.now()} />
                </SodRoute>

                <SodRoute path={routes.lunapark.wheel.ten}>
                    <NumberTenComponent frame={game.current} key={Date.now()} />
                </SodRoute>
            </Switch>
            <AnimateCC
                base="/lunapark/big_wheel"
                animationName="big_wheel"
                additionalSounds={additionalSounds}
                onLoaded={game.current.loaded}
                changeFramerate={true}
            />
        </>
    );
};
export default BigWheelComponent;
