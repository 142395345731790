import React from "react";
import { Switch } from "react-router-dom";
import AnimateCC from "../../animate";
import { AbcMenu } from "./AbcMenu";
import BeeComponent from "./bee/BeeComponent";
import CurtainComponent from "./curtain/CurtainComponent";
import GlassComponent from "./glass/GlassComponent";
import IcecreamAbcComponent from "./icecream_abc/IcecreamAbcComponent";
import NoseComponent from "./nose/NoseComponent";
import EggComponent from "./egg/EggComponent";
import BulbComponent from "./bulb/BulbComponent";
import RocketAbcComponent from "./rocket_abc/RocketAbcComponent";
import UpsidedownComponent from "./upsidedown/UpsidedownComponent";
import StarComponent from "./star/StarComponent";
import HeartComponent from "./heart/HeartComponent";
import CakeComponent from "./cake/CakeComponent";
import WindowComponent from "./window/WindowComponent";
import MandolineComponent from "./mandoline/MandolineComponent";
import YellowComponent from "./yellow/YellowComponent";
import PenguinComponent from "./penguin/PenguinComponent";
import TigerComponent from "./tiger/TigerComponent";
import KaliedascopeComponent from "./kaliedascope/KaliedascopeComponent";
import RobotComponent from "./robot/RobotComponent";
import SunComponent from "./sun/SunComponent";
import TreeComponent from "./tree/TreeComponent";
import ChickenComponent from "./chicken/ChickenComponent";
import { SodRoute } from "../../navigation/PrivateRoot";
import { routes } from "../../navigation/routesPath";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

const AbcComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new AbcMenu(frame));

    return (
        <>
            <Switch>
                <SodRoute path={routes.lunapark.abc.nose}>
                    <NoseComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.egg}>
                    <EggComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.bulb}>
                    <BulbComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.icecream}>
                    <IcecreamAbcComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.bee}>
                    <BeeComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.upsidedown}>
                    <UpsidedownComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.star}>
                    <StarComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.curtain}>
                    <CurtainComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.glass}>
                    <GlassComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.rocket}>
                    <RocketAbcComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.heart}>
                    <HeartComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.cake}>
                    <CakeComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.window}>
                    <WindowComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.tree}>
                    <TreeComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.mandoline}>
                    <MandolineComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.yellow}>
                    <YellowComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.penguin}>
                    <PenguinComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.tiger}>
                    <TigerComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.kaleidoscope}>
                    <KaliedascopeComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.robot}>
                    <RobotComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.sun}>
                    <SunComponent frame={game.current} />
                </SodRoute>

                <SodRoute path={routes.lunapark.abc.chicken}>
                    <ChickenComponent frame={game.current} />
                </SodRoute>
            </Switch>
            <AnimateCC
                base="/lunapark/abc/abc_menu"
                animationName="abc_menu"
                onLoaded={game.current.loaded}
                onUnload={game.current.unload}
            />
        </>
    );
};
export default AbcComponent;
