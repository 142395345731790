import { MC, playSound, setupStage, waitForEvent } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
export class NumberNineGame {
    private root: MC;
    private counter = 0;
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).NineGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.gotoAndStop(2);
        for (let i = 1; i <= 9; i++) {
            this.root["seed_" + i].scale = (getRandomNumber(1, 25) + 75) / 100;
            this.root["seed_" + i].visible = false;
            this.root["seed_" + i].gotoAndStop(0);
            this.addListeners(this.root["seed_" + i], i);
        }
        this.root.text.text = this.counter;
        this.root.seed_1.visible = true;
    };
    addListeners = (seed: MC, i: number) => {
        seed.seed_btn.addEventListener("pressup", async () => {
            if (i - 1 === this.counter) {
                seed.gotoAndPlay(0);

                this.counter += 1;
                await waitForEvent(this.root, "next");
                this.root.text_2.text = this.counter;
                if (this.counter !== 9) {
                    this.root["seed_" + (this.counter + 1)].visible = true;
                    this.root["seed_" + this.counter].currentFrame = 61;

                    seed.seed_btn.cursor = "default";
                } else {
                    this.frame.root.Bar.againbut.visible = true;
                }
                this.root["seed_" + this.counter].gotoAndStop(62);
                playSound("flower");
            }
        });
    };
}
