import React from "react";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import { BibleFrame } from "../story_menu/BibleFrame";
import { CreationBibleGame } from "./CreationBibleGame";
import { additionalSounds } from "./CreationBibleSounds";

const CreationGameComponent: React.FC<{ type: string; frame: BibleFrame; onLoaded: () => void }> = ({
    type,
    frame,
    onLoaded,
}) => {
    const game = React.useRef(new CreationBibleGame(type, frame));
    return (
        <AnimateCC
            base="/bible_stories/creation_game"
            animationName="creation_game"
            onLoaded={(mc: MC) => {
                game.current.loaded(mc);
                onLoaded();
            }}
            additionalSounds={additionalSounds}
        />
    );
};
export default CreationGameComponent;
