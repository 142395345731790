import { localToGlobal, MC } from "../../animate/utils";
import * as TextFunctions from "../utils/TextFunctions";
import { CreationGame } from "../CreationGame";
import { Store } from "./Store";
import { TEXT_INIT_SCALE, TEXT_INIT_SIZE } from "../utils/constants";
import { creationTooltip } from "../utils/creationTooltip";
const jsonData = require("../mapping/sentences.json");

export class SentencesStore extends Store {
    private currentSentencesPanel = 0;
    constructor(private root: MC, private creationGame: CreationGame) {
        super(9, 0, root.panel_lines);
        this.loaded(root);
    }
    loaded = async (mc: MC) => {
        this.root = mc;
        (window as any).sentences = this.root;
        this.readJsonFile();
        this.initHits();
    };

    initHits = () => {
        for (let i = 1; i <= 14; i++) {
            this.root.panel_lines["sentence" + i + "_hit"].mouseEnabled = false;
        }
    };

    prevFrame = () => {
        this.currentSentencesPanel -= 1;
        if (this.currentSentencesPanel < 0) this.currentSentencesPanel = 9;
        this.readJsonFile();
        this.initHits();
    };
    nextFrame = () => {
        this.currentSentencesPanel += 1;
        if (this.currentSentencesPanel > 9) this.currentSentencesPanel = 0;
        this.readJsonFile();
        this.initHits();
    };

    readJsonFile = () => {
        const textField = this.root.panel_lines.listName;
        textField.text = jsonData[this.currentSentencesPanel].title;
        textField.lineHeight = 24;
        const h = textField.getMeasuredHeight();
        const anchor = this.root.panel_lines.title_center;
        textField.y = anchor.y - h / 2;

        let i = 1;
        while (jsonData[this.currentSentencesPanel].sentences[i - 1]) {
            this.initSentenceHit(i);
            i++;
        }
        while (i <= 14) {
            const sentence = this.root.panel_lines["sentence" + i + "_txt"];
            sentence.text = null;
            sentence.removeAllEventListeners();
            i++;
        }
    };
    createNewSentence = (i: number, id: string) => {
        const textX = 765;
        const line = this.root.panel_lines["sentence" + i + "_txt"];
        const textY = localToGlobal(line, 0, 0).y + TEXT_INIT_SIZE / 2;
        const text = line.text;
        TextFunctions.generateStaticInput(textX, textY, text, TEXT_INIT_SCALE, this.creationGame, 1);
    };
    isChildElement = (child: MC) => {
        if (child && child.name && child.name?.startsWith("sentence")) {
            const start = child.name.split("_")[0];
            const index = start.slice(8, start.length);
            return jsonData[this.currentSentencesPanel].sentences[index - 1];
        }
        return false;
    };

    initSentenceHit = (i: number) => {
        const sentence = this.root.panel_lines["sentence" + i + "_txt"];
        sentence.text = jsonData[this.currentSentencesPanel].sentences[i - 1].sentence;

        const text = sentence.text;
        let newText = new createjs.Text(text, "bold 18px NarkisTamMFO");
        newText.textBaseline = "alphabetic";
        const hit = new createjs.Shape();
        hit.graphics
            .beginFill("#000")
            .drawRect(-newText.getMeasuredWidth(), 0, newText.getMeasuredWidth(), newText.getMeasuredHeight());
        sentence.hitArea = hit;

        sentence.removeAllEventListeners();
        const soundPath = jsonData[this.currentSentencesPanel].sentences[i - 1]?.sound;
        const id = jsonData[this.currentSentencesPanel].sentences[i - 1]?.id;
        soundPath && creationTooltip.addToolTip(sentence, soundPath);

        sentence.addEventListener("click", () => {
            this.createNewSentence(i, id);
        });
    };
}
