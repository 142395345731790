import { MC, setupStage, stopAllSounds, waitForEvent } from "../animate/utils";
import { loadSound, soundPlayed } from "../animate/sound_utils";
import { DubbyBaseGame } from "./DubbyGames/DubbyBaseGame";
import DubbyBathGame from "./DubbyGames/DubbyBathRoom";
import DubbyBedGame from "./DubbyGames/DubbyBedRoom";
import DubbyLivingGame from "./DubbyGames/DubbyLivingRoom";
import DubbyKitchenGame from "./DubbyGames/DubbyKitchenRoom";
import { CancelablePromise } from "../utils/CancelablePromise";
import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { getRandomNumber } from "../utils/MathUtils";

export class DubbyEnglishGames {
    gameType: string;
    roomType: string;
    frame: MC;
    currentPromise: CancelablePromise<any>;
    helpSrc: Promise<createjs.AbstractSoundInstance>;
    helpBtnClicked: boolean = false;
    dubbyIsVisible: boolean = false;
    dubbyCurrentGame: DubbyBaseGame;

    constructor(gameType: string, roomType: string) {
        this.gameType = gameType;
        this.roomType = roomType;
    }

    dubbyEnglishFrameLoaded = (root: MC) => {
        this.frame = root;
        this.frame.mcDubi.stop();
        if (this.dubbyCurrentGame) {
            this.startGame();
        }
    };

    dubbyEnglishGamesLoaded = (root: MC) => {
        this.dubbyCurrentGame = this.returnCurrentGame();
        this.dubbyCurrentGame.root = root;
        root.visible = false;
        this.dubbyCurrentGame.hidePreloader = this.hidePreloader;
        this.dubbyCurrentGame.showDubby = this.showDubby;
        this.dubbyCurrentGame.setSpeaker = this.setSpeaker;
        this.dubbyCurrentGame.setText = this.setText;
        this.dubbyCurrentGame.enabledNextBtn = this.enabledNextBtn;
        this.dubbyCurrentGame.cancelableWait = this.cancelableWait;
        this.dubbyCurrentGame.showDubbyFeedBack = this.showDubbyFeedBack;
        this.dubbyCurrentGame.enabledHelpBtn = this.helpBtn;
        this.dubbyCurrentGame.sethelpSrc = this.sethelpSrc;

        if (this.frame) {
            this.startGame();
        }
    };
    sethelpSrc = (src: string) => {
        this.helpSrc = loadSound(src);
    };

    startGame = () => {
        this.dubbyCurrentGame.loaded();
        switch (this.gameType) {
            case "bee":
                this.dubbyCurrentGame.beeMode();
                break;
            case "whatin":
                this.dubbyCurrentGame.whatInMode();
                break;
            case "build":
                this.dubbyCurrentGame.buildMode();
                break;
        }
        this.exitButtonEvents();
        this.helpBtn(false);
        if (this.gameType === "build") {
            this.enabledNextBtn(false);
            this.setSpeaker(false);
        }
        setupStage(this.frame, this.dubbyCurrentGame?.root);
    };

    exitButtonEvents = () => {
        const exit = this.frame.btnExit;
        exit.cursor = "pointer";
        exit.mouseChildren = false;
        exit.gotoAndStop(0);
        exit.addEventListener("click", () => {
            this.dubbyCurrentGame.currentPromise?.cancel?.();
            this.currentPromise?.cancel?.();
            this.dubbyCurrentGame.cleanWhatsIn();
            stopAllSounds();
            Navigation.openPage(routes.dubby_english.menu);
        });
        exit.addEventListener("rollover", () => {
            exit.gotoAndStop("over");
        });
        exit.addEventListener("rollout", () => {
            exit.gotoAndStop("normal");
        });
    };

    helpBtn = (btnStatus: boolean) => {
        const help = this.frame.btnHelp;
        help.mouseChildren = false;
        help.mouseEnabled = btnStatus;
        help.gotoAndStop(0);
        if (btnStatus && !this.dubbyIsVisible) {
            help.removeAllEventListeners();
            help.cursor = "pointer";
            help.addEventListener("rollover", () => {
                if (!this.helpBtnClicked) {
                    help.gotoAndStop("over");
                }
            });
            help.addEventListener("rollout", () => {
                if (!this.helpBtnClicked) {
                    help.gotoAndStop("normal");
                }
            });
            help.addEventListener("click", async () => {
                this.helpBtnClicked = true;
                help.gotoAndStop("disabled");
                this.dubbyCurrentGame.enabledHelpBtn(false);
                if (this.gameType === "whatin") {
                    await this.whatInModeHelpBtn();
                } else {
                    await soundPlayed((await this.helpSrc).play());
                }
                this.dubbyCurrentGame.enabledHelpBtn(true);
                help.gotoAndStop("normal");
                this.helpBtnClicked = false;
            });
        } else {
            help.removeAllEventListeners();
            help.gotoAndStop("disabled");
        }
    };

    whatInModeHelpBtn = async () => {
        clearInterval(this.dubbyCurrentGame.whatsInInterval);
        stopAllSounds();
        await soundPlayed((await this.helpSrc).play());
        await soundPlayed((await this.dubbyCurrentGame.currentItemSound).play());
        this.dubbyCurrentGame.whatsInInterval = this.dubbyCurrentGame.whatInSetInterval();
    };

    enabledNextBtn = (btnStatus: boolean) => {
        const next = this.frame.btnNext;
        if (btnStatus) {
            next.cursor = "pointer";
            next.gotoAndStop(0);
            next.addEventListener("rollover", () => {
                next.gotoAndStop("over");
            });
            next.addEventListener("rollout", () => {
                next.gotoAndStop("normal");
            });
            next.addEventListener("click", () => {
                this.dubbyCurrentGame.nextObject();
            });
        } else {
            next.removeAllEventListeners();
            next.gotoAndStop("disabled");
        }
    };

    hidePreloader = () => {
        this.frame.preloader.visible = false;
    };
    setSpeaker = (btnStatus: boolean) => {
        const btnSpeaker = this.frame.btnSpeaker;
        btnSpeaker.gotoAndStop(btnStatus ? "normal" : "disabled");
        if (btnStatus) {
            btnSpeaker.cursor = "pointer";
            btnSpeaker.addEventListener("click", () => {
                this.dubbyCurrentGame.playAgain();
            });
        } else {
            btnSpeaker.removeAllEventListeners();
        }
    };
    setText = (objectName: string) => {
        this.frame.word.text = objectName;
    };
    showDubby = async () => {
        this.dubbyIsVisible = true;
        this.dubbyCurrentGame.root.mouseEnabled = false;
        this.dubbyCurrentGame.enabledKeyBoard = false;
        this.helpBtn(false);
        const currentInst = loadSound(this.returnCurrentModeInst());
        this.frame.mcDubi.gotoAndPlay(0);
        await this.cancelableWait(waitForEvent(this.frame.mcDubi, "startTalk"));
        this.frame.mcDubi.stop();
        this.frame.mcDubi.head.gotoAndPlay(0);
        await this.cancelableWait(soundPlayed((await currentInst).play()));
        this.dubbyIsVisible = false;
        this.helpBtn(true);
        this.frame.mcDubi.gotoAndPlay("walkOut");
        await this.cancelableWait(waitForEvent(this.frame.mcDubi, "animationEnd"));
        this.frame.mcDubi.stop();
        this.dubbyCurrentGame.root.mouseEnabled = true;
        this.dubbyCurrentGame.enabledKeyBoard = true;
    };

    returnCurrentGame = () => {
        switch (this.roomType) {
            case "living":
                return new DubbyLivingGame();
            case "bed":
                return new DubbyBedGame();
            case "kitchen":
                return new DubbyKitchenGame();
            case "bath":
                return new DubbyBathGame();
        }
    };

    async cancelableWait(p: Promise<any>) {
        this.currentPromise = p as CancelablePromise<any>;
        await p;
        this.currentPromise = null;
    }
    returnCurrentModeInst = () => {
        let currentInst: any;
        switch (this.gameType) {
            case "bee":
                currentInst = "/dubby_english/sounds/bee/inst.mp3";
                break;
            case "whatin":
                currentInst = "/dubby_english/sounds/whatsIn/inst.mp3";
                break;
            case "build":
                currentInst = "/dubby_english/sounds/build/inst.mp3";
                break;
        }
        return currentInst;
    };
    showDubbyFeedBack = async (withReaction: boolean = false) => {
        this.dubbyCurrentGame.root.mouseEnabled = false;
        this.helpBtn(false);
        this.dubbyIsVisible = true;
        const dubbyAnimation = this.randomDubbyFeedback();
        this.frame.mcDubi.gotoAndPlay(dubbyAnimation);
        if (withReaction) {
            await this.cancelableWait(waitForEvent(this.frame.mcDubi, "startTalk"));
            this.frame.mcDubi.stop();
            const stopedFrame = this.frame.mcDubi.currentFrame;
            this.frame.mcDubi.head.gotoAndPlay(0);
            const dubbyReaction = loadSound(`/dubby_english/sounds/${this.dubbyCurrentGame.dubbyReactionSrcSound}.mp3`);
            await this.cancelableWait(soundPlayed((await dubbyReaction).play()));
            if (this.gameType === "whatin") {
                await this.cancelableWait(soundPlayed((await this.dubbyCurrentGame.currentItemSound).play()));
            }
            this.cancelableWait(waitForEvent(this.frame.mcDubi.head, "stopTalking"));
            this.frame.mcDubi.gotoAndPlay(stopedFrame);
        }
        await this.cancelableWait(waitForEvent(this.frame.mcDubi, "animationEnd"));
        if (dubbyAnimation === "pop1") {
            stopAllSounds();
            this.frame.mcDubi.gotoAndPlay("walkOut");
            await this.cancelableWait(waitForEvent(this.frame.mcDubi, "animationEnd"));
        }
        this.dubbyCurrentGame.root.mouseEnabled = true;
        this.dubbyIsVisible = false;
    };
    randomDubbyFeedback = () => {
        const dubbyAnimIndex = getRandomNumber(1, 6);
        return `pop${dubbyAnimIndex}`;
    };
}
