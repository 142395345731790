import { CancelablePromise } from "../utils/CancelablePromise";

export type MC = createjs.MovieClip & any;
export const lettersArray = [
    "א",
    "ב",
    "ג",
    "ד",
    "ה",
    "ו",
    "ז",
    "ח",
    "ט",
    "י",
    "כ",
    "ל",
    "מ",
    "נ",
    "ס",
    "ע",
    "פ",
    "צ",
    "ק",
    "ר",
    "ש",
    "ת",
    "ך",
    "ם",
    "ן",
    "ף",
    "ץ",
];
const endsLetters = [
    { letter: "מ", letter_f: "ם", letter_index: 24 },
    { letter: "כ", letter_f: "ך", letter_index: 23 },
    { letter: "צ", letter_f: "ץ", letter_index: 27 },
    { letter: "פ", letter_f: "ף", letter_index: 26 },
    { letter: "נ", letter_f: "ן", letter_index: 25 },
];

export function getLastLetterIndex(letterString: any) {
    const letter = getLetter(letterString);
    let letter_f_index: any = null;
    endsLetters.forEach((obj) => {
        if (obj.letter === letter) {
            letter_f_index = obj.letter_index;
        }
    });
    if (letter_f_index !== null) {
        return letter_f_index;
    }
    return null;
}

export function getBeginLetter(letterString: string) {
    let correctLetter;
    endsLetters.forEach((obj) => {
        if (letterString === obj.letter_f) {
            correctLetter = obj.letter;
        }
    });
    return correctLetter;
}

export function getEndLetter(letterIndex: any) {
    const letter = getLetter(letterIndex);
    let letter_f: any = null;
    endsLetters.forEach((obj) => {
        if (obj.letter === letter) {
            letter_f = obj.letter_f;
        }
    });
    if (letter_f !== null) {
        return letter_f;
    }
    return null;
}

export function waitForEvent(mc: createjs.MovieClip, name: string) {
    let reject: (reason?: any) => void;
    let listener: (event: Object) => void;

    const promise = new Promise((resolve, r) => {
        reject = r;
        listener = (event: Object) => {
            mc.removeEventListener(name, listener);
            resolve(event);
        };
        mc.addEventListener(name, listener);
    });

    (promise as CancelablePromise<any>).cancel = () => {
        mc.removeEventListener(name, listener);
        reject("canceled");
    };

    return promise;
}

export function waitFor(getter: () => any, interval: number = 10) {
    return new Promise<void>((resolve) => {
        const i = setInterval(() => {
            if (getter()) {
                clearInterval(i);
                resolve();
            }
        }, interval);
    });
}

export function waitUntilVisible() {
    if (isDocVisible()) return Promise.resolve();
    return new Promise<void>((resolve) => {
        const listener = () => {
            if (isDocVisible()) {
                document.removeEventListener("visibilitychange", listener);
                resolve();
            }
        };
        document.addEventListener("visibilitychange", listener);
    });
}

export function isDocVisible() {
    return document.visibilityState === "visible";
}

export function setupStage(top: createjs.MovieClip, bottom: createjs.MovieClip) {
    setImmediate(() => {
        if (top?.stage && bottom?.stage) {
            top.stage.nextStage = bottom.stage;
            (bottom.stage as any).prevStage = top.stage;
            bottom.stage.enableDOMEvents(false);
        }
    });
}

export function contains(parent: createjs.Container, child: createjs.Container) {
    do {
        if (parent === child) return true;
        child = child.parent;
    } while (child.parent);
}

// export function localToGlobal(mc: MC, x: number, y: number) {
//     const global = mc.localToGlobal(x, y);
//     return new createjs.Point(global.x / mc.stage.scaleX, global.y / mc.stage.scaleY);
// }

// export function globalToLocal(mc: MC, x: number, y: number) {
//     return mc.globalToLocal(x * mc.stage.scaleX, y * mc.stage.scaleY);
// }

export function localToGlobal(mc: createjs.MovieClip, x: number, y: number) {
    const global = mc.localToGlobal(x, y);
    const stage = mc.stage;
    const matrix = new createjs.Matrix2D(stage.scaleX, stage.skewX, stage.skewY, stage.scaleY, stage.x, stage.y);
    return matrix.invert().transformPoint(global.x, global.y);
    // return new createjs.Point(global.x / mc.stage.scaleX, global.y / mc.stage.scaleY);
}
export function globalToLocal(mc: createjs.MovieClip, x: number, y: number) {
    const stage = mc.stage;
    const matrix = new createjs.Matrix2D(stage.scaleX, stage.skewX, stage.skewY, stage.scaleY, stage.x, stage.y);
    const global = matrix.transformPoint(x, y);
    return mc.globalToLocal(global.x, global.y);
}

export function loadFont(root: createjs.Container, font: string, bold: boolean = false) {
    root.addChild(new createjs.Text(".", `${bold ? "bold " : ""}1px '${font}'`));
}

export function optimize(mc: createjs.MovieClip) {
    mc.paused = true;
    mc.tickEnabled = false;
    mc.mouseEnabled = false;
    mc.mouseChildren = false;
}

export const playSound: (id: string, props?: any) => Promise<void> = (window as any).playSoundAsync;
export const stopAllSounds = () => createjs.Sound.stop();
export const playSoundSync: (id: string, props?: any) => createjs.AbstractSoundInstance = (window as any).playSound;

export function waitForTimer(ms: number) {
    let reject: (reason?: any) => void;
    let timeoutHandle: number;

    const promise = new Promise((resolve, r) => {
        reject = r;
        timeoutHandle = window.setTimeout(resolve, ms);
    });

    (promise as CancelablePromise<any>).cancel = () => {
        window.clearTimeout(timeoutHandle);
        reject("canceled");
    };
    return promise;
}

export const updateCacheRecursive = (root: createjs.MovieClip | createjs.DisplayObject) => {
    if (root instanceof createjs.MovieClip) {
        root.children.forEach((c) => {
            updateCacheRecursive(c);
        });
    }
    root.updateCache();
};

export function cancelEvent(e: createjs.Event) {
    e.preventDefault();
}

export const cancelRightClickEvent = (e: createjs.Event) => {
    if (isRightClick(e)) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
    }
};

const isRightClick = (event?: createjs.Event) => {
    return event && (event as createjs.MouseEvent).nativeEvent.button !== 0;
};

export const getLetter = (index: number) => {
    return lettersArray[index];
};
export const getLetterIndex = (letter: string) => {
    return lettersArray.indexOf(letter);
};

function print(mc: MC, parent: string = "") {
    Object.keys(mc).forEach((name) => {
        if (mc[name]?.hasOwnProperty("framerate") && name !== "parent" && name !== "stage") {
            console.log(parent, name, mc[name]);
            //print(name, mc[name])
        }
    });
}

(window as any).printMC = print;

function showChild(mc: MC, n: number) {
    mc.children.forEach((c: MC) => (c.visible = false));
    mc.children[n].visible = true;

    Object.keys(mc).forEach((name) => {
        if (mc[name] === mc.children[n]) {
            console.log(n, name);
        }
    });
}

(window as any).showChild = showChild;

function hideChild(mc: MC, n: number) {
    mc.children[n].visible = false;

    Object.keys(mc).forEach((name) => {
        if (mc[name] === mc.children[n]) {
            console.log(n, name);
        }
    });
}

(window as any).hideChild = hideChild;

export const asyncTween = (object: MC, props: {}, duration: number, ease: Function = createjs.Ease.cubicOut) => {
    let reject: (reason?: any) => void;
    const promise = new Promise((resolve, r) => {
        reject = r;
        createjs.Tween.get(object).to(props, duration, ease).call(resolve);
    });

    (promise as CancelablePromise<any>).cancel = () => {
        createjs.Tween.removeTweens(object);
        reject("canceled");
    };
    return promise;
};

export const navigateMap = (direction: string, arrows: MC, directionValues: number[]) => {
    switch (direction) {
        case "Left":
            arrows.x += arrows.x < directionValues[0] ? 5 : 0;
            break;
        case "Right":
            arrows.x -= arrows.x > directionValues[1] ? 5 : 0;
            break;
        case "Up":
            arrows.y += arrows.y < directionValues[2] ? 5 : 0;
            break;
        case "Down":
            arrows.y -= arrows.y > directionValues[3] ? 5 : 0;
            break;
        default:
            break;
    }
};
