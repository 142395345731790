import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, setupStage } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { BOUND_X, BOUND_Y, getBubbleSound, getBongoSound, HEIGHT, WIDTH } from "./common";

const BUBBLE_DIM = 100;
export class PicGame {
    private root: MC;
    private pressed_index: number = 0;
    private should_move: boolean = false;
    private moveX: number = 0;
    private moveY: number = 0;
    private success_num = 0;

    constructor(private frame: any, private isBibleSection: boolean = false) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        (window as any).frame = this.frame.root;
        setupStage(this.frame?.root, this.root);
        this.frame?.toggleInner(false);
        // this.game = new MatchGame(this.root);
        this.should_move = false;
        this.root.addEventListener("tick", () => {
            this.moveElement();
        });
        playSound("Load");
        this.root.gotoAndStop(4);
        this.root.board.addEventListener("click", () => {
            playSound(getBongoSound());
        });
        this.root.image1.addEventListener("click", () => {
            playSound(getBongoSound());
        });
        this.success_num = 0;
        this.initPieces();
    };

    initPieces = () => {
        for (let i = 1; i <= 5; i++) {
            const bubble = this.root["bubble" + i];
            bubble.cursor = "pointer";
            bubble.image2.x = getRandomNumber(0, 600) - 300;
            bubble.image2.y = getRandomNumber(0, 250) - 125;
            this.addListeners(bubble, i);
        }
    };

    mouseDown = (piece: MC, index: number) => {
        piece.addEventListener("mousedown", async () => {
            if (this.should_move) {
                // press while someone is moving
                if (this.pressed_index === index) {
                    await playSound(getBongoSound());
                }
                if (
                    this.root.getChildIndex(piece) <= this.root.getChildIndex(this.root["bubble" + this.pressed_index])
                ) {
                    return;
                }
            }
            this.pressed_index = index;
            this.should_move = false;
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.moveX = localMouse.x - piece.x;
            this.moveY = localMouse.y - piece.y;
            await playSound(getBongoSound());
        });
    };

    pressup = (piece: MC, index: number) => {
        piece.addEventListener("pressup", async () => {
            if (this.should_move && this.pressed_index === index) {
                //current piece was the last one pressed, and is on move.
                this.should_move = false;
                if (this.checkIntersection(piece)) {
                    playSound(getBubbleSound());
                    if (this.isBibleSection) {
                        this.onBibleSuccess();
                    }
                } else {
                    playSound("Click");
                }
                return;
            }
            if (this.pressed_index === index) {
                this.should_move = true;
                playSound("Click");
            }
        });
    };

    mouseout = (piece: MC, index: number) => {
        piece.addEventListener("mouseout", () => {
            if (this.pressed_index === index && !this.should_move) {
                //current piece was the last one pressed.
                this.pressed_index = 0;
            }
        });
    };

    addListeners = (piece: MC, index: number) => {
        this.mouseDown(piece, index);
        this.pressup(piece, index);
        this.mouseout(piece, index);
    };

    moveElement = () => {
        if (this.should_move) {
            if (this.root.stage.mouseX && this.root.stage.mouseY) {
                const pos = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
                const new_x = pos.x - this.moveX;
                const new_y = pos.y - this.moveY;
                const piece = this.root[`bubble${this.pressed_index}`];
                this.move_x(new_x, piece);
                this.move_y(new_y, piece);
            }
        }
    };

    checkIntersection = (bubble: MC) => {
        const image = bubble.image2;
        if (
            bubble.x + image.x > 395 &&
            bubble.x + image.x < 405 &&
            bubble.y + image.y > 220 &&
            bubble.y + image.y < 230
        ) {
            bubble.visible = false;
            return true;
        }
        return false;
    };

    onBibleSuccess = async () => {
        this.success_num += 1;
        const i = getRandomNumber(0, 5);
        if (this.success_num === 5) {
            const sound = await loadSound(`/bible_stories/puzzle_frame/sounds/good${i}.mp3`);
            sound.play();
        }
    };

    private move_y(new_y: number, piece: any) {
        if (new_y - BUBBLE_DIM / 2 >= BOUND_Y && new_y + BUBBLE_DIM / 2 <= BOUND_Y + HEIGHT) {
            piece.y = new_y;
        } else {
            if (new_y - BUBBLE_DIM / 2 < BOUND_Y) {
                piece.y = BOUND_Y + BUBBLE_DIM / 2;
            } else {
                piece.y = BOUND_Y + HEIGHT - BUBBLE_DIM / 2 - 3;
            }
        }
    }

    private move_x(new_x: number, piece: any) {
        if (new_x - BUBBLE_DIM / 2 >= BOUND_X && new_x + BUBBLE_DIM / 2 <= BOUND_X + WIDTH) {
            piece.x = new_x;
        } else {
            if (new_x - BUBBLE_DIM / 2 - 4 < BOUND_X) {
                piece.x = BOUND_X + BUBBLE_DIM / 2 + 4;
            } else {
                piece.x = BOUND_X + WIDTH - BUBBLE_DIM / 2;
            }
        }
    }
}
