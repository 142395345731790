export const getSoundsByLetter = (letter: number) => {
    switch (letter) {
        case 1:
            return [
                {
                    src: "sounds/Avi.mp3",
                    id: "avi",
                },
                {
                    src: "sounds/Alex.mp3",
                    id: "alex",
                },
                {
                    src: "sounds/Ana.mp3",
                    id: "ana",
                },
                {
                    src: "sounds/Efrat.mp3",
                    id: "efrat",
                },
                {
                    src: "sounds/Ela.mp3",
                    id: "ela",
                },
                {
                    src: "sounds/Ula.mp3",
                    id: "ula",
                },
                {
                    src: "sounds/Yoav.mp3",
                    id: "yoav",
                },
                {
                    src: "sounds/Shaul.mp3",
                    id: "shaul",
                },
                {
                    src: "sounds/Nava.mp3",
                    id: "nava",
                },
                {
                    src: "sounds/Tzeela.mp3",
                    id: "tzeela",
                },
                {
                    src: "sounds/Sagi.mp3",
                    id: "sagi",
                },
                {
                    src: "sounds/Gay.mp3",
                    id: "gay",
                },
                {
                    src: "sounds/Ofir.mp3",
                    id: "ofir",
                },
                {
                    src: "sounds/avner.mp3",
                    id: "avner",
                },
                {
                    src: "sounds/Esti.mp3",
                    id: "esti",
                },
                {
                    src: "sounds/Or.mp3",
                    id: "or",
                },
            ];
        case 2:
            return [
                {
                    src: "sounds/Bar.mp3",
                    id: "bar",
                },
                {
                    src: "sounds/Barak.mp3",
                    id: "barak",
                },
                {
                    src: "sounds/Batya.mp3",
                    id: "batya",
                },
                {
                    src: "sounds/Bela.mp3",
                    id: "bela",
                },
                {
                    src: "sounds/Ben.mp3",
                    id: "ben",
                },
                {
                    src: "sounds/Beny.mp3",
                    id: "beny",
                },
                {
                    src: "sounds/Bili.mp3",
                    id: "bili",
                },
                {
                    src: "sounds/Bina.mp3",
                    id: "bina",
                },
                {
                    src: "sounds/Boaz.mp3",
                    id: "boaz",
                },
                {
                    src: "sounds/Booky.mp3",
                    id: "booky",
                },
                {
                    src: "sounds/Boris.mp3",
                    id: "boris",
                },
                {
                    src: "sounds/Brachi.mp3",
                    id: "brachi",
                },
                {
                    src: "sounds/Gabi.mp3",
                    id: "gabi",
                },
                {
                    src: "sounds/Robi.mp3",
                    id: "robi",
                },
            ];
        case 3:
            return [
                {
                    src: "sounds/Gabi.mp3",
                    id: "gabi",
                },
                {
                    src: "sounds/Galit.mp3",
                    id: "galit",
                },
                {
                    src: "sounds/Gama.mp3",
                    id: "gama",
                },
                {
                    src: "sounds/Gay.mp3",
                    id: "gay",
                },
                {
                    src: "sounds/Gidi.mp3",
                    id: "gidi",
                },
                {
                    src: "sounds/Gila.mp3",
                    id: "gila",
                },
                {
                    src: "sounds/Golan.mp3",
                    id: "golan",
                },
                {
                    src: "sounds/Agam.mp3",
                    id: "agam",
                },
                {
                    src: "sounds/Dagan.mp3",
                    id: "dagan",
                },
                {
                    src: "sounds/Hagar.mp3",
                    id: "hagar",
                },
                {
                    src: "sounds/Chagit.mp3",
                    id: "chagit",
                },
                {
                    src: "sounds/Negba.mp3",
                    id: "negba",
                },
                {
                    src: "sounds/Sarig.mp3",
                    id: "sarig",
                },
                {
                    src: "sounds/Yigal.mp3",
                    id: "yigal",
                },
                {
                    src: "sounds/Peleg.mp3",
                    id: "peleg",
                },
                {
                    src: "sounds/Sigal.mp3",
                    id: "sigal",
                },
            ];
        case 4:
            return [
                {
                    src: "sounds/Dafna.mp3",
                    id: "dafna",
                },
                {
                    src: "sounds/Dagan.mp3",
                    id: "dagan",
                },
                {
                    src: "sounds/Dalia.mp3",
                    id: "dalia",
                },
                {
                    src: "sounds/Dalit.mp3",
                    id: "dalit",
                },
                {
                    src: "sounds/Dana.mp3",
                    id: "dana",
                },
                {
                    src: "sounds/David.mp3",
                    id: "david",
                },
                {
                    src: "sounds/dekel.mp3",
                    id: "dekel",
                },
                {
                    src: "sounds/Denis.mp3",
                    id: "denis",
                },
                {
                    src: "sounds/Dina.mp3",
                    id: "dina",
                },
                {
                    src: "sounds/Dolev.mp3",
                    id: "dolev",
                },
                {
                    src: "sounds/Dor.mp3",
                    id: "dor",
                },
                {
                    src: "sounds/Doli.mp3",
                    id: "doli",
                },
                {
                    src: "sounds/Eden.mp3",
                    id: "eden",
                },
                {
                    src: "sounds/Edna.mp3",
                    id: "edna",
                },
                {
                    src: "sounds/Ido.mp3",
                    id: "ido",
                },
                {
                    src: "sounds/Andrei.mp3",
                    id: "andrei",
                },
                {
                    src: "sounds/Adaya.mp3",
                    id: "adaya",
                },
                {
                    src: "sounds/yoad.mp3",
                    id: "yoad",
                },
                {
                    src: "sounds/Ehud.mp3",
                    id: "ehud",
                },
                {
                    src: "sounds/Vered.mp3",
                    id: "vered",
                },
                {
                    src: "sounds/Elad.mp3",
                    id: "elad",
                },
                {
                    src: "sounds/Hadas.mp3",
                    id: "hadas",
                },
                {
                    src: "sounds/Yarden.mp3",
                    id: "yarden",
                },
            ];
        case 5:
            return [
                {
                    src: "sounds/Hila.mp3",
                    id: "hila",
                },
                {
                    src: "sounds/Hilel.mp3",
                    id: "hilel",
                },
                {
                    src: "sounds/Hoopsy.mp3",
                    id: "hoopsy",
                },
                {
                    src: "sounds/Hadar.mp3",
                    id: "hadar",
                },
                {
                    src: "sounds/Hadas.mp3",
                    id: "hadas",
                },
                {
                    src: "sounds/Hagar.mp3",
                    id: "hagar",
                },
                {
                    src: "sounds/Harel.mp3",
                    id: "harel",
                },
                {
                    src: "sounds/Helen.mp3",
                    id: "helen",
                },
                {
                    src: "sounds/Yehoshua.mp3",
                    id: "yehoshua",
                },
                {
                    src: "sounds/Sahar.mp3",
                    id: "sahar",
                },
                {
                    src: "sounds/Tahel.mp3",
                    id: "tahel",
                },
                {
                    src: "sounds/lihi.mp3",
                    id: "lihi",
                },
                {
                    src: "sounds/micha.mp3",
                    id: "micha",
                },
                {
                    src: "sounds/Shira.mp3",
                    id: "shira",
                },
                {
                    src: "sounds/Dalia.mp3",
                    id: "dalia",
                },
                {
                    src: "sounds/Lahav.mp3",
                    id: "lahav",
                },
                {
                    src: "sounds/Moshe.mp3",
                    id: "moshe",
                },
                {
                    src: "sounds/Liza.mp3",
                    id: "liza",
                },
                {
                    src: "sounds/Mika.mp3",
                    id: "mika",
                },
            ];
        case 6:
            return [
                {
                    src: "sounds/Sivan.mp3",
                    id: "sivan",
                },
                {
                    src: "sounds/Vera.mp3",
                    id: "vera",
                },
                {
                    src: "sounds/Vered.mp3",
                    id: "vered",
                },
                {
                    src: "sounds/Vika.mp3",
                    id: "vika",
                },
                {
                    src: "sounds/Viktor.mp3",
                    id: "viktor",
                },
                {
                    src: "sounds/Vitali.mp3",
                    id: "vitali",
                },
                {
                    src: "sounds/David.mp3",
                    id: "david",
                },
                {
                    src: "sounds/chava.mp3",
                    id: "chava",
                },
                {
                    src: "sounds/Ziv.mp3",
                    id: "ziv",
                },
                {
                    src: "sounds/Oded.mp3",
                    id: "oded",
                },
                {
                    src: "sounds/Boris.mp3",
                    id: "boris",
                },
                {
                    src: "sounds/Ruty.mp3",
                    id: "ruty",
                },
                {
                    src: "sounds/Stav.mp3",
                    id: "stav",
                },
            ];
        case 7:
            return [
                {
                    src: "sounds/Zamir.mp3",
                    id: "zamir",
                },
                {
                    src: "sounds/Zeev.mp3",
                    id: "zeev",
                },
                {
                    src: "sounds/Zigi.mp3",
                    id: "zigi",
                },
                {
                    src: "sounds/Ziv.mp3",
                    id: "ziv",
                },
                {
                    src: "sounds/Ziva.mp3",
                    id: "ziva",
                },
                {
                    src: "sounds/Zohar.mp3",
                    id: "zohar",
                },
                {
                    src: "sounds/Zehava.mp3",
                    id: "zehava",
                },
                {
                    src: "sounds/Ozri.mp3",
                    id: "ozri",
                },
                {
                    src: "sounds/Liza.mp3",
                    id: "liza",
                },
                {
                    src: "sounds/Mazal.mp3",
                    id: "mazal",
                },
                {
                    src: "sounds/Paz.mp3",
                    id: "paz",
                },
                {
                    src: "sounds/Boaz.mp3",
                    id: "boaz",
                },
                {
                    src: "sounds/Erez.mp3",
                    id: "erez",
                },
                {
                    src: "sounds/Razya.mp3",
                    id: "razya",
                },
                {
                    src: "sounds/Topaz.mp3",
                    id: "topaz",
                },
            ];
        case 8:
            return [
                {
                    src: "sounds/Chagay.mp3",
                    id: "chagay",
                },
                {
                    src: "sounds/Chagit.mp3",
                    id: "chagit",
                },
                {
                    src: "sounds/Chanan.mp3",
                    id: "chanan",
                },
                {
                    src: "sounds/Chany.mp3",
                    id: "chany",
                },
                {
                    src: "sounds/chava.mp3",
                    id: "chava",
                },
                {
                    src: "sounds/Chayim.mp3",
                    id: "Chayim",
                },
                {
                    src: "sounds/Chayki.mp3",
                    id: "chayki",
                },
                {
                    src: "sounds/Chedva.mp3",
                    id: "chedva",
                },
                {
                    src: "sounds/Chemda.mp3",
                    id: "chemda",
                },
                {
                    src: "sounds/Chen.mp3",
                    id: "chen",
                },
                {
                    src: "sounds/Achiya.mp3",
                    id: "achiya",
                },
                {
                    src: "sounds/Itzchak.mp3",
                    id: "itzchak",
                },
                {
                    src: "sounds/Shachar.mp3",
                    id: "shachar",
                },
                {
                    src: "sounds/Tzach.mp3",
                    id: "tzach",
                },
                {
                    src: "sounds/Yiftach.mp3",
                    id: "yiftach",
                },
                {
                    src: "sounds/Perach.mp3",
                    id: "perach",
                },
                {
                    src: "sounds/Pinchas.mp3",
                    id: "pinchas",
                },
                {
                    src: "sounds/Rachel.mp3",
                    id: "rachel",
                },
            ];
        case 9:
            return [
                {
                    src: "sounds/Tedy.mp3",
                    id: "tedy",
                },
                {
                    src: "sounds/Tiran.mp3",
                    id: "tiran",
                },
                {
                    src: "sounds/Tal.mp3",
                    id: "tal",
                },
                {
                    src: "sounds/Tova.mp3",
                    id: "tova",
                },
                {
                    src: "sounds/Tara.mp3",
                    id: "tara",
                },
                {
                    src: "sounds/Moti.mp3",
                    id: "moti",
                },
                {
                    src: "sounds/Vitali.mp3",
                    id: "vitali",
                },
                {
                    src: "sounds/Neta.mp3",
                    id: "neta",
                },
                {
                    src: "sounds/lotem.mp3",
                    id: "lotem",
                },
                {
                    src: "sounds/Shavit.mp3",
                    id: "shavit",
                },
                {
                    src: "sounds/Viktor.mp3",
                    id: "viktor",
                },
                {
                    src: "sounds/Katya.mp3",
                    id: "katya",
                },
                {
                    src: "sounds/Topaz.mp3",
                    id: "topaz",
                },
            ];
        case 10:
            return [
                {
                    src: "sounds/Yael.mp3",
                    id: "yael",
                },
                {
                    src: "sounds/Yafa.mp3",
                    id: "yafa",
                },
                {
                    src: "sounds/Yakov.mp3",
                    id: "yakov",
                },
                {
                    src: "sounds/Yarden.mp3",
                    id: "yarden",
                },
                {
                    src: "sounds/Yariv.mp3",
                    id: "yariv",
                },
                {
                    src: "sounds/Yehoshua.mp3",
                    id: "yehoshua",
                },
                {
                    src: "sounds/Yiftach.mp3",
                    id: "yiftach",
                },
                {
                    src: "sounds/Yigal.mp3",
                    id: "yigal",
                },
                {
                    src: "sounds/yoad.mp3",
                    id: "yoad",
                },
                {
                    src: "sounds/Yoav.mp3",
                    id: "yoav",
                },
                {
                    src: "sounds/Yona.mp3",
                    id: "yona",
                },
                {
                    src: "sounds/Yoram.mp3",
                    id: "yoram",
                },
                {
                    src: "sounds/Yosi.mp3",
                    id: "yosi",
                },
                {
                    src: "sounds/Yuval.mp3",
                    id: "yuval",
                },
                {
                    src: "sounds/Gay.mp3",
                    id: "gay",
                },
                {
                    src: "sounds/Tiran.mp3",
                    id: "tiran",
                },
                {
                    src: "sounds/Miryam.mp3",
                    id: "miryam",
                },
                {
                    src: "sounds/Ziv.mp3",
                    id: "ziv",
                },
                {
                    src: "sounds/Aya.mp3",
                    id: "aya",
                },
                {
                    src: "sounds/Asi.mp3",
                    id: "asi",
                },
                {
                    src: "sounds/Moti.mp3",
                    id: "moti",
                },
                {
                    src: "sounds/Shuli.mp3",
                    id: "shuli",
                },
                {
                    src: "sounds/Itzchak.mp3",
                    id: "itzchak",
                },
                {
                    src: "sounds/michal.mp3",
                    id: "michal",
                },
                {
                    src: "sounds/Meshi.mp3",
                    id: "meshi",
                },
            ];
        case 11:
            return [
                {
                    src: "sounds/carmel.mp3",
                    id: "carmel",
                },
                {
                    src: "sounds/carmi.mp3",
                    id: "carmi",
                },
                {
                    src: "sounds/cfir.mp3",
                    id: "cfir",
                },
                {
                    src: "sounds/cineret.mp3",
                    id: "cineret",
                },
                {
                    src: "sounds/cnaan.mp3",
                    id: "cnaan",
                },
                {
                    src: "sounds/micha.mp3",
                    id: "micha",
                },
                {
                    src: "sounds/Malka.mp3",
                    id: "malka",
                },
                {
                    src: "sounds/Alex.mp3",
                    id: "alex",
                },
            ];
        case 12:
            return [
                {
                    src: "sounds/Lahav.mp3",
                    id: "lahav",
                },
                {
                    src: "sounds/Leon.mp3",
                    id: "leon",
                },
                {
                    src: "sounds/lihi.mp3",
                    id: "lihi",
                },
                {
                    src: "sounds/Likush.mp3",
                    id: "likush",
                },
                {
                    src: "sounds/Lin.mp3",
                    id: "lin",
                },
                {
                    src: "sounds/Linoy.mp3",
                    id: "linoy",
                },
                {
                    src: "sounds/Lipaz.mp3",
                    id: "lipaz",
                },
                {
                    src: "sounds/Liran.mp3",
                    id: "liran",
                },
                {
                    src: "sounds/Liza.mp3",
                    id: "liza",
                },
                {
                    src: "sounds/lotem.mp3",
                    id: "lotem",
                },
                {
                    src: "sounds/Elad.mp3",
                    id: "elad",
                },
                {
                    src: "sounds/Dalit.mp3",
                    id: "dalit",
                },
                {
                    src: "sounds/Ayala.mp3",
                    id: "ayala",
                },
                {
                    src: "sounds/Helen.mp3",
                    id: "helen",
                },
                {
                    src: "sounds/dekel.mp3",
                    id: "dekel",
                },
                {
                    src: "sounds/Yigal.mp3",
                    id: "yigal",
                },
                {
                    src: "sounds/Sigal.mp3",
                    id: "sigal",
                },
                {
                    src: "sounds/Dolev.mp3",
                    id: "dolev",
                },
                {
                    src: "sounds/Tal.mp3",
                    id: "tal",
                },
            ];
        case 13:
            return [
                {
                    src: "sounds/Meydan.mp3",
                    id: "meydan",
                },
                {
                    src: "sounds/merav.mp3",
                    id: "merav",
                },
                {
                    src: "sounds/Mali.mp3",
                    id: "mali",
                },
                {
                    src: "sounds/Amir.mp3",
                    id: "amir",
                },
                {
                    src: "sounds/Amit.mp3",
                    id: "amit",
                },
                {
                    src: "sounds/Emie.mp3",
                    id: "emie",
                },
                {
                    src: "sounds/Moran.mp3",
                    id: "moran",
                },
                {
                    src: "sounds/Amitay.mp3",
                    id: "amitay",
                },
                {
                    src: "sounds/Meshi.mp3",
                    id: "meshi",
                },
                {
                    src: "sounds/Chemda.mp3",
                    id: "chemda",
                },
            ];
        case 14:
            return [
                {
                    src: "sounds/Nadav.mp3",
                    id: "nadav",
                },
                {
                    src: "sounds/Nava.mp3",
                    id: "nava",
                },
                {
                    src: "sounds/Negba.mp3",
                    id: "negba",
                },
                {
                    src: "sounds/Neta.mp3",
                    id: "neta",
                },
                {
                    src: "sounds/Nevo.mp3",
                    id: "nevo",
                },
                {
                    src: "sounds/Nili.mp3",
                    id: "nili",
                },
                {
                    src: "sounds/Nira.mp3",
                    id: "nira",
                },
                {
                    src: "sounds/Nitzan.mp3",
                    id: "nitzan",
                },
                {
                    src: "sounds/Noa.mp3",
                    id: "noa",
                },
                {
                    src: "sounds/avner.mp3",
                    id: "avner",
                },
                {
                    src: "sounds/cineret.mp3",
                    id: "cineret",
                },
                {
                    src: "sounds/Mona.mp3",
                    id: "mona",
                },
                {
                    src: "sounds/Roni.mp3",
                    id: "roni",
                },
                {
                    src: "sounds/Einat.mp3",
                    id: "einat",
                },
            ];
        case 15:
            return [
                {
                    src: "sounds/Sami.mp3",
                    id: "sami",
                },
                {
                    src: "sounds/Sigal.mp3",
                    id: "sigal",
                },
                {
                    src: "sounds/Smadar.mp3",
                    id: "smadar",
                },
                {
                    src: "sounds/Sofi.mp3",
                    id: "sofi",
                },
                {
                    src: "sounds/Yosi.mp3",
                    id: "yosi",
                },
                {
                    src: "sounds/Esti.mp3",
                    id: "esti",
                },
                {
                    src: "sounds/Osnat.mp3",
                    id: "osnat",
                },
                {
                    src: "sounds/Alex.mp3",
                    id: "alex",
                },
                {
                    src: "sounds/Pinchas.mp3",
                    id: "pinchas",
                },
                {
                    src: "sounds/Saar.mp3",
                    id: "saar",
                },
                {
                    src: "sounds/Stav.mp3",
                    id: "stav",
                },
                {
                    src: "sounds/Asaf.mp3",
                    id: "asaf",
                },
                {
                    src: "sounds/Hadas.mp3",
                    id: "hadas",
                },
            ];
        case 16:
            return [
                {
                    src: "sounds/Omer.mp3",
                    id: "omer",
                },
                {
                    src: "sounds/Oded.mp3",
                    id: "oded",
                },
                {
                    src: "sounds/Edna.mp3",
                    id: "edna",
                },
                {
                    src: "sounds/Ofra.mp3",
                    id: "ofra",
                },
                {
                    src: "sounds/Alma.mp3",
                    id: "alma",
                },
                {
                    src: "sounds/Ugi.mp3",
                    id: "ugi",
                },
                {
                    src: "sounds/Elad.mp3",
                    id: "elad",
                },
                {
                    src: "sounds/Boaz.mp3",
                    id: "boaz",
                },
                {
                    src: "sounds/Noa.mp3",
                    id: "noa",
                },
                {
                    src: "sounds/Mayan.mp3",
                    id: "mayan",
                },
                {
                    src: "sounds/Neta.mp3",
                    id: "neta",
                },
                {
                    src: "sounds/Ozri.mp3",
                    id: "ozri",
                },
                {
                    src: "sounds/yoad.mp3",
                    id: "yoad",
                },
                {
                    src: "sounds/Eden.mp3",
                    id: "eden",
                },
                {
                    src: "sounds/Mayan.mp3",
                    id: "mayan",
                },
            ];
        case 17:
            return [
                {
                    src: "sounds/Paz.mp3",
                    id: "paz",
                },
                {
                    src: "sounds/Pazit.mp3",
                    id: "pazit",
                },
                {
                    src: "sounds/Peleg.mp3",
                    id: "peleg",
                },
                {
                    src: "sounds/Perach.mp3",
                    id: "perach",
                },
                {
                    src: "sounds/Pika.mp3",
                    id: "pika",
                },
                {
                    src: "sounds/Pinchas.mp3",
                    id: "pinchas",
                },
                {
                    src: "sounds/Tzipi.mp3",
                    id: "tzipi",
                },
                {
                    src: "sounds/Lipaz.mp3",
                    id: "lipaz",
                },
                {
                    src: "sounds/Sapir.mp3",
                    id: "sapir",
                },
                {
                    src: "sounds/Topaz.mp3",
                    id: "topaz",
                },
            ];
        case 18:
            return [
                {
                    src: "sounds/Tzach.mp3",
                    id: "tzach",
                },
                {
                    src: "sounds/Tzeela.mp3",
                    id: "tzeela",
                },
                {
                    src: "sounds/Tzion.mp3",
                    id: "tzion",
                },
                {
                    src: "sounds/Tzipi.mp3",
                    id: "tzipi",
                },
                {
                    src: "sounds/Tzofnat.mp3",
                    id: "tzofnat",
                },
                {
                    src: "sounds/Tzuri.mp3",
                    id: "tzuri",
                },
                {
                    src: "sounds/Tzila.mp3",
                    id: "tzila",
                },
                {
                    src: "sounds/Tzvi.mp3",
                    id: "tzvi",
                },
                {
                    src: "sounds/Tzvia.mp3",
                    id: "tzvia",
                },
                {
                    src: "sounds/Tzfardi.mp3",
                    id: "tzfardi",
                },
                {
                    src: "sounds/Nitzan.mp3",
                    id: "nitzan",
                },
                {
                    src: "sounds/Tirtza.mp3",
                    id: "tirtza",
                },
                {
                    src: "sounds/Itzchak.mp3",
                    id: "itzchak",
                },
            ];
        case 19:
            return [
                {
                    src: "sounds/Katya.mp3",
                    id: "katya",
                },
                {
                    src: "sounds/Keinan.mp3",
                    id: "keinan",
                },
                {
                    src: "sounds/Keren.mp3",
                    id: "keren",
                },
                {
                    src: "sounds/Yakov.mp3",
                    id: "yakov",
                },
                {
                    src: "sounds/Mika.mp3",
                    id: "mika",
                },
                {
                    src: "sounds/Booky.mp3",
                    id: "booky",
                },
                {
                    src: "sounds/Itzchak.mp3",
                    id: "itzchak",
                },
                {
                    src: "sounds/dekel.mp3",
                    id: "dekel",
                },
                {
                    src: "sounds/Barak.mp3",
                    id: "barak",
                },
                {
                    src: "sounds/Rivka.mp3",
                    id: "rivka",
                },
            ];
        case 20:
            return [
                {
                    src: "sounds/Rachel.mp3",
                    id: "rachel",
                },
                {
                    src: "sounds/Rami.mp3",
                    id: "rami",
                },
                {
                    src: "sounds/Razya.mp3",
                    id: "razya",
                },
                {
                    src: "sounds/Reaya.mp3",
                    id: "reaya",
                },
                {
                    src: "sounds/Rivka.mp3",
                    id: "rivka",
                },
                {
                    src: "sounds/Robi.mp3",
                    id: "robi",
                },
                {
                    src: "sounds/Roki.mp3",
                    id: "roki",
                },
                {
                    src: "sounds/Ronen.mp3",
                    id: "ronen",
                },
                {
                    src: "sounds/Roni.mp3",
                    id: "roni",
                },
                {
                    src: "sounds/Rotem.mp3",
                    id: "rotem",
                },
                {
                    src: "sounds/Rubi.mp3",
                    id: "rubi",
                },
                {
                    src: "sounds/Ruty.mp3",
                    id: "ruty",
                },
                {
                    src: "sounds/Erez.mp3",
                    id: "erez",
                },
                {
                    src: "sounds/Yariv.mp3",
                    id: "yariv",
                },
                {
                    src: "sounds/Liran.mp3",
                    id: "liran",
                },
                {
                    src: "sounds/Ofir.mp3",
                    id: "ofir",
                },
                {
                    src: "sounds/Omer.mp3",
                    id: "omer",
                },
                {
                    src: "sounds/Saar.mp3",
                    id: "saar",
                },
                {
                    src: "sounds/Ofra.mp3",
                    id: "ofra",
                },
            ];
        case 21:
            return [
                {
                    src: "sounds/Shachaf.mp3",
                    id: "shachaf",
                },
                {
                    src: "sounds/Shachar.mp3",
                    id: "shachar",
                },
                {
                    src: "sounds/Sharon.mp3",
                    id: "sharon",
                },
                {
                    src: "sounds/Shaul.mp3",
                    id: "shaul",
                },
                {
                    src: "sounds/Shavit.mp3",
                    id: "shavit",
                },
                {
                    src: "sounds/Shira.mp3",
                    id: "shira",
                },
                {
                    src: "sounds/Shiran.mp3",
                    id: "shiran",
                },
                {
                    src: "sounds/Shiri.mp3",
                    id: "shiri",
                },
                {
                    src: "sounds/Shuli.mp3",
                    id: "shuli",
                },
                {
                    src: "sounds/Moshe.mp3",
                    id: "moshe",
                },
                {
                    src: "sounds/Meshi.mp3",
                    id: "meshi",
                },
                {
                    src: "sounds/Segev.mp3",
                    id: "segev",
                },
                {
                    src: "sounds/Menashe.mp3",
                    id: "menashe",
                },
            ];
        case 22:
            return [
                {
                    src: "sounds/Tomer.mp3",
                    id: "tomer",
                },
                {
                    src: "sounds/Tirtza.mp3",
                    id: "tirtza",
                },
                {
                    src: "sounds/Talma.mp3",
                    id: "talma",
                },
                {
                    src: "sounds/Tamar.mp3",
                    id: "tamar",
                },
                {
                    src: "sounds/Tamir.mp3",
                    id: "tamir",
                },
                {
                    src: "sounds/Itay.mp3",
                    id: "itay",
                },
                {
                    src: "sounds/Rotem.mp3",
                    id: "rotem",
                },
                {
                    src: "sounds/Ruty.mp3",
                    id: "ruty",
                },
                {
                    src: "sounds/Amit.mp3",
                    id: "amit",
                },
                {
                    src: "sounds/Galit.mp3",
                    id: "galit",
                },
                {
                    src: "sounds/Matan.mp3",
                    id: "matan",
                },
                {
                    src: "sounds/Tom.mp3",
                    id: "tom",
                },
                {
                    src: "sounds/Efrat.mp3",
                    id: "efrat",
                },
                {
                    src: "sounds/Tamar.mp3",
                    id: "tamar",
                },
            ];

        default:
            break;
    }
};
