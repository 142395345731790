import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { RocketAbcGame } from "./RocketAbcGame";
import { additionalSounds } from "./RocketAbcSounds";

const RocketAbcComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new RocketAbcGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/rocket_abc"
            animationName="rocket_abc"
            scale={0.8}
            shiftX={110}
            shiftY={0}
            onLoaded={game.current.loaded}
            changeFramerate={true}
            additionalSounds={additionalSounds}
        />
    );
};
export default RocketAbcComponent;
