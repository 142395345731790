const additionalSounds = [
    {
        src: "sounds/drop_1wav.mp3",
        id: "drop_1",
    },
    {
        src: "sounds/drop_2wav.mp3",
        id: "drop_2",
    },
    {
        src: "sounds/drop_3wav.mp3",
        id: "drop_3",
    },
    {
        src: "sounds/drop_4wav.mp3",
        id: "drop_4",
    },
    {
        src: "sounds/geese.mp3",
        id: "geese",
    },
];
export default additionalSounds;
