import { MC, playSound, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
export class NumberTenGame {
    private root: MC;
    private drop: number[] = [3, 3, 3, 3, 3, 3, 3, 3, 3, 3];
    private gravity: number = 1.2;
    private droppingBalls: MC[] = [];
    private goingUp: MC[] = [];
    private posX: number[] = [];
    private posY: number[] = [];
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).EightGame = mc;
        (window as any).frame = this.frame;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.root.gotoAndPlay(2);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.initBalls();
        this.start();
    };
    initBalls = () => {
        for (let i = 0; i < 10; i++) {
            const ball = this.root["ball" + (i + 1)];
            ball.inner.gotoAndStop(getRandomNumber(0, 9));
            ball.rotation = getRandomNumber(0, 360);
            this.droppingBalls[i] = false;
            this.goingUp[i] = true;
            this.posX[i] = ball.x;
            this.posY[i] = ball.y;
            ball.addEventListener("mouseover", () => {
                playSound("ball");
                if (this.goingUp[i]) {
                    this.droppingBalls[i] = true;
                }
            });
            ball.addEventListener("click", () => {
                if (!this.goingUp[i]) {
                    //return to init position
                    ball.x = this.posX[i];
                    ball.y = this.posY[i];
                    //restart
                    this.goingUp[i] = true;
                    this.drop[i] = 3;
                    ball.rotation = getRandomNumber(0, 360);
                    playSound("up");
                }
            });
        }
    };

    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;
            for (let i = 0; i < 10; i++) {
                if (this.droppingBalls[i]) {
                    this.doDrop(i);
                }
            }
        });
    };

    doDrop = (i: number) => {
        const ball = this.root["ball" + (i + 1)];
        ball.mouseEnabled = false;
        if (this.goingUp[i]) {
            //6
            ball.y += this.drop[i];
            this.drop[i] *= this.gravity;
            // if(this.drop[i])
            if (ball.y > 450) {
                // ball.gotoAndPlay(9);
                this.goingUp[i] = false;
                ball.y = ball.y - this.drop[i];
                this.drop[i] *= 0.7;
            }
            if (this.drop[i] < 0.5) {
                ball.gotoAndStop(19);
                this.droppingBalls[i] = false;
                ball.mouseEnabled = true;
            }
            //end 6
            else {
                ball.rotation += 1;
            }
        } else {
            if (this.drop[i] < 0.9) {
                this.goingUp[i] = true;
            } else {
                ball.y = ball.y - this.drop[i];
                this.drop[i] *= 0.7;
            }
        }
    };
}
