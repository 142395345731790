import React from "react";
import { MC } from "../../animate/utils";
import { MathGame } from "../MathGame/MathGame";
import { MathGameComponent } from "../MathGame/MathGameComponent";
import { Wizard } from "./Wizard";

export function WizardGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="wizard"
            // additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Wizard(mc), 2)}
            game={game}
            changeFramerate
        />
    );
}
