interface Routes {
    [key: string]: string | Routes;
}

function withPrefix(prefix: string, obj: string): string;
function withPrefix<T extends Routes>(prefix: string, obj: T): T;
function withPrefix(prefix: string, obj: any): any {
    if (typeof obj === "string") {
        return prefix + obj;
    }
    return Object.keys(obj).reduce((ret, key) => {
        ret[key] = withPrefix(prefix, obj[key]);
        return ret;
    }, {} as any);
}

export const routes = {
    home: "/",
    login: "/login/:returnUrl",
    movies: "/movies/",
    creation: "/creation/:viewMode?/:workId?",
    my_works: withPrefix("/myWorks/", {
        list: "",
        remove: "remove/:workId",
        preview: "preview/:workId",
        create: "create/:workId",
    }),

    lunapark: withPrefix("/lunapark", {
        menu: "",
        puppets: "/puppets/",
        mirrors: "/mirrors/",
        music_room: "/music_room/",
        horses: "/horses/",
        balloons: "/balloons/",
        ghost_cave: "/ghost_cave/",
        trampoline: "/trampoline/",
        hammer: "/hammer/",
        duck: "/duck/",
        roller_coaster: "/roller_coaster/",
        sweets: withPrefix("/sweets/", {
            menu: "",
            ice_cream: "ice_cream/",
            apple: "apple/",
            popcorn: "popcorn/",
        }),
        wheel: withPrefix("/wheel/", {
            menu: "",
            one: "one/",
            two: "two/",
            three: "three/",
            four: "four/",
            five: "five/",
            six: "six/",
            seven: "seven/",
            eight: "eight/",
            nine: "nine/",
            ten: "ten/",
        }),
        rocket: withPrefix("/rocket/", {
            menu: "",
            pic: "pic/:num/",
            puzzle: "puzzle/:num/",
            french_horns: "french_horns/",
            fish: "fish/",
            insects: "insects/",
            leaves: "leaves/",
            veggies: "veggies/",
            underwater: "underwater/",
            space: "space/",
            bugs: "bugs/",
        }),
        abc: withPrefix("/abc/", {
            menu: "",
            nose: "nose/",
            egg: "egg/",
            bulb: "bulb/",
            icecream: "icecream/",
            bee: "bee/",
            upsidedown: "upsidedown/",
            star: "star/",
            curtain: "curtain/",
            glass: "glass/",
            rocket: "rocket/",
            heart: "heart/",
            cake: "cake/",
            window: "window/",
            mandoline: "mandoline/",
            yellow: "yellow/",
            penguin: "penguin/",
            tiger: "tiger/",
            kaleidoscope: "kaleidoscope/",
            tree: "tree/",
            robot: "robot/",
            sun: "sun/",
            chicken: "chicken/",
        }),
    }),
    letters: withPrefix("/letters", {
        menu: "",
        review: withPrefix("/review/:sectionIndex/", {
            menu: "",
            penguins: "penguins/",
            wood_boxes: "box/",
            fishes: "fishes/",
            quiz: "quiz/",
            kites: "kites/",
        }),
        fancy_words: withPrefix("/fancy_words/", {
            menu: "",
            word: ":letterIndex",
        }),
        activities: withPrefix("/letter/:letterIndex/", {
            menu: "",
            fancy_word: "fancy_word/",
            creation: "creation/",
            enter_exit: "enter_exit/:action",
            draw: "draw/",
            color: "color/",
            wood_boxes: "wood_boxes/",
            children_in_boxes: "children_in_boxes/",
            crabs: "crabs/",
            find_letter: "find_letter/",
            final_letter: "final_letter/",
            final_draw: "final_draw/",

            video: "video/",
        }),
        video: "/video/",
        sweets: "/sweets/",
    }),
    coloring_pages: withPrefix("/coloring_pages", {
        menu: "",
    }),
    english_time: withPrefix("/english_time", {
        menu: "",
        game: "/:gameType",
    }),
    bible_stories: withPrefix("/bible_stories", {
        menu: "",
        story_menu: withPrefix("/story/:type/", {
            menu: "",
            puzzle: withPrefix("puzzle/", {
                frame: "",
                game: ":index/",
            }),
            bavel_game: "bavel_game/",
            tree_game: "tree_game/",
            noach_game: "noach_game/",
            creation_game: "creation_game/",
            coloring: "coloring/",
        }),
    }),
    dubby_english: withPrefix("/dubby_english", {
        menu: "",
        game: "/:roomType/:gameType",
    }),
    math: withPrefix("/math", {
        menu: "",
        wizard: "/wizard/",
        hippo: "/hippo/",
        cars: "/cars/",
        aqua: "/aqua/",
        ducks: "/ducks/",
        frogs: "/frogs/",
        numBar: "/numBar/",
        monkey: "/monkey/:param",
        phone: "/phone/:param",
        swing: "/swing/",
    }),
    music: withPrefix("/music", {
        menu: "",
        simon: "/simon/",
        memory: "/memory/",
        train: "/train/",
        instruments: "/instruments/",
        chickens: "/chickens/",
    }),
    week_game: withPrefix("/week_game/", {
        simon: "simon_game/",
        hipomaze: "hipomaze_game/",
        dog: "dog_game/",
        seekers: "seekers_game/",
        memory: "memory_game/",
        letter_race: "letter_race/",
        frog: "frog_game/",
        spacerocks: "spacerocks/",
        differences: "differnces/",
    }),
    holiday: withPrefix("/holiday/", {
        pesah: "pesah",
        purim: "purim",
        hanuka: "hanuka",
        sukkot: "sukkot",
    }),
};
