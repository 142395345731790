import React from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { GhostCaveGame } from "./GhostCaveGame";

const GhostCaveComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new GhostCaveGame(frame));

    return (
        <AnimateCC
            base="/lunapark/ghost_cave"
            animationName="ghost_cave"
            onLoaded={game.current.loaded}
            onUnload={game.current.unload}
        />
    );
};
export default GhostCaveComponent;
