import { Frame } from "../Frame";
import { playSound, setupStage, stopAllSounds, playSoundSync, waitForEvent, MC } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { getInstSoundForHelp } from "../../utils/MathGamesUtils";
import { SoundToolTip } from "../../utils/soundTooltip";
import { loadSound } from "../../animate/sound_utils";
import MathGameBase from "./MathGameBase";
const GAMES_TO_LEVEL_UP = 2;
export class MathGame {
    private frame: Frame = null;
    private game: MathGameBase = null;
    private levels: number = 0;
    private currentLevel: number = 0;
    private playedGames: number = 0;
    private upLevelPressed: boolean = false;
    private innerMenu: boolean = false;
    private canPress: boolean = true;

    gameLoaded = (mc: createjs.MovieClip, game: MathGameBase, levels: number, innerMenu?: boolean) => {
        this.game = game;
        this.levels = levels;
        this.innerMenu = innerMenu;

        game.addPrize = this.frame.addPrize;
        game.cleanPrizes = this.cleanPrizes;
        game.enableHelp = this.enableHelp;
        game.disableHelp = this.disableHelp;
        game.initChooser = this.frame.initChooser;

        this.stopPreloader();
        this.game.loaded();

        if (innerMenu) {
            playSound("chooseGame");
        }

        setupStage(this.frame?.root, this.game?.root);
        this.enableHelp();
    };

    help = async () => {
        this.frame.root.btn_help.gotoAndStop("normal");
        this.disableHelp();
        if (this.frame.root.levels_anim_mc.currentFrame === 0) {
            await getInstSoundForHelp(
                this.game.name,
                this.currentLevel,
                this.game.helpNum ? this.game.helpNum : null
            ).then(() => {
                this.game.name === "phone1" && this.game.ask();
                this.enableHelp();
            });
        } else {
            await playSound("chooseLevel").then(() => {
                this.enableHelp();
            });
        }
    };

    enableBtn = (btn_mc: MC, click: () => void) => {
        btn_mc.addEventListener("click", click);
        this.frame.setFrameButton(btn_mc);
    };

    enableHelp = () => {
        const btn_mc = this.frame.root.btn_help;
        this.enableBtn(btn_mc, this.help);
    };

    disableHelp = () => {
        this.frame.root.btn_help.cursor = "default";
        this.frame.root.btn_help.removeAllEventListeners();
    };
    enableLevel = () => {
        this.enableBtn(this.frame.root.btn_levels, this.initChooser);
    };

    disableLevel = () => {
        this.frame.root.btn_levels.cursor = "default";
        this.frame.root.btn_levels.removeAllEventListeners();
    };

    stopPreloader = () => {
        this.frame.stopPreloader(this.levels, this.innerMenu).then(async () => {
            if (this.game.name === "aqua") {
                await loadSound("/math/sounds/aqua/aquaBgsounds.mp3");

                this.game.bgSound = playSoundSync("/math/sounds/aqua/aquaBgsounds.mp3", { loop: -1 });
                this.game.bgSound.volume = 0.4;
            }
            if (this.innerMenu) {
                this.game.openSelector = this.initChooser;
                this.disableLevel();
            } else {
                this.enableLevel();
            }
        });
        this.frame.bgPlay = playSoundSync(`${this.game.name}Opening`, { loop: -1 });
    };

    showPopup = () => {
        this.frame.showLevelUpPopup();
        SoundToolTip.addToolTip(this.frame.root.popup.no_btn, "no");
        SoundToolTip.addToolTip(this.frame.root.popup.yes_btn, "yes");
        const generalAction = () => {
            this.frame.root.popup.visible = false;
            this.game.handleLevel(this.currentLevel);
            // this.resetProps();
        };
        this.frame.root.popup.yes_btn.addEventListener("click", () => {
            if (this.upLevelPressed) {
                return;
            }
            this.upLevelPressed = true;
            this.game.isFirstGame = true;
            this.currentLevel++;
            generalAction();
            this.frame.handleLevel(this.currentLevel);
        });
        this.frame.root.popup.no_btn.addEventListener("click", () => {
            this.game.isFirstGame = false;
            generalAction();
        });
    };

    cleanPrizes = (fromReset?: boolean) => {
        this.frame.prizes.forEach((prize) => this.frame.root.removeChild(prize));
        this.frame.prizes = [];
        if (fromReset) return;
        this.playedGames++;
        if (this.playedGames === GAMES_TO_LEVEL_UP && this.currentLevel < this.levels) {
            this.upLevelPressed = false;
            this.playedGames = 0;
            this.showPopup();
        } else {
            this.game.isFirstGame = false;
            this.game.handleLevel(this.currentLevel);
        }
    };

    resetProps = () => {
        this.playedGames = 0;
        this.cleanPrizes(true);
    };

    initChooser = (isNotRedPhone?: boolean) => {
        if (isNotRedPhone !== undefined && typeof isNotRedPhone === "boolean") {
            loadSound("/math/sounds/math/choose_level.mp3").then(() => {
                playSound("/math/sounds/math/choose_level.mp3");
            });
            this.levels = isNotRedPhone ? 3 : 2;
        }
        this.enableLevel();
        this.game.openMenu = true;
        this.game.disableGame();
        stopAllSounds();
        this.game.chooserUp();
        this.canPress = true;
        this.frame.initChooser(this.levels);
    };

    frameLoaded = (mc: createjs.MovieClip) => {
        this.frame = new Frame(mc);
        setupStage(this.frame?.root, this.game?.root);
        const levelSelector = this.frame?.root.levels_anim_mc;
        const { level_btn1, level_btn2, level_btn3 } = levelSelector.levels_mc;
        this.frame.loaded();
        this.frame.setFrameButton(this.frame.root.btn_exit);

        const chooseLevel = async (level: number) => {
            if (!this.canPress) return;
            this.canPress = false;
            this.playedGames = 0;
            levelSelector.gotoAndPlay("close");
            await waitForEvent(levelSelector, "chooserClosed");
            this.currentLevel = level;
            // this.game.unload();
            this.game.isFirstGame = true;
            this.game.handleLevel(level);
            this.frame.handleLevel(level);
        };

        level_btn1.addEventListener("click", () => {
            chooseLevel(1);
        });

        level_btn2.addEventListener("click", () => {
            chooseLevel(2);
        });

        level_btn3.addEventListener("click", () => {
            chooseLevel(3);
        });

        this.frame.root.btn_exit.addEventListener("click", () => {
            stopAllSounds();
            this.game.onGame = false;
            if (this.innerMenu && this.game.onInnerGame) {
                if (this.frame.root.levels_anim_mc.currentFrame !== 0) {
                    levelSelector.gotoAndPlay("close");
                }
                this.frame.level = 0;
                this.game.backToOuterMenu();
                playSound("chooseGame");
                this.disableLevel();
            } else {
                Navigation.openPage(routes.math.menu);
            }
        });
        levelSelector.levels_mc.close_btn.addEventListener("click", () => {
            levelSelector.gotoAndPlay("close");
            this.game.chooserDown();
        });
    };

    unload = () => {
        console.log("in math game unload");

        if (this.game) {
            this.game.unload();
            this.game = null;
        }
        if (this.frame) {
            this.frame.unload();
            this.game = null;
        }
    };
}
