import React from "react";
import AnimateCC from "../../animate";
import HammerGame from "./HammerGame";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

const HammerComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const hammerGame = React.useRef(new HammerGame(frame));

    return (
        <AnimateCC
            base="/lunapark/hammer"
            animationName="hammer"
            onLoaded={hammerGame.current.loaded}
            changeFramerate={true}
        />
    );
};
export default HammerComponent;
