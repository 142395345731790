import { MC, waitForEvent, playSound } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";

// const x = [0, 200]
// const y = [0, 60]
export class DrawLetter {
    root: MC;
    showSwitcher: () => void;
    constructor(root: createjs.MovieClip, showSwitcher: () => void) {
        this.root = root;
        (window as any).drawLetter = root;
        this.showSwitcher = showSwitcher;
        this.loaded();
    }

    loaded = () => {
        this.root.loadMvc.letterAnim.gotoAndStop(0);
        this.root.visible = false;
    };

    showLetter = async (letter: number) => {
        if (this.root.checkMvc) this.root.checkMvc.visible = false;
        this.root.loadMvc.letterAnim.gotoAndPlay(0);
        this.root.visible = true;
        for (let index = 1; index <= 4; index++) {
            waitForEvent(this.root.loadMvc, `draw${index}`).then(() => {
                playSound(`draw${index}`);
            });
        }
        await waitForEvent(this.root.loadMvc, "drawed");
        if (this.root.loadMvc.airplane) this.root.loadMvc.airplane.visible = false;
        await playSound(`drawedletter${letter + 1}`);
        Navigation.openPage(routes.letters.activities.color.replace(":letterIndex", letter + ""));
        this.showSwitcher();
    };

    unload = () => {};
}
