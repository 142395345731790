import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, stopAllSounds, waitFor } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";

const MENU_ITEMS = ["btnSimon", "btnMemory", "btnTrain", "btnInstruments", "btnHens"];
const MENU_ROUTES: any[] = [
    routes.music.simon,
    routes.music.memory,
    routes.music.train,
    routes.music.instruments,
    routes.music.chickens,
];

export class MusicMenu {
    root: MC;
    private _isLoggedIn: boolean;
    loaded = async (root?: MC) => {
        if (root) {
            this.root = root;
        }
        await waitFor(() => this.root);
        (window as any).MusicMenu = root;
        this.root.btnBack.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
        this.root.btnBack.addEventListener("rollover", async () => {
            this.root.btnBack.over_btn.gotoAndPlay("over");
            await loadSound("/music/music_menu/sounds/back.mp3");
            await playSound("/music/music_menu/sounds/back.mp3");
        });
        this.root.gotoAndPlay(1);
        this.menuItemsEvents();
        this.updateGuestArrow();
    };

    menuItemsEvents = () => {
        MENU_ITEMS.forEach((item: string, index: number) => {
            const btn = this.root[item];
            btn.addEventListener("rollout", () => {
                btn.gotoAndPlay(13);
            });
            btn.addEventListener("rollover", () => {
                btn.cursor = "pointer";
                btn.gotoAndPlay(1);
            });
            btn.addEventListener("click", () => {
                stopAllSounds();
                Navigation.openPage(MENU_ROUTES[index]);
            });
        });
    };

    set isLoggedIn(value: boolean) {
        this._isLoggedIn = value;
        this.updateGuestArrow();
    }

    private async updateGuestArrow() {
        if (!this.root) return;
        if (this._isLoggedIn) {
            this.root.sample.visible = false;
        } else {
            this.root.sample.visible = true;
            this.root.sample.alpha = 1;
            this.root.sample.gotoAndPlay(0);
        }
    }
}
