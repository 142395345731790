import React from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { AppleGame } from "./AppleGame";
import appleSounds from "./sounds_apple.json";

const AppleComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new AppleGame(frame));

    return (
        <AnimateCC
            base="/lunapark/sweets/apple"
            animationName="apple"
            additionalSounds={appleSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default AppleComponent;
