import { MC, waitFor, playSound, waitForEvent, waitForTimer, stopAllSounds } from "../../animate/utils";
import { getRandomArr, getRandomNumber } from "../../utils/MathUtils";
import { loadSound, soundPlayed } from "../../animate/sound_utils";
import { ReviewGameBasic } from "../review/ReviewGameBasic";
import { SoundToolTip } from "../../utils/soundTooltip";
import { getWrongRymeFor, getRymes } from "../review/jsonFileFunction";
import { routes } from "../../navigation/routesPath";
import { Navigation } from "../../navigation/navigation";
const GAME_LENGTH = 5;
export class Sweets extends ReviewGameBasic {
    root: MC;
    level: number;
    rymes: any[] = [];
    icecreamIndex = 0;
    gameStarted: boolean = false;
    soundIsPlaying: boolean = false;
    positions: number[];
    arrToRender: any[];

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        document.addEventListener("visibilitychange", async () => {
            if (document.visibilityState === "visible") {
                await waitForTimer(0);
                this.root.machine.distractor0.gotoAndStop("color0");
                this.root.machine.distractor1.gotoAndStop("color1");
                this.root.machine.arm.gotoAndStop(`ice${this.icecreamIndex + 1}`);
                this.root.machine.machine_display.gotoAndStop(1);
                this.root.machine.gotoAndStop("ready");
            }
        });

        (window as any).sweet = this;
        this.loaded();
    }

    initGame = () => {
        this.root.gotoAndStop("gameScreen");
        this.root.word.alpha = 0;
        this.root.word.mouseEnabled = false;
        this.root.machine.distractor0.gotoAndStop("color0");
        this.root.machine.distractor1.gotoAndStop("color1");
        this.root.machine.arm.gotoAndStop(0);
        this.root.machine.machine_display.gotoAndStop(1);
        this.root.machine.gotoAndPlay("startCone");
        playSound("machine_work");
        return waitForEvent(this.root.machine, "animationEnd");
    };

    loadAndPlaySound = async (path: string) => {
        if (this.notOnSweets()) return;
        const sound = await loadSound(path);
        return soundPlayed(sound.play());
    };

    notOnSweets = () => {
        return Navigation.history.location.pathname !== "/letters/sweets/";
    };

    option = async (index: number) => {
        if (this.notOnSweets()) return;
        const { word } = this.rymes[this.icecreamIndex].rymes[index];
        this.root.machine[`distractor${index}`].gotoAndStop(`color${index}lit`);
        await this.loadAndPlaySound(
            `/letters_island/sweetRhymes/sounds/data/rime${this.level}_${word}_quest${index + 1}.mp3`
        );
        return this.root.machine[`distractor${index}`].gotoAndStop(`color${index}`);
    };

    clearListeners = () => {
        [0, 1].forEach((index) => {
            const distractor = this.root.machine[`distractor${index}`];
            distractor.removeAllEventListeners();
            distractor.cursor = "default";
            distractor.addEventListener("rollout", () => distractor.gotoAndStop(`color${index}`));
        });
        const { screen } = this.root.machine.machine_display;
        screen.removeAllEventListeners();
        screen.cursor = "default";
    };

    resetAndChooseLevel = () => {
        if (this.notOnSweets()) return;
        this.root.AlertBox.visible = false;
        playSound("chooseLevel");
        this.soundIsPlaying = true;
        const btn = this.root.instButtons.level2.InnerButton;
        btn.removeAllEventListeners();
        btn.gotoAndStop(0);
        btn.cursor = "pointer";
        this.enableLevelEvents(btn, 2);
    };

    checkAnswer = async (index: number) => {
        stopAllSounds();
        const { right } = this.rymes[this.icecreamIndex].rymes[index];
        const { word } = this.rymes[this.icecreamIndex];
        if (right) {
            if (this.notOnSweets()) return;
            this.disableButtons(["reset", "help"]);
            this.soundIsPlaying = false;
            this.clearListeners();
            await this.loadAndPlaySound(`/letters_island/common/feedBacks/Good${getRandomNumber(1, 10)}.mp3`);
            await this.loadAndPlaySound(`/letters_island/sweetRhymes/sounds/data/rime${this.level}_${word}_full.mp3`);
            this.root.machine.gotoAndPlay("machineShake");
            playSound("machine_shake");
            await waitForEvent(this.root.machine, "animationEnd");
            this.root.machine.gotoAndStop("ready");
            this.root.machine.machine_display.gotoAndStop(1);
            this.icecreamIndex++;
            this.root.machine.arm.gotoAndPlay(`ice${this.icecreamIndex}`);
            await waitForTimer(1000);
            this.root.machine.distractor0.gotoAndStop("color0");
            this.root.machine.distractor1.gotoAndStop("color1");
            if (this.icecreamIndex < GAME_LENGTH) {
                this.nextRyme();
            } else {
                playSound(`game_end_level_${this.level}`);
                this.root.gotoAndPlay("openSuccessScreen");
                await waitForEvent(this.root, "animationEnd");
                this.root.AlertBox.visible = true;
                this.root.AlertBox.giraff_yes.mouseEnabled = false;
                this.root.AlertBox.giraff_no.mouseEnabled = false;
                SoundToolTip.addToolTip(this.root.AlertBox.yes, "yes");
                SoundToolTip.addToolTip(this.root.AlertBox.no, "no");
                if (this.level === 1) {
                    this.root.AlertBox.message.text = "?רוצים לעלות רמה";
                    this.root.AlertBox.no.addEventListener("click", () => {
                        this.startGame(1);
                    });
                    this.root.AlertBox.yes.addEventListener("click", () => {
                        this.startGame(2);
                    });
                } else {
                    this.root.AlertBox.message.text = "?רוצים לשחק שוב";
                    this.root.AlertBox.no.addEventListener("click", this.goBack);
                    this.root.AlertBox.yes.addEventListener("click", this.resetAndChooseLevel);
                }
            }
        } else {
            this.loadAndPlaySound(`/letters_island/common/feedBacks/Bad${getRandomNumber(0, 2)}.mp3`);
        }
    };

    distractorEvents = (index: number) => {
        const distractor = this.root.machine[`distractor${index}`];
        distractor.cursor = "pointer";
        const { word } = this.rymes[this.icecreamIndex].rymes[index];
        SoundToolTip.addToolTip(
            distractor,
            `/letters_island/sweetRhymes/sounds/data/rime${this.level}_${word}.mp3`,
            true,
            false
        );
        distractor.addEventListener("rollover", async () => {
            this.root.machine[`distractor${index}`].gotoAndStop(`color${index}lit`);
        });
        distractor.addEventListener("click", () => this.checkAnswer(index));
        distractor.addEventListener("rollout", () => distractor.gotoAndStop(`color${index}`));
    };

    screenEvents = () => {
        const { screen } = this.root.machine.machine_display;
        screen.cursor = "pointer";
        const { word } = this.rymes[this.icecreamIndex];
        SoundToolTip.addToolTip(screen, `/letters_island/common/words/he/default/${word}.mp3`, true, false);
        screen.addEventListener("rollover", async () => {
            this.root.machine.machine_display.gotoAndStop(2);
        });
        screen.addEventListener("rollout", () => this.root.machine.machine_display.gotoAndStop(1));
    };

    nextRyme = async () => {
        if (this.notOnSweets()) return;
        this.soundIsPlaying = false;
        const { word } = this.rymes[this.icecreamIndex];
        this.root.word.alpha = 0;
        this.disableButtons(["reset"]);
        this.root.word.gotoAndStop(word);
        await this.animateObject(this.root.word);
        await playSound("question");
        this.root.machine.machine_display.gotoAndStop(2);
        await this.loadAndPlaySound(`/letters_island/common/words/he/question/${word}.mp3`);
        this.root.machine.machine_display.gotoAndStop(1);
        await this.option(0);
        await playSound("or");
        await this.option(1);
        this.enableButtons(["reset", "help", "exit"]);
        this.soundIsPlaying = true;
        [0, 1].forEach((index) => {
            this.distractorEvents(index);
        });
        this.screenEvents();
    };

    animateObject = (object: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(object)
                .to(
                    {
                        alpha: 1,
                    },
                    800
                )
                .call(resolve);
        });
    };

    resetRhyme = () => {
        this.clearListeners();
        let reset = 0;
        do {
            reset = getRandomNumber(0, this.arrToRender.length - 1);
        } while (this.positions.includes(reset));
        const ryme = this.arrToRender[reset];
        const wrongRyme = getWrongRymeFor(ryme, this.level + "");
        const rightPos = getRandomNumber(0, 1);
        const rymes = [
            { word: ryme, right: true },
            { word: wrongRyme, right: false },
        ];
        if (rightPos === 1) rymes.reverse();
        this.rymes[this.icecreamIndex] = { word: ryme, rymes };
        this.nextRyme();
    };

    startGame = async (level: number) => {
        this.initButtons(this.root, this.resetRhyme, "general", -1, null, this.goBack);
        const enabled = level === 1 ? 2 : 1;
        this.enableLevelEvents(this.root.instButtons[`level${enabled}`].InnerButton, enabled);
        this.disableLevelEvents(this.root.instButtons[`level${level}`].InnerButton);
        this.disableButtons(["help"]);
        this.enableButtons(["reset", "exit"]);
        this.stopInst();
        this.icecreamIndex = 0;
        this.clearListeners();
        this.level = level;
        this.rymes = [];
        this.arrToRender = getRymes(level + "");
        this.positions = getRandomArr(0, this.arrToRender.length - 1, 5);
        this.positions.forEach((pos) => {
            const ryme = this.arrToRender[pos];
            const wrongRyme = getWrongRymeFor(ryme, this.level + "");
            const rightPos = getRandomNumber(0, 1);
            const rymes = [
                { word: ryme, right: true },
                { word: wrongRyme, right: false },
            ];
            if (rightPos === 1) rymes.reverse();
            this.rymes.push({ word: ryme, rymes });
        });
        this.soundIsPlaying = false;
        await this.initGame();
        this.nextRyme();
    };

    playInst = (sound: string) => {
        return new Promise((resolve) => {
            if (!this.gameStarted) {
                resolve(playSound(sound));
            }
        });
    };

    stopInst = () => {
        stopAllSounds();
        this.gameStarted = true;
    };

    enableLevelEvents = (btn: MC, index: number) => {
        btn.cursor = "pointer";
        btn.gotoAndStop("up");
        btn.addEventListener("rollover", async () => {
            btn.gotoAndStop("over");
            this.soundIsPlaying && this.loadAndPlaySound(`/letters_island/sweetRhymes/sounds/level${index}.mp3`);
        });
        btn.addEventListener("rollout", () => {
            btn.gotoAndStop("up");
        });
        btn.addEventListener("click", () => {
            if (!this.soundIsPlaying && this.gameStarted) return;
            btn.gotoAndStop("up");
            btn.gotoAndStop(0);
            this.startGame(index);
        });
    };

    disableLevelEvents = (btn: MC) => {
        btn.gotoAndStop("clicked");
        btn.removeAllEventListeners();
        btn.cursor = "default";
    };

    goBack = () => {
        Navigation.openPage(routes.letters.menu);
    };

    initLevelButtonsOnLoad = () => {
        [1, 2].forEach((index: number) => {
            const btn = this.root.instButtons[`level${index}`].InnerButton;
            btn.removeAllEventListeners();
            btn.gotoAndStop(0);
            btn.cursor = "pointer";
            this.enableLevelEvents(btn, index);
        });
    };

    loaded = async () => {
        await waitFor(() => this.root);
        this.root.gotoAndStop(0);
        this.initButtons(this.root, null, "chooseLevel", -1, null, this.goBack, this.stopInst);
        this.disableButtons(["reset"]);
        this.enableButtons(["help", "exit"]);
        this.initLevelButtonsOnLoad();
        this.root.gotoAndPlay(1);
        await waitForTimer(2000);
        await this.playInst("gameName");
        await this.playInst("general");
        await this.playInst("chooseLevelInst");
        this.soundIsPlaying = true;
    };

    unloaded = async () => {
        return;
    };
}
