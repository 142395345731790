import { MC, playSound, waitForEvent, asyncTween, waitForTimer, stopAllSounds } from "../../animate/utils";
import MathGameBase from "../MathGame/MathGameBase";
import { getRandomArr, getRandomNumber } from "../../utils/MathUtils";
import { loadSound } from "../../animate/sound_utils";

export class Aqua extends MathGameBase {
    level: number;
    data: number[];
    currentIndex: number = 0;
    minVal: number;
    maxVal: number;
    name = "aqua";
    bgSound: any;
    canPress = true;
    isChooserUp = false;
    setupDataCalled: boolean;

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).aqua = root;
    }

    chooserUp = () => {
        stopAllSounds();
        this.isChooserUp = true;
        this.root.fish_holder_mc.removeAllChildren();
    };

    chooserDown = () => {
        this.isChooserUp = false;
        this.playLevel(true);
        playSound(`/math/sounds/aqua/fish_inst_level1.mp3`).then(() => {
            this.enableHelp();
        });
    };

    loaded = () => {
        this.root.gotoAndStop(0);
    };

    getWrongNumber = (num: number) => {
        const retVal: number[] = [-1, -1];
        for (let index = 0; index < 2; index++) {
            let val;
            do {
                val = getRandomNumber(this.minVal, this.maxVal);
            } while (val === num || val === retVal[0]);
            retVal[index] = val;
        }
        return retVal;
    };

    initFish = (fish: MC) => {
        fish.cursor = "pointer";
        const hit = new createjs.Shape();
        hit.graphics
            .beginFill("#000")
            .drawRect(
                fish.nominalBounds.x + 25,
                fish.nominalBounds.y + 25,
                fish.nominalBounds.width - 50,
                fish.nominalBounds.height - 50
            );
        fish.hitArea = hit;
        fish.addEventListener("click", async () => {
            if ([0, 47, 46].includes(fish.bubble_mc.currentFrame)) {
                fish.bubble_mc.visible = true;
                fish.bubble_mc.gotoAndPlay("start");
            } else {
                fish.bubble_mc.gotoAndPlay("end");
                await waitForEvent(fish.bubble_mc, "animationEnd");
                fish.bubble_mc.visible = false;
            }
        });
    };

    playLevel = async (fromSetup?: boolean) => {
        if (this.isChooserUp) return;
        this.toggleOptions(true);
        this.root.fish_holder_mc.removeAllChildren();
        const rightAnswer = this.data[this.currentIndex];
        const rightPosition = getRandomNumber(0, 2);
        const wrongAns = this.getWrongNumber(rightAnswer);
        this.canPress = true;
        ["A", "B", "C"].forEach(async (item, index) => {
            let val: number;
            if (index === rightPosition) {
                val = rightAnswer;
            } else {
                val = wrongAns[0];
                wrongAns.shift();
            }

            const btn = this.root[`option${item}_mc`];
            btn.removeAllEventListeners();
            btn.option_txt.alpha = 0;
            btn.option_txt.text = val;
            await waitForTimer(50);
            if (this.isChooserUp) return;
            createjs.Tween.get(btn.option_txt)
                .to({ alpha: 1 }, 1000, createjs.Ease.cubicIn)
                .call(() => {});
            btn.cursor = "pointer";
            btn.addEventListener("rollover", () => {
                btn.gotoAndPlay("over");
            });
            btn.addEventListener("rollout", () => {
                btn.gotoAndStop(0);
            });
            btn.addEventListener("click", () => {
                if (!this.canPress) return;
                this.canPress = false;
                btn.gotoAndPlay("out");
                this.bgSound.volume = 0;
                this.checkAnswer(val);
            });
            if (fromSetup) {
                this.setupDataCalled = false;
            }
        });
        this.root.locators_mc.gotoAndStop(rightAnswer - 1);

        let fishesType = getRandomNumber(0, 4);
        await waitForTimer(200);
        if (this.isChooserUp) return;
        this.root.setChildIndex(this.root.fish_holder_mc, 3);
        for (let index = 1; index <= rightAnswer; index++) {
            const fish = new this.root.lib.Fish();
            const scale = (getRandomNumber(1, rightAnswer <= 4 ? 60 : 40) + 61) / 100;
            fish.scale = scale;
            const fromRight = getRandomNumber(0, 1) === 1;
            if (fromRight) {
                fish.scaleX = -1;
                fish.x = 720;
            } else {
                fish.x = -60;
            }
            fish.fromRight = fromRight;
            const locator = this.root.locators_mc[`locator${index}`];
            fish.y = locator.y;
            createjs.Tween.get(fish)
                .to({ x: locator.x }, 4500, createjs.Ease.cubicIn)
                .call(() => {});

            fish.bubble_mc.gotoAndStop(0);
            this.initFish(fish);
            if (this.level === 2) {
                fishesType = getRandomNumber(0, 4);
            }
            this.root.fish_holder_mc.addChild(fish);
            fish.gotoAndStop(fishesType);
        }
    };

    toggleOptions = (show: boolean) => {
        ["A", "B", "C"].forEach(async (item) => {
            this.root[`option${item}_mc`].visible = show;
        });
    };
    finishGame = () => {
        this.toggleOptions(false);
        const end = new this.root.lib.end();
        (window as any).end = end;
        this.root.addChild(end);
        end.x = 330;
        end.y = 210;
        end.gotoAndPlay(0);
        waitForEvent(end, "playSound").then(() => {
            playSound("endsound");
        });
        return waitForEvent(end, "endingAnimationDone").then(() => {
            this.root.removeChild(end);
        });
    };

    handleGameEnd = async () => {
        await this.finishGame();
        this.root.gotoAndStop(0);
        this.cleanPrizes();
        this.bgSound.volume = 0.4;
    };

    handleSuccess = () => {
        if (this.isChooserUp) return;
        this.currentIndex++;
        const remark = `good${getRandomNumber(0, 10)}`;
        playSound(remark);
        this.addPrize("prize_fish");
        const promises: any[] = [];
        this.root.fish_holder_mc.children.forEach((fish: MC) => {
            promises.push(asyncTween(fish, { x: fish.fromRight ? -60 : 720 }, 4500));
        });
        Promise.all(promises).then(() => {
            if (this.isChooserUp || this.setupDataCalled) return;
            this.root.fish_holder_mc.removeAllChildren();
            if (this.currentIndex < 5) {
                this.playLevel();
            } else {
                this.handleGameEnd();
            }
        });
    };

    checkAnswer = (value: number) => {
        if (value === this.data[this.currentIndex]) {
            this.handleSuccess();
        } else {
            this.canPress = true;
            playSound(`wrong${getRandomNumber(1, 2)}`);
        }
    };

    setupData = async () => {
        this.minVal = this.level === 1 ? 1 : 5;
        this.maxVal = this.level === 1 ? 6 : 10;
        this.data = getRandomArr(this.minVal, this.maxVal, 5);
        this.currentIndex = 0;
        loadSound(`/math/sounds/aqua/fish_inst_level1.mp3`).then(() => {
            playSound(`/math/sounds/aqua/fish_inst_level1.mp3`).then(() => {
                this.enableHelp();
            });
        });
        this.setupDataCalled = true;
        this.playLevel(true);
    };

    handleLevel = (level?: number) => {
        this.isChooserUp = false;
        this.level = level;
        this.cleanPrizes(true);
        this.setupData();
    };
}
