import { loadSound } from "../../../animate/sound_utils";
import { MC, playSound, stopAllSounds, waitFor, waitForEvent, waitForTimer } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { SpecialGameBasic } from "../../SpecialGameBasic";

export class SimonGame extends SpecialGameBasic {
    shouldPlayInst: boolean = true;
    chosenLevel: number;
    levelRoundsArr: number[] = [4, 5, 6];
    roundsNum: number = 0;
    currentSequence: number[] = [];
    enableEvents: boolean = false;
    playedInst: boolean = false;
    seqIndex: number = 0;
    correctIndex: number = 0;
    endSeq: number[] = [];
    helpSound = "/week_games/simon_game/sounds/HelpInst.mp3";
    bGSound = "/week_games/dog/sounds/music.mp3";
    instructionSound = "/week_games/simon_game/sounds/HelpInst.mp3";

    loaded = async (root: MC) => {
        this.root = root;
        (window as any).simon2 = this.root;
        // this.root.mcProgress.visible = false;
        this.root.gotoAndStop("game");
        this.root.base.mcSpeaker.gotoAndStop(0);
        this.root.btn_levels.flag.gotoAndStop(`level_1`);
        this.disableLevelChooser();
        this.root.levelSelector.gotoAndStop(0);
        this.initLevelSelector();
        this.enableHelp();
        this.initHelp("/week_games/simon_game/sounds/HelpLevel.mp3", true);
        this.initExit();
        this.initIconMc();
        await this.loadBtnSounds();

        await waitForTimer(1000);

        this.openSelector();
        this.initGameBtns();
    };

    initLevelSelector = () => {
        const pannel = this.root.levelSelector.pannel;
        this.btnOverAndOut(this.root.btn_levels, "/week_games/simon_game/sounds/Levels.mp3", "levels");
        this.initLevelBtns(pannel, true, this.chooseLevel);
        this.root.btn_levels.addEventListener("click", () => {
            if (!this.enableLevelBtn) return;
            stopAllSounds();
            this.root.stop();
            this.resetBtns();
            this.root.btn_levels.gotoAndStop("down");
            this.openSelector();
        });
    };

    resetBtns = () => {
        for (let index = 0; index < 4; index++) {
            const btn = this.root[`btn${index}`];
            btn.gotoAndStop("up");
        }
    };

    openSelector = async () => {
        this.levelSelected = false;
        this.root.base.mcSpeaker.gotoAndStop(0);
        this.root.levelSelector.gotoAndPlay("in");
        this.disableLevelChooser();
        await playSound("/week_games/simon_game/sounds/close.mp3");
        this.root.base.mcSpeaker.gotoAndPlay(0);
        playSound("/week_games/simon_game/sounds/ChooseLevel.mp3").then(() => {
            this.root.base.mcSpeaker.gotoAndStop(0);
        });
    };

    closeSelector = async () => {
        this.root.levelSelector.gotoAndPlay("out");
        await waitForEvent(this.root, "selectorOut");

        if (this.shouldPlayInst) {
            this.shouldPlayInst = false;
            this.disableHelp();
            this.root.base.mcSpeaker.gotoAndPlay(0);
            this.playInstSound().then(() => {
                this.playedInst = true;
                this.root.base.mcSpeaker.gotoAndStop(0);
                this.enableLevelChooser();
                this.enableHelp();
            });
        } else {
            this.enableLevelChooser();
            this.enableHelp();
        }
    };

    initGameBtns = async () => {
        for (let index = 0; index < 4; index++) {
            const btn = this.root[`btn${index}`];
            const icon = this.root[`icon_${index}`];
            [btn, icon].forEach((mc: MC) => {
                mc.addEventListener("rollover", () => {
                    if (!this.enableEvents) return;
                    mc.cursor = "pointer";
                    btn.gotoAndStop("over");
                });
                mc.addEventListener("rollout", () => {
                    if (!this.enableEvents) return;
                    mc.cursor = "default";
                    btn.gotoAndStop("up");
                });
                mc.addEventListener("click", async () => {
                    if (!this.enableEvents) return;
                    this.isCorrect(btn, index);
                    this.indexClicked = index;
                });
            });
        }
    };

    loadBtnSounds = async () => {
        for (let index = 0; index < 4; index++) {
            await loadSound(`/week_games/simon_game/sounds/${index + 1}_1.mp3`);
        }
        await loadSound("/week_games/simon_game/sounds/close.mp3");
        await loadSound("/week_games/simon_game/sounds/ChooseLevel.mp3");
        await loadSound("/week_games/simon_game/sounds/Wrong1.mp3");
        await loadSound("/week_games/simon_game/sounds/Final.mp3");
    };

    resetGameProps = () => {
        this.currentSequence = [];
        this.correctIndex = 0;
        this.enableEvents = false;
        this.endSeq = [];
    };

    chooseLevel = async (level: number) => {
        await this.closeSelector();
        this.resetGameProps();
        this.chosenLevel = level;
        this.roundsNum = this.levelRoundsArr[level - 1];
        console.log("rounds num", this.roundsNum);
        this.startGame();
    };

    startGame = async () => {
        this.resetGameProps();
        this.generateSequence();
        await waitFor(() => this.playedInst);
        this.enableHelp();
        this.enableLevelChooser();
        this.playSequence();
    };

    generateSequence = () => {
        let num: number;
        this.seqIndex = 1;
        for (let i = 0; i < this.roundsNum; i++) {
            do {
                num = getRandomNumber(0, 3);
            } while (num === this.currentSequence[i - 1] || this.includesNum(num) > 2);
            this.currentSequence.push(num);
        }
        console.log("sequence", this.currentSequence);
    };

    includesNum = (num: number): number => {
        let times = 0;
        for (let index = 0; index < this.currentSequence.length; index++) {
            if (this.currentSequence[index] === num) times++;
        }
        return times;
    };

    playSequence = async () => {
        this.enableEvents = false;
        this.disableHelp();
        for (let i = 0; i < this.seqIndex; i++) {
            const btn_num = this.currentSequence[i];
            this.root[`btn${btn_num}`].gotoAndStop("selected_up");
            this.root.base.mcSpeaker.gotoAndPlay(0);
            await playSound(`/week_games/simon_game/sounds/${btn_num + 1}_1.mp3`);
            this.root.base.mcSpeaker.gotoAndStop(0);

            this.root[`btn${btn_num}`].gotoAndStop("up");
            console.log("playing", btn_num);
        }
        this.enableEvents = true;
        this.enableHelp();
    };

    isCorrect = async (btn: MC, index: number) => {
        if (index === this.currentSequence[this.correctIndex]) {
            btn.gotoAndStop("selected_up");
            this.correctIndex++;
            if (this.correctIndex === this.seqIndex) {
                this.enableEvents = false;
                this.root.base.mcSpeaker.gotoAndPlay(0);
                await playSound(`/week_games/simon_game/sounds/${index + 1}_1.mp3`);
                this.indexClicked === index && this.root.base.mcSpeaker.gotoAndStop(0);
                btn.gotoAndStop("up");
                await this.nextStep();
            } else {
                this.root.base.mcSpeaker.gotoAndPlay(0);
                await playSound(`/week_games/simon_game/sounds/${index + 1}_1.mp3`);
                this.indexClicked === index && this.root.base.mcSpeaker.gotoAndStop(0);
                btn.gotoAndStop("up");
            }
        } else {
            console.log("wrong");
            btn.gotoAndStop("over");
            this.root.base.mcSpeaker.gotoAndPlay(0);
            await playSound("/week_games/simon_game/sounds/Wrong1.mp3");
            this.indexClicked === index && this.root.base.mcSpeaker.gotoAndStop(0);

            btn.gotoAndStop("up");
            this.correctIndex = 0;
            await waitForTimer(500);
            this.playSequence();
        }
    };

    nextStep = async () => {
        console.log("in next step");
        if (this.correctIndex === this.currentSequence.length) {
            console.log("you won!!");
            this.enableEvents = false;
            await waitForTimer(500);
            this.playEnd();
        } else {
            console.log("in play next");
            this.seqIndex++;
            this.correctIndex = 0;
            await waitForTimer(500);
            this.playSequence();
        }
    };

    playEnd = async () => {
        this.generateEndSeq();

        this.disableHelp();
        this.disableLevelChooser();
        await loadSound("/week_games/simon_game/sounds/Sof_short.mp3");
        this.root.base.mcSpeaker.gotoAndPlay(0);
        playSound("/week_games/simon_game/sounds/Sof_short.mp3").then(async () => {
            await playSound("/week_games/simon_game/sounds/Final.mp3");
            this.root.base.mcSpeaker.gotoAndStop(0);
            this.startGame();
        });
        for (let index = 0; index < this.endSeq.length; index++) {
            const btn = this.root[`btn${this.endSeq[index]}`];
            // if (stopSeq) break;
            btn.gotoAndStop("selected_up");
            await waitForTimer(100);
            btn.gotoAndStop("up");
        }
    };

    generateEndSeq = () => {
        let num;

        for (let index = 0; index < 55; index++) {
            do {
                num = getRandomNumber(0, 3);
            } while (this.endSeq[index - 1] === num);
            this.endSeq.push(num);
        }
    };
    indexClicked: number;
}
