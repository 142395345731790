import { localToGlobal, MC, playSound } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

const CAR_AREA_WIDTH = 100;
const CAR_AREA_HEIGHT = 50;

const GHOST_AREA_WIDTH = 44.15;
const GHOST_AREA_HEIGHT = 45.75;
const MAX_ARCH = 44;

export class GhostCaveGame {
    private root: MC;
    // private g = new createjs.Shape()

    private ghostsList: string[];

    private ghostOnScreen: boolean[];
    private ghostSizes: number[];
    private ghostDirections: number[];
    private ghostCrash: boolean[];

    private archs: MC[];
    private archSize: number[] = [0.2, 0.04];
    private maxArchSize = 5;
    private archCount: number = 0;
    private stopNewGhosts: boolean = false;
    private returnToMenu: boolean = false;
    private dArch: number = 1.2;
    private crashGhostCounter: number = 12;
    private oldX: number = 800;

    constructor(private frame: LunaparkMenu) {}

    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        (window as any).GhostCaveGame = mc;
        this.frame.hideBackground();
        this.root.gotoAndStop(10);

        // this.root.addChild(this.g)

        this.ghostsList = ["g1", "g2", "g3", "g4"];
        this.ghostSizes = [0.01, 0.01, 0.01, 0.01];
        this.ghostOnScreen = [false, false, false, false];
        this.ghostDirections = [1, 1, 1, 1];
        this.ghostCrash = [false, false, false, false];

        this.ghostsList.forEach((ghost) => {
            this.root[ghost].visible = false;
        });

        this.archs = [this.root.arch, this.root.arch2];

        this.archs.forEach((a, i) => {
            a.visible = true;
            a.sky.visible = false;
            a.scale = this.archSize[i];
            a.x = 400;
            a.y = 337;
        });

        this.root.stage.mouseX = this.frame.mouseX;
        this.root.addEventListener("tick", async (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            if (!(this.crashGhostCounter > 0)) {
                this.root.gotoAndStop(26);
                this.frame.toggleAgainBtn(true);
            } else {
                this.frame.toggleAgainBtn(false);
                //car movement
                const pos = this.root.globalToLocal(this.root.stage.mouseX, 0);
                createjs.Tween.get(this.root.car)
                    .to({ x: pos.x }, 600)
                    .call(() => {})
                    .addEventListener("change", () => this.handleCarMove(this.root.stage.mouseX));

                if (!this.stopNewGhosts) {
                    //before 20 frames
                    for (let i = 0; i < 4; i++) {
                        //start display ghosts
                        const ghost = this.ghostsList[i];
                        if (!this.ghostOnScreen[i]) {
                            this.startGhosts(ghost, i);
                        } else {
                            this.singleGhostStart(ghost, i);
                        }
                    }
                }

                this.archs.forEach(this.moveArch);
            }
        });
    };

    unload = () => {
        createjs.Tween.removeTweens(this.root.car);
        this.root.removeAllEventListeners();
    };

    handleCarMove(mouseX: number) {
        if (mouseX < this.oldX) {
            createjs.Tween.get(this.root.car, { loop: 1, override: true }).to({ rotation: -10 }, 200);
            createjs.Tween.get(this.root.car.rocketItem.girl, {
                loop: 1,
                override: true,
            }).to({ rotation: -20 }, 200);
            createjs.Tween.get(this.root.car.rocketItem.giraffe, {
                loop: 1,
                override: true,
            }).to({ rotation: 20 }, 200);
        } else if (mouseX > this.oldX) {
            createjs.Tween.get(this.root.car, { loop: 1, override: true }).to({ rotation: 10 }, 200);
            createjs.Tween.get(this.root.car.rocketItem.girl, {
                loop: 1,
                override: true,
            }).to({ rotation: 20 }, 200);
            createjs.Tween.get(this.root.car.rocketItem.giraffe, {
                loop: 1,
                override: true,
            }).to({ rotation: -20 }, 200);
        } else if (mouseX === this.oldX) {
            createjs.Tween.get(this.root.car, { loop: 1, override: true }).to({ rotation: 0 }, 200);
            createjs.Tween.get(this.root.car.rocketItem.girl, {
                loop: 1,
                override: true,
            }).to({ rotation: 0 }, 200);
            createjs.Tween.get(this.root.car.rocketItem.giraffe, {
                loop: 1,
                override: true,
            }).to({ rotation: 0 }, 200);
        }
        this.oldX = mouseX;
    }

    isIntersecting = (ghost: MC) => {
        const localCarArea = localToGlobal(this.root.car.area, 0, 0);
        const carAreaRect = new createjs.Rectangle(
            localCarArea.x - CAR_AREA_WIDTH / 2,
            localCarArea.y - CAR_AREA_HEIGHT / 2,
            CAR_AREA_WIDTH,
            CAR_AREA_HEIGHT
        );

        // this.g.graphics.clear();
        // this.g.graphics.f("blue").drawRect(carAreaRect.x, carAreaRect.y, carAreaRect.width, carAreaRect.height)

        const localGhostArea = localToGlobal(ghost.area, 0, 0);
        const cureGostWidth = GHOST_AREA_WIDTH * ghost.scale;
        const cureGhostHeight = GHOST_AREA_HEIGHT * ghost.scale;

        const ghostAreaRect = new createjs.Rectangle(
            localGhostArea.x - cureGostWidth / 2,
            localGhostArea.y - cureGhostHeight / 2,
            cureGostWidth,
            cureGhostHeight
        );

        // this.g.graphics.f("red").drawRect(ghostAreaRect.x, ghostAreaRect.y, ghostAreaRect.width, ghostAreaRect.height)
        // this.root.addChild(this.g);

        return carAreaRect.intersects(ghostAreaRect);
    };

    moveArch = (arch: MC) => {
        arch.scale *= this.dArch;
        if (arch.scale > this.maxArchSize) {
            if (this.returnToMenu) {
                this.frame.exitGame();
            }
            arch.scale = 0.01;
            this.archCount++;
        }

        if (this.archCount === MAX_ARCH - 1) {
            arch.visible = false;
            this.maxArchSize = 2;
        }
        if (this.archCount === MAX_ARCH) {
            this.dArch = 1.2;
            this.stopNewGhosts = true;
            this.root.arch.visible = false;
            arch.visible = true;
            arch.sky.visible = true;
            this.ghostsList.forEach((ghost) => {
                this.root[ghost].visible = false;
            });
        }
        if (this.archCount === MAX_ARCH + 1) {
            this.returnToMenu = true;
            this.dArch = 1.03;
        }
    };

    startGhosts = (ghost: string, ghostIndex: number) => {
        if (getRandomNumber(0, 99) === 1) {
            this.ghostOnScreen[ghostIndex] = true;
            this.root[ghost].visible = true;
            this.getGhostDirection(ghostIndex);
            this.ghostSizes[ghostIndex] = 0.01;
            this.root[ghost].gotoAndStop(9);
        }
    };

    singleGhostStart = (ghost: string, ghostIndex: number) => {
        if (this.root[ghost].scale < 4.8) {
            this.ghostMovement(this.root[ghost], ghostIndex);
        } else {
            this.resetGhost(ghostIndex, ghost);
        }
    };

    ghostMovement = (ghost: MC, ghostIndex: number) => {
        ghost.scale += this.ghostSizes[ghostIndex];
        ghost.x += this.ghostDirections[ghostIndex];
        this.ghostDirections[ghostIndex] *= 1.1;
        this.ghostSizes[ghostIndex] *= 1.1;
        if (!this.ghostCrash[ghostIndex] && this.isIntersecting(ghost)) {
            this.ghostCrash[ghostIndex] = true;
            const ghostAlpha = `ghost${this.crashGhostCounter}`;
            // this.root[ghostAlpha].gotoAndStop(10);
            this.crashGhostCounter--;
            this.root[ghostAlpha].alpha = 1;
            playSound("ghost_3wav");
        }
    };

    getGhostDirection = (ghostIndex: number) => {
        const expick = getRandomNumber(0, 2);
        if (expick === 0) {
            this.ghostDirections[ghostIndex] = 1;
        }
        if (expick === 1) {
            this.ghostDirections[ghostIndex] = -1;
        }
        if (expick === 2) {
            this.ghostDirections[ghostIndex] = 0;
        }
    };

    resetGhost = (ghostIndex: number, ghost: string) => {
        this.ghostOnScreen[ghostIndex] = false;
        this.root[ghost].scale = 0.1;
        this.root[ghost].visible = false;
        this.root[ghost].x = 400;
        this.root[ghost].y = 337;
        this.ghostCrash[ghostIndex] = false;
    };
}
