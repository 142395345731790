import { loadFont, localToGlobal, MC, playSound, stopAllSounds, waitFor, waitForTimer } from "../animate/utils";
import { Navigation } from "../navigation/navigation";
import { routes } from "../navigation/routesPath";
import { generatePath } from "react-router-dom";
import * as TextFunctions from "./utils/TextFunctions";
import {
    ACTION_BTNS,
    BOTTOM_BOARD_LIMIT,
    CUR_COLOR,
    CUR_DRAG,
    CUR_EDIT,
    CUR_ERASER,
    CUR_SIZE,
    CURSORS,
    DIGITS,
    EDIT_BTNS,
    GRAPHIC_SCALES,
    LEFT_BOARD_LIMIT,
    LETTERS,
    NEXT_BTN,
    PREV_BTN,
    RIGHT_BOARD_LIMIT,
    STORE_BTNS,
    TEXT_GRID_SPACING,
    TEXT_SCALES,
    TOP_BOARD_LIMIT,
    DANGLE_Y,
    LETTERS_MAP,
    CHARACTERS,
    SHEKEL,
    SHEKEL_REPLACEMENT,
    LETTER_SEPARATOR,
    CHAR_SEPARATOR,
    LTR,
    RTL,
    PREVIEW_BTNS,
    RTL_CHARACHTERS,
    PREVIEW_FULLSCREEN_SCALE,
    MAIL_PREVIEW_BTNS,
    PREVIEW_DELETE_SCALE,
    VALID_ALERT as VALID_ALERTS,
} from "./utils/constants";
import { KeyboardInput } from "./keyboardInput";
import { Store } from "./stores/Store";
import { BackgroundStore } from "./stores/BackgroundStore";
import { StampStore } from "./stores/StampStore";
import { SentencesStore } from "./stores/SentencesStore";
import { ColorsStore } from "./stores/ColorsStore";
import { LettersStore } from "./stores/LettersStore";
import { loadSound } from "../animate/sound_utils";
import { colorMap } from "./mapping/colorMap";
import { creationTooltip } from "./utils/creationTooltip";
import { CreationApi } from "../api/creationApi";
import { isMac, isWin } from "../utils/BrowserUtils";
import { SaveCreation } from "./SaveCreation";
import { getSentenceById, getStampByName } from "./utils/mapUtils";
import { SendEmail } from "./SendEmail";

export type ViewMode = "create" | "preview" | "mail" | "contacts" | "myWorks" | "myWorksCreation" | "delete";
export class CreationGame {
    private root: MC;
    private printVersion: MC;
    private shouldPrint: boolean;
    private showInput: boolean;
    private selectedStoreBtn: MC;
    private selectedEditBtn: MC;
    private currentCursor: MC;
    private isOverBtn: boolean = false;
    private overStore: boolean = false;
    private keyboardInput: KeyboardInput = null;
    private currentStore: Store;
    private backgroundStore: BackgroundStore;
    private sentencesStore: SentencesStore;
    private stampStore: StampStore;
    private colorsStore: ColorsStore;
    private lettersStore: LettersStore;
    private currentItemToDrag: MC = null;
    private moveX: number = 0;
    private moveY: number = 0;
    private choosedColor: string = "#FF3535";
    private itemsOnBoard: any[] = [];
    private api: CreationApi = null;
    private creationId: number;
    private saveCreation: SaveCreation;
    private sendMail: SendEmail;
    private contactsList: string;
    private notEnabledRootChildrenList: MC[] = [];
    private unloaded = false;
    private hideValidAlert = false;
    private newPageMode = false;
    private btnsToDisplay: any[] = null;
    private select_figure_mc: MC;
    private panel_stamps: MC;
    private panel_stamps_ab: MC;

    constructor(
        private letterIndex: string,
        private setLetterStatus: (letter: number, activity: string) => void,
        private setScreenReady: (val: boolean) => void,
        private viewMode: ViewMode = "create",
        private workId: string = "0",
        private setShouldPrint?: (val: boolean) => void,
        public setShowInput?: (val: boolean) => void,
        public setNameText?: (val: string) => void,
        public setEmailText?: (val: string) => void,
        private setShouldRefresh?: (val: boolean) => void,
        public setShowSelectImages?: (val: boolean) => void,
        public setShowDeleteAlert?: (val: boolean) => void,
        private setParentsInfo?: (value: boolean) => void
    ) {}
    loaded = async (mc: MC) => {
        this.root = mc;
        !this.isCreationMode() && this.hideAllChildren();
        loadFont(this.root, "NarkisTamMFO");
        (window as any).creation = this.root;
        this.root.gotoAndStop(2);
        this.setShouldRefresh?.(false);
        this.setupView(); //must be before init selected
        this.initCursorPosition();
        this.initCursors();
        this.initAlert();
        this.showContactsBg(false);
        await this.initApi();
        if (this.unloaded) return;

        if (!this.isCreationMode() && !this.isContactsMode()) {
            this.generateClassInstances(); //must be before init selected and addroot listeners
            this.displayCreationInViewMode();
        } else {
            this.initStampPanels();
            this.generateClassInstances(); //must be before init selected and addroot listeners
            this.initTopIcons();
            this.initBottomIcons();
            this.initEditBtns();
            this.initStorebtns();
            this.initSelected();
            this.initContactsBtns();
            await waitFor(() => this.root);
            this.letterIndex && this.initGalleryDisplay();
            this.soundOpenPage();
            this.initExitAlert();
            this.workId !== "0" && this.displayCreation();
            this.initLastDump();
            if (this.isContactsMode()) {
                const list = await this.api.getContactsList();
                this.sendMail = new SendEmail(this.root, list, this, "contactMode");
                this.getStampPanel().visible = false;
                this.toggleAddContacts(true);
                this.setScreenReady(true);
                this.showContactsBg(true);
            }
        }
        if (this.viewMode !== "myWorks" && this.viewMode !== "contacts") {
            this.addRootListeners();
            this.listenToPrint();
            this.setScreenReady(true);
        }
        await waitForTimer(0);
        // this.debugBoardLimits();
    };

    unload() {
        this.unloaded = true;
    }
    showContactsBg(val: boolean) {
        this.root.contactsBg.visible = val;
        this.root.logo.visible = val;
        this.root.closeBtn.visible = val;
        this.root.btnBack.visible = val;
    }

    selectImageLoaded = async (mc: MC) => {
        this.select_figure_mc = mc.select_figure_mc;
    };
    deleteAlert = (mc: MC) => {
        this.deleteAlert = mc;
    };
    getDeleteAlert = () => {
        return this.deleteAlert;
    };
    getSelectFigure = async () => {
        await waitFor(() => this.select_figure_mc);
        return this.select_figure_mc;
    };

    isCreationMode = () => {
        return this.viewMode === "create" || this.isMyWorksCreationMode();
    };
    isContactsMode = () => {
        return this.viewMode === "contacts";
    };
    soundOpenPage = async () => {
        let soundPath;
        let soundPath2;
        if (this.isContactsMode()) return;
        if (this.letterIndex) {
            soundPath = `/creation_corner/sounds/write_letter.mp3`;
            soundPath2 = `/creation_corner/sounds/helpBtn/letter${this.letterIndex}.mp3`;
            await loadSound(soundPath);
            await loadSound(soundPath2);
            await playSound(soundPath);
            await playSound(soundPath2);
        } else {
            soundPath = "/creation_corner/sounds/creation.mp3";
            await loadSound(soundPath);
            await playSound(soundPath);
        }
    };

    initApi = async () => {
        if (this.api) return;
        if (this.letterIndex) {
            this.api = CreationApi.forLetter(this.letterIndex);
        } else {
            this.api = CreationApi.forWork(this.workId, this.isMailMode() ? "2" : "1");
        }
        await this.api.getInfo();
    };

    getContactsList = () => {
        return this.contactsList;
    };

    initGalleryDisplay = () => {
        const display = this.api.getDisplayForLetter(this.letterIndex);
        for (let i = 0; i < display.length; i++) {
            const displayObj = display[i];
            this.generateLetterObject(displayObj);
        }
    };

    generateClassInstances = () => {
        this.keyboardInput = new KeyboardInput(this.root, this);
        this.backgroundStore = new BackgroundStore(this.root, this);
        this.sentencesStore = new SentencesStore(this.root, this);
        this.colorsStore = new ColorsStore(this.root, this);
        this.lettersStore = new LettersStore(this.root, this);
        this.saveCreation = new SaveCreation(this.root);
    };

    initStorebtns = async () => {
        let timeout: any;
        this.root[NEXT_BTN].mouseChildren = null;
        this.root[PREV_BTN].mouseChildren = null;
        await loadSound(`/creation_corner/sounds/storeBtn/${NEXT_BTN}.mp3`);
        await loadSound(`/creation_corner/sounds/storeBtn/${PREV_BTN}.mp3`);
        [NEXT_BTN, PREV_BTN].forEach((btnName) => {
            const btn = this.root[btnName];
            btn.addEventListener("mouseover", () => {
                btn.gotoAndStop("lightBlue");
                if (!this.root.panel_backgrounds.visible) {
                    timeout = setTimeout(async () => {
                        await playSound(`/creation_corner/sounds/storeBtn/${btnName}.mp3`);
                    }, 750);
                }
            });
            btn.addEventListener("mouseout", () => {
                btn.gotoAndStop("blue");
                if (timeout) {
                    clearTimeout(timeout);
                }
            });
        });
        this.root[NEXT_BTN].addEventListener("click", async () => {
            this.playClick();
            this.currentStore.nextFrame();
            this.selectNewEdit(this.root.btnDrag);
            if (timeout) {
                clearTimeout(timeout);
            }
        });
        this.root[PREV_BTN].addEventListener("click", async () => {
            this.playClick();
            this.currentStore.prevFrame();
            this.selectNewEdit(this.root.btnDrag);
            if (timeout) {
                clearTimeout(timeout);
            }
        });
    };
    disableStoreBtns = () => {
        [NEXT_BTN, PREV_BTN].forEach((btnName) => {
            const btn = this.root[btnName];
            btn.gotoAndStop("gray");
            btn.mouseEnabled = false;
        });
    };

    enableStoreBtns = () => {
        [NEXT_BTN, PREV_BTN].forEach((btnName) => {
            const btn = this.root[btnName];
            btn.gotoAndStop("blue");
            btn.mouseEnabled = true;
        });
    };

    playClick = async () => {
        playSound("click");
    };
    onStore = () => {
        const store_local_position = this.root.storeBG.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        return this.root.storeBG.hitTest(store_local_position.x, store_local_position.y);
    };
    onKeyboard = () => {
        const position = this.root.keyboardBG.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        return this.root.keyboardBG.hitTest(position.x, position.y);
    };
    overBtns = (target: string) => {
        if (!target) return false;
        let res = false;
        [...ACTION_BTNS, ...STORE_BTNS, ...EDIT_BTNS].forEach((btnName) => {
            if (target.startsWith(btnName)) {
                res = true;
            }
        });
        return res;
    };
    overArrows = (target: string) => {
        if (!target) return false;
        let res = false;
        [NEXT_BTN, PREV_BTN].forEach((btnName) => {
            if (target.startsWith(btnName)) {
                res = true;
            }
        });
        return res;
    };
    initCursors = () => {
        this.currentCursor = this.getCursor();
        this.root.cursor = "none";
        this.root[CUR_COLOR].gotoAndStop(5);
        CURSORS.forEach((cursor: string) => {
            this.root[cursor].mouseEnabled = false;
        });
    };

    initTopIcons = () => {
        ACTION_BTNS.forEach(async (iconName: string) => {
            const icon = this.root[iconName];
            const icon_hit = this.root[iconName + "_hit"];
            creationTooltip.addToolTip(icon_hit, `/creation_corner/sounds/storeBtn/${iconName}.mp3`);
            icon.gotoAndStop("blue");
            icon_hit.addEventListener("mouseover", () => {
                icon.gotoAndStop("lightblue");
            });
            icon_hit.addEventListener("mouseout", () => {
                icon.gotoAndStop("blue");
            });
            icon_hit.addEventListener("click", async () => {
                this.iconOnClick(icon, icon_hit);
            });
        });
    };

    isOverIcon = (icon_hit: MC) => {
        const position = icon_hit.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        return icon_hit.hitTest(position.x, position.y);
    };
    initBottomIcons = () => {
        STORE_BTNS.forEach((btnName: string) => {
            const btn = this.root[btnName];
            const btn_hit = this.root[btnName + "_hit"];
            creationTooltip.addToolTip(btn_hit, `/creation_corner/sounds/storeBtn/${btnName}.mp3`);
            btn.gotoAndStop("blue");
            btn_hit.addEventListener("mouseover", () => {
                if (this.selectedStoreBtn === btn) return;
                btn.gotoAndStop("lightblue");
            });
            btn_hit.addEventListener("mouseout", () => {
                if (this.selectedStoreBtn === btn) return;
                btn.gotoAndStop("blue");
            });
            btn_hit.addEventListener("click", async () => {
                this.playClick();
                if (this.selectedStoreBtn === btn) {
                    btn.gotoAndStop("lightblue");
                    await waitForTimer(20);
                }
                this.selectNewStore(btn);
            });
        });
    };
    getEditMode = () => {
        return this.selectedEditBtn;
    };

    initEditBtns = () => {
        EDIT_BTNS.forEach((btnName: string) => {
            const btn = this.root[btnName];
            const btn_hit = this.root[btnName + "_hit"];
            creationTooltip.addToolTip(btn_hit, `/creation_corner/sounds/storeBtn/${btnName}.mp3`);
            btn.gotoAndStop("blue");
            btn_hit.addEventListener("mouseover", () => {
                if (this.selectedEditBtn === btn) return;
                btn.gotoAndStop("lightblue");
            });
            btn_hit.addEventListener("mouseout", () => {
                if (this.selectedEditBtn === btn) return;
                btn.gotoAndStop("blue");
            });
            btn_hit.addEventListener("click", async () => {
                this.playClick();
                if (this.selectedEditBtn === btn) {
                    btn.gotoAndStop("lightblue");
                    await waitForTimer(20);
                }
                this.selectNewEdit(btn);
            });
        });
    };

    selectAction = (btn: MC) => {
        switch (btn) {
            case this.root.iNew:
                this.newPage();
                break;
            case this.root.iPrint:
                this.setShouldPrint(true);
                break;
            case this.root.iHelp:
                this.playHelp();
                break;
            case this.root.iSave:
                this.saveWork();
                break;
            case this.root.iMail:
                this.openMail();
                break;
            case this.root.iQuit:
                this.openExitPopup();
                break;
        }
    };
    saveWork = () => {
        this.keyboardInput.deActivate();
        if (!this.itemsOnBoard.length) return;
        const has_changed = this.saveCreation.saveCreation(this.itemsOnBoard, this);
        this.setKeyBoardActiveState(false);
        this.setShouldRefresh?.(has_changed);
    };

    openMail = () => {
        if (!this.itemsOnBoard.length) {
            this.openPopup(this.root.alert);
            this.toggleAlert(true);
            return;
        }
        if (!this.sendMail) {
            this.sendMail = new SendEmail(this.root, this.api.getContactsList(), this);
        }
        // this.root.setChildIndex(this.root.contacts_mc, this.root.numChildren - 1);
        this.keyboardInput.deActivate();
        this.toggleContacts(true);
        this.setKeyBoardActiveState(false);
        this.openPopup(this.root.contacts_mc);
    };

    playHelp = async () => {
        this.setKeyBoardActiveState(false);
        let soundPath;
        if (this.letterIndex) {
            soundPath = `/creation_corner/sounds/helpBtn/letter${this.letterIndex}.mp3`;
        } else {
            soundPath = "/creation_corner/sounds/helpBtn/create_help.mp3";
        }
        (await loadSound(soundPath)).play();
    };

    initSelected = () => {
        this.selectedStoreBtn = this.root.btnPics;
        this.selectedEditBtn = this.root.btnDrag;
        this.getStampPanel().visible = true;
        this.currentStore = this.stampStore;
        this.selectedEditBtn.gotoAndStop("red");
        this.selectedStoreBtn.gotoAndStop("green");
    };

    initAlert = () => {
        this.root.alert.proceed_btn.visible = false;
        this.root.alert.yes_btn.visible = false;
        this.root.alert.no_btn.visible = false;
        this.root.alert.cursor = "default";
        this.root.alert.close_btn.cursor = "pointer";
        this.toggleAlert(false);
        this.root.alert.close_btn.addEventListener("click", () => {
            this.toggleAlert(false);
            this.closePopup();
        });
    };
    toggleAlert = (val: boolean) => {
        this.root.alert.visible = val;
    };
    selectNewEdit = (btn: MC) => {
        this.selectedEditBtn && this.selectedEditBtn.gotoAndStop("blue");
        this.selectedEditBtn = btn;
        btn.gotoAndStop("red");
        this.setKeyBoardActiveState();
    };

    selectNewStore = (btn: MC) => {
        const prevBtn = this.selectedStoreBtn;
        this.selectedStoreBtn.gotoAndStop("blue");
        this.selectedStoreBtn = btn;
        if (this.selectedStoreBtn === this.root.btnColor) {
            this.disableStoreBtns();
        } else {
            this.enableStoreBtns();
        }
        btn.gotoAndStop("green");
        this.hidePanels();
        this.updateEditState();
        this.updateSelectedPanel(prevBtn);
    };

    updateEditState = () => {
        if (!this.selectedEditBtn) return;
        if (this.selectedStoreBtn === this.root.btnColor) {
            this.selectedEditBtn.gotoAndStop("blue");
            this.selectedEditBtn = null;
            this.setKeyBoardActiveState();
        } else {
            this.selectNewEdit(this.root.btnDrag);
        }
    };

    initStampPanels = () => {
        this.panel_stamps = new this.root.lib["panel_stamps"]();
        this.panel_stamps_ab = new this.root.lib["panel_stamps_ab"]();
        [this.panel_stamps_ab, this.panel_stamps].forEach((panel) => {
            panel.visible = false;
            panel.x = 614;
            panel.y = 72;
            this.root.addChild(panel);
        });

        this.stampStore = new StampStore(this.root, this.getStampPanel(), this.letterIndex, this); // this.letterindex
    };
    hidePanels = () => {
        this.root.panel_colors.visible = false;
        this.root.panel_backgrounds.visible = false;
        this.panel_stamps && (this.panel_stamps.visible = false);
        this.root.panel_lines.visible = false;
        this.panel_stamps_ab && (this.panel_stamps_ab.visible = false);
    };

    updateSelectedPanel = (prevBtn: MC) => {
        switch (this.selectedStoreBtn) {
            case this.root.btnColor:
                this.root.panel_colors.visible = true;
                this.root.panel_colors.gotoAndStop(0);
                break;
            case this.root.btnBackPic:
                this.root.panel_backgrounds.visible = true;
                this.root.panel_backgrounds.gotoAndStop(0);
                this.currentStore = this.backgroundStore;
                break;
            case this.root.btnPics:
                const stamps_panel = this.getStampPanel();
                stamps_panel.visible = true;
                this.currentStore = this.stampStore;
                if (prevBtn !== this.selectedStoreBtn && this.letterIndex) {
                    this.currentStore.setCurrentFrame(0);
                }
                break;
            case this.root.btnTxt:
                this.root.panel_lines.visible = true;
                this.currentStore = this.sentencesStore;
                break;
        }
    };
    setKeyBoardActiveState = (showline: boolean = true) => {
        this.keyboardInput.deActivate();
        switch (this.selectedEditBtn) {
            case this.root.btnEdit:
                this.keyboardInput.activate(showline);
                break;
            default:
                this.keyboardInput.deActivate();
                break;
        }
    };

    deactivateKeyBoard = () => {
        this.keyboardInput.deActivate();
    };

    newPage = () => {
        this.selectNewEdit(this.root.btnDrag);
        if (this.creationHasChanged() && this.itemsOnBoard.length) {
            this.newPageMode = true;
            this.openPopup(this.root.AlertBox, true);
            this.toggleExitAlert(true);
            this.root.setChildIndex(this.root.AlertBox, this.root.numChildren - 1);
            return;
        }
        this.removeItemsFromBoard();
        this.setShouldRefresh?.(false);
        this.workId = "0";
    };

    toggleExitAlert = (val: boolean) => {
        this.root.AlertBox.visible = val;
    };

    toggleContacts = (val: boolean) => {
        this.root.contacts_mc.visible = val;
    };

    toggleAddContacts = (val: boolean) => {
        this.root.add_contacts_mc.visible = val;
        this.setShowInput(val);
    };

    openExitPopup = () => {
        this.keyboardInput.deActivate();
        if (!this.creationHasChanged() || !this.isCreationMode() || !this.itemsOnBoard.length) {
            this.exitGame();
            return;
        }
        // this.root.children.forEach((c: MC) => {
        //     c.mouseEnabled = false;
        // });
        // this.root.AlertBox.mouseEnabled = true;
        this.openPopup(this.root.AlertBox, true);
        this.toggleExitAlert(true);
        this.root.setChildIndex(this.root.AlertBox, this.root.numChildren - 1);
        //    this.toggleCurrentCursor(false);
    };

    moveCursor = (position: createjs.Point) => {
        const cursor = this.getCursor();
        if (this.currentCursor !== cursor) {
            this.changeCursor(cursor);
        }
        this.currentCursor.x = position.x;
        this.currentCursor.y = position.y;
    };

    getCursor = () => {
        return this.root[this.getCursorType()];
    };

    getCursorType = () => {
        if (this.isOverBtn) {
            return CUR_DRAG;
        } else if (this.overStore) {
            if (this.selectedStoreBtn === this.root.btnColor) {
                return CUR_COLOR;
            }
            return CUR_DRAG;
        } else if (this.selectedEditBtn === this.root.btnEraser) {
            return CUR_ERASER;
        } else if (this.selectedEditBtn === this.root.btnSize) {
            return CUR_SIZE;
        } else if (this.selectedEditBtn === this.root.btnEdit) {
            return CUR_EDIT;
        } else if (this.selectedEditBtn === this.root.btnDrag) {
            return CUR_DRAG;
        } else if (this.selectedStoreBtn === this.root.btnColor) {
            return CUR_COLOR;
        } else {
            return CUR_DRAG;
        }
    };

    addRootListeners = () => {
        this.root.addEventListener("tick", (e: createjs.MouseEvent) => {
            if (this.isDeleteMode()) {
                if (this.root.getChildIndex(this.root.delete_alert) < this.root.numChildren - 1) {
                    this.root.setChildIndex(this.root.delete_alert, this.root.numChildren - 1);
                }
            }
            this.btnsToDisplay && this.checkPreviewBtnsIndex();

            if ((e as createjs.TickerEvent).paused) return;
            const position = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.isCreationMode() && this.dragItem(position);
            this.moveCursor(position);
        });
        if (this.isCreationMode()) {
            this.root.addEventListener("mouseover", (e: createjs.MouseEvent) => {
                if (this.root.getChildIndex(this.currentCursor) < this.root.numChildren - 1) {
                    //cursor index is incorrect
                    this.initCursorIndex();
                }
                if (this.overBtns(e.target.parent?.name) || this.onKeyboard() || this.overArrows(e.target.name)) {
                    this.isOverBtn = true;
                }
                if (this.onStore() && !this.currentItemToDrag) {
                    this.overStore = true;
                }
            });
            this.root.addEventListener("mouseout", (e: createjs.MouseEvent) => {
                if (!this.overBtns(e.target.parent?.name) && !this.onKeyboard()) {
                    this.isOverBtn = false;
                }
                if (!this.onStore()) {
                    this.overStore = false;
                }
            });
            this.root.addEventListener("click", (e: createjs.MouseEvent) => {
                if (this.currentStore.isChildElement && this.currentStore.isChildElement(e.target)) {
                    if (this.isNotDragAction()) {
                        this.selectNewEdit(this.root.btnDrag);
                        this.currentCursor = this.root[CUR_DRAG];
                    }
                }

                if (!(e.target.parent?.name === "add_new_mc")) this.hideNotValidAlerts();
            });
        }
    };

    getStampPanel = () => {
        if (this.letterIndex) {
            return this.panel_stamps_ab;
        } else {
            return this.panel_stamps;
        }
    };

    initCursorIndex = () => {
        this.root.setChildIndex(this.currentCursor, this.root.numChildren - 1);
    };

    getCurrentCursor = () => {
        return this.currentCursor;
    };

    setNewItemToDrag = (item: MC, x?: number, y?: number) => {
        const position = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
        this.currentItemToDrag = item;
        this.moveX = position.x - x;
        this.moveY = position.y - y;
    };
    getCurrentItemToDrag = () => {
        return this.currentItemToDrag;
    };

    dragItem = (position: createjs.Point) => {
        if (this.isNotDragAction()) return;
        if (this.currentItemToDrag) {
            this.currentItemToDrag.x = position.x - this.moveX;
            this.currentItemToDrag.y = position.y - this.moveY;
            this.root.setChildIndex(this.currentItemToDrag, this.root.numChildren - 2);
            if (this.currentItemToDrag.text) {
                this.currentItemToDrag.y = this.applyGridY(this.currentItemToDrag.y, false);
            }
        }
    };
    addItemToBoard = async (item: createjs.Text | MC, type: number, size?: number) => {
        item.sizeIndex = size ? size : 0;
        item.type = type;
        this.itemsOnBoard.push(item);
        this.itemsOnBoard[this.itemsOnBoard.indexOf(item)].sizeIndex = size ? size : 0;
        this.itemsOnBoard[this.itemsOnBoard.indexOf(item)].type = type;
        this.root.addChild(item);
        this.initCursorIndex();
    };
    getItemsOnBoard = () => {
        return this.itemsOnBoard;
    };

    setChooseColor = (color: string) => {
        this.choosedColor = color;
    };

    chooseAction = (item: createjs.Text | MC, type: "text" | "graphic") => {
        switch (this.getCursor().name) {
            case "curSize":
                this.resizeItem(item, type);
                break;
            case "curColor":
                item.color = this.choosedColor;
                this.playClick();
                break;
            case "curDrag":
                this.startDrag(item);
                break;
            case "curEraser":
                this.removeItemFromBoard(item);
                break;
            default:
                return;
        }
    };

    removeItemFromBoard = async (item: any) => {
        await item.removeAllEventListeners();
        this.root.removeChild(item);
        this.itemsOnBoard.splice(this.itemsOnBoard.indexOf(item), 1);
    };

    generateLetterObject = (displayObj: { [k: string]: any }) => {
        const id = parseInt(displayObj["nID"]);
        const x = parseInt(displayObj["x"]);
        const y = parseInt(displayObj["y"]);
        const local = localToGlobal(this.root.theWork, x, y);
        const content = displayObj["sContent"];
        const scale = parseInt(displayObj["nSize"]) - 1;
        const color = parseInt(displayObj["nColor"]) + 1;
        const type = parseInt(displayObj["nType"]);
        if (id === 999) {
            //graphic
            const instance = new this.root.lib[content]();
            instance.lib = content;
            instance.x = local.x;
            instance.y = local.y;
            instance.scale = GRAPHIC_SCALES[scale];
            this.addItemToBoard(instance, type);
            this.stampStore.initInstanceListeners(instance);
        } else if (id >= 1 && id <= 27) {
            //text
            const letter = LETTERS[id - 1];
            const calc_y = scale === 2 ? local.y : local.y - 16;
            TextFunctions.generateStaticInput(
                local.x,
                calc_y,
                letter,
                TEXT_SCALES[scale],
                this,
                type,
                colorMap["c" + color],
                false,
                true
            );
        }
    };

    startDrag = (item: any) => {
        this.setNewItemToDrag(item, item.x, item.y);
    };

    resizeItem = (item: any, type: "text" | "graphic") => {
        switch (type) {
            case "text":
                this.textResize(item);
                break;
            case "graphic":
                this.resizeGraphic(item);
                break;
        }
    };

    textResize = (item: any) => {
        const index = this.itemsOnBoard.indexOf(item);
        const newIndex = (this.itemsOnBoard[index].sizeIndex + 1) % 4;
        let scale = TEXT_SCALES[newIndex];
        this.itemsOnBoard[index].sizeIndex = newIndex;
        if (TextFunctions.getSentenceWidth(item, this) > RIGHT_BOARD_LIMIT - LEFT_BOARD_LIMIT) {
            scale = TEXT_SCALES[3];
            this.itemsOnBoard[index].sizeIndex = 3;
        }
        item.scale = scale;
        TextFunctions.checkPosition(this, item);
    };

    resizeGraphic = (item: any) => {
        const index = this.itemsOnBoard.indexOf(item);
        const newIndex = (this.itemsOnBoard[index].sizeIndex + 1) % 3;
        item.scale = GRAPHIC_SCALES[newIndex];
        this.itemsOnBoard[index].sizeIndex = newIndex;
        this.stampStore.checkPosition(item);
    };

    getcurrentGraphicScale = (item: any) => {
        const index = this.itemsOnBoard.indexOf(item);
        return GRAPHIC_SCALES[this.itemsOnBoard[index].sizeIndex];
    };

    getTextScale = (item: createjs.Text) => {
        const index = this.itemsOnBoard.indexOf(item);
        return TEXT_SCALES[this.itemsOnBoard[index].sizeIndex];
    };
    changeCursor = (cursor: any) => {
        if (this.root.contacts_mc.visible || this.root.AlertBox.visible) return;
        this.toggleCurrentCursor(false);
        this.currentCursor = cursor;
        this.toggleCurrentCursor(true);
        this.initCursorIndex();
    };

    debugBoardLimits = () => {
        const g = new createjs.Shape();
        this.root.addChild(g);
        g.graphics
            .s("blue")
            .r(
                LEFT_BOARD_LIMIT,
                TOP_BOARD_LIMIT,
                RIGHT_BOARD_LIMIT - LEFT_BOARD_LIMIT,
                BOTTOM_BOARD_LIMIT - TOP_BOARD_LIMIT
            );
    };

    isNotDragAction = () => {
        return (
            this.selectedEditBtn === this.root.btnEdit ||
            this.selectedEditBtn === this.root.btnSize ||
            this.selectedEditBtn === this.root.btnEraser
        );
    };

    prepareForPrint = async (mc: createjs.MovieClip) => {
        this.keyboardInput && this.keyboardInput.deActivate();

        (window as any).printVersion = mc;
        this.printVersion = mc;
        await this.initApi();
        await waitFor(() => this.printVersion.stage);
        this.printVersion.children.forEach((c: MC) => {
            c.visible = false;
        });
        this.printVersion.theWork.visible = true;
        this.printVersion.theWork.x = 0;
        this.printVersion.theWork.y = 15;
        this.printVersion.theWork.BackPics.gotoAndStop(this.backgroundStore?.getCurrentBg() || 0);
        this.printVersion.theWork.printTab.visible = true;
        this.printVersion.theWork.printTab.siteTxt.text = await this.api.getSiteUrl();
        this.printVersion.theWork.printTab.nameTxt.text = await this.api.getUserName();

        const printItems: any[] = [];
        this.itemsOnBoard &&
            this.itemsOnBoard.forEach(async (item) => {
                const clone = item.clone();
                printItems.push(clone);
                clone.x = item.x - 36;
                clone.y = item.y - 50;
                this.printVersion.addChild(clone);
            });
        this.printVersion.stage.update();

        this.shouldPrint && window.print();
        this.setShouldPrint(false);
        printItems.forEach((item) => {
            this.printVersion.removeChild(item);
        });
        this.keyboardInput && this.setKeyBoardActiveState(false);
    };
    shouldShowInput = () => {
        this.showInput = true;
    };
    // setNameText= ()=>{
    //     this.changeText=
    // }
    startPrint = () => {
        this.shouldPrint = true;
        this.prepareForPrint(this.printVersion);
    };

    applyGridY = (y: number, checkBottom: boolean = true) => {
        let rounded = Math.round((y + DANGLE_Y) / TEXT_GRID_SPACING) * TEXT_GRID_SPACING;
        if (checkBottom && rounded >= BOTTOM_BOARD_LIMIT - DANGLE_Y) {
            rounded -= TEXT_GRID_SPACING;
        }
        return rounded;
    };

    listenToPrint = () => {
        window.addEventListener("keydown", (e) => {
            if (e.code === "KeyP" && ((isWin() && e.ctrlKey) || (isMac() && e.metaKey))) {
                this.keyboardInput && this.keyboardInput.deleteCharacters();
                e.preventDefault();
                this.startPrint();
            }
        });
    };

    initExitAlert = () => {
        this.root.AlertBox.message.text = "רוצים לשמור את היצירה שלכם?";
        this.root.AlertBox.x -= 10;
        this.root.AlertBox.y = 300;
        this.root.AlertBox.cursor = "default";
        this.root.AlertBox.yes.cursor = "pointer";
        this.root.AlertBox.no.cursor = "pointer";
        this.overListeners();
        this.root.AlertBox.yes.addEventListener("click", () => {
            this.root.AlertBox.yes.gotoAndPlay("_down");
            this.keyboardInput.deActivate();
            const shouldRefresh = this.saveCreation.saveCreation(this.itemsOnBoard, this);
            this.toggleExitAlert(false);
            if (this.newPageMode) {
                this.removeItemsFromBoard();
                this.closePopup();
                this.newPageMode = false;
                this.saveCreation.setLastDump("");
                this.workId = "0";
            } else {
                this.exitGame();
            }
            this.setShouldRefresh?.(shouldRefresh);
            this.setShouldRefresh?.(false);
        });
        this.root.AlertBox.no.addEventListener("click", () => {
            this.root.AlertBox.no.gotoAndPlay("_down");
            if (this.newPageMode) {
                this.removeItemsFromBoard();
                this.toggleExitAlert(false);
                this.closePopup();
                this.newPageMode = false;
                this.saveCreation.setLastDump("");
                this.workId = "0";
            } else {
                this.exitGame();
            }
        });
    };

    exitGame = () => {
        if (this.letterIndex) {
            this.setLetterStatus(parseInt(this.letterIndex), "write");
            Navigation.openPage(
                generatePath(routes.letters.activities.menu, { letterIndex: parseInt(this.letterIndex) - 1 })
            );
            stopAllSounds();
        } else {
            if (!this.isCreationMode() || this.isMyWorksCreationMode()) {
                Navigation.openPage(routes.my_works.list);
                stopAllSounds();
            } else {
                Navigation.openPage(routes.home);
                stopAllSounds();
            }
        }
    };

    drawObject = (displayObj: { [k: string]: any }) => {
        const id = displayObj["nID"];
        const x = parseInt(displayObj["x"]);
        const y = parseInt(displayObj["y"]);
        const local = localToGlobal(this.root.theWork, x, y);
        const content = displayObj["sContent"];
        const scale = parseInt(displayObj["nSize"]) - 1;
        const color = parseInt(displayObj["nColor"]) + 1;
        const type = displayObj["nType"];
        const calc_y = local.y;
        const calc_x = local.x;

        switch (type) {
            case "0": //graphic
                this.createGraphicFromDump(content, local, scale, type);
                break;
            case "1": //sentence
                const sentence = getSentenceById(id) || "";
                this.createInputFromDump(calc_x, calc_y, sentence, type, scale, color);
                break;
            case "2": //letter
                const letter = LETTERS[id - 1];
                if (!letter) {
                    console.error("letter not found", id);
                    return;
                }
                this.createInputFromDump(calc_x, calc_y, letter, type, scale, color);
                break;
            case "3": //digit
                const digit = DIGITS[id];
                if (!digit) {
                    console.error("digit not found", id);
                    return;
                }
                this.createInputFromDump(calc_x, calc_y, digit, type, scale, color);
                break;
            case "4": //user input
                const text = this.parseKeyboardInput(content);
                this.createInputFromDump(calc_x, calc_y, text, type, scale, color);
                break;
        }
    };

    parseKeyboardInput = (input: string) => {
        let result = LTR;
        const params = input.split(SHEKEL).join(SHEKEL_REPLACEMENT).split(LETTER_SEPARATOR);

        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            let letter = param.slice(0, 2);
            let rest = param.slice(2);
            if (input.indexOf(LETTER_SEPARATOR) !== 0 && i === 0) {
                letter = null;
                rest = param;
            }
            if (letter) {
                const index = LETTERS_MAP.indexOf(letter);
                if (index >= 0) {
                    //letter
                    result += LTR + String.fromCharCode(index + 1488) + RTL;
                } else {
                    console.error("letter not found", letter);
                }
            }
            const split = rest.split(CHAR_SEPARATOR);
            for (let j = 0; j < split.length; j++) {
                if (split[j] === "") {
                    continue;
                }
                const element = split[j];
                let character = "%" + element.slice(0, 2);
                let digits = element.slice(2);
                if (rest.indexOf(CHAR_SEPARATOR) !== 0 && j === 0) {
                    character = null;
                    digits = element;
                }
                if (character) {
                    const char = Object.keys(CHARACTERS).find((key) => CHARACTERS[key] === character);
                    if (!char) {
                        console.error("char not found", character);
                    } else {
                        if (RTL_CHARACHTERS.includes(character)) {
                            result += LTR + char + RTL;
                        } else {
                            result += char;
                        }
                    }
                }
                result += digits.split("").reverse().join("");
            }
        }

        result += LTR;
        let text = result;
        result = "";
        for (let i = 0; i < text.length; i++) {
            result += text[text.length - 1 - i];
        }
        return result;
    };

    createInputFromDump = (x: number, y: number, text: string, type: number, scale: number, color: number) => {
        const input = TextFunctions.generateStaticInput(
            x,
            y,
            text,
            TEXT_SCALES[scale],
            this,
            type,
            colorMap["c" + color],
            false,
            true
        );
        // const g = new createjs.Shape();
        // g.graphics.f("red").drawRect(x, y, 5, 5);
        // this.root.addChild(g);
        TextFunctions.checkPosition(this, input);
        return input;
    };

    getCurrentBg = () => {
        return this.backgroundStore.getCurrentBg();
    };

    initCursorPosition = () => {
        this.root.stage.mouseX = 700;
        this.root.stage.mouseY = 600;
    };

    displayCreation = async () => {
        const display = await this.api.getDump();
        if (this.unloaded) return;
        const bg = parseInt(display[0]["nPic"]);
        this.backgroundStore.setCurrentBg(bg);
        for (let i = 1; i < display.length; i++) {
            const displayObj = display[i];
            this.drawObject(displayObj);
        }
        this.initLastDump();
    };

    createGraphicFromDump = (content: any, local: createjs.Point, scale: number, type: any) => {
        const instance = new this.root.lib[getStampByName(content)]();
        instance.lib = getStampByName(content);
        instance.x = local.x;
        instance.y = local.y;
        instance.scale = GRAPHIC_SCALES[scale];
        this.addItemToBoard(instance, type, scale);
        this.isCreationMode() && this.stampStore.initInstanceListeners(instance);
    };
    getLetterIndex = () => {
        return this.letterIndex;
    };

    toggleCurrentCursor = (val: boolean) => {
        this.currentCursor.visible = val;
    };

    displayCreationInViewMode = () => {
        this.root.theWork.visible = true;
        this.displayCreation();
        this.root.theWork.BackPics.gotoAndStop(this.backgroundStore?.getCurrentBg() || 0);

        this.currentCursor.scale /= PREVIEW_FULLSCREEN_SCALE;
        this.setupContactsPopupInViewMode();
        const mask = new createjs.Shape();
        mask.graphics
            .s("#000")
            .drawRect(
                LEFT_BOARD_LIMIT,
                TOP_BOARD_LIMIT,
                RIGHT_BOARD_LIMIT - LEFT_BOARD_LIMIT,
                BOTTOM_BOARD_LIMIT - TOP_BOARD_LIMIT - 5
            );
        this.root.mask = mask;
        this.root.cursor = "default";

        if (this.isMyWorksMode()) {
            this.drawLines();
        }
        (this.isPreviewMode() || this.isMailMode() || this.isDeleteMode()) && this.initPreviewBtns();
        this.setScreenReady(true);
        this.initDeletePopup();
    };

    setupView = () => {
        this.toggleAddContacts(false);
        this.toggleContacts(false);
        this.toggleExitAlert(false);
        this.toogleDeleteAlert(false);
        this.hidePanels(); //must be before init selected
        this.root.popupBg.visible = false;
        this.root.popupBg.cursor = "default";
        this.root.theWork.printTab.visible = false;
        this.root.letterTooltip.visible = false;
        this.root.noNameTooltip.visible = false;
        this.root.noEmailTooltip.visible = false;
        this.root.noValidEmailTooltip.visible = false;
        this.root.sendAlert.visible = false;
    };

    initPreviewBtns = () => {
        let start = localToGlobal(this.root.theWork, 0, 0).x + 30;
        switch (this.viewMode) {
            case "mail":
                this.btnsToDisplay = MAIL_PREVIEW_BTNS;
                break;
            case "preview":
                this.btnsToDisplay = PREVIEW_BTNS;
                break;
            case "delete":
                this.btnsToDisplay = PREVIEW_BTNS;
                break;
            case "myWorks":
                this.btnsToDisplay = null;
                break;
        }

        this.btnsToDisplay &&
            this.btnsToDisplay.forEach(async (iconName: string) => {
                const icon = this.root[iconName];
                const icon_hit = this.root[iconName + "_hit"];
                const icon_pic = this.root["pic" + iconName.slice(1)];
                [icon, icon_pic, icon_hit].forEach((part) => {
                    part.visible = true;
                    part.scale /= PREVIEW_FULLSCREEN_SCALE;
                    const children = this.root.numChildren;
                    this.root.setChildIndex(part, children - 1);
                    part.x = start;
                    part.y += 60;
                });
                start += 25;
                icon.gotoAndStop("blue");
                creationTooltip.addToolTip(icon_hit, `/creation_corner/sounds/storeBtn/${iconName}.mp3`);
                icon_hit.addEventListener("mouseover", () => {
                    this.root.cursor = "none";
                    this.toggleCurrentCursor(true);
                    icon.gotoAndStop("lightblue");
                });
                icon_hit.addEventListener("mouseout", () => {
                    this.root.cursor = "default";
                    this.toggleCurrentCursor(false);
                    icon.gotoAndStop("blue");
                });
                icon_hit.addEventListener("click", async () => {
                    this.iconOnClick(icon, icon_hit);
                });
            });
        this.initCursorIndex();
    };

    checkPreviewBtnsIndex = () => {
        this.btnsToDisplay.forEach(async (iconName: string) => {
            const icon = this.root[iconName];
            const icon_hit = this.root[iconName + "_hit"];
            const icon_pic = this.root["pic" + iconName.slice(1)];
            if (this.root.getChildIndex(icon) < this.root.numChildren - 1) {
                [icon, icon_pic, icon_hit].forEach((part) => {
                    this.root.setChildIndex(part, this.root.numChildren - 1);
                });
            }
        });
        if (this.isDeleteMode()) {
            if (this.root.getChildIndex(this.root.delete_alert) < this.root.numChildren - 1) {
                this.root.setChildIndex(this.root.delete_alert, this.root.numChildren - 1);
            }
        }
        this.initCursorIndex();
    };
    setWorkId = (id: string) => {
        this.workId = id;
    };
    getWorkId = () => {
        return this.workId;
    };
    openPopup = (alert: MC, val?: boolean) => {
        this.root.setChildIndex(this.root.popupBg, this.root.numChildren - 1);
        this.root.setChildIndex(alert, this.root.numChildren - 1);
        this.root.popupBg.visible = true;
        this.currentCursor.visible = false;
        if (val) {
            this.root.popupBg.alpha = 0.1;
        }
    };
    closePopup = () => {
        this.root.popupBg.alpha = 30;
        this.root.popupBg.visible = false;
        this.currentCursor.visible = true;
    };

    creationHasChanged = () => {
        return this.saveCreation.getLastDump().localeCompare(this.saveCreation.createSDump(this, this.itemsOnBoard));
    };

    onEmailSuccess = (id: string) => {
        this.setWorkId(id);
        this.setShouldRefresh?.(this.creationHasChanged() ? true : false);
        this.initLastDump();
    };

    setupContactsPopupInViewMode = () => {
        this.root.contacts_mc.scale /= PREVIEW_FULLSCREEN_SCALE;
        this.root.add_contacts_mc.scale /= PREVIEW_FULLSCREEN_SCALE;
        this.root.sendAlert.scale /= PREVIEW_FULLSCREEN_SCALE;

        this.root.contacts_mc.x = this.root.theWork.x + 260;
        this.root.contacts_mc.y = this.root.theWork.y + 200;
        this.root.add_contacts_mc.x = this.root.theWork.x + 306;
        this.root.add_contacts_mc.y = this.root.theWork.y + 185;
        this.root.sendAlert.x = this.root.theWork.x + 260;
        this.root.sendAlert.y = this.root.theWork.y + 190;
        this.root.setChildIndex(this.root.contacts_mc, this.root.numChildren - 1);
        this.root.setChildIndex(this.root.add_contacts_mc, this.root.numChildren - 1);
    };

    drawLines = () => {
        const topLine = new createjs.Shape();
        topLine.graphics
            .f("#f6a62d")
            .drawRect(LEFT_BOARD_LIMIT, TOP_BOARD_LIMIT, RIGHT_BOARD_LIMIT - LEFT_BOARD_LIMIT, 3);
        this.root.addChild(topLine);
        const bottomLine = new createjs.Shape();
        bottomLine.graphics
            .f("#f6a62d")
            .drawRect(LEFT_BOARD_LIMIT, BOTTOM_BOARD_LIMIT - 8, RIGHT_BOARD_LIMIT - LEFT_BOARD_LIMIT, 3);
        this.root.addChild(bottomLine);
    };

    iconOnClick = (icon: any, icon_hit: any) => {
        icon.gotoAndStop("grayblue");
        setTimeout(() => {
            if (!this.root?.stage) return;
            if (this.isOverIcon(icon_hit)) {
                icon.gotoAndStop("lightblue");
            } else {
                icon.gotoAndStop("blue");
            }
        }, 200);
        this.selectAction(icon);
    };

    private overListeners() {
        this.root.AlertBox.yes.addEventListener("mouseover", () => {
            this.root.AlertBox.yes.gotoAndStop("_over");
        });
        this.root.AlertBox.yes.addEventListener("mouseout", () => {
            this.root.AlertBox.yes.gotoAndStop("_up");
        });
        this.root.AlertBox.no.addEventListener("mouseover", () => {
            this.root.AlertBox.no.gotoAndStop("_over");
        });
        this.root.AlertBox.no.addEventListener("mouseout", () => {
            this.root.AlertBox.no.gotoAndStop("_up");
        });
    }

    private removeItemsFromBoard() {
        this.itemsOnBoard.forEach((item: any) => {
            this.removeItemFromBoard(item);
        });
        this.backgroundStore.setCurrentBg(0);
    }

    private initLastDump() {
        const lastDump = this.saveCreation.createSDump(this, this.itemsOnBoard);
        this.saveCreation.setLastDump(lastDump);
    }

    getDump = () => {
        return this.saveCreation.createSDump(this, this.itemsOnBoard);
    };
    initDeletePopup = () => {
        if (this.isDeleteMode()) {
            this.toogleDeleteAlert(true);
            const alert = this.root.delete_alert;
            this.root.setChildIndex(this.root.delete_alert, this.root.numChildren - 1);
            alert.scale /= PREVIEW_DELETE_SCALE;
            alert.message.text = "רוצים למחוק את היצירה?";
            alert.no_hit.cursor = "pointer";
            alert.yes_hit.cursor = "pointer";
            alert.no_hit.addEventListener("click", () => {
                Navigation.openPage(routes.my_works.list);
            });
            alert.yes_hit.addEventListener("click", async () => {
                await this.api.deleteCreation(this.workId);
                this.setShouldRefresh?.(true);
                Navigation.openPage(routes.my_works.list);
            });
            alert.no_hit.addEventListener("mouseover", () => alert.no.gotoAndStop(1));
            alert.no_hit.addEventListener("mouseout", () => alert.no.gotoAndStop(0));
            alert.yes_hit.addEventListener("mouseover", () => alert.yes.gotoAndStop(1));
            alert.yes_hit.addEventListener("mouseout", () => alert.yes.gotoAndStop(0));
            // });
        } else {
            this.toogleDeleteAlert(false);
        }
    };
    toogleDeleteAlert = async (val: boolean) => {
        this.root.delete_alert.visible = val;
        if (val) {
            (await loadSound("/creation_corner/sounds/DeleteWorkAsk.mp3")).play();
        }
    };

    hideAllChildren = () => {
        this.root.children.forEach((c: MC) => {
            c.visible = false;
        });
    };

    showNotValidAlert = (c: string) => {
        this.root.setChildIndex(this.root.letterTooltip, this.root.numChildren - 1);
        this.root.letterTooltip.visible = true;
        this.root.letterTooltip.show = true;
        this.root.letterTooltip.txt.text = "לא ניתן להקליד " + c;
        this.hideValidAlert = true;
    };
    hideNotValidAlerts = () => {
        VALID_ALERTS.forEach((v) => {
            if (this.root[v].visible) {
                this.root[v].visible = false;
            }
        });
    };
    updateEmailText = (email: string) => {
        this.sendMail.setEmailText(email);
    };
    updateNameText = (name: string) => {
        this.hideNotValidAlerts();
        this.sendMail.setNameText(name);
        this.setNameText(name);
    };
    setValidAlert = (val: boolean) => {
        this.hideValidAlert = val;
    };

    isDeleteMode = () => {
        return this.viewMode === "delete";
    };
    isMailMode = () => {
        return this.viewMode === "mail";
    };
    isMyWorksMode = () => {
        return this.viewMode === "myWorks";
    };
    isMyWorksCreationMode = () => {
        return this.viewMode === "myWorksCreation";
    };
    isPreviewMode = () => {
        return this.viewMode === "preview";
    };
    initContactsBtns = () => {
        const close_btn = this.root.closeBtn;
        close_btn.gotoAndStop("up");
        close_btn.cursor = "pointer";
        close_btn.addEventListener("click", () => {
            Navigation.openPage(routes.home);
        });
        close_btn.addEventListener("mouseover", () => {
            close_btn.gotoAndStop("over");
        });
        close_btn.addEventListener("mouseout", () => {
            close_btn.gotoAndStop("up");
        });
        const back_btn = this.root.btnBack;
        back_btn.gotoAndStop("up");
        back_btn.cursor = "pointer";
        back_btn.addEventListener("click", () => {
            this.setParentsInfo(true);
            Navigation.openPage(routes.home);
            Navigation.openIframe("/parentsInfo/details.aspx");
        });
        back_btn.addEventListener("mouseover", () => {
            back_btn.gotoAndStop("over");
        });
        back_btn.addEventListener("mouseout", () => {
            back_btn.gotoAndStop("up");
        });
    };
}
