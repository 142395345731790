import React from "react";
import hipoSounds from "../hipoSounds.json";
import mathSounds from "../mathSounds.json";
import { MathGame } from "../MathGame/MathGame";
import { MC } from "../../animate/utils";
import { Hipo } from "./Hipo";
import { MathGameComponent } from "../MathGame/MathGameComponent";

export function HipoGameComponent() {
    const game = React.useRef(new MathGame());
    return (
        <MathGameComponent
            animationName="hipo"
            additionalSounds={hipoSounds.concat(mathSounds)}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Hipo(mc), 2)}
            game={game}
        />
    );
}
