import { MC, playSoundSync, stopAllSounds, asyncTween } from "../../../animate/utils";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { routes } from "../../../navigation/routesPath";
import { Navigation } from "../../../navigation/navigation";
export class FancyWords {
    root: MC;
    lettersDone: boolean[];
    bgSound: any;

    loaded = async (mc: MC, lettersDone: boolean[]) => {
        this.root = mc;
        this.lettersDone = lettersDone;
        this.root.exit_btn.gotoAndStop(0);
        this.root.exit_btn.cursor = "pointer";
        this.root.back_btn.cursor = "pointer";
        this.root.back_btn.gotoAndStop(0);
        this.bgSound = playSoundSync("menuBg", { loop: -1 });
        this.bgSound.volume = 0.2;
        this.root.back_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.letters.menu);
        });
        this.root.exit_btn.addEventListener("click", () => {
            stopAllSounds();
            Navigation.openPage(routes.home);
        });
        this.root.preloader_mc.visible = false;
        this.root.book.scaleX = 0.7;
        this.root.book.scaleY = 0.7;
        this.root.book.x = 850;
        this.root.book.y = 120;
        this.root.book.all_letters_btn.visible = true;
        for (let i = 0; i < 22; i++) {
            this.root.book.all_letters_btn[`letter${i + 1}_btn`].visible = false;
        }

        for (let i = 0; i < 22; i++) {
            const currentLetterBtn = this.root.book.all_letters_btn[`letter${i + 1}_btn`];
            currentLetterBtn.index = i + 1;
            const letter = this.root.book[`letter${i + 1}`];
            currentLetterBtn.hit.alpha = 0.01;
            currentLetterBtn.visible = true;
            currentLetterBtn.alpha = 0;
            if (this.lettersDone[i]) {
                currentLetterBtn.gotoAndStop(1);
            } else {
                currentLetterBtn.gotoAndStop(45);
            }
            asyncTween(letter, { alpha: 0 }, 300);
            this.addEventToLetter(currentLetterBtn);
            await asyncTween(currentLetterBtn, { alpha: 1 }, 300);
        }
    };

    muteUnmuteBgSound = (mute: boolean) => {
        if (this.bgSound) {
            if (mute) {
                this.bgSound.stop();
            } else {
                this.bgSound.play({ loop: -1, volume: 0.2 });
            }
        }
    };
    addEventToLetter = (letterBtn: MC) => {
        if (letterBtn.currentFrame === 1) {
            letterBtn.hit.cursor = "pointer";
            letterBtn.cursor = "pointer";
            letterBtn.hit.mouseChildren = false;
            SoundToolTip.addToolTip(letterBtn, `letter${letterBtn.index}`);
            letterBtn.addEventListener("click", () => {
                this.bgSound.stop();
                stopAllSounds();
                Navigation.openPage(routes.letters.fancy_words.word.replace(":letterIndex", letterBtn.index + ""));
            });
            letterBtn.addEventListener("rollover", () => {
                letterBtn.gotoAndStop(15);
            });
            letterBtn.addEventListener("rollout", () => {
                letterBtn.gotoAndStop(1);
            });
        }
    };
}
