import { loadSound } from "../../animate/sound_utils";
import { MC, playSound, waitForEvent, stopAllSounds } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";
import MathGameBase from "../MathGame/MathGameBase";
import { CancelablePromise } from "../../utils/CancelablePromise";
import { routes } from "../../navigation/routesPath";
import { Navigation } from "../../navigation/navigation";
const addedNuts = [[], [1, 1], [2, 2], [3, 4]];
const gamesByLevels: any = {
    game1Levels: [[], [0, 9], [0, 8], [0, 6]],
    game2Levels: [[], [1, 9], [2, 9], [4, 9]],
};
export class Monkey extends MathGameBase {
    root: MC;
    addPrize: (prizeName: string) => void;
    cleanPrizes: (reset?: boolean) => void;
    enableHelp: () => void;
    disableHelp: () => void;
    stopPreloader: () => void;
    chosenLevel: number;
    barBtnyPos: number;
    number: any;
    wrongAnswers: number;
    correctAnswer: number = 0;
    btnsDisabled: boolean = false;
    enableEvents: boolean = true;
    isBarOpen: any;
    count: number;
    btnAllowClick: MC = null;
    openSelector: any;
    initChooser: (levels: number) => void;
    name = "monkey";
    gameType: "m" | "e";
    onInnerGame = false;
    gameNumber: 1 | 2;
    currentPromise: CancelablePromise<any>;
    goodResults = 0;
    startNuts = 0;

    constructor(root: createjs.MovieClip) {
        super();
        this.root = root;
        (window as any).monkey = root;
    }
    chooserDown = () => {
        this.playGame();
    };

    resetText = () => {
        ["", "1", "2"].forEach((item) => {
            const cad = this.root[`game_monkey_${this.gameNumber}`].monkey_mc[`cad_mc_${item}`];
            if (cad?.num_txt?.text) cad.num_txt.text = "";
        });
    };

    setText = (text: string) => {
        ["", "1", "2"].forEach((item) => {
            const cad = this.root[`game_monkey_${this.gameNumber}`].monkey_mc[`cad_mc_${item}`];
            if (cad?.num_txt?.text) cad.num_txt.text = text;
        });
    };

    animalTalk = (animal: MC, sounds: string) => {
        animal.head_mc.gotoAndPlay("loop");
        return playSound(sounds).then(() => {
            animal.head_mc.gotoAndStop(0);
        });
    };

    resetAnimals = () => {
        const mc = this.root[`game_monkey_${this.gameNumber}`];
        mc.gotoAndStop(0);
        mc.pil_mc.gotoAndStop(0);
        mc.monkey_mc.gotoAndStop(0);
    };

    closeMouthes = () => {
        this.root[`game_monkey_${this.gameNumber}`].monkey_mc.head_mc.gotoAndStop(0);
        this.root[`game_monkey_${this.gameNumber}`].pil_mc?.head_mc?.gotoAndStop(0);
    };

    stopAnimation = (resetText = true) => {
        this.currentPromise?.cancel?.();
        if (!this.gameNumber) return;
        this.removeNuts();
        this.closeMouthes();
        resetText && this.resetText();
        const { balutim_mc } = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        balutim_mc.gotoAndStop(0);
        this.root.game_monkey_2.monkey_mc.cad_mc_1.cross.visible = false;
        this.resetAnimals();
    };

    chooserUp = () => {
        this.stopAnimation(false);
        this.closeBar();
    };

    handleLevel = (level?: number) => {
        this.disableHelp();
        this.correctAnswer = 0;
        this.chosenLevel = level;
        this.goodResults = 0;
        this.cleanPrizes(true);
        this.enableEvents = true;
        this.playGame();
    };

    resetBarBtns = () => {
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.gotoAndStop(0);
            btn.num_mc.all_num.gotoAndStop(i);
            btn.num_mc.all_num[`num${i}`].gotoAndStop(0);
        }
    };

    openBar = async () => {
        this.isBarOpen = true;
        this.enableEvents = false;
        this.root.btns_anim_mc.visible = true;
        return new Promise<void>((resolve) => {
            createjs.Tween.get(this.root.btns_anim_mc)
                .to({ y: 400 }, 800)
                .call(() => {
                    this.enableEvents = true;
                    resolve();
                });
        });
    };

    closeBar = async () => {
        if (this.isBarOpen) {
            this.resetBarBtns();
            this.initBarBtns();
        }
        this.isBarOpen = false;
        return new Promise<void>((resolve) => {
            createjs.Tween.get(this.root.btns_anim_mc)
                .to({ y: 800 }, 500)
                .call(() => {
                    this.enableEvents = false;
                    resolve();
                });
        });
    };

    initBarBtns = () => {
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.gotoAndStop(0);
            btn.num_mc.all_num.gotoAndStop(i);
            btn.num_mc.all_num[`num${i}`].gotoAndStop(0);
            btn.mouseChildren = false;
            this.addBarBtnEvents(btn, i);
        }
    };

    addBarBtnEvents = async (mc: MC, index: number) => {
        this.barBtnyPos = mc.y;
        mc.cursor = "pointer";
        mc.removeAllEventListeners();
        mc.addEventListener("rollover", () => {
            if (!this.enableEvents) return;
            createjs.Tween.get(mc)
                .to({ y: mc.y - 10 }, 50, createjs.Ease.quadIn)
                .call(() => {});
        });
        mc.addEventListener("rollout", () => {
            if (!this.enableEvents) return;
            createjs.Tween.get(mc)
                .to({ y: this.barBtnyPos }, 50, createjs.Ease.quadIn)
                .call(() => {});
        });
        mc.addEventListener("click", () => {
            if (!this.enableEvents) return;
            mc.y = this.barBtnyPos;
            this.checkNumber(mc, index);
        });
    };

    initGame = () => {
        this.root.btns_anim_mc.visible = false;
        this.root.game_monkey_1.visible = false;
        this.root.game_monkey_2.visible = false;
        this.root.game_monkey_0.visible = true;
    };

    addMenuEvents = (mc: MC, event: MC) => {
        mc.anim_mc.gotoAndStop(0);
        mc.shadow_mc.gotoAndStop(0);
        mc.cursor = "pointer";
        mc.btn_mc.gotoAndStop(0);
        mc.addEventListener("rollover", async () => {
            stopAllSounds();
            mc.anim_mc.gotoAndPlay(1);
            mc.shadow_mc.gotoAndPlay(1);
            mc.btn_mc.gotoAndPlay(1);
            if (mc.name === "monkey_mc") {
                await loadSound("/math/sounds/monkeyGameMenu.wav");
                playSound("/math/sounds/monkeyGameMenu.wav");
            }
        });
        mc.addEventListener("click", () => {
            this.root.game_monkey_0.visible = false;
            this.root.game_monkey_1.visible = false;
            this.root.game_monkey_1.visible = false;
            event.visible = true;
            this.onInnerGame = true;
            this.gameType = mc.name === "pil_mc" ? "e" : "m";
            this.gameNumber = mc.name === "pil_mc" ? 1 : 2;
            Navigation.openPage(routes.math.monkey.replace(":param", this.gameNumber.toString()));

            this.root.game_monkey_2.monkey_mc.cad_mc_1.num_txt.text = null;
            this.root.game_monkey_1.monkey_mc.cad_mc_2.num_txt.visible = false;
            this.root.game_monkey_2.monkey_mc.cad_mc_1.num_txt.y = 37;
            this.root.game_monkey_1.monkey_mc.cad_mc_2.num_txt.y = 37;
            this.openSelector(true);
        });
    };
    monkeyStartCount = async () => {
        this.wrongAnswers = 0;
        const monkey = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        const levelArray = gamesByLevels[`game${this.gameNumber}Levels`][this.chosenLevel];
        const min = levelArray[0];
        const max = levelArray[1];
        this.startNuts = getRandomNumber(min, max);
        await loadSound(`/math/sounds/monkey/Monkey1_gues_${this.startNuts}nut.mp3`);
        monkey[`cad_mc_${this.gameNumber === 1 ? 2 : 1}`].num_txt.text = this.startNuts;
        if (this.gameNumber === 1) monkey.cad_mc_2.num_txt.visible = true;
        return this.animalTalk(monkey, `/math/sounds/monkey/Monkey1_gues_${this.startNuts}nut.mp3`);
    };

    getAnimal = () => {
        return this.root[`game_monkey_${this.gameNumber}`][this.gameNumber === 1 ? `monkey_mc` : "pil_mc"];
    };

    askExercise = async () => {
        const animal = this.getAnimal();
        await loadSound(`/math/sounds/monkey/monkey_game${this.gameNumber}_ques${this.chosenLevel}.mp3`);
        return this.animalTalk(animal, `/math/sounds/monkey/monkey_game${this.gameNumber}_ques${this.chosenLevel}.mp3`);
    };

    activateExercise = async () => {
        const countVals = addedNuts[this.chosenLevel];
        this.count = getRandomNumber(countVals[0], countVals[1]);
        const animal = this.getAnimal();
        if (this.gameNumber === 1) {
            animal.balut.x = 72 + 40 * Math.max(0, this.startNuts - 6);
        }
        for (let index = 1; index <= this.count; index++) {
            const { pil_mc } = this.root[`game_monkey_${this.gameNumber}`];
            pil_mc.gotoAndPlay("start");
            const { monkey_mc } = this.root[`game_monkey_${this.gameNumber}`];
            monkey_mc.gotoAndPlay("start");
            await this.cancelableWait(waitForEvent(this.root, "howmuch"));
            const nut = new this.root.lib.nutsToPlace();
            animal.balut.addChild(nut);
            const x = this.gameNumber === 1 ? 0 : -160;
            const y = this.gameNumber === 1 ? 0 : 130;
            nut.x = x + 40 * (index - 1);
            nut.y = y;
            if (index !== 1) {
                nut.gotoAndPlay(0);
            } else {
                nut.gotoAndStop("end");
            }
        }
        return this.count;
    };

    askResult = async () => {
        await loadSound(`/math/sounds/monkey/monkey_ques_howmuch.mp3`);
        const monkey = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        await this.animalTalk(monkey, `/math/sounds/monkey/monkey_ques_howmuch.mp3`);
        this.root[`game_monkey_${this.gameNumber}`].monkey_mc.head_mc.gotoAndStop(0);
        this.root.game_monkey_2.monkey_mc.cad_mc_1.num_txt.alpha = 0.5;
        this.root.game_monkey_2.monkey_mc.cad_mc_1.cross.visible = true;
        await this.openBar();
        this.enableHelp();
    };

    playGame = async () => {
        const { balutim_mc } = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        balutim_mc.gotoAndStop(0);
        this.resetAnimals();
        await this.monkeyStartCount();
        await this.askExercise();
        this.count = await this.activateExercise();
        await this.askResult();
    };

    disableBarBtns = () => {
        this.btnsDisabled = true;
        for (let i = 0; i <= 10; i++) {
            const btn = this.root.btns_anim_mc.btns_mc[`btn${i}_mc`];
            btn.removeAllEventListeners();
        }
    };

    handleGameCompleted = async () => {
        const monkey = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        monkey.gotoAndPlay("big_end");
        await waitForEvent(monkey, "onBigEndDone");
        this.goodResults = 0;
        this.cleanPrizes();
    };

    throwNuts = async (result: number) => {
        const { balutim_mc } = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        balutim_mc.x = -91 - 40 * Math.min(0, this.startNuts - 6);

        balutim_mc.gotoAndPlay("start");
        for (let index = 1; index <= result; index++) {
            waitForEvent(balutim_mc, `num${index}`).then(() => {
                balutim_mc[`balut${index}`].gotoAndPlay(1);
            });
        }
        return waitForEvent(balutim_mc, `num${result}`).then(() => {
            balutim_mc.stop();
        });
    };

    removeNuts = () => {
        const animal = this.getAnimal();
        animal.balut.removeAllChildren();
    };

    handleSuccess = async (btn: MC, result: number, fromHelper?: boolean) => {
        this.disableBarBtns();
        this.addPrize("prize_balut");
        btn.gotoAndStop("pressed");
        btn.num_mc.all_num[`num${result}`].gotoAndStop(1);
        this.root.game_monkey_2.monkey_mc.cad_mc_1.cross.visible = false;
        this.root.game_monkey_2.monkey_mc.cad_mc_1.num_txt.alpha = 1;
        this.disableBarBtns();
        if (!fromHelper) {
            this.sayGood();
            this.root[`game_monkey_${this.gameNumber}`].monkey_mc.gotoAndPlay("end");
            await waitForEvent(this.root, "balutim");
            const count = this.gameNumber === 1 ? this.startNuts : result;
            if (count > 0) {
                await this.throwNuts(count);
            } else {
                const { balutim_mc } = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
                balutim_mc.gotoAndStop(0);
            }
        } else {
            await this.sayGood();
        }

        this.closeBar();
        this.goodResults++;
        await loadSound(`/math/sounds/monkey/Monkey_end${result}.mp3`);
        const monkey = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        await this.animalTalk(monkey, `/math/sounds/monkey/Monkey_end${result}.mp3`);
        const { balutim_mc } = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        balutim_mc.gotoAndStop(0);
        this.removeNuts();
        if (this.goodResults === 5) {
            this.handleGameCompleted();
        } else {
            this.playGame();
        }
    };

    checkNumber = async (btn: MC, result: number) => {
        const baseCount = parseInt(
            this.root[`game_monkey_${this.gameNumber}`].monkey_mc[`cad_mc_${this.gameNumber === 1 ? 2 : 1}`].num_txt
                .text
        );
        const added = this.gameNumber === 2 ? this.count * -1 : this.count;
        if (baseCount + added === result) {
            this.disableHelp();
            this.handleSuccess(btn, result);
        } else {
            this.wrongAnswers++;
            if (this.wrongAnswers < 2) {
                this.soundsWrong();
                this.enableEvents = true;
            } else {
                this.showCorrect(baseCount + added);
            }
        }
    };
    soundsWrong = async () => {
        const num = getRandomNumber(1, 3);
        await loadSound(`/math/sounds/math/again${num}.mp3`);
        await playSound(`/math/sounds/math/again${num}.mp3`);
    };

    sayGood = async () => {
        const num = getRandomNumber(1, 9);
        return playSound(`m_good${num}`);
    };

    showCorrect = async (correct: number) => {
        const btn = this.root.btns_anim_mc.btns_mc[`btn${correct}_mc`];
        this.soundsWrong();
        btn.gotoAndStop("pressed");
        const correctButton = btn.num_mc.all_num[`num${correct}`];
        correctButton.gotoAndStop(1);
        this.disableBarBtns();
        const monkey = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        this.root[`game_monkey_${this.gameNumber}`].monkey_mc.gotoAndPlay("end");
        await waitForEvent(this.root, "balutim");
        this.animalTalk(monkey, `/math/sounds/monkey/monkey_ques_howmuch.mp3`);
        const { balutim_mc } = this.root[`game_monkey_${this.gameNumber}`].monkey_mc;
        const count = this.gameNumber === 1 ? this.startNuts : correct;
        if (count > 0) {
            await this.throwNuts(count);
        } else {
            balutim_mc.gotoAndStop(0);
        }
        btn.addEventListener("click", () => {
            this.disableHelp();
            this.handleSuccess(btn, correct, true);
        });
        this.enableEvents = true;
    };
    backToOuterMenu = () => {
        this.cleanPrizes(true);
        this.stopAnimation();
        this.initGame();
        this.addMenuEvents(this.root.pil_mc, this.root.game_monkey_1);
        this.addMenuEvents(this.root.monkey_mc, this.root.game_monkey_2);
        this.onInnerGame = false;
        this.root.btns_anim_mc.visible = false;
        Navigation.openPage(routes.math.monkey);
        this.initBarBtns();
    };

    loaded = () => {
        this.backToOuterMenu();
    };

    async cancelableWait(p: Promise<any>) {
        this.currentPromise = p as CancelablePromise<any>;
        await p;
        this.currentPromise = null;
    }
}
