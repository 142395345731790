import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MusicGameComponent } from "../musicGame/MusicGameComponent";
import { MusicSectionGame } from "../musicGame/MusicSectionGame";
import { Instruments } from "./Instruments";

export function InstrumentsGameComponent() {
    const game = React.useRef(new MusicSectionGame());
    return (
        <MusicGameComponent
            animationName="instruments"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Instruments(mc))}
            game={game}
            levels={0}
            shiftX={123}
            shiftY={110}
            scale={0.65}
        />
    );
}
