import { MC, playSound } from "../../animate/utils";
import { getRandomNumber } from "../../utils/MathUtils";

const CLOWN_WIDTH = 110;
const CLOWN_HEIGHT = 223;

const GIRL_WIDTH = 49;
const GIRL_HEIGHT = 169;

const GIRAFFE_WIDTH = 78;
const GIRAFFE_HEIGHT = 216;

const TRAMP_WIDTH = 125;
const TRAMP_HEIGHT = 36;
export class TrampolineGame {
    private root: MC;
    private actor: MC;
    private tramp: MC;

    private delta: number = 2;
    private up: number = 1.1;
    private manXPos: number;
    private direction = 0;
    private counter = 0;
    private hitSide: boolean = false;
    private shouldCheckIntersection: boolean = true; // guard to not check twice when cutting through character with trampoline
    constructor(private frame: any) {}

    loaded = (mc: createjs.MovieClip) => {
        this.root = mc;
        this.tramp = this.root.tramp;
        (window as any).trampoline = mc;
        (window as any).counter = this.frame.root.Bar.maincounter;
        this.frame.root.Bar.maincounter.counterValue.y = 16;
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            const pos = this.root.globalToLocal(
                this.frame.root.stage.mouseX,
                0
            );
            this.root.tramp.x = pos.x;
        });

        this.root.man.gotoAndStop(0);
        this.manXPos = this.root.man.x;
        this.selectActor();

        this.frame.hideBackground();

        this.start();
    };

    selectActor = () => {
        this.root.man.children.forEach((element: MC) => {
            element.visible = false;
        });
        this.actor = this.root.man.children[getRandomNumber(0, 2)];
        if (this.actor === this.root.man.clown) {
            this.root.man.clown.hat.gotoAndStop(Math.floor(Math.random() * 4));
        }
        this.actor.gotoAndPlay(10);
        this.root.man.y = 380;
        this.actor.visible = true;
        this.delta = 2;
        this.up = 1.1;
        this.counter = 0;
        this.frame.updateMainCounter(0);
    };

    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;

            this.root.man.y += this.delta;
            this.root.man.x += this.direction;
            if (
                (this.root.man.x - this.getWidth() / 2 < 0 ||
                    this.root.man.x + this.getWidth() / 2 > 800) &&
                !this.hitSide
            ) {
                this.direction = 0;
                this.hitSide = true;
            }
            this.delta *= this.up;
            if (this.delta > -1 && this.delta < 0) {
                //change direction, go down
                this.shouldCheckIntersection = true;
                this.delta = 2;
                this.up = 1.1;
                this.actor.gotoAndPlay(10);
            }
            if (this.shouldCheckIntersection && this.isIntersecting()) {
                //hit
                this.shouldCheckIntersection = false;
                playSound("trampHit");
                this.tramp.gotoAndPlay(1);
                this.getDirection();
                if (this.delta > 0) {
                    //first hit, start to go up
                    this.delta *= -1;
                    this.up = 1 / 1.1;

                    this.counter += 1;
                    this.frame.updateMainCounter(this.counter);
                    this.actor.gotoAndPlay(5);
                }
            } else if (this.root.man.y > 1000) {
                //fall
                this.direction = 0;
                this.root.man.x = this.manXPos;
                // playSound("trampFall");
                this.selectActor();
            }
        });
    };

    getDirection = () => {
        const dir = getRandomNumber(0, 3);
        if (this.hitSide) {
            this.direction = this.root.man.x - this.getWidth() / 2 < 0 ? 1 : -1;
            this.hitSide = false;
            return;
        }
        switch (dir) {
            case 0:
                this.direction = 1;
                break;
            case 1:
                this.direction = -1;
                break;
            case 2:
                this.direction = 2;
                break;
            case 3:
                this.direction = -2;
                break;
        }
    };

    isIntersecting = () => {
        const trampRect = new createjs.Rectangle(
            this.tramp.x - TRAMP_WIDTH / 2,
            this.tramp.y,
            TRAMP_WIDTH,
            TRAMP_HEIGHT
        );
        const manRect = new createjs.Rectangle(
            this.root.man.x - this.getWidth() / 2,
            this.root.man.y - this.getHeight(),
            this.getWidth(),
            this.getHeight()
        );
        return trampRect.intersects(manRect);
    };

    getWidth = () => {
        switch (this.actor) {
            case this.root.man.clown:
                return CLOWN_WIDTH;
            case this.root.man.girl:
                return GIRL_WIDTH;
            case this.root.man.giraffe:
                return GIRAFFE_WIDTH;
        }
    };

    getHeight = () => {
        switch (this.actor) {
            case this.root.man.clown:
                return CLOWN_HEIGHT;
            case this.root.man.girl:
                return GIRL_HEIGHT;
            case this.root.man.giraffe:
                return GIRAFFE_HEIGHT;
        }
    };
}
