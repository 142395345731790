import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { CurtainGame } from "./CurtainGame";

const CurtainComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new CurtainGame(frame));

    return <AnimateCC base="/lunapark/abc/curtain" animationName="curtain" onLoaded={game.current.loaded} />;
};
export default CurtainComponent;
