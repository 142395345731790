import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { StarGame } from "./StarGame";
import additionalSounds from "./StarGameSounds";
const StarComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new StarGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/star"
            animationName="star"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default StarComponent;
