import { MC, playSound, stopAllSounds } from "../../animate/utils";
import { Navigation } from "../../navigation/navigation";
import { routes } from "../../navigation/routesPath";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

export class BigWheelGame {
    private root: MC;
    private speed: number = 0;
    private selectedGame: string;

    constructor(private frame: LunaparkMenu) {}

    loaded = async (mc: createjs.MovieClip) => {
        (window as any).WheelGame = mc;
        (window as any).game = this;
        this.root = mc;
        console.log("loaded bigwheel");
        this.init();

        this.root.Bar.onetwothreebut.addEventListener("click", () => {
            this.exit();
            Navigation.openPage(routes.lunapark.wheel.menu);
        });
        this.root.Bar.out.addEventListener("click", () => {
            this.frame.exitGame();
        });
        this.root.Bar.againbut.addEventListener("click", () => {
            this.LoadGame(this.selectedGame);
        });
        this.start();
        this.addListeners();
    };
    init = () => {
        this.root.gotoAndStop(0);
        this.resetBar();
        this.root.big_wheel.gotoAndStop(1);
        this.root.big_wheel.children.forEach((child: MC, i: number) => {
            if (child.myno) {
                child.gotoAndStop(0);
                child.myno.gotoAndStop(i - 1);
            }
        });
    };
    start = () => {
        this.root.addEventListener("tick", (e: any) => {
            const localMouse = this.root.globalToLocal(this.root.stage.mouseX, this.root.stage.mouseY);
            this.speed = (localMouse.x - 400) / 200;
            this.root.big_wheel.rotation -= this.speed;
            this.root.big_wheel.children.forEach((child: MC, i: number) => {
                if (child.myno) {
                    child.rotation += this.speed;
                }
            });
        });
    };
    addListeners = () => {
        this.listeners(this.root.big_wheel.seat_1, routes.lunapark.wheel.one);
        this.listeners(this.root.big_wheel.seat_2, routes.lunapark.wheel.two);
        this.listeners(this.root.big_wheel.seat_3, routes.lunapark.wheel.three);
        this.listeners(this.root.big_wheel.seat_4, routes.lunapark.wheel.four);
        this.listeners(this.root.big_wheel.seat_5, routes.lunapark.wheel.five);
        this.listeners(this.root.big_wheel.seat_6, routes.lunapark.wheel.six);
        this.listeners(this.root.big_wheel.seat_7, routes.lunapark.wheel.seven);
        this.listeners(this.root.big_wheel.seat_8, routes.lunapark.wheel.eight);
        this.listeners(this.root.big_wheel.seat_9, routes.lunapark.wheel.nine);
        this.listeners(this.root.big_wheel.seat_10, routes.lunapark.wheel.ten);
    };
    listeners = (seat: MC, path: any) => {
        seat.addEventListener("click", () => {
            this.LoadGame(path);
            playSound("Prize3");
        });
    };

    LoadGame = (path: string) => {
        stopAllSounds();
        this.selectedGame = path;
        this.resetBar();
        this.root.gotoAndStop("gameBg");
        this.showBackground();
        Navigation.openPage(path);
    };

    toggleAgainbut = (val: boolean) => {
        this.root.Bar.againbut.visible = val;
    };
    toggleOnetwothreebut = (val: boolean) => {
        this.root.Bar.onetwothreebut.visible = val;
    };

    resetBar = () => {
        this.root.Bar.againbut.visible = false;
        this.root.Bar.onetwothreebut.visible = false;
    };
    hideBackground = () => {
        this.root.children.forEach((child: MC) => {
            if (child !== this.root.Bar) {
                child.visible = false;
            }
        });
    };
    showBackground = () => {
        this.root.children.forEach((child: MC) => {
            child.visible = true;
        });
    };

    private exit() {
        this.init();
        this.showBackground();
        stopAllSounds();
    }
}
