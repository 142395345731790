import React from "react";
import { MC } from "../../animate/utils";
import { getMayaFeedbackWithEffect } from "../../letters_island/common/feedBacksSounds";
import { MusicGameComponent } from "../musicGame/MusicGameComponent";
import { MusicSectionGame } from "../musicGame/MusicSectionGame";
import { Train } from "./Train";

export function TrainGameComponent() {
    const game = React.useRef(new MusicSectionGame());
    return (
        <MusicGameComponent
            animationName="train"
            additionalSounds={[...getMayaFeedbackWithEffect()]}
            onLoaded={(mc: MC) => game.current.gameLoaded(mc, new Train(mc))}
            levels={2}
            game={game}
            shiftX={105}
            shiftY={100}
        />
    );
}
