import { routes } from "./routesPath";
import { matchPath } from "react-router-dom";

function getLetterAdditionalParams(params: any) {
    return { nLetter: parseInt(params.letterIndex) + 1 + "" };
}

function getFancyAdditionalParams(params: any) {
    return { nLetter: parseInt(params.letterIndex) };
}

function getBiblePage(params: any) {
    let page = "";
    console.log("params", params);

    switch (params.type) {
        case "1":
            page = "creation";
            break;
        case "2":
            page = "eden";
            break;
        case "3":
            page = "ark";
            break;
        case "4":
            page = "tower";
            break;
    }
    return { sPage: page };
}

const LETTER_ACTIVITY_LESSON = {
    params: { sSection: "otiot", sPage: "lesson" },
    additionalParams: getLetterAdditionalParams,
};

const getLetterCreationAdditionalParams = (params: any) => {
    return { nLetter: parseInt(params.letterIndex) + 1 + "" };
};

const getZigiPage = (params: any) => {
    let page = "";
    switch (params.gameType) {
        case "numbers":
            page = "Numbers";
            break;
        case "verbs":
            page = "Verbs";
            break;
        case "colors":
            page = "Colors";
            break;
        case "nouns":
            page = "Nouns";
            break;
        case "face":
            page = "Face";
            break;
    }
    return { sPage: page };
};
const getDubyPage = (params: any) => {
    let room = "";
    let game = "";
    switch (params.roomType) {
        case "living":
            room = "livingroom";
            break;
        case "bed":
            room = "bedroom";
            break;
        case "kitchen":
            room = "kitchen";
            break;
        case "bath":
            room = "bathroom";
            break;
    }
    switch (params.gameType) {
        case "bee":
            game = "bee";
            break;
        case "whatin":
            game = "whatsin";
            break;
        case "build":
            game = "buildItems";
            break;
    }
    return { sPage: room + "~" + game };
};

const getPhonePage = (params: any) => {
    let page = "";
    switch (params.param) {
        case "1":
            page = "phone1";
            break;
        case "2":
            page = "phone1";
            break;
    }
    return { sPage: page };
};
const getMonkeyPage = (params: any) => {
    let page = "";
    switch (params.param) {
        case "1":
            page = "monkey";
            break;
        case "2":
            page = "monkey2";
            break;
    }
    return { sPage: page };
};

const MAPPING = [
    //lunapark
    { pathname: routes.lunapark.wheel.menu, params: { sPage: "helter-skelter", sSection: "LunaPark" } },
    { pathname: routes.lunapark.puppets, params: { sPage: "puppets", sSection: "LunaPark" } },
    { pathname: routes.lunapark.mirrors, params: { sPage: "mirrors", sSection: "LunaPark" } },
    { pathname: routes.lunapark.music_room, params: { sPage: "music", sSection: "LunaPark" } },
    { pathname: routes.lunapark.horses, params: { sPage: "merrygoround", sSection: "LunaPark" } },
    { pathname: routes.lunapark.balloons, params: { sPage: "cloun", sSection: "LunaPark" } },
    { pathname: routes.lunapark.abc.menu, params: { sPage: "abc", sSection: "LunaPark" } },
    { pathname: routes.lunapark.roller_coaster, params: { sPage: "rollercoaster", sSection: "LunaPark" } },
    { pathname: routes.lunapark.trampoline, params: { sPage: "trampoline", sSection: "LunaPark" } },
    { pathname: routes.lunapark.hammer, params: { sPage: "hammer", sSection: "LunaPark" } },
    { pathname: routes.lunapark.ghost_cave, params: { sPage: "train", sSection: "LunaPark" } },
    { pathname: routes.lunapark.sweets.menu, params: { sPage: "sweets", sSection: "LunaPark" } },
    { pathname: routes.lunapark.duck, params: { sPage: "duck", sSection: "LunaPark" } },
    { pathname: routes.lunapark.rocket.menu, params: { sPage: "puzzels", sSection: "LunaPark" } },
    {
        //lettersisland
        pathname: routes.letters.fancy_words.word,
        params: { sSection: "otiot", sPage: "fancy" },
        additionalParams: getFancyAdditionalParams,
    },
    {
        pathname: routes.letters.sweets,
        params: { sSection: "otiot", sPage: "icecream" },
    },
    {
        pathname: routes.letters.activities.fancy_word,
        params: { sSection: "otiot", sPage: "fancy" },
        additionalParams: getFancyAdditionalParams,
    },
    {
        pathname: routes.letters.activities.video,
        params: { sSection: "otiot", sPage: "video" },
        additionalParams: getLetterAdditionalParams,
    },
    {
        pathname: routes.letters.activities.enter_exit,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.draw,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.color,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.wood_boxes,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.children_in_boxes,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.crabs,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.find_letter,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.final_letter,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.final_draw,
        ...LETTER_ACTIVITY_LESSON,
    },
    {
        pathname: routes.letters.activities.creation,
        params: { sSection: "SingleWork", sPage: "MyWork" },
        additionalParams: getLetterCreationAdditionalParams,
    },
    {
        //moviesSection
        pathname: routes.movies,
        params: { sSection: "Video", sPage: "Movies" },
    }, //creation corner
    {
        pathname: routes.creation,
        params: { sSection: "SingleWork", sPage: "MyWork", nLetter: "" },
    }, //bible stories
    {
        pathname: routes.bible_stories.story_menu.menu,
        params: { sSection: "Bible" },
        additionalParams: getBiblePage,
    },
    //zigi english
    {
        pathname: routes.english_time.game,
        params: { sSection: "EnglishTime" },
        additionalParams: getZigiPage,
    },
    //dubby english
    {
        //must be before menu
        pathname: routes.dubby_english.game,
        params: { sSection: "EnglishDubi" },
        additionalParams: getDubyPage,
    },
    {
        pathname: routes.dubby_english.menu,
        params: { sSection: "EnglishDubi", sPage: "flashLoaded" },
    },
    //math
    {
        pathname: routes.math.wizard,
        params: { sSection: "math", sPage: "hat" },
    },
    {
        pathname: routes.math.phone,
        params: { sSection: "math" },
        additionalParams: getPhonePage,
    },
    {
        pathname: routes.math.hippo,
        params: { sSection: "math", sPage: "hipo" },
    },
    {
        pathname: routes.math.cars,
        params: { sSection: "math", sPage: "cars" },
    },
    {
        pathname: routes.math.aqua,
        params: { sSection: "math", sPage: "aqua" },
    },
    {
        pathname: routes.math.ducks,
        params: { sSection: "math", sPage: "ducks" },
    },
    {
        pathname: routes.math.frogs,
        params: { sSection: "math", sPage: "frogs" },
    },
    {
        pathname: routes.math.monkey,
        params: { sSection: "math" },
        additionalParams: getMonkeyPage,
    },
    {
        pathname: routes.math.swing,
        params: { sSection: "math", sPage: "swing" },
    },

    //music
    {
        pathname: routes.music.simon,
        params: { sSection: "Music", sPage: "simon" },
    },
    {
        pathname: routes.music.memory,
        params: { sSection: "Music", sPage: "memory" },
    },
    {
        pathname: routes.music.train,
        params: { sSection: "Music", sPage: "songstrain" },
    },
    {
        pathname: routes.music.instruments,
        params: { sSection: "Music", sPage: "instruments" },
    },
    {
        pathname: routes.music.chickens,
        params: { sSection: "Music", sPage: "chickens" },
    },
];

export function mapPathToStatisticsParams(path: string): any {
    for (let i = 0; i < MAPPING.length; i++) {
        const mappingElement = MAPPING[i];
        const match = matchPath<{ letterIndex: string }>(path, mappingElement.pathname);
        if (match) {
            const additionalParams = mappingElement.additionalParams
                ? mappingElement.additionalParams(match.params)
                : {};
            return { ...mappingElement.params, ...additionalParams };
        }
    }
    return null;
}
