import { MC, playSound, setupStage } from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { RevealGame } from "../RevealGame";

const SOUND = "aqua";
const NUM_PIECES = 20;
const NUM_COVERS = 5;
export class UnderwaterGame {
    private root: MC;
    private game: RevealGame;
    private tweens: any[] = [];
    private animationsEnded: number = 0;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).game = this.root;
        (window as any).frame = this.frame.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.initBubblePositions();
        this.game = new RevealGame(
            this.root,
            this.onSuccess,
            SOUND,
            NUM_PIECES,
            NUM_COVERS
        );
        this.game.start();
    };
    unload = () => {
        this.game && this.game.unload();
    };

    initBubblePositions = () => {
        this.root.bubble1.y = 500;
        this.root.bubble2.y = 504;
        this.root.bubble3.y = 573;
        this.root.bubble4.y = 600;
        this.root.bubble5.y = 700;
    };

    onSuccess = () => {
        this.root.gotoAndStop(2);
        this.initBubblePositions();
        playSound("end");
        this.animationsEnded = 0;
        for (let i = 1; i <= 5; i++) {
            const bubble = this.root["bubble" + i];
            bubble.x = getRandomNumber(0, 400) + 200;
            bubble.scale = getRandomNumber(25, 100) / 100;
            createjs.Tween.get(bubble)
                .to(
                    {
                        y: 0,
                    },
                    3000
                )
                .call(() => {
                    this.animationsEnded += 1;
                    if (this.animationsEnded === 5) {
                        this.restart();
                    }
                });
        }
    };
    restart = async () => {
        await createjs.Tween.removeAllTweens();
        this.root.gotoAndStop(0);
        this.game.start();
    };
}
