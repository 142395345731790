import React, { useState } from "react";
import AnimateCC from "../../../animate";
import { MC } from "../../../animate/utils";
import { PreloaderComponent } from "../../../loaders/PreloaderComponent";
import { SimonGame } from "./SimonGame";

const SimonWeekGameComponent: React.FC<{}> = () => {
    const game = React.useRef(new SimonGame());
    const [screenReady, setScreenReady] = useState(false);

    return (
        <>
            {!screenReady && <PreloaderComponent name="plain" />}

            <AnimateCC
                base="/week_games/simon_game"
                animationName="simon_game"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                    setScreenReady(true);
                }}
                scale={0.75}
                shiftY={90}
                shiftX={100}
            />
        </>
    );
};
export default SimonWeekGameComponent;
