import { MC, localToGlobal } from "../../animate/utils";
import { DANGLE_Y, DIGITS, LETTERS, TEXT_INIT_SCALE, TEXT_INIT_SIZE } from "../utils/constants";
import { CreationGame } from "../CreationGame";
import { creationTooltip } from "../utils/creationTooltip";
import * as TextFunctions from "../utils/TextFunctions";

export class LettersStore {
    constructor(private root: MC, private creationGame: CreationGame) {
        this.start();
    }
    start = () => {
        this.root.keyboardBG.mouseEnabled = false;
        for (let i = 1; i <= 27; i++) {
            creationTooltip.addToolTip(
                this.root.keyboard["letter_" + i + "_hit"],
                `/creation_corner/sounds/letters/letter${i}.mp3`
            );
            this.root.keyboard["letter_" + i + "_hit"].addEventListener("click", () => {
                const text = LETTERS[i - 1];
                this.newText(i, text, "letter_");
            });
        }
        for (let i = 0; i <= 9; i++) {
            creationTooltip.addToolTip(
                this.root.keyboard["num_" + i + "_hit"],
                `/creation_corner/sounds/digit/digit${i}.mp3`
            );
            this.root.keyboard["num_" + i + "_hit"].addEventListener("click", () => {
                console.log("keyBoard");
                const text = DIGITS[i];
                this.newText(i, text, "num_");
            });
        }
    };

    private newText(i: number, text: string, type: string) {
        const position = localToGlobal(this.root.keyboard[type + i + "_hit"], 0, 0);
        const textY = position.y + TEXT_INIT_SIZE - DANGLE_Y;
        const textX = position.x + 27;
        let inputType;
        if (type === "num_") {
            inputType = 3;
        } else {
            inputType = 2;
        }
        TextFunctions.generateStaticInput(textX, textY, text, TEXT_INIT_SCALE, this.creationGame, inputType);
        this.creationGame.selectNewEdit(this.root.btnDrag);
        this.creationGame.deactivateKeyBoard();
    }
}
