import React from "react";
import AnimateCC from "../../../animate";
import { AbcMenu } from "../AbcMenu";
import { TigerGame } from "./TigerGame";
import { additionalSounds } from "./TigerSounds";
const TigerComponent: React.FC<{
    frame: AbcMenu;
}> = ({ frame }) => {
    const game = React.useRef(new TigerGame(frame));

    return (
        <AnimateCC
            base="/lunapark/abc/tiger"
            animationName="tiger"
            onLoaded={game.current.loaded}
            additionalSounds={additionalSounds}
            changeFramerate={true}
        />
    );
};
export default TigerComponent;
