import { loadSound } from "../../../animate/sound_utils";
import {
    getBeginLetter,
    getLetter,
    getLetterIndex,
    loadFont,
    MC,
    playSound,
    playSoundSync,
    stopAllSounds,
    waitForEvent,
    waitForTimer,
} from "../../../animate/utils";
import { getRandomNumber } from "../../../utils/MathUtils";
import { SoundToolTip } from "../../../utils/soundTooltip";
import { getLetterForIndex, randomObjectToBeginLetter, randomObjectToEndLetter } from "../jsonFileFunction";
import { ReviewGameBasic } from "../ReviewGameBasic";

const COLOR_ARR = ["#000000", "#FA9FC1", "#E91720", "#F68125", "#FFE42F", "#00CC2E", "#3EA5FF", "#9902FF"];
export class Penguin extends ReviewGameBasic {
    bgMenuSound: any = null;
    keys: Array<any> = [];
    numOfLetters: number = 27;
    curWord: any;
    curObject: any;
    gameMode: number = 1;
    lettersArr: any = [];
    wordsArr: any = [];
    picsArr: any = [];

    lettersLeft: number = 5;
    correctAnswers: number = 0;
    wrongCount = 0;
    lettersIndex = [
        [1, 4, 1, 4],
        [5, 8, 1, 4],
        [9, 12, 1, 8],
        [13, 17, 1, 12],
        [18, 22, 1, 17],
    ];
    emptyIndex: number = 0;
    wordIndex: number = 0;
    gameSoundPath: string;
    gameSound: any;
    bgSound: any = null;
    menuInst: any = null;
    objSoundUrl: string = null;
    prevObj: any = null;
    playing: boolean = false;
    epilogShowing: boolean = false;
    boxOriginalX: number;
    boxDestinationX: number;
    firstChoiceArr: any = [];
    secondChoiceArr: any = [];
    hovering: boolean = false;
    currentMenu: any;
    currentIntro: any;
    setAnimSound: boolean = true;
    endOfGame: boolean = false;
    letterClicked: boolean = false;
    overWord: boolean = false;
    overColor: boolean = false;
    prevPainter: MC = null;
    painterIndex: number = 2;
    textEpilogChildren: any[] = [];
    setShouldPrint: (val: boolean) => void;
    textColors: string[] = ["#055C8F", "#055C8F", "#055C8F", "#055C8F", "#055C8F"];

    constructor(
        public root: MC,
        private sectionIndex: any,
        private lettersContext: any,
        setShouldPrint: (val: boolean) => void
    ) {
        super();
        this.prevPainter = this.root.curPaint;
        this.setShouldPrint = setShouldPrint;
        this.loaded();
        (window as any).peng = root;
    }

    startBoard = () => {
        for (var i = 0; i < this.numOfLetters; i++) {
            let key = this.root[`letter${i + 1}`];
            key.visible = true;
            key.textbox.text = getLetter(i);
            key.textbox.textAlign = "center";
            key.scale = 1.1;
        }
        this.disableInactiveKeys();
    };

    enableKeyBoardEvents = () => {
        this.soundPlayed = false;
        for (var i = 0; i < this.numOfLetters; i++) {
            const letter = this.root[`letter${i + 1}`];
            this.clickKeyboardEvent(letter);
            const letterTxt = getLetterForIndex(getLetter(i));
            loadSound(`/letters_island/common/letters/${letterTxt}.mp3`);
            SoundToolTip.addToolTip(letter, `/letters_island/common/letters/${letterTxt}.mp3`);
            letter.cursor = "pointer";
        }
    };

    disableKeyBoardEvents = () => {
        for (var i = 0; i < this.numOfLetters; i++) {
            const letter = this.root[`letter${i + 1}`];
            letter.removeAllEventListeners();
            // SoundToolTip.addToolTip(letter, "");
            letter.cursor = "default";
        }
    };

    hideBoard = () => {
        for (var i = 0; i < this.numOfLetters; i++) {
            this.root[`letter${i + 1}`].visible = false;
            this.root[`letter${i + 1}`].removeAllEventListeners();
        }
    };
    disableKey = (i: number) => {
        this.root[`letter${i + 1}`].mouseEnabled = false;
        this.root[`letter${i + 1}`].alpha = 0.4;
    };

    disableInactiveKeys = () => {
        const arr = [11, 13, 14, 17, 18];
        switch (this.gameMode) {
            case 1:
                for (let i = 22; i < this.numOfLetters; i++) {
                    this.disableKey(i);
                }
                break;
            case 2:
                for (let i = 0; i < arr.length; i++) {
                    this.disableKey(arr[i] - 1);
                }
                break;
        }
    };

    enableAllKeys = () => {
        for (var i = 0; i < this.numOfLetters; i++) {
            this.root[`letter${i + 1}`].mouseEnabled = true;
            this.root[`letter${i + 1}`].alpha = 1;
        }
    };

    startBoxes = () => {
        let box;
        this.positionBoxes();
        for (let i = 0; i < this.curWord.length; i++) {
            box = this.root.boxes[`let${i + 1}`];
            box.visible = true;
            box.wordText.visible = true;
            if (i === this.emptyIndex || (this.gameMode === 3 && i === 0)) {
                box.gotoAndStop("current");
            } else {
                box.wordText.text = this.curWord.charAt(i);
                box.gotoAndStop("filled");
            }
            if (this.gameMode === 3 && i > 0) {
                box.gotoAndStop("empty");
            }
        }
        if (this.curWord.length < 5) {
            for (let i = this.curWord.length + 1; i < 6; i++) {
                box = this.root.boxes[`let${i}`];
                box.visible = false;
            }
        }
        this.animateBoxesIn();
    };

    positionBoxes = () => {
        const boxNum = this.curWord.length;
        const boxW = 80;
        const marginLeft = (800 - boxW * boxNum) / 2;
        this.boxOriginalX = this.root.boxes.x;
        this.boxDestinationX = 780 - marginLeft;
    };

    returnBoxesToOriginalPosition = () => {
        this.root.boxes.x = this.boxOriginalX;
    };

    animateBoxesIn = () => {
        this.root.picBox.removeAllEventListeners();
        this.root.boxes.x = 800;
        this.root.penguin.x = 820;
        this.root.boxes.visible = true;
        this.root.penguin.visible = true;
        if (this.setAnimSound) {
            this.bgSound = playSoundSync("animationBG", { loop: -1 });
        }
        createjs.Tween.get(this.root.boxes).to(
            {
                x: this.boxDestinationX,
            },
            1500
        );
        this.root.penguin.gotoAndPlay(0);

        createjs.Tween.get(this.root.penguin)
            .to(
                {
                    x: this.boxDestinationX + 28,
                },
                1500
            )
            .call(async () => {
                this.enableButtons(["reset"]);
                this.root.penguin.gotoAndStop(29);
                this.enableKeyBoardEvents();
                await waitForTimer(500);
                this.root.penguin.visible = false;
                this.root.penguin.gotoAndPlay(0);
                this.root.penguinB.x = this.root.penguin.x;
                this.root.penguinB.visible = true;
                this.root.penguinB.gotoAndPlay(14);
                createjs.Tween.get(this.root.penguinB)
                    .to(
                        {
                            x: 800,
                        },
                        1300
                    )
                    .call(() => {
                        this.bgSound.stop();
                        SoundToolTip.addToolTip(this.root.picBox, this.objSoundUrl);
                    });
            });
    };

    animateBoxesOut = () => {
        this.root.picBox.removeAllEventListeners();
        // this.root.picBox.
        this.root.penguin.x = 820;
        this.root.penguin.visible = true;
        this.root.penguin.gotoAndPlay(0);
        return new Promise<void>((resolve) => {
            createjs.Tween.get(this.root.penguin)
                .to(
                    {
                        x: this.boxDestinationX + 28,
                    },
                    1500
                )
                .call(async () => {
                    this.root.penguin.gotoAndStop(29);
                    await waitForTimer(300);
                    this.root.penguin.gotoAndPlay(0);
                    this.animateOut(this.root.boxes);
                    await this.animateOut(this.root.penguin);
                    this.root.boxes.visible = false;
                    resolve();
                });
        });
    };

    animateOut = (obj: MC) => {
        return new Promise((resolve) => {
            createjs.Tween.get(obj)
                .to(
                    {
                        x: 0,
                    },
                    1500
                )
                .call(resolve);
        });
    };

    nextBox = () => {
        this.resetError();
        this.enableKeyBoardEvents();
        const box = this.root.boxes[`let${this.wordIndex + 1}`];
        box.gotoAndStop("current");
    };

    findObjectForLetter = async (letterIndex: number) => {
        do {
            if (this.gameMode === 2) {
                this.curObject = randomObjectToEndLetter(`${letterIndex}`);
            } else {
                this.curObject = randomObjectToBeginLetter(`${letterIndex}`);
            }
        } while (this.curObject === this.prevObj);
        this.curWord = this.curObject.sText;
        this.prevObj = this.curObject;
        switch (this.gameMode) {
            case 1:
                this.emptyIndex = 0;
                break;
            case 2:
                this.emptyIndex = this.curWord.length - 1;
                break;
        }
        this.objSoundUrl = `/letters_island/common/objects/${this.curObject.sID}.mp3`;
        await loadSound(this.objSoundUrl);
        await this.showObject();
        this.startBoxes();
    };

    showObject = async () => {
        this.disableKeyBoardEvents();
        this.root.picBox.pic.gotoAndStop(this.curObject.sID);
        this.objSoundUrl && playSound(this.objSoundUrl);
    };
    isRight = (key: MC) => {
        if (this.gameMode === 3) {
            return key.textbox.text === this.curWord.charAt(this.wordIndex);
        }
        return key.textbox.text === this.curWord.charAt(this.emptyIndex);
    };
    isComplete = () => {
        return this.gameMode !== 3 || this.wordIndex === this.curWord.length;
    };
    clickKeyboardEvent = (key: MC) => {
        key.addEventListener("click", async () => {
            if (this.letterClicked === false) {
                this.letterClicked = true;
                await playSound("keyClick");

                this.disableKeyBoardEvents();
                if (this.isRight(key)) {
                    if (this.gameMode === 2 || this.gameMode === 1) {
                        this.root.picBox.removeAllEventListeners();
                    }
                    const box = this.root.boxes[`let${this.gameMode === 3 ? this.wordIndex + 1 : this.emptyIndex + 1}`];
                    box.wordText.text = key.textbox.text;
                    box.gotoAndStop("correct");
                    await playSound("good");
                    box.gotoAndStop("filled");
                    this.wordIndex++;

                    if (this.isComplete()) {
                        if (this.gameMode === 3) {
                            this.root.picBox.removeAllEventListeners();
                        }
                        this.removeLetterFromArray(this.gameMode === 3 ? this.curWord.charAt(0) : key.textbox.text);
                        this.correctAnswers++;
                        this.wordsArr.push(this.curWord);
                        this.picsArr.push(this.curObject.sID);
                        this.soundPlayed = true;
                        this.showPrize();

                        await playSound(this.objSoundUrl);

                        await playSound(`Good${getRandomNumber(1, 10)}`);

                        this.resetError();
                        if (this.correctAnswers === 5) {
                            this.bgSound = playSoundSync("animationBG", {
                                loop: -1,
                            });
                            await this.animateBoxesOut();
                            this.soundPlayed = false;
                            stopAllSounds();
                            this.endGame();
                        } else {
                            this.wordIndex = 0;
                            this.loadAnotherWord();
                        }
                    } else {
                        this.nextBox();
                    }
                } else {
                    const index = this.gameMode === 3 ? this.wordIndex : this.emptyIndex;
                    this.wrongAnswer(index, key, this.curWord.charAt(index));
                }
                this.letterClicked = false;
            }
        });
    };

    loadAnotherWord = async () => {
        this.bgSound = playSoundSync("animationBG", {
            loop: -1,
        });
        this.setAnimSound = false;
        await this.animateBoxesOut();
        this.returnBoxesToOriginalPosition();
        this.startPlay();
    };

    wrongAnswer = async (index: number, key: MC, correctAns: string) => {
        this.wrongCount++;
        const box = this.root.boxes[`let${index + 1}`];
        box.wordText.text = key.textbox.text;
        box.gotoAndStop("wrong");
        await playSound("bad");
        box.gotoAndStop("current");
        this.enableKeyBoardEvents();
        if (this.wrongCount === 2) {
            this.wrongCount = 0;
            for (let i = 0; i < this.numOfLetters; i++) {
                if (i !== getLetterIndex(correctAns)) {
                    this.disableKey(i);
                }
            }
            await playSound(`Bad${getRandomNumber(0, 2)}`);
        } else {
            if (this.wrongCount > 2) {
                console.error("wrong count is larger than 2");
            }
            await playSound(this.curObject.sID);
        }
    };

    removeLetterFromArray(letter: string) {
        let letterIndex = getLetterIndex(letter) + 1;
        if (letterIndex > 22) {
            let beginLetter = getBeginLetter(letter);
            letterIndex = getLetterIndex(beginLetter) + 1;
        }
        let index = this.lettersArr.indexOf(letterIndex);
        this.lettersArr.splice(index, 1);
        this.lettersLeft--;
    }

    showPrize = () => {
        this.disableButtons(["reset"]);
        this.root[`prize${this.correctAnswers - 1}`].visible = true;
    };

    initLetterArr = () => {
        let letterIndex;
        let randIndex;
        for (let index = 0; index < 3; index++) {
            do {
                randIndex = getRandomNumber(0, this.firstChoiceArr.length - 1);
                letterIndex = this.firstChoiceArr[randIndex];
            } while (this.lettersArr.includes(letterIndex));
            this.lettersArr.push(letterIndex);
        }

        for (let index = 0; index < 2; index++) {
            do {
                randIndex = getRandomNumber(0, this.secondChoiceArr.length - 1);
                letterIndex = this.secondChoiceArr[randIndex];
            } while (this.lettersArr.includes(letterIndex));
            this.lettersArr.push(letterIndex);
        }
    };

    arrangeGroup = () => {
        if (this.sectionIndex != null) {
            if (this.sectionIndex === 4) {
                switch (this.gameMode) {
                    case 1:
                        this.firstChoiceArr = [13, 14, 15, 16, 17];
                        this.secondChoiceArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
                        break;
                    case 2:
                        this.firstChoiceArr = [15, 13, 14, 18];
                        this.secondChoiceArr = [2, 3, 4, 7, 8, 9, 11, 12];
                        break;
                    case 3:
                        this.firstChoiceArr = [15, 13, 14, 17];
                        this.secondChoiceArr = [2, 3, 4, 7, 8, 9, 11, 12];
                        break;
                }
            } else {
                switch (this.gameMode) {
                    case 1:
                        this.firstChoiceArr = [18, 19, 20, 21, 22];
                        this.secondChoiceArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
                        break;
                    case 2:
                        this.firstChoiceArr = [18, 19, 20, 21, 22];
                        this.secondChoiceArr = [2, 3, 4, 7, 8, 9, 11, 12, 13, 14, 15, 17];
                        break;
                    case 3:
                        this.firstChoiceArr = [18, 19, 20, 21, 22];
                        this.secondChoiceArr = [2, 3, 4, 7, 8, 9, 11, 12, 13, 14, 15, 17];
                        break;
                }
            }
        }
        this.initLetterArr();
    };

    randomLetter = () => {
        let letterIndex;
        letterIndex = getRandomNumber(0, this.lettersLeft - 1);
        return this.lettersArr[letterIndex];
    };

    resetError = () => {
        this.wrongCount = 0;
        this.enableAllKeys();
        this.disableInactiveKeys();
    };

    startPlay = () => {
        this.playing = true;
        this.endOfGame = false;
        this.letterClicked = false;
        this.wordIndex = 0;
        let letter = this.randomLetter();
        this.findObjectForLetter(letter);
    };

    resetProps = () => {
        for (let i = 0; i < this.correctAnswers; i++) {
            this.root[`prize${i}`].visible = false;
        }
        this.objSoundUrl = null;
        this.correctAnswers = 0;
        this.wrongCount = 0;
        this.lettersArr = [];
        this.initLetterArr();
        this.lettersLeft = 5;
        this.hovering = false;
        this.root.penguin.visible = false;
        this.root.penguinB.visible = false;
        this.root.boxes.visible = false;
        this.root.picBox.pic.gotoAndStop(0);
        // this.root.picBox.pic.cursor = "pointer";
        this.hideAndRemoveTweens();
        this.enableAllKeys();
        stopAllSounds();
        this.setAnimSound = true;
        this.playing = false;
        // this.endOfGame = false;
        this.letterClicked = false;
    };

    reset = () => {
        this.resetProps();
        this.resetEpilogProps();
        this.startPlay();
    };
    endGame = async () => {
        this.endOfGame = true;
        this.playing = false;
        this.root.picBox.pic.gotoAndStop(0);
        this.root.boxes.visible = false;
        this.root.penguin.visible = false;
        const game = `40${this.gameMode}`;
        this.lettersContext.setGameStatus(this.sectionIndex, game);
        // setReviewGameCompleted(this.sectionIndex, game);
        await waitForTimer(1000);
        await playSound("end");
        this.root.picBox.visible = false;
        this.hideBoard();
        this.resetProps();
        this.showEpilog();
    };
    epilogMouse = (painter: MC) => {
        painter.visible = true;
        painter.mouseEnabled = false;
        this.root.addEventListener("tick", (e: any) => {
            if ((e as createjs.TickerEvent).paused) return;
            if (this.overWord || this.overColor) {
                this.root.cursor = "none";
                this.prevPainter.visible = false;
                this.prevPainter = painter;
                painter.visible = true;
                const parent: createjs.DisplayObject = this.root;
                const position = parent.globalToLocal(parent.stage.mouseX, parent.stage.mouseY);
                painter.x = position.x;
                painter.y = position.y;
            } else {
                painter.visible = false;
                this.root.cursor = "default";
            }
        });
    };

    setEpilogWords = () => {
        for (let i = 0; i < 5; i++) {
            const word = this.root.words[`w${i + 1}`];
            word.visible = true;
            const text = new createjs.Text(this.wordsArr[i], "bold 45px 'ProntoMF' ", "#055C8F");
            text.textAlign = "center";

            word.addChild(text);
            this.textEpilogChildren.push(text);
            const hit = new createjs.Shape();
            hit.graphics.beginFill("#000").drawRect(-80, -10, text.getMeasuredWidth() + 60, 60);
            word.hitArea = hit;
            word.addEventListener("click", () => {
                playSound("keyClick");
                text.color = COLOR_ARR[this.painterIndex];
                this.textColors[i] = COLOR_ARR[this.painterIndex];
            });
            word.addEventListener("rollover", () => {
                this.overWord = true;
            });
            word.addEventListener("rollout", () => {
                this.overWord = false;
            });
            this.root.words[`pic${i + 1}`].gotoAndStop(this.picsArr[i]);
        }
    };

    setEpilogColorBar = () => {
        for (let i = 0; i < 8; i++) {
            const color = this.root.colorsBar[`color${i + 1}`];
            color.visible = true;
            color.hit.alpha = 0;
            color.addEventListener("click", () => {
                playSound("keyClick");
                this.epilogMouse(this.root[`bucket${i + 1}`]);
                this.painterIndex = i;
            });
            color.addEventListener("rollover", () => {
                this.overColor = true;
            });
            color.addEventListener("rollout", () => {
                this.overColor = false;
            });
        }
    };

    showEpilog = async () => {
        this.menuInst = playSoundSync("epilogIntro");
        this.epilogShowing = true;
        this.root.words.visible = true;
        this.setEpilogPrinter();
        this.root.goon.visible = true;
        SoundToolTip.addToolTip(this.root.goon, "backToGame");
        this.root.goon.game3.mouseEnabled = false;
        this.root.goon.addEventListener("click", () => {
            stopAllSounds();
            this.root.goon.gotoAndStop(1);
            this.setAlertBox(this.root.AlertBox, this.initGame, this.sectionIndex);
            this.root.AlertBox.visible = true;
        });
        this.setEpilogWords();
        this.root.colorsBar.visible = true;
        this.setEpilogColorBar();
        this.epilogMouse(this.root.bucket3);
    };

    setEpilogPrinter = () => {
        this.root.printer.visible = true; //--DO NOT ERASE IMPORTANT FOR PRINT
        this.root.printer.addEventListener("click", () => {
            this.printCreation();
        });
        this.root.printer.print_icon.cursor = "pointer";
        this.root.printer.print_icon.addEventListener("rollover", () => {
            this.root.printer.btn.gotoAndStop(1);
        });
        this.root.printer.print_icon.addEventListener("rollout", () => {
            this.root.printer.btn.gotoAndStop(0);
        });
        SoundToolTip.addToolTip(this.root.printer, "print"); //--DO NOT ERASE IMPORTANT FOR PRINT
    };
    printCreation = () => {
        this.setShouldPrint(true);
        // window.print();
    };

    initFrame = async () => {
        this.root.children.forEach((child: MC) => {
            if (child) child.visible = false;
        });

        this.root.frame.visible = true;
        this.root.LoadBlock.visible = true;
        this.root.LoadBlock.giraffe.visible = true;
        this.root.BGStationed.visible = true;
        this.root.LoadBlock.giraffe.gotoAndPlay(0);
        await waitForEvent(this.root.LoadBlock, "giraffeAnimationEnd");

        this.root.LoadBlock.gotoAndPlay(19);
        await waitForEvent(this.root.LoadBlock, "animationEnd");
        switch (this.sectionIndex) {
            case 4:
                this.currentMenu = this.root.menu2;
                this.currentIntro = this.root.intro2;
                this.initMenu(2, true);

                break;
            case 5:
                this.currentMenu = this.root.menu;
                this.currentIntro = this.root.intro;
                this.initMenu(3, true);
                break;
        }
    };

    async animateMenu(numOfGames: number) {
        let i = 0;
        let dir = 1;
        do {
            let game = this.currentMenu[`game${i + 1}`];
            game.gotoAndStop(1);
            await waitForTimer(500);
            game.gotoAndStop(0);
            await waitForTimer(100);
            if (i === numOfGames - 1 && dir === 1) {
                dir = 0;
            } else {
                if (i === 0 && dir === 0) dir = 1;
            }
            if (dir === 1) {
                i++;
            } else {
                i--;
            }
        } while (!this.playing && !this.hovering);
    }

    initMenu = async (numOfGames: number, playGeneral: boolean) => {
        this.currentIntro.visible = true;
        this.currentMenu.visible = true;
        this.initButtons(this.root, this.reset, "menuInst", this.sectionIndex, null);
        this.disableButtons(["reset", "help"]);
        for (let i = 0; i < numOfGames; i++) {
            let game = this.currentMenu[`game${i + 1}`];
            game.gotoAndStop(0);
            game.addEventListener("click", () => {
                this.disableButtons(["reset", "exit", "help"]);
                stopAllSounds();
                this.gameClickEvent(game);
                this.initGame();
            });
            game.addEventListener("rollover", () => {
                this.hovering = true;
                game.gotoAndStop(1);
            });
            game.addEventListener("rollout", () => {
                game.gotoAndStop(0);
            });
            game.cursor = "pointer";
        }
        this.soundPlayed = true;
        if (playGeneral) {
            await playSound("gameGeneral");
        }
        this.menuInst = playSoundSync("menuInst");
        this.menuInst.on("complete", () => {
            this.soundPlayed = false;
            if (this.menuInst) {
                this.bgMenuSound = playSoundSync("menuBG", { loop: -1 });
                this.bgMenuSound.volume = 0.8;
                this.animateMenu(numOfGames);
                this.enableButtons(["help"]);
            }
        });
    };

    gameClickEvent = (game: MC) => {
        switch (game.name) {
            case "game1":
                this.gameMode = 1;
                this.gameSoundPath = "game1inst";
                break;
            case "game2":
                this.gameMode = 2;
                this.gameSoundPath = "game2inst";
                break;
            case "game3":
                this.gameMode = 3;
                this.gameSoundPath = "game3inst";
                break;
        }
    };

    resetEpilogProps = () => {
        this.overWord = false;
        this.overColor = false;
        this.prevPainter = this.root.bucket2;
        this.painterIndex = 2;
        this.wordsArr = [];
        this.picsArr = [];
        for (let i = 0; i < 5; i++) {
            const word = this.root.words[`w${i + 1}`];
            word.removeChild(this.textEpilogChildren[i]);
        }
        this.textEpilogChildren = [];
        this.root.goon.removeAllEventListeners();
    };

    hideEpilog = () => {
        this.resetEpilogProps();
        this.epilogShowing = false;
        this.root.words.visible = false;
        this.root.printer.visible = false;
        this.root.goon.visible = false;
        this.root.colorsBar.visible = false;
    };

    removeListeners = () => {
        const numOfGames = this.sectionIndex - 2;
        for (let i = 0; i < numOfGames; i++) {
            let game = this.currentMenu[`game${i + 1}`];
            game.removeAllEventListeners();
        }
    };

    hideAndRemoveTweens = () => {
        this.root.boxes.visible = false;
        this.root.penguin.visible = false;
        this.root.penguinB.visible = false;
        createjs.Tween.removeTweens(this.root.penguin);
        createjs.Tween.removeTweens(this.root.penguinB);
        createjs.Tween.removeTweens(this.root.boxes);
    };
    backFunc = () => {
        this.root.picBox.visible = false;
        stopAllSounds();
        this.hideAndRemoveTweens();
        this.hideBoard();
        this.removeListeners();
        this.resetProps();
        this.resetEpilogProps();
        this.lettersArr = [];
        this.correctAnswers = 0;
        if (this.epilogShowing) {
            this.hideEpilog();
            this.initLetterArr();
        }
        this.initMenu(this.sectionIndex - 2, false);
    };

    initGame = () => {
        this.initButtons(this.root, this.reset, this.gameSoundPath, this.sectionIndex, null, this.backFunc);
        this.disableButtons(["help", "reset"]);
        this.root.AlertBox.visible = false;
        this.currentIntro.visible = false;
        this.currentMenu.visible = false;
        this.root.picBox.visible = true;
        this.root.boxes.gotoAndStop(0);
        this.root.boxes.let1.gotoAndStop(0);
        this.root.boxes.let2.gotoAndStop(0);
        this.root.boxes.let3.gotoAndStop(0);
        this.root.boxes.let4.gotoAndStop(0);
        this.root.boxes.let5.gotoAndStop(0);
        if (!this.endOfGame) this.arrangeGroup();
        this.startBoard();
        if (this.epilogShowing) {
            this.hideEpilog();
        }
        this.gameSound = playSoundSync(this.gameSoundPath);
        this.gameSound.on("complete", () => {
            this.startPlay();
        });
    };

    loaded = () => {
        this.initFrame();
        loadFont(this.root, "NarkisTamMFO", true);
        loadFont(this.root, "Choco", true);
    };
}
