import { MutableRefObject } from "react";
import { MC, playSoundSync, stopAllSounds } from "../../animate/utils";
import { getBibleLockedChannels } from "../../api/moviesSection";
import { Navigation } from "../../navigation/navigation";
import { VideoPlayer } from "../../video/VideoPlayer";
const bibleStoryMovies = [
    "/movies/bibleStory/Briaytulam.mp4",
    "/movies/bibleStory/Adamvehava.mp4",
    "/movies/bibleStory/Noach.mp4",
    "/movies/bibleStory/Migdalbavel.mp4",
];
const MARKER_MIN = 0.25;
const MARKER_MAX = 175;
export class BibleMovies {
    private isLock: boolean = false;
    lockedMovies: string[] = [];
    private bgSound: createjs.AbstractSoundInstance;
    private isLoggedIn: boolean;
    constructor(
        private root: MC,
        private type: string,
        private setVideoSrc: (value: string) => void,
        private videoRef: MutableRefObject<VideoPlayer>,
        private setParentsInfo: (value: boolean) => void,
        isLoggedIn: boolean
    ) {
        (window as any).story = this.root;
        this.isLoggedIn = isLoggedIn;
        this.start();
    }
    start = async () => {
        this.setVideoSrc(bibleStoryMovies[parseInt(this.type) - 1]);
        await this.getLockedMovies();
        this.mooviesListeners();
        this.setUpView();
        this.markerListener();
        this.initLockAlert();
        this.initSound();
        this.root.pic.gotoAndStop(parseInt(this.type) - 1);
    };
    initSound = () => {
        this.bgSound = playSoundSync("bgGameMenu", { loop: -1 });
        this.bgSound.volume = 0.4;
    };
    playBgSound = () => {
        this.bgSound.play();
    };
    getLockedMovies = async () => {
        this.lockedMovies = [];
        getBibleLockedChannels().then((res) => {
            this.lockedMovies = res;
            if (this.lockedMovies.includes(parseInt(this.type).toString())) {
                this.isLock = true;
                this.root.btnLock.gotoAndStop("up");
                this.toggleBtns(false);
            } else {
                if (!this.isLock) return;
                this.isLock = false;
                this.root.btnLock.gotoAndStop("selected_up");
                this.toggleBtns(true);
            }
        });
    };
    toggleBtns = (val: boolean) => {
        this.root.video.btnPlay.visible = val;
        this.root.video.btnRewind.visible = val;
        this.root.video.progressBar.visible = val;
    };
    mooviesListeners = () => {
        this.root.video.btnPlay.addEventListener("click", () => {
            if (this.root.video.btnPlay.currentFrame) {
                this.playBgSound();
                this.videoRef.current.pause();
                this.root.video.btnPlay.gotoAndStop(0);
            } else {
                this.root.pic.visible = false;
                stopAllSounds();
                this.videoRef.current.play();
                this.root.video.btnPlay.gotoAndStop(1);
            }
        });
        this.root.video.btnRewind.addEventListener("click", () => {
            this.restartVideo();
        });
        this.isLoggedIn && this.addLockListeners();
    };
    addLockListeners = () => {
        this.root.btnLock.addEventListener("rollover", () => {
            this.isLock && this.root.btnLock.gotoAndStop("over");
            !this.isLock && this.root.btnLock.gotoAndStop("selected_over");
        });
        this.root.btnLock.addEventListener("rollout", () => {
            this.isLock && this.root.btnLock.gotoAndStop("down");
            !this.isLock && this.root.btnLock.gotoAndStop("selected_down");
        });
        this.root.btnLock.addEventListener("click", () => {
            this.showunLockMessage(this.root.lockAlert);
        });
    };
    setUpView = () => {
        this.root.video.btnPlay.gotoAndStop(0);
        this.root.video.btnPlay.btn.gotoAndStop(0);
        this.root.video.btnRewind.gotoAndStop(0);
        this.root.video.btnRewind.inner1.gotoAndStop(0);
        this.root.video.btnRewind.inner2.gotoAndStop(0);
        this.root.lockAlert.visible = false;
        this.root.lockAlert.yes_btn.cursor = "pointer";
        this.root.lockAlert.close_btn.cursor = "pointer";
        this.root.lockAlert.no_btn.cursor = "pointer";
        this.root.video.progressBar.marker.cursor = "pointer";
        this.root.video.btnPlay.cursor = "pointer";
        this.root.video.btnRewind.cursor = "pointer";
        if (this.isLoggedIn) {
            this.isLock && this.root.btnLock.gotoAndStop("up");
            !this.isLock && this.root.btnLock.gotoAndStop("selected_up");
            this.root.btnLock.cursor = "pointer";
        } else {
            this.root.btnLock.visible = false;
        }

        this.root.video.loadingClip.visible = false;
        this.root.video.txtGuest.visible = false;
    };
    updateVideoPosition = () => {
        const currentTime = this.videoRef.current.getCurrentTime();
        const videoDuration = this.videoRef.current.getVideoDuration();
        if (!isNaN(videoDuration)) {
            this.root.video.progressBar.marker.x =
                MARKER_MIN + (MARKER_MAX - MARKER_MIN) * (currentTime / videoDuration);
        }
    };
    updateLockedMoovies = () => {
        this.setParentsInfo(false);
        this.getLockedMovies();
        this.root.lockAlert.visible = false;
    };
    restartVideo = () => {
        this.root.pic.visible = true;
        this.videoRef.current.restartVideo();
        this.videoRef.current.pause();
        this.root.video.btnPlay.gotoAndStop(0);
        this.root.video.progressBar.marker.x = 0.25;
    };
    markerListener = () => {
        this.root.video.progressBar.marker.addEventListener("mousedown", () => {
            const startX = this.root.video.progressBar.marker.x;
            const startMouseX = this.root.stage.mouseX;
            const wasPlaying = this.root.video.btnPlay.currentFrame;
            if (wasPlaying) {
                this.videoRef.current.pause();
            }
            const onMove = () => {
                const mouseDelta = (this.root.stage.mouseX - startMouseX) / this.root.stage.scale;
                const newX = Math.min(Math.max(startX + mouseDelta, MARKER_MIN), MARKER_MAX);
                this.root.video.progressBar.marker.x = newX;
                this.videoRef.current.setCurrentTime(
                    ((newX - MARKER_MIN) / (MARKER_MAX - MARKER_MIN)) * this.videoRef.current.getVideoDuration()
                );
            };
            const onUp = () => {
                this.root.video.progressBar.marker.removeEventListener("pressmove", onMove);
                this.root.video.progressBar.marker.removeEventListener("pressup", onUp);
                if (wasPlaying) {
                    this.videoRef.current.play();
                }
            };
            this.root.video.progressBar.marker.addEventListener("pressmove", onMove);
            this.root.video.progressBar.marker.addEventListener("pressup", onUp);
        });
    };
    showunLockMessage = (msg: MC) => {
        msg.visible = true;
        this.restartVideo();
    };
    initLockAlert = () => {
        const msg = this.root.lockAlert;
        msg.no_btn.gotoAndStop("up");
        msg.yes_btn.gotoAndStop("up");
        msg.yes_btn.addEventListener("click", () => {
            this.setParentsInfo(true);
            Navigation.openIframe("/parentsInfo/bibleStories.aspx");
        });
        msg.no_btn.addEventListener("click", () => {
            msg.visible = false;
        });
        msg.close_btn.addEventListener("click", () => {
            msg.visible = false;
        });
        msg.yes_btn.addEventListener("mouseover", () => {
            msg.yes_btn.gotoAndStop("over");
        });
        msg.no_btn.addEventListener("mouseover", () => {
            msg.no_btn.gotoAndStop("over");
        });
        msg.yes_btn.addEventListener("mouseout", () => {
            msg.yes_btn.gotoAndStop("up");
        });
        msg.no_btn.addEventListener("mouseout", () => {
            msg.no_btn.gotoAndStop("up");
        });
    };
}
