const additionalSounds = [
    {
        src: "sounds/star_1.mp3",
        id: "star_1",
    },
    {
        src: "sounds/star_2.mp3",
        id: "star_2",
    },
    {
        src: "sounds/star_3.mp3",
        id: "star_3",
    },
    {
        src: "sounds/star_4.mp3",
        id: "star_4",
    },
];
export default additionalSounds;
