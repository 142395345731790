import React from "react";
import AnimateCC from "../../../animate";
import { NumberFiveGame } from "./NumberFiveGame";
import additionalSounds from "../BigWheelSounds";
import { BigWheelGame } from "../BigWheelGame";
const NumberFiveComponent: React.FC<{ frame: BigWheelGame }> = ({ frame }) => {
    const game = React.useRef(new NumberFiveGame(frame));

    return (
        <AnimateCC
            //base="/lunapark/sweets/popcorn"
            base="/lunapark/big_wheel/number_five"
            animationName="number_five"
            additionalSounds={additionalSounds}
            onLoaded={game.current.loaded}
        />
    );
};
export default NumberFiveComponent;
