import React, { useState } from "react";
import AnimateCC from "../../animate";
import { SpecialWordsGame } from "./specialWordGame";
import { gameSounds } from "./games_sounds";
import { myasFeedBacksSounds } from "../common/feedBacksSounds";
import { useParams, useRouteMatch } from "react-router-dom";
import { routes } from "../../navigation/routesPath";
import { Navigation } from "../../navigation/navigation";
import { useLettersContext } from "../../context/LettersContext";

export default function SpecialWordComponent() {
    const match = useRouteMatch();
    const { letterIndex } = useParams<{ letterIndex: string }>();
    const letterInt = parseInt(letterIndex);
    const { setLetterStatus } = useLettersContext();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const exit = () => {
        if (match.path === routes.letters.fancy_words.word) {
            Navigation.openPage(routes.letters.fancy_words.menu);
        } else {
            Navigation.openPage(routes.letters.activities.menu.replace(":letterIndex", `${letterInt - 1}`));
        }
    };

    const [currentLetter, setCurrentLetter] = useState("");

    const game = React.useRef(new SpecialWordsGame(parseInt(letterIndex), setCurrentLetter, exit, setLetterStatus));

    return (
        <>
            <AnimateCC
                key="special_word"
                base="/letters_island/fancy_words/"
                animationName="special_word"
                scale={0.8}
                shiftX={80}
                shiftY={60}
                onLoaded={game.current.specialWordsLoaded}
                onUnload={game.current.unload}
                additionalSounds={gameSounds(parseInt(letterIndex))}
            />
            {currentLetter !== "" && (
                <AnimateCC
                    key="fancyWord"
                    base="/letters_island/fancy_words"
                    animationName={`fancy_word${currentLetter}`}
                    scale={0.65}
                    onLoaded={game.current.fancyWordLoaded}
                    additionalSounds={myasFeedBacksSounds()}
                />
            )}
        </>
    );
}
