import { getLetter } from "../../../animate/utils";

const jsonData = require("./crabData.json");

export function returnObjectsForLetter(letter: number) {
    let objectsForLetter: any[] = [];
    jsonData.root.letters.letter.forEach((object: any) => {
        if (object.sLetter === getLetter(letter)) {
            object.level.forEach((element: any) => {
                objectsForLetter.push(element);
            });
        }
    });
    return objectsForLetter;
}

export function returnModeForLetter(letter: number) {
    let mode;
    jsonData.root.letters.letter.forEach((object: any) => {
        if (object.sLetter === getLetter(letter)) {
            mode = object.sTypes;
        }
    });
    return mode;
}

// export function returnObjectsForLastLetter(letter: number) {
//     let objectsForLetter: any[] = [];
//     let returnObject: any;

//     jsonData.root.letters.letter.forEach((object: any) => {
//         if (object.sLetter === getLetter(letter)) {
//             object.level.forEach((element: any) => {
//                 if (element.sType === "last") objectsForLetter.push(element);
//             });
//         }
//         const index = getRandomNumber(0, objectsForLetter.length - 1);
//         returnObject = objectsForLetter[index];
//     });
//     return returnObject;
// }
