export const quizSounds = [
    {
        src: "sounds/Welcome.mp3",
        id: "welcome",
    },
    {
        src: "sounds/help.mp3",
        id: "help",
    },
    {
        src: "sounds/exit.mp3",
        id: "exit",
    },
    {
        src: "sounds/Again.mp3",
        id: "again",
    },
    {
        src: "sounds/well_1.mp3",
        id: "well1",
    },
    {
        src: "sounds/well_2.mp3",
        id: "well2",
    },
    {
        src: "sounds/well_3.mp3",
        id: "well3",
    },
    {
        src: "sounds/well_4.mp3",
        id: "well4",
    },
    {
        src: "sounds/well_5.mp3",
        id: "well5",
    },
];
