import React from "react";
import AnimateCC from "../../../animate";
import { RocketMenu } from "../RocketMenu";
import { FrenchHornsGame } from "./FrenchHornsGame";

const FrenchHornsComponent: React.FC<{
    frame: RocketMenu;
}> = ({ frame }) => {
    const game = React.useRef(new FrenchHornsGame(frame));

    return (
        <AnimateCC
            base="/lunapark/rocket/french_horns"
            animationName="french_horns"
            scale={0.75}
            shiftX={130}
            shiftY={5}
            onLoaded={game.current.loaded}
        />
    );
};
export default FrenchHornsComponent;
