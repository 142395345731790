import React from "react";
import AnimateCC from "../../animate";
import { SweetsMenu } from "./SweetsMenu";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";

const SweetsMenuComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const menu = React.useRef(new SweetsMenu(frame));

    return (
        <AnimateCC
            base="/lunapark/sweets/sweets_menu/"
            animationName="sweets_menu"
            onLoaded={menu.current.loaded}
        />
    );
};
export default SweetsMenuComponent;
