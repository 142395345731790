import React from "react";
import AnimateCC from "../../animate";
import { MC } from "../../animate/utils";
import { BibleFrame } from "../story_menu/BibleFrame";
import { BavelGame } from "./BavelGame";

const BavelGameComponent: React.FC<{ frame: BibleFrame; onLoaded: () => void }> = ({ frame, onLoaded }) => {
    const game = React.useRef(new BavelGame(frame));

    return (
        <>
            <AnimateCC
                base="/bible_stories/bavel_game"
                animationName="bavel_game"
                onLoaded={(mc: MC) => {
                    game.current.loaded(mc);
                    onLoaded();
                }}
                frameHeight={789}
                frameWidth={1034}
                shiftX={100}
                shiftY={87}
                changeFramerate={true}
            />
        </>
    );
};

export default BavelGameComponent;
