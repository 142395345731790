import { MC, setupStage } from "../../../animate/utils";

export class YellowGame {
    private root: MC;
    private index = 1;

    constructor(private frame: any) {}
    loaded = (mc: MC) => {
        this.root = mc;
        (window as any).Window = this.root;
        setupStage(this.frame?.root, this.root);
        this.frame.toggleInner(false);
        this.root.gotoAndStop(24);
        this.root.back.gotoAndStop(0);
        this.root.back.addEventListener("click", () => {
            this.root.back.gotoAndStop(this.index);
            if (this.index === 5) {
                this.index = 0;
            } else this.index++;
        });
    };
}
