const additionalSounds = [
    {
        src: "sounds/bangwav.mp3",
        id: "pop",
    },
    {
        src: "sounds/drop.mp3",
        id: "dropBal",
    },
    {
        src: "sounds/Clown3.mp3",
        id: "clown",
    },
];
export default additionalSounds;
