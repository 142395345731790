import { loadSound, soundPlayed } from "../animate/sound_utils";
import { MC, playSoundSync, stopAllSounds } from "../animate/utils";
import { CancelablePromise } from "../utils/CancelablePromise";
import { getRandomNumber } from "../utils/MathUtils";
import { SpecialGameBasic } from "./SpecialGameBasic";

export class FindItems extends SpecialGameBasic {
    itemsArray: number[][] = [[], []];
    correctIndex: number = 0;
    currentPromise: any;
    navigationInterval: NodeJS.Timeout;
    isSoundFinished: any;
    NUM_GOOD_ITEMS: number;
    NUM_BAD_ITEMS: number;
    MAX_LEFT: number;
    MAX_RIGHT: number;
    MAX_UP: number;
    MAX_DOWN: number;
    setAnimationFunction: Function;
    setItemsFunction: Function;
    selectCurrentItemFunction: Function;
    soundUrl: string = "";
    batItemSound = loadSound(`special_games/sounds/bad.mp3`);
    goodItemSound = loadSound(`special_games/sounds/good.mp3`);
    numPrizes: number;
    setSpeaker: boolean = false;
    selectedArray: number[] = [];
    selectedItem: number;
    setItemVisible: boolean = false;
    playAgain: createjs.AbstractSoundInstance;
    playAgainSoundUrl = `special_games/sounds/end_game.mp3`;

    constructor(soundUrl: string) {
        super();
        this.soundUrl = soundUrl;
        this.instructionSound = `special_games/${this.soundUrl}sounds/inst.mp3`;
        this.bGSound = `special_games/${this.soundUrl}sounds/music.mp3`;
    }

    public async onLoad() {
        this.initFrameBtns();
        this.setup();
        this.initExit();
        this.initIconMc();
        this.initNavigationBtns();
        this.root.gotoAndPlay(0);
        this.resetPrizes();
        await super.playInstSound().then(() => (this.isSoundFinished = true));
        this.enableBtns();
        if (this.soundUrl && this.soundUrl === "pesah/") super.setBgSound();
        await loadSound(this.playAgainSoundUrl);
    }

    initFrameBtns() {
        if (this.setSpeaker) this.initSpeker();
        this.initHelp();
    }

    enableBtns() {
        this.enableHelp();
        this.enableSpeaker();
    }

    setup = () => {
        this.root.endMessage.visible = false;
        const root = this.root;
        [root.opening, root.endMessage].forEach((btn) => {
            btn.cursor = "pointer";
            btn.addEventListener("click", () => {
                btn.gotoAndPlay("hide");
                this.resetPrizes();
                this.playAgain?.stop();
                if (btn.name === "opening") {
                    this.enableBtns();
                    stopAllSounds();
                    if (this.soundUrl === "pesah/" && !this.isSoundFinished) {
                        super.setBgSound();
                    }
                }
                this.setItemsFunction();
            });
        });
    };
    initNavigationBtns() {
        ["Right", "Left", "Up", "Down"].forEach((element) => {
            this.root[`mapScroll${element}`].addEventListener("rollover", () => {
                this.navigationInterval = setInterval(async () => {
                    this.navigateMap(element, this.root.map.innerMap, [
                        this.MAX_LEFT,
                        this.MAX_RIGHT,
                        this.MAX_UP,
                        this.MAX_DOWN,
                    ]);
                }, 50);
            });
            this.root[`mapScroll${element}`].addEventListener("rollout", () => {
                clearInterval(this.navigationInterval);
            });
        });
    }
    navigateMap = (direction: string, arrows: MC, directionValues: number[]) => {
        switch (direction) {
            case "Left":
                arrows.x += arrows.x < directionValues[0] ? 5 : 0;
                break;
            case "Right":
                arrows.x -= arrows.x > directionValues[1] ? 5 : 0;
                break;
            case "Up":
                arrows.y += arrows.y < directionValues[2] ? 5 : 0;
                break;
            case "Down":
                arrows.y -= arrows.y > directionValues[3] ? 5 : 0;
                break;
            default:
                break;
        }
    };
    public setItems(element: string, numItems: number, numItemsToSelect: number, index: number) {
        for (let i = 0; i < numItems; i++) {
            const item = this.root.map.innerMap[element + i];
            item.cursor = "pointer";
            item.visible = this.setItemVisible;
            this.itemsArray[index].push(i);
            item.removeAllEventListeners();
            item.addEventListener("click", () => {
                this.onItemSelected(item);
            });
        }
        this.randSelectedItems(element, numItemsToSelect, index);
    }
    randSelectedItems(elementName: string, numItems: number, arrayIndex: number) {
        for (let index = 0; index < numItems; index++) {
            const randInd = getRandomNumber(0, this.itemsArray[arrayIndex].length - 1);
            this.selectedArray.push(this.itemsArray[arrayIndex][randInd]);
            const item = this.root.map.innerMap[elementName + this.itemsArray[arrayIndex][randInd]];
            item.visible = true;
            this.itemsArray[arrayIndex].splice(randInd, 1);
        }
    }

    resetPrizes = () => {
        for (let index = 0; index < this.numPrizes; index++) {
            this.root[`prize${index}`].gotoAndStop("off");
        }
    };

    onItemSelected = async (item: MC) => {
        if (
            item.name.includes("goodItem") ||
            (!!!item.name.includes("badItem") && item.name.includes(this.selectedItem))
        ) {
            this.root.mouseEnabled = false;
            this.setAnimationFunction(item);
        } else {
            await soundPlayed((await this.batItemSound).play());
        }
    };

    async cancelableWait(p: Promise<any>) {
        this.currentPromise = p as CancelablePromise<any>;
        await p;
        this.currentPromise = null;
    }

    finishGame = async () => {
        this.root.endMessage.visible = true;
        this.root.mouseEnabled = true;
        this.root.endMessage.gotoAndStop("show");
        await loadSound(this.playAgainSoundUrl);
        this.playAgain = playSoundSync(this.playAgainSoundUrl);
        this.correctIndex = 0;
        this.selectedArray = [];
        this.itemsArray = [[], []];
    };
}
