const additionalSounds = [
    {
        src: "sounds/light.mp3",
        id: "light",
    },
    {
        src: "sounds/darkness.mp3",
        id: "darkness",
    },
];
export default additionalSounds;
