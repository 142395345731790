import React from "react";
import AnimateCC from "../../animate";
import { LunaparkMenu } from "../lunapark_menu/LunaparkMenu";
import { HorsesGame } from "./HorsesGame";
const HorsesComponent: React.FC<{ frame: LunaparkMenu }> = ({ frame }) => {
    const game = React.useRef(new HorsesGame(frame));

    return (
        <AnimateCC
            base="/lunapark/horses"
            animationName="horses"
            onLoaded={game.current.loaded}
        />
    );
};
export default HorsesComponent;
