import React from "react";
import * as loginApi from "../api/login";

export type Madorim = {
    english_time: boolean;
    dubby_english: boolean;
    letters: boolean;
    math: boolean;
    music: boolean;
    bible_stories: boolean;
};

// export const ALL_PERMISSIONS = {
//     english_time: true,
//     dubby_english: true,
//     letters: true,
//     math: true,
//     music: true,
//     bible_stories: true,
// };
const UserContext = React.createContext<{
    currentUser: string;
    setCurrentUser: (val: string) => void;
    isLoggedIn: boolean;
    logout: () => void;
    // permissions: Madorim | null;
    permissions: Madorim;
    setPermissions: (val: Madorim) => void;
}>({
    currentUser: null,
    setCurrentUser: null,
    isLoggedIn: null,
    logout: null,
    permissions: null,
    setPermissions: null,
});

export const UserContextProvider: React.FC = (props) => {
    const [currentUser, setCurrentUser] = React.useState<string>(loginApi.getUserId());
    // const [permissions, setPermissions] = React.useState<Madorim | null>(ALL_PERMISSIONS);
    const [permissions, setPermissions] = React.useState<Madorim>({
        english_time: true,
        dubby_english: true,
        letters: true,
        math: true,
        music: true,
        bible_stories: true,
    });
    const isLoggedIn = currentUser !== null;

    const logout = () => {
        loginApi.logout();
        setPermissions({
            english_time: true,
            dubby_english: true,
            letters: true,
            math: true,
            music: true,
            bible_stories: true,
        });
        setCurrentUser(null);
    };

    return (
        <UserContext.Provider value={{ currentUser, setCurrentUser, isLoggedIn, logout, permissions, setPermissions }}>
            {props.children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => React.useContext(UserContext);
