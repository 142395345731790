import { MC, setupStage, waitForEvent } from "../../../animate/utils";

export class NumberTwoGame {
    private root: MC;
    constructor(private frame: any) {}
    loaded = (mc: createjs.MovieClip) => {
        (window as any).WheelGame = mc;
        (window as any).game = this;
        this.root = mc;
        setupStage(this.frame?.root, this.root);
        this.frame.hideBackground();
        this.frame.toggleOnetwothreebut(true);
        this.root.two.gotoAndStop(0);
        this.root.one.gotoAndStop(0);
        this.root.one.oneButton.addEventListener("click", async () => {
            this.root.one.gotoAndPlay(1);
            await waitForEvent(this.root, "finish animate");
            this.root.one.gotoAndStop(0);
        });
        this.root.two.twoButton.addEventListener("click", async () => {
            this.root.two.gotoAndPlay(1);
            await waitForEvent(this.root, "finishJump");
            this.root.two.gotoAndPlay(13);
            await waitForEvent(this.root, "finish animate");
            this.root.two.gotoAndStop(0);
        });
    };
}
